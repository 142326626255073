import React, { Component } from 'react';
import NoRecordFound from '../../assets/images/no-image-found.png';

class NoRecordsFound extends Component {
    render() {
        let message = 'No Records Found.';
        return (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center position-relative">
                    <img src={NoRecordFound} alt="" />
                </div>
                <div className="position-absolute fw-medium fs-14 ps-10">{this.props.text ? `No ${this.props.text} Found.` : message}</div>
            </div>
        );
    }
}
export default NoRecordsFound;
