import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../../Components/Common/ChartsDynamicColor';
import { getChartsData } from '../../../Components/Common/Util';

const AnnouncmentStatusChart = ({ dataColors, series }) => {
    var dountchartUserDeviceColors = getChartColorsArray(dataColors);
    const labels = ['Read', 'Unread'];
    var options = getChartsData({ labels: labels, colors: dountchartUserDeviceColors });

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="donut" height="180" className="apex-charts" />
        </React.Fragment>
    );
};

export default AnnouncmentStatusChart;
