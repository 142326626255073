const masterTenantSchema = [
    {
        label: 'Branding',
        icon: 'ri-price-tag-3-fill',
        active: true,
        fields: [
            {
                label: 'Primary Header',
                value: 'PRIMARY_HEADER',
                path: ['BRANDING', 'PRIMARY_HEADER'],
                helpText: 'Enter the Primary Header.',
                inputType: 'input',
                maxLength: 50
            },
            {
                label: 'Secondary Header',
                value: 'SECONDARY_HEADER',
                path: ['BRANDING', 'SECONDARY_HEADER'],
                helpText: 'Enter the Secondary Header.',
                inputType: 'input',
                maxLength: 50
            },
            /*
             * {
             *     label: 'Primary Label',
             *     value: 'COMPANY_PRIMARY_LABEL',
             *     helpText: 'Enter the Primary Label.',
             *     inputType: 'input',
             *     maxLength: 50
             * },
             * {
             *     label: 'Secondary Label',
             *     value: 'COMPANY_SECONDARY_LABEL',
             *     helpText: 'Enter the Secondary Label.',
             *     inputType: 'input',
             *     maxLength: 50
             * },
             * {
             *     label: 'Primary Color',
             *     value: 'COMPANY_PRIMARY_COLOR',
             *     helpText: 'Controls the Primary Color.',
             *     inputType: 'input',
             *     type: 'color',
             *     class: 'widthPer-30 padding-5'
             * },
             * {
             *     label: 'Secondary Color',
             *     value: 'COMPANY_SECONDARY_COLOR',
             *     inputType: 'input',
             *     helpText: 'Controls the Secondary Color.',
             *     type: 'color',
             *     class: 'widthPer-30 padding-5'
             * },
             * {
             *     label: 'Success Color',
             *     value: 'COMPANY_SUCCESS_COLOR',
             *     helpText: 'Controls the Success Color',
             *     inputType: 'input',
             *     type: 'color',
             *     class: 'widthPer-30 padding-5'
             * },
             */
            {
                label: 'Icon on the tab',
                value: 'FAV_ICON',
                path: ['BRANDING', 'FAV_ICON'],
                helpText: 'This icon will be used as the icon on the tab',
                accept: ['.ico', 'png'],
                inputType: 'upload',
                noMinSize: true
            },
            {
                label: 'Company Logo',
                value: 'COMPANY_LOGO',
                path: ['BRANDING', 'COMPANY_LOGO'],
                helpText: 'Upload the Company Logo',
                accept: ['.jpg', '.jpeg', '.png', '.svg'],
                inputType: 'upload'
            },
            {
                label: 'Login Page Image',
                value: 'COMPANY_SLIDE_BG1',
                path: ['BRANDING', 'COMPANY_SLIDE_BG1'],
                helpText: 'Upload the Image for login page',
                accept: ['.jpg', '.jpeg', '.png', '.svg'],
                inputType: 'upload'
            },
            {
                label: 'Footer Text',
                value: 'COMPANY_FOOTER',
                path: ['BRANDING', 'COMPANY_FOOTER'],
                inputType: 'input',
                helpText: 'Enter Footer Text',
                maxLength: 30
            }
        ]
    },
    {
        label: 'Modules',
        icon: 'ri-article-line',
        fields: [
            {
                inputType: 'buttons',
                hideMode: 'view',
                buttons: [
                    {
                        label: 'Select All',
                        value: 'selectAll',
                        icon: 'ri-check-double-line',
                        helpText: 'Selects all modules',
                        inputType: 'selectButton'
                    },
                    {
                        label: 'Clear All',
                        value: 'clearAll',
                        icon: 'ri-close-circle-line',
                        helpText: 'Clears all modules',
                        inputType: 'selectButton'
                    }
                ]
            },
            {
                label: 'Dashboard',
                value: 'DASHBOARD',
                helpText: 'Controls Dashboard',
                inputType: 'toggle',
                path: ['DASHBOARD', 'featureEnabled']
            },
            { label: 'Setup', value: 'SETUP', helpText: 'Controls Setup', inputType: 'toggle', path: ['SETUP', 'featureEnabled'] },
            {
                label: 'Policies',
                value: 'DEVICE_POLICY',
                helpText: 'Controls Device Policies',
                inputType: 'toggle',
                path: ['DEVICE_POLICY', 'featureEnabled']
            },
            { label: 'Devices', value: 'DEVICES', helpText: 'Controls Devices', inputType: 'toggle', path: ['DEVICES', 'featureEnabled'] },
            { label: 'Apps', value: 'APPS', helpText: 'Controls apps', inputType: 'toggle', path: ['APPS', 'featureEnabled'] },
            {
                label: 'Launcher',
                value: 'LAUNCHER_SETUP',
                helpText: 'Controls Launcher',
                inputType: 'toggle',
                path: ['LAUNCHER_SETUP', 'featureEnabled']
            },
            { label: 'Reports', value: 'REPORTS', helpText: 'Controls Reports', inputType: 'toggle', path: ['REPORTS', 'featureEnabled'] },
            /*
             * {
             *     label: 'Device Automation',
             *     value: 'DEVICE_AUTOMATION',
             *     helpText: 'Controls Device Automation',
             *     inputType: 'toggle',
             *     path: ['DEVICE_AUTOMATION', 'featureEnabled']
             * },
             */
            {
                label: 'Bulk Requests',
                value: 'DEVICE_BULK_REQUEST',
                helpText: 'Controls device bulk requests',
                inputType: 'toggle',
                path: ['DEVICE_BULK_REQUEST', 'featureEnabled']
            },
            { label: 'Files', value: 'FILES', helpText: 'Controls Files', inputType: 'toggle', path: ['FILES', 'featureEnabled'] },
            {
                label: 'Messages',
                value: 'MESSAGES',
                helpText: 'Controls Messages',
                inputType: 'toggle',
                path: ['MESSAGES', 'featureEnabled']
            },
            { label: 'Browser', value: 'BROWSER', helpText: 'Controls browser', inputType: 'toggle', path: ['BROWSER', 'featureEnabled'] },
            {
                label: 'App Protection Policy',
                value: 'APP_PROTECTION_POLICY',
                helpText: 'Controls App Protection Policy',
                inputType: 'toggle',
                path: ['APP_PROTECTION_POLICY', 'featureEnabled']
            },
            {
                label: 'Firewall',
                value: 'FIREWALL',
                helpText: 'Controls firewall',
                inputType: 'toggle',
                path: ['FIREWALL', 'featureEnabled']
            },
            {
                label: 'Geo-Fence',
                value: 'GEOFENCE',
                helpText: 'Controls Geo-Fence',
                inputType: 'toggle',
                path: ['GEOFENCE', 'featureEnabled']
            },
            {
                label: 'Time-Fence',
                value: 'TIMEFENCE',
                helpText: 'Controls Time-Fence',
                inputType: 'toggle',
                path: ['TIMEFENCE', 'featureEnabled']
            },
            {
                label: 'Wi-Fi Fence',
                value: 'WIFIFENCE',
                helpText: 'Controls Wi-Fi-Fence',
                inputType: 'toggle',
                path: ['WIFIFENCE', 'featureEnabled']
            },
            {
                label: 'SIM Settings',
                value: 'SIM_SETTINGS',
                helpText: 'Controls SIM Settings',
                inputType: 'toggle',
                path: ['SIM_SETTINGS', 'featureEnabled']
            },
            {
                label: 'Contact List',
                value: 'CONTACT_LIST',
                helpText: 'Controls Contact List',
                inputType: 'toggle',
                path: ['CONTACT_LIST', 'featureEnabled']
            },
            {
                label: 'Call Settings',
                value: 'CALL_CONFIG',
                helpText: 'Controls Call Configurations',
                inputType: 'toggle',
                path: ['CALL_CONFIG', 'featureEnabled']
            },
            {
                label: 'Packages',
                value: 'PACKAGES',
                helpText: 'Controls Packages',
                inputType: 'toggle',
                path: ['PACKAGES', 'featureEnabled']
            },
            {
                label: 'Certificates',
                value: 'CERTIFICATE_MANAGEMENT',
                helpText: 'Controls Packages',
                inputType: 'toggle',
                path: ['CERTIFICATE_MANAGEMENT', 'featureEnabled']
            },
            {
                label: 'Acer ODM Features',
                value: 'ACER_ODM_FEATURES',
                helpText: 'Controls Acer ODM Features',
                inputType: 'toggle',
                path: ['ACER_ODM_FEATURES', 'featureEnabled']
            },
            {
                label: 'Conditional Access MAM',
                value: 'CONDITIONAL_ACCESS_MAM',
                helpText: 'Controls Conditional Access MAM',
                inputType: 'toggle',
                path: ['CONDITIONAL_ACCESS_MAM', 'featureEnabled']
            },
            {
                label: 'Groups',
                value: 'DEVICE_GROUPS',
                helpText: 'Controls Device Groups',
                inputType: 'toggle',
                path: ['DEVICE_GROUPS', 'featureEnabled']
            },
            { label: 'Assets', value: 'ASSETS', helpText: 'Controls Assets', inputType: 'toggle', path: ['ASSETS', 'featureEnabled'] },
            {
                label: 'Password',
                value: 'PASSWORD',
                helpText: 'Controls Password screen',
                inputType: 'toggle',
                path: ['PASSWORD', 'featureEnabled']
            },
            {
                label: 'Authentication Modes',
                value: 'AUTH_MODES',
                helpText: 'Controls auth modes',
                inputType: 'toggle',
                path: ['AUTH_MODES', 'featureEnabled']
            },
            {
                label: 'Admin Reports',
                value: 'ADMIN_REPORTS',
                helpText: 'Controls admin reports screen',
                inputType: 'toggle',
                path: ['ADMIN', 'featureEnabled']
            },
            {
                label: 'License',
                value: 'LICENSE',
                helpText: 'Controls Licensing screen',
                inputType: 'toggle',
                path: ['LICENSE', 'featureEnabled']
            }
        ]
    },
    {
        label: 'Settings',
        icon: 'ri-settings-5-line',
        active: false,
        fields: [
            /*
             * {
             *     label: 'Online Within',
             *     quantity: 'ONLINE',
             *     unit: 'ONLINE_UNIT',
             *     maxLength: '3',
             *     mandatory: true,
             *     inputType: 'input_dropdown',
             *     dropdownPlaceholder: 'Select the unit',
             *     options: [
             *         { label: 'Hours', value: 'hours' },
             *         { label: 'Days', value: 'days' },
             *         { label: 'Weeks', value: 'weeks' }
             *     ],
             *     helpText: 'Device will show online until this time.'
             * },
             * {
             *     label: 'Offline After',
             *     quantity: 'OFFLINE',
             *     unit: 'OFFLINE_UNIT',
             *     mandatory: true,
             *     maxLength: '3',
             *     dropdownPlaceholder: 'Select the unit',
             *     inputType: 'input_dropdown',
             *     options: [
             *         { label: 'Hours', value: 'hours' },
             *         { label: 'Days', value: 'days' },
             *         { label: 'Weeks', value: 'weeks' }
             *     ],
             *     helpText: 'Device will show offline after this time.'
             * },
             */
            {
                label: 'Default Password',
                value: 'defaultPassword',
                path: ['EMM', 'settings', 'DEVICE_DEFAULT_PASSWORD'],
                mandatory: true,
                helpText:
                    'Default Password after resetting the password. Password should contain atleast one small alphabet, one capital alphabet, one digit, one symbol and should contain atleast 8 characters and maximum 12 characters.',
                helpIconColor: 'red',
                inputType: 'input',
                maxLength: 15
            },
            {
                label: 'Enable Enrollment Authenticator',
                value: 'authenticator',
                path: ['EMM', 'settings', 'ENABLE_QR_AUTHENTICATOR'],
                helpText: 'Enabled Enrollment Authenticator',
                inputType: 'toggle'
            },
            {
                isHeader: true,
                label: 'Select any one from the below options',
                mandatory: true
            },
            /*
             * {
             *     label: 'Enable Chrome OS',
             *     value: 'chromeEnabled',
             *     helpText: 'Allows Chrome OS',
             *     inputType: 'toggle'
             * },
             */
            {
                label: 'Enable Android',
                value: 'androidEnterpriseEnabled',
                path: ['EMM', 'settings', 'ANDROID_ENTERPRISE_ENABLED'],
                helpText: 'Allows Android Mobile',
                inputType: 'toggle'
            },
            {
                label: 'Enable TV',
                value: 'androidTvEnabled',
                path: ['EMM', 'settings', 'ANDROID_TV_ENABLED'],
                helpText: 'Allows Android TV',
                inputType: 'toggle'
            },
            /*
             * {
             *     label: 'Android Legacy',
             *     value: 'androidLegacyEnabled',
             *     helpText: 'Allows Android Legacy',
             *     inputType: 'toggle'
             * },
             */
            {
                label: 'Enable iOS',
                value: 'appleIOSEnabled',
                path: ['EMM', 'settings', 'APPLE_IOS_ENABLED'],
                helpText: 'Allows Apple iOS',
                inputType: 'toggle'
            },
            {
                label: 'Enable Windows',
                value: 'windowsEnabled',
                path: ['EMM', 'settings', 'WINDOWS_ENABLED'],
                helpText: 'Allows Windows',
                inputType: 'toggle'
            }
            /*
             * {
             *     label: 'Content Management Total Size',
             *     quantity: 'maxFileSize',
             *     mandatory: true,
             *     mandatoryCheck: 'CONTENT_MANAGEMENT_ENABLED',
             *     unit: 'maxFileUnit',
             *     dropdownPlaceholder: 'Select the unit',
             *     type: 'number',
             *     helpText: 'Defines total usable size in content management',
             *     inputType: 'input_dropdown',
             *     options: [
             *         { label: 'MB', value: 'MB' },
             *         { label: 'GB', value: 'GB' },
             *         { label: 'TB', value: 'TB' }
             *     ]
             * },
             * {
             *     label: 'File Size',
             *     quantity: 'FILE_SIZE_QUANTITY_LIMIT',
             *     mandatory: true,
             *     unit: 'FILE_SIZE_UNIT',
             *     dropdownPlaceholder: 'Select the unit',
             *     type: 'number',
             *     helpText: 'Defines maximum size of the file for one upload',
             *     inputType: 'input_dropdown',
             *     options: [
             *         { label: 'KB', value: 'KB' },
             *         { label: 'MB', value: 'MB' }
             *     ]
             * },
             * {
             *     label: 'Max Devices',
             *     value: 'EMM_MAX_DEVICES',
             *     helpText: 'Controls the Max Devices.',
             *     inputType: 'input',
             *     type: 'number',
             *     class: 'w-100'
             *     // hide: 'EMM_ENABLED'
             * },
             * {
             *     label: 'Languages',
             *     value: 'LANGUAGES',
             *     inputType: 'select',
             *     helpText: 'Defines the languages to be used in the announcements screen',
             *     isMulti: true,
             *     options: []
             * },
             * {
             *     label: 'Asset Types',
             *     value: 'ASSET_TYPES',
             *     inputType: 'select',
             *     helpText: 'Controls the asset types used in the assets screen',
             *     isMulti: true,
             *     options: [
             *         { label: 'Launcher Logo', value: 'Launcher Logo' },
             *         { label: 'Launcher Background Image', value: 'Launcher Background Image' },
             *         { label: 'Wallpaper', value: 'Wallpaper' },
             *         { label: 'Boot Logo Image', value: 'Boot Logo Image' },
             *         { label: 'Boot Animation', value: 'Boot Animation' },
             *         { label: 'Boot Sound', value: 'Boot Sound' },
             *         { label: 'Buzzer Sound', value: 'Buzzer Sound' }
             *     ]
             * },
             * {
             *     label: 'Custom Launcher Name',
             *     value: 'CUSTOM_LAUNCHER_NAME',
             *     inputType: 'input',
             *     helpText: 'Controls the name of the primary custom launcher'
             * },
             * {
             *     label: 'Custom Launcher Package',
             *     value: 'CUSTOM_LAUNCHER_PACKAGE',
             *     inputType: 'input',
             *     helpText: 'Controls the primary custom launcher'
             * },
             * {
             *     label: 'Default Path',
             *     value: 'DEFAULT_PATH',
             *     inputType: 'input',
             *     helpText: 'Controls the default path for the content management'
             * },
             * {
             *     label: 'FIle types allowed',
             *     value: 'CONTENT_TYPES_ALLOWED',
             *     inputType: 'select',
             *     isMulti: true,
             *     helpText: 'Controls the types of files allowed to upload in the content management',
             *     options: [
             *         { label: 'Audio', value: 'audio' },
             *         { label: 'Video', value: 'video' },
             *         { label: 'Image', value: 'image' },
             *         { label: 'Document', value: 'document' }
             *     ]
             * },
             * {
             *     label: 'Custom Launcher Name',
             *     value: 'CUSTOM_LAUNCHER_NAME',
             *     inputType: 'input',
             *     helpText: 'Controls the name of the primary custom launcher'
             * },
             * {
             *     label: 'Custom Launcher Package',
             *     value: 'CUSTOM_LAUNCHER_PACKAGE',
             *     inputType: 'input',
             *     helpText: 'Controls the primary custom launcher'
             * },
             * {
             *     label: 'Default Path',
             *     value: 'DEFAULT_PATH',
             *     inputType: 'input',
             *     helpText: 'Controls the default path for the content management'
             * },
             * {
             *     label: 'File types allowed',
             *     value: 'CONTENT_TYPES_ALLOWED',
             *     inputType: 'select',
             *     isMulti: true,
             *     helpText: 'Controls the types of files allowed to upload in the content management',
             *     options: [
             *         { label: 'Audio', value: 'audio' },
             *         { label: 'Video', value: 'video' },
             *         { label: 'Image', value: 'image' },
             *         { label: 'Document', value: 'document' }
             *     ]
             * }
             */
        ]
    },
    {
        label: 'Dashboard',
        key: 'dashboard',
        icon: 'ri-dashboard-2-fill',
        active: false,
        fields: [
            {
                label: 'Widgets',
                value: 'DASHBOARD_WIDGETS',
                helpText: 'Controls widgets in dashboard',
                path: ['DASHBOARD', 'settings', 'DASHBOARD_WIDGETS'],
                inputType: 'select',
                key: 'widgets',
                conditionalMandatory: true,
                mandatoryCondition: ['DASHBOARD'],
                isMulti: true,
                options: [
                    { label: 'Device Status Cards', value: 'dashboard_devices' },
                    { label: 'Device Online Summary', value: 'dashboard_device_online_summary' },
                    { label: 'OS Distribution', value: 'dashboard_os_distribution' },
                    { label: 'Device Distribution', value: 'dashboard_device_distribution' },
                    { label: 'Apps', value: 'dashboard_apps' },
                    { label: 'Device Count by Supplier', value: 'supplier_count' },
                    { label: 'Power ON Devices (Post Enrolment)', value: 'power_on_status' },
                    { label: 'Top (5) Most Used Apps', value: 'most_used_apps' },
                    { label: 'Login & Logout History', value: 'login_history' },
                    { label: 'Supervised & UnSupervised Devices', value: 'super_unSuper_devices' },
                    { label: 'Device Type', value: 'device_type' }
                ]
            },
            {
                label: 'Custom dashboard user',
                value: 'CUSTOM_DASHBOARD_EMAIL',
                path: ['DASHBOARD', 'settings', 'CUSTOM_DASHBOARD_EMAIL'],
                mandatory: true,
                helpText:
                    'User with this mail will get the custom dashboard with supplier filter. Enter multiple mail ids with (,) seperation',
                inputType: 'input',
                multiple: true
            }
        ]
    },
    {
        label: 'Android Policies',
        key: 'android_policies',
        icon: 'ri-profile-fill',
        active: false,
        fields: [
            {
                label: 'Configurations',
                value: 'POLICY_CONFIGURATIONS',
                key: 'configurations',
                path: ['DEVICE_POLICY', 'settings', 'POLICY_CONFIGURATIONS'],
                helpText: 'Controls the configurations in the policy',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'Enable Call Logs', value: 'enable_call_logs_config' },
                    { label: 'Enable App Usage Report', value: 'enable_app_usage_report_config' },
                    { label: 'Enable Violations', value: 'enable_violations_cofig' },
                    { label: 'Enable Location History', value: 'enable_location_history_config' },
                    { label: 'Enable SIM Operators', value: 'enable_sim_operators_config' },
                    { label: 'Enable Battery Percentage', value: 'enable_battery_config' },
                    { label: 'Enable Last Modified Date', value: 'enable_last_modified_date_config' },
                    { label: 'Enable Personal Account', value: 'enable_personal_account_config' },
                    { label: 'Enable Wallpaper', value: 'enable_wallpaper_config' },
                    { label: 'Enable Lock Screen Wallpaper', value: 'enable_lock_screen_wallpaper' },
                    { label: 'Enable Boot Animation', value: 'enable_boot_animation_config' },
                    { label: 'Enable Shutdown Logo', value: 'enable_shutdown_logo' }
                ]
            },
            {
                label: 'Policy Types',
                value: 'EMM_POLICY_TYPES',
                path: ['DEVICE_POLICY', 'settings', 'policyTypes'],
                key: 'policy_types',
                inputType: 'select',
                helpText: 'Controls the types of profiles',
                isMulti: true,
                conditionalMandatory: true,
                mandatoryCondition: ['androidEnterpriseEnabled', 'DEVICE_POLICY'],
                // hide: 'EMM_ENABLED',
                options: [
                    { label: 'Fully Managed Device', value: 'FM' },
                    { label: 'Work Profile', value: 'WP' },
                    { label: 'Dedicated Device', value: 'DD' }
                ]
            },
            {
                label: 'Dedicated device Policy Types',
                value: 'EMM_DD_POLICY_TYPES',
                key: 'dd_policy_types',
                path: ['DEVICE_POLICY', 'settings', 'ddPolicyTypes'],
                inputType: 'select',
                helpText: 'Controls the types of DD profiles',
                mandatory: true,
                isMulti: true,
                hide: 'EMM_POLICY_TYPES',
                checkInArr: true,
                checkVal: 'DD',
                options: [
                    { label: 'Single APP', value: 'SA' },
                    { label: 'Kiosk Launcher', value: 'KL' },
                    { label: 'Tectoro Launcher', value: 'TL' }
                ]
            },
            {
                label: 'Workflow Required',
                value: 'workflow',
                path: ['DEVICE_POLICY', 'settings', 'ENABLE_WORKFLOW'],
                helpText: 'Enables the workflow',
                inputType: 'toggle'
            },
            {
                label: 'Sign-In based enrollment',
                value: 'enableSignIn',
                path: ['EMM', 'settings', 'ENABLE_SIGN_IN'],
                helpText: 'Enables the sign-In based enrollment',
                inputType: 'toggle'
            },
            {
                label: 'Enable Launcher Branding',
                value: 'EMM_LAUNCHER_BRANDING',
                path: ['DEVICE_POLICY', 'settings', 'EMM_LAUNCHER_BRANDING'],
                helpText: 'Enabling this will give branding customisation in Tectoro launcher.',
                inputType: 'toggle'
            },
            {
                label: 'Package Enabling',
                value: 'AE_ENABLE_DISABLE_PACKAGE',
                path: ['DEVICE_POLICY', 'settings', 'AE_ENABLE_DISABLE_PACKAGE'],
                helpText: 'Enabling this will let the user to enable or disable the packages in android policy.',
                inputType: 'toggle'
            }
        ]
    },
    {
        label: 'Android Devices',
        key: 'android_devices',
        icon: 'ri-device-line',
        active: false,
        fields: [
            {
                label: 'Maximum device',
                value: 'MAX_ANDROID_DEVICE',
                inputType: 'input',
                path: ['DEVICES', 'settings', 'MAX_ANDROID_DEVICE'],
                change: 'max_device_page_change',
                helpText: 'Maximum number of android devices to be enrolled.',
                maxLength: 8,
                conditionalMandatory: true,
                mandatoryCondition: ['androidEnterpriseEnabled', 'DEVICES']
            },
            {
                label: 'Filter Panels',
                value: 'FILTER_PANELS',
                path: ['DEVICES', 'settings', 'FILTER_PANELS'],
                key: 'filter_panels',
                inputType: 'select',
                helpText: 'Controls the filter panels in the devices screen',
                isMulti: true,
                options: [
                    { label: 'App Status', value: 'appStatusArr' },
                    { label: 'Device Status', value: 'device_status' },
                    { label: 'Groups', value: 'groups' },
                    { label: 'Online Status', value: 'online_status' },
                    { label: 'Policies', value: 'policies' },
                    { label: 'Violations', value: 'violations' }
                ]
            },
            {
                label: 'Table Columns',
                value: 'TABLE_COLUMNS',
                path: ['DEVICES', 'settings', 'TABLE_COLUMNS'],
                key: 'table_columns',
                checkObject: true,
                helpText: 'Controls the table columns in devices screen.',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'Android Version', value: 'Android Version' },
                    { label: 'Battery', value: 'Battery' },
                    { label: 'Device Status', value: 'Device Status' },
                    { label: 'Group', value: 'Group' },
                    { label: 'IMEI', value: 'IMEI' },
                    { label: 'Last Contact', value: 'Last Contact' },
                    { label: 'User', value: 'User' },
                    { label: 'Policy Version', value: 'Policy Version' },
                    { label: 'Policy View', value: 'Policy View' },
                    { label: 'Sequence', value: 'Sequence' },
                    { label: 'Username', value: 'Username' },
                    { label: 'Manufacturer', value: 'Manufacturer' },
                    { label: 'Violations', value: 'Violations' },
                    { label: 'Custom Field', value: 'Custom Field' }
                ]
            },
            {
                label: 'Device Actions & Monitoring Items',
                value: 'DEVICE_ACTIONS_MONITORING',
                path: ['DEVICES', 'settings', 'DEVICE_ACTIONS'],
                key: 'device_actions',
                helpText: 'Controls the actions and monitoring items on devices.',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'Antivirus', value: 'ANTIVIRUS' },
                    { label: 'App Usage', value: 'APP_USAGE' },
                    { label: 'Camera', value: 'CAMERA' },
                    { label: 'Factory Mode', value: 'FACTORY_MODE' },
                    { label: 'Factory Reset', value: 'FACTORY_RESET' },
                    { label: 'Geofence', value: 'GEOFENCE' },
                    { label: 'Kiosk Exit QR', value: 'KIOSK_EXIT' },
                    { label: 'Launcher Password', value: 'LAUNCHER_PASSWORD' },
                    { label: 'Location History', value: 'LOCATION_HISTORY' },
                    { label: 'Lock', value: 'LOCK' },
                    { label: 'Lost mode', value: 'LOST_MODE' },
                    { label: 'Map View', value: 'MAP_VIEW' },
                    { label: 'Reboot', value: 'REBOOT' },
                    { label: 'Refresh', value: 'REFRESH' },
                    { label: 'Remote', value: 'REMOTE' },
                    { label: 'Reset Password', value: 'RESET_PASSWORD' },
                    { label: 'Retire', value: 'RETIRE' },
                    { label: 'Scream', value: 'SCREAM' },
                    { label: 'Violations', value: 'VIOLATIONS' }
                ]
            },
            {
                label: 'Enable Data Tracking',
                value: 'enableDataTracking',
                path: ['DEVICES', 'settings', 'ENABLE_DATA_TRACKING'],
                helpText: 'Enables Data Tracking',
                inputType: 'toggle'
            },
            {
                label: 'Auto Refresh',
                value: 'autoRefresh',
                path: ['DEVICES', 'settings', 'AUTO_REFRESH'],
                helpText: 'Refreshes the devices screen for every 30 seconds.',
                inputType: 'toggle'
            },
            {
                label: 'Show SIM Info',
                value: 'simSimInfo',
                path: ['DEVICES', 'settings', 'SHOW_SIM_INFO'],
                helpText: 'Contorl SIM info card in device details screen.',
                inputType: 'toggle'
            },
            {
                label: 'Maximum Data Usage',
                value: 'maxDataUsage',
                path: ['DEVICES', 'settings', 'MAX_DATA_USAGE'],
                key: 'max_data',
                unit: 'dataUnit',
                unitPath: ['DEVICES', 'settings', 'MAX_DATA_USAGE_UNIT'],
                mandatory: true,
                helpText: 'Contorls the maximum data usage per device for one day',
                inputType: 'slider',
                min: 0,
                max: 1000,
                hide: 'enableDataTracking',
                dropdownPlaceholder: 'Select Unit',
                optionValue: 'unitVal',
                options: [
                    { label: 'KB', value: 'KB', unitVal: 'kb', step: 100 },
                    { label: 'MB', value: 'MB', unitVal: 'mb', step: 100 },
                    { label: 'GB', value: 'GB', unitVal: 'gb', step: 1 },
                    { label: 'TB', value: 'TB', unitVal: 'tb', step: 1 }
                ]
            },
            {
                label: 'Hourly App Usage',
                value: 'HOURLY_APP_USAGE',
                unitPath: ['DEVICES', 'settings', 'HOURLY_APP_USAGE'],
                helpText: 'Enabling this results in hourly app usage report. Disabling this results in daily app usage report',
                inputType: 'toggle'
            }
        ]
    },
    {
        label: 'TV Policies',
        icon: 'ri-profile-fill',
        active: false,
        fields: [
            {
                label: 'Geofence',
                value: 'ENABLE_TV_GEOFENCE',
                path: ['TV_POLICY', 'settings', 'ENABLE_TV_GEOFENCE'],
                helpText: 'Enabled geo fence in TV',
                inputType: 'toggle'
            }
        ]
    },
    {
        label: 'TV Devices',
        key: 'tv_devices',
        icon: 'ri-tv-2-line',
        active: false,
        fields: [
            {
                label: 'Device Actions & Monitoring Items',
                value: 'TV_DEVICE_ACTIONS_MONITORING',
                path: ['TV_DEVICES', 'settings', 'DEVICE_ACTIONS'],
                key: 'device_actions',
                helpText: 'Controls the actions and monitoring items on tv devices.',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'App Usage', value: 'APP_USAGE' },
                    { label: 'Factory Reset', value: 'FACTORY_RESET' },
                    { label: 'Geofence', value: 'GEOFENCE' },
                    { label: 'Launcher Password', value: 'LAUNCHER_PASSWORD' },
                    { label: 'Location History', value: 'LOCATION_HISTORY' },
                    { label: 'Lock', value: 'LOCK' },
                    { label: 'Map View', value: 'MAP_VIEW' },
                    { label: 'Refresh', value: 'REFRESH' },
                    { label: 'Reboot', value: 'REBOOT' },
                    { label: 'Volume', value: 'VOLUME' }
                ]
            },
            {
                label: 'Hourly App Usage',
                value: 'TV_HOURLY_APP_USAGE',
                path: ['TV_DEVICES', 'settings', 'HOURLY_APP_USAGE'],
                helpText: 'Enabling this results in hourly app usage report. Disabling this results in daily app usage report',
                inputType: 'toggle'
            }
        ]
    },
    {
        label: 'iOS Policies',
        key: 'ios_policies',
        icon: 'ri-profile-fill',
        active: false,
        fields: [
            {
                label: 'Policy Types',
                value: 'IOS_POLICY_TYPES',
                path: ['IOS_POLICY', 'settings', 'policyTypes'],
                key: 'policy_types',
                inputType: 'select',
                helpText: 'Controls the types of profiles',
                isMulti: true,
                conditionalMandatory: true,
                mandatoryCondition: ['appleIOSEnabled', 'DEVICE_POLICY'],
                // hide: 'EMM_ENABLED',
                options: [
                    { label: 'iOS', value: 'ios' },
                    { label: 'macOS', value: 'macos' }
                    // { label: 'Dedicated Device', value: 'DD' }
                ]
            },
            {
                label: 'Dedicated Device Policy Types',
                value: 'IOS_DD_POLICY_TYPES',
                path: ['IOS_POLICY', 'settings', 'ddPolicyTypes'],
                inputType: 'select',
                helpText: 'Controls the types of DD profiles',
                mandatory: true,
                isMulti: true,
                options: [
                    { label: 'Single App', value: 'SA' },
                    { label: 'Multi App', value: 'MA' }
                ]
            }
        ]
    },
    {
        label: 'iOS Devices',
        key: 'ios_devices',
        icon: 'ri-tablet-line',
        active: false,
        fields: [
            {
                label: 'Maximum device',
                value: 'MAX_IOS_DEVICE',
                path: ['IOS_DEVICES', 'settings', 'MAX_IOS_DEVICE'],
                inputType: 'input',
                change: 'max_device_page_change',
                helpText: 'Last page of the devices screen.',
                maxLength: 8,
                conditionalMandatory: true,
                mandatoryCondition: ['appleIOSEnabled', 'DEVICES']
            },
            {
                label: 'Device Actions & Monitoring Items',
                value: 'IOS_DEVICE_ACTIONS_MONITORING',
                path: ['IOS_DEVICES', 'settings', 'IOS_DEVICE_ACTIONS_MONITORING'],
                key: 'ios_device_actions',
                helpText: 'Controls the actions and monitoring items on iOS devices.',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'Reboot', value: 'REBOOT' },
                    { label: 'Lock', value: 'LOCK' },
                    { label: 'Shutdown', value: 'SHUTDOWN' },
                    { label: 'Lost Mode Sound', value: 'LOST_MODE_SOUND' },
                    { label: 'Lost Mode', value: 'LOST_MODE' },
                    { label: 'App Install', value: 'APP_INSTALL' },
                    { label: 'App Uninstall', value: 'APP_UNINSTALL' },
                    { label: 'Wallpaper', value: 'SET_WALLPAPER' },
                    { label: 'Activation Lock Bypass Code', value: 'ACTIVATION_LOCK' },
                    { label: 'Erase Device', value: 'ERASE_DEVICE' },
                    { label: 'Refresh', value: 'REFRESH' },
                    { label: 'Sync Policy', value: 'SYNC_POLICY' }
                ]
            },
            {
                label: 'Filter Panels',
                value: 'IOS_FILTER_PANELS',
                path: ['IOS_DEVICES', 'settings', 'IOS_FILTER_PANELS'],
                key: 'filter_panels',
                inputType: 'select',
                helpText: 'Controls the filter panels in the iOS devices screen',
                isMulti: true,
                options: [
                    { label: 'Device Status', value: 'device_status' },
                    { label: 'Groups', value: 'groups' },
                    { label: 'Policies', value: 'policies' }
                ]
            }
        ]
    },
    {
        label: 'Windows Policies',
        key: 'windows_policies',
        icon: 'ri-profile-fill',
        active: false,
        fields: [
            {
                label: 'Policy Types',
                value: 'WINDOWS_POLICY_TYPES',
                path: ['WINDOWS_POLICY', 'settings', 'policyTypes'],
                key: 'policy_types',
                inputType: 'select',
                helpText: 'Controls the types of profiles',
                isMulti: true,
                conditionalMandatory: true,
                mandatoryCondition: ['windowsEnabled', 'DEVICE_POLICY'],
                options: [
                    { label: 'Fully Managed Device', value: 'FM' },
                    { label: 'Work Profile', value: 'WP' }
                ]
            }
        ]
    },
    {
        label: 'Windows Devices',
        key: 'windows_devices',
        icon: 'ri-windows-fill',
        active: false,
        fields: [
            {
                label: 'Maximum device',
                value: 'MAX_WINDOWS_DEVICE',
                path: ['WINDOWS_DEVICES', 'settings', 'MAX_WINDOWS_DEVICE'],
                inputType: 'input',
                change: 'max_device_page_change',
                helpText: 'Last page of the devices screen.',
                maxLength: 8,
                conditionalMandatory: true,
                mandatoryCondition: ['windowsEnabled', 'DEVICES']
            },
            {
                label: 'Device Actions & Monitoring Items',
                value: 'WINDOWS_DEVICE_ACTIONS_MONITORING',
                path: ['WINDOWS_DEVICES', 'settings', 'WINDOWS_DEVICE_ACTIONS_MONITORING'],
                key: 'windows_device_actions',
                helpText: 'Controls the actions and monitoring items on Windows devices.',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'Reboot', value: 'REBOOT' },
                    { label: 'Erase Device', value: 'ERASE_DEVICE' },
                    { label: 'Sync', value: 'SYNC' },
                    { label: 'Refresh', value: 'REFRESH' },
                    { label: 'Unenroll Device', value: 'UNENROLL_DEVICE' }
                ]
            },
            {
                label: 'Filter Panels',
                value: 'WINDOWS_FILTER_PANELS',
                path: ['WINDOWS_DEVICES', 'settings', 'WINDOWS_FILTER_PANELS'],
                key: 'filter_panels',
                inputType: 'select',
                helpText: 'Controls the filter panels in the Windows devices screen',
                isMulti: true,
                options: [
                    { label: 'Device Status', value: 'device_status' },
                    { label: 'Groups', value: 'groups' },
                    { label: 'Policies', value: 'policies' }
                ]
            }
        ]
    },
    /*
     * {
     *     label: 'Device Automation',
     *     icon: 'ri-tools-fill',
     *     key: 'automation',
     *     active: false,
     *     fields: [
     *         {
     *             label: 'Automations Available',
     *             value: 'DEVICE_AUTOMATIONS_AVAILABLE',
     *             path: ['DEVICE_AUTOMATION', 'settings', 'features'],
     *             key: 'automations',
     *             helpText: 'Enables the fences available in automation screen.',
     *             inputType: 'select',
     *             conditionalMandatory: true,
     *             mandatoryCondition: ['DEVICE_AUTOMATION'],
     *             isMulti: true,
     *             options: [
     *                 { label: 'Policy Switch', value: 'POLICY_SWITCH' },
     *                 { label: 'Boundary Switch', value: 'BOUNDARY_SWITCH' },
     *                 { label: 'Switch Wallpaper', value: 'SWITCH_WALLPAPER' },
     *                 { label: 'Network Switch', value: 'NETWORK_SWITCH' },
     *                 { label: 'Group Switch', value: 'GROUP_SWITCH' }
     *             ]
     *         }
     *     ]
     * },
     */
    {
        label: 'Messages',
        key: 'messages',
        icon: 'ri-message-2-fill',
        active: false,
        fields: [
            {
                label: 'Enable Schedule Message',
                value: 'ENABLE_SCHEDULE_MESSAGE',
                path: ['MESSAGES', 'settings', 'ENABLE_SCHEDULE_MESSAGE'],
                helpText: 'Enables scheduled messages',
                inputType: 'toggle'
            },
            {
                label: 'Enable Recall',
                value: 'ENABLE_RECALL',
                path: ['MESSAGES', 'settings', 'ENABLE_RECALL'],
                helpText: 'Enables recall feature in messages',
                inputType: 'toggle'
            }
        ]
    },
    {
        label: 'Reports',
        icon: 'ri-file-chart-fill',
        key: 'reports',
        active: false,
        fields: [
            {
                label: 'Android Reports',
                value: 'ANDROID_REPORTS_CARDS',
                path: ['REPORTS', 'settings', 'ANDROID_REPORTS_CARDS'],
                helpText: 'Controls types of android reports',
                inputType: 'select',
                key: 'android_reports',
                conditionalMandatory: true,
                mandatoryCondition: ['androidEnterpriseEnabled', 'REPORTS'],
                isMulti: true,
                options: [
                    { label: 'Call logs', value: 'call_logs_reports' },
                    { label: 'All User Activity', value: 'user_activity_report' },
                    { label: 'App Versions Report', value: 'app_install' },
                    { label: 'Device Power Status', value: 'device_power_status' },
                    { label: 'Geo Fence', value: 'geo_fence_mobile' }
                ]
            },
            {
                label: 'TV Reports',
                value: 'TV_REPORTS_CARDS',
                path: ['REPORTS', 'settings', 'TV_REPORTS_CARDS'],
                helpText: 'Controls types of TV reports',
                key: 'tv_reports',
                inputType: 'select',
                conditionalMandatory: true,
                mandatoryCondition: ['androidTvEnabled', 'REPORTS'],
                isMulti: true,
                options: [
                    { label: 'Teachers Sessions', value: 'teachers_audit' },
                    { label: 'Geo Fence', value: 'geo_fence_tv' },
                    { label: 'App Installation', value: 'tv_app_installation' },
                    { label: 'Error Devices', value: 'tv_error_devices' },
                    { label: 'Power Status', value: 'tv_power_status' }
                ]
            }
        ]
    },
    {
        label: 'Admin',
        key: 'admin',
        icon: 'ri-shield-user-fill',
        active: false,
        fields: [
            {
                label: 'Admin Reports',
                value: 'ADMIN_REPORT_CARDS',
                path: ['ADMIN', 'settings', 'cards'],
                helpText: 'Controls reports in admin',
                key: 'admin_report_cards',
                inputType: 'select',
                conditionalMandatory: true,
                mandatoryCondition: ['ADMIN_REPORTS'],
                isMulti: true,
                options: [{ label: 'Login history', value: 'login_history' }]
            }
        ]
    }
];

export { masterTenantSchema };
