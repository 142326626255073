/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-depth */
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';
import { Tooltip, getChartsData } from '../../Components/Common/Util';
import NoRecordsFound from '../../Components/Common/NoRecordsFound';

const OnlineOfflineChart = (props) => {
    let dataColors = '["--EMM-green-graph1", "--EMM-green-graph2", "--EMM-green-graph3","--EMM-info","--EMM-green-1"]';

    const [onlineStatus, setOnlineStatus] = useState(props.onlineStatus);
    const [series, setSeries] = useState(props.series);
    const [labels, setLabels] = useState(props.labels);
    const [totalDevices, setTotalDevices] = useState(props.totalDevices);

    useEffect(() => {
        setSeries(props.series);
        setLabels(props.labels);
        setOnlineStatus(props.onlineStatus);
        setTotalDevices(props.totalDevices);
    }, [props]);

    var dountchartUserDeviceColors = getChartColorsArray(dataColors);
    var options = getChartsData({
        labels: labels,
        colors: dountchartUserDeviceColors,
        label: '0 to 30 Days',
        totalDevices: totalDevices,
        showTotal: true
    });
    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center fw-semibold d-flex gap-1 padding-y-25">
                    <h4 className="card-title mb-0">Device Online Summary</h4>
                    <i className="ri-information-line link-info" id={'info-summary'} />
                    {Tooltip(
                        'info-summary',
                        <div className="align-items-start">
                            <div className="word-wrap-break">
                                The number of devices connected to the internet is tracked over three time periods:
                            </div>
                            <div className="mt-2"> 0-7 days: Devices connected within the last 7 days.</div>
                            <div>0-15 days: Devices connected within the last 15 days.</div>
                            <div>0-30 days: Devices connected within the last 30 days.</div>
                        </div>
                    )}
                </CardHeader>

                <div className="bg-white">
                    <CardBody className="card-body">
                        {onlineStatus?.length > 0 ? (
                            <>
                                <div dir="ltr">
                                    <ReactApexChart
                                        className="apex-charts"
                                        series={series?.length > 0 ? series : []}
                                        options={options}
                                        type="donut"
                                        height={250}
                                    />
                                </div>
                                <div className="pt-2 ps-4 pe-4 bg-white">
                                    <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                                        {onlineStatus?.length > 0 &&
                                            onlineStatus.map((ele, index) => {
                                                return (
                                                    <li key={index} className="list-group-item px-0">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 ms-2">
                                                                <p className="fs-14 mb-0 d-flex align-items-center">
                                                                    <i
                                                                        className={`ri-checkbox-blank-circle-fill fs-14 align-middle text-${
                                                                            index % 5 === 0
                                                                                ? 'green-graph1'
                                                                                : index % 5 === 1
                                                                                ? 'green-graph2'
                                                                                : index % 5 === 2
                                                                                ? 'green-graph3'
                                                                                : index % 5 === 3
                                                                                ? 'info'
                                                                                : 'green-1'
                                                                        } me-1`}
                                                                    ></i>
                                                                    {ele.label}
                                                                </p>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <p className="fs-14 mb-0">{ele.count}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <NoRecordsFound />
                        )}
                    </CardBody>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default OnlineOfflineChart;
