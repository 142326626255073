import React from 'react';
import { Container, Card, Row, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import SearchOption from '../../Components/Common/SearchOption';
import TableContainer from '../../Components/Common/TableContainer';
import { useHistory } from 'react-router-dom';
import { tableData, details } from './TableSchema';
const LicensePayment = () => {
    const history = useHistory();
    const addLicense = () => {
        history.push('/license/add');
    };

    const handleClickView = (row) => {
        history.push('/license/view');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="License Management" pageTitle="License" />
                    <Card>
                        <Row>
                            <div className="d-flex justify-content-between align-items-center px-5">
                                <div className="col-3">
                                    <SearchOption />
                                </div>
                                <div>
                                    <Button className="bg-primary" onClick={addLicense}>
                                        + Add License
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    </Card>
                    <TableContainer
                        hideDisplayTotalCount={true}
                        data={[...details]}
                        columns={tableData}
                        customPageSize={5}
                        handleClickView={handleClickView}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LicensePayment;
