import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import Select from 'react-select';

const Actions = (props) => {
    const handleChange = (option, action, index, key) => {
        let slots = JSON.parse(JSON.stringify(props.array));
        action[key] = option;
        slots.splice(index, 1, action);
        props.validation.setValues({ ...props.validation.values, [props.key]: slots });
        if (option.value === 'NO_ACTION' || option.value === 'DISABLED') props.validation.values.timeSlots[index].policy = undefined;
    };

    const handleRemainingChange = (option, key) => {
        let remaining = JSON.parse(JSON.stringify(props.validation.values.remaining || {}));
        remaining[key] = option;
        if (key === 'action' && (option.value === 'NO_ACTION' || option.value === 'DISABLED'))
            props.validation.setValues({ ...props.validation.values, remaining: { [key]: option } });
        else props.validation.setValues({ ...props.validation.values, remaining: remaining });
    };
    const timeChanger = (time) => {
        return time
            ? Number(time?.split(':')?.[0]) < 12
                ? Number(time?.split(':')?.[0]) === 0
                    ? '12:00 AM'
                    : time + ' AM'
                : Number(time?.split(':')?.[0] - 12) === 0
                ? '12:00 PM'
                : Number(time?.split(':')?.[0] - 12) + ':00 PM'
            : time;
    };

    return (
        <React.Fragment>
            {props.array?.map((action, index) => (
                <Row key={props.index}>
                    <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Label className="mb-0 mt-2 fw-medium">
                            Period {index + 1} :{' '}
                            <span className="fw-medium text-primary">
                                {action?.startTime && timeChanger(action.startTime)}-{action?.endTime && timeChanger(action.endTime)}
                            </span>
                            <span className="ms-2 text-danger">*</span>
                        </Label>
                        <div className="text-muted font-size-12 mb-2">
                            Specify actions that occur when the device is within the defined time interval{' '}
                        </div>
                    </Col>

                    <Col
                        sm={action.action?.value === 'PolicySwitch' ? 6 : 12}
                        md={action.action?.value === 'PolicySwitch' ? 6 : 12}
                        lg={action.action?.value === 'PolicySwitch' ? 6 : 12}
                        xl={action.action?.value === 'PolicySwitch' ? 6 : 12}
                        xxl={action.action?.value === 'PolicySwitch' ? 6 : 12}
                    >
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            isDisabled={props.formType === 'view'}
                            options={[
                                { label: 'No Action', value: 'NO_ACTION' },
                                { label: 'Choose Policy', value: 'PolicySwitch' },
                                { label: 'Disable Device', value: 'DISABLED' }
                            ]}
                            placeholder="Select"
                            onChange={(selectedOption) => {
                                handleChange(selectedOption, action, index, 'action');
                            }}
                            value={action.action || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>

                    {action.action?.value === 'PolicySwitch' && (
                        <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <Select
                                getOptionValue={(option) => option.code}
                                getOptionLabel={(option) => option.name}
                                isDisabled={props.formType === 'view'}
                                options={props.policies}
                                placeholder="Select"
                                onChange={(selectedOption) => {
                                    handleChange(selectedOption, action, index, 'policy');
                                }}
                                value={action.policy || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col>
                    )}
                </Row>
            ))}
            <Row key={props.index}>
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Label className="mb-0 mt-2 fw-medium">
                        Remaining Hours
                        <span className="ms-2 text-danger">*</span>
                    </Label>
                    <div className="text-muted font-size-12 mb-2">
                        Specify actions that occur when the device is outside the defined time interval
                    </div>
                </Col>

                <Col
                    sm={props.validation.values?.remaining?.action?.value === 'PolicySwitch' ? 6 : 12}
                    md={props.validation.values?.remaining?.action?.value === 'PolicySwitch' ? 6 : 12}
                    lg={props.validation.values?.remaining?.action?.value === 'PolicySwitch' ? 6 : 12}
                    xl={props.validation.values?.remaining?.action?.value === 'PolicySwitch' ? 6 : 12}
                    xxl={props.validation.values?.remaining?.action?.value === 'PolicySwitch' ? 6 : 12}
                >
                    <Select
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        isDisabled={props.formType === 'view'}
                        options={[
                            { label: 'No Action', value: 'NO_ACTION' },
                            { label: 'Choose Policy', value: 'PolicySwitch' },
                            { label: 'Disable Device', value: 'DISABLED' }
                        ]}
                        placeholder="Select"
                        onChange={(selectedOption) => {
                            handleRemainingChange(selectedOption, 'action');
                        }}
                        value={props.validation.values?.remaining?.action || ''}
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                </Col>

                {props.validation.values.remaining?.action?.value === 'PolicySwitch' && (
                    <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <Select
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.name}
                            isDisabled={props.formType === 'view'}
                            options={props.policies}
                            placeholder="Select"
                            onChange={(selectedOption) => {
                                handleRemainingChange(selectedOption, 'policy');
                            }}
                            value={props.validation.values?.remaining?.policy || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
};

export default Actions;
