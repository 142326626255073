import React from 'react';
import { Button, Col, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import { Tooltip } from '../../../Components/Common/Util';

const inputComponent = (props) => {
    return (
        <>
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                <Label className="form-check-label fw-medium d-flex align-items-center">
                    {props.field.label}
                    {props.props.formType !== 'view' ? (
                        props.field.mandatory ? (
                            <span className="red-color ps-1">*</span>
                        ) : (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`inputComponent-${props.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                    {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        )
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                <div className={`input-group ${props.formType === 'view' ? '' : props.field.class ? props.field.class : ''}`}>
                    {props.formType !== 'view' ? (
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={props.field.value}
                                id={props.field.value}
                                className={`form-control ${props.field.class ? props.field.class : 'w-100'}`}
                                placeholder={`Enter ${props.field.label}`}
                                type={props.field.type}
                                maxLength={props.field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={props.validation.handleChange}
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values[props.field.value] || ''}
                                invalid={
                                    props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? true : false
                                }
                            />
                        </div>
                    ) : props.validation?.values[props.field.value] ? (
                        props.validation?.values[props.field.value]
                    ) : (
                        '–'
                    )}
                    {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                        <p className="m-0 mt-2 text-danger">{props.validation.errors[props.field.value]}</p>
                    ) : null}
                </div>
            </Col>
        </>
    );
};

const toggleButton = (props) => {
    return (
        <>
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                <Col>
                    <Label className="form-check-label fw-medium d-flex align-items-center">
                        {props.field.label}
                        <i
                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                            id={`toggleButton-${props.index}`}
                        />
                        <UncontrolledTooltip placement="bottom" target={`toggleButton-${props.index}`}>
                            {props.field.helpText ? props.field.helpText : 'Helper Text'}
                        </UncontrolledTooltip>
                    </Label>
                    <div className="fs-12 text-muted">{props.field.helpText}</div>
                </Col>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                {props.formType !== 'view' ? (
                    <div className="form-check form-switch form-switch-lg">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={props.field.value}
                            id={props.field.value}
                            checked={props.validation.values[props.field.value] || false}
                            onChange={props.validation.handleChange}
                            onBlur={props.validation.handleBlur}
                        />
                    </div>
                ) : props.validation?.values[props.field.value] ? (
                    'Yes'
                ) : (
                    'No'
                )}
            </Col>
        </>
    );
};

const uploadButton = (props) => {
    return (
        <>
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                <Label className="form-check-label fw-medium d-flex align-items-center">
                    {props.field.label}
                    {props.formType !== 'view' ? (
                        props.field.mandatory ? (
                            <span className="red-color ps-1">*</span>
                        ) : (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`inputComponent-${props.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                    {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        )
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                <Col sm="auto">
                    {props.formType === 'view' ? (
                        <span className="fw-medium link-secondary cursor-pointer" onClick={() => props.onClickImage(props.field)}>
                            {props.validation.values[props.field.value]}
                        </span>
                    ) : props.validation?.values?.[props.field.value] ? (
                        <div className="d-flex align-items-center jsutify-content-center">
                            {props.validation.values?.[props.field.value]}
                            {props.validation.values?.[props.field.value] && (
                                <i
                                    className="ri-close-line link-primary cursor-pointer fs-20 ms-3"
                                    onClick={() => props.validation.setValues({ ...props.validation.values, [props.field.value]: null })}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="d-flex">
                            <Button
                                type="button"
                                color="primary"
                                className="py-1 my-0"
                                id="fileUpload"
                                onClick={() => props.uploadDoc(props.field)}
                            >
                                <span className="d-flex align-items-center">
                                    <span className="fs-14">Upload</span>
                                    <i className="ri-upload-2-line ms-2 fs-18" />
                                </span>
                            </Button>
                            {Tooltip('fileUpload', 'File Upload')}
                            <input
                                hidden
                                id="file"
                                type="file"
                                accept={'image/*'}
                                ref={(file) => props.setFileUploader(file)}
                                onChange={(ev) => props.handleUpload(ev, 'file', props.field)}
                                onClick={(ev) => (ev.target.value = null)}
                            />
                        </div>
                    )}
                </Col>
            </Col>
        </>
    );
};

const selectComponent = (props) => {
    return (
        <div className="mb-2 mb-lg-0">
            <Row>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                    <Label className="form-label d-flex align-items-center fw-medium">
                        {props.field.label}
                        <i className="ri-information-line blue-icon ps-1" id={`UncontrolledTooltipExample-${props.index}`} />
                        <UncontrolledTooltip placement="bottom" target={`UncontrolledTooltipExample-${props.index}`}>
                            {props.field.helpText ? props.field.helpText : 'Helper Text'}
                        </UncontrolledTooltip>
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                    <div className="d-flex align-items-center">
                        {props.formType !== 'view' ? (
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                isMulti={props.field.isMulti ? true : false}
                                isClearable={props.field.isMulti ? true : false}
                                id={props.field.label}
                                className="w-100"
                                name={props.field.value}
                                options={props.field.options}
                                placeholder={`Select ${props.field.label}`}
                                onBlur={() => props.validation.handleBlur({ target: { name: props.field.value } })}
                                onChange={(selectedOption) =>
                                    props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } })
                                }
                                value={props.validation.values[props.field.value] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                            props.validation?.values?.[props.field.value]
                                ?.map((v) => v.label)
                                ?.toString()
                                ?.split(',')
                                ?.join(', ')
                        ) : props.validation?.values[props.field.value]?.label ? (
                            props.validation?.values[props.field.value]?.label
                        ) : props.validation?.values[props.field.value] ? (
                            props.validation?.values[props.field.value]
                        ) : (
                            '–'
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const inputDropdownComponent = (props) => {
    return (
        <div className="mb-2 mb-lg-0">
            <Row>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                    <Label className="form-check-label fw-medium d-flex align-items-center">
                        {props.field.label}
                        <i
                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                            id={`inputComponent-${props.index}`}
                        />
                        <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                            {props.field.helpText ? props.field.helpText : 'Helper Text'}
                        </UncontrolledTooltip>
                        {props.formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                    {props.formType !== 'view' ? (
                        <Row>
                            <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Input
                                            name={props.field.quantity}
                                            id={props.field.quantity}
                                            className={`form-control ${props.field.class ? props.field.class : 'w-100'}`}
                                            placeholder={`Enter ${props.field.label}`}
                                            type={props.field.type}
                                            maxLength={props.field.maxLength}
                                            validate={{ required: { value: true } }}
                                            onChange={props.validation.handleChange}
                                            onBlur={props.validation.handleBlur}
                                            value={props.validation.values[props.field.quantity] || ''}
                                            invalid={
                                                props.validation.touched[props.field.quantity] &&
                                                props.validation.errors[props.field.quantity]
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <p className="m-0 mt-2 font-size-10 text-danger">
                                            {props.validation.errors?.[props.field.quantity]}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                <div className="d-flex align-items-center">
                                    <Select
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                        isMulti={props.field.isMulti ? true : false}
                                        isClearable={props.field.isMulti ? true : false}
                                        id={props.field.label}
                                        className="w-100"
                                        name={props.field.unit}
                                        options={props.field.options}
                                        placeholder={`Select ${props.field.label}`}
                                        onBlur={() => props.validation.handleBlur({ target: { name: props.field.unit } })}
                                        onChange={(selectedOption) =>
                                            props.validation.handleChange({ target: { name: props.field.unit, value: selectedOption } })
                                        }
                                        value={props.validation.values[props.field.unit] || ''}
                                        isSearchable={true}
                                        noOptionsMessage={() => 'No data found'}
                                    />
                                </div>
                            </Col>
                        </Row>
                    ) : props.validation?.values[props.field.quantity] ? (
                        props.validation?.values[props.field.quantity] +
                        ' ' +
                        (props.validation?.values?.[props.field.unit]?.length > 0
                            ? props.validation?.values?.[props.field.unit]
                                  ?.map((v) => v.label)
                                  ?.toString()
                                  ?.split(',')
                                  ?.join(', ')
                            : props.validation?.values[props.field.unit]?.label
                            ? props.validation?.values[props.field.unit]?.label
                            : props.validation?.values[props.field.unit]
                            ? props.validation?.values[props.field.unit]
                            : '–')
                    ) : (
                        '–'
                    )}
                </Col>
            </Row>
        </div>
    );
};

export const dynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props.field.inputType === 'input'
                ? inputComponent(props)
                : props.field.inputType === 'toggle'
                ? toggleButton(props)
                : props.field.inputType === 'upload'
                ? uploadButton(props)
                : props.field.inputType === 'select'
                ? selectComponent(props)
                : props.field.inputType === 'input_dropdown'
                ? inputDropdownComponent(props)
                : props.field.isHeader && (
                      <div className="fw-semibold text-decoration-underline text-success fs-14 mb-2">{props.field.label} :</div>
                  )}
        </React.Fragment>
    );
};
