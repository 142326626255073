import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { getDate, getFormTypeAndRecordId, timeSort } from '../../../Components/Common/Util';
import polDelete from '../../../assets/images/poldelete.png';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { PROTECTION_POLICY, TT_PROTECTION_POLICY, policiesArray } from './Schema';

const ProtectionPolicies = (props) => {
    document.title = 'App Protection Policies';
    const urlconf = useEnv();
    const history = useHistory();
    const api = new APIClient();
    const [addAppModal, setAddAppModal] = useState(false);
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [policyId, setPolicyId] = useState('');
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const osType = formTypeAndId.recordID;
    const [policies, setPolicies] = useState([]);
    const [configuration, setConfiguration] = useState({});
    const [selectedRow, setSelectedRow] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [type, setType] = useState({
        label: 'Android',
        value: 'android',
        url: formTypeAndId.recordID === 'tectoro' ? url.TT_APP_PROTECTION : url.MS_APP_PROTECTION
    });

    useEffect(() => {
        if (props.selectedTab === 'appProtection') {
            props.setLoading(true);
            getPolicies(formTypeAndId.recordID === 'tectoro' ? url.TT_APP_PROTECTION : url.MS_APP_PROTECTION);
        }
    }, [props.selectecTab]);

    const getPolicies = (apiUrl) => {
        const policiesPromise = new Promise((resolve, reject) => {
            api.get(apiUrl, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp);
                    else reject('Policy failed');
                })
                .catch((err) => reject(err));
        });

        Promise.allSettled([policiesPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    result[0].value.data?.forEach((data) => {
                        data.rowView = true;
                        data.modifiedBy = data.modifiedby || '—';
                        data.platform = formTypeAndId.recordID === 'tectoro' ? 'android' : data.platform;
                        data.appsCount = data.deployedAppCount || data.apps?.length || 0;
                        data.createdDate = data.createdDateTime ? getDate(data.createdDateTime) : '—';
                        data.modifiedDate = data.modifieddate
                            ? getDate(data.modifieddate)
                            : data.lastModifiedDateTime
                            ? getDate(data.lastModifiedDateTime)
                            : '—';
                    });
                    setPolicies(timeSort(result[0].value?.data || [], 'modifiedDate') || []);
                }
                props.setLoading(false);
            })
            .catch((err) => props.setLoading(false));
    };

    const handleClickView = (row) => {
        history.push(`/appprotectionpolicy/${osType}/${row.original?.platform?.toLowerCase()}/edit/${row.original._id}`);
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        props.setLoading(true);
        setShowDeleteModal(false);
        api.delete(type.url + '/' + selectedRow?._id, '', domains.MDM_COMMONS)
            .then((resp) => {
                setSelectedRow('');
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.appProtecitonDeleted);
                    getPolicies(type.url);
                } else props.setLoading(false);
            })
            .catch((err) => props.setLoading(false));
    };

    const deleteMessage = () => {
        return (
            <div className="p-5">
                <div className="mb-3 fw-medium">Are you sure you want to remove server?</div>
                <div className="fs-13 text-start bg-light py-2 px-4">
                    <span className="fw-medium">Note :</span>
                    <span className=" fs-10 text ps-1">
                        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of
                        a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the
                        final copy is
                    </span>
                </div>
            </div>
        );
    };

    const deletePolicyMessage = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete this policy?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.name}</span>
                </div>
            </div>
        );
    };

    const handleModalBody = () => {
        let pArr = policiesArray.filter((ele) => ele.id !== 'ios');
        let arr = formTypeAndId.recordID === 'tectoro' ? pArr : policiesArray;
        return (
            <div>
                <Row className="d-flex align-items-center justify-content-evenly">
                    {arr.map((ele, index) => {
                        return (
                            <Card
                                key={index}
                                className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-220 height-220 cursor-pointer
									${ele.id === policyId ? 'border-2 border-primary' : ''}`}
                                onClick={() => setPolicyId(ele.id)}
                            >
                                <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                    {ele.id === policyId && (
                                        <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                    )}
                                    <div>
                                        <img src={ele.img} alt="" height={60} width={60} />
                                    </div>
                                    <p className="fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                    <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                </CardBody>
                            </Card>
                        );
                    })}
                </Row>
            </div>
        );
    };

    const handlePlatformChange = (option) => {
        props.setLoading(true);
        setType(option);
        getPolicies(option.url);
    };

    return (
        <React.Fragment>
            <TableContainer
                isGlobalFilter={true}
                tableHeader={'App Protection'}
                // searchFilter2={true}
                dropDownFilter2={true}
                dropDownParams2={{
                    label: 'Platform',
                    dropdownVal: {
                        label: 'Android',
                        value: 'android',
                        url: formTypeAndId.recordID === 'tectoro' ? url.TT_APP_PROTECTION : url.MS_APP_PROTECTION
                    },
                    options:
                        formTypeAndId.recordID !== 'tectoro'
                            ? [
                                  {
                                      label: 'Android',
                                      value: 'android',
                                      url: formTypeAndId.recordID === 'tectoro' ? url.TT_APP_PROTECTION : url.MS_APP_PROTECTION
                                  },
                                  { label: 'iOS/iPadOs', value: 'ios', url: url.APPLE_APP_PROTECTION }
                              ]
                            : [
                                  {
                                      label: 'Android',
                                      value: 'android',
                                      url: formTypeAndId.recordID === 'tectoro' ? url.TT_APP_PROTECTION : url.MS_APP_PROTECTION
                                  }
                              ]
                }}
                handleSelectChange2={handlePlatformChange}
                addButton={true}
                columns={formTypeAndId.recordID === 'tectoro' ? TT_PROTECTION_POLICY : PROTECTION_POLICY}
                pageNum={searchParams.page}
                handleClickView={handleClickView}
                handleDelete={handleDelete}
                data={policies}
                handleAddNew={() => setShowPolicyModal(true)}
                customPageSize={10}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
            <DeleteModal
                size="lg"
                show={addAppModal}
                hideIcon={true}
                confirmation={true}
                onDeleteClick={() => {
                    setAddAppModal(false);
                    history.push('/appprotectionpolicy');
                }}
                hideDeleteMessage={true}
                hideDeleteIcon={true}
                confirmText="Yes"
                cancelText="No"
                message={deleteMessage()}
                onCloseClick={() => setAddAppModal(false)}
            />
            <DeleteModal
                size="md"
                show={showDeleteModal}
                hideIcon={true}
                confirmation={true}
                onDeleteClick={handleDeleteConfirm}
                message={deletePolicyMessage()}
                onCloseClick={() => {
                    setShowDeleteModal(false);
                    setSelectedRow('');
                }}
                confirmText="Yes, Delete it!"
                hideDeleteMessage={true}
                hideDeleteIcon={true}
            />
            <CommonModal
                size={'md'}
                show={showPolicyModal}
                modalheader={'Add Policy'}
                onCloseClick={() => {
                    setPolicyId('');
                    setShowPolicyModal(false);
                }}
                saveText={'Proceed'}
                cancelText={'Cancel'}
                disabled={!policyId}
                handleClick={() => {
                    history.push(`/appprotectionpolicy/${osType}/${policyId}/add`);
                    setShowPolicyModal(false);
                }}
                handleModalBody={handleModalBody}
            />
        </React.Fragment>
    );
};

export default ProtectionPolicies;
