import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { getDate, Tooltip } from '../../../Components/Common/Util';
import { bussinessCertiData } from '../../../Components/constants/constants';

const ABMCard = (props) => {
    const [data, setData] = useState([...props.data]);

    useEffect(() => {
        data.forEach((ele) => {
            ele.status = ele.status === 'A' || ele.status === 'Active' ? 'Active' : 'Inactive';
        });
        setData([...data]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    return (
        <>
            {data.map((obj, ind) => {
                return (
                    <Card className="border" key={ind}>
                        <CardHeader className="border-bottom-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="fs-12 d-flex">
                                        <div className="text-muted">Last Synced -</div>
                                        <div className="fw-medium">{getDate(obj.lastsync ? obj.lastsync : new Date())}</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <div>
                                        <i className="ri-information-line d-flex align-items-center blue-icon fs-15" id={'ABM_Renew'} />
                                        {Tooltip(
                                            'ABM_Renew',
                                            'The certificate is valid for one year from its date of creation. Renewing certificate is similar to generating a new certificate. Select RENEW and follow the same steps for generating server token for MDM certificate.'
                                        )}
                                    </div>
                                    <div
                                        className="text-primary text-decoration-underline px-1 fs-12 fw-medium cursor-pointer"
                                        onClick={() => props.handleAction('change', obj._id)}
                                    >
                                        Renew Certificate
                                    </div>
                                    <div>
                                        <i
                                            id={'ABM_Edit'}
                                            className="ri-edit-box-line px-1 fs-16 text-secondary cursor-pointer"
                                            onClick={() => props.handleAction('change', obj._id)}
                                        ></i>
                                        {Tooltip('ABM_Edit', 'Edit')}
                                    </div>
                                    <div>
                                        <i
                                            id={'ABM_Sync'}
                                            className="ri-refresh-line px-1 fs-16 text-secondary cursor-pointer"
                                            onClick={() => props.handleAction('sync', obj._id)}
                                        ></i>
                                        {Tooltip('ABM_Sync', 'Sync')}
                                    </div>
                                    <div>
                                        <i
                                            id={'ABM_Delete'}
                                            className="ri-delete-bin-6-line px-1 fs-16 text-danger cursor-pointer"
                                            onClick={() => props.handleAction('delete', obj._id)}
                                        ></i>
                                        {Tooltip('ABM_Delete', 'Delete')}
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {bussinessCertiData.map((ele, index) => {
                                    return (
                                        <Col className="col-6 mb-2" key={index}>
                                            <div className="p-0">
                                                <div className="fw-medium py-1">{ele.label}</div>
                                                <div className="text-muted d-flex justify-content-start align-items-center">
                                                    {ele.type === 'date' ? getDate(obj[ele.key]) : obj[ele.key] ? obj[ele.key] : '—'}
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </CardBody>
                    </Card>
                );
            })}
        </>
    );
};

export default ABMCard;
