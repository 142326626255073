/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Card, CardBody, Col, Form, Input, Label, Row, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, TenantConfig, convertUTCtoIST, profileTypeLabel } from '../../../Components/Common/Util';
import { policiesArr } from '../../../Components/constants/commons';
import { priviliges } from '../../../Components/constants/constants';
import polClone from '../../../assets/images/clonePolicy.png';
import polDelete from '../../../assets/images/poldelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { policiesSchema } from './TableSchema';

const Policies = (props) => {
    document.title = 'Policy';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const [policiesTableSchema, setPoliciesTableSchema] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10000);
    const [totalRecords, setTotalRecords] = useState(0);
    const [qrCodeString, setQRCodeString] = useState('');
    const [policyType, setPolicyType] = useState('');
    const [signInEnrollment, setSignInEnrollment] = useState('NOT REQUIRED');
    const [policyCards, setPolicyCards] = useState(JSON.parse(JSON.stringify(policiesArr)));
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');

    useEffect(() => {
        localStorage.removeItem('policyNameForTokens');
        getPolicies();
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.POLICY_EDITOR);
        let schema = [...policiesSchema];
        if (findPriv) schema = policiesSchema.filter((policy, index) => index < policiesSchema.length - 2);
        setPoliciesTableSchema([...schema]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPolicies = (paramsObj) => {
        setLoading(true);
        props.setLoading(true);
        let pURL = url.POLICIES;
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { page: pageNumber, size: pageSize };
        api.get(pURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((data) => {
                        data.hideQR = data.status === 'InActive';
                        data.hideToken = data.status === 'InActive';
                        data.doNotDelete = data.deviceCount > 0;
                        data.disabled = data.deviceCount > 0;
                        data.protectedPolicy = !!data.config?.protectedPolicy;
                        data.icon = !!data.config?.protectedPolicy ? 'ri-lock-2-fill' : undefined;
                        data.iconTooltip = !!data.config?.protectedPolicy ? 'This policy is protected' : undefined;
                        data.iconClass = !!data.config?.protectedPolicy ? 'link-primary' : 'link-success';
                        data.icon2 = !!data.inTimeFence && data.inTimeFence !== null ? 'ri-settings-5-line' : undefined;
                        data.icon2Tooltip =
                            !!data.inTimeFence && data.inTimeFence !== null ? `This policy is in ${data.inTimeFence}` : undefined;
                        data.iconClass2 = !!data.inTimeFence && data.inTimeFence !== null ? 'link-primary' : 'link-success';
                        data.badgeClass = !!data.config?.protectedPolicy ? 'warning' : 'success';
                        data.policyVersion = data.version ? data.version : '—';
                        data.polType = profileTypeLabel(data.policyType?.toUpperCase());
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        let formattedDate = data.lastmodifiedTime ? convertUTCtoIST(data.lastmodifiedTime) : '—';
                        data.modifiedDate = formattedDate;
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                }
                setLoading(false);
                props.setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const handleAddNew = () => {
        setPolicyType('');
        let PolicyArray = policiesArr;
        let configArr = [];
        PolicyArray.forEach((ele) => {
            if (tenantConfig?.COMPANY_POLICY_ACCESS?.join()?.includes(ele.id)) configArr.push(ele);
        });
        setPolicyCards(configArr);
        setShowPolicyModal(true);
    };

    const handleEdit = (row) => {
        if (row.original.protectedPolicy) {
            setSelectedRow(row);
            setShowPasswordModal(true);
        } else history.push('/aepolicies/edit/' + row.original.id + '/' + row.original.policyType);
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleDeleteConfirmation = () => {
        setPassword('');
        if (selectedRow?.original?.protectedPolicy && password !== tenantConfig.DEVICE_DEFAULT_PASSWORD) {
            toast.error(toastMessages.invalidPassword);
            return;
        }
        setLoading(true);
        props.setLoading(true);
        setDeleteModal(false);
        api.delete(url.POLICIES + '/' + selectedRow.original.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyDeleted);
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const handleQR = (row) => {
        if (signInEnrollment === 'NOT REQUIRED') {
            setLoading(true);
            props.setLoading(true);
            setSelectedRow(row);
            api.get(url.POLICIES + url.QR_CODE + '?id=' + row.original.id + `&signIn=${signInEnrollment === 'REQUIRED' ? true : false}`)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(tenantConfig.ENABLE_SIGN_IN ? toastMessages.qrGeneratedWithoutSignIn : toastMessages.qrGenerated);
                        setShowQRModal(true);
                        setQRCodeString(resp.data);
                        setLoading(false);
                        props.setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    props.setLoading(false);
                });
        } else {
            setSelectedRow(row);
            setShowQRModal(true);
        }
    };

    const handleClone = (row) => {
        validation.resetForm();
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const onClickSwitch = (row) => {
        setLoading(true);
        props.setLoading(true);
        let rowData = JSON.parse(JSON.stringify(row.original));
        let obj = {
            id: rowData.id,
            status: rowData.status?.toLowerCase() === 'active' ? 'InActive' : 'Active',
            name: rowData.name,
            code: rowData.code,
            enterpriseId: rowData.enterpriseId,
            policy: rowData.policy
        };
        api.update(url.POLICIES + '/' + row?.original?.id, obj)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyStatusUpdated);
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const handleEnrollmentToken = (row) => {
        localStorage.setItem('policyNameForTokens', row.original?.name);
        history.push('/aepolicies/enrollmenttoken/' + row.original.id + '/' + row.original.policyType);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { code: '' },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            setLoading(true);
            props.setLoading(true);
            api.create(url.CLONE + '/' + selectedRow?.original?.id + '/' + selectedRow.original.policyType)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(toastMessages.policyCloned);
                        validation.resetForm();
                        setShowCloneModal(false);
                        getPolicies();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setShowCloneModal(false);
                    props.setLoading(false);
                });
        }
    });

    const handlePolicyTypeClick = (item) => {
        setPolicyType(item);
        if (item.childPolicies?.length > 0) {
            let configArr = [];
            item.childPolicies.forEach((ele) => {
                if (tenantConfig?.EMM_DD_POLICY_TYPES?.join()?.includes(ele.id)) configArr.push(ele);
            });
            setPolicyCards(configArr);
        }
    };

    const handleCloneModalBody = () => {
        return (
            <Form
                className="h-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to clone this Policy?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={polClone} alt="clonepolicy" width={60} height={60} />
                    </div>
                    <div>
                        Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                    </div>
                </div>
            </Form>
        );
    };
    const handleQrScan = (type) => {
        setLoading(true);
        props.setLoading(true);
        api.get(url.POLICIES + url.QR_CODE + '?id=' + selectedRow.original.id + `&signIn=${type === 'REQUIRED' ? true : false}`)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(type !== 'REQUIRED' ? toastMessages.qrGeneratedWithoutSignIn : toastMessages.qrGeneratedWithSignIn);
                    setQRCodeString(resp.data);
                    setLoading(false);
                    props.setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };
    const generatingQrCode = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={5} lg={5}>
                        <Label className="fw-medium my-2 fs-16">Sign In Enrollment </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={7} lg={7}>
                        <div className="mb-4 d-flex align-items-center justify-content-center w-100 ">
                            <div className="p-1 d-flex align-items-center border border-1 fs-11 border-info rounded-3 w-90 justify-content-center">
                                <div
                                    className={`${
                                        signInEnrollment !== 'REQUIRED' ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-70 px-4 py-2 cursor-pointer text-center`}
                                    onClick={() => {
                                        setQRCodeString('');
                                        setSignInEnrollment('NOT REQUIRED');
                                        handleQrScan('NOT REQUIRED');
                                    }}
                                >
                                    NOT REQUIRED
                                </div>
                                <div
                                    className={`${
                                        signInEnrollment === 'REQUIRED' ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-50 px-4 py-2 cursor-pointer text-center`}
                                    onClick={() => {
                                        setQRCodeString('');
                                        setSignInEnrollment('REQUIRED');
                                        handleQrScan('REQUIRED');
                                    }}
                                >
                                    REQUIRED
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const handleQRModalBody = () => {
        return (
            <Row className="d-flex align-items-center justify-content-center">
                {tenantConfig?.ENABLE_SIGN_IN && <div className="mb-2">{generatingQrCode()}</div>}
                <div className="text-center">
                    <h5 className="text-primary">QR Code</h5>
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        {qrCodeString ? 'Please scan for the device enrollment' : 'QR code is being generated...'}
                    </Alert>
                </div>

                <div className={`d-flex align-items-center justify-content-center flex-wrap ${qrCodeString ? '' : 'mt-3'}`}>
                    {qrCodeString ? (
                        <img alt="" className="w-75" src={`data:image/png;base64,${qrCodeString}`} />
                    ) : (
                        <Spinner color="primary" className="spinner-style" />
                    )}
                </div>
            </Row>
        );
    };

    const qrHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Policy :</Label>
                <p className="p-0 m-0 mt-3 ms-2 fw-normal">{selectedRow?.original?.name}</p>
            </div>
        );
    };

    const handleBackToMainMenu = () => {
        let PolicyArray = policiesArr;
        let configArr = [];
        PolicyArray.forEach((ele) => {
            tenantConfig?.COMPANY_POLICY_ACCESS?.forEach((item) => {
                if (ele.id?.toUpperCase() === item) {
                    configArr.push(ele);
                }
            });
        });
        setPolicyCards(configArr);
        setPolicyType('');
    };

    const handlePolicyModalBody = () => {
        return (
            <div>
                {(policyType.childPolicies?.length > 0 || policyType.id === 'SA' || policyType.id === 'KL' || policyType.id === 'TL') && (
                    <div className="d-flex align-items-center cursor-pointer link-primary mb-2" onClick={handleBackToMainMenu}>
                        <i className="ri-arrow-left-line me-2" />
                        Back
                    </div>
                )}
                <Row className="d-dlex align-items-center justify-content-evenly">
                    {policyCards?.length > 0 &&
                        policyCards.map((ele, index) => {
                            return (
                                <Card
                                    key={index}
                                    className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-250 height-250 cursor-pointer ${
                                        ele.id === policyType.id ? 'border-2 border-primary' : ''
                                    }`}
                                    onClick={() => handlePolicyTypeClick(ele)}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                        {ele.id === policyType.id && (
                                            <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                        )}
                                        <div>
                                            <img src={ele.img} alt="" height={60} width={60} />
                                        </div>
                                        <p className="text-uppercase fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                        <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                    </CardBody>
                                </Card>
                            );
                        })}
                </Row>
            </div>
        );
    };

    const handleClickView = (row) => {
        history.push('/aepolicies/view/' + row.original.id + '/' + row.original.policyType);
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const verifyPassword = () => {
        if (tenantConfig.DEVICE_DEFAULT_PASSWORD === password)
            history.push('/aepolicies/edit/' + selectedRow.original.id + '/' + selectedRow.original.policyType);
        else toast.error(toastMessages.invalidPassword);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Policy?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                </div>
                {selectedRow?.original?.protectedPolicy && (
                    <div className="mt-4 d-flex align-items-center justify-content-center">
                        <Input
                            name={'passwordPolicy'}
                            type={'text'}
                            className="w-50"
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password || ''}
                        />
                    </div>
                )}
            </div>
        );
    };

    const handlePasswordBody = () => {
        return (
            <Row>
                <Col sm={4} className="d-flex align-items-center">
                    <Label className="form-label m-0 p-0 d-flex align-items-center">
                        Enter Password <span className="text-danger">*</span>
                    </Label>
                </Col>
                <Col>
                    <div className="input-group">
                        <div className="w-100">
                            <input
                                id="policyPassword"
                                name="policyPassword"
                                className="form-control"
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password || ''}
                                // invalid={validation1?.touched['notes'] && validation1?.errors['notes'] ? true : false}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <React.Fragment>
            <TableContainer
                loading={loading}
                isGlobalFilter={true}
                addButton={true}
                largeSearchFilter={true}
                SearchFilterWidth={'width-280'}
                searchFilter2={true}
                searchPlaceHolder={'Policy Code or Name'}
                columns={policiesTableSchema}
                handleGlobalSearch={handleGlobalSearch}
                handleQR={handleQR}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleAddNew={handleAddNew}
                handleClickViewDetails={handleClickView}
                handleClone={handleClone}
                onClickSwitch={onClickSwitch}
                totalRecords={totalRecords}
                handleEnrollmentToken={handleEnrollmentToken}
                data={policies}
                editor={priviliges.POLICY_EDITOR}
                reader={priviliges.POLICY_READER}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
            <DeleteModal
                hideIcon={true}
                show={deleteModal}
                hideDeleteMessage={true}
                message={message()}
                disabled={selectedRow?.original?.protectedPolicy ? password === '' || !password : false}
                onDeleteClick={handleDeleteConfirmation}
                onCloseClick={() => {
                    setDeleteModal(false);
                    setPassword('');
                }}
            />
            <DeleteModal
                hideIcon={true}
                show={showCloneModal}
                hideDeleteMessage={true}
                message={handleCloneModalBody()}
                onDeleteClick={validation.handleSubmit}
                confirmText="Yes, Clone it"
                hideDeleteIcon={true}
                onCloseClick={() => {
                    setShowCloneModal(false);
                    validation.resetForm();
                }}
            />
            <CommonModal
                size={'md'}
                show={showQRModal}
                disabled={false}
                hideSaveButton={true}
                modalheader={qrHeader()}
                onCloseClick={() => {
                    setShowQRModal(false);
                    setSignInEnrollment('NOT REQUIRED');
                }}
                handleClick={() => setShowQRModal(false)}
                handleModalBody={() => handleQRModalBody()}
            />
            <CommonModal
                size={'lg'}
                disabled={!policyType?.id || policyType?.id === '' || policyType?.id === 'DD'}
                cancelText={'Cancel'}
                saveText={'Proceed'}
                show={showPolicyModal}
                modalheader={'Create New Policy'}
                onCloseClick={() => {
                    setShowPolicyModal(false);
                    setPolicyType('');
                }}
                handleClick={() => {
                    history.push('/aepolicies/add/' + policyType.id);
                    setShowPolicyModal(false);
                }}
                handleModalBody={() => handlePolicyModalBody()}
            />
            <CommonModal
                size={'md'}
                disabled={!password || password === ''}
                cancelText="Cancel"
                saveText="Verify"
                show={showPasswordModal}
                modalheader="Verify Policy Edit"
                onCloseClick={() => {
                    setShowPasswordModal(false);
                    setSelectedRow('');
                    setPassword('');
                }}
                handleClick={verifyPassword}
                handleModalBody={handlePasswordBody}
            />
        </React.Fragment>
    );
};

export default Policies;
