import React from 'react';

const plus = (props) => (
    <svg {...props} x="0px" y="0px" viewBox="0 0 408 408" fill="#eed053" fontSize={'15px'}>
        <path
            d="M372,88.661H206.32l-33-39.24c-0.985-1.184-2.461-1.848-4-1.8H36c-19.956,0.198-36.023,16.443-36,36.4v240
        c-0.001,19.941,16.06,36.163,36,36.36h336c19.94-0.197,36.001-16.419,36-36.36v-199C408.001,105.08,391.94,88.859,372,88.661z"
        />
    </svg>
);

const minus = (props) => (
    <svg {...props} x="0px" y="0px" viewBox="0 0 408 408" fill="#eed053" fontSize={'15px'}>
        <g>
            <g>
                <path
                    d="M367.731,112.653H40.291c-22.269,0.132-40.258,18.21-40.28,40.48c-0.015,0.226-0.015,0.454,0,0.68l23.4,174.6
			c0.284,22.16,18.318,39.98,40.48,40h280.4c22.161-0.02,40.196-17.84,40.48-40l23.24-174.6c0.015-0.226,0.015-0.454,0-0.68
			C407.99,130.863,390.001,112.785,367.731,112.653z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M337.851,72.333h-131.52l-26-30.92c-0.985-1.184-2.461-1.848-4-1.8H70.171c-16.559,0.022-29.978,13.441-30,30v28.84h10
			h317.4C365.624,83.521,352.909,72.347,337.851,72.333z"
                />
            </g>
        </g>
    </svg>
);

const closeIcon = (props) => {
    delete props.style.opacity;
    delete props.style.cursor;
    return (
        <svg {...props} x="0px" y="0px" viewBox="0 0 48 48" fill="#878a99" fontSize={'15px'}>
            <path d="M37,47H11c-2.209,0-4-1.791-4-4V5c0-2.209,1.791-4,4-4h18.973  c0.002,0,0.005,0,0.007,0h0.02H30c0.32,0,0.593,0.161,0.776,0.395l9.829,9.829C40.84,11.407,41,11.68,41,12l0,0v0.021  c0,0.002,0,0.003,0,0.005V43C41,45.209,39.209,47,37,47z M31,4.381V11h6.619L31,4.381z M39,13h-9c-0.553,0-1-0.448-1-1V3H11  C9.896,3,9,3.896,9,5v38c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V13z M33,39H15c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h18  c0.553,0,1,0.448,1,1C34,38.553,33.553,39,33,39z M33,31H15c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h18c0.553,0,1,0.448,1,1  C34,30.553,33.553,31,33,31z M33,23H15c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h18c0.553,0,1,0.448,1,1C34,22.553,33.553,23,33,23  z" />
        </svg>
    );
};

export { plus, minus, closeIcon };
