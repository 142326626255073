import React, { useEffect, useState } from 'react';
import Tree from 'react-animated-tree-v2';
import { closeIcon, minus, plus } from './Icons';
import { Tooltip } from './Util';

const AnimatedTreeComponent = (props) => {
    const [groups, setGroups] = useState([...props.groups]);
    const [flatGroups, setFlatGroups] = useState([...props.flatGroups]);

    useEffect(() => {
        setGroups(props.groups);
    }, [props.groups]);

    useEffect(() => {
        setFlatGroups(props.flatGroups);
    }, [props.flatGroups]);

    const groupLoop = (groupObj, group) => {
        if (groupObj.children?.length > 0) {
            groupObj.children?.forEach((obj) => {
                if (obj.code === group.code) {
                    obj.checked = !obj.checked;
                    return;
                } else if (obj.children) obj.children = groupLoop(obj, group);
            });
        }
        return groupObj.children;
    };

    const toggleOpen = (group) => {
        let groupsArr = JSON.parse(JSON.stringify(props.groups));
        let checked = [];
        let flatGroupArr = JSON.parse(JSON.stringify(flatGroups));
        flatGroupArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !groupObj.checked;
            if (groupObj.checked) checked.push(groupObj.code);
        });
        setFlatGroups(flatGroupArr);
        groupsArr.forEach((groupObj) => {
            if (group.code === groupObj.code) {
                groupObj.checked = !groupObj.checked;
                return;
            } else if (groupObj.children?.length > 0) {
                groupObj.children = groupLoop(groupObj, group);
            }
        });
        props.changeGroups(groupsArr, checked, group);
    };

    const treeNode = (group) => {
        return (
            <React.Fragment>
                <span>
                    <input
                        type={'checkbox'}
                        className={'form-check-input border-primary width-15 height-15'}
                        id={`checkbox-${group.code}`}
                        checked={group.checked}
                        disabled={props.disableCheckbox}
                        onChange={() => toggleOpen(group)}
                    />
                </span>
                <span
                    className={`fs-12 ms-2 font-family-primary text-transform-initial ${props.checkOnLabelClick ? 'cursor-pointer' : ''}`}
                    onClick={() => {
                        if (props.checkOnLabelClick) toggleOpen(group);
                    }}
                    id={`group-${group.code}`}
                >
                    {props.showSmallerLabel ? (group.name?.length > 10 ? group.name?.substr(0, 10) + '...' : group.name) : group.name}
                </span>
                {props.showSmallerLabel ? (group.name?.length > 10 ? Tooltip(`group-${group.code}`, group.name) : '') : ''}
                {props.showCount && (
                    <span className="float-end font-family-primary text-transform-initial">
                        <span className="badge bg-light fs-10 text-muted">{group.count ? group.count : 0}</span>
                    </span>
                )}
            </React.Fragment>
        );
    };

    const treeComponent = (group, ind) => {
        return (
            <Tree
                key={ind}
                // content={group.name}
                type={treeNode(group)}
                icons={{ plusIcon: plus, minusIcon: minus, closeIcon: closeIcon }}
                onItemClick={() => toggleOpen(group)}
            >
                {group.children?.length > 0 && group.children.map((groupObj, index) => treeComponent(groupObj, index))}
            </Tree>
        );
    };

    return <div>{groups.map((group, ind) => treeComponent(group, ind))}</div>;
};

export default AnimatedTreeComponent;
