import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { TenantConfig } from '../../Components/Common/Util';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';
import { useEnv } from '../../envContext';
import { tenantConfig as TenantConf, authUser, enterpriseAccount } from '../../store/actions';
import AuthSlider from './AuthSlider';

const SuccessMsg = () => {
    const urlconf = useEnv();
    document.title = 'Success Message';
    const tenantConfig = TenantConfig();
    const dispatch = useDispatch();
    const history = useHistory();

    const backToLogin = () => {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(authUser(null));
        dispatch(enterpriseAccount(null));
        dispatch(TenantConf(tenant?.[urlconf.REACT_APP_COMPANY_ID]));
        history.push('/login');
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center min-vh-100 vw-100 overflow-y-auto">
                {/* <div className="bg-overlay"></div> */}
                <div className="min-vh-100 overflow-x-hidden vw-100">
                    <div className="min-vh-100 vw-100">
                        <Row className="min-vh-100 vw-100">
                            <Col lg={12} className="min-vh-100 vw-100 p-0">
                                <Card className="min-vh-100 overflow-x-hidden m-0">
                                    <Row className="g-0 min-vh-100 vw-100">
                                        <AuthSlider />
                                        <Col lg={4} className="d-flex justify-content-center align-items-center">
                                            <div className="p-lg-12 p-6 w-100">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="text-center mb-10 text-white-50">
                                                            <div className="mb-4">
                                                                <div className="d-inline-block auth-logo">
                                                                    <img src={tenantConfig?.COMPANY_LOGO} alt="" height="60" />
                                                                </div>
                                                            </div>
                                                            {tenantConfig.PRIMARY_HEADER && (
                                                                <h4 className={'mt-4 text-dark'}>{tenantConfig.PRIMARY_HEADER}</h4>
                                                            )}
                                                            {tenantConfig.SECONDARY_HEADER && (
                                                                <h5 className="fw-medium text-muted">{tenantConfig.SECONDARY_HEADER}</h5>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="avatar-lg mx-auto mt-3">
                                                    <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                        <i className="ri-checkbox-circle-fill"></i>
                                                    </div>
                                                </div>
                                                <div className="mt-6 pt-3">
                                                    <h4 className="text-center">Well done !</h4>
                                                    <p className="text-muted mx-6 text-center">
                                                        Your password is now updated successfully..
                                                    </p>
                                                    <div className="mt-6">
                                                        <button type="button" onClick={backToLogin} className="btn btn-success w-100">
                                                            Back to Login
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SuccessMsg;
