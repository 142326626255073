import { CHROME_ENTERPRISE } from './actionType';

const INIT_STATE = { chromeConfig: '' };

const Chromeconfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHROME_ENTERPRISE:
            return { ...state, chromeConfig: action.payload };
        default:
            return state;
    }
};

export default Chromeconfig;
