/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import { Tooltip, convertBytesTo, getDate } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const IOSApps = () => {
    document.title = 'iOS Apps';
    let history = useHistory();
    const api = new APIClient();
    const urlconf = useEnv();
    const [appsData, setAppsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileUploader, setFileUploader] = useState('');
    const [commonModal, setCommonModal] = useState({ show: false, header: '', type: '' });
    const [actionText, setActionText] = useState('');
    const [offcanvasCommonModal, setOffcanvasCommonModal] = useState({ show: false, header: '', type: '' });
    const [offcanvasActionText, setOffcanvasActionText] = useState('');
    const [seletecdAppData, setSeletecdAppData] = useState({});

    useEffect(() => {
        getApps();
    }, []);

    const getApps = () => {
        setLoading(true);
        let params = { page: 1, size: 2000 };
        api.get(url.IOSAPPS, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setAppsData(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleRefresh = () => {
        setLoading(true);
        api.patch(url.IOSAPPS_SYNC, {}, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const uploadDoc = () => {
        fileUploader.click();
    };

    const handleUpload = (ev) => {
        const file = ev.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension === 'ipa' || fileExtension === 'plist') {
                let formData = new FormData();
                formData.append('file', file);
                setLoading(true);
                api.create(url.IOSAPPS_UPLOAD, formData, true, domains.IOS_V1)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            toast.success(toastMessages.fileUploaded);
                            getApps();
                        }
                        setLoading(false);
                    })
                    .catch((_err) => setLoading(false));
            } else {
                toast.error('Please select a valid .ipa or .plist file');
            }
        }
    };

    const toggleCommonModal = useCallback(() => {
        setCommonModal({ ...commonModal, show: !commonModal.show });
    }, [commonModal]);

    const handlePurchaseLicenses = (data) => {
        setCommonModal({ show: true, header: `Purchase Licenses for ${data.name}`, type: 'purchase_licenses' });
        setActionText('Open Apple Business Manager');
    };

    const handleCommonModalBody = () => {
        if (commonModal.type === 'purchase_licenses') {
            return (
                <div>Purchase one or more licenses through Apple Business Manager then sync your apps with Apple from the Apps page.</div>
            );
        }
    };

    const handleCommonModalConfirm = () => {
        if (commonModal.type === 'purchase_licenses') {
            window.open('https://business.apple.com/', '_blank');
        }
    };

    const toggleOffcanvasCommonModal = useCallback(() => {
        setOffcanvasCommonModal({ ...offcanvasCommonModal, show: !offcanvasCommonModal.show });
        setSeletecdAppData({});
    }, [offcanvasCommonModal]);

    const handleViewDetails = (data) => {
        setOffcanvasCommonModal({ show: true, header: data.name, type: 'view_details' });
        setOffcanvasActionText('Ok');
        setSeletecdAppData(data);
    };

    const handleOffcanvasBody = () => {
        if (offcanvasCommonModal.type === 'view_details') {
            return (
                <Col>
                    <Row className="mb-2">
                        <Col className="fw-semibold">ID :</Col>
                        <Col className="fw-normal">{seletecdAppData.adamid ? seletecdAppData.adamid : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold"> Name :</Col>
                        <Col className="fw-normal word-ellipsis" id={'app_name'}>
                            {seletecdAppData.name ? seletecdAppData.name : '—'}
                        </Col>
                        {Tooltip('app_name', seletecdAppData.name)}
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Package Name :</Col>
                        <Col className="fw-normal">{seletecdAppData.packagename ? seletecdAppData.packagename : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Seller :</Col>
                        <Col className="fw-normal">{seletecdAppData.owner ? seletecdAppData.owner : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Version :</Col>
                        <Col className="fw-normal">{seletecdAppData.version ? seletecdAppData.version : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Size :</Col>
                        <Col className="fw-normal">{seletecdAppData.size ? convertBytesTo(seletecdAppData.size) : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">OS Requirement :</Col>
                        <Col className="fw-normal">{seletecdAppData.osrequirement ? seletecdAppData.osrequirement : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Made For :</Col>
                        <Col className="fw-normal">
                            {seletecdAppData.platforms?.length > 0 ? seletecdAppData.platforms?.toString() : '—'}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Device Assignable :</Col>
                        <Col className="fw-normal">{seletecdAppData.deviceassignable ? 'Yes' : 'No'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">Last Released :</Col>
                        <Col className="fw-normal">
                            {seletecdAppData.currentversionreleaseDate ? getDate(seletecdAppData.currentversionreleaseDate) : '—'}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">First Released :</Col>
                        <Col className="fw-normal">{seletecdAppData.releasedate ? getDate(seletecdAppData.releasedate) : '—'}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="fw-semibold">View in App Store :</Col>
                        <Col
                            className="fw-normal word-ellipsis text-info text-decoration-underline cursor-pointer"
                            id={`view_app_${seletecdAppData.adamid}`}
                            onClick={() => window.open(seletecdAppData.url, '_blank')}
                        >
                            {seletecdAppData.url ? seletecdAppData.url : '—'}
                        </Col>
                        {Tooltip(`view_app_${seletecdAppData.adamid}`, seletecdAppData.url)}
                    </Row>
                </Col>
            );
        }
    };

    const handleOffcanvasSave = () => {};

    const userLabel = (uname) => {
        let name = uname?.split(' ');
        if (name?.length === 1) return name?.[0]?.charAt(0)?.toUpperCase();
        else return name?.[0]?.charAt(0)?.toUpperCase() + name?.[1]?.charAt(0)?.toUpperCase();
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb title="iOS Apps" pageTitle="iOS Apps" backLink="iosapps" history={history} />
                    <Card className="min-height-600">
                        <CardHeader>
                            {/* <Row className="d-flex align-items-center justify-content-between"> */}
                            {/* <Col className="col-5 d-flex align-items-center">
                                    <div className="w-50 pe-5">
                                        <SearchOption />
                                    </div>
                                    <div className="w-30">
                                        <Select options={options} />
                                    </div>
                                </Col> */}
                            <Row className="d-flex align-items-center justify-content-end">
                                <Col sm={'auto'} className="d-flex align-items-center">
                                    <div className="d-flex">
                                        <Button type="button" color="primary" id="bulkupload" onClick={uploadDoc}>
                                            <span className="d-flex align-items-center ">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="pe-1">Upload</span>
                                                    <i className="ri-file-upload-line ms-1" id="bulkupload"></i>
                                                </div>
                                            </span>
                                        </Button>
                                        {Tooltip('bulkupload', 'Bulk Import Actions')}
                                        <input
                                            hidden
                                            id="file"
                                            type="file"
                                            accept=".ipa, .plist"
                                            ref={(file) => setFileUploader(file)}
                                            onChange={(ev) => handleUpload(ev)}
                                            onClick={(ev) => (ev.target.value = null)}
                                        />
                                    </div>
                                </Col>
                                <Col sm={'auto'} className="d-flex align-items-center">
                                    <Button outline type="button" color="primary" onClick={handleRefresh}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="pe-1">Sync</span>
                                            <i className="ri-refresh-line"></i>
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                            {/* </Row> */}
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {appsData?.length > 0 ? (
                                    appsData.map((data, index) => (
                                        <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                                            <Card className="shadow-card border">
                                                <CardBody>
                                                    <div className="mb-4">
                                                        <div
                                                            className={`badge-soft-${
                                                                data.type?.toUpperCase() === 'ENTERPRISE' ? 'info' : 'success'
                                                            } position-absolute top-0 start-0 fs-10 p-1 fw-medium`}
                                                        >
                                                            {data.type?.toUpperCase() === 'ENTERPRISE' ? 'Enterprise' : 'Apps & Books'}
                                                        </div>
                                                        {data.type?.toUpperCase() !== 'ENTERPRISE' && (
                                                            <div className="position-absolute cursor-pointer top-0 end-5 p-2">
                                                                <UncontrolledDropdown direction="start">
                                                                    <DropdownToggle
                                                                        tag="button"
                                                                        className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                                                    >
                                                                        <i className="ri-more-fill" />
                                                                    </DropdownToggle>

                                                                    <DropdownMenu className="dropdown-menu-end">
                                                                        <DropdownItem onClick={() => handleViewDetails(data)}>
                                                                            <i className="ri-information-line align-bottom me-2 text-muted"></i>
                                                                            View Details
                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={() => handlePurchaseLicenses(data)}>
                                                                            <i className="ri-briefcase-line align-bottom me-2 text-muted"></i>
                                                                            Purchase Licenses
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="pe-2">
                                                            {data.icon ? (
                                                                <img
                                                                    src={data.icon}
                                                                    alt="img"
                                                                    height={50}
                                                                    width={50}
                                                                    className="rounded border"
                                                                />
                                                            ) : (
                                                                <span className="rounded height-50 width-50 border border-2 d-flex align-items-center justify-content-center fs-15 bg-primary text-white">
                                                                    {userLabel(data.name)}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <Col>
                                                            <div className="fs-13 fw-semibold">
                                                                <div className="word-ellipsis">{data.name}</div>
                                                            </div>
                                                            <div className="fs-12 fw-medium">{data.platforms?.join(', ')}</div>
                                                            <div className="fs-11 text-muted">
                                                                Version {data.version ? data.version : '1.0.0'}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </CardBody>
                                                <CardFooter>
                                                    {data.assignedlicenses || data.availablelicenses ? (
                                                        <div className="mt-auto">
                                                            <div className="d-flex mb-1">
                                                                <div className="flex-grow-1">
                                                                    <div className="fw-medium fs-11">Licenses</div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="ri-list-check align-bottom me-1 text-muted fs-11"></i>
                                                                        <span className="fw-medium fs-11">
                                                                            {data.assignedlicenses}/{data.availablelicenses}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="progress progress-sm animated-progess">
                                                                <div
                                                                    className="progress-bar bg-success"
                                                                    role="progressbar"
                                                                    style={{ width: data.assignedlicenses * 5.2 }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center mb-2">
                                                            <i className="ri-information-line fs-14 me-2" />
                                                            <i className="text-grey fw-medium fs-11 word-ellipsis">
                                                                No License is required.
                                                            </i>
                                                        </div>
                                                    )}
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <CommonModal
                size={'md'}
                show={commonModal.show}
                modalheader={commonModal.header}
                onCloseClick={toggleCommonModal}
                saveText={actionText}
                handleClick={handleCommonModalConfirm}
                handleModalBody={() => handleCommonModalBody()}
            />
            <OffcanvasModal
                direction="end"
                open={offcanvasCommonModal.show}
                toggle={toggleOffcanvasCommonModal}
                handleCloseClick={toggleOffcanvasCommonModal}
                loading={loading}
                saveText={offcanvasActionText}
                OffcanvasModalID={offcanvasCommonModal.type}
                closeButtonBorder={true}
                handleSaveClick={handleOffcanvasSave}
                handleOffcanvasBody={handleOffcanvasBody}
                offcanvasHeader={offcanvasCommonModal.header}
                hideSaveButton={offcanvasCommonModal.type === 'view_details' ? true : false}
                modalClassName={offcanvasCommonModal.type === 'view_details' ? 'w-30' : 'w-50'}
            />
        </React.Fragment>
    );
};

export default IOSApps;
