export const appsSchema = [
    {
        Header: 'App Name',
        accessor: 'title',
        customCell: true,
        fieldType: 'iconText',
        hideLink: 'configExist',
        accessor2: 'totalDevices',
        imageURL: 'smallIconUrl',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Package',
        accessor: 'packageName',
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        sortable: true
    },
    {
        Header: 'Version',
        accessor: 'appVersionNumber',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Last Release',
        accessor: 'createdDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        // edit: true,
        sortable: false,
        config: true,
        configFade: 'configExist',
        configTooltip: 'Configurations not available',
        // view: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this app, as it is already being used in a policy',
        fade: 'doNotDelete',
        noUpdate: 'noUpdate',
        // download: true,
        refresh: true,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const configsSchema = [
    { Header: 'Configuration Name', accessor: 'title', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Created Date', accessor: 'createdDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Actions', accessor: 'html', sortable: false, customCell: true, fieldType: 'actions', edit: true, delete: true }
];

export const policyDetailsSchema = [
    { Header: 'Group', accessor: 'group', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Total Devices', accessor: 'totalDevices', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
    {
        Header: 'Installation Status',
        sortable: false,
        customCell: true,
        fieldType: 'deviceCount',
        accessor1: 'notInstalled',
        accessor2: 'notLatest',
        accessor3: 'latest',
        width: 200,
        maxWidth: 200,
        minWidth: 200
    },
    { Header: 'Actions', customCell: true, accessor: 'html', fieldType: 'download', sortable: false }
];

export const LauncherSetupSchema = [
    {
        Header: 'Name',
        accessor: 'title',
        customCell: true,
        fieldType: 'clickable',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    {
        Header: 'Package Name',
        accessor: 'packageName',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    { Header: 'Policies', accessor: 'policies', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const bulkUploadsSchema = [
    {
        Header: 'Purpose',
        accessor: 'purpose',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        thClass: 'width-150 max-width-150 min-width-150',
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'File Name',
        accessor: 'fileName',
        thClass: 'width-150 max-width-150 min-width-150',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    /*
     * {
     *     Header: 'Count',
     *     accessor: 'count',
     *     sortable: true
     * },
     * {
     *     Header: 'Failure Count',
     *     accessor: 'failureCount',
     *     sortable: true
     * },
     * {
     *     Header: 'Success Count',
     *     accessor: 'successCount',
     *     sortable: true
     * },
     */
    { Header: 'Status', accessor: 'status', sortable: true, customCell: true, fieldType: 'badge' },
    {
        Header: 'Submitted By',
        accessor: 'createdBy',
        thClass: 'width-150 max-width-150 min-width-150',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    { Header: 'Submitted Date', accessor: 'createdDate', sortable: false, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Completed Date', accessor: 'completedDate', sortable: false, width: 100, maxWidth: 100, minWidth: 100 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        download: true,
        maxWidth: '20px',
        downloadTooltip: 'Export as CSV File'
    }
];

export const bulkUploadViewSchema = [
    {
        Header: 'Serial/Code/IMEI',
        accessor: 'serialNo',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: false
    },
    {
        Header: 'Action',
        accessor: 'action',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: false
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'badge'
    },
    {
        Header: 'Remarks',
        accessor: 'remarks',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: false
    },
    {
        Header: 'Submitted Date',
        accessor: 'createdDate',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: false
    },
    {
        Header: 'Completed Date',
        accessor: 'lastModifiedDate',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: false
    }
];
