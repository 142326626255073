import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { Tooltip } from './Util';

const BreadCrumb = ({
    pageTitle,
    history,
    hideLinks,
    handleExport,
    customBack,
    showBack,
    backLink,
    showIcon,
    icon,
    showInNormalCase,
    isApplication,
    setIsApplication,
    label
}) => {
    /*
     * if (homeLink) {
     *     crumbs = { ...crumbs };
     * }
     * let i = Object.keys(crumbs).length;
     */

    /*
     * const Links = Object.keys(crumbs).map((k) => {
     *     i--;
     *     const v = crumbs[k];
     *     let clickFunc = v.onClick;
     *     if (!v.onClick && v.pageLink) {
     *         clickFunc = () => {
     *             history.push('/' + v.pageLink);
     *         };
     *     }
     *     if (i === 0) {
     *         return (
     *             <BreadcrumbItem id={k} key={i} className={'active fs-14 fw-normal'}>
     *                 {v.label}
     *             </BreadcrumbItem>
     *         );
     *     } else {
     *         return (
     *             <BreadcrumbItem to="#" key={i} id={k} onClick={clickFunc} className={'text-dark fs-14 fw-normal cursor-pointer'}>
     *                 {v.label}
     *             </BreadcrumbItem>
     *         );
     *     }
     * });
     */

    const handleBack = () => {
        if (!isApplication && label === 'Applications') setIsApplication(true);
        else if (customBack) backLink();
        else if (backLink) history.push('/' + backLink);
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        {showInNormalCase ? (
                            <span
                                className={'mb-sm-0 fw-bold fs-14 tex-dark d-flex align-items-center ' + (showBack ? 'cursor-pointer' : '')}
                                onClick={handleBack}
                            >
                                {showBack && (
                                    <span className="link-primary me-2 d-flex align-items-center">
                                        <i className="ri-arrow-left-line" />
                                    </span>
                                )}
                                {showIcon && (
                                    <span className="me-1">
                                        <img src={icon} alt="icon" width={20} height={20} />
                                    </span>
                                )}
                                {pageTitle}
                            </span>
                        ) : (
                            <h4
                                className={'mb-sm-0 tex-dark d-flex align-items-center ' + (showBack ? 'cursor-pointer' : '')}
                                onClick={handleBack}
                            >
                                {showBack && (
                                    <span className="link-primary me-2 d-flex align-items-center">
                                        <i className="ri-arrow-left-line" />
                                    </span>
                                )}
                                {showIcon && (
                                    <span className="me-1">
                                        <img src={icon} alt="icon" width={20} height={20} />
                                    </span>
                                )}
                                {pageTitle}
                            </h4>
                        )}
                        {hideLinks && (
                            <div className="d-flex align-items-center gap-2">
                                {/* <Button
                                    type="button"
                                    id="download_pdf"
                                    className="padding-y-4 padding-x-10"
                                    color="primary"
                                    onClick={() => handleExport('pdf')}
                                >
                                    <span className="d-flex align-items-center">
                                        <i className="ri-file-pdf-line fs-16 align-bottom me-1" /> Export PDF
                                    </span>
                                </Button>
                                {Tooltip('download_pdf', 'Export PDF')} */}
                                <Button
                                    type="button"
                                    id="download_csv"
                                    className="padding-y-4 padding-x-10"
                                    color="primary"
                                    onClick={() => handleExport('excel')}
                                >
                                    <span className="d-flex align-items-center">
                                        <i className="ri-file-excel-2-line fs-16 align-bottom me-1" /> Export CSV
                                    </span>
                                </Button>
                                {Tooltip('download_csv', 'Export CSV')}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
