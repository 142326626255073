/* eslint-disable quotes */
import React from 'react';

// import Scss
import './assets/scss/themes.scss';

// imoprt Route
import { defineLordIconElement } from 'lord-icon-element';
import { loadAnimation } from 'lottie-web';
import Route from './Routes';

import { ToastContainer } from 'react-toastify';
import ScrollToTop from './Components/Common/ScrollToTop';
import { TenantConfig } from './Components/Common/Util';

// Import React FilePond
import { registerPlugin } from 'react-filepond';
// Import the plugin code
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

// Register the plugins
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginImageValidateSize
);

// register lottie and define custom element
defineLordIconElement(loadAnimation);

function App() {
    const tenantConfig = TenantConfig();

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = tenantConfig?.COMPANY_FAVICON;

    return (
        <React.Fragment>
            <ToastContainer
                theme="colored"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                limit={0}
            />
            <ScrollToTop />
            <Route />
        </React.Fragment>
    );
}

export default App;
