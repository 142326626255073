/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import CommonModal from '../../Components/Common/CommonModal';
import { sortBy, sortByAsc } from '../../Components/Common/Util';
import { licenseData, modalData, totalQuantityData } from '../../Components/constants/constants';
import ExpiredImage from '../../assets/images/expiredImage.png';
import FreeImage from '../../assets/images/licenses/freeImage.png';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import AndroidVersion from '../Dashboard/AndroidVersion';
const LicensePlan = () => {
    document.title = 'License Plan';
    const [refreshModal, setRefreshModal] = useState(false);
    const [androidVersionDetails, setAndroidVersionDetails] = useState([]);
    const [showOSDetailsModal, setShowOSDetailsModal] = useState(false);
    const [totalDevices, setTotalDevices] = useState(0);
    const [apps, setApps] = useState([]);
    const [series, setSeries] = useState();
    const [labels, setLabels] = useState();
    const [appsBackup, setAppsBackup] = useState([]);
    const [manufacturerDetails, setManufacturerDetails] = useState([]);
    const [onlineStatus, setOnlineStatus] = useState();
    const [loading, setLoading] = useState(false);
    const api = new APIClient();
    const urlconf = useEnv();
    const [widgetCount, setWidgetCount] = useState({});
    const history = useHistory();
    useEffect(() => {
        getMobileMetricCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getMobileMetricCount = () => {
        setLoading(true);
        api.create(url.DASHBOARD)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    setWidgetCount({
                        deviceStatusCount: { ...resp.data?.aggs?.deviceStatusCount },
                        onlineCount: { ...resp.data?.aggs?.onlineStatusCount },
                        totalCount: resp.data.totalCount
                    });
                    // delete resp.data?.aggs?.totalCount;
                    delete resp.data?.aggs?.onlineStatusCount;
                    delete resp.data?.aggs?.deviceStatusCount;
                    let onlineArr = [];
                    for (let [key, value] of Object.entries(resp.data?.aggs?.updescoonlineStatus)) {
                        onlineArr.push({
                            label: key === 'last_7_days' ? '0 to 7 days' : key === 'last_15_days' ? '0 to 15 days' : '0 to 30 days',
                            count: value,
                            key: key === 'last_7_days' ? 3 : key === 'last_15_days' ? 2 : 1
                        });
                        if (key === 'last_30_days') setTotalDevices(value);
                    }
                    delete resp.data?.aggs?.updescoonlineStatus;
                    let manufacturerArr = [];
                    let totalDeviceCountAsManufacturer = 0;
                    for (let [key, value] of Object.entries(resp.data?.aggs?.manufacture)) {
                        if (key !== '') {
                            manufacturerArr.push({
                                label: key.charAt(0).toUpperCase() + key.slice(1),
                                count: value
                            });
                            totalDeviceCountAsManufacturer = totalDeviceCountAsManufacturer + value;
                        }
                    }
                    let mfArr = [];
                    manufacturerArr.forEach((obj) => {
                        // eslint-disable-next-line max-nested-callbacks
                        if (!mfArr.find((mf) => mf.label?.toLowerCase() === obj.label?.toLowerCase())) {
                            let mfAr = manufacturerArr.filter((mfObj) => mfObj.label?.toLowerCase() === obj.label?.toLowerCase());
                            let count = 0;
                            mfAr.forEach((mf) => (count = count + (mf.count ? Number(mf.count) : 0)));
                            mfArr.push({ label: obj.label, count: count });
                        }
                    });
                    mfArr = sortByAsc(mfArr, 'count');
                    if (resp.data?.aggs?.manufacture?.['']) mfArr.push({ label: 'NA', count: resp.data?.aggs?.manufacture?.[''] });
                    delete resp.data?.aggs?.manufacture;
                    let androidVersionArr = [];
                    for (let [key, value] of Object.entries(resp.data?.aggs?.androidVersion)) {
                        if (key !== '') androidVersionArr.push({ label: 'Android ' + key, count: value });
                    }
                    let osArr = [];
                    androidVersionArr.forEach((av) => {
                        if (!osArr.find((os) => os.label?.toLowerCase() === av.label?.toLowerCase())) {
                            let ofAr = androidVersionArr.filter((avObj) => avObj.label?.toLowerCase() === av.label?.toLowerCase());
                            let count = 0;
                            ofAr.forEach((ofObj) => (count = count + (ofObj.count ? Number(ofObj.count) : 0)));
                            osArr.push({ label: av.label, count: count });
                        }
                    });
                    osArr = sortByAsc(osArr, 'count');
                    if (resp.data?.aggs?.androidVersion?.['']) osArr.push({ label: 'NA', count: resp.data?.aggs?.androidVersion?.[''] });
                    delete resp.data?.aggs?.androidVersion;
                    let applications = [];
                    for (let [_key, value] of Object.entries(resp.data?.aggs)) {
                        let obj = {
                            latest: value?.[3] ? value?.[3] : 0,
                            notLatest: value?.[2] ? value?.[2] : 0,
                            notInstalled: value?.[1] ? value?.[1] : 0,
                            smallIconUrl: value?.iconUrl,
                            title: value?.appTitle
                        };
                        let total =
                            (obj.latest ? Number(obj.latest) : 0) +
                            (obj.notLatest ? Number(obj.notLatest) : 0) +
                            (obj.notInstalled ? Number(obj.notInstalled) : 0);
                        obj.progress = [
                            { width: obj.latest ? Math.floor((Number(obj.latest) / total) * 100) : 0, bgColor: 'bg-success' },
                            { width: obj.notLatest ? Math.floor((Number(obj.notLatest) / total) * 100) : 0, bgColor: 'bg-warning' },
                            { width: obj.notInstalled ? Math.floor((Number(obj.notInstalled) / total) * 100) : 0, bgColor: 'bg-danger' }
                        ];
                        applications.push(obj);
                    }
                    applications = applications?.filter((app) => app.latest > 0 || app.notLatest > 0 || app.notInstalled > 0);
                    applications = sortBy(applications, 'title');
                    setApps(applications);
                    setAppsBackup(applications);
                    setManufacturerDetails(mfArr);
                    setAndroidVersionDetails(osArr);
                    let arr = onlineArr.sort((a, b) => b.key - a.key);
                    setOnlineStatus(arr);
                    setSeries(arr.map((ele) => ele.count));
                    setLabels(arr.map((ele) => ele.label));
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };
    const handleClick = () => {
        history.push('/license/purchase');
    };
    const handleExtendClick = () => {
        setRefreshModal(!refreshModal);
    };

    const handleModalBody = () => {
        return (
            <div className="px-10 padding-bottom-10">
                <div className="fs-18 fw-semibold text-center d-flex justify-content-start align-items-center">
                    <div>
                        <img className="w-50" src={FreeImage} alt=""></img>
                    </div>
                    <div>Extend Free Trail Plan</div>
                </div>
                <div className="badge-soft-warning my-5 py-2 text-black d-flex align-items-center margin-top-10 fw-medium fs-12">
                    <i className="ri-error-warning-line px-1"></i>Contact the IT Administrator to Extend Free Trail Plan
                </div>
                <Row>
                    {modalData.map((ele, index) => {
                        return (
                            <Col className="col-6 py-3" key={index}>
                                <div className="py-2 fw-semibold">{ele.label}</div>
                                <div className="fw-semibold text-muted">{ele.value}</div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="License" pageTitle="License" />
                    <div>
                        <Card className="py-5 justify-content-center badge-soft-danger px-2 border-2 border-danger">
                            <Row>
                                <div className="col-1 m-auto text-center mx-0 position-relative">
                                    <img className="w-75 " src={FreeImage} alt="img" title="freeimg" />
                                    <img
                                        className="w-75 z-index-99 position-absolute start-10 position-bottom-20"
                                        src={ExpiredImage}
                                        alt="img"
                                        title="expiredimg"
                                    />
                                </div>
                                <div className="col-11">
                                    <Row>
                                        {licenseData.map((ele, ind) => (
                                            <Col key={ind} className="col-2 justify-content-center  flex-column ">
                                                <div className="text-muted py-1">{ele.title}</div>
                                                <div className={`fw-semibold ${ele.labelCss}`}>{ele.value}</div>
                                            </Col>
                                        ))}
                                        <Col className="d-flex justify-content-end ">
                                            <Button className="border-primary bg-white text-primary px-2 m-2" onClick={handleExtendClick}>
                                                Extend Free Trail
                                            </Button>
                                            <Button className="px-2 m-2 bg-primary border-none" onClick={handleClick}>
                                                Purchase License
                                            </Button>
                                        </Col>
                                    </Row>
                                    {refreshModal ? (
                                        <CommonModal
                                            size={'md'}
                                            show={refreshModal}
                                            headerCss={'border-bottom-0'}
                                            hideFooter={true}
                                            disabled={false}
                                            onCloseClick={() => {
                                                setRefreshModal(false);
                                            }}
                                            handleModalBody={() => handleModalBody()}
                                        />
                                    ) : null}
                                </div>
                            </Row>
                        </Card>
                        <Row>
                            <Col xl={4} md={4}>
                                <AndroidVersion
                                    details={androidVersionDetails || []}
                                    showDetailsModal={() => setShowOSDetailsModal(true)}
                                />
                            </Col>
                            <Col className="col-8">
                                <Card className="border">
                                    <CardHeader className="border-bottom-dashed fw-medium">
                                        <div>License Quantity</div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="p-2">
                                            {totalQuantityData.map((ele, ind) => (
                                                <Col className="col-3" key={ind}>
                                                    <div className="text-muted py-1">{ele.title}</div>
                                                    <div className={`fw-semibold ${ele.labelCss}`}>{ele.value}</div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LicensePlan;
