import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { tenantConfig as tenant } from '../../../Components/constants/TenantConfig';
import noAccessImg from '../../../assets/images/access-denied.png';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { authModes, authUser, enterpriseAccount, tenantConfig } from '../../../store/actions';

const NoAccess = () => {
    const api = new APIClient();
    const urlconf = useEnv();
    let dispatch = useDispatch();
    let history = useHistory();

    const logout = () => {
        api.get(url.LOGOUT, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    sessionStorage.clear();
                    localStorage.clear();
                    dispatch(authUser(null));
                    dispatch(enterpriseAccount(null));
                    dispatch(authModes(null));
                    dispatch(tenantConfig(tenant?.[urlconf.REACT_APP_COMPANY_ID]));
                    history.push('/account');
                }
            })
            .catch((err) => {});
    };

    return (
        <React.Fragment>
            <div className={'page-content vh-90 postion-relative'}>
                <Row className="h-100 d-flex align-items-center justify-content-center">
                    <Col lg={6}>
                        <Card>
                            <CardBody>
                                <div className="text-center">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <h4 className="mt-4 fw-semibold">Access Denied</h4>
                                            <p className="text-muted mt-3">The page you are looking for is not accessible</p>
                                            <div className="mt-4">
                                                <button type="button" onClick={logout} className="btn btn-success">
                                                    Back to Login
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center mt-5 mb-2">
                                        <Col sm={7} xs={8}>
                                            <img src={noAccessImg} alt="No Access" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default NoAccess;
