import React from 'react';
import { Button, Card, CardBody, Col } from 'reactstrap';

const NoEnterprise = (props) => {
    return (
        <Col xl={12}>
            <Card className="vh-77">
                <CardBody className="d-flex align-items-center justify-content-center flex-column gap-5">
                    <img src={props.image} alt="No Enterprise" />
                    <div className="fw-medium fs-18">
                        {props.enterPrise?.toString() !== '403' ? props.message : 'You do not have access to this screen'}
                    </div>
                    {props.enterPrise?.toString() !== '403' && (
                        /*
                         * <IsAuthorized
                         *     privRequired={priviliges.ENTERPRISE_EDITOR}
                         *     yes={() => (
                         */
                        <Button type="button" color="success" className="w-fit" onClick={() => props.setAddEnterpriseModal(true)}>
                            <i className="mdi mdi-plus me-1" />
                            Add Enterprise
                        </Button>
                        /*
                         *     )}
                         *   />
                         */
                    )}
                </CardBody>
            </Card>
        </Col>
    );
};

export default NoEnterprise;
