import React from 'react';
import paginator from 'paginator';
import Page from './Page';
import cx from 'classnames';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';

export default class Pagination extends React.Component {
    static defaultProps = {
        itemsCountPerPage: 10,
        pageRangeDisplayed: 5,
        activePage: 1,
        prevPageText: 'Previous',
        firstPageText: 'First',
        nextPageText: 'Next',
        lastPageText: 'Last',
        innerClass: 'pagination pagination-separated pagination-sm mb-0',
        itemClass: 'page-item',
        linkClass: 'page-link',
        activeLinkClass: undefined,
        hideFirstLastPages: false,
        getPageUrl: (i) => '#'
    };

    isFirstPageVisible(hasPreviousPage) {
        const { hideDisabled, hideNavigation, hideFirstLastPages } = this.props;
        if (hideFirstLastPages || (hideDisabled && !hasPreviousPage)) return false;
        return true;
    }

    isPrevPageVisible(hasPreviousPage) {
        const { hideDisabled, hideNavigation } = this.props;
        if (hideNavigation || (hideDisabled && !hasPreviousPage)) return false;
        return true;
    }

    isNextPageVisible(hasNextPage) {
        const { hideDisabled, hideNavigation } = this.props;
        if (hideNavigation || (hideDisabled && !hasNextPage)) return false;
        return true;
    }

    isLastPageVisible(hasNextPage) {
        const { hideDisabled, hideNavigation, hideFirstLastPages } = this.props;
        if (hideFirstLastPages || (hideDisabled && !hasNextPage)) return false;
        return true;
    }

    buildPages() {
        const pages = [];
        const {
            itemsCountPerPage,
            pageRangeDisplayed,
            activePage,
            prevPageText,
            nextPageText,
            firstPageText,
            lastPageText,
            totalItemsCount,
            onChange,
            activeClass,
            itemClass,
            itemClassFirst,
            itemClassPrev,
            itemClassNext,
            itemClassLast,
            activeLinkClass,
            disabledClass,
            hideDisabled,
            hideNavigation,
            linkClass,
            linkClassFirst,
            linkClassPrev,
            linkClassNext,
            linkClassLast,
            hideFirstLastPages,
            getPageUrl
        } = this.props;

        const paginationInfo = new paginator(itemsCountPerPage, pageRangeDisplayed).build(totalItemsCount, activePage);

        for (let i = paginationInfo.first_page; i <= paginationInfo.last_page; i++) {
            pages.push(
                <Page
                    isActive={i === activePage}
                    key={i}
                    href={getPageUrl(i)}
                    pageNumber={i}
                    pageText={i + ''}
                    onClick={onChange}
                    itemClass={itemClass}
                    linkClass={linkClass}
                    activeClass={activeClass}
                    activeLinkClass={activeLinkClass}
                    ariaLabel={`Go to page number ${i}`}
                />
            );
        }

        this.isPrevPageVisible(paginationInfo.has_previous_page) &&
            pages.unshift(
                <Page
                    key={'prev' + paginationInfo.previous_page}
                    href={getPageUrl(paginationInfo.previous_page)}
                    pageNumber={paginationInfo.previous_page}
                    onClick={onChange}
                    pageText={prevPageText}
                    isDisabled={!paginationInfo.has_previous_page}
                    itemClass={cx(itemClass, itemClassPrev)}
                    linkClass={cx(linkClass, linkClassPrev)}
                    disabledClass={disabledClass}
                    ariaLabel="Go to previous page"
                />
            );

        this.isFirstPageVisible(paginationInfo.has_previous_page) &&
            pages.unshift(
                <Page
                    key={'first'}
                    href={getPageUrl(1)}
                    pageNumber={1}
                    onClick={onChange}
                    pageText={firstPageText}
                    isDisabled={!paginationInfo.has_previous_page}
                    itemClass={cx(itemClass, itemClassFirst)}
                    linkClass={cx(linkClass, linkClassFirst)}
                    disabledClass={disabledClass}
                    ariaLabel="Go to first page"
                />
            );

        this.isNextPageVisible(paginationInfo.has_next_page) &&
            pages.push(
                <Page
                    key={'next' + paginationInfo.next_page}
                    href={getPageUrl(paginationInfo.next_page)}
                    pageNumber={paginationInfo.next_page}
                    onClick={onChange}
                    pageText={nextPageText}
                    isDisabled={!paginationInfo.has_next_page}
                    itemClass={cx(itemClass, itemClassNext)}
                    linkClass={cx(linkClass, linkClassNext)}
                    disabledClass={disabledClass}
                    ariaLabel="Go to next page"
                />
            );

        this.isLastPageVisible(paginationInfo.has_next_page) &&
            pages.push(
                <Page
                    key={'last'}
                    href={getPageUrl(paginationInfo.total_pages)}
                    pageNumber={paginationInfo.total_pages}
                    onClick={onChange}
                    pageText={lastPageText}
                    isDisabled={paginationInfo.current_page === paginationInfo.total_pages}
                    itemClass={cx(itemClass, itemClassLast)}
                    linkClass={cx(linkClass, linkClassLast)}
                    disabledClass={disabledClass}
                    ariaLabel="Go to last page"
                />
            );

        return pages;
    }

    render() {
        const pages = this.buildPages();
        const { itemsCountPerPage, pageRangeDisplayed, activePage, totalItemsCount } = this.props;
        const paginationInfo = new paginator(itemsCountPerPage, pageRangeDisplayed).build(totalItemsCount, activePage);
        var recordsPerPage = { label: '20', value: 20 };
        const recordsPerPageOptions = [
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 }
        ];
        const handleRecordsPerPageChange = (option) => {
            recordsPerPage = option;
        };
        return (
            <Row
                className={
                    'g-2 ' + (this.props.marginTopClass ? this.props.marginTopClass : 'mt-6') + (this.props.isColumn ? ' flex-column' : '')
                }
            >
                {!this.props.hideTotal && (
                    <Col className="d-flex justify-content-start">
                        <div className="text-muted">
                            Showing <span className="fw-semibold">{paginationInfo.results}</span> of{' '}
                            <span className="fw-semibold">{paginationInfo.total_results}</span> Results
                        </div>
                    </Col>
                )}
                <Col className="d-flex justify-content-end">
                    {/* <div className="width-80">
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            id={'appToRemove'}
                            name={'appToRemove'}
                            options={recordsPerPageOptions}
                            placeholder="Select application to remove"
                            onChange={(selectedOption) => handleRecordsPerPageChange(selectedOption)}
                            value={recordsPerPage || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No apps found'}
                        />
                    </div> */}
                    <ul className={this.props.innerClass}>{pages}</ul>
                </Col>
            </Row>
        );
    }
}
