import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';
import FAV_ICON from '../../assets/images/favicon.ico';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { authModes } from '../../store/AuthModes/action';
import { tenantConfig } from '../../store/TenantConfig/action';
import AuthSlider from './AuthSlider';

const AccountDetails = () => {
    document.title = 'Account Details';
    document.querySelector('link[rel="icon"]').href = FAV_ICON;
    const urlconf = useEnv();
    let api = new APIClient();
    let dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    let tenantData = tenant?.[urlconf.REACT_APP_COMPANY_ID];

    useEffect(() => {
        let params = new URLSearchParams(decodeURIComponent(window?.location?.search));
        const code = params.get('code');
        const state = params.get('state');
        if (code && state) window.location.replace(window.location.origin + '/#/callback?code=' + code + '&state=' + state);
        dispatch(authModes(null));
    }, []);

    const prepareTenantData = (config) => {
        let configData = {
            name: config?.name,
            COMPANY_ID: tenantData?.COMPANY_ID,
            COMPANY_AUTH_USER: tenantData?.COMPANY_AUTH_USER,
            COMPANY_LOGO: config?.branding?.COMPANY_LOGO ? config?.branding?.COMPANY_LOGO : tenantData.COMPANY_LOGO,
            PRIMARY_HEADER: config?.branding?.PRIMARY_HEADER ? config?.branding?.PRIMARY_HEADER : tenantData?.PRIMARY_HEADER,
            SECONDARY_HEADER: config?.branding?.SECONDARY_HEADER ? config?.branding?.SECONDARY_HEADER : tenantData?.SECONDARY_HEADER,
            COMPANY_DEVELOPED_BY: 'Enterprise Mobility Solution',
            COMPANY_FAVICON: tenantData.COMPANY_FAVICON,
            COMAPNY_PRIMARY_COLOR: config?.branding?.COMPANY_PRIMARY_COLOR,
            COMAPNY_SECONDARY_COLOR: config?.branding?.COMPANY_SECONDARY_COLOR,
            COMAPNY_SUCCESS_COLOR: config?.branding?.COMPANY_SUCCESS_COLOR,
            COMPANY_FOOTER: config?.branding?.COMPANY_FOOTER,
            FAV_ICON: config?.branding?.FAV_ICON ? config?.branding?.FAV_ICON : FAV_ICON,
            COMPANY_SLIDE_BG1: config?.branding?.COMPANY_SLIDE_BG1 ? config?.branding?.COMPANY_SLIDE_BG1 : tenantData?.COMPANY_SLIDE_BG1
        };
        // writeFile();
        return configData;
    };

    const handlePromise = (values) => {
        setLoading(true);
        const brandingPromise = new Promise((resolve, reject) => {
            api.get(url.BRANDING + '?tenant=' + values.account, '', domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        resolve(resp.data);
                    } else reject('branding failed.');
                })
                .catch((err) => reject('branding failed'));
        });

        const authModePromise = new Promise((resolve, reject) => {
            api.get(url.AUTH_MODES + values.account, { status: 'A' }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp.data);
                    else reject('auth modes failed.');
                })
                .catch((err) => reject('auth modes failed.'));
        });

        Promise.allSettled([brandingPromise, authModePromise]).then((result) => {
            if (result[0].status === 'fulfilled') {
                localStorage.setItem('account', values.account?.trim(' '));
                let tenantObj = prepareTenantData(result[0]?.value);
                dispatch(tenantConfig(tenantObj));
            }
            let authModesArr = [];
            if (result[1].status === 'fulfilled') {
                result[1].value = result[1].value ? result[1].value : [];
                authModesArr = result[1].value;
                dispatch(authModes(JSON.stringify(result[1].value)));
            }
            if (authModesArr?.length === 0) toast.error(toastMessages.noAuthModesConfigured);
            else {
                toast.success(toastMessages.accountVerified);
                history.push('/login');
            }
            setLoading(false);
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { account: '' },
        validationSchema: Yup.object({
            account: Yup.string().required('Please Enter Your Account ID.')
        }),
        onSubmit: (values) => {
            handlePromise(values);
        }
    });

    const handleAccountIDChange = (e) => {
        validation.setValues({ ...validation.values, account: e.target.value?.trim(' ')?.toUpperCase() });
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center overflow-y-auto">
                <Col lg={12}>
                    <Card className=" overflow-x-hidden m-0">
                        <Row className={'g-0 vh-100 vw-100'}>
                            <AuthSlider />
                            <Col lg={4} className={'d-flex justify-content-center align-items-center'}>
                                <div className="p-lg-12 p-6 w-100">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center mb-5 text-white-50">
                                                <div className="mb-5">
                                                    <div className="d-inline-block auth-logo">
                                                        <img src={tenantData?.COMPANY_LOGO} alt="" height={40} />
                                                    </div>
                                                </div>
                                                {tenantData.PRIMARY_HEADER && (
                                                    <h4 className={'mt-4 text-dark'}>{tenantData.PRIMARY_HEADER}</h4>
                                                )}
                                                {tenantData.SECONDARY_HEADER && (
                                                    <h5 className="fw-medium text-muted">{tenantData.SECONDARY_HEADER}</h5>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>

                                    <div>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#"
                                        >
                                            <div className="d-flex align-items-center justify-content-center mb-1">
                                                <p className={'fs-18 mb-0 fw-medium text-primary'}>Login Account</p>
                                            </div>
                                            <div className="mb-4">
                                                <Label className="form-label">Account ID</Label>
                                                <Input
                                                    name="account"
                                                    className={'form-control'}
                                                    placeholder="Enter Account ID"
                                                    type="text"
                                                    onChange={handleAccountIDChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.account || ''}
                                                    invalid={validation.touched.account && validation.errors.account ? true : false}
                                                />
                                                {validation.touched.account && validation.errors.account ? (
                                                    <FormFeedback type="invalid">{validation.errors.account}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mt-6">
                                                <button
                                                    type="submit"
                                                    className={'btn btn-success btn-load w-100'}
                                                    disabled={validation.values.account === '' || loading}
                                                >
                                                    <span className="d-flex align-items-center justify-content-center">
                                                        Verify Account
                                                        {loading && (
                                                            <span className="ms-2 spinner-border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </span>
                                                        )}
                                                    </span>
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </div>
        </React.Fragment>
    );
};

export default withRouter(AccountDetails);
