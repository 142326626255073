import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { TenantConfig } from '../../Components/Common/Util';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { authModes, authUser, enterpriseAccount } from '../../store/actions';
import AuthSlider from './AuthSlider';

const BasicLogout = () => {
    document.title = 'Log Out';
    const api = new APIClient();
    const urlconf = useEnv();
    let history = useHistory();
    let dispatch = useDispatch();
    const tenantConfig = TenantConfig();

    useEffect(() => {
        api.get(url.LOGOUT, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    sessionStorage.clear();
                    localStorage.clear();
                    dispatch(authUser(null));
                    dispatch(enterpriseAccount(null));
                    dispatch(authModes(null));
                    dispatch(tenantConfig(tenant?.[urlconf.REACT_APP_COMPANY_ID]));
                    history.push('/account');
                }
            })
            .catch((err) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center min-vh-100 vw-100 overflow-y-auto">
                {/* <div className="bg-overlay"></div> */}
                <div className="min-vh-100 overflow-x-hidden vw-100">
                    <div className="min-vh-100 vw-100">
                        <Row className="min-vh-100 vw-100">
                            <Col lg={12} className="min-vh-100 vw-100 p-0">
                                <Card className="min-vh-100 overflow-x-hidden m-0">
                                    <Row className="g-0 min-vh-100 vw-100">
                                        <AuthSlider />

                                        <Col lg={4} className="d-flex justify-content-center align-items-center">
                                            <div className="p-lg-12 p-6 w-100 text-center">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="text-center mb-10 text-white-50">
                                                            <div className="mb-4">
                                                                <div className="d-inline-block auth-logo">
                                                                    <img src={tenantConfig?.COMPANY_LOGO} alt="" height="60" />
                                                                </div>
                                                            </div>
                                                            {tenantConfig.PRIMARY_HEADER && (
                                                                <h4 className={'mt-4 text-dark'}>{tenantConfig.PRIMARY_HEADER}</h4>
                                                            )}
                                                            {tenantConfig.SECONDARY_HEADER && (
                                                                <h5 className="fw-medium text-muted">{tenantConfig.SECONDARY_HEADER}</h5>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/hzomhqxz.json"
                                                    trigger="loop"
                                                    colors="primary:#405189,secondary:#08a88a"
                                                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                                                    style={{ width: '180px', height: '180px' }}
                                                ></lord-icon>

                                                <div className="mt-8 pt-4">
                                                    <h5>You are Logged Out</h5>
                                                    Thank you for using{' '}
                                                    <span className="fw-bold text-primary">{tenantConfig?.COMPANY_DEVELOPED_BY}</span>
                                                    <div className="mt-8 text-center">
                                                        <div
                                                            onClick={() => history.push('/login')}
                                                            className="btn btn-success w-100 cursor-pointer"
                                                        >
                                                            Sign in
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BasicLogout;
