import React from 'react';
import { getFormTypeAndRecordId } from '../../Components/Common/Util';
import ViewAndroidDevices from './ViewAndroidDevices';
import ViewIOSDevices from './ViewIOSDevices';

const MyDeviceDetails = () => {
    document.title = 'View My Device';
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let formType = formTypeAndId['formType'];
    let deviceType = formTypeAndId['recordID'];
    const recordID = formTypeAndId['queryType'];

    const components = {
        ios: <ViewIOSDevices recordID={recordID} formType={formType} />,
        android: <ViewAndroidDevices recordID={recordID} formType={formType} />
    };

    return <React.Fragment>{components[deviceType]}</React.Fragment>;
};

export default MyDeviceDetails;
