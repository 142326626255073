/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import Select from 'react-select';
import { Alert, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import Loader from '../../../Components/Common/Loader';
import { AuthUser, DownloadBunnyFile } from '../../../Components/Common/Util';
import * as FormValidation from '../../../Components/Common/Validation';

const AddAsset = (props) => {
    document.title = 'Assets';

    const assetTypeList = [
        /*
         * { label: 'Launcher Logo', value: 'Launcher Logo',type:'wallpaper' },
         * { label: 'Launcher Background Image', value: 'Launcher Background Image',type:'wallpaper' },
         */
        { label: 'Wallpaper', value: 'Wallpaper', type: 'wallpaper' },
        // { label: 'Boot Logo Image', value: 'Boot Logo Image',type:'wallpaper' },
        { label: 'Boot Animation', value: 'Boot Animation', type: 'bootAnimation' },
        /*
         * { label: 'Boot Sound', value: 'Boot Sound',type:'bootSound' },
         * { label: 'Buzzer Sound', value: 'Buzzer Sound',type:'bootBuzzer' }
         */
        { label: 'Shutdown Logo', value: 'Shutdown Logo', type: 'shutdownlogo' }
    ];

    const [mode, setMode] = useState(props.mode);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState({});
    const [currentDetailsObj, setCurrentDetailsObj] = useState(props.selectedRow || '');

    useEffect(() => {
        setMode(props.mode);
    }, []);
    useEffect(() => {
        setCurrentDetailsObj(props.selectedRow || '');
    }, []);

    useEffect(() => {
        props.saveFormData(currentDetailsObj);
    }, [currentDetailsObj]);

    useEffect(() => {
        props.formErrors(errors);
    }, [errors]);

    useEffect(() => {
        setCurrentDetailsObj({ ...currentDetailsObj, files: files });
    }, [files]);

    const handleChange = (e, field) => {
        setCurrentDetailsObj({ ...currentDetailsObj, [field.value]: e.target.value });
        validateFeild(e.target.value, field.value);
    };

    const fields = [{ key: 'name', validators: 'alphaNumeric' }];

    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => key === obj['key']);
        err = FormValidation.validateFileld(text[0]?.validators, key, ev);
        if (err[key]) errors[key] = true;
        else delete errors[key];

        if (ev === null || ev === '' || ev === undefined) delete errors[key];
        let prevErrors = { ...errors, ...err };
        setErrors(prevErrors);
    };

    const handleDownload = () => {
        DownloadBunnyFile(currentDetailsObj.url, currentDetailsObj.fileName, setLoading);
    };

    const inputComponent = (field) => {
        return (
            <div className="mb-3 mb-lg-0">
                <Row>
                    <Col xs={12} sm={12} md={12} xl={mode === 'view' ? 6 : 4} lg={mode === 'view' ? 6 : 4}>
                        <Label className="form-label d-flex align-items-center">
                            {field.label}
                            {mode !== 'view' ? <span className="red-color ps-1"> *</span> : <span className="ps-1"> :</span>}
                        </Label>
                    </Col>
                    <Col>
                        <div className="input-group">
                            {mode !== 'view' ? (
                                <Input
                                    id={field.label}
                                    type={field.type}
                                    name={field.value}
                                    className="form-control"
                                    maxLength={field.maxLength}
                                    placeholder={`Enter ${field.label}`}
                                    onChange={(e) => handleChange(e, field)}
                                    value={currentDetailsObj[field.value] || ''}
                                    invalid={errors[field.value] ? true : false}
                                />
                            ) : currentDetailsObj[field.value] ? (
                                currentDetailsObj[field.value]
                            ) : (
                                '–'
                            )}
                            {errors[field.value] ? (
                                <FormFeedback type="invalid">
                                    <div>{field.errorMsg}</div>
                                </FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const selectComponent = (field) => {
        return (
            <div className="mb-2 mb-lg-0">
                <Row>
                    <Col xs={12} sm={12} md={12} xl={mode === 'view' ? 6 : 4} lg={mode === 'view' ? 6 : 4}>
                        <Label className="form-label d-flex align-items-center fw-medium">
                            {field.label}
                            {mode === 'add' ? <span className="red-color ps-1"> *</span> : <span className="ps-1"> :</span>}
                        </Label>
                    </Col>
                    <Col>
                        {mode === 'add' ? (
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                isMulti={field.isMulti ? true : false}
                                isClearable={field.isMulti ? true : false}
                                id={field.label}
                                name={field.value}
                                options={field.options}
                                placeholder={`Select ${field.label}`}
                                onChange={(e) => handleChange({ e, target: { name: field.value, value: e } }, field)}
                                value={currentDetailsObj[field.value] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        ) : currentDetailsObj[field.value]?.label ? (
                            currentDetailsObj[field.value]?.label
                        ) : currentDetailsObj[field.value] ? (
                            currentDetailsObj[field.value]
                        ) : (
                            '–'
                        )}
                        {errors[field.value] ? (
                            <FormFeedback type="invalid">
                                <div>{field.errorMsg}</div>
                            </FormFeedback>
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    };

    const handleFiles = (fileItems) => {
        let longFiles = [];
        let validFiles = [];
        if (fileItems.length > 0) {
            longFiles = fileItems.filter((fileItem) => fileItem.filename?.length > 100);
            validFiles = fileItems.filter((fileItem) => fileItem.filename?.length <= 100);
        }
        setFiles(validFiles);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            {!loading && (
                <Form className="h-100">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                        <div className="h-100">
                            <div className="mb-3 mb-lg-0 d-flex flex-column gap-2">
                                {inputComponent({
                                    label: 'Asset Name',
                                    value: 'name',
                                    type: 'text',
                                    maxLength: 50,
                                    errorMsg: 'Name must be alphanumeric'
                                })}
                                {selectComponent({ label: 'Asset Type', value: 'assetType', options: assetTypeList })}
                                <div className="mb-3 mb-lg-0">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} xl={mode === 'view' ? 6 : 4} lg={mode === 'view' ? 6 : 4}>
                                            <Label className="form-label d-flex align-items-center">
                                                Attachement
                                                {mode === 'add' ? (
                                                    <span className="red-color ps-1"> *</span>
                                                ) : (
                                                    <span className="ps-1"> :</span>
                                                )}
                                            </Label>
                                        </Col>
                                        <Col>
                                            {mode === 'add' ? (
                                                <FilePond
                                                    name="files"
                                                    minFileSize="5KB"
                                                    maxFileSize="30MB"
                                                    maxFiles={1}
                                                    allowMultiple={true}
                                                    files={files}
                                                    className="filepond filepond-input-multiple"
                                                    onupdatefiles={(fileItems) => handleFiles(fileItems)}
                                                />
                                            ) : currentDetailsObj.url ? (
                                                <span
                                                    onClick={handleDownload}
                                                    className="text-success text-decoration-underline cursor-pointer"
                                                >
                                                    {currentDetailsObj.fileName}
                                                </span>
                                            ) : (
                                                '–'
                                            )}
                                            {mode === 'add' && (
                                                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                                                    File name should be less than 100 characters.
                                                </Alert>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form>
            )}
        </React.Fragment>
    );
};

export default AddAsset;
