export const LoginHistorySchema = [
    {
        Header: 'Username',
        accessor: 'username',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Activity',
        accessor: 'activity',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        customCell: true,
        fieldType: 'badge'
    },
    {
        Header: 'Application',
        accessor: 'app',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Type',
        accessor: 'authtype',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Activity Date',
        accessor: 'modifiedDate',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    }
];
