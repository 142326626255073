import toastMessages from '../../common/messages/toastMessages';

export const windowsPolicyTableSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Policy Code',
        accessor: 'code',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedby',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true,
        clone: true,
        deleteFadable: true,
        fadeTooltipKeyDelete: 'deleteTooltip',
        fade: 'doNotDelete'
    }
];

export const windowsDevicesSchema = [
    {
        Header: 'checkbox',
        customHeader: true,
        headerType: 'checkbox',
        customCell: true,
        fieldType: 'checkbox',
        accessor: 'checked',
        thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center',
        className: 'width-50 max-width-50',
        width: 80,
        maxWidth: 80,
        minWidth: 80
    },
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'fieldClickable',
        viewRoute: '/windowsdevices/view',
        fieldKey: 'serial',
        routeKey: '_id',
        view: true,
        copy: true,
        copyToastMsg: toastMessages.serialCopied
    },
    {
        Header: 'Policy',
        accessor: 'policy',
        sortable: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100
    },
    {
        Header: 'Group',
        accessor: 'group',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    // eslint-disable-next-line multiline-comment-style
    // {
    //     Header: 'Model',
    //     accessor: 'model',
    //     sortable: true,
    //     width: 100,
    //     minWidth: 100,
    //     maxWidth: 100
    // },
    {
        Header: 'Device Name',
        accessor: 'deviceName',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'OS Version',
        accessor: 'osversion',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Enrollment Date',
        accessor: 'enrolldate',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Enrollment Type',
        accessor: 'enrollmenttype',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Supervised',
        accessor: 'issupervised',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Device Status',
        accessor: 'deviceStatus',
        sortable: true,
        customCell: true,
        fieldType: 'badge'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        reboot: true,
        disableReboot: 'notsupervised',
        rebootTooltip: 'This device is not supervised',
        disableShutdown: 'notsupervised',
        shutdownTooltip: 'This device is not supervised',
        factoryreset: true,
        factoryResetTooltip: 'Erase Device'
        // delete: true
    }
];
