import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import AzureImage from '../../../assets/images/link-azure.png';

const LinkAzureDirectory = () => {
    const [azureLinkingData, setAzureLinkingData] = useState([]);

    const onConfigureAzure = () => {};

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">Link with Azure Directory</div>
                    <div
                        className={`d-flex justify-content-center align-items-center fs-12 ${
                            azureLinkingData.length === 0 ? 'text-danger' : 'text-primary'
                        }`}
                    >
                        {azureLinkingData.length === 0 ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-primary"></i>
                        )}
                        {azureLinkingData.length === 0 ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Bring Your Own Device (BYOD) in Mobile Device Management (MDM) refers to the practice of managing personal devices used
                    by employees for work purposes within an organization’s MDM environment.
                </div>
            </CardHeader>
            {azureLinkingData.length === 0 && (
                <div>
                    <CardBody>
                        <div className=" text-center py-20">
                            <div>
                                <img height={65} src={AzureImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Link with Azure Directory</div>
                            <div className="font-size-12 text-start fw-medium text-muted px-2">
                                Enrolling devices in BYOD MDM empowers organizations to have a balance between employee flexibility and
                                corporate security, enabling employees to use their preferred devices.
                            </div>
                            <div className="py-4">
                                <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onConfigureAzure()}>
                                    Configure
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </div>
            )}
        </Card>
    );
};

export default LinkAzureDirectory;
