import React from 'react';
import Flatpickr from 'react-flatpickr';
import Slider from 'react-rangeslider';
import Select from 'react-select';
import { Alert, Button, Col, FormFeedback, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { checkValue } from './PolicyUtil';

export const InputComponent = (props) => {
    return (
        (props.field.show
            ? props.field.showValues?.includes(props.applicationObj?.[props?.selectedAppData?.packageName]?.[props.field?.show]?.value)
            : true) && (
            <React.Fragment key={props.index}>
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={props.code || props.field.codeBased ? 7 : 5}
                    xl={props.code || props.field.codeBased ? 7 : 5}
                    className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                >
                    <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                        {props.field.label}
                        {(props.field.mandatory ||
                            (props.field.conditionalMandatory ? props.validation.values[props.field.condition] : false)) &&
                        props.formType !== 'view' &&
                        props.formType !== 'viewChecker' ? (
                            <span className="red-color ps-1">*</span>
                        ) : props.field.helpText ? (
                            <>
                                <i
                                    className={`ri-information-line d-flex ${
                                        props.field.notes ? '' : 'align-items-center'
                                    } blue-icon ps-1 fs-15`}
                                    id={`inputComponent-${props.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                    {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    <div className="input-group">
                        {props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                            <Input
                                name={props.field.value}
                                id={props.field.value}
                                className="form-control"
                                placeholder={`Enter ${props.field.label}`}
                                type={props.field.type}
                                validate={{ required: { value: true } }}
                                maxLength={props.field.maxLength}
                                onChange={
                                    props.selected && props.selected?.extraContent && !props.field?.exclude
                                        ? (e) => props.handleChange(e, [props.field.value], props.field.inputType)
                                        : props.validation?.handleChange
                                }
                                onBlur={props.validation?.handleBlur}
                                onKeyDown={(e) => props.handleKeyDown(e)}
                                value={
                                    props.selected && props.selected?.extraContent && !props.field.exclude
                                        ? props.applicationObj?.[props.selectedAppData?.packageName]?.[props.field?.value]
                                            ? props.applicationObj?.[props.selectedAppData?.packageName]?.[props.field?.value]
                                            : ''
                                        : props.validation?.values[props.field?.value] || ''
                                }
                                invalid={
                                    props.validation?.touched?.[props.field?.value] && props.validation?.errors?.[props.field?.value]
                                        ? true
                                        : false
                                }
                            />
                        ) : (props.selected && props.selected?.extraContent && !props.field?.exclude) ||
                          (props.selected && props.selected?.label === 'Launcher Settings' && !props.field?.normal) ? (
                            props.applicationObj?.[props.selectedAppData?.packageName]?.[props.field.value] ? (
                                props.applicationObj?.[props.selectedAppData?.packageName]?.[props.field.value]
                            ) : props.validation?.values[props.field.value] ? (
                                props.validation?.values[props.field.value]
                            ) : (
                                '–'
                            )
                        ) : props.validation?.values[props.field.value] ? (
                            props.validation?.values[props.field.value]
                        ) : (
                            '–'
                        )}
                        {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                            <FormFeedback type="invalid">{props.validation.errors[props.field.value]}</FormFeedback>
                        ) : null}
                    </div>
                    {props.field.notes && props.formType !== 'view' && (
                        <Alert className={`alert-borderless p-1 alert-${props.field.class} text-center mt-1 mb-0`} role="alert">
                            {props.field.notes}
                        </Alert>
                    )}
                </Col>
            </React.Fragment>
        )
    );
};

const textComponent = (field, index, code) => {
    return (
        <React.Fragment key={index}>
            <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5} className="d-flex align-items-center justify-content-between">
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {field.label}
                    {field.helpText ? (
                        <>
                            {' '}
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`textComponent-${index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`textComponent-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                {field.val?.label}
            </Col>
        </React.Fragment>
    );
};

export const selectComponent = (
    field,
    index,
    validation,
    code,
    formType,
    selected,
    handleChange,
    applicationObj,
    selectedAppData,
    mode,
    configurations,
    applicationData,
    assets,
    handleLaunchAppChange,
    handleLocationModeChange
) => {
    return (
        (field.show ? field.showValues?.includes(applicationObj[selectedAppData.packageName]?.[field.show]?.value) : true) && (
            <React.Fragment key={index}>
                <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {(field.mandatory || (field.conditionalMandatory ? validation.values[field.condition] : false)) &&
                        mode !== 'view' &&
                        formType !== 'viewChecker' ? (
                            <span className="red-color ps-1">*</span>
                        ) : field.helpText ? (
                            <>
                                <i
                                    className={`ri-information-line d-flex ${field.notes ? '' : 'align-items-center'} blue-icon ps-1 fs-15`}
                                    id={`inputComponent-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType !== 'view' && formType !== 'viewChecker' ? (
                        <Select
                            getOptionValue={(option) => (field.optionValue ? option[field.optionValue] : option.value)}
                            getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                            isMulti={field.isMulti ? true : false}
                            isClearable={field.isMulti || field.removable ? true : false}
                            closeMenuOnSelect={field.isMulti ? false : true}
                            id={field.label}
                            name={field.value}
                            // isDisabled={field.value === 'delegatedScopes' ? selectedAppData?.code === 'LAUNCHER' : false}
                            options={
                                field.optionsVal === 'geofenceConfigs'
                                    ? configurations?.geofences
                                    : field.optionsVal === 'callconfigs'
                                    ? configurations.callconfigs
                                    : field.optionsVal === 'acerodmfeatures'
                                    ? configurations.acerodmfeatures
                                    : field.optionsVal === 'contacts'
                                    ? configurations.contacts
                                    : field.optionsVal === 'firewallConfigs'
                                    ? configurations?.firewalls
                                    : field.optionsVal === 'simSettings'
                                    ? configurations?.simSettings
                                    : field.optionsVal === 'certificates'
                                    ? configurations?.certificates
                                    : field.optionsVal === 'packages'
                                    ? configurations?.packages
                                    : field.optionsVal === 'policies'
                                    ? applicationData
                                    : field.optionsVal === 'tracks'
                                    ? selectedAppData?.details?.tracks
                                        ? selectedAppData?.details?.tracks
                                        : []
                                    : field.optionsVal === 'managedConfigs'
                                    ? selectedAppData?.managedConfigs
                                    : field.optionsVal === 'wallpapers'
                                    ? assets.wallpaper
                                    : field.optionsVal === 'bootAnimations'
                                    ? assets.bootanimation
                                    : field.optionsVal === 'shutdownlogo'
                                    ? assets.shutdownlogo
                                    : field.options?.filter((option) => !option.hidden)
                            }
                            placeholder={`Select ${field.label}`}
                            onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                            onChange={
                                field.value === 'launchApp'
                                    ? (e) => handleLaunchAppChange(e)
                                    : field.value === 'locationMode'
                                    ? (e) => handleLocationModeChange(e)
                                    : (selected && selected?.extraContent) ||
                                      (selected && selected?.label === 'Launcher Settings' && !field.normal)
                                    ? (e) => handleChange(e, [field.value], 'dropdown')
                                    : (selectedOption) => {
                                          field.isMulti &&
                                          selectedOption?.length &&
                                          selectedOption?.find((option) => option.value === 'ALL_FEATURES')
                                              ? validation.handleChange({
                                                    target: { name: field.value, value: field.options.slice(1) }
                                                })
                                              : validation.handleChange({ target: { name: field.value, value: selectedOption } });
                                      }
                            }
                            value={
                                (selected && selected?.extraContent) ||
                                (selected && selected?.label === 'Launcher Settings' && !field.normal)
                                    ? applicationObj?.[selectedAppData?.packageName]?.[field.value]
                                        ? applicationObj?.[selectedAppData?.packageName]?.[field.value]
                                        : ''
                                    : validation.values[field.value] || ''
                            }
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    ) : (selected && selected?.extraContent) || (selected && selected?.label === 'Launcher Settings' && !field.normal) ? (
                        applicationObj?.[selectedAppData?.packageName]?.[field.value]?.length > 0 ? (
                            applicationObj?.[selectedAppData?.packageName]?.[field.value]
                                ?.map((v) => v?.label)
                                ?.toString()
                                ?.split(',')
                                ?.join(', ')
                        ) : applicationObj?.[selectedAppData?.packageName]?.[field.value]?.label ? (
                            applicationObj?.[selectedAppData?.packageName]?.[field.value]?.label
                        ) : applicationObj?.[selectedAppData?.packageName]?.[field.value]?.title ? (
                            applicationObj?.[selectedAppData?.packageName]?.[field.value]?.title
                        ) : Array.isArray(applicationObj?.[selectedAppData?.packageName]?.[field.value]) ? (
                            applicationObj?.[selectedAppData?.packageName]?.[field.value]?.length > 0 ? (
                                applicationObj?.[selectedAppData?.packageName]?.[field.value]
                            ) : (
                                '–'
                            )
                        ) : applicationObj?.[selectedAppData?.packageName]?.[field.value] ? (
                            applicationObj?.[selectedAppData?.packageName]?.[field.value]
                        ) : (
                            '–'
                        )
                    ) : validation?.values?.[field.value]?.length > 0 ? (
                        validation?.values?.[field.value]
                            ?.map((v) => (v?.label ? v?.label : v?.fileName))
                            ?.toString()
                            ?.split(',')
                            ?.join(', ')
                    ) : validation?.values?.[field.value]?.label || validation?.values?.[field.value]?.name ? (
                        validation?.values?.[field.value]?.label || validation?.values?.[field.value]?.name
                    ) : validation?.values[field.value] ? (
                        field.isMulti ? (
                            validation?.values[field.value]?.length === 0 ? (
                                '–'
                            ) : (
                                validation?.values[field.value]
                            )
                        ) : (
                            '–'
                        )
                    ) : (
                        '–'
                    )}
                </Col>
            </React.Fragment>
        )
    );
};

const radioButton = (field, index, code, formType, validation) => {
    return (
        <React.Fragment key={index}>
            <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5} className="d-flex align-items-center justify-content-between">
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {field.label}
                    {field.helpText ? (
                        <>
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id={`radioButton-${index}`} />
                            <UncontrolledTooltip placement="bottom" target={`radioButton-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                {formType !== 'view' && formType !== 'viewChecker' ? (
                    <>
                        <div className="form-check form-check-inline">
                            <Input
                                className="form-check-input"
                                type="radio"
                                id={field.value}
                                name={field.value}
                                value="true"
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                checked={validation.values[field.value] === 'true'}
                            />
                            <Label className="mb-0">{field.label1}</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input
                                className="form-check-input"
                                type="radio"
                                id={field.value}
                                name={field.value}
                                value="false"
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                checked={validation.values[field.value] === 'false'}
                            />
                            <Label className="mb-0">{field.label2}</Label>
                        </div>
                    </>
                ) : validation.values[field.value] === 'true' ? (
                    field.label1
                ) : validation.values[field.value] === 'false' ? (
                    field.label2
                ) : (
                    '–'
                )}
            </Col>
        </React.Fragment>
    );
};

const staticField = (field, index, code, formType) => {
    return (
        <React.Fragment key={index}>
            <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5} className="d-flex align-items-center justify-content-between">
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {field.label}
                    {field.helpText ? (
                        <>
                            {' '}
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id={`staticField-${index}`} />
                            <UncontrolledTooltip placement="bottom" target={`staticField-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                <div className="input-group">
                    {formType !== 'view' && formType !== 'viewChecker' ? (
                        <Input
                            name={field.value}
                            id={field.value}
                            className="form-control"
                            placeholder={`Enter ${field.label}`}
                            value={field.dataVal}
                            disabled={true}
                        />
                    ) : field.dataVal ? (
                        field.dataVal
                    ) : (
                        '–'
                    )}
                </div>
            </Col>
        </React.Fragment>
    );
};

const cardComponent = (field, index, code, formType, mode, handleCardClick, validation) => {
    return (
        <Row className="mt-2" key={index}>
            <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5}>
                <Label className="mb-0 fw-medium d-flex align-items-center">{mode !== 'view' && 'Select'} Launcher Type :</Label>
            </Col>
            <Col xs={3} md={3} sm={3} lg={code ? 5 : 6} xl={code ? 5 : 6}>
                {formType !== 'view' && formType !== 'viewChecker' ? (
                    <div className="d-flex align-items-center gap-3">
                        {field.options.map((option, ind) => (
                            <div className="form-check form-check-inline" key={ind}>
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    id={option.value}
                                    name={option.value}
                                    value="true"
                                    onClick={() => handleCardClick(option)}
                                    checked={validation.values[field.value] === option.value ? true : false}
                                />
                                <Label className="mb-0">{option.label}</Label>
                            </div>
                        ))}
                    </div>
                ) : validation.values[field.value] ? (
                    validation.values[field.value]?.replace('_', ' ')
                ) : (
                    '—'
                )}
            </Col>
        </Row>
    );
};

const chipsComponent = (
    field,
    index,
    code,
    formType,
    validation,
    handleKeyDown,
    handleAddFRPMail,
    isInvalidMail,
    handleRemoveOneFRPMail
) => {
    return (
        <Row className="mt-2" key={index}>
            <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5}>
                <Label className="mb-0 fw-medium d-flex align-items-center">{field.label}</Label>
            </Col>
            <Col xs={3} md={3} sm={3} lg={code ? 5 : 6} xl={code ? 5 : 6}>
                <div className="d-flex align-items-center">
                    {formType !== 'view' && formType !== 'viewChecker' && (
                        <>
                            <div className="input-group widthPer-60 me-2">
                                <Input
                                    name={field.value}
                                    id={field.value}
                                    className="form-control"
                                    placeholder={`Enter ${field.label}`}
                                    type={field.type}
                                    validate={{ required: { value: true } }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    value={validation.values[field.value] || ''}
                                    invalid={validation.touched[field.value] && validation.errors[field.value] ? true : false}
                                />
                            </div>
                            <Button
                                size="sm"
                                type="button"
                                disabled={!validation.values[field.value]}
                                color={'primary'}
                                onClick={() => handleAddFRPMail(field)}
                            >
                                <span className="d-flex align-items-center">
                                    <i className="ri-add-line me-1 fs-16"></i> Add FRP Mail
                                </span>
                            </Button>
                        </>
                    )}
                </div>
                {isInvalidMail ? <p className="mt-1 mb-1 text-danger fs-12">{field.invalidMsg}</p> : ''}
                <div
                    className={`filter-choices-input height-205 overflow-auto ${
                        formType === 'view' && formType !== 'viewChecker' ? '' : 'p-2'
                    }`}
                >
                    <div className="choices" data-type="text">
                        <div className="choices__inner min-height-1">
                            <div className="choices__list choices__list--multiple">
                                {validation.values?.[field.list]?.length > 0 &&
                                    validation.values?.[field.list]?.map((ele, ind) => {
                                        return (
                                            <div key={ind} className="choices__item choices__item--selectable bg-primary border-white">
                                                <div id={`bookmark-${ind}`}>
                                                    {ele}
                                                    {formType !== 'view' && formType !== 'viewChecker' && (
                                                        <button
                                                            type="button"
                                                            className="choices__button"
                                                            onClick={() => handleRemoveOneFRPMail(ele, field)}
                                                        >
                                                            Remove item
                                                        </button>
                                                    )}
                                                </div>
                                                {/* {Tooltip(`bookmark-${ind}`, ele)} */}
                                            </div>
                                        );
                                    })}
                                {validation.values?.[field.list]?.length === 0 && formType === 'view' && formType !== 'viewChecker'
                                    ? '—'
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const doubleDateComponent = (field, index, code, fp1, validation, fp2) => {
    return (
        <Row className="mt-2" key={index}>
            <Col xs={6} md={6} sm={6} lg={code ? 7 : 5} xl={code ? 7 : 5}>
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {field.label}
                    {field.mandatory ? <span className="red-color ps-1">*</span> : ''}
                </Label>
            </Col>
            <Col xs={3} md={3} sm={3} lg={code ? 5 : 6} xl={code ? 5 : 6}>
                <div className="d-flex align-items-center gap-2">
                    <div className="input-group flex-nowrap">
                        <Flatpickr
                            ref={fp1}
                            value={validation.values[field.value1]}
                            placeholder="Select Start Date"
                            className="form-control w-50 p-2"
                            options={{
                                mode: 'single',
                                dateFormat: 'd M, Y',
                                minDate: 'today',
                                maxDate: validation.values[field.value2] ? validation.values[field.value2] : undefined
                            }}
                            onChange={(e) => {
                                validation.setValues({ ...validation.values, [field.value1]: e[0] });
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                            <i className="ri-calendar-2-line"></i>
                        </div>
                    </div>
                    <div className="input-group flex-nowrap">
                        <Flatpickr
                            ref={fp2}
                            value={validation.values[field.value2]}
                            placeholder="Select End Date"
                            className="form-control w-50 p-2"
                            options={{
                                mode: 'single',
                                dateFormat: 'd M, Y',
                                minDate: validation.values[field.value1] ? validation.values[field.value1] : 'today'
                            }}
                            onChange={(e) => {
                                validation.setValues({ ...validation.values, [field.value2]: e[0] });
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                            <i className="ri-calendar-2-line"></i>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const rangeSlider = (field, index, formType, validation, formatLabel) => {
    return (
        <React.Fragment key={index}>
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {field.label}
                    {field.helpText ? (
                        <>
                            {' '}
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id={`rangeSlider-${index}`} />
                            <UncontrolledTooltip placement="bottom" target={`rangeSlider-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                {formType !== 'view' && formType !== 'viewChecker' && (
                    <div className="d-flex align-items-center justify-content-between">
                        <span>{field.minVal}</span>
                        <span>
                            Value: {validation.values[field.value]} {field?.formatLabel && `${field?.formatLabel}`}
                        </span>
                        <span>{field.maxVal}</span>
                    </div>
                )}
                {formType !== 'view' && formType !== 'viewChecker' ? (
                    <Slider
                        className="my-3"
                        min={field.minVal}
                        max={field.maxVal}
                        format={(value) => formatLabel(value, field)}
                        step={field?.step ? field.step : undefined}
                        onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                        onChange={(e) => validation.handleChange({ target: { name: field.value, value: e } })}
                        value={validation.values[field.value]}
                    />
                ) : validation?.values[field.value] ? (
                    validation?.values[field.value]
                ) : (
                    '–'
                )}
            </Col>
        </React.Fragment>
    );
};

export const toggleButton = (
    field,
    index,
    validation,
    code,
    formType,
    selected,
    handleChange,
    applicationObj,
    selectedAppData,
    handleAlert,
    launcherAppsData,
    handleLauncherList
) => {
    return (
        <React.Fragment key={index}>
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className={`d-flex ${field.notes ? '' : 'align-items-center'} justify-content-between`}>
                <Label className={`mb-0 fw-medium d-flex ${field.notes ? '' : 'align-items-center'}`}>
                    {field.label}
                    {field.helpText ? (
                        <>
                            <i
                                className={`ri-information-line d-flex ${field.notes ? '' : 'align-items-center'} blue-icon ps-1 fs-15`}
                                id={`toggleButton-${index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                <div>
                    {formType !== 'view' && formType !== 'viewChecker' ? (
                        <div className="form-check form-switch form-switch-lg">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={field.value}
                                id={field.value}
                                checked={
                                    field.apps
                                        ? !!launcherAppsData?.find((app) => app.packageName === selectedAppData?.packageName)?.[field.value]
                                        : selected && (selected?.extraContent || (selected?.label === 'Launcher Settings' && !field.normal))
                                        ? applicationObj?.[selectedAppData?.packageName]?.[field?.value]
                                            ? applicationObj?.[selectedAppData?.packageName]?.[field?.value]
                                            : false
                                        : validation?.values[field.value] || false
                                }
                                onChange={
                                    field?.showAlert
                                        ? (e) => handleAlert(e, field)
                                        : field.apps
                                        ? handleLauncherList
                                        : selected &&
                                          (selected?.extraContent || (selected?.label === 'Launcher Settings' && !field?.normal))
                                        ? (e) => handleChange(e, [field.value], 'checkbox')
                                        : validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                            />
                        </div>
                    ) : field.apps ? (
                        !!launcherAppsData?.find((app) => app.packageName === selectedAppData?.packageName)?.[field.value] ? (
                            'Yes'
                        ) : (
                            'No'
                        )
                    ) : selected && (selected?.extraContent || (selected?.label === 'Launcher Settings' && !field.normal)) ? (
                        applicationObj?.[selectedAppData?.packageName]?.[field?.value] ? (
                            'Yes'
                        ) : (
                            'No'
                        )
                    ) : validation?.values?.[field.value] ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </div>
                {field.notes && formType !== 'view' && (
                    <Alert className={`alert-borderless p-1 alert-${field.class} text-center mt-1 mb-0`} role="alert">
                        {field.notes}
                    </Alert>
                )}
            </Col>
        </React.Fragment>
    );
};

const noteComponent = (field, index, formType, validation, code) => {
    return (
        formType !== 'view' &&
        validation?.values?.[field.val]?.value === field.noteValue && (
            <Row className={`p-0 ${field.notes ? '' : 'align-items-center'}`} key={index}>
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={code ? 7 : 5}
                    xl={code ? 7 : 5}
                    className="d-flex align-items-center justify-content-between"
                ></Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    <Alert className={`alert-borderless alert-${field.noteColor} text-center mb-4 ps-0 pe-0`} role="alert">
                        {field.note}
                    </Alert>
                </Col>
            </Row>
        )
    );
};

const formatLabel = (value, fieldKey) => {
    if (checkValue(fieldKey?.formatLabel)) return value + ' ' + fieldKey.formatLabel;
    else return value;
};

export const DynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props.field.inputType === 'input' ? (
                <InputComponent
                    field={props.field}
                    index={props.index}
                    validation={props.validation}
                    code={props.code}
                    formType={props.formType}
                    selected={props.selected}
                    handleChange={props.handleChange}
                    handleKeyDown={props.handleKeyDown}
                    applicationObj={props.applicationObj}
                    selectedAppData={props.selectedAppData}
                />
            ) : props.field.inputType === 'toggle' ? (
                toggleButton(
                    props.field,
                    props.index,
                    props.validation,
                    props.code,
                    props.formType,
                    props.selected,
                    props.handleChange,
                    props.applicationObj,
                    props.selectedAppData,
                    props.handleAlert,
                    props.launcherAppsData,
                    props.handleLauncherList
                )
            ) : props.field.inputType === 'slider' ? (
                rangeSlider(props.field, props.index, props.formType, props.validation, formatLabel)
            ) : props.field.inputType === 'select' ? (
                selectComponent(
                    props.field,
                    props.index,
                    props.validation,
                    props.code,
                    props.formType,
                    props.selected,
                    props.handleChange,
                    props.applicationObj,
                    props.selectedAppData,
                    props.mode,
                    props.configurations,
                    props.applicationData,
                    props.assets,
                    props.handleLaunchAppChange,
                    props.handleLocationModeChange
                )
            ) : props.field.inputType === 'radio' ? (
                radioButton(props.field, props.index, props.code, props.formType, props.validation)
            ) : props.field.inputType === 'staticField' ? (
                staticField(props.field, props.index, props.code, props.formType)
            ) : props.field.inputType === 'text' ? (
                textComponent(props.field, props.index, props.code)
            ) : props.field.inputType === 'chips' ? (
                chipsComponent(
                    props.field,
                    props.index,
                    props.code,
                    props.formType,
                    props.validation,
                    props.handleKeyDown,
                    props.handleAddFRPMail,
                    props.isInvalidMail,
                    props.handleRemoveOneFRPMail
                )
            ) : props.field.inputType === 'cards' ? (
                cardComponent(props.field, props.index, props.code, props.formType, props.mode, props.handleCardClick, props.validation)
            ) : props.field.inputType === 'DoubleDatePicker' ? (
                doubleDateComponent(props.field, props.index, props.code, props.fp1, props.validation, props.fp2)
            ) : props.field.inputType === 'note' ? (
                noteComponent(props.field, props.index, props.formType, props.validation, props.code)
            ) : (
                props.field.isHeader &&
                (props.field.show
                    ? props.field.showValues?.includes(props.applicationObj[props.selectedAppData.packageName]?.[props.field.show]?.value)
                    : true) && <div className="fw-semibold text-decoration-underline text-success fs-14">{props.field.label} :</div>
            )}
        </React.Fragment>
    );
};
