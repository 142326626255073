import React, { useEffect, useState } from 'react';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { Tooltip, getChartsData } from '../../Components/Common/Util';
import NoRecordsFound from '../../Components/Common/NoRecordsFound';

const PowerOnDevices = (props) => {
    let dataColors = '["--EMM-success", "--EMM-light"]';
    let dataColorsArr = ['success', 'light'];
    var dountchartUserDeviceColors = getChartColorsArray(dataColors);

    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const [details, setDetails] = useState([]);
    const [totalDevices, setTotalDevices] = useState(props.totalDevices);

    useEffect(() => {
        setTotalDevices(props.details?.total);
        setLabels(props.details?.arr.map((detail) => detail.label));
        setSeries(props.details?.arr?.map((detail) => detail.count));
        let detailArr = JSON.parse(JSON.stringify(props.details?.arr));
        setDetails(detailArr);
    }, [props]);
    var options = getChartsData({ labels: labels, colors: dountchartUserDeviceColors, totalDevices: totalDevices, showTotal: true });

    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center fw-semibold d-flex gap-1 padding-y-25">
                    <h4 className="card-title mb-0">Power ON Devices (Post Enrollment)</h4>
                    <i className="ri-information-line link-info" id={'info-power-on'} />
                    {Tooltip(
                        'info-power-on',
                        'This chart indicates the number of devices that were powered on after the enrollment process.'
                    )}
                </CardHeader>
                <div className="bg-white">
                    <CardBody className="card-body">
                        {details?.length > 0 && totalDevices > 0 && (
                            <ReactApexChart dir="ltr" options={options} series={series} type="donut" height="250" className="apex-charts" />
                        )}
                        <div className="pt-2 ps-4 pe-4 bg-white">
                            <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                                {details?.length > 0 && totalDevices > 0 ? (
                                    details.map((ele, index) => {
                                        return (
                                            <li key={index} className="list-group-item px-0">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 ms-2">
                                                        <p className="fs-14 mb-0 d-flex align-items-center">
                                                            <i
                                                                className={`ri-checkbox-blank-circle-fill fs-14 align-middle text-${
                                                                    dataColorsArr[index % 6]
                                                                } me-1`}
                                                            ></i>
                                                            {ele.label}
                                                        </p>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <p className="fs-14 mb-0">{ele.count}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </ul>
                            {props.details.arr?.length > 3 && (
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <span className="text-info fw-medium cursor-pointer" onClick={props.showDetailsModal}>
                                        Show All
                                    </span>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default PowerOnDevices;
