import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { Tooltip, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { masterTenantSchema } from '../MasterTenantConfig/MasterTenantSchema';

const CreateTenantConfig = () => {
    document.title = 'Edit Tenant Config';
    const urlconf = useEnv();
    let api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    const [selected, setSelected] = useState(masterTenantSchema[0]);
    const [tenantDetails, setTenantDetails] = useState([masterTenantSchema[0]]);
    const [fileUploader, setFileUploader] = useState('');
    const [imageField, setImageField] = useState({});
    const [colors, setColors] = useState({});
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (recordID) {
            setLoading(false);
            getTenantConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordID]);

    const getTenantConfig = () => {
        api.get(url.TENANTS + '/bycode/' + recordID + '/config/BRANDING', '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setFormValues({
                        ...formValues,
                        COMPANY_NAME: resp.data.name,
                        COMPANY_LOGO: resp.data.COMPANY_LOGO,
                        COMPANY_SLIDE_BG1: resp.data.COMPANY_SLIDE_BG1,
                        FAV_ICON: resp.data.FAV_ICON,
                        PRIMARY_HEADER: resp.data.PRIMARY_HEADER,
                        SECONDARY_HEADER: resp.data.SECONDARY_HEADER,
                        COMPANY_FOOTER: resp.data.COMPANY_FOOTER
                    });
                    setColors({
                        ...colors,
                        COMPANY_PRIMARY_COLOR: resp.data.COMPANY_PRIMARY_COLOR,
                        COMPANY_SECONDARY_COLOR: resp.data.COMPANY_SECONDARY_COLOR,
                        COMPANY_SUCCESS_COLOR: resp.data.COMPANY_SUCCESS_COLOR
                    });
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...formValues
        },
        validationSchema: Yup.object({
            COMPANY_NAME: Yup.string().required('Please enter your company name')
            /*
             * EMAIL: Yup.string().email('Invalid Email ID.'),
             * ONLINE: Yup.string().matches(/^[0-9]+$/g, 'Should be a number'),
             * OFFLINE: Yup.string().matches(/^[0-9]+$/g, 'Should be a number')
             */
        }),
        onSubmit: (values) => {
            setLoading(true);
            let dataObj = {
                PRIMARY_HEADER: values?.PRIMARY_HEADER,
                SECONDARY_HEADER: values?.SECONDARY_HEADER,
                COMPANY_PRIMARY_COLOR: colors?.COMPANY_PRIMARY_COLOR,
                COMPANY_SECONDARY_COLOR: colors?.COMPANY_SECONDARY_COLOR,
                COMPANY_SUCCESS_COLOR: colors?.COMPANY_SUCCESS_COLOR,
                COMPANY_FOOTER: values?.COMPANY_FOOTER,
                COMPANY_LOGO: values?.COMPANY_LOGO ? values?.COMPANY_LOGO : undefined,
                COMPANY_SLIDE_BG1: values?.COMPANY_SLIDE_BG1 ? values?.COMPANY_SLIDE_BG1 : undefined,
                name: values?.COMPANY_NAME,
                FAV_ICON: values?.FAV_ICON
            };
            updateTenant(JSON.parse(JSON.stringify({ ...dataObj })));
        }
    });

    const updateTenant = (dataObj) => {
        setLoading(true);
        api.patch(url.TENANTS + '/bycode/' + recordID + '/config/BRANDING', dataObj, false, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.tenantUpdated);
                    validation.resetForm();
                    setLoading(false);
                    history.push('/mytenant');
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleTenantDetail = (tenantObj, index) => {
        tenantObj = { ...tenantObj, active: true };
        let tenantDetailsArr = [];
        tenantDetails.map((detail) => {
            if (tenantDetails.indexOf(detail) === index) return tenantDetailsArr.push(tenantObj);
            else return tenantDetailsArr.push({ ...detail, active: false });
        });
        setSelected(tenantObj);
        setTenantDetails(tenantDetailsArr);
    };

    const handleAddFile = (files, field) => {
        if (field.accept.some((extension) => files?.filename?.endsWith(extension))) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', files?.file);
            api.create(url.FILE, formData)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') validation.setValues({ ...validation.values, [field.value]: resp.data });
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        } else {
            toast.error(toastMessages.invalidFileFormat);
            validation.setValues({ ...validation.values, [field.value]: '' });
        }
    };

    const handleColorChange = (e, field) => {
        setColors({ ...colors, [field.value]: e.target.value });
    };

    const onClickImage = (field) => {};

    const inputComponent = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                    <Label className="form-check-label fw-medium d-flex align-items-center">
                        {field.label}
                        {formType !== 'view' ? (
                            field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    <div className={`input-group ${formType === 'view' ? '' : field.class ? field.class : ''}`}>
                        {formType !== 'view' ? (
                            <div className="d-flex align-items-center w-100">
                                <Input
                                    name={field.value}
                                    id={field.value}
                                    className={`form-control ${field.class ? field.class : 'w-100'}`}
                                    placeholder={`Enter ${field.label}`}
                                    type={field.type}
                                    maxLength={field.maxLength}
                                    validate={{ required: { value: true } }}
                                    onChange={field.type === 'color' ? (e) => handleColorChange(e, field) : validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={(field.type === 'color' ? colors[field.value] : validation.values[field.value]) || ''}
                                    invalid={validation.touched[field.value] && validation.errors[field.value] ? true : false}
                                />
                                {field.type === 'color' && <p className="m-0 ms-2">{colors[field.value] ? colors[field.value] : ''}</p>}
                            </div>
                        ) : field.type === 'color' ? (
                            colors[field.value] ? (
                                <div className="d-flex">
                                    <div className="width-20 height-20 me-2" style={{ backgroundColor: colors?.[field.value] }}></div>
                                    {colors[field.value]}
                                </div>
                            ) : (
                                '–'
                            )
                        ) : validation?.values[field.value] ? (
                            validation?.values[field.value]
                        ) : (
                            '–'
                        )}
                        {validation.touched[field.value] && validation.errors[field.value] ? (
                            <p className="m-0 mt-2 text-danger">{validation.errors[field.value]}</p>
                        ) : null}
                    </div>
                </Col>
            </>
        );
    };

    const toggleButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                    <Label className="form-check-label fw-medium d-flex align-items-center">
                        {field.label}
                        <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id={`toggleButton-${index}`} />
                        <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                            {field.helpText ? field.helpText : 'Helper Text'}
                        </UncontrolledTooltip>
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType !== 'view' ? (
                        <div className="form-check form-switch form-switch-lg">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={field.value}
                                id={field.value}
                                checked={validation.values[field.value]?.length > 0 || false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                            />
                        </div>
                    ) : validation?.values[field.value]?.length > 0 ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </Col>
            </>
        );
    };

    const uploadButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {formType !== 'view' ? (
                            field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType === 'view' ? (
                        validation.values[field.value] ? (
                            <span className="fw-medium link-secondary cursor-pointer" onClick={() => onClickImage(field)}>
                                <img src={validation.values[field.value]} alt={field.value} height={40} />
                            </span>
                        ) : (
                            '–'
                        )
                    ) : validation?.values?.[field.value] ? (
                        <div className="d-flex align-items-center jsutify-content-center">
                            <img src={validation.values[field.value]} alt={field.value} height={40} />
                            {validation.values?.[field.value] && (
                                <i
                                    className="ri-close-line link-primary cursor-pointer fs-20 ms-3"
                                    onClick={() => validation.setValues({ ...validation.values, [field.value]: null })}
                                />
                            )}
                        </div>
                    ) : (
                        <FilePond
                            name={field.value}
                            id={field.value}
                            minFileSize={field.noMinSize ? undefined : '5KB'}
                            maxFileSize="10MB"
                            maxFiles={1}
                            allowMultiple={true}
                            acceptedFileTypes={field.accept}
                            className="filepond filepond-input-multiple mb-0"
                            onaddfile={(_error, fileItems) => handleAddFile(fileItems, field)}
                        />
                    )}
                </Col>
            </>
        );
    };

    const selectComponent = (field, index) => {
        return (
            <div className="mb-2 mb-lg-0">
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            {field.label}
                            <i className="ri-information-line blue-icon ps-1" id={`UncontrolledTooltipExample-${index}`} />
                            <UncontrolledTooltip placement="bottom" target={`UncontrolledTooltipExample-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </Label>
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                        <div className="d-flex align-items-center">
                            {formType !== 'view' ? (
                                <Select
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.label}
                                    isMulti={field.isMulti ? true : false}
                                    isClearable={field.isMulti ? true : false}
                                    id={field.label}
                                    className="w-100"
                                    name={field.value}
                                    options={field.options}
                                    placeholder={`Select ${field.label}`}
                                    onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                                    onChange={(selectedOption) =>
                                        validation.handleChange({ target: { name: field.value, value: selectedOption } })
                                    }
                                    value={validation.values[field.value] || ''}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            ) : validation?.values?.[field.value]?.length > 0 ? (
                                validation?.values?.[field.value]
                                    ?.map((v) => v.label)
                                    ?.toString()
                                    ?.split(',')
                                    ?.join(', ')
                            ) : validation?.values[field.value]?.label ? (
                                validation?.values[field.value]?.label
                            ) : validation?.values[field.value] ? (
                                validation?.values[field.value]
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const inputDropdownComponent = (field, index) => {
        return (
            <div className="mb-2 mb-lg-0">
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        <Label className="form-check-label fw-medium d-flex align-items-center">
                            {field.label}
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`inputComponent-${index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                            {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                        </Label>
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        {formType !== 'view' ? (
                            <Row>
                                <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <Input
                                                name={field.quantity}
                                                id={field.quantity}
                                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                                placeholder={`Enter ${field.label}`}
                                                type={field.type}
                                                maxLength={field.maxLength}
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[field.quantity] || ''}
                                                invalid={
                                                    validation.touched[field.quantity] && validation.errors[field.quantity] ? true : false
                                                }
                                            />
                                            <p className="m-0 mt-2 font-size-10 text-danger">{validation.errors?.[field.quantity]}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                    <div className="d-flex align-items-center">
                                        <Select
                                            getOptionValue={(option) => option.value}
                                            getOptionLabel={(option) => option.label}
                                            isMulti={field.isMulti ? true : false}
                                            isClearable={field.isMulti ? true : false}
                                            id={field.label}
                                            className="w-100"
                                            name={field.unit}
                                            options={field.options}
                                            placeholder={`Select ${field.label}`}
                                            onBlur={() => validation.handleBlur({ target: { name: field.unit } })}
                                            onChange={(selectedOption) =>
                                                validation.handleChange({ target: { name: field.unit, value: selectedOption } })
                                            }
                                            value={validation.values[field.unit] || ''}
                                            isSearchable={true}
                                            noOptionsMessage={() => 'No data found'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ) : validation?.values[field.quantity] ? (
                            validation?.values[field.quantity] +
                            ' ' +
                            (validation?.values?.[field.unit]?.length > 0
                                ? validation?.values?.[field.unit]
                                      ?.map((v) => v.label)
                                      ?.toString()
                                      ?.split(',')
                                      ?.join(', ')
                                : validation?.values[field.unit]?.label
                                ? validation?.values[field.unit]?.label
                                : validation?.values[field.unit]
                                ? validation?.values[field.unit]
                                : '–')
                        ) : (
                            '–'
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    const dynamicComponents = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field.inputType === 'input'
                    ? inputComponent(field, index)
                    : field.inputType === 'toggle'
                    ? toggleButton(field, index)
                    : field.inputType === 'upload'
                    ? uploadButton(field, index)
                    : field.inputType === 'select'
                    ? selectComponent(field, index)
                    : field.inputType === 'input_dropdown'
                    ? inputDropdownComponent(field, index)
                    : field.isHeader && (
                          <div className="fw-semibold text-decoration-underline text-success fs-14 mb-2">{field.label} :</div>
                      )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={formType === 'view' ? 'View Tenant' : formType === 'edit' ? 'Edit Tenant' : 'Add Tenant'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="mytenant"
                    />
                </Container>
                <Card className="card-height-100">
                    <Form onSubmit={validation.handleSubmit}>
                        <CardHeader className="p-4">
                            <Row className="d-flex gap-2 flex-column">
                                <Col lg={6} className="d-flex flex-column gap-2">
                                    <Row>
                                        {inputComponent({
                                            label: 'Company Name',
                                            value: 'COMPANY_NAME',
                                            type: 'text',
                                            exclude: true,
                                            mandatory: true
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            <Row className={`${!validation.values.COMPANY_NAME ? 'mask pe-none' : ''}`}>
                                <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                    <Card className="h-100 border mb-0 shadow-none">
                                        <div className="chat-message-list">
                                            <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                                                {tenantDetails.map((tenant, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleTenantDetail(tenant, index)}
                                                            className={
                                                                'd-flex align-items-center p-2 cursor-pointer' +
                                                                (tenant.active ? ' active' : '')
                                                            }
                                                        >
                                                            <i className={`${tenant.icon} fs-18 ms-2 me-2`}></i>
                                                            <span className="text-truncate" id={'label-' + index}>
                                                                {tenant.label}
                                                                {tenant.label?.length > 22 && Tooltip('label-' + index, tenant.label)}
                                                            </span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </Card>
                                </Col>
                                {selected && selected !== '' && (
                                    <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                                        <Row sm={12} className="p-4 gap-3 align-items-center">
                                            {selected.fields?.length > 0 ? (
                                                selected.fields.map((field, index) => {
                                                    return (
                                                        (field.hide ? validation.values?.[field.hide]?.length > 0 : true) && (
                                                            <Row className="p-0 align-items-center" key={index}>
                                                                {dynamicComponents(field, index)}
                                                            </Row>
                                                        )
                                                    );
                                                })
                                            ) : (
                                                <NoRecordsFound text="Tenants" />
                                            )}
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                        <CardFooter>
                            {selected && selected !== '' && (
                                <Row className="p-0 m-0">
                                    <Col>
                                        <div className="gap-2 d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light" onClick={() => history.push('/mytenant')}>
                                                Cancel
                                            </button>
                                            {formType !== 'view' && (
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    id="add-btn"
                                                    disabled={!validation.values.COMPANY_NAME || validation.values.COMPANY_NAME === ''}
                                                >
                                                    {formType === 'edit' ? 'Update' : 'Save'}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </CardFooter>
                    </Form>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default CreateTenantConfig;
