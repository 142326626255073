import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import Slider from 'react-rangeslider';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { Tooltip, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { LANGUAGES } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { masterTenantSchema } from './MasterTenantSchema';

const AddMasterTenant = () => {
    document.title = 'Add Tenant';
    const urlconf = useEnv();
    let api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'add' ? 'Add Tenant' : formType === 'edit' ? ' Edit Tenant' : 'View Tenant';
    const [selected, setSelected] = useState(masterTenantSchema[0]);
    const [tenantDetails, setTenantDetails] = useState(masterTenantSchema);
    const [fileUploader, setFileUploader] = useState('');
    const [imageField, setImageField] = useState({});
    const [colors, setColors] = useState({});
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (recordID) {
            setLoading(true);
            getTenantConfig();
        } else defineFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordID]);

    const defineFormValues = () => {
        let obj = {};
        masterTenantSchema.forEach((schema) => {
            schema.fields?.forEach((field, ind) => {
                if ((schema.label === 'Modules' ? ind > 0 : true) && field.inputType === 'toggle') obj[field.value] = false;
            });
        });
        setFormValues({ ...obj });
    };

    const handleFieldValue = (field, config) => {
        let val = config;
        field.path.forEach((path) => {
            val = val?.[path];
        });
        return field.multiple ? val?.join(',') : val;
    };

    const handleToggleValue = (field, config) => {
        let val = config;
        field.path.forEach((path) => {
            val = val?.[path];
        });
        return !!val;
    };

    const handleSingleSelect = (field, config) => {
        let valStr = config;
        field.path.forEach((path) => {
            valStr = valStr?.[path];
        });
        let val = field.options?.find((option) => valStr === option.value);
        return val;
    };

    const handleMultiSelect = (field, config) => {
        let valStr = config;
        field.path.forEach((path) => {
            valStr = valStr?.[path];
        });
        if (field.checkObject) {
            valStr = valStr?.map((val) => val.value);
        }
        let val = valStr?.length > 0 ? field.options?.filter((option) => valStr?.includes(option.value)) : [];
        return val;
    };

    const handleTenantFields = (detail, obj, config) => {
        detail.fields?.forEach((field) => {
            if (field.path) {
                if (field.inputType === 'input' && field.multiple) obj[field.value] = handleFieldValue(field, config);
                else if (field.inputType === 'input' || field.inputType === 'upload') obj[field.value] = handleFieldValue(field, config);
                else if (field.inputType === 'toggle') obj[field.value] = handleToggleValue(field, config);
                else if (field.inputType === 'select' && !field.isMulti) obj[field.value] = handleSingleSelect(field, config);
                else if (field.inputType === 'select' && field.isMulti) obj[field.value] = handleMultiSelect(field, config);
            }
        });
        return obj;
    };

    const getTenantConfig = () => {
        api.get(url.TENANT_MASTER + '/' + recordID, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let obj = { COMPANY_NAME: resp.data.name };
                    masterTenantSchema?.forEach((detail) => {
                        if (detail.fields?.length > 0) {
                            obj = handleTenantFields(detail, obj, resp.data.config);
                        }
                    });
                    setFormValues({
                        ...obj
                    });
                    setColors({
                        ...colors,
                        COMPANY_PRIMARY_COLOR: resp.data.config?.BRANDING?.COMPANY_PRIMARY_COLOR,
                        COMPANY_SECONDARY_COLOR: resp.data.config?.BRANDING?.COMPANY_SECONDARY_COLOR,
                        COMPANY_SUCCESS_COLOR: resp.data.config?.BRANDING?.COMPANY_SUCCESS_COLOR
                    });
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const checkDDPolicy = (arr, val, optional) => {
        let filtered = arr?.filter((type) => type.value === val);
        if (optional && filtered?.length > 0) {
            return validation.values?.[optional]?.length > 0 ? false : true;
        } else {
            return filtered?.length > 0 ? true : false;
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...formValues
        },
        validationSchema: Yup.object({
            COMPANY_NAME: Yup.string().required('Please enter your company name'),
            defaultPassword: Yup.string().matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,12}$/,
                'Password should contain atleast one capital letter, one small letter, one digit, one special character and atleast 8 character length and maximum 12 characters.'
            ),
            EMAIL: Yup.string().email('Invalid Email ID.'),
            ONLINE: Yup.string().matches(/^[0-9]+$/g, 'Should be a number'),
            OFFLINE: Yup.string().matches(/^[0-9]+$/g, 'Should be a number')
        }),
        onSubmit: (values) => {
            if (checkSaveDisabled()) {
                toast.error(toastMessages.fillAllMandatoryFields);
                return;
            }
            setLoading(true);
            let found = false;
            if (
                (values?.ONLINE_UNIT?.value === values?.OFFLINE_UNIT?.value && values?.ONLINE > values?.OFFLINE) ||
                (values?.ONLINE_UNIT?.value === 'hours' && values?.OFFLINE_UNIT?.value !== 'hours') ||
                (values?.ONLINE_UNIT?.value === 'days' && values?.OFFLINE_UNIT?.value === 'hours') ||
                (values?.ONLINE_UNIT?.value === 'weeks' && values?.OFFLINE_UNIT?.value !== 'weeks')
            )
                found = true;
            if (found) {
                setLoading(false);
                toast.error(toastMessages.offlineTimeRestriction);
                return;
            }
            let dataObj = {
                tenantName: values?.COMPANY_NAME,
                ownerEmail: values?.EMAIL,
                ownerUsername: values?.userName,
                tenantConfig: {
                    BRANDING: {
                        PRIMARY_HEADER: values?.PRIMARY_HEADER,
                        SECONDARY_HEADER: values?.SECONDARY_HEADER,
                        COMPANY_PRIMARY_COLOR: colors?.COMPANY_PRIMARY_COLOR,
                        COMPANY_SECONDARY_COLOR: colors?.COMPANY_SECONDARY_COLOR,
                        COMPANY_SUCCESS_COLOR: colors?.COMPANY_SUCCESS_COLOR,
                        COMPANY_FOOTER: values?.COMPANY_FOOTER,
                        COMPANY_LOGO: values?.COMPANY_LOGO ? values?.COMPANY_LOGO : undefined,
                        COMPANY_SLIDE_BG1: values?.COMPANY_SLIDE_BG1 ? values?.COMPANY_SLIDE_BG1 : undefined,
                        name: values?.COMPANY_NAME,
                        FAV_ICON: values?.FAV_ICON
                    },
                    DASHBOARD: {
                        featureEnabled: values?.DASHBOARD,
                        settings: {
                            DASHBOARD_WIDGETS:
                                values?.DASHBOARD_WIDGETS?.length > 0 ? values?.DASHBOARD_WIDGETS?.map((widget) => widget.value) : [],
                            CUSTOM_DASHBOARD_EMAIL: values?.CUSTOM_DASHBOARD_EMAIL ? values?.CUSTOM_DASHBOARD_EMAIL?.split(',') : []
                        }
                    },
                    DEVICES: {
                        featureEnabled: values?.DEVICES,
                        settings: {
                            TABLE_COLUMNS: values?.TABLE_COLUMNS,
                            FILTER_PANELS: values?.FILTER_PANELS ? values?.FILTER_PANELS?.map((val) => val.value)?.join(',') : '',
                            DEVICE_ACTIONS:
                                validation.values.DEVICE_ACTIONS_MONITORING?.length > 0
                                    ? validation.values.DEVICE_ACTIONS_MONITORING?.map((val) => val.value)
                                    : [],
                            SHOW_SIM_INFO: validation.values.simSimInfo,
                            HOURLY_APP_USAGE: validation.values.HOURLY_APP_USAGE,
                            ENABLE_DATA_TRACKING: validation.values.enableDataTracking,
                            MAX_DATA_USAGE: validation.values.enableDataTracking ? validation.values.maxDataUsage : undefined,
                            MAX_DATA_USAGE_UNIT: validation.values.enableDataTracking ? validation.values.dataUnit?.value : undefined,
                            AUTO_REFRESH: !!validation.values.autoRefresh,
                            MAX_ANDROID_DEVICE: Number(validation.values.MAX_ANDROID_DEVICE)
                        }
                    },
                    TV_DEVICES: {
                        featureEnabled: values?.androidTvEnabled,
                        settings: {
                            DEVICE_ACTIONS:
                                validation.values.TV_DEVICE_ACTIONS_MONITORING?.length > 0
                                    ? validation.values.TV_DEVICE_ACTIONS_MONITORING?.map((val) => val.value)
                                    : [],
                            HOURLY_APP_USAGE: validation.values.TV_HOURLY_APP_USAGE
                        }
                    },
                    DEVICE_POLICY: {
                        featureEnabled: values?.DEVICE_POLICY,
                        settings: {
                            policyTypes: values?.EMM_POLICY_TYPES?.map((type) => type.value),
                            ddPolicyTypes: checkDDPolicy(values?.EMM_POLICY_TYPES, 'DD')
                                ? values?.EMM_DD_POLICY_TYPES?.map((type) => type.value)
                                : undefined,
                            POLICY_CONFIGURATIONS: values?.POLICY_CONFIGURATIONS
                                ? values?.POLICY_CONFIGURATIONS?.map((conf) => conf.value)
                                : null,
                            ENABLE_WORKFLOW: values?.workflow,
                            EMM_LAUNCHER_BRANDING: values?.EMM_LAUNCHER_BRANDING,
                            AE_ENABLE_DISABLE_PACKAGE: values?.AE_ENABLE_DISABLE_PACKAGE
                        }
                    },
                    TV_POLICY: {
                        settings: { ENABLE_TV_GEOFENCE: values.ENABLE_TV_GEOFENCE }
                    },
                    IOS_POLICY: {
                        settings: {
                            policyTypes: values?.IOS_POLICY_TYPES?.map((type) => type.value),
                            ddPolicyTypes: values?.IOS_DD_POLICY_TYPES?.map((type) => type.value)
                        }
                    },
                    IOS_DEVICES: {
                        featureEnabled: values?.appleIOSEnabled,
                        settings: {
                            MAX_IOS_DEVICE: Number(values?.MAX_IOS_DEVICE),
                            IOS_DEVICE_ACTIONS_MONITORING: values?.IOS_DEVICE_ACTIONS_MONITORING?.map((action) => action.value),
                            IOS_FILTER_PANELS:
                                values?.IOS_FILTER_PANELS?.length > 0 ? values?.IOS_FILTER_PANELS?.map((panel) => panel.value) : []
                        }
                    },
                    WINDOWS_POLICY: {
                        settings: {
                            policyTypes: values?.WINDOWS_POLICY_TYPES?.map((type) => type.value)
                        }
                    },
                    WINDOWS_DEVICES: {
                        featureEnabled: values?.windowsEnabled,
                        settings: {
                            MAX_WINDOWS_DEVICE: Number(values?.MAX_WINDOWS_DEVICE),
                            WINDOWS_DEVICE_ACTIONS_MONITORING: values?.WINDOWS_DEVICE_ACTIONS_MONITORING?.map((action) => action.value),
                            WINDOWS_FILTER_PANELS:
                                values?.WINDOWS_FILTER_PANELS?.length > 0 ? values?.WINDOWS_FILTER_PANELS?.map((panel) => panel.value) : []
                        }
                    },
                    DEVICE_GROUPS: { featureEnabled: values?.DEVICE_GROUPS, settings: {} },
                    /*
                     * DEVICE_AUTOMATION: {
                     *     featureEnabled: values?.DEVICE_AUTOMATION,
                     *     settings: {
                     *         features:
                     *             values.DEVICE_AUTOMATIONS_AVAILABLE?.length > 0
                     *                 ? values.DEVICE_AUTOMATIONS_AVAILABLE?.map((card) => card.value)
                     *                 : []
                     *     }
                     * },
                     * DEVICE_SETTINGS: { featureEnabled: values?.DEVICE_SETTINGS?.length > 0, settings: {} },
                     */
                    DEVICE_BULK_REQUEST: { featureEnabled: values?.DEVICE_BULK_REQUEST, settings: {} },
                    APPS: { featureEnabled: values?.APPS, settings: {} },
                    BROWSER: { featureEnabled: values?.BROWSER, settings: {} },
                    APP_PROTECTION_POLICY: { featureEnabled: values?.APP_PROTECTION_POLICY, settings: {} },
                    FILES: {
                        featureEnabled: values?.FILES,
                        settings: {
                            MAX_FILE_SIZE_QUANTITY: values?.maxFileSize,
                            MAX_FILE_SIZE_UNIT: values?.maxFileUnit?.value,
                            DEFAULT_PATH: values?.DEFAULT_PATH,
                            CONTENT_TYPES_ALLOWED:
                                values?.CONTENT_TYPES_ALLOWED?.length > 0
                                    ? values?.CONTENT_TYPES_ALLOWED?.map((val) => val.value)?.join(',')
                                    : ''
                        }
                    },
                    LAUNCHER_SETUP: { featureEnabled: values?.LAUNCHER_SETUP, settings: {} },
                    MESSAGES: {
                        featureEnabled: values?.MESSAGES,
                        settings: {
                            ENABLE_SCHEDULE_MESSAGE: validation.values?.ENABLE_SCHEDULE_MESSAGE,
                            ENABLE_RECALL: validation.values?.ENABLE_RECALL
                        }
                    },
                    REPORTS: {
                        featureEnabled: values?.REPORTS,
                        settings: {
                            ANDROID_REPORTS_CARDS:
                                values?.ANDROID_REPORTS_CARDS?.length > 0 ? values?.ANDROID_REPORTS_CARDS?.map((cards) => cards.value) : [],
                            TV_REPORTS_CARDS:
                                values?.TV_REPORTS_CARDS?.length > 0 ? values?.TV_REPORTS_CARDS?.map((card) => card.value) : []
                        }
                    },
                    FIREWALL: { featureEnabled: values?.FIREWALL, settings: {} },
                    GEOFENCE: { featureEnabled: values?.GEOFENCE, settings: {} },
                    TIMEFENCE: { featureEnabled: values?.TIMEFENCE, settings: {} },
                    WIFIFENCE: { featureEnabled: values?.WIFIFENCE, settings: {} },
                    SIM_SETTINGS: { featureEnabled: values?.SIM_SETTINGS, settings: {} },
                    CONTACT_LIST: { featureEnabled: values?.CONTACT_LIST, settings: {} },
                    CALL_CONFIG: { featureEnabled: values?.CALL_CONFIG, settings: {} },
                    PACKAGES: { featureEnabled: values?.PACKAGES, settings: {} },
                    CERTIFICATE_MANAGEMENT: { featureEnabled: values?.CERTIFICATE_MANAGEMENT, settings: {} },
                    ACER_ODM_FEATURES: { featureEnabled: values?.ACER_ODM_FEATURES, settings: {} },
                    CONDITIONAL_ACCESS_MAM: { featureEnabled: values?.CONDITIONAL_ACCESS_MAM, settings: {} },
                    CONTACT_MANAGEMENT: { featureEnabled: values?.CONTACT_MANAGEMENT, settings: {} },
                    ASSETS: {
                        featureEnabled: values?.ASSETS,
                        settings: {
                            ASSET_TYPES: values?.ASSET_TYPES?.length > 0 ? values?.ASSET_TYPES?.map((value) => value.value)?.join(',') : []
                        }
                    },
                    AUTH_MODES: { featureEnabled: values?.AUTH_MODES, settings: {} },
                    LICENSE: { featureEnabled: values?.LICENSE, settings: {} },
                    PASSWORD: { featureEnabled: values?.PASSWORD, settings: {} },
                    SETUP: { featureEnabled: values?.SETUP, settings: {} },
                    ADMIN: {
                        featureEnabled: values?.ADMIN_REPORTS,
                        settings: {
                            cards: values?.ADMIN_REPORT_CARDS?.length > 0 ? values?.ADMIN_REPORT_CARDS?.map((value) => value.value) : []
                        }
                    },
                    EMM: {
                        featureEnabled: values?.EMM_ENABLED,
                        settings: {
                            DEVICE_DEFAULT_PASSWORD: values?.defaultPassword,
                            MAX_FILE_SIZE_QUANTITY: values?.maxFileSize,
                            MAX_FILE_SIZE_UNIT: values?.maxFileUnit?.value,
                            CHROME_ENABLED: values?.chromeEnabled,
                            ANDROID_TV_ENABLED: values?.androidTvEnabled,
                            ANDROID_LEGACY_ENABLED: values?.androidLegacyEnabled,
                            ANDROID_ENTERPRISE_ENABLED: values?.androidEnterpriseEnabled,
                            ENABLE_QR_AUTHENTICATOR: values?.authenticator,
                            ENABLE_SIGN_IN: values?.enableSignIn,
                            APPLE_IOS_ENABLED: values?.appleIOSEnabled,
                            WINDOWS_ENABLED: values?.windowsEnabled
                        }
                    }
                }
            };
            if (formType === 'edit') {
                delete dataObj.ownerUsername;
                delete dataObj.ownerEmail;
                dataObj.config = dataObj.tenantConfig;
                delete dataObj.tenantConfig;
            }

            createNdUpdateTenant(JSON.parse(JSON.stringify({ ...dataObj })));
        }
    });

    const createNdUpdateTenant = (dataObj) => {
        let apiService = '';
        setLoading(true);
        if (formType === 'edit') apiService = api.patch(url.TENANT_MASTER + '/' + recordID, dataObj, false, domains.IDM);
        else apiService = api.create(url.TENANT_MASTER, dataObj, false, domains.IDM);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.tenantUpdated : toastMessages.tenantCreated);
                    validation.resetForm();
                    setLoading(false);
                    history.push('/tenantmaster');
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleTenantDetail = (tenantObj, index) => {
        tenantObj = { ...tenantObj, active: true };
        let tenantDetailsArr = [];
        tenantDetails.map((detail) => {
            if (tenantDetails.indexOf(detail) === index) return tenantDetailsArr.push(tenantObj);
            else return tenantDetailsArr.push({ ...detail, active: false });
        });
        setSelected(tenantObj);
        setTenantDetails(tenantDetailsArr);
    };

    const handleAddFile = (files, field) => {
        if (field.accept.some((extension) => files?.filename?.endsWith(extension))) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', files?.file);
            api.create(url.FILE, formData)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') validation.setValues({ ...validation.values, [field.value]: resp.data });
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        } else {
            toast.error(toastMessages.invalidFileFormat);
            validation.setValues({ ...validation.values, [field.value]: '' });
        }
    };

    const handleColorChange = (e, field) => {
        setColors({ ...colors, [field.value]: e.target.value });
    };

    const onClickImage = (field) => {};

    const checkSaveDisabled = () => {
        return (
            !validation.values.COMPANY_NAME ||
            validation.values.COMPANY_NAME === '' ||
            (formType === 'add'
                ? !validation.values.EMAIL ||
                  validation.values.EMAIL === '' ||
                  !validation.values.userName ||
                  validation.values.userName === '' ||
                  validation.errors.EMAIL
                : false) ||
            (!!validation.values.androidEnterpriseEnabled
                ? (!!validation.values.DEVICE_POLICY
                      ? !validation.values.EMM_POLICY_TYPES?.length || validation.values.EMM_POLICY_TYPES?.length === 0
                      : false) ||
                  (!!validation.values.DEVICES
                      ? !validation.values.MAX_ANDROID_DEVICE || validation.values.MAX_ANDROID_DEVICE === 0
                      : false) ||
                  (!!validation.values.REPORTS
                      ? !validation.values.ANDROID_REPORTS_CARDS || validation.values.ANDROID_REPORTS_CARDS?.length === 0
                      : false)
                : false) ||
            (!!validation.values.appleIOSEnabled
                ? (!!validation.values.DEVICE_POLICY
                      ? !validation.values.IOS_POLICY_TYPES?.length || validation.values.IOS_POLICY_TYPES?.length === 0
                      : false) ||
                  (!!validation.values.DEVICES ? !validation.values.MAX_IOS_DEVICE || validation.values.MAX_IOS_DEVICE === 0 : false)
                : false) ||
            (!!validation.values.androidTvEnabled && !!validation.values.REPORTS
                ? !validation.values.TV_REPORTS_CARDS || validation.values.TV_REPORTS_CARDS?.length === 0
                : false) ||
            (!!validation.values?.DASHBOARD ? !validation.values?.DASHBOARD_WIDGETS?.length > 0 : false) ||
            checkDDPolicy(validation?.values?.EMM_POLICY_TYPES, 'DD', 'EMM_DD_POLICY_TYPES') ||
            checkDDPolicy(validation?.values?.IOS_POLICY_TYPES, 'DD', 'IOS_DD_POLICY_TYPES') ||
            validation.errors.defaultPassword ||
            validation.values.defaultPassword === undefined ||
            validation.values.defaultPassword === '' ||
            (validation.values.enableDataTracking ? Number(validation.values.maxDataUsage) === 0 : false) ||
            /*
             * (validation.values.DEVICE_AUTOMATION
             *     ? validation.values.DEVICE_AUTOMATIONS_AVAILABLE?.length === 0 || !validation.values.DEVICE_AUTOMATIONS_AVAILABLE
             *     : false) ||
             */
            (validation.values.DEVICES || validation.values?.DEVICE_POLICY
                ? !(
                      validation.values.androidTvEnabled ||
                      validation.values.androidEnterpriseEnabled ||
                      validation.values.androidLegacyEnabled ||
                      validation.values.appleIOSEnabled ||
                      validation.values.windowsEnabled
                  )
                : false) ||
            (validation.values.windowsEnabled
                ? !validation.values.WINDOWS_POLICY_TYPES?.length ||
                  validation.values.WINDOWS_POLICY_TYPES?.length === 0 ||
                  !validation.values.MAX_WINDOWS_DEVICE ||
                  validation.values.MAX_WINDOWS_DEVICE === 0
                : false)
        );
    };

    const handleMaxDevicePageChange = (e, key) => {
        validation.setValues({ ...validation.values, [key]: parseInt(e.target.value) });
    };

    const handleMandatoryCondition = (conditions) => {
        let flag = true;
        conditions?.forEach((condition) => (flag = flag && !!validation.values[condition]));
        return flag;
    };

    const inputComponent = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {formType !== 'view' ? (
                            <>
                                {field.helpText && (
                                    <>
                                        <i
                                            className={`ri-information-line d-flex align-items-center ${
                                                field.helpIconColor ? field.helpIconColor : 'blue'
                                            }-icon ps-1 fs-15`}
                                            id={`inputComponent-${index}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                            {field.helpText ? field.helpText : 'Helper Text'}
                                        </UncontrolledTooltip>
                                    </>
                                )}
                                {(field.conditionalMandatory ? handleMandatoryCondition(field.mandatoryCondition) : field.mandatory) ? (
                                    <span className="red-color ps-1">*</span>
                                ) : (
                                    <span className="ps-1">:</span>
                                )}
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    <div className={`input-group ${formType === 'view' ? '' : field.class ? field.class : ''}`}>
                        {formType !== 'view' ? (
                            <div className="d-flex align-items-center w-100">
                                <Input
                                    name={field.value}
                                    id={field.value}
                                    className={`form-control ${field.class ? field.class : 'w-100'}`}
                                    placeholder={`Enter ${field.label}`}
                                    type={field.type}
                                    maxLength={field.maxLength}
                                    validate={{ required: { value: true } }}
                                    onChange={
                                        field.type === 'color'
                                            ? (e) => handleColorChange(e, field)
                                            : field.change === 'max_device_page_change'
                                            ? (e) => handleMaxDevicePageChange(e, field.value)
                                            : validation.handleChange
                                    }
                                    onBlur={validation.handleBlur}
                                    value={(field.type === 'color' ? colors[field.value] : validation.values[field.value]) || ''}
                                    invalid={validation.touched[field.value] && validation.errors[field.value] ? true : false}
                                />
                                {field.type === 'color' && <p className="m-0 ms-2">{colors[field.value] ? colors[field.value] : ''}</p>}
                            </div>
                        ) : field.type === 'color' ? (
                            colors[field.value] ? (
                                <div className="d-flex">
                                    <div className="width-20 height-20 me-2" style={{ backgroundColor: colors?.[field.value] }}></div>
                                    {colors[field.value]}
                                </div>
                            ) : (
                                '–'
                            )
                        ) : validation?.values[field.value] ? (
                            validation?.values[field.value]
                        ) : (
                            '–'
                        )}
                        {validation.touched[field.value] && validation.errors[field.value] ? (
                            <p className="m-0 mt-2 text-danger">{validation.errors[field.value]}</p>
                        ) : null}
                    </div>
                </Col>
            </>
        );
    };

    const toggleButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {field.helpText ? (
                            <>
                                {' '}
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType !== 'view' ? (
                        <div className="form-check form-switch form-switch-lg">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={field.value}
                                id={field.value}
                                checked={!!validation.values[field.value]}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                            />
                        </div>
                    ) : validation?.values[field.value] ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </Col>
            </>
        );
    };

    const uploadButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {formType !== 'view' ? (
                            field.conditionalMandatory ? (
                                handleMandatoryCondition(field.mandatoryCondition)
                            ) : field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType === 'view' ? (
                        validation.values[field.value] ? (
                            <span className="fw-medium link-secondary cursor-pointer" onClick={() => onClickImage(field)}>
                                <img src={validation.values[field.value]} alt={field.value} height={40} />
                            </span>
                        ) : (
                            '–'
                        )
                    ) : validation?.values?.[field.value] ? (
                        <div className="d-flex align-items-center jsutify-content-center">
                            <img src={validation.values[field.value]} alt={field.value} height={40} />
                            {validation.values?.[field.value] && (
                                <i
                                    className="ri-close-line link-primary cursor-pointer fs-20 ms-3"
                                    onClick={() => validation.setValues({ ...validation.values, [field.value]: null })}
                                />
                            )}
                        </div>
                    ) : (
                        <FilePond
                            name={field.value}
                            id={field.value}
                            minFileSize={field.noMinSize ? undefined : '5KB'}
                            maxFileSize="10MB"
                            maxFiles={1}
                            allowMultiple={true}
                            acceptedFileTypes={field.accept}
                            className="filepond filepond-input-multiple mb-0"
                            onaddfile={(_error, fileItems) => handleAddFile(fileItems, field)}
                        />
                    )}
                </Col>
            </>
        );
    };

    const selectComponent = (field, index) => {
        return (
            <div className="mb-2 mb-lg-0">
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            {field.label}
                            <i className="ri-information-line blue-icon ps-1" id={`UncontrolledTooltipExample-${index}`} />
                            <UncontrolledTooltip placement="bottom" target={`UncontrolledTooltipExample-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                            {formType !== 'view' &&
                            (field.conditionalMandatory ? handleMandatoryCondition(field.mandatoryCondition) : field.mandatory) ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                        <div className="d-flex align-items-center">
                            {formType !== 'view' ? (
                                <Select
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.label}
                                    isMulti={field.isMulti ? true : false}
                                    isClearable={field.isMulti ? true : false}
                                    id={field.label}
                                    className="w-100"
                                    name={field.value}
                                    closeMenuOnSelect={false}
                                    options={field.value === 'LANGUAGES' ? [...LANGUAGES] : field.options}
                                    placeholder={`Select ${field.label}`}
                                    onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                                    onChange={(selectedOption) =>
                                        validation.handleChange({ target: { name: field.value, value: selectedOption } })
                                    }
                                    value={validation.values[field.value] || ''}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            ) : validation?.values?.[field.value]?.length > 0 ? (
                                validation?.values?.[field.value]
                                    ?.map((v) => v.label)
                                    ?.toString()
                                    ?.split(',')
                                    ?.join(', ')
                            ) : validation?.values[field.value]?.label ? (
                                validation?.values[field.value]?.label
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const inputDropdownComponent = (field, index) => {
        return (
            <div className="mb-2 mb-lg-0">
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`inputComponent-${index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                            {formType !== 'view' ? (
                                (field.conditionalMandatory ? handleMandatoryCondition(field.mandatoryCondition) : field.mandatory) ? (
                                    <span className="red-color ps-1">*</span>
                                ) : (
                                    <span className="ps-1">:</span>
                                )
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center pe-0">
                        {formType !== 'view' ? (
                            <Row className="w-100">
                                <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                    <div className="d-flex align-items-center w-100">
                                        <Input
                                            name={field.quantity}
                                            id={field.quantity}
                                            className={`form-control ${field.class ? field.class : 'w-100'}`}
                                            placeholder={`Enter ${field.label}`}
                                            type={field.type}
                                            maxLength={field.maxLength}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values[field.quantity] || ''}
                                            invalid={validation.touched[field.quantity] && validation.errors[field.quantity] ? true : false}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={6} xl={6} className="pe-0">
                                    <div className="d-flex align-items-center w-100">
                                        <Select
                                            getOptionValue={(option) => option.value}
                                            getOptionLabel={(option) => option.label}
                                            isMulti={field.isMulti ? true : false}
                                            isClearable={field.isMulti ? true : false}
                                            id={field.label}
                                            className="w-100"
                                            name={field.unit}
                                            options={field.options}
                                            placeholder={field.dropdownPlaceholder}
                                            onBlur={() => validation.handleBlur({ target: { name: field.unit } })}
                                            onChange={(selectedOption) =>
                                                validation.handleChange({ target: { name: field.unit, value: selectedOption } })
                                            }
                                            value={validation.values[field.unit] || ''}
                                            isSearchable={true}
                                            noOptionsMessage={() => 'No data found'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ) : validation?.values[field.quantity] ? (
                            validation?.values[field.quantity] +
                            ' ' +
                            (validation?.values?.[field.unit]?.length > 0
                                ? validation?.values?.[field.unit]
                                      ?.map((v) => v.label)
                                      ?.toString()
                                      ?.split(',')
                                      ?.join(', ')
                                : validation?.values[field.unit]?.label
                                ? validation?.values[field.unit]?.label
                                : validation?.values[field.unit]
                                ? validation?.values[field.unit]
                                : '–')
                        ) : (
                            '–'
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    const sliderComponent = (field, index) => {
        return (
            <div className="mb-2 mb-lb-0">
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`inputComponent-${index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                {field.helpText ? field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                            {formType !== 'view' ? (
                                (field.conditionalMandatory ? handleMandatoryCondition(field.mandatoryCondition) : field.mandatory) ? (
                                    <span className="red-color ps-1">*</span>
                                ) : (
                                    <span className="ps-1">:</span>
                                )
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={6} xl={6} className="pe-0">
                        {formType === 'view' ? (
                            validation.values.maxDataUsage && (
                                <>{validation.values.maxDataUsage + ' ' + validation.values[field.unit]?.label}</>
                            )
                        ) : (
                            <Row>
                                <Col sm={9}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>{field.min}</span>
                                        <span>
                                            Value: {validation.values.maxDataUsage ? validation.values.maxDataUsage : 0}{' '}
                                            {validation.values.dataUnit?.label ? validation.values.dataUnit?.label : 'KB'}
                                        </span>
                                        <span>{field.max}</span>
                                    </div>
                                    <div className="custom-range-slider-info">
                                        <Slider
                                            className="my-3"
                                            min={field.min}
                                            max={field.max}
                                            step={validation.values.dataUnit?.step}
                                            onBlur={() => validation.handleBlur({ target: { name: 'maxDataUsage' } })}
                                            onChange={
                                                formType === 'view'
                                                    ? null
                                                    : (e) => validation.handleChange({ target: { name: 'maxDataUsage', value: e } })
                                            }
                                            value={validation.values.maxDataUsage}
                                        />
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div className="d-flex align-items-center w-100">
                                        <Select
                                            getOptionValue={(option) => option?.[field.optionValue]}
                                            getOptionLabel={(option) => option.label}
                                            id={field.label}
                                            className="w-100"
                                            name={field.unit}
                                            options={field.options}
                                            placeholder={field.dropdownPlaceholder}
                                            onBlur={() => validation.handleBlur({ target: { name: field.unit } })}
                                            onChange={(selectedOption) =>
                                                validation.handleChange({ target: { name: field.unit, value: selectedOption } })
                                            }
                                            value={validation.values[field.unit] || ''}
                                            isSearchable={true}
                                            noOptionsMessage={() => 'No units found'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    const handleSelect = (field) => {
        const masterSchema = JSON.parse(JSON.stringify(masterTenantSchema));
        const modules = masterSchema?.find((schema) => schema.label === 'Modules');
        let obj = {};
        modules.fields?.forEach((module) => {
            if (module.inputType === 'toggle') obj[module.value] = !!(field.value === 'selectAll');
        });
        validation.setValues({ ...validation.values, ...obj });
    };

    const selectButtonComponent = (field, index) => {
        return (
            <div className="d-flex align-items-center gap-3">
                {field.buttons.map((button, ind) => (
                    <Button size="sm" key={ind} outline type="button" color="primary" onClick={() => handleSelect(button)}>
                        <span className="d-flex align-items-center" id={`selectButton-${index}`}>
                            <i className={`${button.icon} me-1 fs-16`}></i> {button.label}
                        </span>
                    </Button>
                ))}
            </div>
        );
    };

    const dynamicComponents = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field.inputType === 'input'
                    ? inputComponent(field, index)
                    : field.inputType === 'toggle'
                    ? toggleButton(field, index)
                    : field.inputType === 'upload'
                    ? uploadButton(field, index)
                    : field.inputType === 'select'
                    ? selectComponent(field, index)
                    : field.inputType === 'input_dropdown'
                    ? inputDropdownComponent(field, index)
                    : field.inputType === 'buttons' && formType !== field.hideMode
                    ? selectButtonComponent(field, index)
                    : field.inputType === 'slider'
                    ? sliderComponent(field, index)
                    : field.isHeader && (
                          <div className="mb-2">
                              <span className="fw-semibold text-decoration-underline text-success fs-14 ">{field.label}</span>
                              {formType === 'view' ? (
                                  <span className="ps-1">:</span>
                              ) : (
                                  (field.conditionalMandatory ? handleMandatoryCondition(field.mandatoryCondition) : field.mandatory) && (
                                      <span className="red-color ps-1">*</span>
                                  )
                              )}
                          </div>
                      )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={
                            formType === 'view' ? 'View Tenant Master' : formType === 'edit' ? 'Edit Tenant Master' : 'Add Tenant Master'
                        }
                        history={history}
                        showBack={true}
                        homeLink="Dashboard"
                        backLink="tenantmaster"
                    />
                </Container>
                <Card className="card-height-100">
                    <Form onSubmit={validation.handleSubmit}>
                        <CardHeader className="p-4">
                            <Row>
                                {formType === 'add' && (
                                    <Col lg={6} className="d-flex flex-column gap-2">
                                        <>
                                            <Row>
                                                {inputComponent({
                                                    label: 'Email ID',
                                                    value: 'EMAIL',
                                                    type: 'email',
                                                    exclude: true,
                                                    mandatory: true
                                                })}
                                            </Row>
                                            <Row>
                                                {inputComponent({
                                                    label: 'User Name',
                                                    value: 'userName',
                                                    type: 'text',
                                                    exclude: true,
                                                    mandatory: true
                                                })}
                                            </Row>
                                        </>
                                    </Col>
                                )}
                                <Col lg={6} className="d-flex flex-column gap-2">
                                    <Row>
                                        {inputComponent({
                                            label: 'Company Name',
                                            value: 'COMPANY_NAME',
                                            type: 'text',
                                            exclude: true,
                                            mandatory: true
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            <Row className={`${!validation.values.COMPANY_NAME ? 'mask pe-none' : ''}`}>
                                <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                    <Card className="h-100 border mb-0 shadow-none">
                                        <div className="chat-message-list">
                                            <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                                                {tenantDetails.map((tenant, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleTenantDetail(tenant, index)}
                                                            className={
                                                                'd-flex align-items-center p-2 cursor-pointer' +
                                                                (tenant.active ? ' active' : '')
                                                            }
                                                        >
                                                            <i className={`${tenant.icon} fs-18 ms-2 me-2`}></i>
                                                            <span className="text-truncate" id={'label-' + index}>
                                                                {tenant.label}
                                                                {tenant.label?.length > 22 && Tooltip('label-' + index, tenant.label)}
                                                            </span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </Card>
                                </Col>
                                {selected && selected !== '' && (
                                    <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                                        <Row sm={12} className="p-4 gap-3 align-items-center">
                                            {selected.fields?.length > 0 ? (
                                                selected.fields?.map((field, index) => {
                                                    return (
                                                        (field.show
                                                            ? validation.values[field.show]?.length > 0 > 0
                                                            : field.hide
                                                            ? field.checkInArr
                                                                ? validation.values?.[field.hide]?.find(
                                                                      (val) => val.value === field.checkVal
                                                                  )
                                                                : validation.values?.[field.hide]?.length > 0
                                                            : true) && (
                                                            <Row className="p-0 align-items-center" key={index}>
                                                                {dynamicComponents(field, index)}
                                                            </Row>
                                                        )
                                                    );
                                                })
                                            ) : (
                                                <NoRecordsFound text="Tenants" />
                                            )}
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                        <CardFooter>
                            {selected && selected !== '' && (
                                <Row className="p-0 m-0">
                                    <Col>
                                        <div className="gap-2 d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light" onClick={() => history.push('/tenantmaster')}>
                                                Cancel
                                            </button>
                                            {formType !== 'view' && (
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    id="add-btn"
                                                    disabled={checkSaveDisabled()}
                                                >
                                                    {formType === 'edit' ? 'Update' : 'Save'}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </CardFooter>
                    </Form>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default AddMasterTenant;
