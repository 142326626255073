/* eslint-disable no-lone-blocks */
/* eslint-disable multiline-comment-style */
import React, { useState } from 'react';
import { Container, Card, CardHeader, CardBody, Row, Col, Label, Input, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Flatpickr from 'react-flatpickr';
import { useFormik } from 'formik';
import * as Yup from 'yup';
const AddLicense = () => {
    const [selectedStartDate, setSelectedStartDate] = useState('Select License Start Date');
    const [selectEndDate, setSelectEndDate] = useState('Enter License End Date');
    const handleDateChange = (date) => {
        setSelectedStartDate(date[0]);
    };
    const handleEndDateChange = (date) => {
        setSelectEndDate(date[0]);
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { devices: '', startDate: '', endDate: '' },
        validationSchema: Yup.object({
            devices: Yup.string(),
            startDate: Yup.string(),
            endDate: Yup.string()
        }),
        onSubmit: (values) => {}
    });
    const handleClick = () => {
        validation.handleSubmit();
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="License Management" pageTitle="License" />
                    <Row>
                        <Col className="col-6 m-auto my-12">
                            <Card className="rounded-4 d-flex justify-content-center align-items-center py-10">
                                <CardHeader className="border-bottom-0 ">
                                    <div className="d-flex justify-content-center align-items-center fw-semibold fs-16">
                                        <div>{/* <img src={''} alt="college logo"></img> */}</div>
                                        <div>Sri Chaitanya Educational Institute</div>
                                    </div>
                                </CardHeader>
                                <CardBody className="px-0">
                                    <div className="py-3">
                                        <Label htmlFor="devices">Device Count</Label>
                                        <Input
                                            type="text"
                                            name="devices"
                                            id="devices"
                                            placeholder="Enter Device Count"
                                            value={validation.values.devices}
                                        ></Input>
                                    </div>
                                    <div className="py-3">
                                        <Label for="startdate">License Start Date</Label>
                                        <Flatpickr
                                            id="startdate"
                                            className="form-control"
                                            placeholder={selectedStartDate}
                                            value={selectedStartDate}
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                    <div className="py-3">
                                        <Label for="enddate">License End Date</Label>
                                        <Flatpickr
                                            id="enddate"
                                            className="form-control"
                                            placeholder={selectEndDate}
                                            value={selectEndDate}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="px-1">
                                            <Button className="bg-primary text-white border-none px-5" onClick={handleClick}>
                                                Submit
                                            </Button>
                                        </div>
                                        <div className="px-1">
                                            <Button className="bg-light text-primary border-primary">Save as Draft</Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddLicense;
