import React from 'react';
import { Alert, Col, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';

export const radioComponent = (props) => {
    return (
        <React.Fragment key={props.index}>
            <Col
                xs={12}
                sm={12}
                md={props.field.customCol ? 7 : 12}
                xl={props.field.customCol ? 7 : 12}
                lg={props.field.customCol ? 7 : 12}
            >
                <>
                    <div className="form-check form-check-inline">
                        <Input
                            className="form-check-input"
                            type="radio"
                            id={props.field.value}
                            name={props.field.value}
                            value={props.field.val}
                            onBlur={props.validation.handleBlur}
                            onChange={props.validation.handleChange}
                            checked={props.validation.values[props.field.value] === props.field.val}
                            disabled={props.formType === 'view'}
                        />
                        <Label className="form-check-label">{props.field.label}</Label>
                    </div>
                    <div className="text-muted  mb-2 word-wrap-break padding-left-per-2">
                        {props.field.helpText && props.field.helpText}
                    </div>
                </>
            </Col>
        </React.Fragment>
    );
};

export const selectComponent = (props) => {
    return (
        <Row className={`mb-3 pb-3 ${props.field.customBorder && 'border-bottom-dashed border-light'}`}>
            <Col
                md={props.field.customCol ? 6 : 12}
                xl={props.field.customCol ? 5 : 12}
                lg={props.field.customCol ? 5 : 12}
                className="d-flex align-items-center"
            >
                <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-14">
                    {props.field.label}
                    {props.field.helpText && (
                        <>
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`toggleButton-${props.index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`toggleButton-${props.index}`}>
                                {props.field.helpText ? props.field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    )}
                </Label>
            </Col>
            <div>
                <Col md={props.field.customCol ? 6 : 12} xl={props.field.customCol ? 5 : 12} lg={props.field.customCol ? 5 : 12}>
                    {props.field.helpText && <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText}</div>}
                </Col>
            </div>
            <Col md={props.field.customCol ? 6 : 12} xl={props.field.customCol ? 5 : 12} lg={props.field.customCol ? 5 : 12}>
                {props.formType !== 'view' ? (
                    <Select
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        isMulti={props.field.isMulti ? true : false}
                        isClearable={props.field.isMulti ? true : false}
                        id={props.field.label}
                        name={props.field.value}
                        closeMenuOnSelect={!props.field.isMulti}
                        options={props.field.options}
                        placeholder={props.field.placeholder}
                        onBlur={() => props.validation.handleBlur({ target: { name: props.field.value } })}
                        onChange={(selectedOption) => {
                            props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } });
                        }}
                        value={props.validation?.values[props.field.value] || ''}
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                    props.validation?.values?.[props.field.value]
                        ?.map((v) => v.label)
                        ?.toString()
                        ?.split(',')
                        ?.join(', ')
                ) : props.validation?.values[props.field.value]?.label ? (
                    props.validation?.values[props.field.value]?.label
                ) : props.validation?.values[props.field.value] ? (
                    props.validation?.values[props.field.value]
                ) : (
                    '–'
                )}
            </Col>
        </Row>
    );
};
export const inputComponent = (props) => {
    return (
        <Row>
            {props.field.label && (
                <Col
                    xs={props.field.smallLabel ? 2 : 4}
                    sm={props.field.smallLabel ? 2 : 4}
                    md={props.field.smallLabel ? 2 : 4}
                    xl={props.field.smallLabel ? 2 : 4}
                    lg={props.field.smallLabel ? 2 : 4}
                    className="d-flex align-items-center justify-content-between"
                >
                    <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                        {props.field.label}
                        {props.formType !== 'view' ? (
                            props.field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : props.field.helpText ? (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${props.index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                        {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                ''
                            )
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
            )}
            {props.field.customText && (
                <Row>
                    <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex align-items-center justify-content-between">
                        <div>
                            {props.field.customText && <div className="text-muted  mb-2 word-wrap-break">{props.field.customText}</div>}
                        </div>
                    </Col>
                </Row>
            )}

            <Col
                xs={props.field.largeBox ? 6 : 4}
                sm={props.field.largeBox ? 6 : 4}
                md={props.field.largeBox ? 6 : 4}
                xl={props.field.largeBox ? 6 : 4}
                lg={props.field.largeBox ? 6 : 4}
            >
                <div className={`input-group ${props.formType === 'view' ? '' : props.field.class ? props.field.class : ''}`}>
                    {props.formType !== 'view' ? (
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={props.field.value}
                                id={props.field.value}
                                className={`form-control ${props.field.class ? props.field.class : 'w-100'}`}
                                placeholder={`Enter ${props.field.placeholder}`}
                                type={props.field.type}
                                max={props.field.maxValue}
                                maxLength={props.field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={(e) => {
                                    props.field.type === 'number'
                                        ? props.handleNumberChnage(e, props.field)
                                        : props.validation.handleChange(e);
                                }}
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values[props.field.value] || ''}
                                invalid={
                                    props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? true : false
                                }
                            />
                            {props.field.type === 'color' && (
                                <p className="m-0 ms-2">
                                    {props.validation.values[props.field.value] ? props.validation.values[props.field.value] : ''}
                                </p>
                            )}
                        </div>
                    ) : props.validation?.values[props.field.value] ? (
                        props.validation?.values[props.field.value]
                    ) : (
                        '–'
                    )}
                    {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                        <p className="m-0 mt-2 text-danger">{props.validation.errors[props.field.value]}</p>
                    ) : null}
                </div>
            </Col>
        </Row>
    );
};
export const fieldEnableToggle = (props) => {
    return (
        <>
            <Row key={props.index} className={'mb-3 ' + (props.field.class ? props.field.class : '')}>
                {props.field.label && (
                    <Col
                        xs={props.field.smallLabel ? 2 : 6}
                        sm={props.field.smallLabel ? 2 : 6}
                        md={props.field.smallLabel ? 2 : 6}
                        xl={props.field.smallLabel ? 2 : 6}
                        lg={props.field.smallLabel ? 2 : 6}
                    >
                        <Label
                            className={`form-check-label fw-semibold fs-14 d-flex align-items-center mb-0 ${
                                props.field.customCSS && 'text-success'
                            }`}
                        >
                            {props.field.label ? props.field.label : 'Feature Enabled'}
                        </Label>
                        <div>
                            <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText && props.field.helpText}</div>
                        </div>
                    </Col>
                )}

                <Col xs={4} sm={4} md={4} xl={4} lg={4}>
                    {props.formType !== 'view' ? (
                        <div className="form-check form-switch form-switch-lg">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={props.field.value}
                                id={props.field.value}
                                disabled={props.field.disabled}
                                checked={!!props.validation.values[props.field.value]}
                                onChange={props.validation.handleChange}
                            />
                        </div>
                    ) : !!props.validation.values?.[props.field.value] ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </Col>
            </Row>
            {props.field?.fields && !!props.validation.values[props.field.value] && (
                <Row className={`${props.field.customBorder && 'border-bottom-dashed border-light mb-2'}`}>
                    {props.field?.fields?.map((ele, ind) => {
                        return (
                            <Col key={ind}>
                                <DynamicComponents field={ele} index={ind} formType={props.formType} validation={props.validation} />
                            </Col>
                        );
                    })}
                </Row>
            )}
        </>
    );
};
export const heading = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props.field.alert && props.field.noteVal?.includes(props.validation.values[props.field.noteCondition]) && (
                <Row className="mb-3">
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        Note: This option will work only if you have given the ICCID number against the SIM slot while uploading the bulk
                        device information.
                    </Alert>
                </Row>
            )}
            <Col
                xs={12}
                sm={12}
                md={props.field.customCol ? 7 : 12}
                xl={props.field.customCol ? 7 : 12}
                lg={props.field.customCol ? 7 : 12}
            >
                <div className="fw-semibold text-success fs-14">{props.field.label}</div>
                <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText && props.field.helpText}</div>
            </Col>
        </React.Fragment>
    );
};
export const radioButton = (props) => {
    return (
        <React.Fragment key={props.index}>
            <Col
                xs={6}
                md={6}
                sm={6}
                lg={props.field.code ? 7 : 5}
                xl={props.field.code ? 7 : 5}
                className="d-flex align-items-center justify-content-between"
            >
                <Label className="mb-0 fw-medium d-flex align-items-center fs-14">{props.field.label}</Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex align-items-center justify-content-between">
                <div>{props.field.helpText && <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                <>
                    {props.field?.radios?.map((ele, ind) => {
                        return (
                            <div className="form-check form-check-inline" key={ind}>
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    id={props.field.value}
                                    name={props.field.value}
                                    value={ele.val}
                                    onChange={props.validation.handleChange}
                                    onBlur={props.validation.handleBlur}
                                    checked={props.validation.values[props.field.value] === ele.val}
                                    disabled={props.formType === 'view'}
                                />
                                <Label className="mb-0">{ele.label}</Label>
                            </div>
                        );
                    })}
                </>
            </Col>
        </React.Fragment>
    );
};
export const DynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props?.field?.inputType === 'input'
                ? inputComponent({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType,
                      handleNumberChnage: props.handleNumberChnage
                  })
                : props?.field?.inputType === 'select'
                ? selectComponent({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : props?.field?.inputType === 'toggle'
                ? fieldEnableToggle({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : props?.field?.inputType === 'heading'
                ? heading({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : props.field.inputType === 'radios'
                ? radioButton({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : props.field.inputType === 'radio'
                ? radioComponent({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : ''}
        </React.Fragment>
    );
};
