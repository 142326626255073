/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable multiline-comment-style */
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import {
    AuthUser,
    TenantConfig,
    Tooltip,
    convertBytesTo,
    convertSecondsTo,
    formatDate,
    getDate,
    sortBy,
    sortByAsc
} from '../../../Components/Common/Util';
import AndroidVersion from '../../Dashboard/AndroidVersion';
import AppInstall from '../../Dashboard/AppInstall';
import DashboardWidgets from '../../Dashboard/DashboardWidgets';
import Manufacturer from '../../Dashboard/Manufacturer';
import OnlineOfflineChart from '../../Dashboard/OnlineOfflineChart';

import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import TableContainer from '../../../Components/Common/TableContainer';
import huawei from '../../../assets/images/huawei.png';
import itel from '../../../assets/images/itel.png';
import acer from '../../../assets/images/logos/acer.png';
import asus from '../../../assets/images/logos/asus.png';
import blackview from '../../../assets/images/logos/blackview.png';
import droid from '../../../assets/images/logos/droid.png';
import fusion5 from '../../../assets/images/logos/fusion5.png';
import hmd from '../../../assets/images/logos/hmd.png';
import iball from '../../../assets/images/logos/iball.png';
import ibrit from '../../../assets/images/logos/ibrit.png';
import ikall from '../../../assets/images/logos/ikall.png';
import infinixmobilitylimited from '../../../assets/images/logos/infinixmobilitylimited.png';
import lava from '../../../assets/images/logos/lava.png';
import lenovo from '../../../assets/images/logos/lenovo.png';
import lg from '../../../assets/images/logos/lg.png';
import mi from '../../../assets/images/logos/mi.png';
import motorola from '../../../assets/images/logos/motorola.png';
import nalogo from '../../../assets/images/logos/nalogo.png';
import oneplus from '../../../assets/images/logos/oneplus.png';
import oppo from '../../../assets/images/logos/oppo.png';
import panasonic from '../../../assets/images/logos/panasonic.png';
import polaroid from '../../../assets/images/logos/polaroid.png';
import realme from '../../../assets/images/logos/realme.png';
import samsung from '../../../assets/images/logos/samsung.png';
import swipe from '../../../assets/images/logos/swipe.png';
import viewSonic from '../../../assets/images/logos/viewsonic.png';
import vivo from '../../../assets/images/logos/vivo.png';
import wishtel from '../../../assets/images/logos/wishtel.png';
import tcl from '../../../assets/images/tcl.png';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import DeviceCoutSupplier from '../../Dashboard/DeviceCoutSupplier';
import PowerOnDevices from '../../Dashboard/PowerOnDevices';
import CustomDashboard from '../CustomDashboard/CustomDashboard';

const AndroidDashboard = () => {
    document.title = 'Dashboard';

    const [loading, setLoading] = useState(false);
    let history = useHistory();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    const api = new APIClient();
    const urlconf = useEnv();
    const [widgetCount, setWidgetCount] = useState({});
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [series, setSeries] = useState();
    const [onlineStatus, setOnlineStatus] = useState();
    const [labels, setLabels] = useState();
    const [totalDevices, setTotalDevices] = useState(0);
    const [showOSDetailsModal, setShowOSDetailsModal] = useState(false);
    const [manufacturerDetails, setManufacturerDetails] = useState([]);
    const [androidVersionDetails, setAndroidVersionDetails] = useState([]);
    const [appsBackup, setAppsBackup] = useState([]);
    const [applicationFilter, setApplicationFilter] = useState('');
    const [installStatusFilter, setInstallStatusFilter] = useState({ name: 'All', value: '' });
    const [policies, setPolicies] = useState([]);
    const [apps, setApps] = useState([]);
    const tenant = TenantConfig();
    const [supplierCounts, setSupplierCounts] = useState([]);
    const [loginHistory, setLoginHistory] = useState([]);
    const [totalLoginRecords, setTotalLoginRecords] = useState(0);
    const [topUsedApps, setTopUsedApps] = useState({ time: '0SS', top5: [] });
    const [loginParams, setLoginParams] = useState({ page: 1, size: 7, username: user?.data?.username });
    const [powerStatusDetails, setPowerStatusDetails] = useState([
        { label: 'Power ON', count: 0 },
        { label: 'Power OFF', count: 0 }
    ]);
    const [totalPowerRecords, setTotalPowerRecords] = useState(0);
    const [type, setType] = useState('');

    const handlePolicySelection = (event) => {};

    const toggle = useCallback(async () => {
        setShowDetailsModal(!showDetailsModal);
    }, [showDetailsModal]);

    const toggle1 = useCallback(async () => {
        setShowOSDetailsModal(!showOSDetailsModal);
    }, [showOSDetailsModal]);

    const handleLoader = (val) => {
        setLoading(val);
    };
    const [selectedTab, setSelectedTab] = useState(tenant?.ANDROID_ENTERPRISE_ENABLED ? 'mobile' : '');
    const manufacturerLogos = {
        samsung: samsung,
        realme: realme,
        oppo: oppo,
        vivo: vivo,
        wishtel: wishtel,
        xiaomi: mi,
        'hmd global': hmd,
        lenovo: lenovo,
        acer: acer,
        motorola: motorola,
        panasonic: panasonic,
        itel: itel,
        tcl: tcl,
        huawei: huawei,
        na: nalogo,
        lava: lava,
        fusion5: fusion5,
        oneplus: oneplus,
        ibrit: ibrit,
        blackview: blackview,
        iball: iball,
        ikall: ikall,
        asus: asus,
        swipe: swipe,
        polaroid: polaroid,
        'infinix mobility limited': infinixmobilitylimited,
        droidlogic: droid,
        amlogic: droid,
        lg: lg,
        viewsonic: viewSonic,
        mediatek: acer
    };

    const typeObj = {
        device_distribution: { arr: manufacturerDetails, header: 'Manufacturer Details' },
        os_distribution: { arr: androidVersionDetails, header: 'Android Version Details' },
        power: { arr: powerStatusDetails, header: 'Power Status Details' },
        supplier: { arr: supplierCounts, header: 'Supplier Count' }
    };

    useEffect(() => {
        if (tenant.CUSTOM_DASHBOARD_EMAIL?.length > 0 && tenant.CUSTOM_DASHBOARD_EMAIL?.find((email) => user.data?.email === email)) {
        } else {
            setLoading(true);
            handlePromise();
        }
    }, []);

    const handlePromise = () => {
        const metricPromise = new Promise((resolve, reject) => {
            if (
                tenant.DASHBOARD_WIDGETS?.includes('dashboard_devices') ||
                tenant.DASHBOARD_WIDGETS?.includes('dashboard_device_online_summary') ||
                tenant.DASHBOARD_WIDGETS?.includes('dashboard_os_distribution') ||
                tenant.DASHBOARD_WIDGETS?.includes('dashboard_device_distribution') ||
                tenant.DASHBOARD_WIDGETS?.includes('dashboard_apps')
            )
                api.create(url.DASHBOARD)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);

                        reject('Dashboard failed');
                    })
                    .catch((err) => reject('Dashboard failed.'));
            else reject('Widgets, Online Summary, OS Distribution, Device Distribution and Apps are not enabled');
        });

        const loginHistoryPromise = new Promise((resolve, reject) => {
            if (tenant.DASHBOARD_WIDGETS?.includes('login_history'))
                api.get(url.LOGIN_HISTORY, loginParams, domains.IDM)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.status === 'success') resolve(resp);

                        reject('Login History failed');
                    })
                    .catch((err) => reject('Login History failed.'));
            else reject('Login History not enabled');
        });

        const powerOnStatusPromise = new Promise((resolve, reject) => {
            if (tenant.DASHBOARD_WIDGETS?.includes('power_on_status'))
                api.get(url.POWER_STATUS)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.status === 'success') resolve(resp.data);

                        reject('Power Status failed');
                    })
                    .catch((err) => reject('Power Status failed.'));
            else reject('Power Status not enabled');
        });

        const top5AppsPromise = new Promise((resolve, reject) => {
            if (tenant.DASHBOARD_WIDGETS?.includes('most_used_apps'))
                api.get(url.TOP_USED)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.status === 'success') resolve(resp.data);

                        reject('Top used apps failed');
                    })
                    .catch((err) => reject('Top used apps failed'));
            else reject('Top used apps not enabled');
        });

        Promise.allSettled([metricPromise, loginHistoryPromise, powerOnStatusPromise, top5AppsPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    handleMetrics(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    handleLoginHistory(result[1].value);
                }
                if (result[2].status === 'fulfilled') {
                    setTotalPowerRecords(result[2].value?.total);
                    let arr = [
                        { label: 'Power ON', count: result[2].value?.on },
                        { label: 'Power OFF', count: result[2].value?.off }
                    ];
                    setPowerStatusDetails(arr);
                }
                if (result[3].status === 'fulfilled') {
                    let totalTime = 0;
                    result[3].value.forEach((value) => {
                        value.img =
                            result[0].value?.aggs?.[value.package_name]?.iconUrl ||
                            'https://lh3.googleusercontent.com/esZIAQ-orYNSO4FDT4CsmW9Wp4cT6l-QIS3zRAfJvQKEsX9Y0-GYxS8B0IDvGZOQGkkL=s128';
                        value.dataUsed = value.data_used ? convertBytesTo(value.data_used) : 0;
                        totalTime = totalTime + value.screen_time;
                        let { days, hours, minutes, seconds } = convertSecondsTo(value.screen_time ? value.screen_time : 0, true);
                        value.timeVal = formatDate(days, hours, minutes, seconds);
                    });
                    result[3].value = sortByAsc(result[3].value, 'screen_time');
                    let { days, hours, minutes, seconds } = convertSecondsTo(totalTime, true);
                    let totalTimeInWords = formatDate(days, hours, minutes, seconds);
                    setTopUsedApps({ time: totalTimeInWords, top5: result[3].value });
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleLoginHistory = (data) => {
        if (data) {
            setLoginHistory(data.data);
            setTotalLoginRecords(data.totalRecords);
        }
    };

    const getLoginHistory = (params) => {
        setLoginParams(params);
        api.get(url.LOGIN_HISTORY, params, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') handleLoginHistory(resp);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleMetrics = (data) => {
        if (data) {
            setWidgetCount({
                deviceStatusCount: { ...data?.aggs?.deviceStatusCount },
                onlineCount: { ...data?.aggs?.onlineStatusCount },
                totalCount: data.totalCount
            });
            //  delete resp.data?.aggs?.totalCount;
            delete data?.aggs?.onlineStatusCount;
            delete data?.aggs?.deviceStatusCount;
            let onlineArr = [];
            for (let [key, value] of Object.entries(data?.aggs?.updescoonlineStatus)) {
                onlineArr.push({
                    label: key === 'last_7_days' ? '0 to 7 days' : key === 'last_15_days' ? '0 to 15 days' : '0 to 30 days',
                    count: value,
                    key: key === 'last_7_days' ? 3 : key === 'last_15_days' ? 2 : 1
                });
                if (key === 'last_30_days') setTotalDevices(value);
            }
            delete data?.aggs?.updescoonlineStatus;
            let manufacturerArr = [];
            let totalDeviceCountAsManufacturer = 0;
            for (let [key, value] of Object.entries(data?.aggs?.manufacture)) {
                if (key !== '') {
                    manufacturerArr.push({
                        label: key.charAt(0).toUpperCase() + key.slice(1),
                        count: value
                    });
                    totalDeviceCountAsManufacturer = totalDeviceCountAsManufacturer + value;
                }
            }
            let mfArr = [];
            manufacturerArr.forEach((obj) => {
                if (!mfArr.find((mf) => mf.label?.toLowerCase() === obj.label?.toLowerCase())) {
                    let mfAr = manufacturerArr.filter((mfObj) => mfObj.label?.toLowerCase() === obj.label?.toLowerCase());
                    let count = 0;
                    mfAr.forEach((mf) => (count = count + (mf.count ? Number(mf.count) : 0)));
                    mfArr.push({ label: obj.label, count: count });
                }
            });
            mfArr = sortByAsc(mfArr, 'count');
            if (data?.aggs?.manufacture?.['']) mfArr.push({ label: 'NA', count: data?.aggs?.manufacture?.[''] });
            delete data?.aggs?.manufacture;
            let androidVersionArr = [];
            for (let [key, value] of Object.entries(data?.aggs?.androidVersion)) {
                if (key !== '') androidVersionArr.push({ label: 'Android ' + key, count: value });
            }
            let osArr = [];
            androidVersionArr.forEach((av) => {
                if (!osArr.find((os) => os.label?.toLowerCase() === av.label?.toLowerCase())) {
                    let ofAr = androidVersionArr.filter((avObj) => avObj.label?.toLowerCase() === av.label?.toLowerCase());
                    let count = 0;
                    ofAr.forEach((ofObj) => (count = count + (ofObj.count ? Number(ofObj.count) : 0)));
                    osArr.push({ label: av.label, count: count });
                }
            });
            osArr = sortByAsc(osArr, 'count');
            if (data?.aggs?.androidVersion?.['']) osArr.push({ label: 'NA', count: data?.aggs?.androidVersion?.[''] });
            delete data?.aggs?.androidVersion;
            let supplierArr = [];
            for (let [key, value] of Object.entries(data?.aggs?.supplierCount)) {
                supplierArr.push({ label: key, count: value });
            }
            supplierArr = sortByAsc(supplierArr, 'count');
            delete data?.aggs?.supplierCount;
            let applications = [];
            for (let [_key, value] of Object.entries(data?.aggs)) {
                let obj = {
                    latest: value?.[3] ? value?.[3] : 0,
                    notLatest: value?.[2] ? value?.[2] : 0,
                    notInstalled: value?.[1] ? value?.[1] : 0,
                    smallIconUrl: value?.iconUrl,
                    title: value?.appTitle
                };
                let total =
                    (obj.latest ? Number(obj.latest) : 0) +
                    (obj.notLatest ? Number(obj.notLatest) : 0) +
                    (obj.notInstalled ? Number(obj.notInstalled) : 0);
                obj.progress = [
                    { width: obj.latest ? Math.floor((Number(obj.latest) / total) * 100) : 0, bgColor: 'bg-success' },
                    { width: obj.notLatest ? Math.floor((Number(obj.notLatest) / total) * 100) : 0, bgColor: 'bg-warning' },
                    { width: obj.notInstalled ? Math.floor((Number(obj.notInstalled) / total) * 100) : 0, bgColor: 'bg-danger' }
                ];
                applications.push(obj);
            }
            applications = applications?.filter((app) => app.latest > 0 || app.notLatest > 0 || app.notInstalled > 0);
            applications = sortBy(applications, 'title');
            setApps(applications);
            setAppsBackup(applications);
            setSupplierCounts(supplierArr);
            setManufacturerDetails(mfArr);
            setAndroidVersionDetails(osArr);
            let arr = onlineArr.sort((a, b) => b.key - a.key);
            setOnlineStatus(arr);
            setSeries(arr.map((ele) => ele.count));
            setLabels(arr.map((ele) => ele.label));
        }
    };

    const handleLoginPageChange = (page) => {
        getLoginHistory({ ...loginParams, page: page });
    };

    const handleExport = (key) => {
        if (key === 'pdf') {
            window.print('divToDownload');
        } else {
            setLoading(true);
            const applicationList = [];
            if (tenant?.DASHBOARD_WIDGETS?.includes('dashboard_devices')) {
                applicationList.push(
                    ['Total', 'Provisioning', 'Enrolled', 'Not Enrolled', 'Online', 'Offline'],
                    [
                        widgetCount?.totalCount ? widgetCount?.totalCount : '0',
                        widgetCount?.deviceStatusCount?.PROVISIONING ? widgetCount?.deviceStatusCount?.PROVISIONING : '0',
                        (widgetCount?.deviceStatusCount?.ACTIVE ? Number(widgetCount?.deviceStatusCount?.ACTIVE) : 0) +
                        (widgetCount?.deviceStatusCount?.DISABLED ? Number(widgetCount?.deviceStatusCount?.DISABLED) : 0)
                            ? (widgetCount?.deviceStatusCount?.ACTIVE ? Number(widgetCount?.deviceStatusCount?.ACTIVE) : 0) +
                              (widgetCount?.deviceStatusCount?.DISABLED ? Number(widgetCount?.deviceStatusCount?.DISABLED) : 0)
                            : '0',
                        (widgetCount?.deviceStatusCount?.NEW ? Number(widgetCount?.deviceStatusCount?.NEW) : 0) +
                        (widgetCount?.deviceStatusCount?.DELETED ? Number(widgetCount?.deviceStatusCount?.DELETED) : 0) +
                        (widgetCount?.deviceStatusCount?.DELETING ? Number(widgetCount?.deviceStatusCount?.DELETING) : 0)
                            ? (widgetCount?.deviceStatusCount?.NEW ? Number(widgetCount?.deviceStatusCount?.NEW) : 0) +
                              (widgetCount?.deviceStatusCount?.DELETED ? Number(widgetCount?.deviceStatusCount?.DELETED) : 0) +
                              (widgetCount?.deviceStatusCount?.DELETING ? Number(widgetCount?.deviceStatusCount?.DELETING) : 0)
                            : '0',
                        widgetCount?.onlineCount?.ONLINE ? widgetCount?.onlineCount?.ONLINE : '0',
                        (widgetCount?.onlineCount?.[''] ? Number(widgetCount?.onlineCount?.['']) : 0) +
                        (widgetCount?.onlineCount?.OFFLINE ? Number(widgetCount?.onlineCount?.OFFLINE) : 0) +
                        (widgetCount?.onlineCount?.AWAY ? Number(widgetCount?.onlineCount?.AWAY) : 0)
                            ? (widgetCount?.onlineCount?.[''] ? Number(widgetCount?.onlineCount?.['']) : 0) +
                              (widgetCount?.onlineCount?.OFFLINE ? Number(widgetCount?.onlineCount?.OFFLINE) : 0) +
                              (widgetCount?.onlineCount?.AWAY ? Number(widgetCount?.onlineCount?.AWAY) : 0)
                            : '0'
                    ]
                );
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('supplier_count')) {
                applicationList.push([], ['Device Count by Supplier'], ['Supplier', 'Count']);
                supplierCounts.forEach((supplier) => applicationList.push([supplier.label, supplier.count]));
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('power_on_status')) {
                applicationList.push(
                    [],
                    ['Power on Status'],
                    ['Power ON', 'Power OFF'],
                    [
                        powerStatusDetails?.find((power) => power.label === 'Power ON')?.count,
                        powerStatusDetails?.find((power) => power.label === 'Power OFF')?.count
                    ]
                );
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('most_used_apps')) {
                let totalTimeSpent = 0;
                topUsedApps.top5?.forEach((time) => {
                    totalTimeSpent = totalTimeSpent + time.screen_time;
                });
                let total = convertSecondsTo(totalTimeSpent, true);
                applicationList.push(
                    [],
                    ['Top (5) Most Used Apps'],
                    ['Total Devices', 'Time Spent'],
                    [
                        widgetCount.totalCount,
                        (total?.days > 0 ? total.days + 'days' : '') +
                            (total?.hours > 0 ? total.hours + 'hr' : '') +
                            (total?.minutes > 0 ? total?.minutes + 'min' : '') +
                            (total?.seconds > 0 ? total?.seconds + 'sec' : '')
                    ],
                    [],
                    ['App Name', 'Time Spent']
                );
                topUsedApps.top5.forEach((app) => {
                    let screenTime = convertSecondsTo(app.screen_time, true);
                    applicationList.push([
                        app.app_name,
                        (screenTime?.days > 0 ? screenTime.days + 'days' : '') +
                            (screenTime?.hours > 0 ? screenTime.hours + 'hr' : '') +
                            (screenTime?.minutes > 0 ? screenTime?.minutes + 'min' : '') +
                            (screenTime?.seconds > 0 ? screenTime?.seconds + 'sec' : '')
                    ]);
                });
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('dashboard_device_online_summary'))
                applicationList.push(
                    [],
                    ['Device Online Summary'],
                    ['0 to 7 days', '0 to 15 days', '0 to 30 days'],
                    [
                        onlineStatus?.find((status) => status.label === '0 to 7 days')?.count
                            ? onlineStatus.find((status) => status.label === '0 to 7 days')?.count
                            : '0',
                        onlineStatus?.find((status) => status.label === '0 to 15 days')?.count
                            ? onlineStatus.find((status) => status.label === '0 to 15 days')?.count
                            : '0',
                        onlineStatus?.find((status) => status.label === '0 to 30 days')?.count
                            ? onlineStatus.find((status) => status.label === '0 to 30 days')?.count
                            : '0'
                    ]
                );
            if (tenant.DASHBOARD_WIDGETS?.includes('dashboard_os_distribution')) {
                applicationList.push([], ['OS Distribution'], ['OS Version', 'Number of Devices']);
                androidVersionDetails?.forEach((androidVersion) => {
                    applicationList.push([androidVersion.label, androidVersion.count]);
                });
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('dashboard_device_distribution')) {
                applicationList.push([], ['Device Distribution'], ['Device Manufacturer', 'Number of Devices']);
                manufacturerDetails?.forEach((manufacturer) => {
                    applicationList.push([manufacturer.label, manufacturer.count]);
                });
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('login_history')) {
                applicationList.push([], ['Login/Logout History (Last 5 entries)'], ['Username', 'Time', 'Activity']);
                loginHistory.forEach((login, ind) => {
                    if (ind < 5) applicationList.push([login.username, getDate(login.modifieddate)?.replaceAll(',', ' '), login.activity]);
                });
            }
            if (tenant.DASHBOARD_WIDGETS?.includes('dashboard_apps')) {
                applicationList.push([], ['Applications'], ['App Name', 'Latest', 'Not Latest', 'Not Installed']);
                apps.forEach((app) => {
                    let obj = [
                        app.title?.replaceAll(',', ' '),
                        app.latest ? app.latest : '0',
                        app.notLatest ? app.notLatest : '0',
                        app.notInstalled ? app.notInstalled : '0'
                    ];
                    applicationList.push(obj);
                });
            }
            var csvFileData = applicationList;
            var csv = '';
            csvFileData.forEach(function (row) {
                csv += row.join(',');
                csv += '\n';
            });
            // document.write(csv);
            var a = document.createElement('a');
            a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            a.target = '_blank';
            a.download = 'Dashboard Report.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setLoading(false);
        }
    };
    const handleApplicationFilter = (val) => {
        setApplicationFilter(val);
        let applications = JSON.parse(JSON.stringify(appsBackup));
        // eslint-disable-next-line array-callback-return
        applications = applications.filter((app) => {
            if (installStatusFilter && installStatusFilter?.value !== '') {
                if (
                    installStatusFilter.value === '3' &&
                    app.latest &&
                    app.latest > 0 &&
                    !app.notLatest &&
                    !app.notInstalled &&
                    app.title?.toLowerCase().includes(val?.toLowerCase())
                )
                    return app;
                else if (
                    installStatusFilter.value === '1_2' &&
                    ((app.notLatest && app.notLatest > 0) || (app.notInstalled && app.notInstalled > 0)) &&
                    app.title?.toLowerCase().includes(val?.toLowerCase())
                )
                    return app;
            } else if (app.title?.toLowerCase().includes(val?.toLowerCase())) return app;
        });
        setApps(applications);
    };

    const handleInstallStatusSelection = (event) => {
        setInstallStatusFilter(event);
        let applications = JSON.parse(JSON.stringify(appsBackup));
        // eslint-disable-next-line array-callback-return
        applications = applications.filter((app) => {
            if (applicationFilter && applicationFilter !== '') {
                if (
                    event.value === '3' &&
                    app.latest &&
                    app.latest > 0 &&
                    !app.notLatest &&
                    !app.notInstalled &&
                    app.title?.toLowerCase().includes(applicationFilter?.toLowerCase())
                )
                    return app;
                else if (
                    event.value === '1_2' &&
                    ((app.notLatest && app.notLatest > 0) || (app.notInstalled && app.notInstalled > 0)) &&
                    app.title?.toLowerCase().includes(applicationFilter?.toLowerCase())
                )
                    return app;
                else if (event.value === '' && app.title?.toLowerCase().includes(applicationFilter?.toLowerCase())) return app;
            } else {
                if (event.value === '3' && app.latest && app.latest > 0 && !app.notLatest && !app.notInstalled) return app;
                else if (event.value === '1_2' && ((app.notLatest && app.notLatest > 0) || (app.notInstalled && app.notInstalled > 0)))
                    return app;
                else if (event.value === '' && app.title?.toLowerCase().includes(applicationFilter?.toLowerCase())) return app;
            }
        });
        setApps(applications);
    };
    const handleOffcanvasBody = () => {
        let arr = typeObj[type]?.arr || [];

        return (
            <div className="pt-2 ps-4 pe-4 bg-white">
                <ul className="list-group list-group-flush border border-2 border-dashed mb-0">
                    {arr?.length > 0 &&
                        arr.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            {type === 'device_distribution' ? (
                                                <img src={manufacturerLogos[ele.label?.toLowerCase()]} alt={ele.label} width={100} />
                                            ) : (
                                                ele.label
                                            )}
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };
    const handleOSOffcanvasBody = () => {
        let arr = selectedTab === 'mobile' ? androidVersionDetails : '';
        return (
            <div className="pt-2 ps-4 pe-4 bg-white">
                <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                    {arr?.length > 0 &&
                        arr.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <p className="fs-16 text-primary fw-medium">{ele.label}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };

    const DashboardtableSchema = [
        { Header: 'App Name', accessor: 'app_name', className: 'fw-medium', customCell: true, fieldType: 'imageLabel' },
        { Header: 'Time Spent', accessor: 'timeVal', className: 'fw-semibold', customCell: true, fieldType: 'customcss', key: 'timeVal' }
    ];

    const userLabel = (userName) => {
        let name = userName?.split(' ');
        if (name?.length === 1) return name?.[0]?.charAt(0)?.toUpperCase();
        else return name?.[0]?.charAt(0)?.toUpperCase() + name?.[1]?.charAt(0)?.toUpperCase();
    };

    const handleTabContent = (key) => {
        if (key === 'mobile') {
            return (
                !loading && (
                    <>
                        <BreadCrumb
                            pageTitle="Dashboard"
                            history={history}
                            homeLink="Dashboard"
                            hideLinks={true}
                            handleExport={handleExport}
                        />
                        <div id="divToDownload" className="divToDownload">
                            {tenant?.DASHBOARD_WIDGETS?.includes('dashboard_devices') && (
                                <Row>
                                    <DashboardWidgets widgetCount={widgetCount} handleLoader={handleLoader} />
                                </Row>
                            )}
                            <Row>
                                {tenant.DASHBOARD_WIDGETS?.includes('supplier_count') && (
                                    <Col xl={4} md={'auto'} sm="auto">
                                        <DeviceCoutSupplier
                                            details={supplierCounts || []}
                                            showDetailsModal={() => {
                                                setShowDetailsModal(true);
                                                setType('supplier');
                                            }}
                                        />
                                    </Col>
                                )}
                                {tenant.DASHBOARD_WIDGETS?.includes('power_on_status') && (
                                    <Col xl={4} md={'auto'} sm="auto">
                                        <PowerOnDevices
                                            details={{ total: totalPowerRecords, arr: powerStatusDetails || [] }}
                                            showDetailsModal={() => {
                                                setShowDetailsModal(true);
                                                setType('power');
                                            }}
                                        />
                                    </Col>
                                )}
                                {tenant.DASHBOARD_WIDGETS?.includes('most_used_apps') && (
                                    <Col xl={4} md="auto" sm="auto" className="pb-0 ">
                                        <Card className="card-height-100">
                                            <CardHeader className="align-items-center fw-semibold d-flex gap-1 padding-y-25">
                                                <h4 className="card-title mb-0 fw-semibold">Top (5) Most Used Apps</h4>
                                                <i className="ri-information-line link-info" id={'info-most-used-apps'} />
                                                {Tooltip(
                                                    'info-most-used-apps',
                                                    'This chart displays the top five most frequently used applications on devices since the first day of enrollment.'
                                                )}
                                            </CardHeader>
                                            <div className="bg-white">
                                                <CardBody className="px-0 pb-0 card-body">
                                                    {topUsedApps.top5?.length > 0 ? (
                                                        <>
                                                            <Row className="px-5 pt-2 pb-5">
                                                                <Col xl={4} md={4}>
                                                                    <div className="fs-13 text-muted fw-medium">Total Devices</div>
                                                                    <div className="fw-medium fs-14 py-1">{widgetCount.totalCount}</div>
                                                                </Col>
                                                                <Col xl={8} md={8}>
                                                                    <div className="fs-13 text-muted fw-medium">Time Spent</div>
                                                                    <div className="fw-medium fs-14  py-1">{topUsedApps.time}</div>
                                                                </Col>
                                                            </Row>
                                                            <TableContainer
                                                                data={topUsedApps.top5 || []}
                                                                columns={DashboardtableSchema}
                                                                customPageSize={5}
                                                                className="custom-header-css pb-0"
                                                                tableCardClass="mb-0"
                                                                divClass="table-responsive table-card"
                                                                tableClass="table table-nowrap table-border table-centered align-middle mb-0"
                                                                theadClass="bg-light text-muted"
                                                                hidePagination={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <NoRecordsFound />
                                                    )}
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                                {tenant?.DASHBOARD_WIDGETS?.includes('dashboard_device_online_summary') && (
                                    <Col xl={4} md={'auto'} sm="auto">
                                        <OnlineOfflineChart
                                            series={series || []}
                                            onlineStatus={onlineStatus || []}
                                            labels={labels || []}
                                            totalDevices={totalDevices}
                                            handleLoader={handleLoader}
                                        />
                                    </Col>
                                )}
                                {tenant?.DASHBOARD_WIDGETS?.includes('dashboard_os_distribution') && (
                                    <Col xl={4} md={'auto'} sm="auto">
                                        <AndroidVersion
                                            details={androidVersionDetails || []}
                                            showDetailsModal={() => {
                                                setShowOSDetailsModal(true);
                                                setType('os_distribution');
                                            }}
                                        />
                                    </Col>
                                )}
                                {tenant?.DASHBOARD_WIDGETS?.includes('dashboard_device_distribution') && (
                                    <Col xl={4} md={'auto'} sm="auto">
                                        <Manufacturer
                                            details={manufacturerDetails || []}
                                            showDetailsModal={() => {
                                                setShowDetailsModal(true);
                                                setType('device_distribution');
                                            }}
                                        />
                                    </Col>
                                )}
                                {tenant?.DASHBOARD_WIDGETS?.includes('login_history') && (
                                    <Col xl={4} md={'auto'} sm="auto">
                                        <Card className="card-height-100">
                                            <CardHeader className="align-items-center d-flex fw-semibold gap-1 padding-y-25">
                                                <h4 className="card-title mb-0">Login & Logout History</h4>
                                                <i className="ri-information-line link-info" id={'info-login-history'} />
                                                {Tooltip(
                                                    'info-login-history',
                                                    'This section provides a detailed record of all login and logout activities for the current user session.'
                                                )}
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                {loginHistory?.length > 0 ? (
                                                    <>
                                                        {loginHistory?.map((data, index) => {
                                                            return (
                                                                <div
                                                                    className="d-flex align-items-center border-bottom justify-content-between py-2 px-4"
                                                                    key={index}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <span
                                                                            className={`rounded-circle header-profile-user border border-2 d-flex align-items-center justify-content-center fs-15 ${
                                                                                data?.activity?.toLowerCase() === 'login' &&
                                                                                index === 0 &&
                                                                                loginParams.page === 1
                                                                                    ? 'bg-primary'
                                                                                    : 'bg-soft-primary'
                                                                            } text-white`}
                                                                        >
                                                                            {userLabel(data.username)}
                                                                        </span>
                                                                        <div className="px-1 fs-12 fw-medium">
                                                                            <div className="text-primary">{data.username}</div>
                                                                            <div className="pt-1">{getDate(data.modifieddate)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`lh-sm badge badge-soft-${
                                                                            data.activity?.toLowerCase() === 'login'
                                                                                ? 'success'
                                                                                : 'secondary'
                                                                        } fs-11`}
                                                                    >
                                                                        {data.activity?.charAt(0) + data.activity?.substr(1)?.toLowerCase()}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        <div className="mb-4 me-2">
                                                            <Pagination
                                                                activePage={loginParams.page}
                                                                itemsCountPerPage={loginParams.size}
                                                                totalItemsCount={totalLoginRecords}
                                                                pageRangeDisplayed={3}
                                                                onChange={handleLoginPageChange}
                                                                hideFirstLastPages={false}
                                                                marginTopClass="mt-2"
                                                                hideTotal={true}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <NoRecordsFound />
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {/* {tenant?.DASHBOARD_WIDGETS?.includes('dashboard_activity_log') && (
                                    <Col xl={4} md={4}>
                                        <MyActivityLog />
                                    </Col>
                                )} */}
                            </Row>
                            <Row>
                                {tenant?.DASHBOARD_WIDGETS?.includes('dashboard_apps') && (
                                    <AppInstall
                                        handlePolicySelection={handlePolicySelection}
                                        handleApplicationFilter={handleApplicationFilter}
                                        handleInstallStatusSelection={handleInstallStatusSelection}
                                        handleLoader={handleLoader}
                                        apps={apps}
                                        loading={loading}
                                        policies={policies}
                                    />
                                )}
                            </Row>
                        </div>
                    </>
                )
            );
        }
    };

    return tenant.CUSTOM_DASHBOARD_EMAIL?.length > 0 && tenant.CUSTOM_DASHBOARD_EMAIL?.find((email) => user.data?.email === email) ? (
        <CustomDashboard />
    ) : (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>{handleTabContent('mobile')}</Container>
                <OffcanvasModal
                    direction="end"
                    toggle={toggle}
                    open={showDetailsModal}
                    handleCloseClick={toggle}
                    OffcanvasModalID="manufacturer_details"
                    hideSaveButton={true}
                    closeButtonBorder={true}
                    handleOffcanvasBody={handleOffcanvasBody}
                    modalClassName={'w-25'}
                    offcanvasHeader={typeObj[type]?.header || ''}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle1}
                    open={showOSDetailsModal}
                    handleCloseClick={toggle1}
                    OffcanvasModalID="os_details"
                    hideSaveButton={true}
                    closeButtonBorder={true}
                    handleOffcanvasBody={handleOSOffcanvasBody}
                    modalClassName={'w-25'}
                    offcanvasHeader={'OS Details'}
                />
            </div>
        </React.Fragment>
    );
};

export default AndroidDashboard;
