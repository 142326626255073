import React, { useEffect, useState } from 'react';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { Tooltip } from '../../Components/Common/Util';
import NoRecordsFound from '../../Components/Common/NoRecordsFound';

const DeviceCoutSupplier = (props) => {
    let dataColors =
        '["--EMM-supplier-count-1","--EMM-supplier-count-4","--EMM-supplier-count-3","--EMM-supplier-count-2","--EMM-supplier-count-5"]';
    let dataColorsArr = ['supplier-count-1', 'supplier-count-4', 'supplier-count-3', 'supplier-count-2', 'supplier-count-5'];
    var chartDonutBasicColors = getChartColorsArray(dataColors);

    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        if (props.details?.length > 0) {
            setLabels(props.details.map((detail) => detail.label));
            setSeries(props.details?.map((detail) => detail.count));
            let detailArr = JSON.parse(JSON.stringify(props.details));
            setDetails(detailArr.splice(0, 3));
        }
    }, [props]);

    const options = {
        labels: labels,
        chart: {
            type: 'donut',
            height: 250
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: '76%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -5
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            color: '#343a40',
                            fontWeight: 500,
                            offsetY: 5,
                            formatter: function (val) {
                                return val;
                            }
                        },
                        total: {
                            show: true,
                            fontSize: '13px',
                            label: 'Total Devices',
                            color: '#9599ad',
                            fontWeight: 500,
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce(function (a, b) {
                                    return a + b;
                                }, 0);
                            }
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 15,
                height: 10,
                radius: 2
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            }
        },
        colors: chartDonutBasicColors
    };

    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center fw-semibold d-flex gap-1 padding-y-25">
                    <h4 className="card-title mb-0 ">Device Count by Supplier</h4>
                    <i className="ri-information-line link-info" id={'info-device-count'} />
                    {Tooltip('info-device-count', 'It displays the number of devices supplied by a each supplier.')}
                </CardHeader>
                <div className="bg-white">
                    <CardBody className="card-body">
                        {details?.length > 0 && (
                            <>
                                <div dir="ltr">
                                    <ReactApexChart
                                        dir="ltr"
                                        options={options}
                                        series={series}
                                        type="donut"
                                        height="250"
                                        className="apex-charts"
                                    />
                                </div>
                            </>
                        )}
                        <div className="pt-2 ps-4 pe-4 bg-white">
                            <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                                {details?.length > 0 ? (
                                    details.map((ele, index) => {
                                        return (
                                            <li key={index} className="list-group-item px-0">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 ms-2">
                                                        <p className="fs-14 mb-0 d-flex align-items-center">
                                                            <i
                                                                className={`ri-checkbox-blank-circle-fill fs-14 align-middle text-${
                                                                    dataColorsArr[index % 6]
                                                                } me-1`}
                                                            ></i>
                                                            {ele.label}
                                                        </p>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <p className="fs-14 mb-0">{ele.count}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </ul>
                            {props.details?.length > 3 && (
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <span className="text-info fw-medium cursor-pointer" onClick={props.showDetailsModal}>
                                        Show All
                                    </span>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default DeviceCoutSupplier;
