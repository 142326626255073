import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';
import { useProfile } from '../Components/Hooks/UserHooks';
// import Dashboard from '../pages/Dashboard';
import UnauthorizedAccess from './UnauthorizedAccess';
import { AuthUser, TenantConfig } from '../Components/Common/Util';
import { authUser, enterpriseAccount } from '../store/actions';
import { useDispatch } from 'react-redux';
import navdata from '../Layouts/LayoutMenuData';

const AuthProtected = (props) => {
    let dispatch = useDispatch();
    let accountId = localStorage.getItem('account');

    const { userProfile, loading, token } = useProfile();
    useEffect(() => {
        if (userProfile && !loading && token) {
            setAuthorization(token);
        } else if (!userProfile && loading && !token) {
            localStorage.clear();
            sessionStorage.clear();
            dispatch(authUser(null));
            dispatch(enterpriseAccount(null));
        }
    }, [token, userProfile, loading]);

    /*
     *redirect is un-auth access protected routes via url
     */

    if (!userProfile && loading && !token) {
        if (!accountId || accountId === null) return <Redirect to={{ pathname: '/account', state: { from: props.location } }} />;
        else return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }

    return <>{props.children}</>;
};

const checkConditions = (conditions, tenant, link) => {
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let valid = !tenant[conditions[0]];
    conditions.forEach((condition) => (valid = valid || !tenant[condition]));
    return valid || (link.showFor ? !link.showFor?.includes(user.data?.email) : false);
};

const checkValidity = (tenant) => {
    const links = [];
    const navData = navdata().props.children;
    navData.forEach((nav) => {
        if (nav.subItems)
            nav.subItems.forEach((subItem) => {
                if (!subItem.doNotCheck) links.push(subItem);
            });
        if (!nav.doNotCheck) links.push(nav);
    });
    let linkValidity = window.location.hash?.includes('/dashboard') && !tenant.DASHBOARD;
    links.forEach(
        (link) =>
            (linkValidity =
                linkValidity ||
                (window.location.hash?.includes(link.link) &&
                    (link.multipleConditions ? checkConditions(link.key, tenant, link) : !tenant[link.key])))
    );
    return linkValidity;
};

const AccessRoute = ({ component: Component, ...rest }) => {
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let history = useHistory();
    const tenant = TenantConfig();
    let privExist =
        rest.reader || rest.user ? user?.data?.privileges?.includes(rest.reader) || user?.data?.privileges?.includes(rest.editor) : true;
    if (!window.location.hash.includes('devices')) localStorage.removeItem('selTab');
    if (!window.location.hash.includes('applications')) localStorage.removeItem('selAppTab');
    if (!window.location.hash.includes('policies')) localStorage.removeItem('policyNameForTokens');
    if (checkValidity(tenant)) history.push('/auth-noaccess');

    return (
        <Route
            {...rest}
            render={(props) => {
                return <>{privExist ? <Component {...props} /> : <UnauthorizedAccess />}</>;
            }}
        />
    );
};

export { AuthProtected, AccessRoute };
