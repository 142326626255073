import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import TableContainer from '../../Components/Common/TableContainer';
import TabsComponent from '../../Components/Common/TabsComponent';
import { Enterprise, convertDate, convertTextCase, getDate, getDateOnly, getFormTypeAndRecordId } from '../../Components/Common/Util';
import { calllogReport } from '../../Components/constants/constants';
import ReportImage from '../../assets/images/ReportsImage.png';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { scheduleSchema } from './ReportsSchema';

const Reports = (prop) => {
    document.title = 'Reports';
    const [selectedTab, setSelectedTab] = useState('GeneralReports');
    const fp = useRef(null);
    const fp3 = useRef(null);
    const api = new APIClient();

    const [schema, setSchema] = useState(prop.schema || []);
    const [selectedReport, setSelectedReport] = useState('');
    // const [selectedObj, setSelectedObj] = useState();
    const [isInvalidMail, setIsInvalidMail] = useState(false);
    const [callLogReport, setCallLogReport] = useState(false);
    const [isViewHistory, setIsViewHistory] = useState({});
    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};
    const [searchParams, setSearchParams] = useState({ select: '*', page: 1, size: 10 });
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const [applications, setApplications] = useState([]);
    const [dateVal, setDateVal] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const urlconf = useEnv();

    const [isSchedule, setIsSchedule] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [selectedObj, setSelectedObj] = useState({});

    useEffect(() => {
        setSchema(prop.schema || []);
    }, [prop.schema]);

    useEffect(() => {
        const findObj = () => {
            let find = {};
            schema?.[0]?.options?.forEach((obj) => {
                if (selectedReport === obj?.value) find = obj;
                else return;
            });
            return find;
        };
        setSelectedObj(findObj());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReport, schema]);

    const getAppVersions = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.create(url.DEVICE_REPORTS + '/apps', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.appName = data.appName || data.packageName;
                        data.badgeClass = data.status === 'INSTALLED' ? 'success' : 'danger';
                        data.modifiedTime = getDate(data.modifiedDate);
                        data.imei = data.imei ? data.imei : '—';
                    });
                    setTotalRecords(resp.totalRecords);
                    setData(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleSelectChange = (val) => {
        setPageNumber(1);
        let params = { ...searchParams, page: 1, size: 10, packageName: val?.packageName };
        getAppVersions(params);
    };
    const handleGlobalSearch = (val) => {
        let params = {};
        if (val) params = { ...searchParams, serial: val?.trim()?.toUpperCase()?.split(' ') };
        else params = { ...searchParams, serial: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (selectedReport === 'app_install') {
            params = { ...searchParams, page: 1, size: 10, serial: val };
            getAppVersions(params);
        } else if (selectedReport === 'login_history') serviceCall(selectedReport, { ...params, page: 1, size: 10, username: val });
        else serviceCall(selectedReport, { ...params, page: 1, size: 10 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (val?.length === 0) serviceCall(selectedReport, { ...params, page: 1, size: 10 });
    };
    const onPageChange = (pgNumber, pgSize) => {
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        serviceCall(selectedReport, { ...searchParams, page: pgNumber, size: pgSize });
    };
    const [dataObj, setDataObj] = useState([]);
    let formType = formTypeAndId['formType'];
    let history = useHistory();

    const serviceCall = (type, paramsObj) => {
        switch (type) {
            case 'call_logs_reports':
                setLoading(true);
                let params = {};
                if (paramsObj) params = paramsObj;
                else params = { ...searchParams, page: pageNumber, size: pageSize };
                api.get(url.CALL_LOGS, params)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) {
                            setTotalRecords(resp?.totalRecords);
                            resp.data?.forEach((ele) => {
                                ele.serial = ele.serial || '—';
                                ele.callType = ele.callType || '—';
                                ele.number2 = ele.number2 || '—';
                                ele.otherNumber = ele.otherNumber || '—';
                                ele.duration = ele.duration || '0s';
                                ele.badgeClass =
                                    ele.callType === 'INCOMING'
                                        ? 'light-blue'
                                        : ele.callType === 'OUTGOING'
                                        ? 'primary'
                                        : ele.callType === 'MISSED'
                                        ? 'secondary'
                                        : ele.callType === 'REJECTED'
                                        ? 'grey'
                                        : 'white text-black';
                                ele.callDate = ele.callDate ? getDate(ele.callDate) : getDate(ele.createdDate);
                            });
                            setData(resp?.data);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
                break;
            case 'user_activity_report':
                setLoading(true);
                let paramss = {};
                if (paramsObj) paramss = paramsObj;
                else paramss = { ...searchParams, page: pageNumber, size: pageSize };
                api.get(url.USER_ACTIVITY, paramss)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) {
                            resp.data.forEach((data) => {
                                data.userName = data.userName ? data.userName : '—';
                                const activityType = data.activityType.split('_');
                                // eslint-disable-next-line max-nested-callbacks
                                const capitalizedWords = activityType.map(
                                    // eslint-disable-next-line max-nested-callbacks
                                    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                );
                                const activityTypeResult = capitalizedWords.join(' ');
                                data.activityType = activityTypeResult ? activityTypeResult : '—';
                                data.date = data.createdTime ? getDate(data.createdTime) : '—';
                            });
                            setData(resp.data);
                            setTotalRecords(resp.totalRecords);
                        }
                        setLoading(false);
                    })
                    .catch((_err) => setLoading(false));
                break;
            case 'app_install':
                setLoading(true);
                let applicationsPromise = new Promise((resolve, reject) => {
                    api.get(url.APPLICATIONS, { enterpriseId: enterpriseObj?.id, page: 1, size: 1000 })
                        .then((resp) => {
                            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                            if (resp.data) resolve(resp.data);
                            else reject('Failed to fetch Apps.');
                        })
                        .catch((err) => reject('Failed to fetch Apps'));
                });
                let appVersionPromise = new Promise((resolve, reject) => {
                    api.create(url.DEVICE_REPORTS + '/apps', searchParams)
                        .then((resp) => {
                            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                            if (resp.data) resolve(resp);
                            else reject('Failed to fetch app version report.');
                        })
                        .catch((err) => reject('Failed to fetch app version report'));
                });
                Promise.allSettled([applicationsPromise, appVersionPromise])
                    .then((result) => {
                        if (result[0].status === 'fulfilled') {
                            result[0].value?.forEach((app) => {
                                app.label = app.title;
                                app.value = app.packageName;
                            });
                            setApplications(result[0].value);
                        }
                        if (result[1].status === 'fulfilled') {
                            result[1]?.value.data.forEach((data) => {
                                data.appName = data.appName || data.packageName;
                                data.badgeClass = data.status === 'INSTALLED' ? 'success' : 'danger';
                                data.modifiedTime = getDate(data.modifiedDate);
                                data.imei = data.imei ? data.imei : '—';
                            });
                            setTotalRecords(result[1]?.value.totalRecords);
                            setData(result[1]?.value.data);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'tv_app_installation':
                setLoading(true);
                api.get(url.DEVICE_REPORTS + '/appstatus', paramsObj)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) {
                            resp.data.forEach((data) => {
                                data.appName = data.appName || '—';
                                data.badgeClass = data.status === 'INSTALLED' ? 'success' : 'danger';
                                data.modifiedTime = getDate(data.lastModifiedDate);
                            });
                            setData(resp.data);
                            setTotalRecords(resp.totalRecords);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'device_power_status':
                setLoading(true);
                paramsObj = { ...paramsObj, type: 'DEVICE' };
                api.get(url.DEVICE_REPORTS + '/powerstatus', paramsObj)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) {
                            resp.data.forEach((data) => {
                                data.power = data.powerStatus ? 'ON' : 'OFF';
                                data.badgeClass = data.powerStatus ? 'success' : 'danger';
                                data.modifiedDate = data.lastModifiedTime ? getDate(data.lastModifiedTime) : '—';
                            });
                            setData(resp.data);
                            setTotalRecords(resp.totalRecords);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'teachers_audit':
                setLoading(true);
                api.get(url.TEACHER_METRICS, paramsObj)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) {
                            resp.data.forEach((data) => {
                                data.sessionStartDate = data.sessionStartDateTime ? getDate(data.sessionStartDateTime) : '—';
                                data.sessionEndDate = data.sessionEndDateTime ? getDate(data.sessionEndDateTime) : '—';
                                data.class = data.teachingClass;
                                data.subject = data.teachingSubject && data.teachingSubject !== null ? data.teachingSubject : '—';
                            });
                            setData(resp.data);
                            setTotalRecords(resp.totalRecords);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'geo_fence_mobile':
                setLoading(true);
                api.get(url.DEVICE_REPORTS + '/geolocation', paramsObj)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) {
                            resp.data.forEach((data) => {
                                data.date = getDate(data.date, false, true);
                                data.badgeClass = data.status === 'IN' ? 'success' : 'danger';
                            });
                            setData(resp.data);
                            setTotalRecords(resp.totalRecords);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'tv_error_devices':
                setLoading(true);
                let param = {};
                if (paramsObj) param = paramsObj;
                else param = { ...searchParams, page: pageNumber, size: pageSize };
                api.get(url.TV_ERRORS, param)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) {
                            setTotalRecords(resp.data.totalRecords);
                            resp.data.forEach((ele) => {
                                ele.location = ele.isLocationDisabled ? 'Disabled' : 'Enabled';
                                ele.storage = ele.hasLowStorageSpace ? 'Low' : 'Good';
                                ele.showTooltip = ele.launcherStatus !== 'UPDATED';
                                ele.launcherStatus = convertTextCase(ele.launcherStatus);
                                ele.infoTooltip = `Launcher version: ${ele.launcherVersion} - Installed version: ${ele.launcherInstallVersion}`;
                                ele.modifiedDate = ele.lastModifiedDate ? getDate(ele.lastModifiedDate) : '—';
                            });
                            setData(resp.data);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
                break;
            case 'tv_power_status':
                setLoading(true);
                paramsObj = { ...paramsObj, type: 'TV' };
                api.get(url.DEVICE_REPORTS + '/powerstatus', paramsObj)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) {
                            resp.data.forEach((data) => {
                                data.power = data.powerStatus ? 'ON' : 'OFF';
                                data.badgeClass = data.powerStatus ? 'success' : 'danger';
                                data.modifiedDate = data.lastModifiedTime ? getDate(data.lastModifiedTime) : '—';
                            });
                            setTotalRecords(resp.totalRecords);
                            setData(resp.data);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'login_history':
                setLoading(true);
                api.get(url.LOGIN_HISTORY, paramsObj, domains.IDM)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') {
                            setTotalRecords(resp?.totalRecords);
                            resp.data?.forEach((ele) => {
                                ele.badgeClass = ele.activity?.toLowerCase() === 'login' ? 'success' : 'info';
                                ele.activity = convertTextCase(ele.activity, true);
                                ele.modifiedDate = ele.modifieddate ? getDate(ele.modifieddate) : '—';
                                ele.app = ele.app === 'portal' ? 'Web' : convertTextCase(ele.app, true);
                                ele.authtype = ele.authtype === 'local' ? 'Tectoro' : convertTextCase(ele.authtype);
                            });
                            setData(resp?.data || []);
                            setTotalRecords(resp.totalRecords);
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
                break;
            case 'geo_fence_tv':
                setLoading(true);
                api.get(url.DEVICE_REPORTS + '/tvgeofence', paramsObj)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) {
                            resp.data.forEach((data) => {
                                data.date = getDate(data.date, false, true);
                                data.badgeClass = data.status === 'IN' ? 'success' : 'danger';
                            });
                            setData(resp.data);
                            setTotalRecords(resp.totalRecords);
                        }
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            default:
                break;
        }
    };
    const handleExportLogs = () => {
        setLoading(true);
        switch (selectedReport) {
            case 'call_logs_reports':
                api.create(
                    url.CALLLOGS_DOWNLOAD +
                        (searchParams?.serial || searchParams?.fromDate || searchParams?.toDate ? '?' : '') +
                        (searchParams?.serial ? 'serial=' + searchParams?.serial : '') +
                        (searchParams?.serial && searchParams?.fromDate
                            ? '&fromDate=' + searchParams?.fromDate
                            : searchParams?.fromDate
                            ? 'fromDate=' + searchParams?.fromDate
                            : '') +
                        ((searchParams?.serial || searchParams?.fromDate) && searchParams?.toDate
                            ? '&toDate=' + searchParams?.toDate
                            : searchParams?.toDate
                            ? 'toDate=' + searchParams?.toDate
                            : '')
                )
                    .then((resp) => {
                        let blob = new Blob([resp], { type: 'application/octet-stream' });
                        var fileURL = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.download = 'CallLog.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'app_install':
                let params = JSON.parse(JSON.stringify(searchParams));
                delete params.page;
                delete params.size;
                api.create(url.DEVICE_REPORTS + '/download/apps', params).then((resp) => {
                    let blob = new Blob([resp], { type: 'application/octet-stream' });
                    var fileURL = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.download = 'App Installation Report.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoading(false);
                });
                break;
            case 'geo_fence_mobile':
                api.create(
                    url.DEVICE_REPORTS +
                        '/geolocation/download' +
                        (searchParams?.serial || searchParams?.fromDate || searchParams?.toDate ? '?' : '') +
                        (searchParams?.serial ? 'serial=' + searchParams?.serial : '') +
                        (searchParams?.serial && searchParams?.fromDate
                            ? '&fromDate=' + searchParams?.fromDate
                            : searchParams?.fromDate
                            ? 'fromDate=' + searchParams?.fromDate
                            : '') +
                        ((searchParams?.serial || searchParams?.fromDate) && searchParams?.toDate
                            ? '&toDate=' + searchParams?.toDate
                            : searchParams?.toDate
                            ? 'toDate=' + searchParams?.toDate
                            : '')
                )
                    .then((resp) => {
                        let blob = new Blob([resp], { type: 'application/octet-stream' });
                        var fileURL = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.download = 'Mobile Geo Location Report.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'login_history':
                api.get(url.LOGIN_HISTORY_DOWNLOAD, searchParams, domains.IDM)
                    .then((resp) => {
                        let blob = new Blob([resp], { type: 'application/octet-stream' });
                        var fileURL = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.download = 'Login History.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            default:
                break;
        }
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };
    const handleAddFRPMail = (field) => {
        let isValid = true;
        if (field.validator) {
            let regex = field.validator === 'email' ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,4}$/ : '';
            let pattern = new RegExp(regex);
            isValid = pattern.test(scheduleValidation.values[field.value]?.trim());
            setIsInvalidMail(!isValid);
            if (!isValid) {
                toast.error(toastMessages.inValidEmail);
                return;
            } else if (scheduleValidation.values[field.list]?.includes(scheduleValidation.values[field.value]?.trim())) {
                toast.error(toastMessages.mailAlreadyExist);
                return;
            }
        }
        let listObj = [];
        if (scheduleValidation.values[field.list])
            listObj = [...scheduleValidation.values[field.list], scheduleValidation.values[field.value]?.trim()];
        else listObj.push(scheduleValidation.values[field.value]?.trim());

        scheduleValidation.setValues({ ...scheduleValidation.values, [field.list]: [...listObj], [field.value]: '' });
    };

    const handleRemoveOneFRPMail = (ele, field) => {
        let listArr = [...scheduleValidation.values[field.list]];
        listArr = listArr.filter((list) => list !== ele);
        scheduleValidation.setValues({ ...scheduleValidation.values, [field.list]: [...listArr] });
    };
    const selectComponent = (props) => {
        return (
            <Row className={`${props.field?.customFilter ? '' : 'pb-2'}`}>
                <Col
                    md={props.field?.customFilter ? 3 : 12}
                    xl={props.field?.customFilter ? 3 : 12}
                    lg={props.field?.customFilter ? 3 : 12}
                    className="d-flex align-items-center"
                >
                    <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-13">{props.field.label}</Label>
                    {props.field?.mandatory && <span className="text-danger ms-1">*</span>}
                </Col>
                {props.field.helpText && (
                    <div>
                        <Col md={props.field.customCol ? 8 : 12} xl={props.field.customCol ? 7 : 12} lg={props.field.customCol ? 7 : 12}>
                            {props.field.helpText && <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText}</div>}
                        </Col>
                    </div>
                )}
                <Col md={props.field.customCol ? 8 : 9} xl={props.field.customCol ? 7 : 9} lg={props.field.customCol ? 7 : 9}>
                    {props.formType !== 'view' ? (
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            isMulti={props.field.isMulti ? true : false}
                            isClearable={props.field.isMulti ? true : false}
                            id={props.field.label}
                            name={props.field.value}
                            closeMenuOnSelect={!props.field.isMulti}
                            options={props.field?.options}
                            placeholder={props.field.placeholder}
                            onBlur={() => props.validation.handleBlur({ target: { name: props.field.value } })}
                            onChange={(selectedOption) => {
                                props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } });
                                setDateVal('');
                                setSearchParams({ select: '*', page: 1, size: 10 });
                            }}
                            value={props.validation?.values[props.field.value] || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                        props.validation?.values?.[props.field.value]
                            ?.map((v) => v.label)
                            ?.toString()
                            ?.split(',')
                            ?.join(', ')
                    ) : props.validation?.values[props.field.value]?.label ? (
                        props.validation?.values[props.field.value]?.label
                    ) : props.validation?.values[props.field.value] ? (
                        props.validation?.values[props.field.value]
                    ) : (
                        '–'
                    )}
                </Col>
            </Row>
        );
    };
    const radioButton = (props) => {
        return (
            <React.Fragment key={props.index}>
                <Col xs={6} md={6} sm={6} lg={props.field.code ? 7 : 5} xl={props.field.code ? 7 : 5} className="d-flex">
                    <Label className="mb-0 fw-medium d-flex align-items-center fs-13">{props.field.label}</Label>
                    {props.field?.mandatory && <span className="text-danger ms-1">*</span>}
                </Col>
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex align-items-center justify-content-between">
                    <div>{props.field.helpText && <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mb-2">
                    <>
                        {props.field?.radios?.map((ele, ind) => {
                            return (
                                <div className="form-check form-check-inline" key={ind}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={props.field.value}
                                        name={props.field.value}
                                        value={ele.val}
                                        onChange={props.validation.handleChange}
                                        onBlur={props.validation.handleBlur}
                                        checked={props.validation.values[props.field.value] === ele.val}
                                        disabled={props.formType === 'view'}
                                    />
                                    <Label className="mb-0">{ele.label}</Label>
                                </div>
                            );
                        })}
                    </>
                </Col>
            </React.Fragment>
        );
    };
    const dateComponent = (props) => {
        let formattedDate = '';
        return (
            <React.Fragment key={props.index}>
                <Col xs={6} md={6} sm={6} lg={4} xl={4} className={`pt-0 d-flex ${!props.field.helpText && 'mb-2'} `}>
                    <Label className="mb-0 fw-medium d-flex align-items-center fs-13">{props.field.label}</Label>
                    {props.field?.mandatory && <span className="text-danger ms-1">*</span>}
                </Col>
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex align-items-center justify-content-between">
                    <div>{props.field.helpText && <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </Col>
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                    <div className="col-sm-auto">
                        <div className="form-icon flex-nowrap">
                            <Flatpickr
                                ref={fp3}
                                value={formattedDate}
                                placeholder={props?.field?.label}
                                className="form-control form-control-icon w-100 date-filter-picker"
                                options={{
                                    mode: 'single',
                                    dateFormat: 'd M, Y'
                                }}
                                onChange={(e) => {
                                    let date = getDateOnly(e[0]);
                                    let dateObject = new Date(date);
                                    var day = ('0' + dateObject.getDate()).slice(-2);
                                    var month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
                                    var year = dateObject.getFullYear();
                                    formattedDate = year + '-' + month + '-' + day;
                                    scheduleValidation.setFieldValue(props.field.value, formattedDate);
                                }}
                            />
                            <i className="ri-calendar-2-line" />
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };
    const chipsComponent = (props) => {
        return (
            <Row className="mt-2" key={props.index}>
                <Col xs={6} md={12} sm={6} lg={props.code ? 7 : 12} xl={props.code ? 7 : 12} className="d-flex">
                    <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-13">{props.field.label}</Label>
                    {props.field?.mandatory && <span className="text-danger ms-1">*</span>}
                </Col>
                {props.field.helpText && (
                    <div>
                        <Col md={props.field.customCol ? 8 : 12} xl={props.field.customCol ? 7 : 12} lg={props.field.customCol ? 7 : 12}>
                            {props.field.helpText && <div className="text-muted mb-2 word-wrap-break">{props.field.helpText}</div>}
                        </Col>
                    </div>
                )}
                <Col xs={3} md={3} sm={3} lg={9} xl={9}>
                    <div className="d-flex align-items-center">
                        {props.formType !== 'view' && (
                            <>
                                <div className="input-group widthPer-60 me-2">
                                    <Input
                                        name={props.field.value}
                                        id={props.field.value}
                                        className="form-control"
                                        placeholder={`Enter ${props.field.label}`}
                                        type={props.field.type}
                                        validate={{ required: { value: true } }}
                                        onChange={props.validation.handleChange}
                                        onBlur={props.validation.handleBlur}
                                        onKeyDown={(e) => props.handleKeyDown(e)}
                                        value={props.validation.values[props.field.value] || ''}
                                        invalid={
                                            props.validation.touched[props.field.value] && props.validation.errors[props.field.value]
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <Button
                                    size="sm"
                                    type="button"
                                    disabled={!props.validation.values[props.field.value]}
                                    color={'primary'}
                                    onClick={() => props.handleAddFRPMail(props.field)}
                                >
                                    <span className="d-flex align-items-center">
                                        <i className="ri-add-line me-1 fs-16"></i> Add Mail
                                    </span>
                                </Button>
                            </>
                        )}
                    </div>
                    {props.isInvalidMail ? <p className="mt-1 mb-1 text-danger fs-12">{props.field.invalidMsg}</p> : ''}
                    <div className={`filter-choices-input height-100 overflow-auto ${props.formType === 'view' ? '' : 'p-2'}`}>
                        <div className="choices" data-type="text">
                            <div className="choices__inner min-height-1">
                                <div className="choices__list choices__list--multiple">
                                    {props.validation.values?.[props.field.list]?.length > 0 &&
                                        props.validation.values?.[props.field.list]?.map((ele, ind) => {
                                            return (
                                                <div key={ind} className="choices__item choices__item--selectable bg-primary border-white">
                                                    <div id={`bookmark-${ind}`}>
                                                        {ele}
                                                        {props.formType !== 'view' && (
                                                            <button
                                                                type="button"
                                                                className="choices__button"
                                                                onClick={() => props.handleRemoveOneFRPMail(ele, props.field)}
                                                            >
                                                                Remove item
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {props.validation.values?.[props.field.list]?.length === 0 && props.formType === 'view' ? '—' : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            reportFilter: ''
        },
        onSubmit: (values) => {
            setSelectedReport(validation.values?.reportFilter?.key);
            setFormValues(values);
            serviceCall(validation.values?.reportFilter?.key, searchParams);
        }
    });
    const scheduleValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            reportType: '',
            frequency: 'daily',
            startDate: '',
            endDate: '',
            repeat: '',
            reportSendTo: '',
            reportGenerateTime: ''
        },
        onSubmit: (values) => {
            let dataaa = {
                reportType: values?.reportType?.key,
                frequency: values?.frequency,
                startDate: values?.startDate,
                endDate: values?.endDate,
                repeat: values?.repeat,
                reportSendTo: values?.reportSendTo,
                reportGenerateTime: values?.reportGenerateTime
            };
            setDataObj([...dataObj, dataaa]);
            scheduleValidation.resetForm();
        }
    });

    const handleScheduleEdit = (obj, index) => {};
    const handleScheduleDelete = (index) => {
        let deleteArr = [...dataObj];
        deleteArr.splice(index, 1);
        setDataObj(deleteArr);
    };

    const handleViewHistory = (index) => {
        const updatedChecked = { ...isViewHistory };
        updatedChecked[index] = !updatedChecked[index];
        setIsViewHistory(updatedChecked);
    };
    const selectedField = () => {
        let field = {};
        schema?.[0]?.options?.forEach((obj) => {
            if (validation.values?.reportFilter?.key === obj?.key) field = obj;
            else return;
        });
        return field;
    };

    useEffect(() => {
        handleDateSearch(dateVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateVal]);

    const handlePolicyTabContent = (type) => {
        return type === 'GeneralReports' ? (
            <>
                <Card>
                    <Row className="p-3 d-flex align-items-center">
                        <Col md={3} lg={3} sm={'auto'}>
                            {selectComponent({ field: schema?.[0], validation: validation, formType: formType })}
                        </Col>
                        {selectedField()?.filters && (
                            <Col sm={'auto'}>
                                <div className="col-sm-auto">
                                    <div className="form-icon flex-nowrap">
                                        <Flatpickr
                                            ref={fp}
                                            value={dateVal}
                                            placeholder={'Search by Date Range'}
                                            className="form-control form-control-icon date-filter-picker"
                                            options={{ mode: 'range', maxDate: 'today', dateFormat: 'd M, Y' }}
                                            onChange={(e) => {
                                                setDateVal(e);
                                            }}
                                        />
                                        <i className="ri-calendar-2-line" />
                                    </div>
                                </div>
                            </Col>
                        )}
                        <Col sm={'auto'}>
                            <Button
                                size={'md'}
                                className="bg-primary border-primary"
                                disabled={validation.values.reportFilter === ''}
                                onClick={validation.handleSubmit}
                            >
                                Apply
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                size={'md'}
                                className="bg-light border-primary text-primary"
                                disabled={validation.values.reportFilter === ''}
                                onClick={() => {
                                    validation.resetForm();
                                    setSelectedReport('');
                                    setSearchParams({ select: '*', page: 1, size: 10 });
                                    setFormValues({ ...formValues, reportFilter: '' });
                                    setDateVal('');
                                }}
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card className=" p-0 mt-3 mb-0 ">
                    {selectedReport !== '' ? (
                        <TableContainer
                            loading={loading}
                            searchFilter2={selectedObj?.table?.searchFilter}
                            exportLogs={selectedObj?.table?.exportLogs}
                            dropDownFilter2={selectedObj?.table?.dropdownFilter}
                            datePlaceHolder={'Date Range'}
                            searchPlaceHolder={selectedObj?.table?.searchPlaceholder || 'Serial'}
                            exportLogTooltip="Export in CSV."
                            tableHeader={selectedObj?.table?.tableHeader}
                            isGlobalFilter={true}
                            addButton={false}
                            columns={selectedObj?.table?.tableSchema || []}
                            data={dataa || []}
                            pageNum={pageNumber}
                            totalRecords={totalRecords}
                            dropDownParams2={{
                                label: 'Application',
                                value: 'app',
                                options: applications,
                                className: 'width:200 max-width-200 min-width-200',
                                clearable: true
                            }}
                            handleSelectChange2={handleSelectChange}
                            onPageChange={onPageChange}
                            handleGlobalSearch={handleGlobalSearch}
                            handleExportLogs={handleExportLogs}
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="table table-nowrap table-border table-centered align-middle"
                            theadClass="bg-light text-muted"
                        />
                    ) : (
                        <span className="card-height-100 text-center p-20 min-height-490">
                            <img src={ReportImage} alt="reportIamge" height={200} />
                            <div className="fw-semibold fs-14">No Data Found</div>
                            <div className="mt-2">Select the Filters above to choose Which Reports to Display</div>
                        </span>
                    )}
                </Card>
            </>
        ) : (
            <Card className="card-height-100">
                <CardHeader className="d-flex align-items-center justify-content-between">
                    <div className="fw-medium fs-14">Total Reports ({dataObj?.length})</div>
                    <div>
                        <Button size={'md'} className="bg-primary border-none" onClick={() => setIsSchedule(true)}>
                            + Schedule Reports
                        </Button>
                    </div>
                </CardHeader>
                <CardBody className="card-height-100 pb-0 min-height-490">
                    {!callLogReport ? (
                        <span className="d-flex justify-content-center align-items-center">
                            <span className="text-center p-20">
                                <img src={ReportImage} alt="reportIamge" height={200} />
                                <div className="fw-semibold fs-14">No Data Found</div>
                                <div className="mt-2">Select the Filters above to choose Which Reports to Display</div>
                            </span>
                        </span>
                    ) : (
                        <Row>
                            {dataObj?.length > 0 &&
                                dataObj?.map((data, index) => (
                                    <Col md={4} lg={4} xl={4}>
                                        <Card className="card-height-100 shadow-card" key={index}>
                                            <CardHeader className="d-flex justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <i className="ri-contacts-book-line fs-18"></i>
                                                    </div>
                                                    <div className="fw-medium fs-13">Call Log Report</div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="form-switch form-switch-sm">
                                                        <Input
                                                            className={'form-check-input cursor-pointer'}
                                                            type="checkbox"
                                                            role="switch"
                                                        />
                                                    </div>
                                                    <div className="cursor-pointer" onClick={() => handleScheduleEdit(data, index)}>
                                                        <i className="ri-edit-box-line fs-15 pe-1  text-primary px-1"></i>
                                                    </div>
                                                    <div className="cursor-pointer" onClick={() => handleScheduleDelete(index)}>
                                                        <i className="ri-delete-bin-6-line fs-15  text-danger px-1"></i>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            {!isViewHistory[index] ? (
                                                <>
                                                    <CardBody>
                                                        <Row>
                                                            {calllogReport?.map((ele, indexx) => {
                                                                return (
                                                                    <Col className="col-4" key={indexx}>
                                                                        <div className="text-muted fw-medium fs-12 ">{ele.label}</div>
                                                                        <div className="mt-1 fw-semibold fs-12">
                                                                            {data?.[ele?.accessor]?.value
                                                                                ? data?.[ele.accessor].value
                                                                                : data?.[ele?.accessor]}
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                        </Row>
                                                    </CardBody>
                                                    <CardFooter className="d-flex justify-content-between">
                                                        <div className="fw-medium text-secondary">
                                                            ( {dataObj?.[index].reportSendTo?.length} )
                                                        </div>
                                                        <div
                                                            className="fs-13 fw-medium text-secondary cursor-pointer"
                                                            onClick={() => handleViewHistory(index)}
                                                        >
                                                            View Report History
                                                        </div>
                                                    </CardFooter>
                                                </>
                                            ) : (
                                                isViewHistory[index] && (
                                                    <CardBody className="position-relative pt-0 ">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="fs-13 fw-medium pt-1">Report Sent Email IDs</div>
                                                            <div
                                                                className="position-absolute end-0 top-0 cursor-pointer px-2"
                                                                onClick={() => handleViewHistory(index)}
                                                            >
                                                                <i className="ri-close-fill fs-16"></i>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {dataObj?.[index].reportSendTo?.length > 0 &&
                                                                dataObj?.[index].reportSendTo?.map((email, ind) => (
                                                                    <div className="col-6 py-3" key={ind}>
                                                                        {email}
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </CardBody>
                                                )
                                            )}
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    )}
                </CardBody>
            </Card>
        );
    };
    const reportsList = [
        {
            tabID: 'GeneralReports',
            tabName: (
                <div className="d-flex align-items-center">
                    <i className="ri-file-chart-line fs-15 pe-1" />
                    <span> General Reports</span>
                </div>
            ),
            tabContent: handlePolicyTabContent('GeneralReports')
        }
        /*
         * {
         *     tabID: 'ScheduleReports',
         *     tabName: (
         *         <div className="d-flex align-items-center">
         *             <i className="ri-calendar-event-line fs-15 pe-1" />
         *             <span> Schedule Reports</span>
         *         </div>
         *     ),
         *     tabContent: handlePolicyTabContent('ScheduleReports')
         * }
         */
    ];

    const handleShowOn = (field) => {
        let flag = true;
        field.showOn?.forEach((showOn) => (flag = flag && !!scheduleValidation.values[showOn]));
        flag = field.showKey ? flag && scheduleValidation.values[field.showKey] === field.showVal : flag;
        return flag;
    };

    const handleFields = (field, index) => {
        return field.inputType === 'dropdown' ? (
            selectComponent({ field: field, validation: scheduleValidation, index: index, formType: formType })
        ) : field.inputType === 'radios' ? (
            radioButton({ field: field, validation: scheduleValidation, index: index, formType: formType })
        ) : field.inputType === 'date' ? (
            dateComponent({ field: field, validation: scheduleValidation, index: index, formType: formType })
        ) : field.inputType === 'chips' ? (
            chipsComponent({
                field: field,
                validation: scheduleValidation,
                index: index,
                formType: formType,
                handleAddFRPMail: handleAddFRPMail,
                handleKeyDown: handleKeyDown,
                handleRemoveOneFRPMail: handleRemoveOneFRPMail
            })
        ) : (
            <div className="fw-semibold text-decoration-underline text-success fs-14">{field.label} :</div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb pageTitle="Reports" history={history} homeLink="Dashboard" />
                    <Row>
                        {!isSchedule ? (
                            <TabsComponent
                                paddingRequired={true}
                                paddingClass={'p-0'}
                                tabsList={reportsList}
                                defaultTabID={selectedTab}
                                toggleTab={(tab) => setSelectedTab(tab)}
                                custombg="bg-light"
                            />
                        ) : (
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center">
                                        <span className="fw-semibold fs-14 mb-1">Schedule Reports</span>
                                    </div>
                                    <span className="fs-13">helptext</span>
                                </CardHeader>
                                <CardBody>
                                    {scheduleSchema?.map((field, index) => {
                                        return (field.showOn ? handleShowOn(field) : true) && handleFields(field, index);
                                    })}
                                </CardBody>
                                <CardFooter>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <div className="pe-2">
                                            <Button
                                                type="button"
                                                className="btn btn-light w-100"
                                                onClick={() => {
                                                    setIsSchedule(false);
                                                    scheduleValidation.resetForm();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                size="md"
                                                type="button"
                                                className="d-flex align-items-center justify-content-center w-100"
                                                color={'primary'}
                                                disabled={
                                                    scheduleValidation.values?.reportType === '' ||
                                                    scheduleValidation.values?.frequency === '' ||
                                                    scheduleValidation.values?.startDate === '' ||
                                                    scheduleValidation.values?.endDate === '' ||
                                                    scheduleValidation.values?.repeat === '' ||
                                                    scheduleValidation.values?.reportSendTo === '' ||
                                                    scheduleValidation.values?.reportGenerateTime === ''
                                                }
                                                onClick={() => {
                                                    setIsSchedule(false);
                                                    scheduleValidation.handleSubmit();
                                                    setCallLogReport(true);
                                                }}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        )}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Reports;
