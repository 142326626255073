import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { EnvProvider } from './envContext';
import reportWebVitals from './reportWebVitals';
import { Store, persistor } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
            <HashRouter>
                <EnvProvider>
                    <React.Fragment>
                        <App />
                    </React.Fragment>
                </EnvProvider>
            </HashRouter>
        </PersistGate>
    </Provider>
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
