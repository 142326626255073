import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { Enterprise, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { appInstallationSchema } from '../TableSchema';

const AppInstallations = () => {
    document.title = 'App Installation Report';
    let history = useHistory();
    let api = new APIClient();
    const urlconf = useEnv();
    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        handlePromise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePromise = () => {
        setLoading(true);
        let applicationsPromise = new Promise((resolve, reject) => {
            api.get(url.APPLICATIONS, { enterpriseId: enterpriseObj?.id, page: 1, size: 1000 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Failed to fetch Apps.');
                })
                .catch((err) => reject('Failed to fetch Apps'));
        });
        let appVersionPromise = new Promise((resolve, reject) => {
            api.create(url.DEVICE_REPORTS + '/apps', searchParams)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp);
                    else reject('Failed to fetch app version report.');
                })
                .catch((err) => reject('Failed to fetch app version report'));
        });

        Promise.allSettled([applicationsPromise, appVersionPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    result[0].value?.forEach((app) => {
                        app.label = app.title;
                        app.value = app.packageName;
                    });
                    setApplications(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    result[1]?.value.data.forEach((dataObj) => {
                        dataObj.appName = dataObj.appName || dataObj.packageName;
                        dataObj.badgeClass = dataObj.status === 'INSTALLED' ? 'success' : 'danger';
                        dataObj.modifiedTime = getDate(dataObj.modifiedDate);
                        dataObj.imei = dataObj.imei ? dataObj.imei : '—';
                    });
                    setTotalRecords(result[1]?.value.totalRecords);
                    setData(result[1]?.value.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getAppVersions = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.create(url.DEVICE_REPORTS + '/apps', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((dataObj) => {
                        dataObj.appName = dataObj.appName || dataObj.packageName;
                        dataObj.badgeClass = dataObj.status === 'INSTALLED' ? 'success' : 'danger';
                        dataObj.modifiedTime = getDate(dataObj.modifiedDate);
                        dataObj.imei = dataObj.imei ? dataObj.imei : '—';
                    });
                    setTotalRecords(resp.totalRecords);
                    setData(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleExportLogs = () => {
        setLoading(true);
        let params = JSON.parse(JSON.stringify(searchParams));
        delete params.page;
        delete params.size;
        api.create(url.DEVICE_REPORTS + '/download/apps', params).then((resp) => {
            let blob = new Blob([resp], { type: 'application/octet-stream' });
            var fileURL = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = fileURL;
            a.download = 'App Installation Report.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setLoading(false);
        });
    };

    const onPageChange = (pgNumber) => {
        setPageNumber(pgNumber);
        let params = { ...searchParams, page: pgNumber, size: 10 };
        getAppVersions(params);
    };

    const handleGlobalSearch = (val) => {
        setPageNumber(1);
        let params = { ...searchParams, page: 1, size: 10, serial: val };
        getAppVersions(params);
    };

    const handleSelectChange = (val) => {
        setPageNumber(1);
        let params = { ...searchParams, page: 1, size: 10, packageName: val?.packageName };
        getAppVersions(params);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'App Version Report'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="aereports"
                    />
                    <TableContainer
                        searchFilter2={true}
                        exportLogs={true}
                        dropDownFilter2={true}
                        dropDownParams2={{
                            label: 'Application',
                            value: 'app',
                            options: applications,
                            className: 'width:200 max-width-200 min-width-200',
                            clearable: true
                        }}
                        handleSelectChange2={handleSelectChange}
                        handleExportLogs={handleExportLogs}
                        searchPlaceHolder={'Serial'}
                        exportLogTooltip="Export in CSV."
                        tableHeader={'App Versions'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={appInstallationSchema}
                        data={data || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AppInstallations;
