import React, { useEffect, useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import { Card, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom';
import MAMLOGO from '../../../assets/images/MAM.png';
import TickMark from '../../../assets/images/tickmark.svg';
import TabsComponent from '../../../Components/Common/TabsComponent';
import ProtectionPolicies from './ProtectionPolicies';
import ConditionalAccess from './ConditionalAccess';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import tectoro from '../../../assets/images/Tectoro.png';

const AppProtection = () => {
    const api = new APIClient();
    const urlconf = useEnv();
    const history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('appProtection');
    const [configuration, setConfiguration] = useState('');
    const configObj = {
        tectoro: {
            image: tectoro,
            desc: 'App protection policies are essential guidelines designed to ensure the security and privacy of applications. These policies help safeguard against unauthorized access, data breaches, and other security threats in Applications'
        },
        microsoft: { image: MAMLOGO, desc: 'Sync devices from Microsoft Entra ID (Office 365) to apply App Protection Policy.' }
    };

    useEffect(() => {
        handlePromise();
    }, []);

    const handlePromise = () => {
        api.get(url.MS_APP_CONFIG, '', domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') setConfiguration(resp.data);
            })
            .catch((err) => setLoading(false));
    };

    const handleTabContent = (key) => {
        return key === 'appProtection' ? (
            <ProtectionPolicies selectedTab={selectedTab} loading={loading} setLoading={setLoading} />
        ) : (
            <ConditionalAccess selectedTab={selectedTab} loading={loading} setLoading={setLoading} />
        );
    };

    const tabsList = [
        { tabID: 'appProtection', tabName: 'App Protection Policy', tabContent: handleTabContent('appProtection') },
        { tabID: 'conditionalAccess', tabName: 'Conditional Access', tabContent: handleTabContent('conditionalAccess') }
    ];

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Protection Policies"
                        showBack={true}
                        backLink={'appprotectionpolicy'}
                        history={history}
                        homeLink="Dashboard"
                    />
                    <Row>
                        <Card>
                            <div className="p-4 d-flex align-items-center">
                                <div className="pe-4">
                                    <img src={configObj[formTypeAndId.recordID].image} alt="img" height={80} />
                                </div>
                                <Col className="fs-14">
                                    {formTypeAndId.recordID !== 'tectoro' && (
                                        <div className="fw-medium mb-1 fs-14">Integrate Microsoft Entra ID</div>
                                    )}
                                    <div className="d-flex align-items-center text-muted fw-medium mb-1 fs-13">
                                        {configObj[formTypeAndId.recordID].desc}
                                    </div>
                                    <div
                                        className={`d-flex align-items-center ${
                                            Object.keys(configuration)?.length > 0 ? 'text-success' : 'text-danger'
                                        } fw-semibold mb-1 fs-14`}
                                    >
                                        {Object.keys(configuration)?.length > 0 ? 'Configured' : 'Not Configured'}
                                        <div className="ps-1">
                                            {Object.keys(configuration)?.length > 0 ? (
                                                <i className="ri-checkbox-circle-fill text-success height-25" />
                                            ) : (
                                                <i className="ri-close-circle-fill text-danger height-25" />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Card>
                    </Row>
                    <TabsComponent
                        tabsList={
                            formTypeAndId.recordID === 'tectoro'
                                ? [
                                      {
                                          tabID: 'appProtection',
                                          tabName: 'App Protection Policy',
                                          tabContent: handleTabContent('appProtection')
                                      }
                                  ]
                                : tabsList
                        }
                        defaultTabID={'appProtection'}
                        noPadding={true}
                        toggleTab={(tab) => setSelectedTab(tab)}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AppProtection;
