import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import toastMessages from '../../../common/messages/toastMessages';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import MapView from './MapView';

const ViewAndroidMap = () => {
    const config = useEnv();

    Geocode.setApiKey(config.REACT_APP_MAP_TOKEN);
    Geocode.setLanguage('en');
    let api = new APIClient();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let recordID = formTypeAndId['recordID'];
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deviceData, setDeviceData] = useState({ serial: '', center: { lat: 17.43274, lng: 78.3843461 }, geoRadius: 0 });
    const [policy, setPolicy] = useState({ center: { lat: 17.43274, lng: 78.3843461 }, geoRadius: 0 });

    useEffect(() => {
        getDeviceLocation();
    }, []);

    const getAddress = async (latLng) => {
        let addressString = '';
        await Geocode.fromLatLng(latLng.lat, latLng.lng)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.results) {
                    resp.results[0].address_components.forEach((addressObj, ind) => {
                        addressString = addressString + (ind !== 0 ? ', ' : '') + addressObj.long_name?.toString();
                    });
                }
            })
            .catch((_err) => {
                toast.error(toastMessages.addressNotFound);
            });

        return addressString;
    };

    const getDeviceLocation = () => {
        setLoading(true);
        api.get(url.DEVICES + '/lastseen/' + recordID)
            .then(async (resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let configuredLocation = resp.data?.policy?.GEOFENCE?.featureEnabled
                        ? resp.data?.policy?.GEOFENCE?.restrictions?.zones?.[0]
                        : '';
                    let lastSeen = resp?.data?.location ? resp?.data?.location?.split(',') : '';
                    let configuredAddress = configuredLocation?.latitude
                        ? await getAddress({
                              lat: configuredLocation?.latitude ? Number(configuredLocation?.latitude).toFixed(7) : 17.43274,
                              lng: configuredLocation?.longitude ? Number(configuredLocation?.longitude).toFixed(7) : 78.3843461
                          })
                        : '—';
                    let deviceAddress = lastSeen?.[0]
                        ? await getAddress({
                              lat: lastSeen?.[0] ? Number(lastSeen?.[0]).toFixed(7) : 17.43274,
                              lng: lastSeen?.[1] ? Number(lastSeen?.[1]).toFixed(7) : 78.3843461
                          })
                        : '—';
                    setPolicy({
                        configured: !!configuredLocation?.latitude,
                        geoRadius: configuredLocation?.radius,
                        center: {
                            lat: configuredLocation?.latitude ? Number(configuredLocation?.latitude)?.toFixed(7) : 17.43274,
                            lng: configuredLocation?.longitude ? Number(configuredLocation?.longitude) : 78.3843461
                        },
                        address: configuredAddress
                    });
                    setDeviceData({
                        lastSeen: !!lastSeen?.[0],
                        serial: resp.data?.serial,
                        geoRadius: configuredLocation?.radius,
                        center: {
                            lat: lastSeen?.[0] ? Number(lastSeen?.[0]) : 17.43274,
                            lng: lastSeen?.[1] ? Number(lastSeen?.[1]) : 78.3843461
                        },
                        group: resp.data.groupName ? resp.data.groupName : '—',
                        policy: resp.data.policyName ? resp.data.policyName : '—',
                        status: resp.data.geoFencedoc,
                        showCircle: resp.data.geoFencedoc === 'OUT',
                        address: deviceAddress
                    });
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Map View'} history={history} homeLink="Dashboard" showBack={true} backLink="aedevices" />
                    {deviceData.lastSeen ? (
                        <Card className="card-height-100">
                            <CardHeader>
                                <Row>
                                    <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Serial Number</div>
                                        <div className="fw-medium">{deviceData.serial}</div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Policy</div>
                                        <div className="fw-medium">{deviceData.policy}</div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Device Group</div>
                                        <div className="fw-medium">{deviceData.group}</div>
                                    </Col>
                                    {deviceData.status === 'OUT' && (
                                        <Col xxl={3} xl={3} lg={3} md={5} sm={6} xs={6} xxs={12}>
                                            <div className="text-grey-text">Configured Location</div>
                                            <div className="fw-medium">
                                                <i className="ri-checkbox-blank-circle-fill link-success me-1" />
                                                {policy.address}
                                            </div>
                                        </Col>
                                    )}
                                    <Col xxl={3} xl={3} lg={4} md={5} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Device Location</div>
                                        <div className="fw-medium">
                                            <i
                                                className={`ri-checkbox-blank-circle-fill ${
                                                    deviceData?.status === 'OUT' ? 'link-danger' : 'link-success'
                                                } me-1`}
                                            />
                                            {deviceData.address}
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="heightVh-60">
                                <MapView policy={policy} deviceData={deviceData} />
                            </CardBody>
                        </Card>
                    ) : (
                        <Card>
                            <NoRecordsFound />
                        </Card>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewAndroidMap;
