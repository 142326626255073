import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Alert, Card, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';

// Formik Validation
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { TenantConfig } from '../../Components/Common/Util';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import AuthSlider from './AuthSlider';

const ForgetPasswordPage = () => {
    document.title = 'Reset Password';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const tenantConfig = TenantConfig();
    let account = localStorage.getItem('account');

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: { username: '' },
        validationSchema: Yup.object({
            username: Yup.string().required('Please Enter Your User Name')
        }),
        onSubmit: (values) => {
            setLoading(true);
            let params = { username: values.username, tenant: account };
            api.create(url.FORGOT_PASSWORD, params, false, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(toastMessages.otpSent);
                        localStorage.setItem('authObj', JSON.stringify({ username: values.username }));
                        history.push('/forgot-otp-verification');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    });

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center overflow-y-auto">
                {/* <div className="bg-overlay"></div> */}
                <Col lg={12}>
                    <Card className="overflow-x-hidden m-0">
                        <Row className={'g-0 vh-100 vw-100'}>
                            <AuthSlider />
                            <Col lg={4} className={'d-flex justify-content-center align-items-center'}>
                                <div className="p-lg-12 p-6 w-100">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center mb-5 text-white-50">
                                                <div className="mb-5">
                                                    <div className="d-inline-block auth-logo">
                                                        <img src={tenantConfig?.COMPANY_LOGO} alt="" height={40} />
                                                    </div>
                                                </div>
                                                {TenantConfig.PRIMARY_HEADER && (
                                                    <h4 className={'mt-4 text-dark'}>{TenantConfig.PRIMARY_HEADER}</h4>
                                                )}
                                                {TenantConfig.SECONDARY_HEADER && (
                                                    <h5 className="fw-medium text-muted">{TenantConfig.SECONDARY_HEADER}</h5>
                                                )}
                                                <div className="mt-8">
                                                    <p className="fs-15 mb-2 fw-medium text-primary">Forgot Password?</p>
                                                    <p className="text-muted">
                                                        Reset your password with{' '}
                                                        <span className="fw-medium text-primary">{` ${tenantConfig.COMPANY_DEVELOPED_BY}.`}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="text-center">
                                        <lord-icon
                                            src="https://cdn.lordicon.com/rhvddzym.json"
                                            trigger="loop"
                                            colors={'primary:#0ab39c'}
                                            className="avatar-xl"
                                            // eslint-disable-next-line no-inline-styles/no-inline-styles
                                            style={{ width: '100px', height: '100px' }}
                                        ></lord-icon>
                                    </div>

                                    <Alert className="alert-borderless alert-warning text-center mb-4 mx-2 ps-0 pe-0" role="alert">
                                        OTP will be sent to your Email ID
                                    </Alert>
                                    <div className="p-2">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-4">
                                                <Label className="form-label">User Name</Label>
                                                <Input
                                                    name="username"
                                                    className={'form-control'}
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.username || ''}
                                                    invalid={validation.touched.username && validation.errors.username ? true : false}
                                                />
                                                {validation.touched.username && validation.errors.username ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.username}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mt-4">
                                                <button
                                                    type="submit"
                                                    className={'btn btn-success btn-load w-100'}
                                                    disabled={validation.values.username === '' || validation.errors.username || loading}
                                                >
                                                    <span className="d-flex align-items-center justify-content-center">
                                                        Send OTP
                                                        {loading && (
                                                            <span className="ms-2 spinner-border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </span>
                                                        )}
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="mt-5 text-center">
                                                <div className="mb-0 d-flex align-items-center justify-content-center">
                                                    Wait, I remember my password...
                                                    <div
                                                        onClick={() => history.push('/login')}
                                                        className="ps-1 fw-semibold text-primary text-decoration-underline cursor-pointer"
                                                    >
                                                        Click here
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </div>
        </React.Fragment>
    );
};

ForgetPasswordPage.propTypes = {
    history: PropTypes.object
};

export default withRouter(ForgetPasswordPage);
