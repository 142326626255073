const userFormData = [
    { label: 'First Name', value: 'firstname', type: 'text', inputType: 'input' },
    { label: 'Last Name', value: 'lastname', type: 'text', inputType: 'input' },
    { label: 'Email ID', value: 'email', type: 'email', inputType: 'input' },
    { label: 'Mobile Number', value: 'phone', type: 'text', inputType: 'input', enableCountry: true, mobileValue: 'countryCode' },
    { label: 'User Name', value: 'username', type: 'text', inputType: 'input' }
];

const deviceGroupFromData = [
    {
        label: 'Device Group Name',
        value: 'name',
        type: 'text',
        inputType: 'input',
        mandatory: true
    }
];

export { userFormData, deviceGroupFromData };
