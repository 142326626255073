/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FilePond } from 'react-filepond';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Label, Row, UncontrolledAlert } from 'reactstrap';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import getChartColorsArray from '../../../Components/Common/ChartsDynamicColor';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    AuthUser,
    DownloadBunnyFile,
    TenantConfig,
    Tooltip,
    convertBytesTo,
    convertDataToBytes,
    fileValidation,
    getDate,
    noCaseSensitiveSortBy
} from '../../../Components/Common/Util';
import { contentManagementCards } from '../../../Components/constants/commons';
import { fileTypeValidations, priviliges } from '../../../Components/constants/constants';
import images from '../../../assets/images/images.svg';
import polDelete from '../../../assets/images/poldelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { contentManagementSchema } from '../TableSchema';

const ContentManagement = () => {
    const tenant = TenantConfig();
    document.title = 'Files';
    let history = useHistory();
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [checked, setChecked] = useState([]);
    const [checkedNames, setCheckedNames] = useState([]);
    const [contents, setContents] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [flatGroupsForSharedModal, setFlatGroupsForSharedModal] = useState([]);
    const [openGroups, setOpenGroups] = useState(false);
    const [memoryByType, setMemoryByType] = useState(0);
    const [activeTab, setActiveTab] = useState('listView');
    const [acceptFiles, setAcceptFiles] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [deviceGroupsArrBackUp, setDeviceGroupsArrBackUp] = useState([]);
    const [shareGroups, setShareGroups] = useState([]);
    const [shareGroupsBackup, setShareGroupsBackup] = useState([]);
    const [shareChecked, setShareChecked] = useState([]);
    const [tableSchema, setTableSchema] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [file, setFile] = useState('');

    const [sideMenu, setSideMenu] = useState([
        // { label: 'All Files', icon: 'ri-folder-5-fill' },
        { label: 'Groups', icon: 'ri-stack-fill', groups: [] }
    ]);

    const cards = contentManagementCards;
    const [selected, setSelected] = useState({});
    const config = useEnv();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';

    useEffect(() => {
        let findPriv = !user?.data?.privileges?.includes(priviliges.FILE_EDITOR);
        let schema = [...contentManagementSchema];
        if (findPriv) schema = contentManagementSchema.filter((item, index) => index < contentManagementSchema.length - 2);
        setTableSchema([...schema]);
        let params = { ...searchParams };
        getDeviceGroups(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
     * const getCheckedGroupNames = (checkedGroups) => {
     *     let namesArr = [];
     *     if (checkedGroups?.length > 0) {
     *         checkedGroups.forEach((group) => {
     *             deviceGroupsArr.forEach((deviceGroups) => {
     *                 if (group === deviceGroups.value) {
     *                     namesArr.push(deviceGroups.label);
     *                 }
     *             });
     *         });
     *     }
     *     return namesArr;
     * };
     */

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.code);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setGroupsData = (deviceGroups, groupsData) => {
        deviceGroups.forEach((data) => {
            data = { ...data, label: data.name, key: data.code };
            groupsData.push(data);
            if (data.children) {
                let obj = setGroupsData(data.children, groupsData);
                data.children = obj.deviceGroups;
                groupsData = [...obj.groupsData];
            }
        });
        return { deviceGroups: deviceGroups, groupsData: groupsData };
    };

    const getDeviceGroups = (contentParams) => {
        setLoading(true);
        api.get(url.DEVICE_GROUPS, '', domains.IDM)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                let tagArr = [];
                if (resp.data?.length > 0) {
                    setFlatGroups(resp.data);
                    setFlatGroupsForSharedModal(resp.data);
                    if (resp.data?.length > 0) {
                        let groupsData = [];
                        resp.data.forEach((data) => {
                            data = { ...data, label: data.name, key: data.code };
                            groupsData.push(data);
                            if (data.children) {
                                let obj = setGroupsData(data.children, groupsData);
                                data.children = obj.deviceGroups;
                                groupsData = [...obj.groupsData];
                            }
                        });
                        groupsData[0].checked = true;
                        setFlatGroups(groupsData);
                    }
                    tagArr = noCaseSensitiveSortBy(resp.data, 'label');
                    tagArr[0].checked = true;
                    setChecked([tagArr[0].code]);
                    setCheckedNames([tagArr[0].name]);
                    setLoading(false);
                    setShareGroups(JSON.parse(JSON.stringify(resp.data)));
                    setShareGroupsBackup(JSON.parse(JSON.stringify(resp.data)));
                    setDeviceGroupsArrBackUp(resp.data);
                    let sideMenuItems = JSON.parse(JSON.stringify(sideMenu));
                    sideMenuItems.forEach((side) => {
                        if (side.label === 'Groups') side.groups = JSON.parse(JSON.stringify(tagArr));
                    });
                    setSideMenu(JSON.parse(JSON.stringify(sideMenuItems)));
                }
                setSearchParams({ ...contentParams, groups: tagArr[0]?.code });
                getContents({ ...contentParams, groups: tagArr[0]?.code });
            })
            .catch((err) => setLoading(false));
    };

    const getContents = (params) => {
        setLoading(true);
        api.get(url.CONTENT_MANAGEMENT, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    let audio = resp.memorySize?.find((memorySize) => memorySize[0]?.toLowerCase() === 'audio');
                    let video = resp.memorySize?.find((memorySize) => memorySize[0]?.toLowerCase() === 'video');
                    let image = resp.memorySize?.find((memorySize) => memorySize[0]?.toLowerCase() === 'image');
                    let document = resp.memorySize?.find((memorySize) => memorySize[0]?.toLowerCase() === 'document');
                    setMemoryByType({
                        image: { size: image?.[2] ? convertBytesTo(image?.[2]) : 0, count: image?.[1] },
                        video: { size: video?.[2] ? convertBytesTo(video?.[2]) : 0, count: video?.[1] },
                        audio: { size: audio?.[2] ? convertBytesTo(audio?.[2]) : 0, count: audio?.[1] },
                        document: { size: document?.[2] ? convertBytesTo(document?.[2]) : 0, count: document?.[1] }
                    });
                    resp.data?.forEach((ele) => {
                        ele.fileSize = convertBytesTo(ele.fileSize);
                        ele.createdBy = ele.createdBy ? ele.createdBy : 'Tectoro';
                        ele.createdDate = getDate(ele.createdTime);
                        ele.sharedGroup = ele.sharedGroups?.length ? ele.sharedGroups?.map((group) => group.name)?.join(', ') : 0;
                        ele.doNotDelete = ele.sharedGroupsLength > 1;
                        ele.rowView = true;
                    });
                }
                setContents(resp.data);
                setTotalRecords(resp.totalRecords);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleClickView = (row) => {
        DownloadBunnyFile(row.original?.url, row.original?.fileName, setLoading);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.CONTENT_MANAGEMENT + '/' + selectedRow?.original?.id)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.fileDeleted);
                    getContents({ ...searchParams });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleCardClick = (card) => {
        let selectedCard = { [card.fileType]: !selected[card.fileType] };
        setSelected({ ...selectedCard });
        setAcceptFiles(card.fileFormats);
        let params = { ...searchParams };
        if (selectedCard[card.fileType]) params.fileType = card.fileType;
        else params.fileType = undefined;

        if (checked?.length > 0) params.groups = checked?.toString();
        else params.groups = undefined;

        setSearchParams({ ...params });
        getContents({ ...params });
        setPageNumber(1);
        // history.push('/contentmanagement/' + card.label);
    };

    const defineSharedGroups = (groups, sharedGroupIds, sharedGroups) => {
        let groupArr = JSON.parse(JSON.stringify(groups));
        groupArr.forEach((group) => {
            if (sharedGroupIds.includes(group.code)) {
                group.checked = true;
                sharedGroups.push(group.code);
            }
            if (group.children?.length > 0) {
                let obj = defineSharedGroups(group.children, sharedGroupIds, sharedGroups);
                group.children = obj.groups;
                sharedGroups = obj.sharedGroups;
            }
        });
        return { groups: groupArr, sharedGroups: sharedGroups };
    };

    const handleShare = (rowObj) => {
        setLoading(true);
        let row = JSON.parse(JSON.stringify(rowObj.original));
        let sharedGroupIds = row.sharedGroups.map((shared) => {
            let sharedArr = shared.split(',');
            return sharedArr[0]?.split('=')?.[1];
        });
        let sharedGroups = [];
        let deviceGroups = JSON.parse(JSON.stringify(shareGroupsBackup));
        deviceGroups.forEach((group) => {
            if (sharedGroupIds.includes(group.code)) {
                group.checked = true;
                sharedGroups.push(group.code);
            }
            if (group.children?.length > 0) {
                let obj = defineSharedGroups(group.children, sharedGroupIds, sharedGroups);
                group.children = obj.groups;
                sharedGroups = obj.sharedGroups;
            }
        });
        let flatGroupArr = JSON.parse(JSON.stringify(flatGroupsForSharedModal));
        flatGroupArr.forEach((group) => {
            if (sharedGroups.includes(group.code)) group.checked = true;
        });
        setLoading(false);
        setFlatGroupsForSharedModal(flatGroupsForSharedModal);
        setShareGroups(deviceGroups);
        setShareChecked(sharedGroups);
        setSelectedRow(row);
    };

    const setGroupChecked = (groups, val) => {
        groups.forEach((group) => {
            group.checked = val;
            if (group.children?.length > 0) group.children = setGroupChecked(group.children, val);
        });
        return groups;
    };

    const handleUpload = (files) => {
        let fileType = 'All';
        setShowUploadModal(false);
        let size = convertDataToBytes(tenant.MAX_FILE_SIZE ? tenant.MAX_FILE_SIZE : '500MB');
        Object.entries(selected).forEach((item) => {
            if (item[1]) fileType = item[0];
        });
        if (files?.[0]?.fileSize > 30000000) {
            toast.error(toastMessages.filesSizeGT30);
            return;
        }
        if (files?.[0]?.name?.length > 100) {
            toast.error(toastMessages.fileNameLimit);
            return;
        }
        let formData = new FormData();
        for (let [_key, value] of Object.entries(files)) {
            formData.append('files', value.file);
        }
        let validObj = fileValidation(fileType, files[0]?.fileExtension, fileTypeValidations);
        if (!validObj.valid) {
            toast.error('File Extension should be ' + validObj.message);
            setFile('');
            return;
        }
        formData.append('fileType', fileType);
        if (checked.length > 0) formData.append('groupCode', checked?.[0]);
        setLoading(true);
        setFile('');

        api.create(url.CONTENT_MANAGEMENT, formData, true)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.fileUploaded);
                    let params = { ...searchParams };
                    if (checked?.length > 0) params = { ...params, groups: checked?.toString() };
                    Object.entries(selected).forEach((item) => {
                        if (item[1]) params.fileType = item[0];
                    });
                    getContents({ ...params });
                    setLoading(false);
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleModalBody = () => {
        return (
            <div className="d-flex align-items-center justify-content-center flex-column">
                <div>
                    <div className="text-muted fw-medium">Group Name</div>
                    <Label className="mt-1 mb-3 fs-20">{checkedNames[0]}</Label>
                    <UncontrolledAlert color="warning" className="alert-border-left margin-bottom-0 padding-10">
                        <i className="ri-error-warning-line me-3 align-middle fs-18"></i>
                        <b>Upload</b> - The file will be shared to the above group
                    </UncontrolledAlert>
                    <div className="mt-3">
                        <FilePond
                            name="files"
                            maxFiles={1}
                            minFileSize="5KB"
                            maxFileSize="30MB"
                            allowMultiple={true}
                            files={file}
                            className="filepond filepond-input-multiple"
                            onupdatefiles={(fileItems) => setFile(fileItems)}
                        />
                    </div>
                </div>
                <Button
                    size="lg"
                    type="button"
                    id="takeAction"
                    color={'primary'}
                    disabled={file?.length === 0}
                    onClick={() => handleUpload(file)}
                >
                    <span className="d-flex align-items-center">Submit</span>
                </Button>
            </div>
        );
    };

    const onPageChange = (pgNumber, pgSize) => {
        let params = { ...searchParams, page: pgNumber, size: pgSize };
        Object.entries(selected).forEach((item) => {
            if (item[1]) params.fileType = item[0];
        });
        if (checked?.length > 0) params.groups = checked?.toString();
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        getContents(params);
    };

    const UpdateDonut = ({ dataColors }) => {
        var chartDonutupdatingColors = getChartColorsArray(dataColors);
        const series = [44, 55, 13, 33];
        var options = {
            chart: { height: 280, type: 'donut' },
            dataLabels: { enabled: false },
            legend: { show: false },
            colors: chartDonutupdatingColors
        };
        return (
            <div>
                <ReactApexChart series={series} options={options} type="donut" height={267.7} />
                <div className="w-100 mt-5">
                    <div className="d-flex justify-content-between w-100 height-50">
                        <div className="fs-12 fw-medium">
                            <i className="ri-checkbox-blank-circle-fill link-primary me-2" />
                            Images
                        </div>
                        <div className="fs-14 fw-semibold">10GB</div>
                    </div>
                    <div className="d-flex justify-content-between w-100 height-50">
                        <div className="fs-12 fw-medium">
                            <i className="ri-checkbox-blank-circle-fill link-success me-2" />
                            Videos
                        </div>
                        <div className="fs-14 fw-semibold">10GB</div>
                    </div>
                    <div className="d-flex justify-content-between w-100 height-50">
                        <div className="fs-12 fw-medium">
                            <i className="ri-checkbox-blank-circle-fill link-warning me-2" />
                            Audio
                        </div>
                        <div className="fs-14 fw-semibold">10GB</div>
                    </div>
                    <div className="d-flex justify-content-between w-100 height-50">
                        <div className="fs-12 fw-medium">
                            <i className="ri-checkbox-blank-circle-fill link-danger me-2" />
                            Documents
                        </div>
                        <div className="fs-14 fw-semibold">10GB</div>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="d-flex justify-content-between height-40">
                        <div className="fs-12 fw-medium">
                            <i className="me-4" />
                            Used Storage
                        </div>
                        <div className="fs-14 fw-semibold text-info">50GB</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="fs-12 fw-medium">
                            <i className="me-4" />
                            Remaining Storage
                        </div>
                        <div className="fs-14 fw-semibold text-info">78GB</div>
                    </div>
                </div>
            </div>
        );
    };

    const handleSideMenuChange = (ind) => {
        let params = { ...searchParams };
        delete params.groups;
        Object.entries(selected).forEach((item) => {
            if (item[1]) params.fileType = item[0];
        });
        if (ind === 0) getContents({ ...params });
        setOpenGroups(ind === 0 ? false : !openGroups);
        setChecked([]);
        setCheckedNames([]);
    };

    const handleGlobalSearch = (val) => {
        let params = {};
        if (val) {
            params = { ...searchParams, fileName: val?.trim() };
            setLoading(true);
            getContents(params);
        } else {
            params = { ...searchParams, fileName: undefined };
            setLoading(true);
            getContents(params);
        }
        setPageNumber(1);
        setSearchParams({ ...params });
    };

    const handleGroupFilter = (groups, groupObj) => {
        groups.forEach((group) => {
            if (group.code === groupObj.code) group.checked = !!group.checked;
            else if (group.children) group.children = handleGroupFilter(group.children, groupObj);
        });
        return groups;
    };

    const changeGroups = (groups, checkedData, groupObj) => {
        let groupArr = [...sideMenu?.find((menu) => menu.label === 'Groups')?.groups];
        let flatGroupArr = [...flatGroups];
        if (checkedData.length > 0) {
            let groupNames = [];
            let groupIds = [];
            let params;
            let flatGroup = [];
            flatGroupArr.forEach((group) => {
                if (checkedData.includes(group.code)) {
                    groupNames.push(group.name);
                    groupIds.push(group.code);
                    group.checked = true;
                } else group.checked = false;
                flatGroup.push(group);
            });
            flatGroupArr = [...flatGroup];
            groups.forEach((group) => {
                if (group.code === groupObj.code) group.checked = !!group.checked;
                else if (group.children) group.children = handleGroupFilter(group.children, groupObj);
            });
            groupArr = groups;
            setCheckedNames(groupNames);
            setChecked(groupIds);
            params = { ...searchParams, groups: groupIds?.toString() };
            Object.entries(selected).forEach((item) => {
                if (item[1]) params.fileType = item[0];
            });
            setSearchParams({ ...params });
            setPageNumber(1);
            getContents({ ...params });
        }
        let sideMenuItems = JSON.parse(JSON.stringify(sideMenu));
        sideMenuItems.forEach((side) => {
            if (side.label === 'Groups') side.groups = JSON.parse(JSON.stringify(groupArr));
        });
        setFlatGroups(flatGroupArr);
        setSideMenu(sideMenuItems);
    };

    const handleUploadClick = () => {
        setShowUploadModal(true);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete File?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deleteGroup" width={60} height={60} />
                </div>
                <div className="text-truncate" id="filename">
                    File Name: <span className="fw-semibold">{selectedRow?.original?.fileName}</span>
                </div>
                {Tooltip('filename', selectedRow?.original?.fileName)}
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb history={history} homeLink="Dashboard" pageTitle="Files" />
                    <Row className="min-height-body">
                        <Col xl={3} sm={3}>
                            <Card
                                className={
                                    activeTab === 'listView' ? 'card-height-100 overflow-y-scroll max-height-780' : 'card-height-102'
                                }
                            >
                                {sideMenu.map((item, ind) => {
                                    return item.label === 'Groups' ? (
                                        <div key={ind}>
                                            <div
                                                className={`d-flex align-items-center height-50  ${
                                                    checked?.length > 0 ? 'bg-light-green' : ''
                                                }`}
                                                data-bs-toggle="collapse"
                                            >
                                                <i
                                                    className={`${item.icon} fs-17 ms-3 ${
                                                        checked?.length > 0 ? 'text-success' : 'text-muted'
                                                    }`}
                                                />
                                                <span
                                                    className={`${
                                                        checked?.length > 0 ? 'text-success' : 'text-muted'
                                                    } fs-14 fw-medium ps-2 `}
                                                >
                                                    {item.label}
                                                </span>
                                            </div>
                                            <div className="overflow-auto margin-left-25">
                                                <div className="d-flex flex-column pt-0">
                                                    <div className="d-flex flex-column device-filter-panel">
                                                        {item.groups?.length > 0 ? (
                                                            <AnimatedTreeComponent
                                                                groups={item.groups}
                                                                flatGroups={flatGroups}
                                                                changeGroups={changeGroups}
                                                            />
                                                        ) : (
                                                            <div className="fs-13 d-flex align-items-center justify-content-center">
                                                                {'No Records Found.'}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`d-flex align-items-center height-50 cursor-pointer ${
                                                checked?.length === 0 ? 'bg-light-green' : ''
                                            }`}
                                            key={ind}
                                            onClick={() => handleSideMenuChange(ind)}
                                        >
                                            <i
                                                className={`${item.icon} fs-17 ms-3  ${
                                                    checked?.length === 0 ? 'text-success' : 'text-muted'
                                                }`}
                                            />
                                            <span
                                                className={`${
                                                    checked?.length === 0 ? 'text-success ' : 'text-muted '
                                                }fs-14 fw-medium ps-2 `}
                                            >
                                                {item.label}
                                            </span>
                                        </div>
                                    );
                                })}
                            </Card>
                            {/* <div className="position-absolute bottom-0 p-2 left-3 w-100">
                                    <Label className="fw-semibold fs-13 text-muted m-0 ps-1">STORAGE STATUS</Label>
                                    <div className="d-flex align-items-center ps-0 pt-0 pe-2 pb-2 w-100">
                                        <i className="ri-database-2-fill font-size-25" />
                                        <div className="ms-2 w-100 mt-1">
                                            <Progress
                                                color="primary"
                                                value={memory.usedInBytes || 0}
                                                // max={maxFileSizeInBytes || 100}
                                                max={1099511627776}
                                                className="progress-sm w-100"
                                            />
                                            <p className="mb-0 mt-1 fw-medium text-info">
                                                {memory.used || '0 GB'} used of 1 TB
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                        </Col>
                        <Col xl={9} sm={9}>
                            {activeTab === 'listView' ? (
                                <Card className="card-height-100 pe-6 ps-6">
                                    <Row className="mb-4 mt-4">
                                        <div className="d-flex justify-content-between">
                                            <Label className="fs-16 fw-semibold mb-0">
                                                <span>
                                                    {checkedNames?.length === 0 ? (
                                                        'File Catalogue'
                                                    ) : checkedNames?.length === 1 ? (
                                                        checkedNames[0]
                                                    ) : checkedNames?.length > 2 ? (
                                                        <div className="d-flex align-items-center">
                                                            {checkedNames[0] + ', ' + checkedNames[1]}
                                                            <div className="filter-choices-input ms-1" id="checkedNames">
                                                                <div className="choices" data-type="text">
                                                                    <div className="choices__inner min-height-1">
                                                                        <div className="choices__list choices__list--multiple">
                                                                            <div className="choices__item bg-primary border-white">
                                                                                + {checkedNames.length - 2} More
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {checkedNames?.length > 1 &&
                                                                Tooltip('checkedNames', checkedNames?.slice(2)?.join(', '))}
                                                        </div>
                                                    ) : (
                                                        checkedNames[0] + ', ' + checkedNames[1]
                                                    )}
                                                </span>
                                            </Label>
                                            {/* <span>
                                                <i
                                                    id="listView"
                                                    className={
                                                        'ri-list-unordered font-size-20 fw-semibold div-info cursor-pointer ' +
                                                        (activeTab === 'listView' ? ' link-secondary' : ' text-muted')
                                                    }
                                                    onClick={() => setActiveTab('listView')}
                                                />
                                                {Tooltip('listView', 'List View')}
                                                <i
                                                    id="graphView"
                                                    className={
                                                        'ri-pie-chart-2-fill font-size-20 fw-semibold ms-3 cursor-pointer' +
                                                        (activeTab === 'pieView' ? ' link-secondary' : ' text-muted')
                                                    }
                                                    onClick={() => setActiveTab('pieView')}
                                                />
                                                {Tooltip('graphView', 'Graph View')}
                                            </span> */}
                                        </div>
                                    </Row>
                                    <Row>
                                        {cards?.length > 0 &&
                                            cards.map((card, ind) => {
                                                return (
                                                    <Col key={ind}>
                                                        <Card
                                                            className={
                                                                'card-height-100 shadow-card border height-180 cursor-pointer' +
                                                                (selected[card.fileType] ? ' border-1 border-' + card.border : '')
                                                            }
                                                            onClick={() => handleCardClick(card)}
                                                        >
                                                            <CardBody>
                                                                {selected[card.fileType] && (
                                                                    <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                                                )}
                                                                <div className="h-100">
                                                                    <div className="d-flex h-100 align-items-center justify-content-center">
                                                                        <div>
                                                                            <div className="d-flex justify-content-center">
                                                                                <img
                                                                                    src={card.icon}
                                                                                    alt={card.alt}
                                                                                    width="60"
                                                                                    height="60"
                                                                                />
                                                                            </div>
                                                                            <div className="d-flex justify-content-center">
                                                                                <div className="fw-medium fs-14 mt-3">{card.label}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="position-absolute bottom-10 w-100 ps-3 pe-3">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="fs-12 text-secondary fw-medium">
                                                                                    {memoryByType?.[card.alt]?.size || '0 KB'}
                                                                                </div>
                                                                                <div className="fs-12 text-secondary fw-medium">
                                                                                    {memoryByType?.[card.alt]?.count || '0'} Files
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                );
                                            })}
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                    <TableContainer
                                        isGlobalFilter={true}
                                        /*
                                         * popUpButton={true}
                                         * popUpButtonLabel={'Upload'}
                                         * handlePopUp={handlePopUp}
                                         * tableHeader={'All Files'}
                                         */
                                        handleUpload={handleUpload}
                                        handleUploadClick={handleUploadClick}
                                        fileUploadButton={true}
                                        hideupload={checked?.length !== 1}
                                        hideDisplayTotalCount={true}
                                        columns={
                                            checked?.length > 1
                                                ? tableSchema?.filter((content) => content.Header !== 'Actions')
                                                : tableSchema
                                        }
                                        handleDelete={handleDelete}
                                        multipleUpload={true}
                                        handleClickView={handleClickView}
                                        handleAddNew={(row) => setSelectedRow(row)}
                                        handleShare={handleShare}
                                        acceptFiles={acceptFiles}
                                        data={contents}
                                        searchFilter2={true}
                                        handleGlobalSearch={handleGlobalSearch}
                                        pageNum={pageNumber}
                                        customPageSize={pageSize}
                                        onPageChange={onPageChange}
                                        totalRecords={totalRecords}
                                        hideHeaderPadding={true}
                                        searchPlaceHolder="File Name"
                                        editor={priviliges.FILE_EDITOR}
                                        reader={priviliges.FILE_READER}
                                        className="custom-header-css"
                                        divClass="table-responsive table-card"
                                        tableClass="table table-nowrap table-border table-centered align-middle"
                                        theadClass="bg-light text-muted"
                                    />
                                </Card>
                            ) : (
                                <div className="mb-4">
                                    <Card className="p-2 px-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Label className="fs-16 fw-semibold mb-0">My Storage</Label>
                                            <span>
                                                <i
                                                    id="listView"
                                                    className={
                                                        'ri-list-unordered font-size-20 fw-semibold div-info cursor-pointer ' +
                                                        (activeTab === 'listView' ? ' link-secondary' : ' text-muted')
                                                    }
                                                    onClick={() => setActiveTab('listView')}
                                                />
                                                {Tooltip('listView', 'List View')}
                                                <i
                                                    id="graphView"
                                                    className={
                                                        'ri-pie-chart-2-fill font-size-20 fw-semibold ms-3 cursor-pointer' +
                                                        (activeTab === 'pieView' ? ' link-secondary' : ' text-muted')
                                                    }
                                                    onClick={() => setActiveTab('pieView')}
                                                />
                                                {Tooltip('graphView', 'Graph View')}
                                            </span>
                                        </div>
                                    </Card>
                                    <Row>
                                        <Col>
                                            <Card className="p-4 heightPer-102">
                                                <Label className="fw-semibold fs-14">Storage Status</Label>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <UpdateDonut dataColors='["--EMM-primary", "--EMM-success", "--EMM-warning", "--EMM-danger", "--EMM-info"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="p-4 h-50">
                                                <Label className="fw-semibold fs-14">Most Used Storage Groups(Top 5)</Label>
                                                <div className="p-4">
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-gray-1 me-4" />
                                                            West Godavari
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">100GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-danger me-4" />
                                                            East Godavari
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">70GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-orange-1 me-4" />
                                                            Vizag
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">60GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-primary me-4" />
                                                            Eluru
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">44GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-success me-4" />
                                                            Nellore
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">20GB</div>
                                                    </div>
                                                </div>
                                            </Card>
                                            <Card className="p-4 h-50">
                                                <Label className="fw-semibold fs-14">Most Unused Storage Groups(Top 5)</Label>
                                                <div className="p-4">
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-gray-1 me-4" />
                                                            Anakapalli
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">9GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-danger me-4" />
                                                            Narasapuram
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">8GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-orange-1 me-4" />
                                                            Bheemavaram
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">4GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-primary me-4" />
                                                            Krishna
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">4GB</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center height-40">
                                                        <div className="fs-12 fw-medium">
                                                            <i className="ri-checkbox-blank-circle-fill link-success me-4" />
                                                            Nellore 2
                                                        </div>
                                                        <div className="fs-14 fw-semibold text-info">0GB</div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="p-3 heightPer-102">
                                                <Label className="fs-12 fw-semibold">Top Liked Files</Label>
                                                <Card className="mt-2 p-2">
                                                    <Row className="d-flex align-items-center">
                                                        <Col xl={4} xs={4} xxl={4} sm={4} md={4}>
                                                            <div className="d-flex align-items-center">
                                                                <img src={images} className="width-20 height-20 me-2" alt="images" />
                                                                <span className="fs-12 fw-medium text-list">School</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} xs={3} xxl={3} sm={3} ms={3}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-thumb-up-fill link-green-2" />
                                                                <span className="fs-12 fw-medium text-list">1000</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} xs={3} xxl={3} sm={3} ms={3}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-thumb-down-fill link-danger" />
                                                                <span className="fs-12 fw-medium text-list">400</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={2} xs={2} xxl={2} sm={2} ms={2}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-eye-fill link-info" />
                                                                <span className="fs-12 fw-medium text-list">1000</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card className="mt-2 p-2">
                                                    <Row className="d-flex align-items-center">
                                                        <Col xl={4} xs={4} xxl={4} sm={4} md={4}>
                                                            <div className="d-flex align-items-center">
                                                                <img src={images} className="width-20 height-20 me-2" alt="images" />
                                                                <span className="fs-12 fw-medium text-list">School</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} xs={3} xxl={3} sm={3} ms={3}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-thumb-up-fill link-green-2" />
                                                                <span className="fs-12 fw-medium text-list">1000</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} xs={3} xxl={3} sm={3} ms={3}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-thumb-down-fill link-danger" />
                                                                <span className="fs-12 fw-medium text-list">400</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={2} xs={2} xxl={2} sm={2} ms={2}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-eye-fill link-info" />
                                                                <span className="fs-12 fw-medium text-list">1000</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card className="mt-2 p-2">
                                                    <Row className="d-flex align-items-center">
                                                        <Col xl={4} xs={4} xxl={4} sm={4} md={4}>
                                                            <div className="d-flex align-items-center">
                                                                <img src={images} className="width-20 height-20 me-2" alt="images" />
                                                                <span className="fs-12 fw-medium text-list">School</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} xs={3} xxl={3} sm={3} ms={3}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-thumb-up-fill link-green-2" />
                                                                <span className="fs-12 fw-medium text-list">1000</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} xs={3} xxl={3} sm={3} ms={3}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-thumb-down-fill link-danger" />
                                                                <span className="fs-12 fw-medium text-list">400</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={2} xs={2} xxl={2} sm={2} ms={2}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="ri-eye-fill link-info" />
                                                                <span className="fs-12 fw-medium text-list">1000</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        deleteMessage={'Do you want to delete this file ?'}
                    />
                    <CommonModal
                        size={'md'}
                        hideFooter={true}
                        show={showUploadModal}
                        cancelText={'Cancel'}
                        saveText={'Save'}
                        disabled={loading}
                        modalheader={'Upload File'}
                        onCloseClick={() => setShowUploadModal(false)}
                        handleClick={() => setShowUploadModal(false)}
                        handleModalBody={() => handleModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ContentManagement;
