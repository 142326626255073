import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const TabsComponent = (props) => {
    const [activeTab, setActiveTab] = useState(props.defaultTabID);

    useEffect(() => {
        if (props.defaultTabID) setActiveTab(props.defaultTabID);
    }, [props.defaultTabID]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        props.toggleTab && props.toggleTab(tab);
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={12} className={props.tabsClassName}>
                    <Card className={props.nomuteBg ? '' : 'bg-mutes'}>
                        <CardBody className={`${props.noPadding ? 'p-0' : props.paddingRequired ? props.paddingClass : ''}`}>
                            <Nav tabs className="nav nav-tabs nav-border-bottom nav-border-bottom-success">
                                {props.tabsList.map((ele, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                to="#"
                                                className={
                                                    classNames('cursor-pointer', { active: activeTab === ele.tabID }) +
                                                    (activeTab === ele.tabID ? ' bg-white' : '')
                                                }
                                                onClick={() => toggleTab(ele.tabID)}
                                                disabled={ele.disabled}
                                            >
                                                <div className="d-flex align-items-center">
                                                    {ele.icon && <i className={`${ele.icon} me-2 ${activeTab === ele.tabID}`} />}
                                                    {ele.tabName}
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            {props.tabsList.map((ele, index) => {
                                return (
                                    <TabContent
                                        key={index}
                                        className={
                                            `${props.custombg ? props.custombg : 'bg-white px-4'}` +
                                            (props.mode === 'view' && !props.enableOnView ? 'pe-none opacity-50' : '') +
                                            (props.selectedTab === ele.tabID ? ' py-1' : '')
                                        }
                                        activeTab={activeTab}
                                    >
                                        <TabPane tabId={ele.tabID} id="nav-border-top-home">
                                            <Row>
                                                <Col>{ele.tabContent}</Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                );
                            })}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TabsComponent;
