import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const DeleteModal = ({
    show,
    size,
    onDeleteClick,
    onCloseClick,
    deleteMessage,
    hideIcon,
    confirmation,
    confirmText,
    customBody,
    deleteBody,
    disabled,
    image,
    cancelText,
    message,
    hideDeleteMessage,
    hideDelete,
    showAlert,
    hideAreYouSure,
    imgWidth,
    hideDeleteIcon,
    modalheader,
    showHeader,
    enablePadding,
    hideCenterAlign
}) => {
    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true} size={size ? size : 'md'}>
            {showHeader && (
                <ModalHeader className="border-bottom" toggle={onCloseClick}>
                    {modalheader && modalheader}
                </ModalHeader>
            )}
            <ModalBody>
                {customBody ? (
                    deleteBody
                ) : (
                    <div className={` ${hideCenterAlign ? '' : 'text-center'}`}>
                        {!hideIcon && (
                            <lord-icon
                                src={confirmation ? 'https://cdn.lordicon.com/kbtmbyzy.json' : 'https://cdn.lordicon.com/gsqxdxog.json'}
                                trigger="loop"
                                colors={confirmation ? 'primary:#121331,secondary:#08a88a' : 'primary:#f7b84b,secondary:#f06548'}
                                // eslint-disable-next-line no-inline-styles/no-inline-styles
                                style={{ width: '100px', height: '100px' }}
                            ></lord-icon>
                        )}
                        {showAlert && (
                            <lord-icon
                                src="https://cdn.lordicon.com/lfqzieho.json"
                                trigger="hover"
                                colors="primary:#e83a30"
                                // eslint-disable-next-line no-inline-styles/no-inline-styles
                                style={{ width: '100px', height: '100px' }}
                            ></lord-icon>
                        )}
                        {image && <img src={image} alt="delete_image" width={imgWidth ? imgWidth : 100} height={100} />}
                        {message && <div className="fs-15 mx-4 mx-sm-5">{message}</div>}
                        {!hideDeleteMessage && (
                            <div className="fs-15 mx-4 mx-sm-5">
                                {!hideAreYouSure && <h4>Are you sure ?</h4>}
                                <p className="text-muted mx-4 mb-0">
                                    {deleteMessage ? deleteMessage : 'Do you want to remove this record ?'}
                                </p>
                            </div>
                        )}
                    </div>
                )}
                <div className={'d-flex gap-2 justify-content-center mt-4 mb-2 ' + (enablePadding ? 'px-4 py-2' : '')}>
                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseClick}>
                        {cancelText ? cancelText : 'Close'}
                    </button>
                    {!hideDelete && (
                        <button
                            type="button"
                            className={`btn ${!hideDeleteIcon ? 'd-flex align-items-center' : ''} w-sm btn-${
                                confirmation ? 'success' : 'danger'
                            }`}
                            id="delete-record"
                            onClick={onDeleteClick}
                            disabled={disabled ? disabled : false}
                        >
                            {!hideDeleteIcon && <i className="ri-delete-bin-fill me-1" />}
                            {confirmation ? (confirmText ? confirmText : 'Yes') : confirmText ? confirmText : ' Yes, Delete It!'}
                        </button>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

DeleteModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    deleteMessage: PropTypes.string,
    show: PropTypes.any
};

export default DeleteModal;
