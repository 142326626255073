import { AUTH_USER, ENTERPRISE_ACCOUNT, ENV_VALS } from './actionType';

const INIT_STATE = { authUser: null, enterpriseAccount: null };

const Commons = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTH_USER:
            return { ...state, authUser: action.payload };
        case ENTERPRISE_ACCOUNT:
            return { ...state, enterpriseAccount: action.payload };
        case ENV_VALS:
            return { ...state, envVals: action.payload };
        default:
            return state;
    }
};

export default Commons;
