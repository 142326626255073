const tvPoliciesFieldsSchema = [
    {
        label: 'General Settings',
        icon: 'ri-settings-5-line',
        active: true,
        fields: [
            {
                label: 'Wallpaper',
                value: 'wallpaper',
                helpText: 'Select a wallpaper that has already been configured',
                inputType: 'select',
                optionsVal: 'wallpapers',
                optionLabel: 'label',
                optionValue: 'value',
                removable: true
            },
            /*
             * {
             *     label: 'Launcher Exit Password',
             *     value: 'launcherExitPassword',
             *     inputType: 'input',
             *     mandatoryTooltip: true,
             *     helpText: 'Enter password to exit Launcher  (in between 3 to 6 digits).'
             * },
             */
            {
                label: 'GeoFence',
                value: 'geofence',
                helpText: 'Select a Geofence that has been configured under Geofence in configurations tab',
                inputType: 'select',
                optionLabel: 'name',
                optionValue: 'config',
                optionsVal: 'geofenceConfigs',
                conditionalHide: true,
                hideOn: 'tenant',
                field: 'ENABLE_TV_GEOFENCE'
            },
            {
                label: 'Enable Volume',
                value: 'enableVolume',
                inputType: 'toggle',
                helpText: ''
            },
            {
                label: 'Volume Level',
                value: 'volumeLevel',
                inputType: 'slider',
                helpText: '',
                step: 1,
                max: 100,
                min: 0,
                conditionalHide: true,
                hideOn: 'value',
                field: 'enableVolume'
            }
            /*
             * {
             *     label: 'Location',
             *     value: 'enableLocation',
             *     helpText: 'Enabled location history',
             *     inputType: 'toggle'
             * },
             * {
             *     label: 'Remote Monitoring',
             *     value: 'enableRemote',
             *     helpText: 'Enabled remote monitoring',
             *     inputType: 'toggle'
             * }
             */
            /*
             * {
             *     label: 'Screamer',
             *     value: 'enableScreamer',
             *     helpText: 'Enabled screamer',
             *     inputType: 'toggle'
             * }
             */
        ]
    },
    {
        label: 'Applications',
        key: 'apps',
        icon: 'ri-apps-line',
        active: false
    }
];

export default tvPoliciesFieldsSchema;
