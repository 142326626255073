import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';

const Fallback = ({ retry }) => {
    const [seconds, setSeconds] = useState(60);
    const [isActive, setIsActive] = useState(false);
    let timerId;

    useEffect(() => {
        startCountdown();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startCountdown = () => {
        if (isActive) return;

        setIsActive(true);
        timerId = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds <= 1) {
                    setSeconds(60);
                    startCountdown();
                    clearInterval(timerId);
                    if (retry) retry();
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };

    return (
        <div className="error-screen">
            <Card className="p-5 shadow-card">
                <CardBody>
                    <div className="mb-3">
                        <svg width="80" height="80" viewBox="0 0 24 24">
                            <path
                                fill="#f7b84b"
                                d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zM12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1-15h-2v6h2zm0 8h-2v2h2z"
                            />
                        </svg>
                    </div>
                    <h1 className="something-went-wrong">Oops! Something went wrong.</h1>
                    <p className="check-connection">We couldn't load the data. Please check your connection and try again.</p>
                    <p>
                        Auto Retries in <b>{seconds}</b> seconds
                    </p>
                </CardBody>
            </Card>
        </div>
    );
};

export default Fallback;
