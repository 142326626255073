import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Components/Common/Loader';
import { Card, CardBody, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import ClearData from '../../../assets/images/DataLoss.png';
import polClone from '../../../assets/images/clonePolicy.png';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import DeleteModal from '../../../Components/Common/DeleteModal';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import { windowsPolicyTableSchema } from '../TableSchema';
import { TenantConfig, convertUTCtoIST } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import CommonModal from '../../../Components/Common/CommonModal';
import { windowsPoliciesArr } from '../../../Components/constants/commons';

const WindowsPolicies = () => {
    document.title = 'Windows Policy';
    const api = new APIClient();
    const urlconf = useEnv();
    const history = useHistory();
    const tenantConfig = TenantConfig();
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [policiesTableSchema, setPoliciesTableSchema] = useState([...windowsPolicyTableSchema]);
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [searchParams, setSearchParams] = useState({ page: 1, size: 100 });
    const [selectedRow, setSelectedRow] = useState('');
    const [policyType, setPolicyType] = useState('');
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [policyCards, setPolicyCards] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);

    useEffect(() => {
        getPolicies(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPolicies = (params) => {
        setLoading(true);
        api.get(url.POLICIES, params, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    resp.data.forEach((data) => {
                        data.modifiedDate = data.modifieddate ? convertUTCtoIST(data.modifieddate) : '—';
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setSearchVal(val);
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleAddNew = () => {
        setPolicyType('');
        let policyArray = windowsPoliciesArr;
        let configArr = [];
        policyArray.forEach((ele) => {
            if (tenantConfig?.WINDOWS_POLICY_TYPES?.join()?.includes(ele.id)) configArr.push(ele);
        });
        setPolicyCards(configArr);
        setShowPolicyModal(true);
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setSelectedRow('');
        api.delete(url.POLICIES + '/' + selectedRow._id, '', domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyDeleted);
                    setSearchVal('');
                    getPolicies(searchParams);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to Delete?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={ClearData} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.name}</span>
                </div>
            </div>
        );
    };

    const handleClone = (row) => {
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const handleCloneModalBody = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to clone this Policy?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polClone} alt="clonepolicy" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                </div>
            </div>
        );
    };

    const handleCloneConfirm = () => {
        setLoading(true);
        setShowCloneModal(false);
        api.create(url.POLICIES + '/' + selectedRow.original._id + '/clone', '', false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    getPolicies(searchParams);
                } else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handlePolicyTypeClick = (item) => {
        setPolicyType(item);
    };

    const handlePolicyModalBody = () => {
        return (
            <div>
                <Row className="align-items-center justify-content-evenly">
                    {policyCards?.length > 0 &&
                        policyCards.map((ele, index) => {
                            return (
                                <Card
                                    key={index}
                                    className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-250 height-250 cursor-pointer ${
                                        ele.id === policyType.id ? 'border-2 border-primary' : ''
                                    }`}
                                    onClick={() => handlePolicyTypeClick(ele)}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                        {ele.id === policyType.id && (
                                            <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                        )}
                                        <div>
                                            <img src={ele.img} alt="" height={60} width={60} />
                                        </div>
                                        <p className="text-uppercase fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                        <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                    </CardBody>
                                </Card>
                            );
                        })}
                </Row>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Windows Policies" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Policies'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchVal={searchVal}
                        searchPlaceHolder={'Policy Code or Name'}
                        columns={policiesTableSchema}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDelete={handleDelete}
                        handleClone={handleClone}
                        handleAddNew={handleAddNew}
                        handleClickView={(row) =>
                            history.push(
                                `/windowspolicies/view/${row.original._id}/${row.original?.policyType ? row.original?.policyType : 'FM'}`
                            )
                        }
                        handleEdit={(row) =>
                            history.push(
                                `/windowspolicies/edit/${row.original._id}/${row.original?.policyType ? row.original?.policyType : 'FM'}`
                            )
                        }
                        totalRecords={totalRecords}
                        data={policies}
                        editor={priviliges.WINDOWSPOLICY_EDITOR}
                        reader={priviliges.WINDOWSPOLICY_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <CommonModal
                        size={'lg'}
                        disabled={!policyType?.id || policyType?.id === ''}
                        cancelText={'Cancel'}
                        saveText={'Proceed'}
                        show={showPolicyModal}
                        modalheader={'Create New Policy'}
                        onCloseClick={() => {
                            setShowPolicyModal(false);
                            setPolicyType('');
                        }}
                        handleClick={() => {
                            history.push('/windowspolicies/add/' + policyType.id);
                            setShowPolicyModal(false);
                        }}
                        handleModalBody={() => handlePolicyModalBody()}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showCloneModal}
                        hideDeleteMessage={true}
                        message={handleCloneModalBody()}
                        onDeleteClick={handleCloneConfirm}
                        confirmText="Yes, Clone it"
                        hideDeleteIcon={true}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            setSelectedRow('');
                        }}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsPolicies;
