/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { callLogsSchema } from '../TableSchema';

const CallLogs = () => {
    document.title = 'Call Logs';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [callLogs, setCallLogs] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({ select: '*', page: 1, size: 20 });
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        serviceCall();
    }, []);

    const handleGlobalSearch = (val) => {
        let params = {};
        if (val) params = { ...searchParams, serial: val?.trim()?.toUpperCase()?.split(' ') };
        else params = { ...searchParams, serial: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        serviceCall({ ...params, page: 1, size: 10 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (params.fromDate && params.toDate) serviceCall({ ...params, page: 1, size: 10 });
        else if (val?.length === 0) serviceCall({ ...params, page: 1, size: 10 });
    };

    const serviceCall = (paramsObj) => {
        setLoading(true);
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { ...searchParams, page: pageNumber, size: pageSize };
        api.get(url.CALL_LOGS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    setTotalRecords(resp?.totalRecords);
                    resp.data?.forEach((ele) => {
                        ele.serial = ele.serial || '—';
                        ele.callType = ele.callType || '—';
                        ele.number2 = ele.number2 || '—';
                        ele.otherNumber = ele.otherNumber || '—';
                        ele.duration = ele.duration || '0s';
                        ele.badgeClass =
                            ele.callType === 'INCOMING'
                                ? 'light-blue'
                                : ele.callType === 'OUTGOING'
                                ? 'primary'
                                : ele.callType === 'MISSED'
                                ? 'secondary'
                                : ele.callType === 'REJECTED'
                                ? 'grey'
                                : 'white text-black';
                        ele.callDate = ele.callDate ? getDate(ele.callDate) : getDate(ele.createdDate);
                    });
                    setCallLogs(resp?.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const onPageChange = (pgNumber, pgSize) => {
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        serviceCall({ ...searchParams, page: pgNumber, size: pgSize });
    };

    const handleExportLogs = () => {
        setLoading(true);
        api.create(
            url.CALLLOGS_DOWNLOAD +
                (searchParams?.serial || searchParams?.fromDate || searchParams?.toDate ? '?' : '') +
                (searchParams?.serial ? 'serial=' + searchParams?.serial : '') +
                (searchParams?.serial && searchParams?.fromDate
                    ? '&fromDate=' + searchParams?.fromDate
                    : searchParams?.fromDate
                    ? 'fromDate=' + searchParams?.fromDate
                    : '') +
                ((searchParams?.serial || searchParams?.fromDate) && searchParams?.toDate
                    ? '&toDate=' + searchParams?.toDate
                    : searchParams?.toDate
                    ? 'toDate=' + searchParams?.toDate
                    : '')
        )
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = 'CallLog.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb pageTitle={'Call Logs'} history={history} homeLink="Dashboard" showBack={true} backLink="aereports" />
                    <TableContainer
                        loading={loading}
                        searchFilter2={true}
                        dateRangeFilter2={true}
                        datePlaceHolder={'Date Range'}
                        // searchFiltersButton={true}
                        exportLogs={true}
                        handleExportLogs={handleExportLogs}
                        searchPlaceHolder={'Serial'}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand logs only)."
                        tableHeader={'All Call Logs'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={callLogsSchema}
                        data={callLogs || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CallLogs;
