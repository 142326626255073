import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { NETWORKS } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import SIM_SETTINGS_SCHEMA from './SimSchema';
import { DynamicComponents, inputComponent } from './SimSettingsComponent';

const AddSimSettings = () => {
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'add' ? 'Add SIM Settings' : formType === 'edit' ? 'Edit SIM Settings' : 'View SIM Settings';

    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState('');

    useEffect(() => {
        if (recordID) {
            setLoading(true);
            getConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getConfig = () => {
        api.get(url.CONFIG + '/' + recordID)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    const config = resp.data?.config;
                    let obj = {
                        name: resp.data.name,
                        allowSim: !!config?.allowSim,
                        slot1: NETWORKS.find((slot1) => slot1?.value === config?.networkBinding?.slot1),
                        slot2: NETWORKS.find((slot2) => slot2?.value === config?.networkBinding?.slot2),
                        simBinding: config?.simBinding?.value,
                        deviceAction: config?.deviceAction?.enabled ? 'actionReq' : 'noAction',
                        /*
                         * action: config?.deviceAction?.action
                         *     ? [
                         *           { label: 'Block device on SIM removal', value: 'block_device_on_SIM_removal' },
                         *           { label: 'Block device on incorrect SIM', value: 'block_device_on_incorrect_SIM' },
                         *           {
                         *               label: 'Block device on SIM inserted in wrong slot',
                         *               value: 'block_device_on_SIM_inserted_in_wrong_slot'
                         *           }
                         *       ]?.filter((action) => config?.deviceAction?.action?.includes(action.value))
                         *     : [],
                         */
                        simPinEnabled: !!config?.simPin?.enabled,
                        simPin: config?.simPin?.applyOn,
                        pinGen: config?.simPin?.type,
                        pin: config?.simPin?.pin,
                        enableSimBinding: !!config.simBinding?.enabled,
                        enableNetworkBinding: !!config?.networkBinding?.enabled
                    };
                    setFormValues(obj);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: formValues?.name ? formValues?.name : '',
            allowSim: !!formValues?.allowSim,
            simBinding: formValues?.simBinding || 'first_detected',
            deviceAction: formValues?.deviceAction || 'noAction',
            simPin: formValues.simPin || 'sim1',
            pinGen: formValues.pinGen || 'random',
            // action: formValues.action,
            pin: formValues.pin || '',
            enableSimBinding: !!formValues?.enableSimBinding,
            enableNetworkBinding: !!formValues?.enableNetworkBinding,
            simPinEnabled: !!formValues?.simPinEnabled,
            slot1: formValues?.slot1 || { label: 'Any', value: 'any' },
            slot2: formValues?.slot2 || { label: 'Any', value: 'any' }
        },
        onSubmit: (values) => {
            let dataObj = {
                name: values?.name,
                configType: 'SIM',
                config: {
                    allowSim: !!validation.values.allowSim,
                    networkBinding: {
                        enabled: !!values.enableNetworkBinding,
                        slot1: validation.values.slot1?.value,
                        slot2: validation.values.slot2?.value
                    },
                    simBinding: { enabled: !!values.enableSimBinding, value: validation.values.simBinding },
                    deviceAction: {
                        enabled: validation.values.deviceAction !== 'noAction'
                        /*
                         * action:
                         *     validation.values.deviceAction === 'noAction'
                         *         ? undefined
                         *         : validation.values.action?.map((action) => action.value)
                         */
                    },
                    simPin: {
                        enabled: !!validation.values.simPinEnabled,
                        applyOn: validation.values.simPin,
                        pin:
                            validation.values.pinGen === 'random'
                                ? Math.floor(1000 + Math.random() * 9000)?.toString()
                                : validation.values.pin?.toString(),
                        lastPin: formValues.pin || '0000',
                        type: validation.values.pinGen
                    }
                }
            };
            createNdUpdate(dataObj);
        }
    });
    const createNdUpdate = (dataObj) => {
        setLoading(true);
        let apiService;
        if (formType === 'edit') apiService = api.update(url.CONFIG + '/' + recordID, dataObj);
        else apiService = api.create(url.CONFIG, dataObj);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.simsettingUpdated : toastMessages.simsettingsCreated);
                    history.push('/simsetting');
                    setLoading(false);
                }
            })
            .catch((err) => setLoading(false));
    };

    const handleNumberChnage = (e, field) => {
        let pattern = new RegExp(/^[0-9]+$/);
        let result = pattern.test(e.target.value);
        if (
            (result && (e.target.value?.toString()?.length ? e.target.value?.toString()?.length <= field.maxLength : true)) ||
            e.target.value === ''
        )
            validation.handleChange(e);
    };
    const handleFields = (field, index) => {
        return (
            <DynamicComponents
                key={index}
                field={field}
                validation={validation}
                index={index}
                formType={formType}
                handleNumberChnage={handleNumberChnage}
            />
        );
    };
    const configObj = {
        label: 'Configuration Name',
        value: 'name',
        mandatory: true,
        smallLabel: true,
        maxLength: 30,
        placeholder: 'Configuration Name'
    };

    const handleShowOn = (field) => {
        let flag = true;
        field.showOn?.forEach((showOn) => (flag = flag && !!validation.values[showOn]));
        flag = field.showKey ? flag && validation.values[field.showKey] === field.showVal : flag;
        return flag;
    };
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={
                            formType === 'view' ? 'View Sim Settings' : formType === 'edit' ? 'Edit Sim Settings' : 'Add Sim Settings'
                        }
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="simsetting"
                    />
                    <Card>
                        <CardHeader>
                            <Row>
                                <div className="d-flex align-items-center">
                                    <span className="fw-semibold fs-15 mb-1">SIM Settings</span>
                                </div>
                                <span className="fs-13">
                                    SIM Configuration enables users to define network preferences via Network binding. Additionally, SIM
                                    settings can be customized through SIM binding and SIM PIN lock.
                                </span>
                                <div className="mt-2">
                                    {inputComponent({ field: configObj, validation: validation, formType: formType })}
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className={`${!validation.values.name ? 'mask pe-none' : ''}`}>
                            <div className="mt-2">
                                {SIM_SETTINGS_SCHEMA.map((field, index) => {
                                    return (field.showOn ? handleShowOn(field) : true) && handleFields(field, index);
                                })}
                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex align-items-center justify-content-end">
                                <Row>
                                    <Col>
                                        <Button type="button" className="btn btn-light w-100" onClick={() => history.push('/simsetting')}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    {formType !== 'view' && (
                                        <Col>
                                            <Button
                                                size="md"
                                                type="button"
                                                className="d-flex align-items-center justify-content-center w-100"
                                                color={'primary'}
                                                disabled={
                                                    !validation.values.name ||
                                                    validation.values.name === '' ||
                                                    (validation.values.pinGen === 'custom' &&
                                                        (!validation.values.pin ||
                                                            validation.values.pin.toString().length !== 4 ||
                                                            validation.values.pin.toString() === '0000' ||
                                                            validation.values.pin.toString() === '1111'))
                                                }
                                                onClick={validation.handleSubmit}
                                            >
                                                {formType === 'edit' ? 'Update' : 'Save'}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddSimSettings;
