import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import delFirewall from '../../../assets/images/firewallDelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { firewallSchema } from '../TableSchema';

const Firewall = () => {
    document.title = 'Firewall';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();

    const [loading, setLoading] = useState(false);
    const [firewalls, setFirewalls] = useState([]);
    const [firewallsBackup, setFirewallsBackup] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedFirewall, setSelectedFirewall] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [urls, setUrls] = useState({ allowed: [], blocked: [] });
    const [searchParams, setSearchParams] = useState({ page: 1, size: 2000, configType: 'FIREWALL' });
    const [tableSchema, setTableSchema] = useState([]);

    useEffect(() => {
        setLoading(true);
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.CONFIGURATION_EDITOR);
        let schema = [...firewallSchema];
        if (findPriv) schema = firewallSchema.filter((item, index) => index < firewallSchema.length - 1);
        setTableSchema([...schema]);
        getFirewalls({ ...searchParams });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFirewalls = (params) => {
        api.get(url.CONFIG, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.name = data.name ? data.name : '—';
                        data.policiesApplied = data.policies?.length > 0 ? data.policies?.join(',') : '—';
                        data.doNotDelete = data.policies?.length > 0;
                        data.allowedUrl = data.config?.allowedDomains?.length ? data.config?.allowedDomains?.length : 0;
                        data.blockedUrl = data.config?.blockedDomains?.length ? data.config?.blockedDomains?.length : 0;
                        data.storageUrl = data.config?.storageUrl;
                        data.totalUrl =
                            Number(data.config?.allowedDomains?.length ? data.config?.allowedDomains?.length : 0) +
                            Number(data.config?.blockedDomains?.length ? data.config?.blockedDomains?.length : 0);
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                    });
                    setTotalRecords(resp.totalRecords);
                    setFirewalls(resp.data);
                    setFirewallsBackup(resp.data);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { name: '' },
        onSubmit: (values) => {
            setLoading(true);
            let obj = {
                name: values?.name,
                configType: 'FIREWALL',
                config: { allowedDomains: urls?.allowed, blockedDomains: urls?.blocked, defaultAction: values?.defaultAction }
            };
            if (selectedFirewall?.id) {
                obj.id = selectedFirewall?.id;
                obj.config = { ...obj.config, storageUrl: selectedFirewall?.storageUrl };
            }
            createNdUpdate(obj);
        }
    });

    const validation1 = useFormik({
        enableReinitialize: true,
        initialValues: { name: '' },
        onSubmit: (values) => {
            let obj = { name: values.name, configType: 'FIREWALL', config: selectedRow?.original?.config };
            setShowCloneModal(false);
            setSelectedRow('');
            setLoading(true);
            api.create(url.CONFIG, obj)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(toastMessages.firewallClone);
                        getFirewalls({ ...searchParams });
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    });

    const closeModal = () => {
        setDeleteModal(false);
        setShowCloneModal(false);
        setUrls({ allowed: [], blocked: [] });
        validation.setValues({ ...validation.values, name: '', urlBlocked: '', url: '' });
        validation1.setValues({ ...validation.values, name: '' });
    };

    const createNdUpdate = (dataObj) => {
        let apiService;
        if (selectedFirewall?.id) apiService = api.update(url.CONFIG + '/' + selectedFirewall?.id, dataObj);
        else apiService = api.create(url.CONFIG, dataObj);

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(selectedFirewall?.id ? toastMessages.firewallUpdated : toastMessages.firewallCreated);
                    getFirewalls({ ...searchParams });
                    closeModal();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleSearch = (val) => {
        let filteredFirewalls = [...firewallsBackup];
        if (val?.length > 0)
            filteredFirewalls = filteredFirewalls.filter((fence) => fence?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setTotalRecords(filteredFirewalls.length);
        setFirewalls(filteredFirewalls);
    };

    const handleAddNew = () => {
        history.push('/firewall/add');
    };

    const handleEdit = (firewall) => {
        history.push('/firewall/edit/' + firewall.original.id);
    };

    const handleClickView = (row) => {
        history.push('/firewall/view/' + row.original.id);
    };

    const handleDelete = (firewall) => {
        setSelectedFirewall(firewall.original);
        setDeleteModal(true);
    };

    const processData = (lines, type) => {
        let urlsArr = [];
        let linesArr = [];
        lines.forEach((line, ind) => {
            if (ind !== 0 && !linesArr.includes(line)) linesArr.push(line);
        });
        linesArr.forEach((line) => {
            if (line?.length > 0) {
                line = line?.trimEnd('/r');

                if (type === 'allowed' && !urls.blocked?.includes(line?.trimEnd('/r'))) urlsArr.push(line?.trimEnd('/r')?.trim());
                else if (type === 'blocked' && !urls.allowed?.includes(line?.trimEnd('/r'))) urlsArr.push(line?.trimEnd('/r')?.trim());
            }
        });
        setUrls({ ...urls, [type]: [...urls[type], ...urlsArr] });
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        api.delete(url.CONFIG + '/' + selectedFirewall?.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.firewallDeleted);
                    setSelectedFirewall('');
                    getFirewalls({ ...searchParams });
                    closeModal();
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete Firewall?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={delFirewall} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Firewall Name: <span className="fw-semibold">{selectedFirewall?.name}</span>
                </div>
            </div>
        );
    };

    const handleClone = (row) => {
        validation1.resetForm();
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const handleDownloadFirewall = (row) => {};

    const handleCloneModalBody = () => {
        return (
            <Form
                className="h-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation1.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Firewall Name
                            <span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={6} lg={6}>
                        <div className="input-group">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Firewall Name"
                                validate={{ required: { value: true } }}
                                onChange={validation1.handleChange}
                                onBlur={validation1.handleBlur}
                                value={validation1.values.name || ''}
                                invalid={validation1.touched.name && validation1.errors.name ? true : false}
                            />
                            {validation1.touched.name && validation1.errors.name ? (
                                <FormFeedback type="invalid">{validation1.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Firewall" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        data={firewalls}
                        searchFilter2={true}
                        addButton={true}
                        tableHeader={'All Firewalls'}
                        addButtonText="Add Firewall"
                        handleGlobalSearch={handleSearch}
                        isGlobalFilter={true}
                        searchPlaceHolder={'Name'}
                        customPageSize={10}
                        totalRecords={totalRecords}
                        handleAddNew={handleAddNew}
                        handleClone={handleClone}
                        handleDownloadFirewall={handleDownloadFirewall}
                        handleEdit={handleEdit}
                        handleClickView={handleClickView}
                        handleDelete={handleDelete}
                        columns={tableSchema}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        reader={priviliges.CONFIGURATION_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                    <CommonModal
                        size={'md'}
                        show={showCloneModal}
                        disabled={!validation1.values.name || loading}
                        modalheader={'Clone Firewall'}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            validation1.resetForm();
                        }}
                        handleClick={() => validation1.handleSubmit()}
                        handleModalBody={() => handleCloneModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Firewall;
