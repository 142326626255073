import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { teachersLogSchema } from '../TableSchema';

const TeachersLog = () => {
    document.title = 'Teacher Sessions';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [teachersLogs, setTeachersLogs] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });

    useEffect(() => {
        getTeacherLogs(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTeacherLogs = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.TEACHER_METRICS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.sessionStartDate = data.sessionStartDateTime ? getDate(data.sessionStartDateTime) : '—';
                        data.sessionEndDate = data.sessionEndDateTime ? getDate(data.sessionEndDateTime) : '—';
                        data.class = data.teachingClass;
                        data.subject = data.teachingSubject && data.teachingSubject !== null ? data.teachingSubject : '—';
                    });
                    setTeachersLogs(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onPageChange = (pgNum) => {
        setPageNumber(pgNum);
        getTeacherLogs({ ...searchParams, page: pgNum, size: 10 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setPageNumber(1);
        if (params.fromDate && params.toDate) getTeacherLogs({ ...params, page: 1, size: 10 });
        else if (val?.length === 0) getTeacherLogs({ ...params, page: 1, size: 10 });
    };

    const handleExportLogs = () => {
        setLoading(true);
        api.create(
            url.TEACHER_DOWNLOAD +
                (searchParams?.teacherCode || searchParams?.fromDate || searchParams?.toDate ? '?' : '') +
                (searchParams?.teacherCode ? 'teacherCode=' + searchParams?.teacherCode : '') +
                (searchParams?.teacherCode && searchParams?.fromDate
                    ? '&fromDate=' + searchParams?.fromDate
                    : searchParams?.fromDate
                    ? 'fromDate=' + searchParams?.fromDate
                    : '') +
                ((searchParams?.teacherCode || searchParams?.fromDate) && searchParams?.toDate
                    ? '&toDate=' + searchParams?.toDate
                    : searchParams?.toDate
                    ? 'toDate=' + searchParams?.toDate
                    : '')
        )
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = 'Teachers Session Report.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleGlobalSearch = (val) => {
        let params = { ...searchParams, page: 1, size: 10, teacherCode: val };
        setPageNumber(1);
        getTeacherLogs(params);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'Teacher Sessions'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="atvreports"
                    />
                    <TableContainer
                        loading={loading}
                        searchFilter2={true}
                        dateRangeFilter2={true}
                        datePlaceHolder={'Date Range'}
                        // searchFiltersButton={true}
                        handleExportLogs={handleExportLogs}
                        exportLogs={true}
                        searchPlaceHolder={'Teacher Code'}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand logs only)."
                        tableHeader={'All Teachers Sessions'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={teachersLogSchema}
                        data={teachersLogs || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TeachersLog;
