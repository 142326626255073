import { combineReducers } from 'redux';

import Layout from './layouts/reducer';
import TenantConfig from './TenantConfig/reducer';
import Commons from './Commons/reducer';
import Chromeconfig from './ChromeEnterprise/reducer';
import AuthModes from './AuthModes/reducer';

const rootReducer = combineReducers({
    Layout,
    TenantConfig,
    Commons,
    Chromeconfig,
    AuthModes
});

export default rootReducer;
