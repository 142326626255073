/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { configSchema } from '../TableSchema';

const AcerODMFeatures = () => {
    document.title = 'Acer ODM Features';
    let history = useHistory();
    let api = new APIClient();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let findPriv = !user?.data?.privileges?.includes(priviliges.CONFIGURATION_EDITOR);
    const config = useEnv();
    const [loading, setLoading] = useState(false);
    const [configs, setConfigs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableSchema, setTableSchema] = useState([]);
    const [selectedRow, setSelectedRow] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10, configType: 'ACERODMFEATURES' });
    const [dataBackup, setDataBackup] = useState([]);

    useEffect(() => {
        let schemaArr = JSON.parse(JSON.stringify(configSchema));
        if (findPriv) {
            schemaArr = schemaArr.filter((schema) => schema.Header !== 'Actions');
        }
        setTableSchema(schemaArr);
        getConfigs(searchParams);
    }, []);

    const getConfigs = (params) => {
        setLoading(true);
        api.get(url.CONFIG, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                resp.data.forEach((data) => {
                    data.doNotDelete = data.policies?.length > 0;
                    data.policiesApplied = data.policies?.length > 0 ? data.policies?.join(',') : '—';
                    data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                    data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                });
                setConfigs(resp.data);
                setDataBackup(resp.data);
                setTotalRecords(resp.totalRecords);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleSearch = (val) => {
        let filteredArr = [...dataBackup];
        if (val?.length > 0) filteredArr = filteredArr.filter((ele) => ele?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setTotalRecords(filteredArr.length);
        setConfigs(filteredArr);
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        api.delete(url.CONFIG + '/' + selectedRow?.id)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.acerODMFeatureDeleted);
                    setSelectedRow('');
                    getConfigs({ ...searchParams });
                    setDeleteModal(false);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete Acer ODM Feature?</div>
                <div>
                    Acer ODM Feature Name: <span className="fw-semibold">{selectedRow?.name}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Acer ODM Features" history={history} homeLink="Dashboard" />
                    <TableContainer
                        data={configs}
                        searchFilter2={true}
                        addButton={true}
                        tableHeader={'All Configs'}
                        addButtonText="Add New"
                        handleGlobalSearch={handleSearch}
                        isGlobalFilter={true}
                        handleEdit={(row) => history.push(`/acerodmfeatures/edit/${row.original.id}`)}
                        handleClickView={(row) => history.push(`/acerodmfeatures/view/${row.original.id}`)}
                        searchPlaceHolder={'Name'}
                        customPageSize={10}
                        totalRecords={totalRecords}
                        handleAddNew={() => history.push('/acerodmfeatures/add')}
                        handleDelete={handleDelete}
                        columns={tableSchema}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        reader={priviliges.CONFIGURATION_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AcerODMFeatures;
