import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate, profileTypeLabel } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { checkerSchema } from './TableSchema';

const CheckerPolicies = (props) => {
    document.title = 'Policy';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const [policiesTableSchema, setPoliciesTableSchema] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        if (props.type === 'checker') {
            localStorage.removeItem('policyNameForTokens');
            getPolicies();
            let userString = AuthUser();
            let user = userString ? JSON.parse(userString) : '';
            let findPriv = !user?.data?.privileges?.includes('CHECKER');
            let schema = [...checkerSchema];
            if (findPriv) schema = checkerSchema.filter((policy, index) => index < checkerSchema.length - 1);
            setPoliciesTableSchema([...schema]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    const getPolicies = (paramsObj) => {
        setLoading(true);
        props.setLoading(true);
        let pURL = url.POLICY_WORKFLOW;
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { page: pageNumber, size: pageSize };
        api.get(pURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((data) => {
                        data.hideQR = data.status === 'InActive';
                        data.hideToken = data.status === 'InActive';
                        data.doNotDelete = data.deviceCount > 0;
                        data.statusApplied =
                            data.policyStatus === 'REVIEW'
                                ? 'Pending for Approval'
                                : data.policyStatus === 'REJECTED'
                                ? 'Rejected'
                                : 'Approved';
                        data.badgeClass =
                            data.policyStatus === 'REVIEW' ? 'warning' : data.policyStatus === 'REJECTED' ? 'danger' : 'success';
                        data.hideTakeAction = data.policyStatus !== 'REVIEW';
                        data.policyVersion = data.version ? data.version : '—';
                        data.polType = profileTypeLabel(data.policyType?.toLowerCase());
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        data.createdDate = data.createdTime ? getDate(data.createdTime) : '—';
                        data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                        data.polType = profileTypeLabel(data?.policyType?.toUpperCase());
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                }
                setLoading(false);
                props.setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const onPageChange = (pgNumber, pgSize) => {
        let params = { page: pgNumber, size: pgSize };
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        getPolicies(params);
    };

    const handleClickView = (row) => {
        history.push('/aepolicies/checker/' + row.original.id + '/' + row.original.policyType);
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const handleTakeAction = (row) => {
        history.push('/aepolicies/takeAction/' + row.original.id + '/' + row.original.policyType);
    };

    return (
        <React.Fragment>
            <TableContainer
                loading={loading}
                isGlobalFilter={true}
                largeSearchFilter={true}
                SearchFilterWidth={'width-280'}
                // searchFilter2={true}
                searchPlaceHolder={'Policy Name'}
                columns={policiesTableSchema}
                handleGlobalSearch={handleGlobalSearch}
                handleTakeAction={handleTakeAction}
                handleClickView={handleClickView}
                onPageChange={onPageChange}
                totalRecords={totalRecords}
                data={policies}
                editor={priviliges.POLICY_EDITOR}
                reader={priviliges.POLICY_READER}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
        </React.Fragment>
    );
};

export default CheckerPolicies;
