export const firewallSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        customCell: true,
        fieldType: 'clickable'
    },
    { Header: 'Policies', accessor: 'policiesApplied', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    {
        Header: 'Allowed Domains',
        accessor: 'allowedUrl',
        sortable: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50
    },
    {
        Header: 'Blocked Domains',
        accessor: 'blockedUrl',
        sortable: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50
    },
    {
        Header: 'Total Domains',
        accessor: 'totalUrl',
        sortable: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50
    },
    /*
     * { Header: 'Policies', accessor: 'policies', sortable: true, width: 50, minWidth: 50, maxWidth: 50 },
     * { Header: 'Groups', accessor: 'groups', sortable: true, width: 50, minWidth: 50, maxWidth: 50 },
     */
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 100, minWidth: 100, maxWidth: 100 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        clone1: true,
        // urlDownload: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const geoFenceSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        // customCell: true,
        sortable: true,
        // fieldType: 'clickable',
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    { Header: 'Assign Groups', accessor: 'assingGroups', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Description', accessor: 'description', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Latitude', accessor: 'latitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Longitude', accessor: 'longitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        clone1: true,
        // urlDownload: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const SimSettingsSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        customCell: true,
        fieldType: 'clickable',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    { Header: 'Policies', accessor: 'policiesApplied', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        clone1: true,
        // urlDownload: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const certificatesTableSchema = [
    {
        Header: 'Alias',
        accessor: 'aliasName',
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    { Header: 'File Name', accessor: 'fileName', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Password', accessor: 'password', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Applied Policies', accessor: 'policiesApplied', sortable: false, width: 50, maxWidth: 50, minWidth: 50 },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this certificate, as it is applied to a policy',
        fade: 'doNotDelete',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const contactsSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Total Contacts',
        accessor: 'contactLength',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    { Header: 'Policies', accessor: 'policiesApplied', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const callConfigSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        customCell: true,
        fieldType: 'clickable',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    { Header: 'Policies', accessor: 'policiesApplied', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        clone: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const configSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true,
        customCell: true,
        fieldType: 'clickable'
    },
    { Header: 'Policies', accessor: 'policiesApplied', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const TIMEFENCE_SCHEMA = [
    { Header: 'Name', accessor: 'name', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
    { Header: 'Assigned Group', accessor: 'groups', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
    { Header: 'Modified By', accessor: 'modifiedBy', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
    { Header: 'Modified Date', accessor: 'modifiedDate', width: 100, maxWidth: 100, minWidth: 100, sortable: false },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration.',
        edit: true
    }
];
