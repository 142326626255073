/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { IsAuthorized, PrivCheck, getDate, sortBy } from '../../../Components/Common/Util';
import { tenantConfig } from '../../../Components/constants/TenantConfig';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
// import DeleteModal from '../../../Components/Common/DeleteModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { priviliges } from '../../../Components/constants/constants';
import { useEnv } from '../../../envContext';
import * as domains from '../../../helpers/domain_helper';
import { tenantSchema } from '../TableSchema';

const MasterTenantConfigs = () => {
    document.title = 'Tenant Master';
    const urlconf = useEnv();
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    let tectoroTenant = tenantConfig.TECTORO;
    const [configs, setConfigs] = useState([]);
    const [configsBackup, setConfigsBackup] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchVal, setSearchVal] = useState('');

    useEffect(() => {
        setLoading(true);
        getTenantConfigs({ page: 1, size: 100 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTenantConfigs = (params) => {
        api.get(url.TENANT_MASTER, params, domains.IDM).then((resp) => {
            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp?.data) {
                resp.data.forEach((ele) => {
                    ele.modifiedBy = ele.modifiedby ? ele.modifiedby : 'Tectoro';
                    ele.modifiedDate = ele.modifieddate ? getDate(ele.modifieddate) : '—';
                });
                resp.data = sortBy(resp.data, 'lastModifiedDate');
                setLoading(false);
                setConfigsBackup(resp.data);
                setTotalRecords(resp.totalRecords);
                setConfigs(resp.data);
            }
        });
    };

    const handleEditConfig = (config) => {
        history.push(`/tenantmaster/edit/${config.original._id}`);
    };

    const handleViewConfig = (config) => {
        history.push(`/tenantmaster/view/${config.original._id}`);
    };

    const handleAddConfiguration = () => {
        history.push('/tenantmaster/add');
    };

    const handleGlobalSearch = (val) => {
        let configArr = [...configsBackup];
        setSearchVal(val);
        if (val?.length > 0)
            configArr = configsBackup.filter(
                (config) =>
                    config?.code?.toLowerCase()?.includes(val?.toLowerCase()) || config?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setTotalRecords(configArr.length);
        setConfigs(configArr);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Tenant Master" history={history} homeLink="Dashboard" />
                    {configs?.length <= 8 && (!searchVal || searchVal === '') ? (
                        <>
                            <Row className="g-4 mb-3">
                                <div className="col-sm-3 ms-auto">
                                    <div className="d-flex justify-content-sm-end gap-2">
                                        <IsAuthorized
                                            privRequired={priviliges.TENANTMASTER_EDITOR}
                                            yes={() => (
                                                <Button type="button" color="primary" onClick={() => handleAddConfiguration()}>
                                                    + Add Tenant
                                                </Button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Row>

                            <div className="row">
                                {(configs || []).map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Col xxl={3} sm={6} className="project-card">
                                            <Card className="card-height-100 shadow border">
                                                <CardBody>
                                                    <div className="d-flex flex-column h-100">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex align-items-center mb-5">
                                                                    <div className="text-primary fw-medium">Last update : </div>
                                                                    <p className="text-muted m-0 ps-1">{item.modifiedDate}</p>
                                                                </div>
                                                            </div>
                                                            <PrivCheck
                                                                reader={priviliges.TENANTMASTER_READER}
                                                                editor={priviliges.TENANTMASTER_EDITOR}
                                                                yes={() => (
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-1 align-items-center">
                                                                            <UncontrolledDropdown direction="start">
                                                                                <DropdownToggle
                                                                                    tag="button"
                                                                                    className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                                                                >
                                                                                    <i className="ri-more-fill" />
                                                                                </DropdownToggle>

                                                                                <DropdownMenu className="dropdown-menu-end">
                                                                                    <IsAuthorized
                                                                                        privRequired={priviliges.TENANTMASTER_READER}
                                                                                        yes={() => (
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    handleViewConfig({ original: item })
                                                                                                }
                                                                                            >
                                                                                                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{' '}
                                                                                                View
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                    />
                                                                                    <IsAuthorized
                                                                                        privRequired={priviliges.TENANTMASTER_EDITOR}
                                                                                        yes={() => (
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    handleEditConfig({ original: item })
                                                                                                }
                                                                                            >
                                                                                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '}
                                                                                                Edit
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                    />
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar-sm">
                                                                    <span className={'avatar-title rounded p-2 bg-soft-success'}>
                                                                        <img
                                                                            alt=""
                                                                            src={tectoroTenant?.COMPANY_LOGO}
                                                                            className="img-fluid p-1"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="mb-1 fs-15">
                                                                    <div className="text-dark">{item.name}</div>
                                                                </h5>
                                                                <p className="text-muted text-truncate-two-lines mb-3">{item.code}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="text-primary fw-medium">Policy Types :</div>
                                                            {item?.config?.DEVICE_POLICY?.settings?.policyTypes?.length > 0 ? (
                                                                item?.config?.DEVICE_POLICY?.settings?.policyTypes?.map((ele, ind) => {
                                                                    return (
                                                                        <div className="flex-shrink-0 ps-1" key={ind}>
                                                                            <div className="fs-12 bg-soft-primary border px-2 py-1 rounded-2 m-0 text-primary">
                                                                                {ele}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            ) : (
                                                                <span className="ms-1">No policies found.</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                    ) : (
                        <TableContainer
                            loading={loading}
                            searchFilter2={true}
                            isGlobalFilter={true}
                            largeSearchFilter={true}
                            SearchFilterWidth={'width-320'}
                            addButton={true}
                            columns={tenantSchema}
                            handleGlobalSearch={handleGlobalSearch}
                            // handleDelete={handleDelete}
                            handleEdit={handleEditConfig}
                            handleAddNew={handleAddConfiguration}
                            handleClickView={handleViewConfig}
                            totalRecords={totalRecords}
                            addButtonText="Add Tenant"
                            data={configs}
                            editor={priviliges.TENANTMASTER_EDITOR}
                            reader={priviliges.TENANTMASTER_READER}
                            searchPlaceHolder="Tenant Code or Tenant Name"
                            tableHeader={'All Tenants'}
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="table table-nowrap table-border table-centered align-middle"
                            theadClass="bg-light text-muted"
                        />
                    )}
                    {/* <DeleteModal
                        show={showDeleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setShowDeleteModal(false)}
                        deleteMessage={'Are you sure you want to remove this Tenant Config ?'}
                    /> */}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MasterTenantConfigs;
