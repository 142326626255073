import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { callConfigSchema } from '../TableSchema';

const CallConfigurations = () => {
    document.title = 'Call Configuration';
    let history = useHistory();
    let api = new APIClient();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let findPriv = !user?.data?.privileges?.includes(priviliges.CONFIGURATION_EDITOR);
    const config = useEnv();
    const [loading, setLoading] = useState(false);
    const [callConfigs, setCallConfigs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableSchema, setTableSchema] = useState([]);
    const [selectedRow, setSelectedRow] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10, configType: 'CALLCONFIG' });
    const [dataBackup, setDataBackup] = useState([]);
    const [name, setName] = useState('');
    const [showCloneModal, setShowCloneModal] = useState(false);

    useEffect(() => {
        let schemaArr = JSON.parse(JSON.stringify(callConfigSchema));
        if (findPriv) {
            schemaArr = schemaArr.filter((schema) => schema.Header !== 'Actions');
        }
        setTableSchema(schemaArr);
        getCallConfigs(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCallConfigs = (params) => {
        setLoading(true);
        api.get(url.CONFIG, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                resp.data.forEach((data) => {
                    data.doNotDelete = data.policies?.length > 0;
                    data.policiesApplied = data.policies?.length > 0 ? data.policies?.join(',') : '—';
                    data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                    data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                });
                setCallConfigs(resp.data);
                setDataBackup(resp.data);
                setTotalRecords(resp.totalRecords);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleSearch = (val) => {
        let filteredArr = [...dataBackup];
        if (val?.length > 0) filteredArr = filteredArr.filter((ele) => ele?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setTotalRecords(filteredArr.length);
        setCallConfigs(filteredArr);
    };

    const handleClone = (row) => {
        setSelectedRow(row);
        setShowCloneModal(true);
        setName('');
    };

    const handleCloneConfirm = () => {
        setLoading(true);
        let obj = { name: name, configType: 'CALLCONFIG', config: selectedRow?.original?.config };
        api.create(url.CONFIG, obj)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.callConfigConfigClone);
                    getCallConfigs(searchParams);
                    setShowCloneModal(false);
                    setName('');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleCloneModalBody = () => {
        return (
            <Row>
                <Col xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Label className="form-label d-flex align-items-center fw-medium">
                        Call Setting Name
                        <span className="red-color ps-1">*</span>
                    </Label>
                </Col>
                <Col xs={12} sm={12} md={12} xl={8} lg={8}>
                    <div className="input-group">
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            validate={{ required: { value: true } }}
                            onChange={(e) => setName(e.target.value)}
                            value={name || ''}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleClickView = (row) => {
        history.push('/callconfig/view/' + row.original.id);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        api.delete(url.CONFIG + '/' + selectedRow?.id)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.callConfigDeleted);
                    setSelectedRow('');
                    getCallConfigs({ ...searchParams });
                    setDeleteModal(false);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete call config?</div>
                {/* <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={delFirewall} alt="deleteGroup" width={60} height={60} />
                </div> */}
                <div>
                    Call Config Name: <span className="fw-semibold">{selectedRow?.name}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Call Config" history={history} homeLink="Dashboard" />
                    <TableContainer
                        data={callConfigs}
                        searchFilter2={true}
                        addButton={true}
                        tableHeader={'All Configs'}
                        addButtonText="Add New"
                        handleGlobalSearch={handleSearch}
                        isGlobalFilter={true}
                        handleEdit={(row) => history.push(`/callconfig/edit/${row.original.id}`)}
                        searchPlaceHolder={'Name'}
                        customPageSize={10}
                        handleClone={handleClone}
                        totalRecords={totalRecords}
                        handleClickView={handleClickView}
                        handleAddNew={() => history.push('/callconfig/add')}
                        handleDelete={handleDelete}
                        columns={tableSchema}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        reader={priviliges.CONFIGURATION_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                    <CommonModal
                        size={'md'}
                        show={showCloneModal}
                        disabled={!name || loading}
                        modalheader={'Clone Call Setting'}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            setName('');
                        }}
                        handleClick={handleCloneConfirm}
                        handleModalBody={handleCloneModalBody}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CallConfigurations;
