/* eslint-disable max-lines */
/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent.js';
import BreadCrumb from '../../../Components/Common/BreadCrumb.js';
import CommonModal from '../../../Components/Common/CommonModal.js';
import DeleteModal from '../../../Components/Common/DeleteModal.js';
import { CustomDPCDeviceActions, commonActionsService } from '../../../Components/Common/DeviceUtils.js';
import Loader from '../../../Components/Common/Loader.js';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal.js';
import TableContainer from '../../../Components/Common/TableContainer.js';
import { Enterprise, TenantConfig, convertDate, getUniqueValues, noCaseSensitiveSortBy, uniqBy } from '../../../Components/Common/Util.js';
import ClearData from '../../../assets/images/DataLoss.png';
import toastMessages from '../../../common/messages/toastMessages.js';
import { APIClient } from '../../../helpers/api_helper.js';
import * as url from '../../../helpers/url_helper.js';
import AddPolicy from '../Policies/AddPolicy.js';
import {
    changeWidgetCountFunc,
    deviceStatusArray,
    disableBulkAction,
    dynamicClearAllFunction,
    generateFile,
    handleActionConfirmation,
    handleApplicationModalBody,
    handleBulkModalBodyPart,
    handleDeleteBody,
    handleDeviceGroupFilterResult,
    handleDevices,
    handleIsExport,
    handleModalBody,
    handleNotesModalBody,
    handlePGChangeBody,
    messageFunc,
    onlineStatusArray,
    prepareArray,
    selectedTabFunc,
    setCheckedGroup,
    setChildGroups,
    violationStatusArray
} from './AndroidEnterpriseDevicesFunc.js';
// import Filters from './AndroidEnterpriseDevicesFilters.js';
import Widgets from '../../../Components/Common/Widgets.js';
import { ACTION_TYPES, APP_STATUS, priviliges } from '../../../Components/constants/constants.js';
import ActiveDevices from '../../../assets/images/ActiveDevices.svg';
import DisabledDevices from '../../../assets/images/DisabledDevices.svg';
import NewDevices from '../../../assets/images/NewDevices.svg';
import ProvisioningDevices from '../../../assets/images/ProvisioningDevices.svg';
import TotalDevices from '../../../assets/images/TotalDevices.svg';
import DeletedDevices from '../../../assets/images/WipedDevices.svg';
import { useEnv } from '../../../envContext.js';
import * as domains from '../../../helpers/domain_helper.js';
import Filters from './AndroidEnterpriseDevicesFilters.js';

const AndroidEnterpriseDevices = () => {
    document.title = 'Devices';
    const urlconf = useEnv();
    let eObj = Enterprise();
    let history = useHistory();
    const api = new APIClient();
    const tenantConfig = TenantConfig();
    const enterpriseObj = eObj ? JSON.parse(eObj) : {};
    let tenantDevicesSchema = tenantConfig?.TABLE_COLUMNS?.map((columns) => columns.value);
    let emptySearchObj = {
        policiesArr: [],
        deviceStatusArr: [],
        violationStatusArr: [],
        selectedFiltersKeys: [],
        tagListArr: [],
        onlineStatusArr: [],
        applicationsArr: []
    };
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [retireModal, setRetireModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({
        select: { page: 1, size: 25 },
        filter: {
            search: null,
            dateFrom: null,
            dateTo: null,
            policy: null,
            group: null,
            deviceStatus: null,
            onlineStatus: null
        }
    });
    const [elasticSearchObj, setElasticSearchObj] = useState(emptySearchObj);
    const [WidgetCount, setWidgetCount] = useState({});
    const [resetLoader, setResetLoader] = useState(false);
    const [checkedData, setCheckedData] = useState([]);
    const [expandedData, setExpandedData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [devicesTableSchema, setDevicesTableSchema] = useState([]);
    const [notes, setNotes] = useState('');
    const [wipeDevice, setWipeDevice] = useState(true);
    const [wipeExternalStorage, setWipeExternalStorage] = useState(false);
    const [wipeReasonMessage, setWipeReasonMessage] = useState(false);
    const [wipeReasonMessageValue, setWipeReasonMessageValue] = useState('');
    const [refreshTime, setRefreshTime] = useState(0);
    const [checkAll, setCheckAll] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [bulkActionModal, setBulkActionModal] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [groups, setGroups] = useState([]);
    const [filterCriteriaOptions, setFilterCriterialOptions] = useState([]);
    const [hideAllFilters, setHideAllFilters] = useState(false);
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState({});
    const [policyPackageNames, setPolicyPackageNames] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [type, setType] = useState('');
    const [actionParam, setActionParam] = useState('');
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [exportAllLabel, setExportAllLabel] = useState('Export All');
    const [exportTooltip, setExportTooltip] = useState('Export Devices in CSV format (up to 5 Lakhs).');
    const [searchResults, setSearchResults] = useState([]);
    const [modalHeader, setModalHeader] = useState('');
    const [lostModeParam, setLostModeParam] = useState('START');
    const [policyData, setPolicyData] = useState('');
    const [policyType, setPolicyType] = useState('');
    const [lostModeMessage, setLostModeMessage] = useState('');
    const [lostModePhoneNumber, setLostModePhoneNumber] = useState('');
    const [lostModeEmail, setLostModeEmail] = useState('');
    const [appStatus, setAppStatus] = useState(APP_STATUS);
    const [actionTypes, setActionTypes] = useState(ACTION_TYPES);
    const [searchValue, setSearchValue] = useState('');
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [finalizedElasticSearchObj, setFinalizedElasticSearchObj] = useState(emptySearchObj);
    const [applications, setApplications] = useState([]);
    const [selectedApps, setSelectedApps] = useState([]);

    const paramsRef = useRef();
    paramsRef.current = { search: searchParams, pgNum: pageNumber, elastic: elasticSearchObj };
    const modalsRef = useRef();
    modalsRef.current = { bulkActionModal: bulkActionModal };

    const setActionTextFunc = (val) => {
        setActionText(val === 'STOP' ? 'Stop Lost Mode' : 'Start Lost Mode');
    };

    let messageFuncObj = {
        type: type,
        selectedDevice: selectedDevice,
        lostModeParam: lostModeParam,
        lostModeMessage: lostModeMessage,
        lostModePhoneNumber: lostModePhoneNumber,
        lostModeEmail: lostModeEmail,
        actionParam: actionParam,
        setActionParam: setActionParam,
        setLostModeMessage: setLostModeMessage,
        setLostModePhoneNumber: setLostModePhoneNumber,
        setLostModeEmail: setLostModeEmail,
        setLostModeParam: setLostModeParam,
        setActionTextFunc: setActionTextFunc
    };
    let deleteFuncObj = {
        selectedRow: selectedRow,
        wipeDevice: wipeDevice,
        setWipeDevice: setWipeDevice,
        wipeExternalStorage: wipeExternalStorage,
        setWipeExternalStorage: setWipeExternalStorage,
        wipeReasonMessage: wipeReasonMessage,
        setWipeReasonMessageValue: setWipeReasonMessageValue,
        wipeReasonMessageValue: wipeReasonMessageValue,
        setWipeReasonMessage: setWipeReasonMessage
    };
    let exportObj = {
        totalRecords: totalRecords,
        searchParams: searchParams,
        selectedDevices: selectedDevices,
        setLoading: setLoading
    };
    let devicesObj = {
        setWidgetCount: setWidgetCount,
        setSelectedDevices: setSelectedDevices,
        setCheckAll: setCheckAll,
        setDevices: setDevices,
        setTotalRecords: setTotalRecords
    };

    useEffect(() => {
        selectedTabFunc({ tenantConfig, setHideAllFilters, setDevicesTableSchema, tenantDevicesSchema, setCheckedData });
    }, []);

    useEffect(() => {
        if (elasticSearchObj?.deviceStatusArr && WidgetCount) {
            changeWidgetCountFunc({ elasticSearchObj, setElasticSearchObj, WidgetCount });
        }
    }, [WidgetCount]);

    const prepareGroups = async (tagArr) => {
        let groupsData = [];
        await tagArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        setFlatGroups(groupsData);
        tagArr = await noCaseSensitiveSortBy(tagArr, 'name');
        return groupsData;
    };

    const getDevices = (paramsObj) => {
        setLoading(true);
        let params = {
            aggs: {
                ONLINE_STATUS: { field: 'onlineStatus', type: 'BUCKETS' },
                deviceStatusCount: { field: 'deviceStatusApplied', type: 'BUCKETS' }
            }
        };
        if (paramsObj) params = { ...params, ...paramsObj };
        else
            params = {
                ...params,
                filter: { search: null, dateFrom: null, dateTo: null, policy: null, group: null, deviceStatus: null, onlineStatus: null },
                select: { page: pageNumber, size: pageSize }
            };
        setSearchParams(params);
        api.create(url.ES_DEVICES, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                handleDevices(resp.data, devicesObj);
                setLoading(false);
                setDeleteModal(false);
            })
            .catch((_err) => {
                setLoading(false);
                setDeleteModal(false);
            });
    };

    const handleChildCounts = async (children, metrics) => {
        const childArr = JSON.parse(JSON.stringify(children));
        await childArr.forEach(async (child) => {
            child.count = metrics[child.code];
            if (child.children?.length > 0) child.children = await handleChildCounts(child.children, metrics);
        });
        return childArr;
    };

    const handleGroupCounts = async (groupsArray, metrics) => {
        const groupsArr = JSON.parse(JSON.stringify(groupsArray));
        await groupsArr.forEach(async (group) => {
            group.count = metrics[group.code];
            if (group.children?.length > 0) group.children = await handleChildCounts(group.children, metrics);
        });
        return groupsArr;
    };

    const getGroupMetrics = async (codes) => {
        let groupCodes = [];
        await api
            .create(url.ES_DEVICES, {
                aggs: { GROUP: { field: 'group', type: 'BUCKETS' } },
                filter: { group: codes }
            })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) groupCodes = resp.data.aggs.GROUP;
            })
            .catch((err) => {
                return groupCodes;
            });
        return groupCodes;
    };

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = (paramsObj) => {
        clearTimeout();
        if (window.location.hash !== '#/aedevices' && !!tenantConfig?.AUTO_REFRESH) return;
        const filterPanelPromise = new Promise((resolve, reject) => {
            let aggs = {
                POLICY: { field: 'policyApplied', type: 'BUCKETS' },
                ONLINE_STATUS: { field: 'onlineStatus', type: 'BUCKETS' },
                GROUP: { field: 'group', type: 'BUCKETS' },
                deviceStatusCount: { field: 'deviceStatusApplied', type: 'BUCKETS' }
            };
            api.create(url.ES_DEVICES, {
                aggs: aggs,
                filter: {
                    search: paramsObj?.search?.filter?.search ? paramsObj?.search?.filter?.search : null,
                    dateFrom: paramsObj?.search?.filter?.dateFrom ? paramsObj?.search?.filter?.dateFrom : null,
                    dateTo: paramsObj?.search?.filter?.dateTo ? paramsObj?.search?.filter?.dateTo : null,
                    policy: paramsObj?.search?.filter?.policy ? paramsObj?.search?.filter?.policy : null,
                    group: paramsObj?.search?.filter?.group ? paramsObj?.search?.filter?.group : null,
                    deviceStatus: paramsObj?.search?.filter?.deviceStatus ? paramsObj?.search?.filter?.deviceStatus : null,
                    onlineStatus: paramsObj?.search?.filter?.onlineStatus ? paramsObj?.search?.filter?.onlineStatus : null,
                    apps: paramsObj?.search?.filter?.apps ? paramsObj?.search?.filter?.apps : null,
                    appStatus: paramsObj?.search?.filter?.appStatus ? paramsObj?.search?.filter?.appStatus : null
                },
                select: { page: paramsObj?.pgNum ? paramsObj?.pgNum : 1, size: pageSize }
            })
                .then(async (resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Filter panel data failed');
                })
                .catch((_err) => {
                    reject('Filter panel data failed');
                });
        });

        const policyPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 2000 };
            if (tenantConfig.DEVICE_POLICY)
                api.get(url.POLICIES, params)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy Failed');
                    })
                    .catch((_err) => reject('Policy Failed'));
            else reject('Policy not enabled');
        });

        const groupPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100000 };
            if (tenantConfig.DEVICE_GROUPS)
                api.get(url.DEVICE_GROUPS, params, domains.IDM)
                    .then(async (resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Groups Failed');
                    })
                    .catch((_err) => {
                        reject('Groups Failed');
                    });
            else reject('Groups not enabled');
        });

        const applicationPolicy = new Promise((resolve, reject) => {
            if (tenantConfig.APPS)
                api.get(url.APPLICATIONS, { enterpriseId: enterpriseObj.id, page: 1, size: 2000 })
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Applications Failed');
                    })
                    .catch((_err) => reject('Applications Failed'));
            else reject('Apps not enabled');
        });

        Promise.allSettled([policyPromise, groupPromise, applicationPolicy, filterPanelPromise])
            .then(async (result) => {
                let arr = [];
                let optionsArr = [];
                let packages = [];
                if (result?.[0]?.value?.length > 0) {
                    result?.[0]?.value?.forEach((ele) => {
                        arr.push({
                            label: ele.code,
                            name: ele.name,
                            count: 0,
                            checked: paramsObj?.search?.filter?.policies?.includes(ele.code)
                        });
                        if (ele.status?.toLowerCase() === 'active')
                            optionsArr.push({
                                label: ele.name ? ele.name : ele.code,
                                nameCode: ele.name ? ele.name + (ele.code ? ' - ' + ele.code : '') : ele.code,
                                value: ele.code,
                                count: ele.deviceCount
                            });
                        let apps = ele.policy.applications;
                        if (apps?.length > 0) packages = [...packages, ...apps];
                    });
                }
                packages = uniqBy(packages, 'packageName');
                setPolicyPackageNames(packages);
                optionsArr = noCaseSensitiveSortBy(optionsArr, 'label');
                setPolicies(optionsArr);
                let osArr = JSON.parse(JSON.stringify(onlineStatusArray));
                osArr.forEach(
                    (status) =>
                        (status.checked = !!paramsObj?.elastic?.onlineStatusArr?.find((search) => search.label === status.label)?.checked)
                );
                let groupArr = [];
                if (result?.[1]?.status === 'fulfilled') {
                    let groupsData = await prepareGroups(result[1]?.value);
                    const groupCodes = groupsData.map((group) => group.code);
                    const groupMetrics = await getGroupMetrics(groupCodes);
                    const groupsArr = JSON.parse(JSON.stringify(result[1].value));
                    groupArr = await handleGroupCounts(groupsArr, groupMetrics);

                    setGroups([...groupArr]);
                }
                let dsArr = JSON.parse(JSON.stringify(deviceStatusArray));
                dsArr.forEach(
                    (status) =>
                        (status.checked = !!paramsObj?.elastic?.deviceStatusArr?.find((search) => search.label === status.label)?.checked)
                );
                if (result?.[3]?.status === 'fulfilled')
                    for (let [key, value] of Object.entries(result?.[3]?.value?.aggs?.deviceStatusCount)) {
                        dsArr = await prepareArray(dsArr, key, value);
                    }
                if (result?.[3]?.value?.aggs?.ONLINE_STATUS) {
                    for (let [key, value] of Object.entries(result?.[3]?.value?.aggs?.ONLINE_STATUS)) {
                        osArr = prepareArray(osArr, key, value, true);
                    }
                }
                let violationArr = [...violationStatusArray];
                if (result?.[3]?.value?.aggs?.unauthorizedApps?.Y > 0) {
                    violationArr.forEach((violation) => {
                        if (violation.value === 'apps') violation.count = result[3]?.value?.aggs?.unauthorizedApps?.Y;
                    });
                }
                if (result?.[2]?.status === 'fulfilled') {
                    result?.[2]?.value?.forEach((app) => {
                        app.checked = paramsObj?.search?.filter?.apps?.includes(app.packageName);
                    });
                    setApplications(result?.[2]?.value);
                }
                setElasticSearchObj(
                    paramsObj?.elastic
                        ? paramsObj?.elastic
                        : {
                              deviceStatusArr: dsArr,
                              policiesArr: noCaseSensitiveSortBy(optionsArr, 'name'),
                              selectedFiltersKeys: [],
                              tagListArr: groupArr,
                              tagListBackup: groupArr,
                              violationStatusArr: violationArr,
                              onlineStatusArr: osArr,
                              applicationsArr: noCaseSensitiveSortBy(result?.[2]?.value || [], 'title')
                          }
                );
                setFinalizedElasticSearchObj(
                    paramsObj?.elastic
                        ? paramsObj?.elastic
                        : {
                              deviceStatusArr: dsArr,
                              policiesArr: noCaseSensitiveSortBy(optionsArr, 'name'),
                              selectedFiltersKeys: [],
                              tagListArr: result[1]?.status === 'fulfilled' ? result[1]?.value : [],
                              tagListBackup: result[1]?.status === 'fulfilled' ? result[1]?.value : [],
                              violationStatusArr: violationArr,
                              onlineStatusArr: osArr,
                              applicationsArr: noCaseSensitiveSortBy(result?.[2]?.value || [], 'title')
                          }
                );
                let devicesResult = {
                    aggs: { deviceStatusCount: result[3]?.value?.aggs?.deviceStatusCount },
                    records: result[3]?.value?.records,
                    totalCount: result[3]?.value?.totalCount
                };
                handleDevices(devicesResult, devicesObj);
                setLoading(false);
                setDeleteModal(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
        if (window.location.hash === '#/aedevices' && !!tenantConfig?.AUTO_REFRESH) {
            if (modalsRef.current.bulkActionModal) setBulkActionModal(false);
            setTimeout(() => handlePromise(paramsRef.current), 30000);
        }
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setSearchValue('');
        setLoading(true);
        let params = {};
        if (Number(selectedRow.original?.androidVersion) < 7) {
            CustomDPCDeviceActions({ setLoading: setLoading, id: selectedRow.original.id, type: 'WIPE', urlconf: urlconf });
            return;
        }
        params.wipeExternalStorage = wipeExternalStorage;
        if (wipeReasonMessage) params.wipeReasonMessage = wipeReasonMessageValue;
        api.create(url.DEVICES + '/' + selectedRow.original.id, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(wipeExternalStorage ? toastMessages.factoryResetWithMemorWipe : toastMessages.factoryReset);
                    setPageNumber(searchParams.select.page);
                    let searchObj = { ...searchParams, filter: { ...searchParams.filter, search: undefined } };
                    getDevices({ ...searchObj });
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleDeviceReboot = (row) => {
        setSelectedDevice(row);
        setType('reboot');
        setActionText('Yes, Reboot it!');
        setConfirmationModal(true);
    };

    const handleDeviceLock = (row) => {
        setSelectedDevice(row);
        setType('lock');
        setActionText('Yes, Lock it!');
        setConfirmationModal(true);
    };

    const handleResetPassword = (row) => {
        setShowModal(true);
        setSelectedRow(row);
    };
    const handleRetire = (row) => {
        setSelectedRow(row);
        setRetireModal(true);
    };
    const handleFactoryReset = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleNotes = (row) => {
        setShowNotesModal(true);
        setSelectedRow(row);
        setNotes(row.original.notes);
    };

    const confirmRetire = () => {
        setLoading(false);
        api.update(url.DEVICES + '/' + selectedRow.original.id, { updateType: 'STATE', state: 'RETIRE' })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.deviceRetired);
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => {
                setSelectedRow('');
                setRetireModal(false);
            });
    };

    const handleGlobalSearch = (val) => {
        setSearchValue(val);
        if (val?.trim()?.length < 3 && val?.trim()?.length > 0) return;
        let params = {};
        if (val) {
            params = {
                ...searchParams,
                select: { page: 1, size: pageSize },
                filter: { ...searchParams.filter, search: val?.trim()?.toUpperCase() }
            };
            setLoading(true);
            getDevices(params);
        } else {
            params = {
                ...searchParams,
                select: { page: 1, size: pageSize },
                filter: { ...searchParams.filter, search: null }
            };
            setLoading(true);
            getDevices(params);
        }
        if (
            params.filter?.appStatus?.length > 0 ||
            params.filter?.apps?.length > 0 ||
            params.filter?.deviceStatus?.length > 0 ||
            params.filter?.group?.length > 0 ||
            params.filter?.onlineStatus?.length > 0 ||
            params.filter?.policy?.length > 0 ||
            params.filter?.search?.length > 0 ||
            params.filter?.dateFrom?.length > 0 ||
            params.filter?.dateTo?.length > 0 ||
            selectedDevices?.length > 0
        ) {
            setExportAllLabel('Export');
            setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_ANDROID_DEVICE)}).`);
        } else {
            setExportAllLabel('Export All');
            setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
        }
        setPageNumber(1);
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) {
            params = {
                ...searchParams,
                select: { page: pageNumber, size: pageSize },
                filter: {
                    ...searchParams.filter,
                    dateFrom: convertDate(val?.[0], '-'),
                    dateTo: convertDate(val?.[1], '-')
                }
            };
            setLoading(true);
            getDevices(params);
        } else {
            params = {
                ...searchParams,
                select: { page: pageNumber, size: pageSize },
                filter: { ...searchParams.filter, dateFrom: null, dateTo: null }
            };
            setLoading(true);
            getDevices(params);
        }
        if (
            params.filter?.appStatus?.length > 0 ||
            params.filter?.apps?.length > 0 ||
            params.filter?.deviceStatus?.length > 0 ||
            params.filter?.group?.length > 0 ||
            params.filter?.onlineStatus?.length > 0 ||
            params.filter?.policy?.length > 0 ||
            params.filter?.search?.length > 0 ||
            params.filter?.dateFrom?.length > 0 ||
            params.filter?.dateTo?.length > 0
        ) {
            setExportAllLabel('Export');
            setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_ANDROID_DEVICE)}).`);
        } else {
            setExportAllLabel('Export All');
            setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
        }
        setPageNumber(1);
        setSearchParams({ ...params });
    };

    const handleUpload = (file) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('id', enterpriseObj.id);
        setLoading(true);
        api.create(url.DEVICES_UPLOAD, formData, true)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.deviceUploaded);
                    getDevices();
                    setLoading(false);
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const onPageChange = (pgNumber, pgSize) => {
        let params = { ...searchParams, select: { page: pgNumber, size: pgSize } };
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        window.scrollTo(0, 0);
        getDevices(params);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { newPassword: '', default_password: 'true' },
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Please Enter Your New Password')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (obj) => {
        if (offcanvasModal) {
            commonActionsService({
                deviceID: selectedRow?.original?.id,
                type: 'resetpassword',
                values: obj,
                setLoading: setResetLoader,
                setShowModal: setShowModal,
                urlconf: urlconf
            });
            validation.resetForm();
        } else {
            dynamicFiltersServiceCall(checkedData, appStatus);
        }
    };

    const validation1 = useFormik({
        enableReinitialize: true,
        initialValues: { notes: notes && notes !== '' ? notes : '' },
        validationSchema: Yup.object({ notes: Yup.string().required('Please Enter Your Notes') }),
        onSubmit: (values) => {
            setLoading(true);
            setShowNotesModal(false);
            let params = { updateType: 'NOTES', notes: values.notes?.trim() };
            api.update(url.DEVICES + '/' + selectedRow?.original?.id, params)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(notes && notes !== '' ? toastMessages.notesUpdated : toastMessages.notesAdded);
                        setNotes(resp.data.notes);
                        setLoading(false);
                        validation1.resetForm();
                        getDevices({ ...searchParams });
                    }
                })
                .catch((_err) => {
                    setLoading(false);
                });
        }
    });

    const bulkAction = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {
            setLoading(true);
            let dataObj = {};
            if (selectedDevices?.length > 0) {
                let selectedSerials = selectedDevices.map((device) => device.serial);
                dataObj.serial = selectedSerials?.join(',');
                dataObj.action = values?.actionType?.value;
                dataObj.purpose = values?.purpose;
                if (values?.actionType?.value === 'RESET_PASSWORD') {
                    if (validation.values['default_password'] !== 'true' && !validation.values.newPassword) {
                        toast.error(toastMessages.providePassword);
                        return;
                    }
                    dataObj.params = JSON.stringify({
                        newPassword:
                            validation.values['default_password'] === 'true'
                                ? tenantConfig?.DEVICE_DEFAULT_PASSWORD
                                : validation.values.newPassword
                    });
                } else if (values?.actionType?.value === 'RESET') {
                    dataObj.changeType = { wipeExternalStorage: wipeExternalStorage };
                    if (wipeReasonMessage) dataObj.changeType.wipeReasonMessage = wipeReasonMessageValue;
                } else if (values?.actionType?.value === 'NOTES') {
                    if (!validation1.values.notes || validation1.values.notes?.trim() === '') {
                        toast.error(toastMessages.provideNotes);
                        return;
                    }
                    dataObj.changeType = validation1.values?.notes?.trim();
                } else if (values?.actionType?.value === 'NEWPOLICY') {
                    if (!values.policy?.value) {
                        toast.error(toastMessages.providePolicy);
                        return;
                    }
                    dataObj.changeType = values.policy?.value;
                } else if (values?.actionType?.value === 'NEWGROUP') {
                    if (!values.group.value) {
                        toast.error(toastMessages.provideGroup);
                        return;
                    }
                    dataObj.changeType = values.group?.value;
                } else if (values?.actionType?.value === 'CLEAR_CACHE') {
                    dataObj.params = selectedApps?.packageName;
                } else {
                    delete dataObj.params;
                    delete dataObj.changeType;
                }
            }
            let formData = new FormData();
            formData.append('serials', dataObj.serial);
            formData.append('action', dataObj.action);
            formData.append('purpose', dataObj.purpose);
            formData.append('type', 'MOBILE');
            if (dataObj.params) formData.append('params', dataObj.params);
            if (dataObj.changeType) formData.append('changeType', dataObj.changeType);
            api.create(url.BULK_ACTION, formData, true)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        setBulkActionModal(false);
                        bulkAction.resetForm();
                        let message = '';
                        switch (dataObj.action) {
                            case 'NOTES':
                                message = 'update notes on';
                                break;
                            case 'REBOOT':
                                message = 'reboot';
                                break;
                            case 'RETIRE':
                                message = 'retire';
                                break;
                            case 'LOCK':
                                message = 'lock';
                                break;
                            case 'RESET_PASSWORD':
                                message = 'reset password on';
                                break;
                            case 'SCREAM':
                                message = 'scream on';
                                break;
                            case 'RESET':
                                message = 'factory reset';
                                break;
                            case 'NEWPOLICY':
                                message = 'change policy on';
                                break;
                            case 'NEWGROUP':
                                message = 'change group on';
                                break;
                            default:
                                break;
                        }
                        toast.success('Request raised to ' + message + ' selected devices.');
                        let rows = [...devices];
                        rows.forEach((row) => delete row.checked);
                        setSelectedDevices([]);
                        setCheckAll(false);
                        setDevices(rows);
                        setLoading(false);
                        setWipeReasonMessageValue('');
                        setWipeExternalStorage(false);
                        setWipeReasonMessage(false);
                        const dataArr = ['RETIRE', 'NEWPOLICY', 'NEWGROUP', 'NOTES', 'REBOOT'];
                        if (dataArr.includes(dataObj.action)) {
                            getDevices({ ...searchParams });
                        }
                    }
                })
                .catch((_err) => {
                    setLoading(false);
                });
        }
    });

    const handlePolicyFilter = (e, item) => {
        dynamicCheckFunction(e, item, 'policiesArr', 'Policies');
    };

    const handleDeviceStatusFilter = (e, item) => {
        dynamicCheckFunction(e, item, 'deviceStatusArr', 'Device Status');
    };

    const handleViolationSatusFilter = (e, item) => {
        dynamicCheckFunction(e, item, 'violationStatusArr', 'Violation Status');
    };

    const handleOnlineStatusFilter = (e, item) => {
        dynamicCheckFunction(e, item, 'onlineStatusArr', 'Online Status');
    };

    const handleAppStatusFilter = (e, item) => {
        setPageNumber(1);
        let filterOptions = uniqBy([...filterCriteriaOptions, { label: 'App Status', value: 'applicationsArr' }], 'packageName');
        setFilterCriterialOptions(filterOptions);
        let arr = [];
        if (elasticSearchObj['applicationsArr']?.length > 0) {
            elasticSearchObj['applicationsArr'].forEach((ele) => {
                if (ele.packageName === item.packageName) ele.checked = e.target.checked;
            });
            arr.push({ label: 'App Status' });
            arr = [...elasticSearchObj.selectedFiltersKeys, ...arr];
            setElasticSearchObj({
                ...elasticSearchObj,
                ...elasticSearchObj['applicationsArr'],
                applicationsArr: elasticSearchObj['applicationsArr'],
                selectedFiltersKeys:
                    elasticSearchObj['applicationsArr'].filter((ele) => ele.checked)?.length === 0
                        ? uniqBy(arr, 'label').filter((ele) => ele.label !== 'App Status')
                        : uniqBy(arr, 'label')
            });
        }
    };

    const dynamicCheckFunction = (e, item, key, selLabel) => {
        setPageNumber(1);
        let filterOptions = uniqBy([...filterCriteriaOptions, { label: selLabel, value: key }], 'label');
        setFilterCriterialOptions(filterOptions);
        let arr = [];
        if (elasticSearchObj[key]?.length > 0) {
            elasticSearchObj[key].forEach((ele) => {
                if (ele.value === item.value) ele.checked = e.target.checked;
            });
            arr.push({ label: selLabel });
            arr = [...elasticSearchObj.selectedFiltersKeys, ...arr];
            setElasticSearchObj({
                ...elasticSearchObj,
                ...elasticSearchObj[key],
                [key]: elasticSearchObj[key],
                selectedFiltersKeys:
                    elasticSearchObj[key].filter((ele) => ele.checked)?.length === 0
                        ? uniqBy(arr, 'label').filter((ele) => ele.label !== selLabel)
                        : uniqBy(arr, 'label')
            });
        }
    };

    const dynamicRemoveFunction = (item, key) => {
        setPageNumber(1);
        elasticSearchObj[key]?.forEach((ele) => {
            ele.checked = false;
        });
        setElasticSearchObj({
            ...elasticSearchObj,
            ...elasticSearchObj[key],
            [key]: elasticSearchObj[key],
            selectedFiltersKeys:
                elasticSearchObj[key].filter((ele) => ele.checked)?.length === 0
                    ? uniqBy(elasticSearchObj.selectedFiltersKeys, 'label').filter((ele) => ele.label !== item.label)
                    : uniqBy(elasticSearchObj.selectedFiltersKeys, 'label')
        });
    };

    const dynamicFiltersServiceCall = (checked, appStatusArr) => {
        setFinalizedElasticSearchObj(JSON.parse(JSON.stringify(elasticSearchObj)));
        let pFilter = elasticSearchObj['policiesArr']?.filter((a) => a.checked)?.map((b) => (b.label === 'NA' ? '' : b.value));
        let dsFilter = elasticSearchObj['deviceStatusArr']?.filter((a) => a.checked)?.map((b) => b.label?.toUpperCase());
        let osFilter = elasticSearchObj['onlineStatusArr']?.filter((a) => a.checked).map((b) => b.value);
        let authFilter = elasticSearchObj['violationStatusArr']?.filter((a) => a.checked).map((b) => b.value);
        let appsFilter = elasticSearchObj['applicationsArr']?.filter((a) => a.checked)?.map((b) => b.packageName);
        let appStatusFilterArr = appStatusArr ? appStatusArr : appStatus;
        let appStatusFilter = appStatusFilterArr?.filter((app) => app.checked)?.map((app2) => app2.value);
        if (appsFilter?.length === 0) {
            let appStats = appStatusArr ? appStatusArr : appStatus;
            appStats.forEach((app) => (app.checked = false));
            setAppStatus(appStats);
        }
        let params = {
            ...searchParams,
            filter: {
                ...searchParams.filter,
                policy: pFilter?.length > 0 ? pFilter : null,
                group: checked?.length > 0 ? checked : null,
                deviceStatus: dsFilter?.length > 0 ? dsFilter : null,
                onlineStatus: osFilter?.length > 0 ? osFilter : null,
                apps: appsFilter?.length > 0 ? appsFilter : null,
                appStatus: appStatusFilter?.length > 0 ? appStatusFilter : null
            }
        };
        if (
            params.filter?.appStatus?.length > 0 ||
            params.filter?.apps?.length > 0 ||
            params.filter?.deviceStatus?.length > 0 ||
            params.filter?.group?.length > 0 ||
            params.filter?.onlineStatus?.length > 0 ||
            params.filter?.policy?.length > 0 ||
            params.filter?.search?.length > 0 ||
            params.filter?.dateFrom?.length > 0 ||
            params.filter?.dateTo?.length > 0
        ) {
            setExportAllLabel('Export');
            setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_ANDROID_DEVICE)}).`);
        } else {
            setExportAllLabel('Export All');
            setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
        }
        if (authFilter && authFilter?.length > 0 && authFilter?.includes('apps')) params.violation = 'Y';
        else delete params.violation;
        setLoading(true);
        params.select = { page: 1, size: 25 };
        setShowMoreFilters(false);
        setSearchParams({ ...params });
        getDevices(params);
    };

    const handleFilter = () => {
        setSelectedDevices([]);
        let timeDiff = new Date() - refreshTime;
        if (refreshTime && timeDiff < 5000) {
            let timeAfterOneMinute = refreshTime.getTime() + 5000;
            var date = new Date(timeAfterOneMinute);
            var hours = date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            var formattedTime = hours + ':' + minutes + ':' + seconds;
            toast.error(toastMessages.refreshTime);
            return;
        } else setRefreshTime(new Date());
        getDevices({ ...searchParams });
    };

    const handleRemoveFilter = (item) => {
        if (item.label === 'Policies') dynamicRemoveFunction(item, 'policiesArr');
        else if (item.label === 'Device Groups') {
            setCheckedData([]);
            dynamicRemoveFunction(item, 'tagListArr');
        } else if (item.label === 'Device Status') dynamicRemoveFunction(item, 'deviceStatusArr');
        else if (item.label === 'Online Status') dynamicRemoveFunction(item, 'onlineStatusArr');
        else if (item.label === 'App Status') dynamicRemoveFunction(item, 'applicationsArr');
        else dynamicRemoveFunction(item, 'violationStatusArr');
    };

    const clearAllFilters = () => {
        dynamicClearAllFunction('policiesArr', elasticSearchObj, setPageNumber, setElasticSearchObj);
        dynamicClearAllFunction('tagListArr', elasticSearchObj, setPageNumber, setElasticSearchObj);
        dynamicClearAllFunction('deviceStatusArr', elasticSearchObj, setPageNumber, setElasticSearchObj);
        dynamicClearAllFunction('violationStatusArr', elasticSearchObj, setPageNumber, setElasticSearchObj);
        dynamicClearAllFunction('onlineStatusArr', elasticSearchObj, setPageNumber, setElasticSearchObj);
        dynamicClearAllFunction('applicationsArr', elasticSearchObj, setPageNumber, setElasticSearchObj);
        setCheckedData([]);
        setExpandedData([]);
    };

    const handleDeviceGroupSearch = (event) => {
        let filterdArr = JSON.parse(JSON.stringify([...elasticSearchObj.tagListBackup]));
        if (event !== undefined) {
            filterdArr.forEach((item) => {
                item.children = item.children.filter(
                    (child) =>
                        child.name?.toLowerCase()?.includes(event?.toLowerCase()) ||
                        child.label?.toLowerCase()?.includes(event?.toLowerCase())
                );
                item.count = item.children.reduce((sum, child) => sum + child.count, 0);
            });
            filterdArr = filterdArr.filter((item) => item.children?.length > 0);
        }
        setElasticSearchObj({ ...elasticSearchObj, tagListArr: filterdArr });
        setSpinner(false);
    };

    const handleExpanded = (_expanded, targetNode) => {
        if (expandedData[0] === targetNode.value) setExpandedData([]);
        else setExpandedData([targetNode.value]);
    };

    const handleChecked = (groupsArr, checked) => {
        checked = getUniqueValues(checked);
        setCheckedData(checked);
        let flatGroupArr = JSON.parse(JSON.stringify(flatGroups));
        flatGroupArr.forEach((group) => (group.checked = checked?.includes(group.code)));
        setFlatGroups(flatGroupArr);
        setFilterCriterialOptions(uniqBy([...filterCriteriaOptions, { label: 'Groups', value: 'groupsArr' }], 'label'));
        let selectedFilterKeys = JSON.parse(JSON.stringify(elasticSearchObj?.selectedFiltersKeys));
        if (checked?.length === 0) {
            selectedFilterKeys = selectedFilterKeys?.filter((filterKey) => filterKey?.label !== 'Device Groups');
        } else {
            selectedFilterKeys = uniqBy([...selectedFilterKeys, ...[{ label: 'Device Groups' }]], 'label');
        }
        setElasticSearchObj({
            ...elasticSearchObj,
            ...elasticSearchObj.tagListArr,
            tagListArr: groupsArr,
            selectedFiltersKeys: selectedFilterKeys
        });
    };

    let modalObj = {
        selectedRow: selectedRow,
        tenantConfig: tenantConfig,
        resetLoader: resetLoader,
        validation: validation,
        handleSubmit: handleSubmit
    };

    const handleCheckboxChange = (e, ind, row, _cell) => {
        if (selectedDevices?.length === 100) {
            toast.error(toastMessages.maxDeviceCount);
            return;
        }
        let rows = [...devices];
        rows[ind].checked = e.target.checked;
        if (e.target.checked) setSelectedDevices([...selectedDevices, row.original]);
        else {
            let selDevices = [...selectedDevices];
            selDevices = selDevices.filter((device) => device.serial !== row.original.serial);
            setSelectedDevices(selDevices);
        }
        setDevices(rows);
        let unselectedRows = rows.filter((rowObj) => !rowObj.checked);
        let selectedRows = rows.filter((rowObj) => rowObj.checked);
        if (selectedRows?.length > 0) {
            setExportAllLabel('Export');
            setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_ANDROID_DEVICE)}).`);
        } else {
            setExportAllLabel('Export All');
            setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
        }
        if (unselectedRows?.length > 0) setCheckAll(false);
        else setCheckAll(true);
    };

    const handleCheckAll = (e) => {
        let fhDevices = devices?.slice(0, 100);
        let rows = [...fhDevices];
        rows.forEach((row) => (row.checked = e.target.checked));
        if (e.target.checked) {
            let selDevices = [...selectedDevices, ...rows];
            selDevices = uniqBy(selDevices, 'serial');
            setSelectedDevices(selDevices);
        } else setSelectedDevices([]);
        setDevices(devices);
        if (rows?.length === devices.length) setCheckAll(e.target.checked);
        else setCheckAll(false);
    };

    const handleScream = (row, _cell, _ind) => {
        setType('scream');
        setActionParam('START');
        setModalHeader('Screamer - ' + row.original?.serial);
        setActionText('Submit');
        setSelectedDevice(row);
        setConfirmationModal(true);
    };

    const handleBulkAction = () => {
        setBulkActionModal(true);
    };

    const handleActionChange = (option, typeVal) => {
        if (typeVal === 'filterCriteria') {
            bulkAction.setValues({ ...bulkAction.values, filterCriteria: option });
            return;
        }
        validation.resetForm();
        validation1.resetForm();
        bulkAction.setValues({ ...bulkAction.values, actionType: option, policy: '', group: '' });
    };

    const changeGroups = (groupsArr, _checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            else delete groupObj.checked;
            if (groupObj.children) groupObj.children = setCheckedGroup(groupObj.children, group);
        });
        bulkAction.setValues({ ...bulkAction.values, group: { ...group, value: group.code } });
        setGroups(groupsArr);
    };

    const handleGroupsBody = () => {
        return (
            <div className="overflow-auto mt-2">
                <div className="max-height-200 height-200">
                    <AnimatedTreeComponent groups={groups} flatGroups={flatGroups} changeGroups={changeGroups} />
                </div>
            </div>
        );
    };

    const handleOnSelectApp = (e) => {
        setSelectedApps(e);
    };

    const handleBulkModalBody = () => {
        return (
            <>
                {/* {elasticSearchObj.selectedFiltersKeys && elasticSearchObj.selectedFiltersKeys?.length > 0 && (
                    <Row>
                        <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                            <Label className="form-label d-flex align-items-center fw-medium">
                                Filter on: <span className="red-color ps-1">*</span>
                                <i className="ri-information-line blue-icon ps-1" id={`filterOn`} />
                                <UncontrolledTooltip placement="bottom" target={`filterOn`}>
                                    Apply Filter on
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                autoFocus={true}
                                id="label"
                                name="value"
                                classNamePrefix={'custom-select w-100'}
                                options={filterCriteriaOptions}
                                placeholder="Select Filter Criteria"
                                onBlur={() => bulkAction.handleBlur({ target: { name: 'filterCriteria' } })}
                                onChange={(selectedOption) => handleActionChange(selectedOption, 'filterCriteria')}
                                value={bulkAction.values?.filterCriteria || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col>
                    </Row>
                )} */}
                {handleBulkModalBodyPart(bulkAction, actionTypes, handleActionChange)}
                {bulkAction.values?.actionType &&
                    bulkAction.values?.actionType?.value === 'RESET_PASSWORD' &&
                    handleModalBody(true, modalObj)}
                {bulkAction.values?.actionType && bulkAction.values?.actionType?.value === 'RESET' && handleDeleteBody(true, deleteFuncObj)}
                {bulkAction.values?.actionType &&
                    bulkAction.values?.actionType?.value === 'NEWPOLICY' &&
                    handlePGChangeBody(policies, 'Policy', 'nameCode', bulkAction)}
                {bulkAction.values?.actionType &&
                    bulkAction.values?.actionType?.value === 'NEWGROUP' &&
                    handleGroupsBody(groups, 'Group', 'nameCode')}
                {bulkAction.values?.actionType && bulkAction?.values?.actionType?.value === 'NOTES' && handleNotesModalBody(validation1)}
                {bulkAction.values?.actionType &&
                    bulkAction?.values?.actionType?.value === 'CLEAR_CACHE' &&
                    handleApplicationModalBody(applications, selectedApps, handleOnSelectApp)}
            </>
        );
    };

    const handleClickView = async (row) => {
        setSelectedPolicy(row.original);
        setLoading(true);
        await api
            .get(url.POLICIES + '/code/' + row.original.policyApplied)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    setPolicyType(resp.data.policyType);
                    setPolicyData(resp);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
        setOffcanvasModal(true);
    };

    const toggle = useCallback(async () => {
        setOffcanvasModal(!offcanvasModal);
    }, [offcanvasModal]);

    const toggleFilter = useCallback(() => {
        setElasticSearchObj(JSON.parse(JSON.stringify(finalizedElasticSearchObj)));
        setShowMoreFilters(!showMoreFilters);
    }, [showMoreFilters]);

    const handleOffcanvasBody = () => {
        return offcanvasModal ? (
            <AddPolicy code={selectedPolicy?.policyApplied} policyType={policyType} policyData={policyData} mode="view" />
        ) : (
            <Filters
                showFilterMenu={showFilterMenu}
                devices={devices}
                flatGroups={flatGroups}
                elasticSearchObj={{ ...elasticSearchObj }}
                handlePolicyFilter={handlePolicyFilter}
                handleDeviceStatusFilter={handleDeviceStatusFilter}
                handleViolationSatusFilter={handleViolationSatusFilter}
                handleRemoveFilter={handleRemoveFilter}
                handleDeviceGroupSearch={handleDeviceGroupSearch}
                handleAppStatusFilter={handleAppStatusFilter}
                clearAllFilters={clearAllFilters}
                handleOnlineStatusFilter={handleOnlineStatusFilter}
                handleChecked={handleChecked}
                handleExpanded={handleExpanded}
                handleAppStatus={handleAppStatus}
                handleFilterMenuClick={handleFilterMenuClick}
                appStatus={appStatus}
                spinner={spinner}
                setSpinner={setSpinner}
                handleDeviceGroupFilter={handleDeviceGroupFilter}
                checked={checkedData}
                expanded={expandedData}
                tenantConfig={tenantConfig}
            />
        );
    };

    const handleAppStatus = (status) => {
        let statusArr = [...appStatus];
        statusArr.forEach((obj) => {
            if (obj.value === status) {
                obj.checked = !obj.checked;
            }
        });
        setAppStatus(statusArr);
    };

    const handleFilterMenuClick = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    let handleActionConfirmObj = {
        type: type,
        setConfirmationModal: setConfirmationModal,
        actionParam: actionParam,
        selectedDevice: selectedDevice,
        lostModeParam: lostModeParam,
        setLoading: setLoading,
        setSelectedDevice: setSelectedDevice,
        setActionText: setActionText,
        setShowModal: setShowModal,
        lostModeMessage: lostModeMessage,
        lostModePhoneNumber: lostModePhoneNumber,
        lostModeEmail: lostModeEmail,
        retireModal: retireModal,
        setActionParam: setActionParam,
        setLostModeMessage: setLostModeMessage,
        setLostModePhoneNumber: setLostModePhoneNumber,
        setLostModeEmail: setLostModeEmail,
        setLostModeParam: setLostModeParam,
        setRetireModal: setRetireModal
    };

    const handleLostMode = (row) => {
        setSelectedDevice(row);
        setType('LOST_MODE');
        setModalHeader('Serial - ' + row.original?.serial);
        setConfirmationModal(true);
        setActionTextFunc('START');
    };

    const handleIsExportAll = () => {
        setLoading(true);
        if (exportAllLabel === 'Export All' && selectedDevices?.length === 0)
            api.get(url.DEVICE_DOWNLOAD_ALL)
                .then((resp) => {
                    generateFile(resp);
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        else handleIsExport(exportObj, api, tenantConfig);
    };

    const handleDeviceGroupFilter = (val) => {
        setLoading(true);
        api.get(url.DEVICE_GROUPS_SEARCH + '?name=' + val, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                handleDeviceGroupFilterResult(resp, setSearchResults);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getAllWidgetsData = (widgetCount) => {
        return [
            {
                id: 1,
                label: 'Total',
                counter: widgetCount.totalDevicesCount,
                icon: TotalDevices,
                color: '155BB3',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 2,
                label: 'New',
                counter: widgetCount.deviceNewCount,
                icon: NewDevices,
                color: '69C1C0',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 3,
                label: 'Provisioning',
                counter: widgetCount.deviceProvisioningCount,
                icon: ProvisioningDevices,
                color: 'E77B24',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 4,
                label: 'Active',
                counter: widgetCount.deviceActiveCount,
                icon: ActiveDevices,
                color: '77C974',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 5,
                label: 'Disabled',
                counter: widgetCount.deviceDisabledCount,
                icon: DisabledDevices,
                color: '9599AD',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 6,
                label: 'Deleted',
                counter: widgetCount.deviceDeletedCount,
                icon: DeletedDevices,
                color: 'E25B5D',
                decimals: 0,
                suffix: '',
                prefix: ''
            }
        ];
    };
    const handleRetireBody = () => {
        return (
            <div className="mt-3 ">
                <div className="mb-3 fs-14 text-center">Are you sure you want to Retire this device?</div>
                <div className="mb-3 d-flex align-items-center justify-content-center">
                    <img src={ClearData} alt="deleteGroup" width={60} height={60} />
                </div>
                <div className="mt-3 text-center">
                    Serial: <span className="fw-semibold ">{selectedRow?.original?.serial}</span>
                </div>
                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                    <div>
                        Upon confirmation, the device will be removed from the list. This action would take few minutes to remove the device
                        completely.
                    </div>
                </Alert>
            </div>
        );
    };
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Android Enterprise Devices'} history={history} />
                    <div className="mt-4">
                        <Row>
                            {/* {!hideAllFilters && (
                                <div className={`${!showFilterMenu ? 'widthPer-5' : 'widthPer-20'}`}>
                                    <Filters
                                        showFilterMenu={showFilterMenu}
                                        devices={devices}
                                        flatGroups={flatGroups}
                                        elasticSearchObj={{ ...elasticSearchObj }}
                                        handlePolicyFilter={handlePolicyFilter}
                                        handleDeviceStatusFilter={handleDeviceStatusFilter}
                                        handleViolationSatusFilter={handleViolationSatusFilter}
                                        handleRemoveFilter={handleRemoveFilter}
                                        handleDeviceGroupSearch={handleDeviceGroupSearch}
                                        handleAppStatusFilter={handleAppStatusFilter}
                                        clearAllFilters={clearAllFilters}
                                        handleOnlineStatusFilter={handleOnlineStatusFilter}
                                        handleChecked={handleChecked}
                                        handleExpanded={handleExpanded}
                                        handleAppStatus={handleAppStatus}
                                        handleFilterMenuClick={handleFilterMenuClick}
                                        appStatus={appStatus}
                                        spinner={spinner}
                                        setSpinner={setSpinner}
                                        handleDeviceGroupFilter={handleDeviceGroupFilter}
                                        checked={checkedData}
                                        expanded={expandedData}
                                        tenantConfig={tenantConfig}
                                    />
                                </div>
                            )} */}
                            {/* <div className={`${hideAllFilters ? 'widthPer-100' : !showFilterMenu ? 'widthPer-95' : 'widthPer-80'}`}> */}
                            <Row>
                                <Widgets allWidgetsData={getAllWidgetsData(WidgetCount)} />
                            </Row>
                            <Row className="m-0">
                                <TableContainer
                                    loading={loading}
                                    handleMap={(row) => history.push(`/aedevices/viewmap/${row.original.id}`)}
                                    uploadTooltip="Upload Devices"
                                    // refresh={true}
                                    doNotJustify={true}
                                    refreshTime={refreshTime}
                                    searchFilter3={true}
                                    value={searchValue}
                                    largeSearchFilter={true}
                                    exportTooltip={exportTooltip}
                                    largeFilter={true}
                                    exportFile5l={true}
                                    moreFilters={true}
                                    handleMoreFilters={() => setShowMoreFilters(true)}
                                    exportAllLabel={exportAllLabel}
                                    handleIsExportAll={handleIsExportAll}
                                    filter1CharLimit={200}
                                    dateClass="width-175"
                                    showTooltip={true}
                                    isGlobalFilter={true}
                                    dateRangeFilter={true}
                                    datePlaceHolder={'Enrollment Date'}
                                    checkAll={checkAll}
                                    bulkAction={true}
                                    disableBulkAction={selectedDevices?.length === 0}
                                    handleBulkAction={handleBulkAction}
                                    data={devices}
                                    handleFilter={handleFilter}
                                    handleScream={handleScream}
                                    handleClickView={handleClickView}
                                    columns={devicesTableSchema}
                                    handleRetire={handleRetire}
                                    totalRecords={totalRecords}
                                    pageNum={pageNumber}
                                    reader={priviliges.DEVICE_READER}
                                    toastMessage="Enter minimum 3 characters to search."
                                    editor={priviliges.DEVICE_EDITOR}
                                    customPageSize={25}
                                    handleUpload={handleUpload}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleCheckAll={handleCheckAll}
                                    onPageChange={onPageChange}
                                    handleLostMode={handleLostMode}
                                    handleDeviceLock={handleDeviceLock}
                                    handleNotes={handleNotes}
                                    handleDeviceReboot={handleDeviceReboot}
                                    handleResetPassword={handleResetPassword}
                                    handleFactoryReset={handleFactoryReset}
                                    onChange={handleGlobalSearch}
                                    handleDateSearch={handleDateSearch}
                                    acceptFiles=".csv"
                                    searchPlaceHolder="multiple Serial, Notes and IMEI with space separation"
                                    className="custom-header-css"
                                    divClass="table-responsive table-card"
                                    tableClass="table table-nowrap table-border table-centered align-middle"
                                    theadClass="bg-light text-muted"
                                />
                            </Row>
                            {/* </div> */}
                        </Row>
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={handleDeleteConfirmation}
                            onCloseClick={() => {
                                setDeleteModal(false);
                                setWipeExternalStorage(false);
                                setWipeReasonMessage(false);
                                setWipeReasonMessageValue('');
                            }}
                            deleteMessage={`Are you sure you want to factory reset this device ${selectedRow?.original?.serial} ?`}
                            confirmText={'Yes, Reset it!'}
                            customBody={true}
                            disabled={wipeReasonMessage && (!wipeReasonMessageValue || wipeReasonMessageValue === '')}
                            deleteBody={handleDeleteBody(false, deleteFuncObj, selectedRow)}
                        />
                        <DeleteModal
                            hideIcon={true}
                            show={confirmationModal}
                            showHeader={type === 'scream' || type === 'LOST_MODE'}
                            modalheader={modalHeader ? modalHeader : undefined}
                            hideDeleteIcon={true}
                            hideDeleteMessage={true}
                            message={messageFunc(messageFuncObj)}
                            confirmText={actionText}
                            disabled={
                                type === 'LOST_MODE' && lostModeParam === 'START'
                                    ? lostModeEmail === '' || lostModeMessage === '' || lostModePhoneNumber === ''
                                    : false
                            }
                            onDeleteClick={() => handleActionConfirmation(handleActionConfirmObj, api, urlconf)}
                            onCloseClick={() => {
                                setConfirmationModal(false);
                                setLostModeParam('START');
                                setModalHeader('');
                                setType('');
                                setActionText('');
                                setLostModePhoneNumber('');
                                setLostModeEmail('');
                                setLostModeMessage('');
                            }}
                        />
                        <CommonModal
                            size={'md'}
                            show={showModal}
                            disabled={false}
                            hideSaveButton={true}
                            onCloseClick={() => {
                                validation.resetForm();
                                setShowModal(false);
                            }}
                            handleClick={() => setShowModal(false)}
                            handleModalBody={() => handleModalBody(false, modalObj)}
                        />
                        <DeleteModal
                            show={retireModal}
                            onDeleteClick={confirmRetire}
                            onCloseClick={() => {
                                setSelectedRow('');
                                setRetireModal(false);
                            }}
                            deleteMessage={`Are you sure you want to factory reset this device ${selectedRow?.original?.serial} ?`}
                            confirmText={'Yes, Retire it!'}
                            customBody={true}
                            disabled={wipeReasonMessage && (!wipeReasonMessageValue || wipeReasonMessageValue === '')}
                            deleteBody={handleRetireBody()}
                        />
                        <OffcanvasModal
                            direction="end"
                            toggle={offcanvasModal ? toggle : toggleFilter}
                            open={offcanvasModal || showMoreFilters}
                            hideSaveButton={showMoreFilters ? false : true}
                            handleCloseClick={offcanvasModal ? toggle : toggleFilter}
                            OffcanvasModalID="announcementModal"
                            saveText="Apply Filter"
                            handleOffcanvasBody={handleOffcanvasBody}
                            modalClassName={offcanvasModal ? 'w-50' : 'w-25'}
                            offcanvasHeader={offcanvasModal ? 'View Policy' : 'Filters'}
                            handleSaveClick={handleSubmit}
                        />
                        <CommonModal
                            size={'md'}
                            cancelText={'Cancel'}
                            modalheader={`Notes: ${selectedRow?.original?.serial}`}
                            show={showNotesModal}
                            onCloseClick={() => {
                                validation1.resetForm();
                                setShowNotesModal(false);
                            }}
                            handleClick={() => {
                                validation1.handleSubmit();
                            }}
                            handleModalBody={() => handleNotesModalBody(validation1)}
                            disabled={!validation1.values.notes}
                            saveText={notes && notes !== '' ? 'Update' : 'Save'}
                        />
                        <CommonModal
                            size={'md'}
                            cancelText={'Cancel'}
                            modalheader={`Devices (${selectedDevices?.length})`}
                            show={bulkActionModal}
                            disabled={disableBulkAction(bulkAction, tenantConfig, validation)}
                            onCloseClick={() => {
                                bulkAction.resetForm();
                                validation.resetForm();
                                setWipeExternalStorage(false);
                                setWipeReasonMessage(false);
                                setWipeReasonMessageValue('');
                                setBulkActionModal(false);
                            }}
                            handleClick={() => bulkAction.handleSubmit()}
                            handleModalBody={() => handleBulkModalBody()}
                        />
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AndroidEnterpriseDevices;
