import AppStore from '../../../assets/images/AppleDefaultAppIcons/AppStore.png';
import AppleBooks from '../../../assets/images/AppleDefaultAppIcons/AppleBooks.png';
import AppleTV from '../../../assets/images/AppleDefaultAppIcons/AppleTV.png';
import Calculator from '../../../assets/images/AppleDefaultAppIcons/Calculator.png';
import Camera from '../../../assets/images/AppleDefaultAppIcons/Camera.png';
import Contacts from '../../../assets/images/AppleDefaultAppIcons/Contacts.png';
import Facetime from '../../../assets/images/AppleDefaultAppIcons/Facetime.png';
import Findmy from '../../../assets/images/AppleDefaultAppIcons/Findmy.png';
import health from '../../../assets/images/AppleDefaultAppIcons/health.png';
import home from '../../../assets/images/AppleDefaultAppIcons/home.png';
import Mail from '../../../assets/images/AppleDefaultAppIcons/Mail.png';
import Messages from '../../../assets/images/AppleDefaultAppIcons/Messages.png';
import Podcasts from '../../../assets/images/AppleDefaultAppIcons/Podcasts.png';
import safari from '../../../assets/images/AppleDefaultAppIcons/safari.png';
import shortcuts from '../../../assets/images/AppleDefaultAppIcons/shortcuts.png';
import stocks from '../../../assets/images/AppleDefaultAppIcons/stocks.png';
import translate from '../../../assets/images/AppleDefaultAppIcons/translate.png';
import Weather from '../../../assets/images/AppleDefaultAppIcons/Weather.png';
import AppleMusic from '../../../assets/images/AppleDefaultAppIcons/AppleMusic.png';
import Calendar from '../../../assets/images/AppleDefaultAppIcons/Calendar.png';
import Clips from '../../../assets/images/AppleDefaultAppIcons/Clips.png';
import Clock from '../../../assets/images/AppleDefaultAppIcons/Clock.png';
import iMazing from '../../../assets/images/AppleDefaultAppIcons/iMazing.png';
import Journal from '../../../assets/images/AppleDefaultAppIcons/Journal.png';
import Photos from '../../../assets/images/AppleDefaultAppIcons/Photos.png';
import reminder from '../../../assets/images/AppleDefaultAppIcons/reminders.png';
import Settings from '../../../assets/images/AppleDefaultAppIcons/Settings.png';
import wallet from '../../../assets/images/AppleDefaultAppIcons/wallet.png';
import tips from '../../../assets/images/AppleDefaultAppIcons/Tips.png';
import freeform from '../../../assets/images/AppleDefaultAppIcons/Freeform.png';
import Magnifier from '../../../assets/images/AppleDefaultAppIcons/Magnifier.png';
import measure from '../../../assets/images/AppleDefaultAppIcons/measure.png';
import compass from '../../../assets/images/AppleDefaultAppIcons/Compass.png';
import files from '../../../assets/images/AppleDefaultAppIcons/Files.png';
import iCloudDrive from '../../../assets/images/AppleDefaultAppIcons/iCloud.png';
import maps from '../../../assets/images/AppleDefaultAppIcons/Maps.png';
import mobileStore from '../../../assets/images/AppleDefaultAppIcons/apple-itunes-store.png';
import notes from '../../../assets/images/AppleDefaultAppIcons/Notes.png';
import news from '../../../assets/images/AppleDefaultAppIcons/news.png';
import photobooth from '../../../assets/images/AppleDefaultAppIcons/photobooth.png';
import phone from '../../../assets/images/AppleDefaultAppIcons/apple-phone.png';
import video from '../../../assets/images/AppleDefaultAppIcons/videos.png';
import voicememo from '../../../assets/images/AppleDefaultAppIcons/VoiceMemos.png';

const IOS_DEFAULT_APPS = [
    // { name: 'Arcade', packagename: 'com.apple.Arcade', checked: false, type: 'default' },
    { name: 'App Store', packagename: 'com.apple.AppStore', checked: false, type: 'default', image: AppStore },
    // { name: 'Barcode support Qrcode', packagename: 'com.apple.barcodesupport.qrcode', checked: false, type: 'default' },
    { name: 'Calculator', packagename: 'com.apple.calculator', checked: false, type: 'default', image: Calculator },
    { name: 'Camera', packagename: 'com.apple.camera', checked: false, type: 'default', image: Camera },
    { name: 'Compass', packagename: 'com.apple.compass', checked: false, type: 'default', image: compass },
    { name: 'Files', packagename: 'com.apple.DocumentsApp', checked: false, type: 'default', image: files },
    { name: 'Facetime', packagename: 'com.apple.facetime', checked: false, type: 'default', image: Facetime },
    { name: 'Find My', packagename: 'com.apple.findmy', checked: false, type: 'default', image: Findmy },
    { name: 'Free Form', packagename: 'com.apple.freeform', checked: false, type: 'default', image: freeform },
    // { name: 'Game Center', packagename: 'com.apple.gamecenter', checked: false, type: 'default' },
    { name: 'Health', packagename: 'com.apple.Health', checked: false, type: 'default', image: health },
    { name: 'Home', packagename: 'com.apple.Home', checked: false, type: 'default', image: home },
    { name: 'iBooks', packagename: 'com.apple.iBooks', checked: false, type: 'default', image: AppleBooks },
    { name: 'iCloud Drive App', packagename: 'com.apple.iCloudDriveApp', checked: false, type: 'default', image: iCloudDrive },
    { name: 'Maps', packagename: 'com.apple.Maps', checked: false, type: 'default', image: maps },
    { name: 'Magnifier', packagename: 'com.apple.Magnifier', checked: false, type: 'default', image: Magnifier },
    { name: 'Measure', packagename: 'com.apple.measure', checked: false, type: 'default', image: measure },
    { name: 'Mobile Address Book', packagename: 'com.apple.MobileAddressBook', checked: false, type: 'default', image: Contacts },
    { name: 'Mobile Mail', packagename: 'com.apple.mobilemail', checked: false, type: 'default', image: Mail },
    { name: 'Mobile Safari', packagename: 'com.apple.mobilesafari', checked: false, type: 'default', image: safari },
    { name: 'Mobile SMS', packagename: 'com.apple.MobileSMS', checked: false, type: 'default', image: Messages },
    { name: 'Mobile Store', packagename: 'com.apple.MobileStore', checked: false, type: 'default', image: mobileStore },
    { name: 'Mobile Timer', packagename: 'com.apple.mobiletimer', checked: false, type: 'default', image: Clock },
    { name: 'Mobile Notes', packagename: 'com.apple.mobilenotes', checked: false, type: 'default', image: notes },
    { name: 'Mobile Slideshow', packagename: 'com.apple.mobileslideshow', checked: false, type: 'default', image: Photos },
    { name: 'Music', packagename: 'com.apple.Music', checked: false, type: 'default', image: AppleMusic },
    { name: 'News', packagename: 'com.apple.news', checked: false, type: 'default', image: news },
    { name: 'Wallet', packagename: 'com.apple.Passbook', checked: false, type: 'default', image: wallet },
    { name: 'Phone', packagename: 'com.apple.mobilephone', checked: false, type: 'default', image: phone },
    { name: 'Photo-Booth', packagename: 'com.apple.Photo-Booth', checked: false, type: 'default', image: photobooth },
    { name: 'Podcasts', packagename: 'com.apple.podcasts', checked: false, type: 'default', image: Podcasts },
    { name: 'Reminders', packagename: 'com.apple.reminders', checked: false, type: 'default', image: reminder },
    { name: 'Shortcuts', packagename: 'com.apple.shortcuts', checked: false, type: 'default', image: shortcuts },
    { name: 'Stocks', packagename: 'com.apple.stocks', checked: false, type: 'default', image: stocks },
    { name: 'Tips', packagename: 'com.apple.tips', checked: false, type: 'default', image: tips },
    { name: 'Translate', packagename: 'com.apple.Translate', checked: false, type: 'default', image: translate },
    { name: 'TV', packagename: 'com.apple.tv', checked: false, type: 'default', image: AppleTV },
    { name: 'videos', packagename: 'com.apple.videos', checked: false, type: 'default', image: video },
    { name: 'Voice Memos', packagename: 'com.apple.VoiceMemos', checked: false, type: 'default', image: voicememo },
    { name: 'Weather', packagename: 'com.apple.weather', checked: false, type: 'default', image: Weather }
    /*
     * { name: 'TVApp Store', packagename: 'com.apple.TVAppStore', checked: false, type: 'default' },
     * { name: 'TVHome Sharing', packagename: 'com.apple.TVHomeSharing', checked: false, type: 'default' },
     * { name: 'TVMovies', packagename: 'com.apple.TVMovies', checked: false, type: 'default' },
     * { name: 'TVMusic', packagename: 'com.apple.TVMusic', checked: false, type: 'default' },
     * { name: 'TVPhotos', packagename: 'com.apple.TVPhotos', checked: false, type: 'default' },
     * { name: 'TVSearch', packagename: 'com.apple.TVSearch', checked: false, type: 'default' },
     * { name: 'TVShows', packagename: 'com.apple.TVShows', checked: false, type: 'default' },
     * { name: 'TV WatchList', packagename: 'com.apple.TVWatchList', checked: false, type: 'default' },
     * { name: 'fmf1', packagename: 'com.apple.mobileme.fmf1', checked: false, type: 'default' },
     * { name: 'fmip1', packagename: 'com.apple.mobileme.fmip1', checked: false, type: 'default' }
     */
];

export default IOS_DEFAULT_APPS;
