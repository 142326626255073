import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { loadEnvVariables } from './envLoader';
import { envVals } from './store/actions';
import { Store } from './store/store';
import { useHistory } from 'react-router-dom';
import Loader from './Components/Common/DotsLoader';
import Fallback from './Components/Common/Fallback';

const EnvContext = createContext({});

export const EnvProvider = ({ children }) => {
    const [envVars, setEnvVars] = useState(null);
    const count = useRef(0);
    const [retries, setRetries] = useState(0);
    const history = useHistory();

    useEffect(() => {
        fetchEnvVars();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchEnvVars = async () => {
        const env = await loadEnvVariables();
        if (env) {
            Store.dispatch(envVals(JSON.stringify(env)));
            setEnvVars(env);
        } else {
            count.current += 1;
            setRetries(count.current);
            if (count.current <= 3) fetchEnvVars();
        }
    };

    return (
        <EnvContext.Provider value={envVars}>
            {envVars ? children : retries > 3 ? <Fallback retry={fetchEnvVars} /> : <Loader />}
        </EnvContext.Provider>
    );
};

export const useEnv = () => useContext(EnvContext);
