import React from 'react';

export const tableData = [
    {
        Header: 'License ID',
        accessor: 'licenseId',
        sortable: true,
        customCell: true,
        fieldType: 'clickable'
    },
    {
        Header: 'No.of Devices',
        accessor: 'noOfDevices',
        customCell: true,
        fieldType: 'textColor'
    },
    {
        Header: 'Plan Type',
        accessor: 'planType',
        customCell: true,
        fieldType: 'iconText'
    },

    {
        Header: 'License Validity',
        accessor: 'licenseValidity',
        customCell: true,
        fieldType: 'coloredText'
    },

    {
        Header: 'License Starting Date',
        accessor: 'startDate',
        customCell: true,
        fieldType: 'textColor'
    },
    {
        Header: 'License End Date',
        accessor: 'endDate',
        customCell: true,
        fieldType: 'textColor'
    },
    {
        Header: 'Status',
        accessor: 'status',
        customCell: true,
        fieldType: 'badge'
    }
];

export const details = [
    {
        licenseId: 'TT001',
        planType: 'Purchased',
        licenseValidity: '14 days',
        noOfDevices: 50,
        startDate: 'Apr 01, 2024',
        endDate: 'May 01, 2024',
        badgeClass: 'danger fw-medium',
        status: 'expired',
        textColorClass: 'black fw-medium',
        imageURL: 'https://pixabay.com/photos/birds-bird-nature-pens-turtledove-2378923'
    },
    {
        licenseId: 'TT002',
        planType: 'Purchased',
        licenseValidity: '14 days',
        noOfDevices: 1000,
        startDate: 'Apr 01, 2024',
        endDate: 'May 01, 2024',
        badgeClass: 'success fw-medium',
        status: 'Active',
        textColorClass: 'black fw-medium'
    },
    {
        licenseId: 'TT003',
        planType: 'Purchased',
        licenseValidity: '14 days',
        noOfDevices: 1000,
        startDate: 'Apr 01, 2024',
        endDate: 'May 01, 2024',
        badgeClass: 'info fw-medium',
        status: 'Not Active Yet',
        textColorClass: 'black fw-medium'
    },
    {
        licenseId: 'TT004',
        planType: 'Purchased',
        licenseValidity: '14 days',
        noOfDevices: 1000,
        startDate: 'Apr 01, 2024',
        endDate: 'May 01, 2024',
        badgeClass: 'black fw-medium',
        status: 'Inactive',
        textColorClass: 'black fw-medium'
    },
    {
        licenseId: 'TT005',
        planType: 'free trail',
        licenseValidity: '14 days',
        noOfDevices: 1000,
        startDate: 'Apr 01, 2024',
        endDate: 'May 01, 2024',
        badgeClass: 'warning fw-medium',
        status: 'Under Review',
        textColorClass: 'black fw-medium'
    },
    {
        licenseId: 'TT006',
        planType: 'free trail',
        licenseValidity: '14 days',
        noOfDevices: 1000,
        startDate: 'Apr 01, 2024',
        endDate: 'May 01, 2024',
        badgeClass: 'warning fw-medium',
        status: 'Draft',
        textColorClass: 'black fw-medium'
    }
];

export const LicenseInfo = [
    { title: 'Total Licenses', value: '1000' },
    { title: 'Used Licenses', value: '900' },
    { title: 'Remaining Licenses', value: '100' }
];

export const DevicesInfo = [
    { title: 'Android Devices', value: '800', icon: <i className="ri-android-fill px-1 text-success"></i> },
    { title: 'Windows Devices', value: '50', icon: <i className="ri-windows-fill px-1 text-info"></i> },
    { title: 'iOS Devices', value: '50', icon: <i className="ri-tablet-fill px-1"></i> }
];
