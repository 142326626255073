import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Form, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

const CustomStepperComponent = ({
    stepperData,
    enableSaveBtn,
    handleSave,
    handleDisableSave,
    cancelBtn,
    routeText,
    formType,
    customCancel
}) => {
    const history = useHistory();
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [visitedSteps, setVisitesSteps] = useState([]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];
            if (tab >= 1 && tab <= stepperData?.length) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        let tabs = [...visitedSteps];
        if (!tabs.includes(activeTab)) tabs.push(activeTab);
        setVisitesSteps(tabs);
    }

    const renderFooterBtns = (tabData, index) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {index !== 1 ? (
                    <div className="d-flex align-items-end">
                        <div className="right ms-auto nexttab">
                            <Button outline color="primary" className="d-flex align-items-center" onClick={() => toggleTab(activeTab - 1)}>
                                <i className="ri-arrow-left-line pe-1" />
                                <span>Prev</span>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <span className="px-5"></span>
                )}
                {`Steps(${activeTab}/${stepperData.length})`}
                <div className="d-flex align-items-end ">
                    {cancelBtn && (
                        <div className="pe-3">
                            <Button
                                outline
                                color="primary"
                                onClick={() => {
                                    if (customCancel) customCancel();
                                    else history.push(`/${routeText}`);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    )}

                    {index === stepperData.length && enableSaveBtn ? (
                        <Button
                            size={'md'}
                            color="primary"
                            className="d-flex align-items-center border-primary"
                            disabled={handleDisableSave}
                            onClick={() => (handleSave ? handleSave() : '')}
                        >
                            {formType ? (formType === 'add' ? 'Save' : 'Update') : 'Save'}
                        </Button>
                    ) : stepperData.length !== index ? (
                        <div className="right ms-auto nexttab">
                            <Button outline color="primary" className="d-flex align-items-center" onClick={() => toggleTab(activeTab + 1)}>
                                <span className="pe-1">Next</span>
                                <i className="ri-arrow-right-line" />
                            </Button>
                        </div>
                    ) : (
                        <span className="px-7"></span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Col xl="12" className="p-1">
            <Form action="#">
                <div className="my-2">
                    <Nav className="nav-justified nav-container" role="tablist">
                        {stepperData.map((obj, index) => (
                            <NavItem role="presentation" key={`item_${index}`} className="nav-step-item mb-3">
                                <NavLink
                                    href="#"
                                    className={classnames(
                                        {
                                            active: activeTab === index + 1,
                                            done: activeTab <= stepperData.length && activeTab >= index + 1
                                        },
                                        'rounded-circle step-link align-items-center'
                                    )}
                                    onClick={() => toggleTab(index + 1)}
                                >
                                    {visitedSteps?.includes(index + 1) ? (
                                        <i className="ri-check-line fs-11 p-2 bg-soft-success text-success rounded-circle align-middle me-2" />
                                    ) : (
                                        <span
                                            className={`border fs-10 px-2 py-1 rounded-circle align-middle me-1 ${
                                                activeTab === index + 1 ? 'bg-success border-success text-white' : 'border-grey'
                                            }`}
                                        >
                                            {obj.icon}
                                        </span>
                                    )}
                                    <span className={`${activeTab === index + 1 ? 'text-success' : 'text-grey'} fw-semibold`}>
                                        {obj.title}
                                    </span>
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                    {stepperData.map((tabData, index) => {
                        return (
                            <TabPane tabId={index + 1} id="pills-bill-info" key={`tabpane_${index}`}>
                                <div> {tabData.renderTab}</div>
                                <div className="my-6 "> {renderFooterBtns(tabData, index + 1)}</div>
                            </TabPane>
                        );
                    })}
                </TabContent>
            </Form>
        </Col>
    );
};

export default CustomStepperComponent;
