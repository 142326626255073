import React from 'react';
import CountUp from 'react-countup';
import { Card, CardBody, Col } from 'reactstrap';
import { Tooltip } from './Util';

const Widgets = (props) => {
    return (
        <React.Fragment>
            {(props.allWidgetsData || []).map((item, key) => (
                <Col key={key}>
                    <Card>
                        <CardBody className={`border-start border-2 border-${item.color}`}>
                            <div className="d-flex align-items-center">
                                <img src={item.icon} alt="" />
                                <div className="flex-grow-1 overflow-hidden ms-3">
                                    <p className="text-uppercase fw-semibold text-muted text-truncate mb-3" id={`widget-${key}`}>
                                        {item.label}
                                        {item.label?.length > 8 && Tooltip(`widget-${key}`, item.label)}
                                    </p>
                                    <div className="d-flex align-items-center mb-3">
                                        <h4 className="fs-16 flex-grow-1 text-truncate text-info mb-0">
                                            <span className="counter-value me-1" data-target="825" id={`count-${key}`}>
                                                <CountUp
                                                    start={0}
                                                    prefix={item.prefix}
                                                    suffix={item.suffix}
                                                    separator={item.separator}
                                                    end={item.counter}
                                                    decimals={item.decimals}
                                                    duration={4}
                                                />
                                                {item.counter?.length > 6 && Tooltip(`count-${key}`, item.counter)}
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    );
};

export default Widgets;
