import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { geoFenceSchema } from '../TableSchema';

const MobileGeoFenceReport = () => {
    document.title = 'Mobile Geofence Report';
    const urlconf = useEnv();
    let api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [geoFences, setGeoFences] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        getGeoFences(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getGeoFences = (params) => {
        setLoading(true);
        api.get(url.DEVICE_REPORTS + '/geolocation', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.date = getDate(data.date, false, true);
                        data.badgeClass = data.status === 'IN' ? 'success' : 'danger';
                    });
                    setGeoFences(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onPageChange = (pgNum) => {
        setPageNumber(pgNum);
        setSearchParams({ page: pgNum, size: 10 });
        getGeoFences({ page: pgNum, size: 10 });
    };

    const handleExportLogs = () => {
        setLoading(true);
        api.create(
            url.DEVICE_REPORTS +
                '/geolocation/download' +
                (searchParams?.serial || searchParams?.fromDate || searchParams?.toDate ? '?' : '') +
                (searchParams?.serial ? 'serial=' + searchParams?.serial : '') +
                (searchParams?.serial && searchParams?.fromDate
                    ? '&fromDate=' + searchParams?.fromDate
                    : searchParams?.fromDate
                    ? 'fromDate=' + searchParams?.fromDate
                    : '') +
                ((searchParams?.serial || searchParams?.fromDate) && searchParams?.toDate
                    ? '&toDate=' + searchParams?.toDate
                    : searchParams?.toDate
                    ? 'toDate=' + searchParams?.toDate
                    : '')
        )
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = 'Mobile Geo Location Report.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleGlobalSearch = (val) => {
        let params = { ...searchParams, page: 1, size: 10, serial: val };
        setPageNumber(1);
        setSearchParams(params);
        getGeoFences(params);
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (params.fromDate && params.toDate) getGeoFences({ ...params, page: 1, size: 10 });
        else if (val?.length === 0) getGeoFences({ ...params, page: 1, size: 10 });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb pageTitle={'Geo Fence'} history={history} homeLink="Dashboard" showBack={true} backLink="aereports" />
                    <TableContainer
                        loading={loading}
                        searchFilter2={true}
                        dateRangeFilter2={true}
                        datePlaceHolder={'Date Range'}
                        tableHeader={'All Mobile Geofences'}
                        // searchFiltersButton={true}
                        exportLogs={true}
                        handleExportLogs={handleExportLogs}
                        searchPlaceHolder={'Serial'}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand logs only)."
                        isGlobalFilter={true}
                        addButton={false}
                        columns={geoFenceSchema}
                        data={geoFences || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MobileGeoFenceReport;
