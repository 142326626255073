import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useFormik } from 'formik';
import { Enterprise, getAppProtectionPathParams, sortBy } from '../../../Components/Common/Util';
import tectoro from '../../../assets/images/Tectoro.png';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import Select from 'react-select';
import { DynamicComponents } from './AppComponents';
import {
    tectoroDataProSchema,
    tectoroAccessReqSchema,
    tectoroConditionalLaunch,
    EMPTY_OBJ,
    RESTRICT,
    REQUIRED,
    ALLOW,
    NOT_REQUIRED
} from './Schema';
import TabsComponent from '../../../Components/Common/TabsComponent';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const AddTectoroAPP = () => {
    document.title = 'Add App Protection Policy';
    const api = new APIClient();
    const urlconf = useEnv();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const formTypeAndId = getAppProtectionPathParams(window.location.hash);
    const formType = formTypeAndId['formType'];
    const [appsArray, setAppsArray] = useState([]);
    const [selectedTab, setSelectedTab] = useState({ threats: '', access: '' });
    const [applications, setApplications] = useState([]);
    const [applicationsBackup, setApplicationsBackup] = useState([]);
    const [formData, setFormData] = useState({ ...EMPTY_OBJ });
    const [groups, setGroups] = useState([]);

    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};
    const [flatGroups, setFlatGroups] = useState([]);
    const appVersionSchema = useState([
        {
            label: 'Require App Version',
            inputType: 'radios',
            value: 'requireAppVersion',
            helpText: 'Display name of the profile - will be shown on the device',
            radios: [
                { label: 'Require', val: REQUIRED },
                { label: 'Not Required', val: NOT_REQUIRED }
            ]
        },
        {
            label: 'Apps',
            inputType: 'selectAndCards',
            value: 'selectedApps',
            customCol: true,
            helpText: 'Display name of the profile - will be shown on the device',
            options: applications,
            showOn: 'requireAppVersion',
            showValue: REQUIRED
        }
    ]);
    useEffect(() => {
        handlePromise();
    }, []);

    const handlePromise = () => {
        setLoading(true);
        const groupsPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICE_GROUPS, { page: 1, size: 10000 }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp.data);
                    else reject('Groups failed');
                })
                .catch((err) => reject(err));
        });

        const appPromise = new Promise((resolve, reject) => {
            let params = { enterpriseId: enterpriseObj.id, page: 1, size: 2000 };
            api.get(url.APPLICATIONS, params)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Applications failed.');
                })
                .catch((err) => reject('Applications failed.'));
        });

        const policyPromise = new Promise((resolve, reject) => {
            if (formTypeAndId.recordID)
                api.get(url.TT_APP_PROTECTION + '/' + formTypeAndId.recordID, '', domains.MDM_COMMONS)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy failed');
                    })
                    .catch((err) => reject(err));
            else reject('No policy');
        });

        Promise.allSettled([groupsPromise, appPromise, policyPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    let arr = [];
                    if (result[0].value?.length > 0) {
                        result[0].value.forEach((group) => {
                            arr.push(group);
                            group.label = group.name;
                            group.key = group.code;
                            group.value = group.code;
                            group.nameCode = group.name + ' - ' + group.code;
                            if (group.children) {
                                group.children = setChildGroups(group.children);
                                arr = defineFlatGroups(group.children, arr);
                            }
                            group.childsCount = group.children.length ? group.children.length : 0;
                        });
                        setGroups(result[0].value);
                        setFlatGroups(arr);
                    }
                }
                let appPackages = '';
                if (result[2].status === 'fulfilled') {
                    let data = result[2].value;
                    appPackages = data.apps?.map((app) => app.packagename)?.join(',');
                    let polApps =
                        result[1].value?.length > 0 ? result[1].value?.filter((app) => appPackages?.includes(app.packageName)) : [];
                    setAppsArray(polApps);
                    let obj = {
                        name: data.name,
                        appsType: data?.apps?.length === result[1].value?.length ? 'allApps' : 'specificApps',
                        pinRequired: data.config?.pinrequired ? REQUIRED : NOT_REQUIRED,
                        minimumPinLength: [
                            { label: '4', value: 4 },
                            { label: '6', value: 6 },
                            { label: '8', value: 8 }
                        ]?.find((pin) => pin.value === data.config?.pinminlength),
                        maximumPinRetries: data.config?.pinmaxretries?.value,
                        maxAttemptsDropdown: [
                            { label: 'Reset PIN', value: 'reset_pin' },
                            { label: 'Wipe Data', value: 'wipe_data' }
                        ]?.find((action) => action.value === data.config?.pinmaxretries?.action),
                        pinType: data.config?.pintype,
                        copyPasteBlocked: data.config?.copypasteblocked ? RESTRICT : ALLOW,
                        deviceComplianceRequired: data.config?.devicecompliancerequired ? REQUIRED : NOT_REQUIRED,
                        simplePinBlocked: data.config?.simplepinblocked ? RESTRICT : ALLOW,
                        contactSyncBlocked: data.config?.contactsyncblocked ? RESTRICT : ALLOW,
                        fingerprintBlocked: data.config?.fingerprintblocked ? RESTRICT : ALLOW,
                        screenCaptureBlocked: data.config?.screencaptureblocked ? RESTRICT : ALLOW,
                        offlineInput: data.config?.offlinegraceperiod?.value,
                        offlineDropdown: [
                            { label: 'Block Access', value: 'block_access', key: 'periodOfflineBeforeAccessCheck' },
                            { label: 'Wipe Data', value: 'wipe_data', key: 'periodOfflineBeforeWipeIsEnforced' }
                        ]?.find((action) => action.value === data.config?.offlinegraceperiod?.action),
                        minOsVertionInput: data.config?.minimumrequiredosversion?.value,
                        minOsVersionDropdown: [
                            { label: 'Block Access', value: 'block_access', key: 'periodOfflineBeforeAccessCheck' },
                            { label: 'Wipe Data', value: 'wipe_data', key: 'periodOfflineBeforeWipeIsEnforced' }
                        ]?.find((action) => action.value === data.config?.minimumrequiredosversion?.action),
                        minAppVersionInput: data.config?.minimumrequiredappversion?.value,
                        minAppVersionDropdown: [
                            { label: 'Block Access', value: 'block_access', key: 'minimumRequiredAppVersion' },
                            { label: 'Wipe Data', value: 'wipe_data' },
                            { label: 'Warn', value: 'warn', key: 'minimumWarningAppVersion' }
                        ]?.find((action) => action.value === data.config?.minimumrequiredappversion?.action)
                    };
                    setFormData(obj);
                }
                if (result[1].status === 'fulfilled') {
                    setApplications(result[1].value);
                    let notPolApps = result[1].value?.filter((app) => !appPackages?.includes(app.packageName));
                    setApplicationsBackup(notPolApps);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const defineFlatGroups = (groupsArr, arr) => {
        groupsArr.forEach((group) => {
            arr.push(group);
            if (group.children) arr = defineFlatGroups(group.children, arr);
        });
        return arr;
    };

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formData },
        onSubmit: (values) => {
            setLoading(true);
            let obj = {
                name: values.name,
                // "description": "Testing the Create Function",
                config: {
                    pinrequired: values?.pinRequired === REQUIRED,
                    pinminlength: values?.minimumPinLength?.value,
                    pinmaxretries: { value: values?.maximumPinRetries, action: values?.maxAttemptsDropdown?.value },
                    pintype: values?.pinType,
                    copypasteblocked: values?.copyPasteBlocked === RESTRICT,
                    devicecompliancerequired: values?.deviceComplianceRequired === REQUIRED,
                    simplepinblocked: values?.simplePinBlocked === RESTRICT,
                    contactsyncblocked: values?.contactSyncBlocked === RESTRICT,
                    fingerprintblocked: values?.fingerprintBlocked === RESTRICT,
                    screencaptureblocked: values?.screenCaptureBlocked === RESTRICT,
                    offlinegraceperiod: { value: values.offlineInput, action: values?.offlineDropdown?.value },
                    minimumrequiredosversion: { value: values?.minOsVertionInput, action: values?.minOsVersionDropdown?.value },
                    minimumrequiredappversion: { value: values?.minAppVersionInput, action: values?.minAppVersionDropdown?.value }
                },
                apps:
                    values?.appsType === 'allApps'
                        ? applications?.map((app) => {
                              return { name: app.title, packagename: app.packageName };
                          })
                        : appsArray?.map((app) => {
                              return { name: app.title, packagename: app.packageName };
                          })
            };
            let apiService;
            if (formTypeAndId.recordID)
                apiService = api.patch(url.TT_APP_PROTECTION + '/' + formTypeAndId.recordID, obj, false, domains.MDM_COMMONS);
            else apiService = api.create(url.TT_APP_PROTECTION, obj, false, domains.MDM_COMMONS);
            apiService
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(formTypeAndId.recordID ? toastMessages.appProtecitonUpdated : toastMessages.appProtecitonCreated);
                        history.push(`/appprotectionpolicy/${formTypeAndId.domain}`);
                    }
                    setLoading(false);
                })
                .catch((er) => setLoading(false));
        }
    });

    const radioField = {
        inputType: 'radios',
        value: 'appsType',
        code: true,
        radios: [
            { label: 'All Apps', value: 'appsType', val: 'allApps' },
            { label: 'Specific Apps', value: 'appsType', val: 'specificApps' }
        ]
    };

    const handleChange = (option) => {
        const arr = [...appsArray];
        let arrBackup = JSON.parse(JSON.stringify(applicationsBackup));
        let ind = arrBackup.findIndex((app) => app.packageName === option.packageName);
        arrBackup.splice(ind, 1);
        setApplicationsBackup(arrBackup);
        arr.push(option);
        setAppsArray([...arr]);
    };

    const deleteApp = (index) => {
        let arr = [...appsArray];
        let arrBackup = JSON.parse(JSON.stringify(applicationsBackup));
        arrBackup.push(arr[index]);
        setApplicationsBackup(arrBackup);
        arr.splice(index, 1);
        setAppsArray([...arr]);
    };

    const handleAddApp = (app, field) => {
        let vals = validation.values[field.value];
        vals?.push(app);
    };

    const handleVersionSelectChange = (version, app, valInd, valKey, key) => {
        app[key] = version;
        // eslint-disable-next-line no-unsafe-optional-chaining
        let vals = [...validation.values?.[valKey]];
        vals[valInd] = app;
        validation.setValues({ ...validation.values, [valKey]: vals });
    };

    const setCheckedGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.code === group.code) groupVal.checked = !!groupVal.checked;
            if (groupVal.children) groupVal = setCheckedGroup(groupVal, group);
        });
        return groupsObj;
    };

    const changeGroups = (groupsArr, checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj = setCheckedGroup(groupObj, group);
        });
        let fGroups = JSON.parse(JSON.stringify(flatGroups));
        fGroups.forEach((groupVal) => (groupVal.checked = checked.includes(groupVal.code)));
        setFlatGroups(fGroups);
        setGroups(groupsArr);
    };

    const handleDataProtectionContent = (tabID, schema) => {
        return (
            <Row className="mt-3 mb-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {schema[tabID]?.length > 0 ? (
                        schema[tabID]?.map(
                            (field, index) =>
                                (field.showOn ? validation.values[field.showOn] === field.showValue : true) && (
                                    <DynamicComponents key={index} field={field} validation={validation} />
                                )
                        )
                    ) : (
                        <React.Fragment>
                            {appVersionSchema?.map(
                                (versionObj, ind) =>
                                    (versionObj.showOn ? validation.values[versionObj.showOn] === versionObj.showValue : true) && (
                                        <DynamicComponents
                                            key={ind}
                                            field={versionObj}
                                            index={ind}
                                            validation={validation}
                                            handleAddApp={handleAddApp}
                                            handleVersionSelectChange={handleVersionSelectChange}
                                        />
                                    )
                            )}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        );
    };
    const threatsList = [
        { tabID: 'dataTransfer', tabName: 'Data Transfer', tabContent: handleDataProtectionContent('dataTransfer', tectoroDataProSchema) },
        // { tabID: 'appVersion', tabName: 'App Version', tabContent: handleDataProtectionContent('appVersion', tectoroDataProSchema) },
        { tabID: 'compliance', tabName: 'Compliance', tabContent: handleDataProtectionContent('compliance', tectoroDataProSchema) },
        {
            tabID: 'userExperience',
            tabName: 'User Experience',
            tabContent: handleDataProtectionContent('userExperience', tectoroDataProSchema)
        }
    ];

    const accessList = [
        { tabID: 'pinSettings', tabName: 'Pin Settings', tabContent: handleDataProtectionContent('pinSettings', tectoroAccessReqSchema) }
    ];

    const conditionalLaunchList = [
        {
            tabID: 'appConditions',
            tabName: 'App Conditions',
            tabContent: handleDataProtectionContent('appConditions', tectoroConditionalLaunch)
        }
    ];

    const stepperData = [
        {
            title: 'Applications',
            icon: 1,
            disabled: false,
            renderTab: (
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="fw-medium mb-1">Applications :</div>
                        <div className="fs-10 mb-4">Choose the applications for which conditional access policies are enforced</div>
                        <DynamicComponents field={radioField} validation={validation} />
                        {validation.values.appsType === 'specificApps' ? (
                            <div>
                                <div className="fs-13 fw-medium my-1">Specific App</div>
                                <div className="fs-11 text-muted mb-1">App protection policies are enforced on selected applications</div>
                                <Select
                                    value=""
                                    getOptionValue={(option) => option.packageName}
                                    getOptionLabel={(option) => option.title}
                                    options={sortBy(applicationsBackup, 'name')}
                                    placeholder="Select Apps"
                                    onChange={(selectedOption) => handleChange(selectedOption)}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                                <Row className="mt-3">
                                    {appsArray?.length > 0 &&
                                        appsArray.map((app, ind) => (
                                            <Col key={ind} sm={3}>
                                                <Card className="card-height-100 shadow-card mb-4 border-top">
                                                    <CardBody className="position-relative text-center">
                                                        <div
                                                            className="position-absolute top-0 end-0 cursor-pointer"
                                                            onClick={() => deleteApp(ind)}
                                                        >
                                                            <i className="ri-close-line fs-14" />
                                                        </div>
                                                        <img src={app.smallIconUrl} alt="img" height={40} />
                                                        <EllipsisToolTip className="pt-1" id={`app-${ind}`}>
                                                            {app.title}
                                                        </EllipsisToolTip>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                </Row>
                            </div>
                        ) : (
                            <div className="bg-soft-info py-2 px-4 rounded-3 fs-11">
                                <span className="pe-1 fs-13 fw-medium">Note :</span>App protection policies are enforced on in-house app
                                wrapped apps
                            </div>
                        )}
                    </Col>
                </Row>
            )
        },
        {
            title: 'Data Protection',
            icon: 2,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Data Protection :</div>
                    <div className="fs-11 mb-3">
                        Data protection policies ensures that sensitive information remains secure by enforcing specific security measures
                        based on various conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={threatsList}
                            defaultTabID={'dataTransfer'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, threats: tab })}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Access Requirements',
            icon: 3,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Access Requirements :</div>
                    <div className="fs-11 mb-3">
                        Access requirements define the criteria that must be met for users to gain access to applications, ensuring security
                        based on various conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={accessList}
                            defaultTabID={'pinSettings'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, access: tab })}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Conditional Launch',
            icon: 4,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Conditional Launch :</div>
                    <div className="fs-11 mb-3">
                        Conditional Launch enables you to establish criteria that must be met for an app to be accessed, ensuring that only
                        devices meeting your security standards can reach corporate data. You can also specify actions to be taken if a
                        device fails to meet these conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={conditionalLaunchList}
                            defaultTabID={'appConditions'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, access: tab })}
                        />
                    </Row>
                </div>
            )
        }
        /*
         * {
         *     title: 'Assignment',
         *     icon: 5,
         *     disabled: false,
         *     renderTab: (
         *         <div>
         *             {' '}
         *             <div className="fw-medium">Assignment :</div>
         *             <div className="fs-11 mb-3">
         *                 After configuring the policy settings, assign the policy to user groups. You can either select specific groups or
         *                 apply the policy to all users.
         *             </div>
         *             <div className="h-100 ">
         *                 <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
         *                     <div className="mb-3 mb-lg-0 w-100 shadow-card">
         *                         <div className="padding-8 fs-14 border-radius-top-left-4 border-radius-top-right-4">
         *                             <div className="d-flex align-items-center fw-medium fs-12 justify-content-between">
         *                                 <div className="d-flex align-items-center ">
         *                                     <i className="ri-database-2-fill me-2" />
         *                                     <span>All Groups </span>({groups.length})
         *                                 </div>
         *                             </div>
         *                         </div>
         *                         <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
         *                             {groups?.length > 0 ? (
         *                                 <AnimatedTreeComponent
         *                                     groups={groups}
         *                                     flatGroups={flatGroups}
         *                                     changeGroups={changeGroups}
         *                                     showSmallerLabel={true}
         *                                 />
         *                             ) : (
         *                                 <NoRecordsFound />
         *                             )}
         *                         </div>
         *                     </div>
         *                 </div>
         *             </div>
         *         </div>
         *     )
         * }
         */
    ];

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="App Protection"
                        showBack={true}
                        history={history}
                        backLink={`appprotectionpolicy/${formTypeAndId.domain}`}
                        homeLink="Dashboard"
                    />
                    <Card>
                        <CardHeader>
                            <div className="d-flex fw-semibold fs-15 mb-1">
                                App Protection Policy (Tectoro)
                                <span className="d-flex align-items-center ms-1 badge-soft-success p-1 rounded-3 fs-12">
                                    <img src={tectoro} alt="img" height={15} className="pe-1" />
                                    <span>Android</span>
                                </span>
                            </div>
                            <div className="fs-13">
                                App Protection Policies are a set of security controls designed to manage how corporate data is accessed and
                                used within mobile applications. These policies safeguard sensitive information by enforcing PIN
                                requirements, data encryption, compliance checks and restricting data sharing between apps.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name={'name'}
                                            id={'name'}
                                            className={'form-control'}
                                            placeholder={'Enter Configuration Name'}
                                            type={'text'}
                                            maxLength={'30'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['name'] || ''}
                                            invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                        />
                                    </div>
                                ) : validation?.values['name'] ? (
                                    <span className="ps-1">{validation?.values['name']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['name'] && validation.errors['name'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <CustomStepperComponent
                                cancelBtn={true}
                                stepperData={stepperData}
                                routeText={`appprotectionpolicy/${formTypeAndId.domain}`}
                                enableSaveBtn={true}
                                handleSave={validation.handleSubmit}
                                formType={formType}
                                handleDisableSave={!validation?.values['name']}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddTectoroAPP;
