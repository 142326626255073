/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Col, Collapse, Row } from 'reactstrap';

// Import Data
import navdata from '../LayoutMenuData';
// i18n
import { withTranslation } from 'react-i18next';
import { AuthUser, TenantConfig } from '../../Components/Common/Util';

const HorizontalLayout = (props) => {
    const navData = navdata().props.children;
    let menuItems = [];
    let splitMenuItems = [];
    let menuSplitContainer = 7;
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let roles = user?.data?.privileges;
    let tenant = TenantConfig();
    let menuItemsList = [];

    const checkPrivilege = (subItem) => {
        if (subItem.multipleConditions) {
            let found = true;
            subItem.key?.forEach((key) => (found = found && !!tenant?.[key]));
            return found;
        } else if (subItem.key === 'TENANT' || subItem.key === 'USERS' || subItem.key === 'TENANT_MASTER') return true;
        else if (tenant?.[subItem.key]) return true;
        else return false;
    };

    navData?.forEach((value) => {
        if (value.subItems) {
            let subMenu = [];
            value?.subItems?.forEach((subItem) => {
                let checkValid = checkPrivilege(subItem);
                if (checkValid) {
                    if (subItem.reader || subItem.editor) {
                        roles?.forEach((role) => {
                            if (role === subItem.reader || role === subItem.editor) {
                                let item = subMenu.findIndex((menu) => menu.link === subItem.link);
                                if (item === -1) subMenu.push(subItem);
                            }
                        });
                    } else subMenu.push(subItem);
                }
            });
            if (subMenu.length > 0) {
                value.subItems = subMenu;
                menuItemsList.push(value);
            }
        } else {
            if (value.reader || value.editor) {
                let checkValid = checkPrivilege(value);
                if (checkValid)
                    roles?.forEach((role) => {
                        if (role === value.reader || role === value.editor) {
                            let item = menuItemsList.findIndex((menu) => menu.link === value.link);
                            if (item === -1) menuItemsList.push(value);
                        }
                    });
            } else {
                let checkValid = checkPrivilege(value);
                if (checkValid) menuItemsList.push(value);
            }
        }
    });
    menuItemsList.forEach(function (value, key) {
        if (value['isHeader']) {
            menuSplitContainer++;
        }
        if (key >= menuSplitContainer) {
            let val = value;
            val.childItems = value.subItems;
            val.isChildItem = value.subItems ? true : false;
            delete val.subItems;
            splitMenuItems.push(val);
        } else {
            menuItems.push(value);
        }
    });
    /*
     * menuItems.push({
     *     id: 'more',
     *     label: 'More',
     *     icon: 'ri-briefcase-2-fill',
     *     link: '/#',
     *     stateVariables: isMoreMenu,
     *     subItems: splitMenuItems,
     *     click: function (e) {
     *         e.preventDefault();
     *         setIsMoreMenu(!isMoreMenu);
     *     }
     * });
     */

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const initMenu = () => {
            const pathName = process.env.PUBLIC_URL + window.location.href;
            const ul = document.getElementById('navbar-nav');
            const items = ul.getElementsByTagName('a');
            let itemsArray = [...items]; // converts NodeList to Array
            removeActivation(itemsArray);
            let matchingMenuItem = itemsArray.find((x) => {
                let routeArr = x.href?.split('/').filter((v) => v !== '');
                return pathName.includes(routeArr[3]);
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href, props.layoutType]);

    function activateParentDropdown(item) {
        item.classList.add('active');
        let parentCollapseDiv = item.closest('.collapse.menu-dropdown');

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show');
            parentCollapseDiv.parentElement.children[0].classList.add('active');
            parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
            if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
                var parentElementDiv = parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling;
                if (parentElementDiv)
                    if (parentElementDiv.closest('.collapse')) parentElementDiv.closest('.collapse').classList.add('show');
                parentElementDiv.classList.add('active');
                var parentElementSibling = parentElementDiv.parentElement.parentElement.parentElement.previousElementSibling;
                if (parentElementSibling) {
                    parentElementSibling.classList.add('active');
                }
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains('active'));

        actiItems.forEach((item) => {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
                item.setAttribute('aria-expanded', false);
            }
            item.classList.remove('active');
        });
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="navbar-nav" id="navbar-nav">
                    {(menuItems || []).map((item, key) => {
                        return (
                            <React.Fragment key={key}>
                                {/* Main Header */}
                                {!item['isHeader'] ? (
                                    item.subItems ? (
                                        <li className="nav-item">
                                            <Link
                                                onClick={item.click}
                                                className="nav-link menu-link"
                                                to={item.link ? item.link : '/#'}
                                                data-bs-toggle="collapse"
                                            >
                                                <i className={item.icon}></i> <span data-key="t-apps">{props.t(item.label)}</span>
                                            </Link>
                                            <Collapse
                                                className={item.subItems.length > 13 ? 'menu-dropdown mega-dropdown-menu' : 'menu-dropdown'}
                                                isOpen={item.stateVariables}
                                                id="sidebarApps"
                                            >
                                                {/* subItms  */}
                                                {item.subItems.length > 13 ? (
                                                    <React.Fragment>
                                                        <Row>
                                                            {item.subItems &&
                                                                (item.subItems || []).map((subItem, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {index % 2 === 0 ? (
                                                                            <Col lg={4}>
                                                                                <ul className="nav nav-sm flex-column">
                                                                                    <li className="nav-item">
                                                                                        <Link
                                                                                            to={item.subItems[index].link}
                                                                                            className="nav-link"
                                                                                        >
                                                                                            {item.subItems[index].label}
                                                                                        </Link>
                                                                                    </li>
                                                                                </ul>
                                                                            </Col>
                                                                        ) : (
                                                                            <Col lg={4}>
                                                                                <ul className="nav nav-sm flex-column">
                                                                                    <li className="nav-item">
                                                                                        <Link
                                                                                            to={item.subItems[index].link}
                                                                                            className="nav-link"
                                                                                        >
                                                                                            {item.subItems[index].label}
                                                                                        </Link>
                                                                                    </li>
                                                                                </ul>
                                                                            </Col>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                        </Row>
                                                    </React.Fragment>
                                                ) : (
                                                    <ul className="nav nav-sm flex-column test">
                                                        {item.subItems &&
                                                            (item.subItems || []).map((subItem, index) => (
                                                                <React.Fragment key={index}>
                                                                    {!subItem.isChildItem ? (
                                                                        <li className="nav-item width-250">
                                                                            <Link
                                                                                to={subItem.link ? subItem.link : '/#'}
                                                                                className="nav-link w-100"
                                                                            >
                                                                                <i className={subItem.icon}></i> {props.t(subItem.label)}
                                                                            </Link>
                                                                        </li>
                                                                    ) : (
                                                                        <li className="nav-item">
                                                                            <Link
                                                                                onClick={subItem.click}
                                                                                className="nav-link"
                                                                                to="/#"
                                                                                data-bs-toggle="collapse"
                                                                            >
                                                                                <i className={subItem.icon}></i> {props.t(subItem.label)}
                                                                            </Link>
                                                                            <Collapse
                                                                                className="menu-dropdown"
                                                                                isOpen={subItem.stateVariables}
                                                                                id="sidebarEcommerce"
                                                                            >
                                                                                <ul className="nav nav-sm flex-column">
                                                                                    {/* child subItms  */}
                                                                                    {subItem.childItems &&
                                                                                        (subItem.childItems || []).map(
                                                                                            (subChildItem, inde) => (
                                                                                                <React.Fragment key={inde}>
                                                                                                    {!subChildItem.isChildItem ? (
                                                                                                        <li className="nav-item">
                                                                                                            <Link
                                                                                                                to={
                                                                                                                    subChildItem.link
                                                                                                                        ? subChildItem.link
                                                                                                                        : '/#'
                                                                                                                }
                                                                                                                className="nav-link"
                                                                                                            >
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        subChildItem.icon
                                                                                                                    }
                                                                                                                ></i>
                                                                                                                {props.t(
                                                                                                                    subChildItem.label
                                                                                                                )}
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    ) : (
                                                                                                        <li className="nav-item">
                                                                                                            <Link
                                                                                                                onClick={subChildItem.click}
                                                                                                                className="nav-link"
                                                                                                                to="/#"
                                                                                                                data-bs-toggle="collapse"
                                                                                                            >
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        subChildItem.icon
                                                                                                                    }
                                                                                                                ></i>
                                                                                                                {props.t(
                                                                                                                    subChildItem.label
                                                                                                                )}
                                                                                                            </Link>
                                                                                                            <Collapse
                                                                                                                className="menu-dropdown"
                                                                                                                isOpen={
                                                                                                                    subChildItem.stateVariables
                                                                                                                }
                                                                                                                id="sidebarEcommerce"
                                                                                                            >
                                                                                                                <ul className="nav nav-sm flex-column">
                                                                                                                    {/* child subItms  */}
                                                                                                                    {subChildItem.childItems &&
                                                                                                                        (
                                                                                                                            subChildItem.childItems ||
                                                                                                                            []
                                                                                                                        ).map(
                                                                                                                            (
                                                                                                                                subSubChildItem,
                                                                                                                                ind
                                                                                                                            ) => (
                                                                                                                                <li
                                                                                                                                    className="nav-item apex"
                                                                                                                                    key={
                                                                                                                                        ind
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <Link
                                                                                                                                        to={
                                                                                                                                            subSubChildItem.link
                                                                                                                                                ? subSubChildItem.link
                                                                                                                                                : '/#'
                                                                                                                                        }
                                                                                                                                        className="nav-link"
                                                                                                                                    >
                                                                                                                                        <i
                                                                                                                                            className={
                                                                                                                                                subSubChildItem.icon
                                                                                                                                            }
                                                                                                                                        ></i>
                                                                                                                                        {props.t(
                                                                                                                                            subSubChildItem.label
                                                                                                                                        )}
                                                                                                                                    </Link>
                                                                                                                                </li>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                </ul>
                                                                                                            </Collapse>
                                                                                                        </li>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        )}
                                                                                </ul>
                                                                            </Collapse>
                                                                        </li>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                    </ul>
                                                )}
                                            </Collapse>
                                        </li>
                                    ) : (
                                        <li className="nav-item">
                                            <Link className="nav-link menu-link" to={item.link ? item.link : '/#'}>
                                                <i className={item.icon}></i> <span>{props.t(item.label)}</span>
                                            </Link>
                                        </li>
                                    )
                                ) : (
                                    <li className="menu-title">
                                        <span data-key="t-menu">{props.t(item.label)}</span>
                                    </li>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {/* menu Items */}
                </ul>
                {/* <div className="btn-info btn-rounded shadow-md btn btn-icon btn-md p-2">
                    <i className="ri-settings-4-line spinClass fs-22 link-white"></i>
                </div> */}
            </div>
        </React.Fragment>
    );
};

HorizontalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};

export default withRouter(withTranslation()(HorizontalLayout));
