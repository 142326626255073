import toastMessages from '../../common/messages/toastMessages';

export const androidTVSchema = [
    /*
     * {
     *     Header: 'checkbox',
     *     customHeader: true,
     *     headerType: 'checkbox',
     *     customCell: true,
     *     fieldType: 'checkbox',
     *     accessor: 'checked',
     *     thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center',
     *     className: 'width-50 max-width-50',
     *     width: 50,
     *     maxWidth: 50,
     *     minWidth: 50
     * },
     */
    {
        Header: 'Last Contact',
        accessor: 'customField',
        customCell: true,
        fieldType: 'statusDots',
        sortable: false,
        thClass: ' width-100 max-width-100',
        className: 'width-100 max-width-100',
        width: 200,
        maxWidth: 120,
        minWidth: 120,
        onlineStatusTooltip: 'onlineStatusTooltip'
    },
    {
        Header: 'Device ID',
        accessor: 'serial',
        sortable: true,
        customCell: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        fieldType: 'fieldClickable',
        viewRoute: '/atvdevices/view',
        fieldKey: 'serial',
        routeKey: 'serial',
        view: true,
        copy: true,
        copyToastMsg: toastMessages.serialCopied
    },
    {
        Header: 'Brand',
        accessor: 'brand',
        sortable: true,
        width: 10,
        maxWidth: 150,
        minWidth: 150
    },
    {
        Header: 'Model',
        accessor: 'model',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Phone Number',
        accessor: 'phone',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Policy',
        accessor: 'policy',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Group',
        accessor: 'group',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'User',
        accessor: 'user',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    {
        Header: 'Device Type',
        accessor: 'source',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    {
        Header: 'OS Version',
        accessor: 'android_version',
        sortable: true,
        window: 50,
        minWidth: 50,
        maxWidth: 50
    },
    {
        Header: 'Security Patch',
        accessor: 'lastSecurityPatch',
        sortable: false,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        customCell: true,
        fieldType: 'dateFormat',
        key: 'lastSecurityPatch',
        dateOnly: true
    },
    {
        Header: 'Device Status',
        accessor: 'deviceStatus',
        sortable: true,
        customCell: true,
        fieldType: 'badge',
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        lock: true,
        factoryreset: true,
        notes: true,
        map: true
    }
];

export const androidTVAppSchema = [
    {
        Header: 'Name',
        accessor: 'title',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    },
    {
        Header: 'Package Name',
        accessor: 'packagename',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    }
];

export const androidTvAppsSchema = [
    {
        Header: 'App Name',
        accessor: 'appName',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Package',
        accessor: 'packageName',
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        sortable: true
    },
    {
        Header: 'Version',
        accessor: 'appVersion',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];
