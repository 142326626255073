import * as React from 'react';
import ContentLoader from 'react-content-loader';

const random = Math.random() * (1 - 0.7) + 0.7;
export const TwoLineCardSkelton = (props) => (
    <ContentLoader height="120" width="100%" speed={2}>
        <circle cx="50" cy="52" r="25" />

        <rect x="7%" y="30" rx="5" ry="5" width="17%" height="15" />
        <rect x="25%" y="30" rx="5" ry="5" width="17%" height="15" />
        <rect x="43%" y="30" rx="5" ry="5" width="12%" height="15" />
        <rect x="56%" y="30" rx="5" ry="5" width="12%" height="15" />
        <rect x="69%" y="30" rx="5" ry="5" width="12%" height="15" />
        <rect x="82%" y="30" rx="5" ry="5" width="12%" height="15" />

        <rect x="7%" y="60" rx="5" ry="5" width="17%" height="15" />
        <rect x="25%" y="60" rx="5" ry="5" width="17%" height="15" />
        <rect x="43%" y="60" rx="5" ry="5" width="12%" height="15" />
        <rect x="56%" y="60" rx="5" ry="5" width="12%" height="15" />
        <rect x="69%" y="60" rx="5" ry="5" width="12%" height="15" />
        <rect x="82%" y="60" rx="5" ry="5" width="12%" height="15" />

        <rect x="96%" y="30" rx="5" ry="5" width="3%" height="10" />
        <rect x="96%" y="46" rx="5" ry="5" width="3%" height="10" />
        <rect x="96%" y="62" rx="5" ry="5" width="3%" height="10" />
    </ContentLoader>
);

export const CardSkelton = (props) => (
    <ContentLoader height="150" width="100%" speed={0.5} {...props}>
        <rect x="30" y="15" rx="4" ry="4" width="200" height="12" />
        <rect x="260" y="15" rx="6" ry="6" width="200" height="12" />
        <rect x="480" y="15" rx="6" ry="6" width="200" height="12" />
        <rect x="690" y="15" rx="6" ry="6" width="200" height="12" />

        <rect x="30" y="50" rx="4" ry="4" width="200" height="12" />
        <rect x="260" y="50" rx="6" ry="6" width="200" height="12" />
        <rect x="480" y="50" rx="6" ry="6" width="200" height="12" />
        <rect x="690" y="50" rx="6" ry="6" width="200" height="12" />

        <rect x="30" y="85" rx="4" ry="4" width="200" height="12" />
        <rect x="260" y="85" rx="6" ry="6" width="200" height="12" />
        <rect x="480" y="85" rx="6" ry="6" width="200" height="12" />
        <rect x="690" y="85" rx="6" ry="6" width="200" height="12" />

        <rect x="30" y="120" rx="4" ry="4" width="200" height="12" />
        <rect x="260" y="120" rx="6" ry="6" width="200" height="12" />
        <rect x="480" y="120" rx="6" ry="6" width="200" height="12" />
        <rect x="690" y="120" rx="6" ry="6" width="200" height="12" />
    </ContentLoader>
);

export const TableSkelton = (props) => (
    <div className="overflow-auto">
        <ContentLoader width="100%" height={450} speed={2} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
            <rect x="20" y="37" width="2730" height="15" />
            <rect x="20" y="71" width="2730" height="15" />

            <rect x="20" y="52" width="10" height="20" />
            <rect x="47" y="52" width="20" height="19" />
            <rect x="152" y="52" width="36" height="19" />
            <rect x="357" y="52" width="45" height="19" />
            <rect x="487" y="52" width="36" height="19" />
            <rect x="692" y="52" width="39" height="19" />
            <rect x="816" y="52" width="36" height="19" />
            <rect x="937" y="52" width="41" height="19" />
            <rect x="1147" y="52" width="36" height="19" />
            <rect x="1300" y="52" width="41" height="19" />
            <rect x="1460" y="52" width="36" height="19" />
            <rect x="1620" y="52" width="41" height="19" />
            <rect x="1780" y="52" width="36" height="19" />
            <rect x="1940" y="52" width="41" height="19" />
            <rect x="2100" y="52" width="36" height="19" />
            <rect x="2280" y="52" width="41" height="19" />
            <rect x="2480" y="52" width="36" height="19" />
            <rect x="2660" y="52" width="41" height="19" />

            <rect x="27" y="109" rx="4" ry="4" width="20" height="20" />
            <rect x="67" y="110" rx="10" ry="10" width="85" height="19" />
            <rect x="188" y="111" rx="10" ry="10" width="169" height="19" />
            <rect x="402" y="110" rx="10" ry="10" width="85" height="19" />
            <rect x="523" y="111" rx="10" ry="10" width="169" height="19" />
            <rect x="731" y="109" rx="10" ry="10" width="85" height="19" />
            <rect x="852" y="108" rx="10" ry="10" width="85" height="19" />
            <rect x="1180" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="1340" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="1500" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="1660" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="1820" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="1980" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="2140" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="2300" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="2460" y="109" rx="10" ry="10" width="125" height="19" />
            <rect x="2620" y="109" rx="10" ry="10" width="125" height="19" />

            <rect x="26" y="166" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="167" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="168" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="167" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="168" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="166" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="165" rx="10" ry="10" width="85" height="19" />
            <rect x="1180" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="1340" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="1500" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="1660" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="1820" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="1980" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="2140" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="2300" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="2460" y="165" rx="10" ry="10" width="125" height="19" />
            <rect x="2620" y="165" rx="10" ry="10" width="125" height="19" />

            <rect x="26" y="228" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="229" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="230" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="229" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="230" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="228" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="227" rx="10" ry="10" width="85" height="19" />
            <rect x="1180" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="1340" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="1500" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="1660" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="1820" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="1980" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="2140" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="2300" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="2460" y="227" rx="10" ry="10" width="125" height="19" />
            <rect x="2620" y="227" rx="10" ry="10" width="125" height="19" />

            <rect x="26" y="286" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="287" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="288" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="287" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="288" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="286" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="285" rx="10" ry="10" width="85" height="19" />
            <rect x="1180" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="1340" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="1500" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="1660" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="1820" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="1980" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="2140" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="2300" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="2460" y="285" rx="10" ry="10" width="125" height="19" />
            <rect x="2620" y="285" rx="10" ry="10" width="125" height="19" />

            <rect x="26" y="349" rx="4" ry="4" width="20" height="20" />
            <rect x="66" y="350" rx="10" ry="10" width="85" height="19" />
            <rect x="187" y="351" rx="10" ry="10" width="169" height="19" />
            <rect x="401" y="350" rx="10" ry="10" width="85" height="19" />
            <rect x="522" y="351" rx="10" ry="10" width="169" height="19" />
            <rect x="730" y="349" rx="10" ry="10" width="85" height="19" />
            <rect x="851" y="348" rx="10" ry="10" width="85" height="19" />
            <rect x="1180" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="1340" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="1500" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="1660" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="1820" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="1980" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="2140" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="2300" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="2460" y="348" rx="10" ry="10" width="125" height="19" />
            <rect x="2620" y="348" rx="10" ry="10" width="125" height="19" />

            <rect x="978" y="108" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="165" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="227" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="285" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="348" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="468" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="525" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="585" rx="10" ry="10" width="169" height="19" />
            <rect x="977" y="643" rx="10" ry="10" width="169" height="19" />
        </ContentLoader>
    </div>
);

export const CardHeaderBodySkelton = () => (
    <ContentLoader height="380" width="100%" speed={2}>
        <rect x="5%" y="30" rx="4" ry="4" width="15%" height="25" />
        <rect x="75%" y="30" rx="4" ry="4" width="20%" height="25" />

        <rect x="5%" y="90" rx="4" ry="4" width="35%" height="25" />
        <rect x="80%" y="90" rx="4" ry="4" width="15%" height="25" />

        <rect x="5%" y="150" rx="4" ry="4" width="7%" height="38" />
        <rect x="15%" y="155" rx="4" ry="4" width="50%" height="10" />
        <rect x="15%" y="170" rx="4" ry="4" width="50%" height="10" />
        <rect x="85%" y="160" rx="4" ry="4" width="12%" height="10" />

        <rect x="5%" y="210" rx="4" ry="4" width="7%" height="38" />
        <rect x="15%" y="215" rx="4" ry="4" width="50%" height="10" />
        <rect x="15%" y="230" rx="4" ry="4" width="50%" height="10" />
        <rect x="85%" y="220" rx="4" ry="4" width="12%" height="10" />

        <rect x="5%" y="260" rx="4" ry="4" width="7%" height="38" />
        <rect x="15%" y="265" rx="4" ry="4" width="50%" height="10" />
        <rect x="15%" y="280" rx="4" ry="4" width="50%" height="10" />
        <rect x="85%" y="270" rx="4" ry="4" width="12%" height="10" />

        <rect x="5%" y="310" rx="4" ry="4" width="7%" height="38" />
        <rect x="15%" y="315" rx="4" ry="4" width="50%" height="10" />
        <rect x="15%" y="330" rx="4" ry="4" width="50%" height="10" />
        <rect x="85%" y="320" rx="4" ry="4" width="12%" height="10" />
    </ContentLoader>
);
export const AvatarTwoLineSkelton = () => (
    <ContentLoader height="100%" width="100%">
        <circle cx="10%" cy="30" r="20" />
        <rect x="23%" y="18" rx="3" ry="3" width="70%" height="7" />
        <rect x="23%" y="38" rx="3" ry="3" width="70%" height="6" />

        <circle cx="10%" cy="95" r="20" />
        <rect x="23%" y="83" rx="3" ry="3" width="70%" height="7" />
        <rect x="23%" y="103" rx="3" ry="3" width="70%" height="6" />
    </ContentLoader>
);

export const CommonLoadingSkelton = () => (
    <ContentLoader height="100%" width="100%">
        <circle cx="40%" cy="50%" r="20" />
        <circle cx="50%" cy="50%" r="20" />
        <circle cx="60%" cy="50%" r="20" />
    </ContentLoader>
);

let rows = 2;
let columns = 6;
let coverHeight = 250;
let coverWidth = 230;
let padding = 20;
let speed = 0.5;

let coverHeightWithPadding = coverHeight + padding;
let coverWidthWithPadding = coverWidth + padding;
let initial = 100;
let covers = Array(columns * rows).fill(1);

export const MultiCardSkelton = (props) => (
    <ContentLoader
        speed={speed}
        width={columns * coverWidthWithPadding}
        height={rows * coverHeightWithPadding}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="20" rx="0" ry="0" width={columns * coverWidthWithPadding - padding} height="60" />

        {covers.map((g, i) => {
            let vy = Math.floor(i / columns) * coverHeightWithPadding + initial;
            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding);
            return <rect key={i} x={vx} y={vy} rx="0" ry="0" width={coverWidth} height={coverHeight} />;
        })}
    </ContentLoader>
);

export const PieChartSkelton = (props) => (
    <ContentLoader speed={speed} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
        <circle cx="150" cy="110" r="100" />
        <rect x="20" y="300" rx="0" ry="0" width="7" height="7" />
        <rect x="40" y="300" rx="0" ry="0" width="60" height="7" />

        <rect x="20" y="320" rx="0" ry="0" width="7" height="7" />
        <rect x="40" y="320" rx="0" ry="0" width="60" height="7" />

        <rect x="20" y="340" rx="0" ry="0" width="7" height="7" />
        <rect x="40" y="340" rx="0" ry="0" width="60" height="7" />

        <rect x="20" y="360" rx="0" ry="0" width="7" height="7" />
        <rect x="40" y="360" rx="0" ry="0" width="60" height="7" />

        <rect x="200" y="360" rx="0" ry="0" width="7" height="7" />
        <rect x="220" y="360" rx="0" ry="0" width="60" height="7" />

        <rect x="200" y="340" rx="0" ry="0" width="7" height="7" />
        <rect x="220" y="340" rx="0" ry="0" width="60" height="7" />

        <rect x="200" y="320" rx="0" ry="0" width="7" height="7" />
        <rect x="220" y="320" rx="0" ry="0" width="60" height="7" />

        <rect x="200" y="300" rx="0" ry="0" width="7" height="7" />
        <rect x="220" y="300" rx="0" ry="0" width="60" height="7" />
    </ContentLoader>
);

export const BarChartSkelton = (props) => (
    <ContentLoader height={400} width={500} speed={speed} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
        <rect x="100" y="130" rx="0" ry="0" width="1" height="170" />
        <rect x="100" y="300" rx="0" ry="0" width="360" height="1" />

        <rect x="130" y="200" rx="0" ry="0" width="35" height="100" />
        <rect x="190" y="250" rx="0" ry="0" width="35" height="50" />

        <rect x="250" y="230" rx="0" ry="0" width="35" height="70" />
        <rect x="310" y="110" rx="0" ry="0" width="35" height="190" />
        <rect x="370" y="140" rx="0" ry="0" width="35" height="160" />
    </ContentLoader>
);
