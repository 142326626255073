import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { TenantConfig } from '../../Components/Common/Util';
import { useEnv } from '../../envContext';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';

const AuthSlider = () => {
    const tenantConfigData = TenantConfig();
    const urlconf = useEnv();
    let tenantData = tenant?.[urlconf.REACT_APP_COMPANY_ID];

    useEffect(() => {
        document.getElementById('auth-slider').style.backgroundImage = `url(${
            tenantConfigData?.COMPANY_SLIDE_BG1 ? tenantConfigData?.COMPANY_SLIDE_BG1 : tenantData?.COMPANY_SLIDE_BG1
        })`;
    }, []);

    return (
        <React.Fragment>
            <Col lg={8} className={`${urlconf.REACT_APP_COMPANY_ID === 'TECTORO' ? 'padding-right-10' : 'border-end border-2'}`}>
                <div className="p-lg-12 auth-one-bg p-6 h-100" id="auth-slider">
                    {/* <div className="bg-overlay"></div> */}
                </div>
            </Col>
        </React.Fragment>
    );
};

export default AuthSlider;
