import React from 'react';
import { Input } from 'reactstrap';

export const Filter = ({ column }) => {
    // eslint-disable-next-line no-inline-styles/no-inline-styles
    return <div style={{ marginTop: 5 }}>{column.canFilter && column.render('Filter')}</div>;
};

export const DefaultColumnFilter = ({
    column: {
        Header,
        filterValue,
        setFilter,
        preFilteredRows: { length }
    }
}) => {
    return <Input value={filterValue || ''} onChange={(e) => setFilter(e.target.value || undefined)} placeholder={`Search by ${Header}`} />;
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const options = React.useMemo(() => {
        const ops = new Set();
        preFilteredRows.forEach((row) => {
            ops.add(row.values[id]);
        });
        return [...ops.values()];
    }, [id, preFilteredRows]);

    return (
        <select
            id="custom-select"
            className="form-select"
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};
