/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import TableContainer from '../../../Components/Common/TableContainer';
import { Tooltip } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { policyDetailsSchema } from '../TableSchema';

const ViewPolicyDetailsBody = (props) => {
    let api = new APIClient();
    const urlconf = useEnv();
    const [series, setSeries] = useState([]);
    const [groupSearch, setGroupSearch] = useState('');
    const [policies, setPolicies] = useState([...props.policies]);
    const [groups, setGroups] = useState([...props.devicesOnGroups]);
    const [groupsBackup, setGroupsBackup] = useState([...props.devicesOnGroups]);
    const [loading, setLoading] = useState(false);
    const [policiesBackup, setPoliciesBackup] = useState([...props.policies]);
    const [policySearch, setPolicySearch] = useState({ name: 'All Policies', code: '', nameCode: 'All Policies' });
    const [installStatusCounts, setInstallStatusCounts] = useState([
        { label: 'Latest', value: props.installStatusCounts?.latestCount },
        { label: 'Not Latest', value: props.installStatusCounts?.notLatestCount },
        { label: 'Not Installed', value: props.installStatusCounts?.notInstalledCount }
    ]);

    useEffect(() => {
        if (installStatusCounts?.length > 0) {
            let installCounts = installStatusCounts?.map((count) => count.value);
            setSeries([
                {
                    name: 'Install Status',
                    data: [...installCounts]
                }
            ]);
        }
    }, [installStatusCounts]);

    useEffect(() => {
        setInstallStatusCounts([
            { label: 'Latest', value: props.installStatusCounts?.latestCount },
            { label: 'Not Latest', value: props.installStatusCounts?.notLatestCount },
            { label: 'Not Installed', value: props.installStatusCounts?.notInstalledCount }
        ]);
    }, [props.installStatusCounts]);

    useEffect(() => {
        if (props.policies?.length > 0) {
            setPolicies(props.policies);
            setPoliciesBackup(props.policies);
        }
    }, [props.policies]);

    useEffect(() => {
        if (props.devicesOnGroups?.length > 0) {
            setGroups(props.devicesOnGroups);
            setGroupsBackup([...props.devicesOnGroups]);
        }
    }, [props.devicesOnGroups]);

    const handleSearch = (group, policy) => {
        let searchArr = policy && policy?.code === '' ? groupsBackup : policiesBackup;
        let policiesArr = JSON.parse(JSON.stringify(searchArr));
        // eslint-disable-next-line array-callback-return
        policiesArr = policiesArr.filter((policyObj) => {
            if (group && policy.code) {
                return policyObj.group?.toLowerCase()?.includes(group.toLowerCase()) && policyObj?.policy === policy?.code;
            } else if (group) {
                return policyObj.group?.toLowerCase()?.includes(group.toLowerCase());
            } else if (policy.code) {
                return policyObj?.policy === policy?.code;
            } else {
                return policyObj;
            }
        });
        if (policy && policy?.code === '') setGroups(policy?.code === '' && (!group || group === '') ? groupsBackup : policiesArr);
        else setPolicies(policy?.code === '' && (!group || group === '') ? policiesBackup : policiesArr);
    };

    const onGroupSearch = (e) => {
        setGroupSearch(e.target.value);
        handleSearch(e.target.value, policySearch);
    };

    const handlePolicySearch = (e) => {
        setPolicySearch(e);
        // props.handlePolicySearch(e);
        setLoading(true);
        props.setOffCanvasModalLoader(true);
        api.create(url.ES_DEVICES, {
            filter: { policy: [e.code] },
            aggs: {
                app: {
                    field: 'apps.' + props.countViewRow.packageName?.replaceAll('.', '_'),
                    type: 'EXISTS',
                    subAgg: {
                        policies: {
                            field: 'policyApplied',
                            type: 'BUCKETS',
                            subAgg: {
                                groups: {
                                    field: 'group',
                                    type: 'BUCKETS',
                                    subAgg: {
                                        installStatus: {
                                            field: 'apps.' + props.countViewRow.packageName?.replaceAll('.', '_'),
                                            type: 'BUCKETS'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    let policiesArr = [];
                    let latestCount = 0;
                    let notLatestCount = 0;
                    let notInstalledCount = 0;
                    let devicesOnPolicyArr = [];
                    if (resp?.data?.aggs?.app?.policies) {
                        Object.entries(resp?.data?.aggs?.app?.policies).forEach((policy) => {
                            let devicesOnPolicyObj = {};
                            let obj = {
                                totalDevices: 0
                            };
                            let policyObj = JSON.parse(JSON.stringify(policy));
                            obj.policy = policyObj[0] ? policyObj[0] : '—';
                            devicesOnPolicyObj.policy = policyObj[0] ? policyObj[0] : '—';
                            devicesOnPolicyObj.count = 0;
                            if (policyObj?.[1]?.groups) {
                                Object.entries(policyObj[1].groups).forEach((group) => {
                                    if (group[0]?.toLowerCase()?.includes(groupSearch?.toLowerCase())) {
                                        let groupObj = {};
                                        latestCount =
                                            latestCount + Number(group?.[1]?.installStatus?.[3] ? group?.[1]?.installStatus?.[3] : 0);
                                        notLatestCount =
                                            notLatestCount + Number(group?.[1]?.installStatus?.[2] ? group?.[1]?.installStatus?.[2] : 0);
                                        notInstalledCount =
                                            notInstalledCount + Number(group?.[1]?.installStatus?.[1] ? group?.[1]?.installStatus?.[1] : 0);
                                        devicesOnPolicyObj.count =
                                            Number(group?.[1]?.installStatus?.[3] ? group?.[1]?.installStatus?.[3] : 0) +
                                            Number(group?.[1]?.installStatus?.[2] ? group?.[1]?.installStatus?.[2] : 0) +
                                            Number(group?.[1]?.installStatus?.[1] ? group?.[1]?.installStatus?.[1] : 0);
                                        groupObj.group = group[0] ? group[0] : '—';
                                        groupObj.latest = group?.[1]?.installStatus?.[3] ? group?.[1]?.installStatus?.[3] : 0;
                                        groupObj.notLatest = group?.[1]?.installStatus?.[2] ? group?.[1]?.installStatus?.[2] : 0;
                                        groupObj.notInstalled = group?.[1]?.installStatus?.[1] ? group?.[1]?.installStatus?.[1] : 0;
                                        if (group?.[1]?.installStatus) {
                                            Object.entries(group?.[1]?.installStatus).forEach((installStatus) => {
                                                groupObj.totalDevices =
                                                    (groupObj.totalDevices ? Number(groupObj.totalDevices) : 0) + Number(installStatus[1]);
                                            });
                                        }
                                        obj = { ...obj, ...groupObj };
                                        policiesArr.push(obj);
                                        devicesOnPolicyArr.push(obj);
                                    }
                                });
                            }
                        });
                        setPolicies(devicesOnPolicyArr);
                        setPoliciesBackup(devicesOnPolicyArr);
                    }
                }
                props.setOffCanvasModalLoader(false);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                props.setOffCanvasModalLoader(false);
            });
        handleSearch(groupSearch, e);
    };

    const handleDownload = (row, status) => {
        props.handleDownload(row, status, groupSearch, policySearch);
    };

    const copyToClipboard = async (val) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(toastMessages.packageNameCopied);
        });
    };

    return (
        <React.Fragment>
            <Row className="mb-3"></Row>
            <Row className="w-100 mb-3">
                <Col sm="7">
                    <Row>
                        <Col sm="auto" className="d-flex align-items-center">
                            <div className="d-flex align-items-center border border-1 border-gray p-2 rounded">
                                <img src={props.countViewRow?.iconUrl} alt={props.countViewRow?.title} width={90} height={90} />
                            </div>
                        </Col>
                        <Col>
                            <div className="mb-1">{props.countViewRow?.title}</div>
                            <div className=" mb-1 mt-3 line-break-anywhere">
                                {props.countViewRow?.packageName}
                                <span className="ms-1">
                                    <i
                                        id={'copy'}
                                        onClick={(e) => copyToClipboard(props.countViewRow?.packageName)}
                                        className="ri-file-copy-line fs-13 cursor-pointer link-primary"
                                    ></i>
                                    {Tooltip('copy', 'Copy')}
                                </span>
                            </div>
                            <div className="mt-3">
                                Version : <span className="fw-semibold">{props.countViewRow?.appVersionNumber}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="5" className="pt-2 pb-2 border-start border-1 border-muted">
                    {/* <div dir="ltr">
                        <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="bar" height={250} />
                    </div> */}
                    <Col sm="auto" className="ps-4">
                        <div className="d-flex align-items-center h-100">
                            <div className=" text-truncate">
                                <div className="d-flex align-items-center gap-1">
                                    <i className="ri-checkbox-blank-circle-fill link-success fs-18" />
                                    Latest - {props.installStatusCounts?.latestCount}
                                </div>
                                <div className="d-flex align-items-center gap-1 margin-top-10">
                                    <i className="ri-checkbox-blank-circle-fill link-warning fs-18" />
                                    Not Latest - {props.installStatusCounts?.notLatestCount}
                                </div>
                                <div className="d-flex align-items-center gap-1 margin-top-10">
                                    <i className="ri-checkbox-blank-circle-fill link-danger fs-18" />
                                    Not Installed - {props.installStatusCounts?.notInstalledCount}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>
            <Row>
                {/* <Col sm={3}>
                    <div className="d-flex align-items-center h-100">
                        <div className=" bg-424C8B08 p-4 br-3 border-details">
                            <div className="text-truncate">
                                <div className="d-flex align-items-center">
                                    <div className="text-muted fw-medium">All Policies - </div>
                                    <span className="fw-semibold ms-1">{uniqBy(policies, 'policy')?.length}</span>
                                </div>
                                <div className="d-flex align-items-center margin-top-10">
                                    <div className="text-muted fw-medium">All Groups - </div>
                                    <span className="fw-semibold ms-1">{uniqBy(policies, 'group')?.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col> */}
            </Row>
            <div className="d-flex align-items-center mt-5 mb-3">
                <Row>
                    <Col sm="auto">
                        <div className="width-200">
                            <div className="form-icon">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    value={groupSearch || ''}
                                    onChange={(e) => onGroupSearch(e)}
                                    className="form-control form-control-icon"
                                    id="iconInput"
                                    placeholder="Search Group Name"
                                />
                                <i className="ri-search-line link-info" />
                            </div>
                        </div>
                    </Col>
                    <Col sm="auto">
                        <div className="d-flex">
                            <Select
                                getOptionValue={(option) => option.code}
                                getOptionLabel={(option) => option.nameCode}
                                id="policy"
                                name="policy"
                                options={props.policyOptions}
                                placeholder="Select by Policy"
                                onChange={(e) => handlePolicySearch(e)}
                                value={policySearch || ''}
                                isSearchable={true}
                                className="dropdown-filter-picker width-200 min-width-200 max-width-200"
                                classNamePrefix={'custom-select'}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="ms-3">
                    <Button type="button" id="export_all" color="primary" className="padding-x-9" onClick={() => handleDownload()}>
                        <span className="d-flex align-items-center">
                            <i className="ri-upload-2-line fs-14 align-bottom me-1"></i> Export
                        </span>
                    </Button>
                    {Tooltip('export_all', 'Export All as CSV File')}
                </div>
            </div>
            <TableContainer
                loading={loading}
                columns={policyDetailsSchema}
                data={policySearch && policySearch?.code === '' ? groups : policies}
                handleDownload={handleDownload}
                reader={priviliges.POLICY_READER}
                editor={priviliges.POLICY_EDITOR}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
        </React.Fragment>
    );
};

export default ViewPolicyDetailsBody;
