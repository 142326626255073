const layoutTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
    TWOCOLUMN: 'twocolumn'
};

const layoutModeTypes = {
    LIGHTMODE: 'light',
    DARKMODE: 'dark'
};

const leftSidebarTypes = {
    LIGHT: 'light',
    DARK: 'dark',
    GRADIENT: 'gradient',
    GRADIENT_2: 'gradient-2',
    GRADIENT_3: 'gradient-3',
    GRADIENT_4: 'gradient-4'
};

const layoutWidthTypes = {
    FLUID: 'lg',
    BOXED: 'boxed'
};

const layoutPositionTypes = {
    FIXED: 'fixed',
    SCROLLABLE: 'scrollable'
};

const topbarThemeTypes = {
    LIGHT: 'light',
    DARK: 'dark'
};

const leftsidbarSizeTypes = {
    DEFAULT: 'lg',
    COMPACT: 'md',
    SMALLICON: 'sm',
    SMALLHOVER: 'sm-hover'
};

const leftSidebarViewTypes = {
    DEFAULT: 'default',
    DETACHED: 'detached'
};

const leftSidebarImageTypes = {
    NONE: 'none',
    IMG1: 'img-1',
    IMG2: 'img-2',
    IMG3: 'img-3',
    IMG4: 'img-4'
};

export {
    layoutTypes,
    layoutModeTypes,
    leftSidebarTypes,
    layoutWidthTypes,
    layoutPositionTypes,
    topbarThemeTypes,
    leftsidbarSizeTypes,
    leftSidebarViewTypes,
    leftSidebarImageTypes
};
