import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { Tooltip, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import CompletedRecords from '../../../assets/images/completedRecords.svg';
import FailedRecords from '../../../assets/images/failedRecords.svg';
import NewRecords from '../../../assets/images/newRecords.svg';
import TotalRecords from '../../../assets/images/totalRecords.svg';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { bulkUploadViewSchema } from '../../AndroidEnterprise/TableSchema';

const ATVViewBulkUploads = () => {
    document.title = 'View TV Bulk Uploads';
    let api = new APIClient();
    let history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [counts, setCounts] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [purpose, setPurpose] = useState('');
    const [uploadItem, setUploadItem] = useState('');
    const [refreshTime, setRefreshTime] = useState(0);
    const config = useEnv();

    let hashArr = window.location.hash.split('/');
    let id = hashArr[hashArr?.length - 1];

    useEffect(() => {
        setLoading(true);
        getBulkUploadWidgetCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBulkUploadWidgetCount = () => {
        let endpoint = url.BULK_ACTION + '/' + id + '/countbystatus';
        api.get(endpoint)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status?.toLowerCase() === 'success') {
                    let countObj = {};
                    resp?.data?.forEach((ele) => {
                        if (ele.key1?.toLowerCase() === 'failed' || ele.key1?.toLowerCase() === 'fail')
                            countObj = { ...countObj, failure: ele.count };
                        else if (ele.key1.toLowerCase() === 'done') countObj = { ...countObj, success: ele.count };
                        else if (ele.key1.toLowerCase() === 'new') countObj = { ...countObj, new: ele.count };
                    });
                    setCounts({ ...countObj, total: resp?.data?.reduce((partialSum, a) => partialSum + a.count, 0) });
                }
                getBulkUploads({ page: 1, size: 1, id: id, type: 'TVx' });
            })
            .catch((err) => setLoading(false));
    };

    const getBulkUpload = (params) => {
        // let endpoint = url.BULK_ACTION + '/' + id + '/items?page=1&size=10000';
        api.get(url.BULK_ACTION + '/' + id + '/items', params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((ele) => {
                        ele.createdBy = ele.createdBy ? ele.createdBy : 'Tectoro';
                        ele.createdDate = getDate(ele.createdTime);
                        ele.lastModifiedDate = getDate(ele.lastmodifiedTime);
                        ele.remarks = ele.remarks ? ele.remarks : '—';
                        ele.status =
                            ele.status?.toLowerCase() === 'done' ? 'COMPLETED' : ele.status?.toLowerCase() === 'new' ? 'NEW' : 'FAILED';
                        if (ele.status?.toLowerCase() === 'completed') ele.badgeClass = 'success';
                        else if (ele.status?.toLowerCase() === 'failed') ele.badgeClass = 'danger';
                        else ele.badgeClass = 'primary';
                    });
                    setData(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => setLoading(false));
    };

    const getBulkUploads = (params) => {
        setLoading(true);
        api.get(url.BULK_ACTION, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((dataObj) => {
                        dataObj.createdDate = getDate(dataObj.createdTime);
                    });
                    setUploadItem(resp.data?.[0] ? resp.data?.[0] : '');
                    setPurpose(resp.data?.[0]?.purpose ? resp.data?.[0]?.purpose : '');
                }
                getBulkUpload(searchParams);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const [allWidgetsData, setAllWidgetsData] = useState([
        {
            id: 1,
            label: 'Total',
            val: 'TOTAL',
            counter: 'total',
            icon: TotalRecords,
            selected: false,
            borderColor: 'secondary',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 2,
            label: 'New',
            val: 'NEW',
            counter: 'new',
            icon: NewRecords,
            borderColor: 'grey',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 3,
            label: 'Completed',
            val: 'DONE',
            counter: 'success',
            icon: CompletedRecords,
            borderColor: 'green-1',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 4,
            label: 'Failed',
            val: 'FAIL',
            counter: 'failure',
            icon: FailedRecords,
            borderColor: 'danger',
            decimals: 0,
            suffix: '',
            prefix: ''
        }
    ]);

    const onPageChange = (pgNumber, pgSize) => {
        setLoading(true);
        let params = { ...searchParams, page: pgNumber, size: pgSize };
        setPageNumber(pgNumber);
        setSearchParams(params);
        getBulkUpload(params);
    };

    const handleGlobalSearch = (val) => {
        setLoading(true);
        setPageNumber(1);
        let params = { ...searchParams, page: 1, size: 10, serialNo: val };
        getBulkUpload(params);
    };

    const handleCardClick = (item) => {
        let widgetData = [...allWidgetsData];
        widgetData.forEach((widget) => {
            if (item.label === widget.label) widget.selected = !widget.selected;
            else widget.selected = false;
        });
        let filterData = widgetData.filter((widget) => widget.selected)?.map((widget) => widget.val);
        filterData = filterData.toString();
        let params = { ...searchParams, status: filterData };
        if (filterData === 'TOTAL') {
            delete params.status;
        }
        setSearchParams(params);
        getBulkUpload(params);
        setAllWidgetsData(widgetData);
    };

    const handleFilter = () => {
        /*
         * if (refreshTime === 0) {
         *     setRefreshTime(60000);
         * } else {
         *     setShowTimerModal(true);
         *     return;
         * }
         */
        let timeDiff = new Date() - refreshTime;
        if (refreshTime && timeDiff < 5000) {
            let timeAfterOneMinute = refreshTime.getTime() + 5000;
            var date = new Date(timeAfterOneMinute);
            var hours = date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            var formattedTime = hours + ':' + minutes + ':' + seconds;
            toast.error(toastMessages.refreshTime);
            return;
        } else setRefreshTime(new Date());
        getBulkUploadWidgetCount();
    };

    const handleDownload = (event) => {
        event.stopPropagation();
        setLoading(true);
        let urlVal = url.BULK_ACTION + '/' + id + '/items/download';
        if (searchParams?.status?.length > 0) urlVal = urlVal + '?status=' + searchParams.status;
        api.create(urlVal)
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download =
                    (uploadItem?.filName ? uploadItem?.filName?.replace('.csv', '') : 'Device Bulk Action') +
                    ' ' +
                    uploadItem?.createdDate?.replaceAll(',', '') +
                    '.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Device Bulk Request View"
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="atvbulkrequests"
                    />
                    <Row>
                        {(allWidgetsData || []).map((item, key) => (
                            <Col xl={3} md={6} key={key}>
                                <Card
                                    className={` border-${item.borderColor} cursor-pointer ${
                                        item.selected ? 'border border-1' : 'border-start border-2'
                                    }`}
                                    onClick={() => handleCardClick(item)}
                                >
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={item.icon} alt="" />
                                                <div className="flex-grow-1 overflow-hidden ms-3">
                                                    <p
                                                        className="text-uppercase fw-semibold text-muted text-truncate mb-1"
                                                        id={`widget-${key}`}
                                                    >
                                                        {item.label}
                                                    </p>
                                                    <div className="d-flex align-items-center mb-1">
                                                        <h4 className="fs-16 flex-grow-1 text-truncate text-info mb-0">
                                                            <span className="counter-value me-1" data-target="825" id={`count-${key}`}>
                                                                <CountUp
                                                                    start={0}
                                                                    prefix={item.prefix}
                                                                    suffix={item.suffix}
                                                                    separator={item.separator}
                                                                    end={counts[item.counter]}
                                                                    decimals={item.decimals}
                                                                    duration={4}
                                                                />
                                                                {item.counter?.length > 6 && Tooltip(`count-${key}`, item.counter)}
                                                            </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {item.selected && (
                                                <div>
                                                    <span
                                                        className={'cursor-pointer position-relative lh-sm badge badge-soft-primary'}
                                                        onClick={(e) => handleDownload(e, item)}
                                                    >
                                                        Export
                                                    </span>
                                                </div>
                                            )} */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <TableContainer
                        refresh2={true}
                        refreshTime={refreshTime}
                        handleFilter={handleFilter}
                        data={data}
                        isGlobalFilter={true}
                        columns={bulkUploadViewSchema}
                        editor={priviliges.DEVICE_EDITOR}
                        reader={priviliges.DEVICE_READER}
                        maxPage={true}
                        pageNum={pageNumber}
                        customPageSize={10}
                        handleGlobalSearch={handleGlobalSearch}
                        onPageChange={onPageChange}
                        totalRecords={totalRecords}
                        searchFilter2={true}
                        exportLogs={true}
                        isExportBtnDisabled={data?.length > 0 ? false : true}
                        handleExportLogs={handleDownload}
                        searchPlaceHolder="Serial Number"
                        className="custom-header-css"
                        tableHeader={`Purpose : ${purpose}`}
                        hideDisplayTotalCount={true}
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ATVViewBulkUploads;
