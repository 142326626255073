import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, convertTextCase, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { errorDevicesSchema } from '../TableSchema';

const ErrorDevices = () => {
    document.title = 'Call Logs';
    const api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [errorDevices, setErrorDevices] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const config = useEnv();

    useEffect(() => {
        serviceCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGlobalSearch = (val) => {
        let params = {};
        if (val) params = { ...searchParams, serial: val?.trim() };
        else params = { ...searchParams, serial: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        serviceCall({ ...params, page: 1, size: 10 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (params.fromDate && params.toDate) serviceCall({ ...params, page: 1, size: 10 });
        else if (val?.length === 0) serviceCall({ ...params, page: 1, size: 10 });
    };

    const serviceCall = (paramsObj) => {
        setLoading(true);
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { ...searchParams, page: pageNumber, size: pageSize };
        api.get(url.TV_ERRORS, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    setTotalRecords(resp.data.totalRecords);
                    resp.data.forEach((ele) => {
                        ele.location = ele.isLocationDisabled ? 'Disabled' : 'Enabled';
                        ele.storage = ele.hasLowStorageSpace ? 'Low' : 'Good';
                        ele.showTooltip = ele.launcherStatus !== 'UPDATED';
                        ele.launcherStatus = convertTextCase(ele.launcherStatus);
                        ele.infoTooltip = `Launcher version: ${ele.launcherVersion} - Installed version: ${ele.launcherInstallVersion}`;
                        ele.modifiedDate = ele.lastModifiedDate ? getDate(ele.lastModifiedDate) : '—';
                    });
                    setErrorDevices(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const onPageChange = (pgNumber, pgSize) => {
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        serviceCall({ ...searchParams, page: pgNumber, size: pgSize });
    };

    const handleExportLogs = () => {
        setLoading(true);
        api.create(
            url.CALLLOGS_DOWNLOAD +
                (searchParams?.serial || searchParams?.fromDate || searchParams?.toDate ? '?' : '') +
                (searchParams?.serial ? 'serial=' + searchParams?.serial : '') +
                (searchParams?.serial && searchParams?.fromDate
                    ? '&fromDate=' + searchParams?.fromDate
                    : searchParams?.fromDate
                    ? 'fromDate=' + searchParams?.fromDate
                    : '') +
                ((searchParams?.serial || searchParams?.fromDate) && searchParams?.toDate
                    ? '&toDate=' + searchParams?.toDate
                    : searchParams?.toDate
                    ? 'toDate=' + searchParams?.toDate
                    : '')
        )
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = 'CallLog.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb pageTitle={'Error Devices'} history={history} homeLink="Dashboard" showBack={true} backLink="atvreports" />
                    <TableContainer
                        searchFilter2={true}
                        dateRangeFilter2={true}
                        datePlaceHolder={'Date Range'}
                        // searchFiltersButton={true}
                        handleExportLogs={handleExportLogs}
                        // exportLogs={true}
                        searchPlaceHolder={'Serial'}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand logs only)."
                        tableHeader={'All Error Devices'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={errorDevicesSchema}
                        data={errorDevices || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ErrorDevices;
