export const usersSchema = [
    /*
     * {
     *     Header: 'User ID',
     *     accessor: 'id',
     *     sortable: false,
     *     width: '10px'
     * },
     */
    {
        Header: 'Name',
        accessor: 'name',
        sortable: false,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'User Name',
        accessor: 'username',
        sortable: false,
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'Email ID',
        accessor: 'email',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        sortable: false
    },
    {
        Header: 'Role',
        accessor: 'roleName',
        sortable: false,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        sortable: false
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        sortable: false,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'switch',
        width: '30px',
        maxWidth: '30px'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        rowEdit: true,
        width: '10px'
    }
];

export const privilegesSchema = [
    {
        Header: 'Privilege Roles',
        accessor: 'label',
        sortable: false,
        minWidth: 100,
        maxWidth: 100,
        width: 100
    },
    {
        Header: 'Read',
        customHeader: true,
        showHeader: true,
        headerCheckAll: 'read',
        headerType: 'checkbox',
        customCell: true,
        disable: 'readerPriv',
        fieldType: 'checkbox',
        accessor: 'reader',
        checkedAccessor: 'reader',
        thClass: 'width-50 max-width-50 min-width-50',
        className: 'width-50 max-width-50',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    {
        Header: 'Write',
        customHeader: true,
        showHeader: true,
        headerCheckAll: 'write',
        headerType: 'checkbox',
        customCell: true,
        fieldType: 'checkbox',
        disable: 'editorPriv',
        accessor: 'editor',
        checkedAccessor: 'editor',
        thClass: 'width-50 max-width-50 min-width-50',
        className: 'width-50 max-width-50',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    {
        Header: 'All',
        customHeader: true,
        showHeader: true,
        headerCheckAll: 'all',
        headerType: 'checkbox',
        customCell: true,
        fieldType: 'checkbox',
        accessor: 'all',
        checkedAccessor: 'all',
        thClass: 'width-50 max-width-50 min-width-50',
        className: 'width-50 max-width-50',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
    /*
     * {
     *     Header: 'Privilege Description',
     *     accessor: 'email',
     *     sortable: false
     * }
     */
];

export const priviligesSchema = [
    {
        Header: 'S. No.',
        accessor: 'sno',
        sortable: false
    },
    {
        Header: 'Privilege Roles',
        accessor: 'label',
        sortable: false
    }
];

export const tenantSchema = [
    {
        Header: 'Account Code',
        accessor: 'code',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Account Name',
        accessor: 'name',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        sortable: false,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true,
        // rowDelete: true,
        width: '10px'
    }
];

export const rolesSchema = [
    {
        Header: 'Role Name',
        accessor: 'name',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Privileges',
        accessor: 'privilegesLength',
        customCell: true,
        fieldType: 'priviliges',
        sortable: false,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        sortable: false,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this role, as it is already applied to an user.',
        fade: 'doNotDelete',
        width: '10px'
    }
];
