import React from 'react';
import FullyManaged from '../../assets/images/fullymanaged.svg';
import WorkProfile from '../../assets/images/briefcase.png';
import Dedicated from '../../assets/images/dedicated.png';
import images from '../../assets/images/images.svg';
import videos from '../../assets/images/videos.svg';
import music from '../../assets/images/music.svg';
import documents from '../../assets/images/documents.svg';
import tectoroLauncher from '../../assets/images/launcher.png';
import multiApp from '../../assets/images/multiapp.png';
import singleApp from '../../assets/images/singleapp.png';
import iosSingleApp from '../../assets/images/singleapp.svg';
import iosMultiApp from '../../assets/images/multiapp.svg';
import android from '../../assets/images/android1.png';
import windows from '../../assets/images/windows1.png';
import apple from '../../assets/images/appledeice.png';
import iphone from '../../assets/images/appleicons/iphone.png';
import macbook from '../../assets/images/appleicons/macbook.png';

const policiesArr = [
    {
        img: singleApp,
        label: 'Single App',
        id: 'SA',
        desc: 'This solution set allows IT admins to further lock down the dsage of a device to a Single App'
    },
    {
        img: multiApp,
        label: 'Multi App',
        id: 'MA',
        desc: 'You can exercise complete management control over the fully managed company owned devices'
    },
    {
        img: FullyManaged,
        label: 'Fully Managed Device',
        id: 'FM',
        desc: 'You Can Exercise Complete Management Control Over The Fully Managed Company Owned Devices'
    },
    {
        img: WorkProfile,
        label: 'Work Profile',
        id: 'WP',
        desc: 'The Profile Owner (PO) Deployment Method Is Recommended For Personally Owned Devices (BYOD) That Are Also Used For Work'
    },
    {
        img: Dedicated,
        label: 'Dedicated Device',
        id: 'DD',
        desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Single App Or Small Set Of Apps.',
        childPolicies: [
            {
                img: singleApp,
                label: 'Single App',
                id: 'SA',
                desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Single App'
            },
            {
                img: multiApp,
                label: 'Kiosk Launcher',
                id: 'KL',
                desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Small Set Of Apps'
            },
            {
                img: tectoroLauncher,
                label: 'Tectoro Launcher',
                id: 'TL',
                desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Small Set Of Apps With More Features'
            }
        ]
    }
];

export const iosPoliciesArr = [
    {
        img: iphone,
        label: 'iOS Policy',
        id: 'ios',
        cards: [
            {
                img: iosSingleApp,
                label: 'Single App',
                id: 'SA',
                path: 'SA-ios',
                desc: 'Enabling Single App Mode restricts device usage to the selected app exclusively, preventing access to other apps.'
            },
            {
                img: iosMultiApp,
                label: 'Multi App',
                id: 'MA',
                path: 'MA-ios',
                desc: 'Enabling Multiple App Mode allows end users to exclusively access the apps pushed to the device by the admin, thereby restricting access to other apps.'
            }
        ],
        desc: 'Create a policy to effectively manage both supervised and unsupervised iOS devices. It allows you to configure device settings, restrictions, compliance requirements, and app controls'
    },
    {
        img: macbook,
        label: 'macOS Policy',
        id: 'macos',
        path: 'MA-macos',
        desc: 'Create a policy to effectively manage both supervised and unsupervised macOS devices. It allows you to configure device settings, restrictions, compliance requirements, and app controls'
    }
];

export const myDeviceCards = [
    { img: android, label: 'Android', type: 'android', key: 'ANDROID_ENTERPRISE_ENABLED' },
    {
        img: apple,
        label: 'Apple',
        type: 'apple',
        key: 'IOS_ENABLED',
        steps: (
            <ol className="stepol">
                <li className="stepli">Click on Add devices.</li>
                <li className="stepli">Open Camera app on your the iOS device and Scan the QR code.</li>
                <li className="stepli">A Notification will be seen asking you to open Safari. Please click on the notification.</li>
                <li className="stepli">
                    Once Safari opens, it will show you a dialog informing you that the Settings app will be opened. Click on Allow.
                </li>
                <li className="stepli">
                    MDM app will be downloaded and a screen with the option to Install will be seen. Click on Install.
                </li>
                <li className="stepli">
                    The device will ask for a confirmation by asking you to click on install again. Click on Install.
                </li>
                <li className="stepli">This will start enrolling the device.</li>
            </ol>
        )
    }
    // { img: windows, label: 'Windows', type: 'windows', key: 'WINDOWS_ENABLED' }
];
export const windowsPoliciesArr = [
    {
        img: FullyManaged,
        label: 'Fully Managed Device',
        id: 'FM',
        desc: 'You Can Exercise Complete Management Control Over The Fully Managed Company Owned Devices'
    },
    {
        img: WorkProfile,
        label: 'Work Profile',
        id: 'WP',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    }
];

const checkedData = [];

const contentManagementCards = [
    {
        label: 'Images',
        count: '0',
        size: '0',
        icon: images,
        alt: 'image',
        fileType: 'IMAGE',
        border: 'primary',
        fileFormats: 'image/*'
    },
    { label: 'Videos', count: '0', size: '0', icon: videos, alt: 'video', fileType: 'VIDEO', border: 'danger', fileFormats: 'video/*' },
    { label: 'Audio', count: '0', size: '0', icon: music, alt: 'audio', fileType: 'AUDIO', border: 'warning', fileFormats: 'audio/*' },
    {
        label: 'Documents',
        count: '0',
        size: '0',
        icon: documents,
        alt: 'document',
        fileType: 'DOCUMENT',
        border: 'info',
        fileFormats: 'application/*'
    }
];

export const detailsObj = {
    educationScreens: true,
    systemApps: false,
    allowOffline: false,
    screenOn: false,
    wifiId: undefined,
    securityType: undefined,
    password: undefined,
    securityOptions: 'withoutLogin'
};

export { policiesArr, checkedData, contentManagementCards };
