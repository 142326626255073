/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, Enterprise, TenantConfig, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { appsSchema } from '../TableSchema';
import ViewPolicyDetailsBody from './ViewPolicyDetailsBody';

const AndroidApplications = () => {
    document.title = 'Apps';
    const urlconf = useEnv();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const api = new APIClient();
    const [deleteModal, setDeleteModal] = useState(false);
    const [refreshModal, setRefreshModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [refreshModalData, setRefreshModalData] = useState('');

    const [doNotDeleteApps, setDoNotDeleteApps] = useState([]);
    const [appToRemove, setAppToRemove] = useState('');
    const [apps, setApps] = useState([]);
    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};
    const [policies, setPolicies] = useState([]);
    const [showoffCanvasModal, setShowoffCanvalModal] = useState(false);
    const [installStatusCounts, setInstallStatusCounts] = useState({});
    const [devicesOnPolicy, setDevicesOnPolicy] = useState([]);
    const [countViewRow, setCountViewRow] = useState('');
    const [policyOptions, setPolicyOptions] = useState([]);
    const [devicesOnGroups, setDevicesOnGroups] = useState([]);
    const [tableSchema, setTableSchema] = useState([]);
    const [offCanvasModalLoader, setOffCanvasModalLoader] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.APPLICATION_EDITOR);
        let schema = [...appsSchema];
        if (findPriv) schema = appsSchema.filter((item, index) => index < appsSchema.length - 1);
        setTableSchema([...schema]);
        promiseHandler();
    }, []);

    const promiseHandler = () => {
        const policyPromise = new Promise((resolve, reject) => {
            api.get(url.POLICIES, { page: 1, size: 100 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });
        const applicationPromise = new Promise((resolve, reject) => {
            let params = { enterpriseId: enterpriseObj.id, page: 1, size: 2000 };
            api.get(url.APPLICATIONS, params)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Applications failed.');
                })
                .catch((err) => reject('Applications failed.'));
        });
        Promise.allSettled([policyPromise, applicationPromise])
            .then((result) => {
                if (result[0]?.status === 'fulfilled') {
                    let applications = [];
                    let options = [];
                    result[0]?.value?.forEach((data) => {
                        options.push({ ...data, nameCode: data.name + ' (' + data.code + ')' });
                        if (data.policy?.applications?.length > 0) {
                            data.policy?.applications.forEach((app) => {
                                applications.push(app.packageName);
                            });
                        }
                    });
                    options.unshift({ name: 'All Policies', code: '', nameCode: 'All Policies' });
                    setPolicyOptions(options);
                    setDoNotDeleteApps(applications);
                }
                if (result[1]?.status === 'fulfilled') {
                    result[1].value = handleAppsData({ data: result[1].value });
                    setApps(result[1]?.value?.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleAppsData = (resp) => {
        if (resp?.data) {
            resp.data.forEach((data) => {
                data.doNotDelete = data.policyCount > 0;
                data.createdDate = getDate(data.updateTime);
                data.totalPolicies = data.policyCount ? data.policyCount : 0;
                data.appType = data.appType ? data.appType : '—';
                data.appVersionNumber = data.appVersionNumber ? data.appVersionNumber : '—';
                data.notInstalled = data.notInstallApp ? data.notInstallApp : 0;
                data.notLatest = data.notLatestApp ? data.notLatestApp : 0;
                data.latest = data.latestApp ? data.latestApp : 0;
                data.totalDevices = Number(data.notInstallApp) + Number(data.notLatestApp) + Number(data.latestApp);
                data.noUpdate = data.totalDevices === 0;
                data.totalGroups = data.groupCount ? data.groupCount : 0;
                data.configExist = data.configuration === 'NO';
                data.appSize = data.appSize ? data.appSize : '—';
                data.category = data.category ? data.category : '—';
                data.status = data.totalPolicies > 0 ? 'published' : 'Not_Published';
                data.badgeClass = data.status === 'Published' ? 'success' : 'danger';
            });
        }
        return resp;
    };

    const getApps = () => {
        let params = { enterpriseId: enterpriseObj.id, page: 1, size: 2000 };
        api.get(url.APPLICATIONS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                resp = handleAppsData(resp);
                setApps(resp.data);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleAddApp = () => {
        history.push('/aeapps/add');
    };

    const handleRefresh = (row) => {
        setRefreshModalData(row.original);
        setRefreshModal(true);
    };

    const applicationService = (event, type) => {
        let appiCall;
        let params = { enterpriseId: enterpriseObj.id, packageName: event.packageName };
        let deleteUrl = url.APPLICATIONS + '/' + enterpriseObj.id + '/' + event.packageName;
        setLoading(true);
        if (type === 'create') appiCall = api.create(url.APPLICATIONS, params);
        else appiCall = api.delete(deleteUrl);
        appiCall
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(type === 'create' ? toastMessages.appApproved : toastMessages.appDeleted);
                    if (type === 'delete') {
                        getApps();
                    }
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleApplicationRemoval = () => {
        applicationService({ packageName: appToRemove.packageName }, 'delete');
        setLoading(false);
    };

    const handleDelete = (row) => {
        if (doNotDeleteApps.includes(row.original.packageName)) {
            /* Directly showing warning to user */
            toast.warning(toastMessages.appUsedInPolicy);
        } else {
            setDeleteModal(true);
            setAppToRemove({ title: row.original.title, packageName: row.original.packageName, iconURL: row.original.iconUrl });
        }
    };

    const handleEdit = (row) => {
        setSelectedRow(row);
    };

    const handleView = (row) => {
        setSelectedRow(row);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        handleApplicationRemoval();
        setDeleteModal(false);
    };

    const handleModalBody = () => {
        return (
            <div className="d-flex flex-column justify-content-center">
                <Row className="d-flex align-items-center justify-content-center">
                    <Col sm={4}>
                        <div className="align-items-center d-flex">
                            <i className="ri-checkbox-blank-circle-fill link-warning me-2 fs-20" />
                            <div>Not Latest</div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="align-items-center d-flex">
                            <i className="ri-checkbox-blank-circle-fill link-danger me-2 fs-20" />
                            <div>Not Installed</div>
                        </div>
                    </Col>
                </Row>
                <div className="d-flex align-items-center text-danger justify-content-center mt-3">
                    <i className="ri-information-line" />
                    <span className="font-style-italic">The update action will be performed on all above.</span>
                </div>
            </div>
        );
    };

    const handleRefreshConfirmation = () => {
        setRefreshModal(false);
        setLoading(true);
        api.update(url.POLICIES_REFRESH, { packageName: refreshModalData?.packageName })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.reinstallationRequested);
                }
                setRefreshModalData('');
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleCountViewGroup = (row) => {
        setLoading(true);
        setCountViewRow(row.original);
        api.create(url.ES_DEVICES, {
            aggs: {
                app: {
                    field: 'apps.' + row.original.packageName?.replaceAll('.', '_'),
                    type: 'EXISTS',
                    subAgg: {
                        groups: {
                            field: 'group',
                            type: 'BUCKETS',
                            subAgg: {
                                installStatus: {
                                    field: 'apps.' + row.original.packageName?.replaceAll('.', '_'),
                                    type: 'BUCKETS'
                                }
                            }
                        }
                    }
                }
            }
        })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data?.aggs?.app?.groups) {
                    let groupsArr = [];
                    let latestCount = 0;
                    let notLatestCount = 0;
                    let notInstalledCount = 0;
                    let devicesOnGroupArr = [];
                    Object.entries(resp.data?.aggs?.app?.groups).forEach((group) => {
                        let obj = {};
                        latestCount = latestCount + Number(group?.[1]?.installStatus?.[3] ? group?.[1]?.installStatus?.[3] : 0);
                        notLatestCount = notLatestCount + Number(group?.[1]?.installStatus?.[2] ? group?.[1]?.installStatus?.[2] : 0);
                        notInstalledCount = notInstalledCount + Number(group?.[1]?.installStatus?.[1] ? group?.[1]?.installStatus?.[1] : 0);
                        obj.count =
                            Number(group?.[1]?.installStatus?.[3] ? group?.[1]?.installStatus?.[3] : 0) +
                            Number(group?.[1]?.installStatus?.[2] ? group?.[1]?.installStatus?.[2] : 0) +
                            Number(group?.[1]?.installStatus?.[1] ? group?.[1]?.installStatus?.[1] : 0);
                        obj.group = group[0] ? group[0] : '—';
                        obj.latest = group?.[1]?.installStatus?.[3] ? group?.[1]?.installStatus?.[3] : 0;
                        obj.notLatest = group?.[1]?.installStatus?.[2] ? group?.[1]?.installStatus?.[2] : 0;
                        obj.notInstalled = group?.[1]?.installStatus?.[1] ? group?.[1]?.installStatus?.[1] : 0;
                        if (group?.[1]?.installStatus) {
                            Object.entries(group?.[1]?.installStatus).forEach((installStatus) => {
                                obj.totalDevices = (obj.totalDevices ? Number(obj.totalDevices) : 0) + Number(installStatus[1]);
                            });
                        }
                        groupsArr.push(obj);
                        devicesOnGroupArr.push(obj);
                    });
                    setDevicesOnGroups(devicesOnGroupArr);
                    setInstallStatusCounts({
                        latestCount: latestCount,
                        notLatestCount: notLatestCount,
                        notInstalledCount: notInstalledCount
                    });
                    setLoading(false);
                    setShowoffCanvalModal(true);
                }
            })
            .catch((_err) => setLoading(false));
    };

    const toggle = useCallback(async () => {
        if (showoffCanvasModal) setShowoffCanvalModal(false);
        else setShowoffCanvalModal(true);
    }, [showoffCanvasModal]);

    const handleDownload = (row, status, _group, policy) => {
        setLoading(true);
        setOffCanvasModalLoader(true);
        let params = { select: { page: 1, size: Number(tenantConfig.MAX_ANDROID_DEVICE) } };
        if (row) {
            params = {
                ...params,
                filter: {
                    group: [row.original.group],
                    policy: row.original.policy ? [row.original.policy] : null,
                    appStatus: [status]
                }
            };
        } else if (policy?.code) {
            params = { ...params, filter: { policy: [policy.code] } };
        }
        params.filter = { ...params.filter, apps: [countViewRow.packageName] };
        params.aggs = { app: { field: 'apps.' + countViewRow?.packageName?.replaceAll('.', '_'), type: 'EXISTS' } };
        api.create(url.DEVICES_EXPORT, params)
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                let name = countViewRow.title;
                if (row) name = name + '_' + (row.original.policy ? row.original.policy + '_' : '') + row.original.group;
                a.download = name + '_installation_summary.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
                setOffCanvasModalLoader(false);
            })
            .catch((err) => {
                setLoading(false);
                setOffCanvasModalLoader(false);
            });
    };

    const handleOffcanvasBody = () => {
        return (
            <ViewPolicyDetailsBody
                policies={policies}
                setOffCanvasModalLoader={setOffCanvasModalLoader}
                handleDownload={handleDownload}
                handlePolicySearch={(e) => setPolicyOptions(e)}
                installStatusCounts={installStatusCounts}
                devicesOnPolicy={devicesOnPolicy}
                devicesOnGroups={devicesOnGroups}
                countViewRow={countViewRow}
                policyOptions={policyOptions}
            />
        );
    };

    const handleIconView = (row) => {
        if (row.original.configuration === 'YES') history.push('/aeapps/' + row.original.packageName);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="text-muted mb-4">Are you sure you want to delete?</div>
                <img src={appToRemove?.iconURL} alt={appToRemove?.title} width="80" height="80" />
                <div className="fw-normal mt-4">{appToRemove.title}</div>
            </div>
        );
    };

    const header = () => {
        return (
            <div className="d-flex align-items-center">
                <img src={refreshModalData?.smallIconUrl} alt={refreshModalData?.title} width={30} height={30} className="me-1" />
                <div>{refreshModalData?.title}</div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <TableContainer
                        loading={loading}
                        searchFilter2={true}
                        isGlobalFilter={true}
                        addButton={true}
                        columns={tableSchema}
                        handleAddNew={handleAddApp}
                        handleView={handleView}
                        handleEdit={handleEdit}
                        handleRefresh={handleRefresh}
                        handleCountViewPolicy={handleCountViewGroup}
                        data={apps}
                        handleDelete={handleDelete}
                        handleIconView={handleIconView}
                        searchPlaceHolder={'App Name'}
                        reader={priviliges.APPLICATION_READER}
                        editor={priviliges.APPLICATION_EDITOR}
                        type="adminAccess"
                        adminAccess={priviliges.PLAYSTORE_EDITOR}
                        tableHeader={'All Apps'}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        show={deleteModal}
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        confirmText={'Delete'}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        deleteMessage={'Are you sure you want to delete this App ?'}
                    />
                    <CommonModal
                        size={'md'}
                        show={refreshModal}
                        modalheader={header()}
                        disabled={false}
                        onCloseClick={() => {
                            setRefreshModal(false);
                        }}
                        saveText="Update"
                        cancelText="Cancel"
                        handleModalBody={() => handleModalBody()}
                        handleClick={() => handleRefreshConfirmation()}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={showoffCanvasModal}
                        handleCloseClick={toggle}
                        loading={offCanvasModalLoader}
                        OffcanvasModalID="viewPolicyDetails"
                        hideSaveButton={true}
                        closeButtonBorder={true}
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-50'}
                        offcanvasHeader={countViewRow?.title + ' App Summary'}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AndroidApplications;
