import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Container, Form, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, TenantConfig, convertUTCtoIST } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import polClone from '../../../assets/images/clonePolicy.png';
import polDelete from '../../../assets/images/poldelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { androidTVPoliciesSchema } from '../../AndroidEnterprise/Policies/TableSchema';

const Policies = () => {
    document.title = 'TV Policy';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const [policiesTableSchema, setPoliciesTableSchema] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);
    const [qrCodeString, setQRCodeString] = useState('');
    const [policyType, setPolicyType] = useState('');

    useEffect(() => {
        localStorage.removeItem('policyNameForTokens');
        getPolicies();
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.TVPOLICY_EDITOR);
        let schema = [...androidTVPoliciesSchema];
        if (findPriv) schema = androidTVPoliciesSchema.filter((policy, index) => index < androidTVPoliciesSchema.length - 2);
        setPoliciesTableSchema([...schema]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPolicies = (paramsObj) => {
        setLoading(true);

        let pURL = url.TV_POLICIES;
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { page: pageNumber, size: pageSize };
        api.get(pURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((data) => {
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : '—';
                        data.modifiedDate = data.lastmodifiedTime ? convertUTCtoIST(data.lastmodifiedTime) : '—';
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleAddNew = () => {
        history.push('/atvpolicies/add');
    };

    const handleEdit = (row) => {
        history.push('/atvpolicies/edit/' + row.original.id);
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);

        setDeleteModal(false);
        api.delete(url.POLICIES + '/' + selectedRow.original.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyDeleted);
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleQR = (row) => {
        setLoading(true);

        setSelectedRow(row);
        api.get(url.POLICIES + '/' + row.original.id + url.QR_CODE)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.qrGenerated);
                    setShowQRModal(true);
                    setQRCodeString(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleClone = (row) => {
        validation.resetForm();
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const onClickSwitch = (row) => {
        setLoading(true);

        let rowData = JSON.parse(JSON.stringify(row.original));
        let obj = {
            id: rowData.id,
            status: rowData.status?.toLowerCase() === 'active' ? 'InActive' : 'Active',
            name: rowData.name,
            code: rowData.code,
            enterpriseId: rowData.enterpriseId,
            policy: rowData.policy
        };
        api.update(url.POLICIES + '/' + row?.original?.id, obj)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyStatusUpdated);
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleEnrollmentToken = (row) => {
        localStorage.setItem('policyNameForTokens', row.original?.name);
        history.push('/atvpolicies/enrollmenttoken/' + row.original.id);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { code: '' },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            setLoading(true);

            api.create(url.CLONE + '/' + selectedRow?.original?.id + '/' + selectedRow.original.policyType)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(toastMessages.policyCloned);
                        validation.resetForm();
                        setShowCloneModal(false);
                        getPolicies();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setShowCloneModal(false);
                });
        }
    });

    const handleCloneModalBody = () => {
        return (
            <Form
                className="h-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to clone this Policy?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={polClone} alt="clonepolicy" width={60} height={60} />
                    </div>
                    <div>
                        Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                    </div>
                </div>
            </Form>
        );
    };

    const handleQRModalBody = () => {
        return (
            <Row className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h5 className="text-primary">QR Code</h5>
                    {/* <p className="text-muted mb-1">Android Enterprise Set Up</p> */}
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        Please scan for the device enrollment
                    </Alert>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <img alt="" className="w-75" src={`data:image/png;base64,${qrCodeString}`} />
                </div>
            </Row>
        );
    };

    const qrHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Policy :</Label>
                <p className="p-0 m-0 mt-3 ms-2 fw-normal">{selectedRow?.original?.name}</p>
            </div>
        );
    };

    /*
     * const onPageChange = (pgNumber, pgSize) => {
     *     let params = { page: pgNumber, size: pgSize };
     *     setPageNumber(pgNumber);
     *     setPageSize(pgSize);
     *     getPolicies(params);
     * };
     */

    const handleClickView = (row) => {
        history.push('/atvpolicies/view/' + row.original.id);
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Policy?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Android TV Policies" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Policies'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchPlaceHolder={'Policy Code or Name'}
                        columns={policiesTableSchema}
                        handleGlobalSearch={handleGlobalSearch}
                        handleQR={handleQR}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleAddNew={handleAddNew}
                        handleClickView={handleClickView}
                        handleClone={handleClone}
                        onClickSwitch={onClickSwitch}
                        totalRecords={totalRecords}
                        handleEnrollmentToken={handleEnrollmentToken}
                        data={policies}
                        editor={priviliges.TVPOLICY_EDITOR}
                        reader={priviliges.TVPOLICY_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showCloneModal}
                        hideDeleteMessage={true}
                        message={handleCloneModalBody()}
                        onDeleteClick={validation.handleSubmit}
                        confirmText="Yes, Clone it"
                        hideDeleteIcon={true}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            validation.resetForm();
                        }}
                    />
                    <CommonModal
                        size={'md'}
                        show={showQRModal}
                        disabled={false}
                        hideSaveButton={true}
                        modalheader={qrHeader()}
                        onCloseClick={() => setShowQRModal(false)}
                        handleClick={() => setShowQRModal(false)}
                        handleModalBody={() => handleQRModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Policies;
