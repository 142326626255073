import React from 'react';
import { Row, Col, Card, Input, Label, Form, FormFeedback } from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';

import { TenantConfig } from '../../Components/Common/Util';
import AuthSlider from './AuthSlider';

const Register = () => {
    document.title = 'SignUp';
    const history = useHistory();
    const tenantConfig = TenantConfig();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            first_name: '',
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Please Enter Your Email'),
            first_name: Yup.string().required('Please Enter Your User Name'),
            password: Yup.string().required('Please Enter Your Password'),
            confirm_password: Yup.string().when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                // eslint-disable-next-line quotes
                then: Yup.string().oneOf([Yup.ref('password')], "Confirm Password Isn't Match")
            })
        }),
        onSubmit: (values) => {}
    });

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center min-vh-100 vw-100 overflow-y-auto">
                {/* <div className="bg-overlay"></div> */}
                <div className="min-vh-100 overflow-x-hidden vw-100">
                    <div className="min-vh-100 vw-100">
                        <Row className="min-vh-100 vw-100">
                            <Col lg={12} className="min-vh-100 vw-100 p-0">
                                <Card className="min-vh-100 overflow-x-hidden m-0">
                                    <Row className="g-0 min-vh-100 vw-100">
                                        <AuthSlider />
                                        <Col lg={4} className="d-flex align-items-center justify-content-center">
                                            <div className="p-lg-12 p-6 w-100">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="text-center mb-8 text-white-50">
                                                            <div>
                                                                <div className="d-inline-block auth-logo">
                                                                    <img src={tenantConfig?.COMPANY_LOGO} alt="" height="60" />
                                                                </div>
                                                            </div>
                                                            {tenantConfig?.PRIMARY_HEADER && (
                                                                <p className="mt-4 mb-2 fs-18 fw-medium text-primary">
                                                                    {tenantConfig.PRIMARY_HEADER}
                                                                </p>
                                                            )}
                                                            {tenantConfig?.SECONDARY_HEADER && (
                                                                <p className="mt-1 fs-15 fw-medium text-muted">
                                                                    {tenantConfig.SECONDARY_HEADER}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="mt-6">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        className="needs-validation"
                                                        action="#"
                                                    >
                                                        {/* {success && success ? (
                                                            <>
                                                                {toast('Your Redirect To Login Page...', {
                                                                    position: 'top-right',
                                                                    hideProgressBar: false,
                                                                    className: 'bg-success text-white',
                                                                    progress: undefined,
                                                                    toastId: ''
                                                                })}
                                                                <Alert color="success">
                                                                    Register User Successfully and Your Redirect To Login Page...
                                                                </Alert>
                                                            </>
                                                        ) : null}

                                                        {error && error ? (
                                                            <Alert color="danger">
                                                                <div>
                                                                    Email has been Register Before, Please Use Another Email Address...{' '}
                                                                </div>
                                                            </Alert>
                                                        ) : null} */}

                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                Email <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                id="email"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter email address"
                                                                type="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ''}
                                                                invalid={validation.touched.email && validation.errors.email ? true : false}
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">
                                                                    <div>{validation.errors.email}</div>
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                User Name <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                name="first_name"
                                                                type="text"
                                                                placeholder="Enter user name"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.first_name || ''}
                                                                invalid={
                                                                    validation.touched.first_name && validation.errors.first_name
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.first_name && validation.errors.first_name ? (
                                                                <FormFeedback type="invalid">
                                                                    <div>{validation.errors.first_name}</div>
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                Password <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                name="password"
                                                                type="password"
                                                                placeholder="Enter your password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.password || ''}
                                                                invalid={
                                                                    validation.touched.password && validation.errors.password ? true : false
                                                                }
                                                            />
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <FormFeedback type="invalid">
                                                                    <div>{validation.errors.password}</div>
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-2">
                                                            <Label className="form-label">
                                                                Confirm Password <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                name="confirm_password"
                                                                type="password"
                                                                placeholder="Confirm Password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.confirm_password || ''}
                                                                invalid={
                                                                    validation.touched.confirm_password &&
                                                                    validation.errors.confirm_password
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                <FormFeedback type="invalid">
                                                                    <div>{validation.errors.confirm_password}</div>
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mt-4">
                                                            <button className="btn btn-success w-100" type="submit">
                                                                Sign Up
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>

                                                <div className="mt-12 text-center">
                                                    <div className="mb-0 d-flex align-items-center justify-content-center">
                                                        Already have an account ?
                                                        <div
                                                            onClick={() => history.push('/login')}
                                                            className="ps-1 fw-semibold text-primary text-decoration-underline cursor-pointer"
                                                        >
                                                            Sign in
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Register;
