import React, { useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import BYOD from './BYOD';
import AutoPilot from './AutoPilot';
import BYODSetup from './BYODSetup';

const WindowsEnrollment = () => {
    document.title = 'Windows Enrollment';
    const [loading, setLoading] = useState(false);
    const [showCards, setshowCards] = useState(true);

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb title="Windows Enrollment" pageTitle="Windows Enrollment" />
                    <Row>
                        {showCards ? (
                            <>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <BYOD setshowCards={setshowCards} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    {/* <AutoPilot /> */}
                                </Col>
                            </>
                        ) : (
                            <BYODSetup setshowCards={setshowCards} setLoading={setLoading} />
                        )}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsEnrollment;
