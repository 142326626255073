import React, { useState, useCallback, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { uniqueID } from '../Util';

const EllipsisToolTip = (props) => {
    // TOOL TIP STATE
    const [showTooltip, setShowTooltip] = useState(false);
    // GENERATING - RANDOM ID
    const tid = useMemo(() => uniqueID('5'), []);

    // MOUSE ENTER HANDLER
    const mouseEnterHandler = useCallback(
        (e) => {
            if (e.target.offsetWidth !== e.target.scrollWidth && !showTooltip) {
                setShowTooltip(true);
            } else if (e.target.offsetWidth === e.target.scrollWidth && showTooltip) {
                setShowTooltip(false);
            }
        },
        [showTooltip, setShowTooltip]
    );

    return (
        <React.Fragment>
            <div data-tip data-for={tid} className="word-ellipsis" onMouseEnter={mouseEnterHandler} style={props.style}>
                {props.children}
            </div>
            {showTooltip && (
                <ReactTooltip id={tid} className="custom-tooltip" effect="solid" {...props.options}>
                    {props.children}
                </ReactTooltip>
            )}
        </React.Fragment>
    );
};

export default EllipsisToolTip;
