/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { Enterprise, baseURL } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import NoEnterpriseAccount from '../../ApplicationManagement/NoEnterpriseAccount';

const AddApplication = () => {
    document.title = 'Add Application';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();
    let enterpriseObj = Enterprise();
    const enterprises = enterpriseObj ? JSON.parse(enterpriseObj) : {};

    const [enterprise, setEnterprise] = useState(enterprises);
    const [loading, setLoading] = useState(false);
    const [embededToken, setEmbededToken] = useState('');
    const [showAddApplication, setShowAddApplication] = useState(false);
    const [packageName, setPackageName] = useState('');

    useEffect(() => {
        setEnterprise(enterprises);
        if (Object.keys(enterprises)?.length > 0) getEmbededToken(enterprises);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEmbededToken = (eData) => {
        setLoading(true);
        let EMBEDEDURL = url.EMBEDED_URL;
        let params = { enterpriseId: eData.id, parentUrl: baseURL() };
        api.get(EMBEDEDURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setEmbededToken(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handlePlayStoreIframe = () => {
        if (embededToken) {
            var URL = `https://play.google.com/work/embedded/search?token=${embededToken}&mode=SELECT`;
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';

            script.onload = () => {
                window.gapi.load('gapi.iframes', () => {
                    var options = {
                        url: URL,
                        where: document.getElementById('container'),
                        attributes: {
                            style: 'width: 100%; height:1000px',
                            scrolling: 'yes',
                            showsearchbox: true,
                            search: true
                        }
                    };
                    var iframe = window.gapi.iframes.getContext().openChild(options);
                    iframe.register(
                        'onproductselect',
                        function (event) {
                            if (event.action?.toLowerCase() === 'selected') applicationService(event, 'create');
                        },
                        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
                    );
                });
            };
            document.body.appendChild(script);
        }
        return (
            <div className="col-md-12 mb-4">
                <div id="container"></div>
            </div>
        );
    };

    const applicationService = (event, type) => {
        if (type === 'add') {
            let regex = new RegExp(/^[A-Za-z0-9_.]*$/);
            let valid = regex.test(packageName);
            if (packageName?.[0]?.toLowerCase() === packageName?.[0]?.toUpperCase() || !valid || packageName.includes('..')) {
                toast.error(toastMessages.invalidPackageName);
                return;
            }
        }
        let appiCall;
        let params = { enterpriseId: enterprises.id, packageName: event.packageName };
        let deleteUrl = url.APPLICATIONS + '/' + enterprises.id + '/' + event.packageName;
        setLoading(true);
        if (type === 'create' || type === 'add') appiCall = api.create(url.APPLICATIONS, params);
        else appiCall = api.delete(deleteUrl);
        appiCall
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(type === 'add' ? toastMessages.appAdded : toastMessages.appApproved);
                    setPackageName('');
                    setShowAddApplication(false);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Add Application" showBack={true} history={history} homeLink="Dashboard" backLink="aeapps" />
                    {!enterprise?.id ? (
                        <NoEnterpriseAccount screen="Add Application" />
                    ) : (
                        <div className="d-flex flex-column">
                            <Row>{handlePlayStoreIframe()}</Row>
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddApplication;
