import { GetAuthmodes, baseURL, guid } from '../../../Components/Common/Util';
import { defaultBranding } from '../../../Components/constants/constants';

export const checkValue = (value) => {
    return value !== undefined && value !== undefined;
};

export const policyInitValues = (formValues) => {
    return {
        ...formValues,
        policyName: formValues ? formValues.policyName : undefined,
        code: formValues ? formValues.code : undefined,
        policyType: formValues ? formValues.policyType : undefined,

        //* ** General settings */
        funDisabled: checkValue(formValues.funDisabled) ? !formValues.funDisabled : false,
        cameraAccess: formValues ? formValues.cameraAccess : undefined,
        autoDateAndTimeZone: formValues ? formValues.autoDateAndTimeZone : undefined,
        stayOnPluggedModes: formValues ? formValues.stayOnPluggedModes?.filter((mode) => mode !== null) : undefined,
        debuggingFeaturesAllowed: checkValue(formValues.debuggingFeaturesAllowed) ? formValues.debuggingFeaturesAllowed : false,
        frpAdminEmails: formValues.frpAdminEmails ? formValues.frpAdminEmails : [],

        //* ** Launcher Settings */
        powerButtonActions: formValues ? formValues.powerButtonActions : undefined,
        systemErrorWarnings: formValues ? formValues.systemErrorWarnings : undefined,
        systemNavigation: formValues ? formValues.systemNavigation : undefined,
        statusBar: formValues ? formValues.statusBar : undefined,
        deviceSettings: formValues ? formValues.deviceSettings : undefined,
        useKioskLauncher: !!formValues.useKioskLauncher,

        //* ** Display Settings */
        setWallpaperDisabled: checkValue(formValues.setWallpaperDisabled) ? !formValues.setWallpaperDisabled : false,
        deviceOwnerLockScreenInfo: formValues ? formValues.deviceOwnerLockScreenInfo : undefined,
        skipFirstUseHintsEnabled: checkValue(formValues.skipFirstUseHintsEnabled) ? formValues.skipFirstUseHintsEnabled : false,
        privateKeySelectionEnabled: checkValue(formValues.privateKeySelectionEnabled) ? formValues.privateKeySelectionEnabled : false,
        setUserIconDisabled: checkValue(formValues.setUserIconDisabled) ? !formValues.setUserIconDisabled : false,
        maximumTimeToLock: formValues ? formValues.maximumTimeToLock : undefined,
        workProfileWidgetsDefault: formValues ? formValues.workProfileWidgetsDefault : undefined,

        //* ** Password Settings */
        passwordQuality: formValues ? formValues.passwordQuality : undefined,
        passwordHistoryLength: formValues ? formValues.passwordHistoryLength : undefined,
        passwordExpirationTimeout: formValues
            ? formValues.passwordExpirationTimeout
                ? parseFloat(formValues?.passwordExpirationTimeout)
                : 0
            : undefined,
        maximumFailedPasswordsForWipe: formValues ? formValues.maximumFailedPasswordsForWipe : undefined,
        // unifiedLockSettings: formValues ? formValues.unifiedLockSettings : undefined,

        //* ** Communication Settings */
        outgoingCallsDisabled: checkValue(formValues.outgoingCallsDisabled) ? !formValues.outgoingCallsDisabled : false,
        smsDisabled: checkValue(formValues.smsDisabled) ? !formValues.smsDisabled : false,
        defaultPermissionPolicyInPolicy: checkValue(formValues.defaultPermissionPolicyInPolicy)
            ? formValues.defaultPermissionPolicyInPolicy
            : false,
        bluetoothDisabled: checkValue(formValues.bluetoothDisabled) ? !formValues.bluetoothDisabled : false,
        bluetoothContactSharingDisabled: checkValue(formValues.bluetoothContactSharingDisabled)
            ? !formValues.bluetoothContactSharingDisabled
            : false,
        bluetoothConfigDisabled: checkValue(formValues.bluetoothConfigDisabled) ? !formValues.bluetoothConfigDisabled : false,
        outgoingBeamDisabled: checkValue(formValues.outgoingBeamDisabled) ? !formValues.outgoingBeamDisabled : false,
        locationMode: formValues ? formValues.locationMode : undefined,
        shareLocationDisabled: checkValue(formValues.shareLocationDisabled) ? !formValues.shareLocationDisabled : false,
        adjustVolumeDisabled: checkValue(formValues.adjustVolumeDisabled) ? !formValues.adjustVolumeDisabled : false,
        printingPolicy: formValues ? formValues.printingPolicy : undefined,
        microphoneAccess: formValues ? formValues.microphoneAccess : undefined,

        //* ** Network Settings */
        wifiState: formValues ? formValues.wifiState : undefined,
        airplaneModeState: formValues ? formValues.airplaneModeState : undefined,
        mobileNetworksConfigDisabled: checkValue(formValues.mobileNetworksConfigDisabled)
            ? !formValues.mobileNetworksConfigDisabled
            : false,
        cellBroadcastsConfigDisabled: checkValue(formValues.cellBroadcastsConfigDisabled)
            ? !formValues.cellBroadcastsConfigDisabled
            : false,
        createWindowsDisabled: checkValue(formValues.createWindowsDisabled) ? !formValues.createWindowsDisabled : false,
        networkResetDisabled: checkValue(formValues.networkResetDisabled) ? !formValues.networkResetDisabled : false,
        usbFileTransferDisabled: checkValue(formValues.usbFileTransferDisabled) ? !formValues.usbFileTransferDisabled : false,
        vpnConfigDisabled: checkValue(formValues.vpnConfigDisabled) ? !formValues.vpnConfigDisabled : false,
        alwaysOnVpnPackage: formValues ? formValues.alwaysOnVpnPackage : undefined,
        packageName: formValues.alwaysOnVpnPackage?.packageName ? formValues.alwaysOnVpnPackage?.packageName : '',
        lockdownEnabled: formValues.alwaysOnVpnPackage?.lockdownEnabled ? formValues.alwaysOnVpnPackage?.lockdownEnabled : false,
        dataRoamingDisabled: checkValue(formValues.dataRoamingDisabled) ? !formValues.dataRoamingDisabled : false,
        networkEscapeHatchEnabled: checkValue(formValues.networkEscapeHatchEnabled) ? formValues.networkEscapeHatchEnabled : false,
        usbDataAccess: formValues ? formValues.usbDataAccess : undefined,
        configureWifi: formValues ? formValues.configureWifi : undefined,
        wifiDirectSettings: formValues ? formValues.wifiDirectSettings : undefined,
        tetheringSettings: formValues ? formValues.tetheringSettings : undefined,

        //* ** Security Settings */
        screenCaptureDisabled: checkValue(formValues.screenCaptureDisabled) ? !formValues.screenCaptureDisabled : false,
        mountPhysicalMediaDisabled: checkValue(formValues.mountPhysicalMediaDisabled) ? !formValues.mountPhysicalMediaDisabled : false,
        factoryResetDisabled: checkValue(formValues.factoryResetDisabled) ? !formValues.factoryResetDisabled : false,
        credentialsConfigDisabled: checkValue(formValues.credentialsConfigDisabled) ? !formValues.credentialsConfigDisabled : false,
        installAppsDisabled: checkValue(formValues.installAppsDisabled) ? !formValues.installAppsDisabled : false,
        uninstallAppsDisabled: checkValue(formValues.uninstallAppsDisabled) ? !formValues.uninstallAppsDisabled : false,
        googlePlayProtectVerifyApps: formValues ? formValues.googlePlayProtectVerifyApps : undefined,
        developerSettings: formValues ? formValues.developerSettings : undefined,
        untrustedAppsPolicy: formValues ? formValues.untrustedAppsPolicy : undefined,

        //* ** Compliance Polices */
        encryptionPolicy: formValues ? formValues.encryptionPolicy : undefined,
        appAutoUpdatePolicy: formValues ? formValues.appAutoUpdatePolicy : undefined,
        systemUpdate: formValues ? formValues.systemUpdate : undefined,
        startDate: formValues?.startDate ? formValues.startDate : undefined,
        endDate: formValues?.endDate ? formValues.endDate : undefined,
        //   //* ** BYOD Settings. */

        /*
         *   maxDaysWithWorkOff: formValues ? formValues.maxDaysWithWorkOff : undefined,
         *   personalPlayStoreMode: formValues ? formValues.personalPlayStoreMode : undefined,
         *   personalCameraDisabled: checkValue(formValues.personalCameraDisabled) ? !formValues.personalCameraDisabled : false,
         *   personalScreenCaptureDisabled: checkValue(formValues.personalScreenCaptureDisabled)
         *       ? !formValues.personalScreenCaptureDisabled
         *       : false,
         */

        showWorkContactsInPersonalProfile: formValues ? formValues.showWorkContactsInPersonalProfile : undefined,
        crossProfileCopyPaste: formValues ? formValues.crossProfileCopyPaste : undefined,
        crossProfileDataSharing: formValues ? formValues.crossProfileDataSharing : undefined,
        preferentialNetworkService: formValues ? formValues.preferentialNetworkService : undefined,

        //* ** User and Account Management */
        addUserDisabled: checkValue(formValues.addUserDisabled) ? !formValues.addUserDisabled : false,
        removeUserDisabled: checkValue(formValues.removeUserDisabled) ? !formValues.removeUserDisabled : false,
        modifyAccountsDisabled: checkValue(formValues.modifyAccountsDisabled) ? !formValues.modifyAccountsDisabled : false,
        playStoreMode: formValues ? formValues.playStoreMode : undefined,

        //* ** Keyguard Settings */
        keyguardDisabled: checkValue(formValues.keyguardDisabled) ? !formValues.keyguardDisabled : false,
        keyguardDisabledFeatures: formValues ? formValues.keyguardDisabledFeatures : undefined,

        //* ** Status Reporting settings */
        applicationReportsEnabled: checkValue(formValues.applicationReportsEnabled) ? formValues.applicationReportsEnabled : false,
        deviceSettingsEnabled: checkValue(formValues.deviceSettingsEnabled) ? formValues.deviceSettingsEnabled : false,
        softwareInfoEnabled: checkValue(formValues.softwareInfoEnabled) ? formValues.softwareInfoEnabled : false,
        networkInfoEnabled: checkValue(formValues.networkInfoEnabled) ? formValues.networkInfoEnabled : false,
        /*
         * powerManagementEventsEnabled: checkValue(formValues.powerManagementEventsEnabled)
         *     ? formValues.powerManagementEventsEnabled
         *     : true,
         * memoryInfoEnabled: checkValue(formValues.memoryInfoEnabled) ? formValues.memoryInfoEnabled : false,
         * displayInfoEnabled: checkValue(formValues.displayInfoEnabled) ? formValues.displayInfoEnabled : false,
         * hardwareStatusEnabled: checkValue(formValues.hardwareStatusEnabled) ? formValues.hardwareStatusEnabled : false,
         * systemPropertiesEnabled: checkValue(formValues.systemPropertiesEnabled) ? formValues.systemPropertiesEnabled : false,
         * commonCriteriaModeEnabled: checkValue(formValues.commonCriteriaModeEnabled) ? formValues.commonCriteriaModeEnabled : false,
         */

        // Setup Actions
        setupActions: formValues?.setupActions ? formValues.setupActions : [],

        // * ** Applications */
        installType: formValues ? formValues.installType : undefined,
        defaultPermissionPolicy: formValues ? formValues.defaultPermissionPolicy : undefined,
        // disabled: checkValue(formValues.disabled) ? !formValues.disabled : false,
        delegatedScopes: formValues ? formValues.delegatedScopes : undefined,
        autoUpdateMode: formValues ? formValues.autoUpdateMode : undefined,
        permissionGrants: formValues ? formValues.permissionGrants : undefined,
        extensionConfig: formValues ? formValues.extensionConfig : undefined,
        permittedAccessibilityServices: formValues?.permittedAccessibilityServices ? formValues.permittedAccessibilityServices : undefined,
        connectedWorkAndPersonalApp: formValues ? formValues.connectedWorkAndPersonalApp : undefined,
        workProfileWidgets: formValues ? formValues.workProfileWidgets : undefined,

        default_password: formValues?.default_password ? formValues?.default_password : 'true',

        firewall: formValues?.firewall ? formValues?.firewall : undefined,
        geofence: formValues?.geofence ? formValues?.geofence : [],
        simsetting: formValues?.sim ? formValues?.sim : undefined,
        HiddenSSID: false
    };
};

export const prepareDataObj = (
    values,
    formValues,
    enterpriseObj,
    polType,
    defaultData,
    wifiConfiguration,
    applicationObj,
    launcherPackagename,
    availableApplications,
    tenant,
    accountId,
    tectoroApplications
) => {
    const ssids = [];
    values?.wifiSsids?.split(',')?.forEach((ssid) => ssids.push({ wifiSsid: ssid }));
    let fullyManagedDataObj = {
        name: values.policyName,
        enterpriseId: enterpriseObj.id,
        status: formValues?.status ? formValues?.status : 'Active',
        policyType: formValues?.policyType ? formValues.policyType : polType?.toUpperCase(),
        policy: {
            ...defaultData,
            name: values.policyName,
            // General Settings
            funDisabled: !values.funDisabled,
            cameraAccess: values?.cameraAccess?.value,
            autoDateAndTimeZone: values?.autoDateAndTimeZone?.value,
            stayOnPluggedModes: values?.stayOnPluggedModes?.length > 0 ? values?.stayOnPluggedModes?.map((ele) => ele?.value) : undefined,
            frpAdminEmails: values.frpAdminEmails?.length > 0 ? values.frpAdminEmails : undefined,

            // Display Settings
            setWallpaperDisabled: !values.setWallpaperDisabled,
            deviceOwnerLockScreenInfo: values.deviceOwnerLockScreenInfo ? { defaultMessage: values.deviceOwnerLockScreenInfo } : undefined,
            shortSupportMessage: values.shortSupportMessage ? { defaultMessage: values.shortSupportMessage } : undefined,
            longSupportMessage: values.longSupportMessage ? { defaultMessage: values.longSupportMessage } : undefined,
            skipFirstUseHintsEnabled: values.skipFirstUseHintsEnabled,
            privateKeySelectionEnabled: values.privateKeySelectionEnabled,
            setUserIconDisabled: !values.setUserIconDisabled,
            maximumTimeToLock: values?.maximumTimeToLock?.value,

            // Password Settings
            credentialProviderPolicyDefault: values?.credentialProviderPolicyDefault?.value || undefined,
            passwordPolicies:
                values?.passwordQuality?.value ||
                values.passwordHistoryLength ||
                values.passwordExpirationTimeout ||
                values.maximumFailedPasswordsForWipe
                    ? [
                          {
                              passwordQuality: values?.passwordQuality?.value,
                              passwordHistoryLength: values.passwordHistoryLength,
                              passwordScope: formValues.passwordPolicies?.[0]?.passwordScope,
                              passwordExpirationTimeout: `${values.passwordExpirationTimeout}s`,
                              maximumFailedPasswordsForWipe: values.maximumFailedPasswordsForWipe
                          }
                      ]
                    : undefined,
            // Communication Settings
            outgoingCallsDisabled: !values.outgoingCallsDisabled,
            smsDisabled: !values.smsDisabled,
            bluetoothDisabled: !values.bluetoothDisabled,
            defaultPermissionPolicy: values.defaultPermissionPolicyInPolicy?.value,
            // bluetoothContactSharingDisabled: !values.bluetoothContactSharingDisabled,
            bluetoothConfigDisabled: !values.bluetoothConfigDisabled,
            outgoingBeamDisabled: !values.outgoingBeamDisabled,
            locationMode: values?.locationMode?.value,
            shareLocationDisabled: !values.shareLocationDisabled,
            adjustVolumeDisabled: !values.adjustVolumeDisabled,
            printingPolicy: values?.printingPolicy?.value,
            microphoneAccess: values?.microphoneAccess?.value,
            // wifi settings
            openNetworkConfiguration:
                wifiConfiguration?.length > 0 ? { NetworkConfigurations: prepareWifiConfiguration(wifiConfiguration) } : undefined,
            // Network Settings
            deviceRadioState:
                values?.wifiState?.value ||
                values?.airplaneModeState?.value ||
                values?.ultraWidebandState?.value ||
                values?.cellularTwoGState?.value ||
                values?.minimumWifiSecurityLevel?.value
                    ? {
                          wifiState: values?.wifiState?.value,
                          airplaneModeState: values?.airplaneModeState?.value,
                          ultraWidebandState: values?.ultraWidebandState?.value,
                          cellularTwoGState: values?.cellularTwoGState?.value,
                          minimumWifiSecurityLevel: values?.minimumWifiSecurityLevel?.value
                      }
                    : undefined,
            mobileNetworksConfigDisabled: !values.mobileNetworksConfigDisabled,
            cellBroadcastsConfigDisabled: !values.cellBroadcastsConfigDisabled,
            createWindowsDisabled: !values.createWindowsDisabled,
            networkResetDisabled: !values.networkResetDisabled,
            usbFileTransferDisabled: !values.usbFileTransferDisabled,
            vpnConfigDisabled: !values.vpnConfigDisabled,
            alwaysOnVpnPackage: values.vpnConfigDisabled
                ? { packageName: values.packageName, lockdownEnabled: values.lockdownEnabled }
                : undefined,
            dataRoamingDisabled: !values.dataRoamingDisabled,
            networkEscapeHatchEnabled: values.networkEscapeHatchEnabled,
            deviceConnectivityManagement:
                values?.usbDataAccess?.value ||
                values?.configureWifi?.value ||
                values?.wifiDirectSettings?.value ||
                values?.tetheringSettings?.value ||
                values?.wifiSsidPolicyType?.value
                    ? {
                          usbDataAccess: values?.usbDataAccess?.value,
                          configureWifi: values?.configureWifi?.value,
                          wifiDirectSettings: values?.wifiDirectSettings?.value,
                          tetheringSettings: values?.tetheringSettings?.value,
                          wifiSsidPolicy: values?.wifiSsidPolicyType?.value
                              ? { wifiSsidPolicyType: values?.wifiSsidPolicyType?.value, wifiSsids: ssids }
                              : undefined
                      }
                    : undefined,

            // Security Settings
            screenCaptureDisabled: !values.screenCaptureDisabled,
            mountPhysicalMediaDisabled: !values.mountPhysicalMediaDisabled,
            factoryResetDisabled: !values.factoryResetDisabled,
            credentialsConfigDisabled: !values.credentialsConfigDisabled,
            installAppsDisabled: !values.installAppsDisabled,
            uninstallAppsDisabled: !values.uninstallAppsDisabled,
            advancedSecurityOverrides:
                values?.googlePlayProtectVerifyApps?.value || values?.developerSettings?.value || values?.untrustedAppsPolicy?.value
                    ? {
                          googlePlayProtectVerifyApps: values?.googlePlayProtectVerifyApps?.value,
                          developerSettings: values?.developerSettings?.value,
                          untrustedAppsPolicy: values?.untrustedAppsPolicy?.value
                      }
                    : undefined,
            // Launcher Settings
            kioskCustomLauncherEnabled: !!values.kioskCustomLauncherEnabled,
            kioskCustomization:
                values?.powerButtonActions?.value ||
                values?.systemErrorWarnings?.value ||
                values?.systemNavigation?.value ||
                values?.statusBar?.value ||
                values?.deviceSettings?.value
                    ? {
                          powerButtonActions: values?.powerButtonActions?.value,
                          systemErrorWarnings: values?.systemErrorWarnings?.value,
                          systemNavigation: values?.systemNavigation?.value,
                          statusBar: values?.statusBar?.value,
                          deviceSettings: values?.deviceSettings?.value
                      }
                    : undefined,

            // Compliance Polices
            encryptionPolicy: values?.encryptionPolicy?.value,
            // autoUpdateMode: values?.autoUpdateMode?.value,
            appAutoUpdatePolicy: values?.appAutoUpdatePolicy?.value,
            systemUpdate: values?.systemUpdate?.value
                ? values?.systemUpdate?.value === 'WINDOWED'
                    ? {
                          type: values?.systemUpdate?.value,
                          startMinutes: 1,
                          endMinutes: 60,
                          freezePeriods:
                              values?.startDate && values?.endDate
                                  ? [
                                        {
                                            startDate: values?.startDate
                                                ? {
                                                      year: values?.startDate
                                                          ? Number(new Date(values?.startDate)?.getFullYear())
                                                          : undefined,
                                                      month: values?.startDate
                                                          ? Number(new Date(values?.startDate)?.getMonth() + 1)
                                                          : undefined,
                                                      day: values?.startDate ? Number(new Date(values?.startDate)?.getDate()) : undefined
                                                  }
                                                : undefined,
                                            endDate: values?.endDate
                                                ? {
                                                      year: values?.endDate ? Number(new Date(values?.endDate)?.getFullYear()) : undefined,
                                                      month: values?.endDate
                                                          ? Number(new Date(values?.endDate)?.getMonth() + 1)
                                                          : undefined,
                                                      day: values?.endDate ? Number(new Date(values?.endDate)?.getDate()) : undefined
                                                  }
                                                : undefined
                                        }
                                    ]
                                  : undefined
                      }
                    : { type: values?.systemUpdate?.value }
                : undefined,

            // User and Account Management
            addUserDisabled: !values.addUserDisabled,
            removeUserDisabled: !values.removeUserDisabled,
            modifyAccountsDisabled: !values.modifyAccountsDisabled,
            playStoreMode: values?.playStoreMode?.value,

            // Keyguard Settings
            keyguardDisabled: !values.keyguardDisabled,
            keyguardDisabledFeatures:
                !!values.keyguardDisabled && values.keyguardDisabledFeatures?.length > 0
                    ? values.keyguardDisabledFeatures.map((ele) => ele.value)
                    : undefined,

            // Status Reporting settings
            statusReportingSettings: {
                ...defaultData.statusReportingSettings,
                applicationReportsEnabled: values.applicationReportsEnabled,
                deviceSettingsEnabled: values.deviceSettingsEnabled,
                softwareInfoEnabled: values.softwareInfoEnabled,
                networkInfoEnabled: values.networkInfoEnabled,
                systemPropertiesEnabled: values.systemPropertiesEnabled
                /*
                 * powerManagementEventsEnabled: values.powerManagementEventsEnabled,
                 * memoryInfoEnabled: values.memoryInfoEnabled,
                 * displayInfoEnabled: values.displayInfoEnabled,
                 * hardwareStatusEnabled: values.hardwareStatusEnabled,
                 * commonCriteriaModeEnabled: values.commonCriteriaModeEnabled
                 */
            },
            // Setup Actions
            setupActions: values.setupActions?.length > 0 ? prepareSetupActions(values) : undefined,

            // Applications
            applications:
                Object.keys(applicationObj)?.length > 0
                    ? prepareApplicationsData(
                          applicationObj,
                          availableApplications,
                          launcherPackagename,
                          tenant,
                          accountId,
                          values,
                          formValues,
                          polType,
                          tectoroApplications
                      )
                    : undefined,
            permittedAccessibilityServices:
                preparePermittedAccessibilityServices(applicationObj, availableApplications)?.length > 0
                    ? { packageNames: preparePermittedAccessibilityServices(applicationObj, availableApplications) }
                    : undefined,
            persistentPreferredActivities:
                applicationObj?.[launcherPackagename] && polType === 'FM'
                    ? [
                          {
                              receiverActivity: launcherPackagename + '/com.tectoro.emm.launcher.AutoOpenActivity',
                              actions: ['android.intent.action.MAIN'],
                              categories: ['android.intent.category.HOME', 'android.intent.category.DEFAULT']
                          }
                      ]
                    : undefined
        }
    };
    let workProfileDataObj = {
        name: values.policyName,
        enterpriseId: enterpriseObj.id,
        status: formValues?.status ? formValues?.status : 'Active',
        policyType: formValues?.policyType ? formValues.policyType : polType?.toUpperCase(),
        policy: {
            ...defaultData,
            name: values.policyName,
            // General Settings
            cameraAccess: values?.cameraAccess?.value,
            stayOnPluggedModes: values.stayOnPluggedModes?.length > 0 ? values.stayOnPluggedModes.map((ele) => ele?.value) : undefined,
            // frpAdminEmails: values.frpAdminEmails?.length > 0 ? values.frpAdminEmails : undefined,

            // Display Settings
            skipFirstUseHintsEnabled: values.skipFirstUseHintsEnabled,
            maximumTimeToLock: values?.maximumTimeToLock?.value,

            // Password Settings
            credentialProviderPolicyDefault: values?.credentialProviderPolicyDefault?.value || undefined,
            passwordPolicies:
                values?.passwordQuality?.value ||
                values.passwordHistoryLength ||
                values.passwordExpirationTimeout ||
                values.maximumFailedPasswordsForWipe
                    ? // values?.unifiedLockSettings?.value
                      [
                          {
                              passwordQuality: values?.passwordQuality?.value,
                              //   unifiedLockSettings: values?.unifiedLockSettings?.value,
                              passwordHistoryLength: values.passwordHistoryLength,
                              passwordScope: formValues.passwordPolicies?.[0]?.passwordScope,
                              passwordExpirationTimeout: `${values.passwordExpirationTimeout}s`,
                              maximumFailedPasswordsForWipe: values.maximumFailedPasswordsForWipe
                          }
                      ]
                    : undefined,

            // Communication Settings
            bluetoothContactSharingDisabled: !values.bluetoothContactSharingDisabled,
            outgoingBeamDisabled: !values.outgoingBeamDisabled,
            shareLocationDisabled: !values.shareLocationDisabled,
            printingPolicy: values?.printingPolicy?.value,
            defaultPermissionPolicy: values.defaultPermissionPolicyInPolicy?.value,

            // Network Settings
            vpnConfigDisabled: !values.vpnConfigDisabled,
            alwaysOnVpnPackage: values.vpnConfigDisabled
                ? { packageName: values.packageName, lockdownEnabled: values.lockdownEnabled }
                : undefined,
            preferentialNetworkService: values?.preferentialNetworkService?.value,

            // Compliance Polices
            encryptionPolicy: values?.encryptionPolicy?.value,
            appAutoUpdatePolicy: values?.appAutoUpdatePolicy?.value,
            systemUpdate: values?.systemUpdate?.value
                ? values?.systemUpdate?.value === 'WINDOWED'
                    ? {
                          type: values?.systemUpdate?.value,
                          startMinutes: 1,
                          endMinutes: 60,
                          freezePeriods: [
                              {
                                  startDate: {
                                      year: Number(new Date(values?.startDate)?.getFullYear()),
                                      month: Number(new Date(values?.startDate)?.getMonth() + 1),
                                      day: Number(new Date(values?.startDate)?.getDate())
                                  },
                                  endDate: {
                                      year: Number(new Date(values?.endDate)?.getFullYear()),
                                      month: Number(new Date(values?.endDate)?.getMonth() + 1),
                                      day: Number(new Date(values?.endDate)?.getDate())
                                  }
                              }
                          ]
                      }
                    : { type: values?.systemUpdate?.value }
                : undefined,

            // Security Settings
            screenCaptureDisabled: !values.screenCaptureDisabled,
            credentialsConfigDisabled: !values.credentialsConfigDisabled,
            mountPhysicalMediaDisabled: !values.mountPhysicalMediaDisabled,
            installAppsDisabled: !values.installAppsDisabled,
            uninstallAppsDisabled: !values.uninstallAppsDisabled,
            advancedSecurityOverrides:
                values?.googlePlayProtectVerifyApps?.value || values?.developerSettings?.value || values?.untrustedAppsPolicy?.value
                    ? {
                          googlePlayProtectVerifyApps: values?.googlePlayProtectVerifyApps?.value,
                          developerSettings: values?.developerSettings?.value,
                          untrustedAppsPolicy: values?.untrustedAppsPolicy?.value
                      }
                    : undefined,

            /*
             *   BYOD Settings.
             * personalUsagePolicies:
             *     values?.personalPlayStoreMode?.value ||
             *     values?.maxDaysWithWorkOff ||
             *     values?.personalCameraDisabled ||
             *     values?.personalScreenCaptureDisabled
             *         ? {
             *               cameraDisabled: !values?.personalCameraDisabled,
             *               screenCaptureDisabled: !values?.personalScreenCaptureDisabled,
             *               maxDaysWithWorkOff: values?.maxDaysWithWorkOff,
             *               personalPlayStoreMode: values?.personalPlayStoreMode?.value
             *           }
             *         : undefined,
             */

            crossProfilePolicies:
                values?.showWorkContactsInPersonalProfile?.value ||
                values?.crossProfileCopyPaste?.value ||
                values?.crossProfileDataSharing?.value ||
                values?.workProfileWidgetsDefault?.value
                    ? {
                          showWorkContactsInPersonalProfile: values?.showWorkContactsInPersonalProfile?.value,
                          crossProfileCopyPaste: values?.crossProfileCopyPaste?.value,
                          crossProfileDataSharing: values?.crossProfileDataSharing?.value,
                          workProfileWidgetsDefault: values?.workProfileWidgetsDefault?.value
                      }
                    : undefined,

            // User and Account Management
            addUserDisabled: !values.addUserDisabled,
            removeUserDisabled: !values.removeUserDisabled,
            modifyAccountsDisabled: !values.modifyAccountsDisabled,

            // Wi-Fi settings
            openNetworkConfiguration:
                wifiConfiguration?.length > 0 ? { NetworkConfigurations: prepareWifiConfiguration(wifiConfiguration) } : undefined,

            // Keyguard Settings
            keyguardDisabled: !values.keyguardDisabled,
            keyguardDisabledFeatures:
                !!values.keyguardDisabled && values.keyguardDisabledFeatures?.length > 0
                    ? values.keyguardDisabledFeatures.map((ele) => ele.value)
                    : undefined,

            // Status Reporting settings
            statusReportingSettings: {
                ...defaultData.statusReportingSettings,
                applicationReportsEnabled: values.applicationReportsEnabled,
                deviceSettingsEnabled: values.deviceSettingsEnabled,
                softwareInfoEnabled: values.softwareInfoEnabled,
                networkInfoEnabled: values.networkInfoEnabled,
                systemPropertiesEnabled: values.systemPropertiesEnabled
                /*
                 * powerManagementEventsEnabled: values.powerManagementEventsEnabled,
                 * memoryInfoEnabled: values.memoryInfoEnabled,
                 * displayInfoEnabled: values.displayInfoEnabled,
                 * hardwareStatusEnabled: values.hardwareStatusEnabled,
                 * commonCriteriaModeEnabled: values.commonCriteriaModeEnabled
                 */
            },

            /*
             * Setup Actions
             * setupActions: values.setupActions?.length > 0 ? prepareSetupActions() : undefined,
             */

            // Applications
            applications:
                Object.keys(applicationObj)?.length > 0
                    ? prepareApplicationsData(
                          applicationObj,
                          availableApplications,
                          launcherPackagename,
                          tenant,
                          accountId,
                          values,
                          formValues,
                          polType
                      )
                    : undefined,
            permittedAccessibilityServices:
                preparePermittedAccessibilityServices(applicationObj, availableApplications)?.length > 0
                    ? { packageNames: preparePermittedAccessibilityServices(applicationObj, availableApplications) }
                    : undefined
        }
    };
    let dedictedDataObj = {
        name: values.policyName,
        enterpriseId: enterpriseObj.id,
        status: formValues?.status ? formValues?.status : 'Active',
        policyType: formValues?.policyType ? formValues.policyType : polType?.toUpperCase(),
        policy: {
            ...defaultData,
            name: values.policyName,
            // General Settings
            stayOnPluggedModes: values.stayOnPluggedModes?.length > 0 ? values.stayOnPluggedModes.map((ele) => ele.value) : undefined,
            frpAdminEmails: values.frpAdminEmails?.length > 0 ? values.frpAdminEmails : undefined,
            //* ** Display Settings */
            skipFirstUseHintsEnabled: values.skipFirstUseHintsEnabled,
            setUserIconDisabled: !values.setUserIconDisabled,
            maximumTimeToLock: values?.maximumTimeToLock?.value,
            deviceOwnerLockScreenInfo: values.deviceOwnerLockScreenInfo ? { defaultMessage: values.deviceOwnerLockScreenInfo } : undefined,
            shortSupportMessage: values.shortSupportMessage ? { defaultMessage: values.shortSupportMessage } : undefined,
            longSupportMessage: values.longSupportMessage ? { defaultMessage: values.longSupportMessage } : undefined,
            // Password Settings
            credentialProviderPolicyDefault: values?.credentialProviderPolicyDefault?.value || undefined,
            passwordPolicies:
                values?.passwordQuality?.value ||
                values.passwordHistoryLength ||
                values.passwordExpirationTimeout ||
                values.maximumFailedPasswordsForWipe
                    ? [
                          {
                              passwordQuality: values?.passwordQuality?.value,
                              passwordHistoryLength: values.passwordHistoryLength,
                              passwordScope: formValues.passwordPolicies?.[0]?.passwordScope,
                              passwordExpirationTimeout: `${values.passwordExpirationTimeout}s`,
                              maximumFailedPasswordsForWipe: values.maximumFailedPasswordsForWipe
                          }
                      ]
                    : undefined,
            //* ** Communication Settings */
            locationMode: values?.locationMode?.value,
            outgoingBeamDisabled: !values.outgoingBeamDisabled,
            printingPolicy: values?.printingPolicy?.value,
            defaultPermissionPolicy: values.defaultPermissionPolicyInPolicy?.value,

            // Network Settings
            deviceRadioState:
                values?.wifiState?.value ||
                values?.airplaneModeState?.value ||
                values?.ultraWidebandState?.value ||
                values?.cellularTwoGState?.value ||
                values?.minimumWifiSecurityLevel?.value
                    ? {
                          wifiState: values?.wifiState?.value,
                          airplaneModeState: values?.airplaneModeState?.value,
                          ultraWidebandState: values?.ultraWidebandState?.value,
                          cellularTwoGState: values?.cellularTwoGState?.value,
                          minimumWifiSecurityLevel: values?.minimumWifiSecurityLevel?.value
                      }
                    : undefined,
            vpnConfigDisabled: !values.vpnConfigDisabled,
            alwaysOnVpnPackage: values.vpnConfigDisabled
                ? { packageName: values.packageName, lockdownEnabled: values.lockdownEnabled }
                : undefined,
            createWindowsDisabled: !values.createWindowsDisabled,
            usbFileTransferDisabled: !values.usbFileTransferDisabled,
            deviceConnectivityManagement:
                values?.usbDataAccess?.value ||
                values?.configureWifi?.value ||
                values?.wifiDirectSettings?.value ||
                values?.tetheringSettings?.value ||
                values?.wifiSsidPolicyType?.value
                    ? {
                          usbDataAccess: values?.usbDataAccess?.value,
                          configureWifi: values?.configureWifi?.value,
                          wifiDirectSettings: values?.wifiDirectSettings?.value,
                          tetheringSettings: values?.tetheringSettings?.value,
                          wifiSsidPolicy: values?.wifiSsidPolicyType?.value
                              ? { wifiSsidPolicyType: values?.wifiSsidPolicyType?.value, wifiSsids: ssids }
                              : undefined
                      }
                    : undefined,

            //* ** Security Settings */
            screenCaptureDisabled: !values.screenCaptureDisabled,
            mountPhysicalMediaDisabled: !values.mountPhysicalMediaDisabled,
            factoryResetDisabled: !values.factoryResetDisabled,
            credentialsConfigDisabled: !values.credentialsConfigDisabled,
            advancedSecurityOverrides:
                values?.googlePlayProtectVerifyApps?.value || values?.developerSettings?.value || values?.untrustedAppsPolicy?.value
                    ? {
                          googlePlayProtectVerifyApps: values?.googlePlayProtectVerifyApps?.value,
                          developerSettings: values?.developerSettings?.value,
                          untrustedAppsPolicy: values?.untrustedAppsPolicy?.value
                      }
                    : undefined,
            // Compliance Polices
            encryptionPolicy: values?.encryptionPolicy?.value,
            appAutoUpdatePolicy: values?.appAutoUpdatePolicy?.value,
            systemUpdate: values?.systemUpdate?.value
                ? values?.systemUpdate?.value === 'WINDOWED'
                    ? {
                          type: values?.systemUpdate?.value,
                          startMinutes: 1,
                          endMinutes: 60,
                          freezePeriods: [
                              {
                                  startDate: {
                                      year: Number(new Date(values?.startDate)?.getFullYear()),
                                      month: Number(new Date(values?.startDate)?.getMonth() + 1),
                                      day: Number(new Date(values?.startDate)?.getDate())
                                  },
                                  endDate: {
                                      year: Number(new Date(values?.endDate)?.getFullYear()),
                                      month: Number(new Date(values?.endDate)?.getMonth() + 1),
                                      day: Number(new Date(values?.endDate)?.getDate())
                                  }
                              }
                          ]
                      }
                    : { type: values?.systemUpdate?.value }
                : undefined,
            // Wi-Fi settings
            openNetworkConfiguration:
                wifiConfiguration?.length > 0 ? { NetworkConfigurations: prepareWifiConfiguration(wifiConfiguration) } : undefined,
            // Launcher Settings
            kioskCustomLauncherEnabled: !!values.kioskCustomLauncherEnabled,
            kioskCustomization:
                values?.powerButtonActions?.value ||
                values?.systemErrorWarnings?.value ||
                values?.systemNavigation?.value ||
                values?.statusBar?.value ||
                values?.deviceSettings?.value
                    ? {
                          powerButtonActions: values?.powerButtonActions?.value,
                          systemErrorWarnings: values?.systemErrorWarnings?.value,
                          systemNavigation: values?.systemNavigation?.value,
                          statusBar: values?.statusBar?.value,
                          deviceSettings: values?.deviceSettings?.value
                      }
                    : undefined,
            // Keyguard Settings
            keyguardDisabled: !values.keyguardDisabled,
            keyguardDisabledFeatures:
                !!values.keyguardDisabled && values.keyguardDisabledFeatures?.length > 0
                    ? values.keyguardDisabledFeatures.map((ele) => ele.value)
                    : undefined,
            // Status Reporting settings
            statusReportingSettings: {
                ...defaultData.statusReportingSettings,
                applicationReportsEnabled: values.applicationReportsEnabled,
                deviceSettingsEnabled: values.deviceSettingsEnabled,
                softwareInfoEnabled: values.softwareInfoEnabled,
                networkInfoEnabled: values.networkInfoEnabled,
                systemPropertiesEnabled: values.systemPropertiesEnabled
                /*
                 * powerManagementEventsEnabled: values.powerManagementEventsEnabled,
                 * memoryInfoEnabled: values.memoryInfoEnabled,
                 * displayInfoEnabled: values.displayInfoEnabled,
                 * hardwareStatusEnabled: values.hardwareStatusEnabled,
                 * commonCriteriaModeEnabled: values.commonCriteriaModeEnabled
                 */
            },

            // User and Account Management
            addUserDisabled: !values.addUserDisabled,
            removeUserDisabled: !values.removeUserDisabled,
            modifyAccountsDisabled: !values.modifyAccountsDisabled,
            playStoreMode: values?.playStoreMode?.value,

            // Setup Actions
            setupActions: values.setupActions?.length > 0 ? prepareSetupActions(values) : undefined,

            // Applications
            applications:
                Object.keys(applicationObj)?.length > 0
                    ? prepareApplicationsData(
                          applicationObj,
                          availableApplications,
                          launcherPackagename,
                          tenant,
                          accountId,
                          values,
                          formValues,
                          polType
                      )
                    : undefined,
            permittedAccessibilityServices:
                preparePermittedAccessibilityServices(applicationObj, availableApplications)?.length > 0
                    ? { packageNames: preparePermittedAccessibilityServices(applicationObj, availableApplications) }
                    : undefined
        }
    };

    return { fullyManagedDataObj: fullyManagedDataObj, workProfileDataObj: workProfileDataObj, dedictedDataObj: dedictedDataObj };
};

const prepareWifiConfiguration = (wifiConfiguration) => {
    let list = [];
    if (wifiConfiguration?.length > 0) {
        wifiConfiguration.forEach((ele) => {
            list.push({
                Type: 'WiFi',
                GUID: guid(),
                Name: ele.wifiName,
                WiFi:
                    ele.security === 'None'
                        ? { SSID: ele.ssid, Security: ele.security?.value, HiddenSSID: ele.HiddenSSID, AutoConnect: true }
                        : {
                              SSID: ele.ssid,
                              Security: ele.security?.value,
                              Passphrase: ele.security?.value ? ele.passphrase : undefined,
                              HiddenSSID: ele.HiddenSSID,
                              AutoConnect: true
                          }
            });
        });
    }
    return list;
};

const prepareSetupActions = (values) => {
    let arr = [];
    values.setupActions.forEach((action) =>
        arr.push({
            title: { defaultMessage: action.title },
            description: { defaultMessage: action.description },
            launchApp: { packageName: action.launchApp }
        })
    );
    return arr;
};

const prepareApplicationsData = (
    applicationObj,
    availableApplications,
    launcherPackagename,
    tenant,
    accountId,
    values,
    formValues,
    polType,
    tectoroApplications
) => {
    const authModeString = GetAuthmodes();
    const authModes = authModeString ? JSON.parse(authModeString) : [];
    let list = [];
    Object.entries(applicationObj).forEach((obj) => {
        let managedConfig = obj[1].managedConfigId?.config?.config
            ? obj[1].managedConfigId?.config?.config
            : obj[1].managedConfigId?.config;
        if (availableApplications.includes(obj[0])) {
            let appObj = {
                packageName: obj[0],
                // disabled: obj[1].disabled,
                installType: obj[1].kiosk ? 'KIOSK' : obj[1]?.installType?.value,
                autoUpdateMode: obj[1]?.autoUpdateMode?.value,
                managedConfigurationTemplate:
                    tectoroApplications?.includes(obj[0]) || obj[0] === launcherPackagename
                        ? undefined
                        : !Object.entries(obj[1]?.managedConfigId?.config ? obj[1]?.managedConfigId?.config : {})?.length
                        ? {
                              templateId: obj[1]?.managedConfigId?.managedConfigId?.toString()
                                  ? obj[1]?.managedConfigId?.managedConfigId?.toString()
                                  : obj[1]?.managedConfigId?.[0]?.managedConfigId?.toString()
                                  ? obj[1]?.managedConfigId?.[0]?.managedConfigId?.toString()
                                  : obj[1]?.managedConfigId?.toString()
                                  ? obj[1]?.managedConfigId?.toString()
                                  : ''
                          }
                        : undefined,
                managedConfiguration: Object.entries(obj[1]?.managedConfigId?.config ? obj[1]?.managedConfigId?.config : {})?.length
                    ? {
                          ...managedConfig,
                          tenant: accountId,
                          baseURL: baseURL(),
                          authMode: authModes?.map((mode) => mode.mode),
                          managedConfigId: obj[1]?.managedConfigId?.managedConfigId?.toString(),
                          title: obj[1]?.managedConfigId?.title
                      }
                    : obj[0] === launcherPackagename
                    ? {
                          tenant: accountId,
                          baseURL: baseURL(),
                          authMode: authModes?.map((mode) => mode.mode),
                          branding: defaultBranding,
                          deviceSettings: {
                              refresh_screen_wifi: {
                                  show: true,
                                  req_password: true,
                                  password_type: 'true',
                                  password: tenant.DEVICE_DEFAULT_PASSWORD,
                                  req_timer: false,
                                  timer: 0
                              }
                          }
                      }
                    : tectoroApplications?.includes(obj[0])
                    ? { tenant: accountId, baseURL: baseURL(), authMode: authModes?.map((mode) => mode.mode) }
                    : undefined,
                defaultPermissionPolicy: obj[1]?.defaultPermissionPolicy?.value,
                delegatedScopes: obj[1].delegatedScopes?.length > 0 ? obj[1].delegatedScopes.map((ele) => ele.value) : undefined,
                permissionGrants: obj[1].permissionGrants?.length > 0 ? preparePermissonGrants(obj) : undefined,
                alwaysOnVpnLockdownExemption: obj[1].alwaysOnVpnLockdownExemption?.value,
                extensionConfig: obj[1].extensionConfig
                    ? {
                          signingKeyFingerprintsSha256: [],
                          notificationReceiver: 'com.tectoro.emm.extensionapp.receiver.SampleCommandService'
                      }
                    : undefined,
                accessibleTrackIds: obj[1]?.accessibleTrackIds?.trackId ? [obj[1]?.accessibleTrackIds?.trackId] : undefined,
                connectedWorkAndPersonalApp: obj[1]?.connectedWorkAndPersonalApp?.value
                    ? obj[1]?.connectedWorkAndPersonalApp?.value
                    : undefined,
                workProfileWidgets: obj[1]?.workProfileWidgets?.value ? obj[1]?.workProfileWidgets?.value : undefined,
                credentialProviderPolicy: obj[1]?.credentialProviderPolicy?.value ? obj[1]?.credentialProviderPolicy?.value : undefined,
                installPriority: obj[1]?.installPriority ? obj[1]?.installPriority : undefined,
                userControlSettings: obj[1]?.userControlSettings?.value ? obj[1]?.userControlSettings?.value : undefined
            };
            if (
                (obj[1]?.installType?.value === 'FORCE_INSTALLED' || obj[1]?.installType?.value === 'PREINSTALLED') &&
                (obj[1].networkTypeConstraint?.value || obj[1]?.chargingConstraint?.value || obj[1]?.deviceIdleConstraint?.value)
            ) {
                appObj.installConstraint = [
                    {
                        networkTypeConstraint: obj[1].networkTypeConstraint?.value,
                        chargingConstraint: obj[1]?.chargingConstraint?.value,
                        deviceIdleConstraint: obj[1]?.deviceIdleConstraint?.value
                    }
                ];
            }
            list.push(appObj);
        }
    });
    return list;
};

const preparePermissonGrants = (obj) => {
    let listArr = [];
    obj[1].permissionGrants.forEach((ele) => {
        listArr.push({ permission: ele.value, policy: 'GRANT' });
    });
    return listArr;
};

const preparePermittedAccessibilityServices = (applicationObj, availableApplications) => {
    let listArr = [];
    Object.entries(applicationObj).forEach((obj) => {
        if (availableApplications.includes(obj[0]) && obj[1].permittedAccessibilityServices) listArr.push(obj[0]);
    });
    return listArr;
};
