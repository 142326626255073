/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { ODM_FEATURES_SCHEMA } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { dynamicComponents } from './DynamicComponents';

const AddAcerODMFeatures = () => {
    let history = useHistory();
    let api = new APIClient();

    const config = useEnv();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title =
        formType === 'add' ? 'Add Acer ODM Features' : formType === 'edit' ? 'Edit Acer ODM Features' : 'View Acer ODM Features';

    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState('');
    const [odmFeaturesSchema, setOdmFeaturesSchema] = useState(ODM_FEATURES_SCHEMA);

    useEffect(() => {
        if (recordID) getOneConfig();
    }, []);

    const getOneConfig = () => {
        setLoading(true);
        api.get(url.CONFIG + '/' + recordID)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setFormValues(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: formValues.name ? formValues.name : '',
            // Navigation Bar
            home: formValues?.config?.navigationBar?.home ? formValues?.config?.navigationBar?.home : false,
            recent: formValues?.config?.navigationBar?.recent ? formValues?.config?.navigationBar?.recent : false,
            back: formValues?.config?.navigationBar?.back ? formValues?.config?.navigationBar?.back : false,
            // Hard Keys
            volumeUp: formValues?.config?.hardKeys?.volumeUp ? formValues?.config?.hardKeys?.volumeUp : false,
            volumeDown: formValues?.config?.hardKeys?.volumeDown ? formValues?.config?.hardKeys?.volumeDown : false,
            power: formValues?.config?.hardKeys?.power ? formValues?.config?.hardKeys?.power : false,
            notifications: formValues?.config?.hardKeys?.notifications ? formValues?.config?.hardKeys?.notifications : false,
            factoryMode: formValues?.config?.hardKeys?.factoryMode ? formValues?.config?.hardKeys?.factoryMode : false
        },
        onSubmit: (values) => {
            setLoading(true);
            let obj = {
                name: values.name,
                configType: 'ACERODMFEATURES',
                config: {
                    navigationBar: {
                        home: validation.values.home,
                        recent: validation.values.recent,
                        back: validation.values.back
                    },
                    hardKeys: {
                        volumeUp: validation.values.volumeUp,
                        volumeDown: validation.values.volumeDown,
                        power: validation.values.power,
                        notifications: validation.values.notifications,
                        factoryMode: validation.values.factoryMode
                    }
                }
            };
            if (formType === 'edit') obj.id = recordID;
            createNdUpdate(obj);
        }
    });

    const createNdUpdate = (dataObj) => {
        let apiService;
        if (recordID) apiService = api.update(url.CONFIG + '/' + recordID, dataObj);
        else apiService = api.create(url.CONFIG, dataObj);

        apiService
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.acerODMFeatureConfigUpdate : toastMessages.acerODMFeatureCreate);
                    history.push('/acerodmfeatures');
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${formType?.charAt(0)?.toUpperCase() + formType?.slice(1)} Acer ODM Features`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="acerodmfeatures"
                    />

                    <Card className="p-3">
                        <CardHeader className="d-flex align-items-start justify-content-center">
                            <Col>
                                <div className="d-flex align-items-center">
                                    <span className="fw-semibold fs-15 mb-1">Acer ODM Features</span>
                                    <span className="ms-2 lh-lg badge badge-soft-warning border">Applicable only for Acer Devices</span>
                                </div>
                                <span className="fs-13">
                                    Acer ODM features customizable hardware, robust quality control, tailored software integration, and
                                    dedicated customer support
                                </span>
                                <div className="d-flex align-items-center mt-3">
                                    Name
                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                    <div className={'input-group'}>
                                        {formType !== 'view' ? (
                                            <div className="w-25 ms-5">
                                                <Input
                                                    name={'name'}
                                                    id={'name'}
                                                    className={'form-control'}
                                                    placeholder={'Enter Name'}
                                                    type={'text'}
                                                    maxLength={'30'}
                                                    validate={{ required: { value: true } }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values['name'] || ''}
                                                    invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                                />
                                            </div>
                                        ) : validation?.values['name'] ? (
                                            <span className="ps-1">{validation?.values['name']}</span>
                                        ) : (
                                            '–'
                                        )}
                                        {validation.touched['name'] && validation.errors['name'] ? (
                                            <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                        </CardHeader>

                        <CardBody>
                            {odmFeaturesSchema.map((ele, index) => {
                                return (
                                    <Row className={ele.isHeader ? 'mb-0' : 'mb-3'} key={index}>
                                        {dynamicComponents({ index: index, field: ele, formType: formType, validation: validation })}
                                    </Row>
                                );
                            })}
                        </CardBody>

                        <CardFooter>
                            <Row className="p-0 m-0">
                                <Col className="p-0 m-0">
                                    <div className="gap-2 d-flex justify-content-end">
                                        <button type="reset" className="btn btn-light" onClick={() => history.push('/acerodmfeatures')}>
                                            Cancel
                                        </button>
                                        {formType !== 'view' && (
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                                id="add-btn"
                                                disabled={!validation.values['name'] || validation.values['name'] === '' || loading}
                                                onClick={(e) => validation.handleSubmit()}
                                            >
                                                {formValues?.id ? 'Update' : 'Save'}
                                            </button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddAcerODMFeatures;
