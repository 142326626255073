/* eslint-disable no-console */

export const loadEnvVariables = async () => {
    let API_URL = window.location.hostname === 'localhost' ? 'https://portal.mdmdev.tectoro.com' : window.location.origin;

    try {
        const response = await fetch(API_URL + '/mdm-commons/v1/web');
        if (!response.ok) {
            throw new Error('Failed to fetch environment variables');
        }
        const envVars = await response.json();
        return envVars?.data;
    } catch (error) {
        console.error('Error loading environment variables:', error);
        return null;
    }
};
