import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Container, Input, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import { CustomDPCDeviceActions, commonActionsService } from '../../Components/Common/DeviceUtils';
import Loader from '../../Components/Common/Loader';
import TableContainer from '../../Components/Common/TableContainer';
import { AuthUser, TenantConfig, Tooltip, convertBytesTo, noCaseSensitiveSortBy } from '../../Components/Common/Util';
import applicationsIcon from '../../assets/images/applications.svg';
import lockDevice from '../../assets/images/lockDevice.png';
import nonCompliance from '../../assets/images/nonCompliance.svg';
import rebootDevice from '../../assets/images/rebootDevice.png';
import Scream from '../../assets/images/scream.png';
import { nonComplianceReasons } from '../../common/data/dataEnums';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import {
    actionHistoryFunc,
    handleDeviceIdentityDetailsFunc,
    handleDeviceSecurityFunc,
    handleDeviceSummaryFunc,
    handleHardwareInfoFunc,
    handleSimInfoFunc,
    handleSoftwareInfoFunc
} from '../AndroidEnterprise/Devices/AndroidEnterpriseViewDevicesSummary';
import { applicationSchema, nonComplianceSchema } from '../AndroidEnterprise/Devices/TableSchema';

const ViewAndroidDevices = (props) => {
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const recordID = props.recordID;
    const tenantConfig = TenantConfig();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    const [loading, setLoading] = useState(false);
    const [sidePanelLoader, setSidePanelLoader] = useState(false);
    const [hardwareData, setHardwareData] = useState('');
    const [deviceData, setDeviceData] = useState('');
    const [type, setType] = useState('');
    const [policy, setPolicy] = useState();
    const [policyShift, setPolicyShift] = useState({ confirmation: false, details: '' });
    const [deviceState, setDeviceState] = useState();
    const [deviceGroup, setDeviceGroup] = useState();
    const [actionModal, setActionModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [monitoring, setMonitoring] = useState('');
    const [selectedDeviceGroup, setSelectedDeviceGroup] = useState();
    const [nonComplianceDetails, setNonComplianceDetails] = useState([]);
    const [applications, setApplications] = useState([]);
    const [commandHistory, setCommandHistory] = useState([]);
    const [simData, setSimData] = useState('');
    const [softwareData, setSoftwareData] = useState({});
    const [showDeviceGroup, setShowDeviceGroup] = useState(false);
    const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);
    const [policyOptions, setPolicyOptions] = useState([]);
    const [showDeviceState, setShowDeviceState] = useState(false);
    const [simValue, setSimValue] = useState({ sim1: '', sim2: '' });
    const [disableActions, setDisableActions] = useState({ appUsageReport: true, violations: true, locationhistory: true, geofence: true });
    const [showEditSim, setShowEditSim] = useState({ sim1: false, sim2: false });
    const [deviceGroupOptions, setDeviceGroupsOptions] = useState([]);
    const [deviceGroupOptionsBackup, setDeviceGroupsOptionsBackup] = useState([]);
    const [applicationsInPolicy, setApplicationsInPolicy] = useState([]);
    const [policyBackup, setPolicyBackup] = useState();
    const [policies, setPolicies] = useState([]);
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState();
    const deviceStateOptions = [
        { label: 'ACTIVE', name: 'ACTIVE', value: 'ACTIVE' },
        { label: 'DISABLED', name: 'DISABLED', value: 'DISABLED' }
    ];

    useEffect(() => {
        let policiesParam = { page: 1, size: 2000 };
        const deviceGroupPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICE_GROUPS, '', domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp.data);
                })
                .catch((err) => reject('Groups failed.'));
        });

        const commandHistoryPromise = new Promise((resolve, reject) => {
            if (recordID) {
                let params = { page: 1, size: 2000 };
                api.get(url.DEVICES + '/' + recordID + '/command', params)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        resolve(resp.data);
                    })
                    .catch((err) => reject('Command histor failed.'));
            } else {
                reject('No record ID.');
            }
        });

        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.MY_DEVICE + '/' + recordID)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    policiesParam['type'] = resp.data.policyType === 'WP' ? resp.data.policyType : 'FM,SA,KL,TL';
                    resolve(resp.data);
                })
                .catch((err) => reject('Device failed.'));
        });
        Promise.allSettled([deviceGroupPromise, commandHistoryPromise, devicePromise])
            .then(async (result) => {
                let simObj = {};
                let policiesArr = [];
                let polArr = [];
                let arr = [];
                if (result[0].status === 'fulfilled') {
                    if (result[0].value?.length > 0) {
                        result[0].value.forEach((group) => {
                            arr.push(group);
                            group.label = group.name;
                            group.key = group.code;
                            group.value = group.code;
                            group.nameCode = group.name + ' - ' + group.code;
                            if (group.children) {
                                group.children = setChildGroups(group.children);
                                arr = setGroups(group.children, arr);
                            }
                            group.childsCount = group.children.length ? group.children.length : 0;
                        });
                        setDeviceGroupsOptions(result[0].value);
                        setDeviceGroupsOptionsBackup(result[0].value);
                    }
                }
                if (result[2].status === 'fulfilled') {
                    setApplicationsInPolicy(result[2].value?.appApplications);
                    result[2].value.showActions = result[2].value?.deviceStatusApplied?.toLowerCase() === 'active';
                    result[2].value.hideFactoryReset =
                        (window.location.hostname === 'apse.emmprod1.tectoro.com' ||
                        window.location.hostname === 'portal.emmprod1.tectoro.com'
                            ? (window.location.hostname === 'apse.emmprod1.tectoro.com'
                                  ? result[2].policyApplied === 'P026' ||
                                    result[2].policyApplied === 'P002' ||
                                    result[2].policyApplied === 'P023' ||
                                    result[2].policyApplied === 'P021' ||
                                    result[2].policyApplied === 'P023' ||
                                    result[2].policyApplied === 'APSE002HRB'
                                  : window.location.hostname === 'portal.emmprod1.tectoro.com'
                                  ? result[2].policyApplied === 'P047' ||
                                    result[2].policyApplied === 'P038' ||
                                    result[2].policyApplied === 'P003'
                                  : false) ||
                              result[2].policy === 'APSE002HRB' ||
                              result[2].policy === 'P1OSPatch' ||
                              result[2].policy === 'P2KSP'
                            : false) ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'deleted' ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'deleting' ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'new' ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'retire';

                    result[2].value.serial = result[2].value.serialEnc ? result[2].value.serialEnc : result[2].value.serial;
                    result[2].value.dataUsed = convertBytesTo(Number(result[2].value.allAppsDailyDataUsage));
                    simObj = {
                        simStatus: result[2]?.value?.details?.sim?.simStatus ? result[2]?.value?.details?.sim?.simStatus : 'Active',
                        iccid1Configured: result[2]?.value?.details?.sim?.iccid1Configured,
                        iccid1Installed: result[2]?.value?.details?.sim?.iccid1Installed,
                        iccid2Configured: result[2]?.value?.details?.sim?.iccid2Configured,
                        iccid2Installed: result[2]?.value?.details?.sim?.iccid2Installed,
                        iccid1Matched:
                            result[2]?.value?.details?.sim?.iccid1Configured !== '' &&
                            result[2]?.value?.details?.sim?.iccid1Configured !== null
                                ? result[2]?.value?.details?.sim?.iccid1Configured === result[2]?.value?.details?.sim?.iccid1Installed
                                : true,
                        iccid2Matched:
                            result[2]?.value?.details?.sim?.iccid2Configured !== '' &&
                            result[2]?.value?.details?.sim?.iccid2Configured !== null
                                ? result[2]?.value?.details?.sim?.iccid2Configured === result[2]?.value?.details?.sim?.iccid2Installed
                                : true
                    };
                    setSimValue({ ...simValue, sim1: result[2]?.value?.details?.Sim1, sim2: result[2]?.value?.details?.Sim2 });
                    setDeviceData(result[2].value);
                    setDeviceState({
                        label: result[2].value?.rawMsg?.state,
                        name: result[2].value?.rawMsg?.state,
                        value: result[2].value?.rawMsg?.state
                    });
                    setPolicy({
                        label: result[2].value?.policyAppliedName,
                        name: result[2].value?.policyApplied + ' - ' + result[2].value?.policyAppliedName,
                        value: result[2].value?.policyApplied
                    });
                    setPolicyBackup({ label: result[2].value?.policyAppliedName, value: result[2].value?.policyApplied });
                    let deviceGroupObj = arr.find((ele) => ele.code === result[2].value?.deviceGroup);
                    setDeviceGroup(deviceGroupObj);
                    setSelectedDeviceGroup(deviceGroupObj);
                    setDefaultSelectedGroup(deviceGroupObj);
                    if (Object.keys(result[2].value?.rawMsg?.hardwareInfo)?.length > 0) {
                        setHardwareData(result[2].value?.rawMsg?.hardwareInfo);
                    }
                    if (result[2].value?.rawMsg?.softwareInfo && Object.keys(result[2].value?.rawMsg?.softwareInfo)?.length > 0) {
                        setSoftwareData(result[2].value?.rawMsg?.softwareInfo);
                    }
                    if (result[2].value?.rawMsg?.applicationReports?.length > 0) {
                        result[2].value.rawMsg.applicationReports.forEach((ele) => {
                            ele.badgeClass = 'success';
                        });
                        let apps = noCaseSensitiveSortBy(result[2].value?.rawMsg?.applicationReports, 'displayName');
                        setApplications(apps);
                    }
                    if (result[2].value?.rawMsg?.nonComplianceDetails?.length > 0) {
                        result[2].value.rawMsg.nonComplianceDetails.forEach((comp) => {
                            comp.packageName = comp.packageName ? comp.packageName : '—';
                            comp.currentValue = comp.currentValue ? comp.currentValue : '—';
                            comp.installationFailureReason = comp.installationFailureReason ? comp.installationFailureReason : '—';
                            comp.specificNonComplianceReason = comp.specificNonComplianceReason ? comp.specificNonComplianceReason : '—';
                            // eslint-disable-next-line max-nested-callbacks
                            nonComplianceReasons.forEach((compReason) => {
                                if (comp.nonComplianceReason === compReason.value) {
                                    comp.reason = compReason.label;
                                }
                            });
                        });
                        setNonComplianceDetails(result[2].value?.rawMsg?.nonComplianceDetails);
                    }
                }
                if (result[1].status === 'fulfilled') {
                    result[1].value?.sort(function (a, b) {
                        return new Date(b?.commandDate) - new Date(a?.commandDate);
                    });
                    setCommandHistory(result[1].value?.slice(0, 20));
                    let deviceValue = result[2].value;
                    let simDetails = result[2].value.details;
                    await handlePoliciesPromise({ policiesParam, polArr, policiesArr, deviceValue, simObj, simDetails });
                }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePoliciesPromise = async ({ policiesParam, polArr, policiesArr, deviceValue, simObj, deviceSimDetails }) => {
        api.get(url.POLICIES, policiesParam)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setPolicies(resp.data);
                if (resp.data.length > 0) {
                    resp?.data.forEach((data) => {
                        if (data.status?.toLowerCase() === 'active') {
                            polArr.push(data);
                            policiesArr.push({ name: data.code + ' - ' + data.name, label: data.name, value: data.code });
                        }
                    });
                    let policyApplied = polArr.find((policyObj) => policyObj.code === deviceValue?.policyApplied);
                    simObj.operator1 = policyApplied?.config?.SIM
                        ? policyApplied?.config?.SIM?.restrictions?.enable_sim
                            ? policyApplied?.config?.SIM?.restrictions?.sim1?.allowedOperator
                            : 'None'
                        : 'Any';
                    simObj.operator2 = policyApplied?.config?.SIM
                        ? policyApplied?.config?.SIM?.restrictions?.enable_sim
                            ? policyApplied?.config?.SIM?.restrictions?.sim2?.allowedOperator
                            : 'None'
                        : 'Any';
                    setSimData(simObj);
                    setDisableActions({
                        ...disableActions,
                        appUsageReport: !!policyApplied?.config?.feature?.enable_app_usage_report,
                        violations: !!policyApplied?.config?.feature?.enable_violations,
                        geofence: !!policyApplied?.config?.GEOFENCE?.featureEnabled
                    });
                }
                setPolicyOptions(policiesArr);
            })
            .catch((err) => toast.error('Policies Api failed.'));
    };

    const getCommandHistory = () => {
        setLoading(true);
        let params = { page: 1, size: 2000 };
        api.get(url.DEVICES + '/' + recordID + '/command', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp) {
                    resp.data?.sort(function (a, b) {
                        return new Date(b?.commandDate) - new Date(a?.commandDate);
                    });
                    setCommandHistory(resp?.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const setGroups = (groups, arr) => {
        groups.forEach((group) => {
            arr.push(group);
            if (group.children) arr = setGroups(group.children, arr);
        });
        return arr;
    };

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const actionItems = [
        {
            id: 'reboot',
            label: 'Reboot',
            icon: 'ri-creative-commons-sa-line',
            clickFunction: () => {
                setType('reboot');
                setActionModal(true);
                setActionText('Yes, Reboot it!');
            },
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'REBOOT'
        },
        {
            id: 'lock',
            label: 'Lock',
            icon: 'ri-phone-lock-line',
            clickFunction: () => {
                setType('lock');
                setActionModal(true);
                setActionText('Yes, Lock it!');
            },
            condition: deviceData.showActions,
            tenantField: 'LOCK'
        },
        {
            id: 'scream',
            label: 'Scream',
            icon: 'ri-volume-up-line',
            clickFunction: () => {
                setType('scream');
                setMonitoring({
                    label: 'Scream',
                    icon: 'ri-volume-up-line',
                    type: 'scream',
                    header: '',
                    action: 'START'
                });
                setActionText('Submit');
                setActionModal(true);
            },
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'SCREAM'
        }
    ];

    const onCancelSIM = (key) => {
        setSimValue({ ...simValue, [key]: deviceData?.details?.[key === 'sim1' ? 'Sim1' : 'Sim2'] });
        setShowEditSim({ ...showEditSim, [key]: false });
    };

    const onSubmitSIM = (key) => {
        if (!(parseInt(key).toString() === key && simValue[key].length === 22)) {
            toast.error(toastMessages.simIdLength);
            return;
        }
        // API call here
    };

    const handleSIMChange = (e, key) => {
        let pattern = new RegExp(/^[0-9\b]+$/);
        let result = pattern.test(e.target.value);
        if (result || e.target.value === '') setSimValue({ ...simValue, [key]: e.target.value });
    };

    const inputComponent = (key) => {
        return (
            <React.Fragment>
                <Input
                    name={key}
                    type={'text'}
                    maxLength={22}
                    placeholder={`Enter SIM ${key === 'sim1' ? '1' : '2'} ID`}
                    onChange={(e) => handleSIMChange(e, key)}
                    value={simValue?.[key] || ''}
                />
                <i title="Close" className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger" onClick={() => onCancelSIM(key)} />
                <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => onSubmitSIM(key)} />
            </React.Fragment>
        );
    };

    const onClickEditSim = (key) => {
        setShowEditSim({ ...showEditSim, [key]: true });
    };
    let handleChangeObj = { setPolicy, setDeviceState, setDeviceGroup };
    let onCancelObj = {
        setShowPolicyDropdown,
        setPolicy,
        setShowDeviceState,
        setDeviceState,
        setShowDeviceGroup,
        setDeviceGroup,
        deviceData,
        selectedDeviceGroup
    };
    let onSubmitObj = {
        api,
        selectedDeviceGroup,
        setLoading,
        setShowPolicyDropdown,
        policy,
        deviceState,
        setPolicy,
        setShowDeviceState,
        setDeviceState,
        setShowDeviceGroup,
        setDeviceGroup
    };
    let dynamicCompObj = { handleChangeObj, onCancelObj, onSubmitObj };
    let onClickObj = { setShowPolicyDropdown, setShowDeviceState, setShowDeviceGroup };
    let returnDataObj = {
        showDeviceGroup,
        showPolicyDropdown,
        policy,
        policyOptions,
        dynamicCompObj,
        showDeviceState,
        onClickObj,
        deviceState,
        deviceStateOptions,
        showEditSim,
        onClickEditSim,
        inputComponent
    };

    const handleMonitoring = (key) => {
        setLoading(true);
        setActionModal(false);
        let params = { timer: 172800, action: key.action };
        if (key.action === 'STOP') delete params.timer;
        api.create(url.DEVICE_ACTIONS + '/' + recordID, { command: 'Scream', params: params })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                toast.success(key.action === 'STOP' ? toastMessages.screamSwitchedOff : toastMessages.screamSwitchedOn);
                getCommandHistory(deviceGroupOptions, true);
            })
            .catch((_err) => setLoading(false));
    };

    const actionService = () => {
        setActionModal(false);
        if (parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) < 7) {
            CustomDPCDeviceActions({
                setLoading: setLoading,
                type: type,
                id: recordID,
                command: `${type}_${monitoring.action}`,
                urlconf: urlconf
            });
            return;
        }
        commonActionsService({
            deviceID: recordID,
            type: type,
            setLoading: setLoading,
            setShowModal: setActionModal,
            getCommandHistory: getCommandHistory,
            deviceGroupOptions: deviceGroupOptions,
            urlconf: urlconf
        });
    };

    const actionMessage = () => {
        return type === 'lock' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to lock Device?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={lockDevice} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial: <span className="fw-semibold">{deviceData?.serial}</span>
                    </div>
                </div>
            </React.Fragment>
        ) : type === 'reboot' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to reboot Device?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={rebootDevice} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial: <span className="fw-semibold">{deviceData?.serial}</span>
                    </div>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={Scream} alt="device" width={60} height={60} />
                    </div>
                    <div className="mb-4">
                        Are you sure you want to {monitoring.action === 'START' ? 'activate' : 'de-activate'} scream?
                    </div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                            <div
                                className={`${
                                    monitoring.action === 'STOP' ? 'bg-info text-light' : 'bg-light text-dark'
                                } w-50 px-2 py-1 cursor-pointer`}
                                onClick={() => setMonitoring({ ...monitoring, action: 'STOP' })}
                            >
                                STOP
                            </div>
                            <div
                                className={`${
                                    monitoring.action === 'START' ? 'bg-info text-light' : 'bg-light text-dark'
                                } w-50 px-2 py-1 cursor-pointer`}
                                onClick={() => setMonitoring({ ...monitoring, action: 'START' })}
                            >
                                START
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {!sidePanelLoader && loading && <Loader />}

            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="View Device" history={history} homeLink="Dashboard" showBack={true} backLink="mydevices" />
                    <Card className="p-3 mb-0">
                        <Row>
                            <Col className="col-12">
                                <Card className="pt-3 pb-3 ps-2 pe-2 w-100 d-flex align-items-center mb-0">
                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-15 fw-medium">Serial Number :</div>
                                            <div className="fs-16 ps-2 text-info">{hardwareData?.serialNumber}</div>
                                        </div>
                                        <div className="d-flex align-items-center gap-4">
                                            <div className="d-flex align-items-center justify-content-center gap-1">
                                                {deviceData.showActions &&
                                                actionItems.filter((action) => !!tenantConfig?.DEVICE_ACTIONS?.includes(action.tenantField))
                                                    ?.length > 0 ? (
                                                    <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                        Actions :
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {actionItems.map((action, ind) => {
                                                    return (
                                                        deviceData.showActions &&
                                                        !!tenantConfig?.DEVICE_ACTIONS?.includes(action.tenantField) && (
                                                            <div
                                                                key={ind}
                                                                className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                    action.condition ? 'bg-447BD107 cursor-pointer' : ''
                                                                }`}
                                                                onClick={action.condition ? action.clickFunction : undefined}
                                                            >
                                                                <i
                                                                    id={action.id}
                                                                    className={`${action.icon} fs-20 ${
                                                                        action.condition ? 'action-hover link-info' : 'text-muted'
                                                                    }`}
                                                                />
                                                                {Tooltip(action.id, action.label)}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col className="d-flex flex-column g-3">
                            {handleDeviceSummaryFunc({
                                hardwareData,
                                deviceData,
                                deviceState,
                                deviceGroup,
                                returnDataObj,
                                policy,
                                disableActions,
                                tenantConfig,
                                recordID,
                                setPolicyShift,
                                noEdit: true
                            })}
                            {handleDeviceIdentityDetailsFunc({ hardwareData, deviceData })}
                            {handleDeviceSecurityFunc({ deviceData })}
                        </Col>
                        <Col className="d-flex flex-column g-3">
                            {handleHardwareInfoFunc({ hardwareData, deviceData })}
                            {deviceData.deviceStatusApplied === 'ACTIVE' && !!tenantConfig.SHOW_SIM_INFO && handleSimInfoFunc(simData)}
                            {handleSoftwareInfoFunc({ softwareData, deviceData })}
                        </Col>
                        <Col className="d-flex flex-column g-3">{actionHistoryFunc({ tenantConfig, commandHistory })}</Col>
                        <div className="p-1">
                            <TableContainer
                                loading={loading}
                                headerImage={nonCompliance}
                                tableLabelClass={'text-success'}
                                tableHeader={'Non Compliance Details'}
                                isGlobalFilter={true}
                                columns={nonComplianceSchema}
                                data={nonComplianceDetails}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </div>
                        <div className="p-1">
                            <TableContainer
                                loading={loading}
                                searchFilter2={true}
                                showTooltip={true}
                                searchPlaceHolder={'Package/Application Name'}
                                headerImage={applicationsIcon}
                                tableLabelClass={'text-success'}
                                tableHeader={'Applications'}
                                isGlobalFilter={true}
                                columns={applicationSchema}
                                data={applications}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </div>
                    </Row>
                </Container>
                <DeleteModal
                    hideIcon={true}
                    show={actionModal}
                    hideDeleteIcon={true}
                    hideDeleteMessage={true}
                    message={actionMessage()}
                    confirmText={actionText}
                    onDeleteClick={
                        type === 'lock' || type === 'reboot'
                            ? actionService
                            : () => handleMonitoring(type === 'REFRESH' ? { type: type } : monitoring)
                    }
                    onCloseClick={() => setActionModal(false)}
                />
            </div>
        </React.Fragment>
    );
};

export default ViewAndroidDevices;
