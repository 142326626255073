import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLoggedinUser } from '../../helpers/api_helper';

const useProfile = () => {
    const userProfileObj = getLoggedinUser();
    var token = userProfileObj && userProfileObj?.data['token'];
    const [loading, setLoading] = useState(userProfileObj ? false : true);
    const [userProfile, setUserProfile] = useState(userProfileObj ? userProfileObj : null);

    useEffect(() => {
        const userProfileSession = getLoggedinUser();
        var tokenVal = userProfileObj && userProfileObj?.data['token'];
        setUserProfile(userProfileSession ? userProfileSession : null);
        setLoading(tokenVal ? false : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { userProfile, loading, token };
};

const useTrans = (tns) => {
    const { t } = useTranslation(tns);
    return t;
};

const togglePassword = (id, iconID) => {
    var passwordInput = document.getElementById(id);
    var passwordIcon = document.getElementById(iconID);
    if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        passwordIcon.classList.remove('ri-eye-off-line');
        passwordIcon.classList.add('ri-eye-line');
    } else {
        passwordInput.type = 'password';
        passwordIcon.classList.remove('ri-eye-line');
        passwordIcon.classList.add('ri-eye-off-line');
    }
};

const tooglePasswordStrengthIn = (divID) => {
    var passwordStrengthValidation = document.getElementById(divID);
    passwordStrengthValidation.style.display = 'block';
};
const tooglePasswordStrengthOut = (divID) => {
    var passwordStrengthValidation = document.getElementById(divID);
    passwordStrengthValidation.style.display = 'none';
};

const togglePasswordChange = (event) => {
    if (event) {
        let numberPattern = new RegExp(/(?=.*?[0-9])/);
        let specialSymbolPattern = new RegExp(/(?=.*?[#?!@$%^&*-])/);
        let lowercasePatteren = new RegExp(/(?=.*?[a-z])/);
        let uppercasePattern = new RegExp(/(?=.*?[A-Z])/);
        let minlengthPatteren = new RegExp(/.{8,}/);

        if (specialSymbolPattern.test(event)) classNameChangeFunction('pass-special', 'invalid', 'valid');
        else classNameChangeFunction('pass-special', 'valid', 'invalid');
        if (numberPattern.test(event)) classNameChangeFunction('pass-number', 'invalid', 'valid');
        else classNameChangeFunction('pass-number', 'valid', 'invalid');
        if (lowercasePatteren.test(event)) classNameChangeFunction('pass-lower', 'invalid', 'valid');
        else classNameChangeFunction('pass-lower', 'valid', 'invalid');
        if (uppercasePattern.test(event)) classNameChangeFunction('pass-upper', 'invalid', 'valid');
        else classNameChangeFunction('pass-upper', 'valid', 'invalid');
        if (minlengthPatteren.test(event)) classNameChangeFunction('pass-length', 'invalid', 'valid');
        else classNameChangeFunction('pass-length', 'valid', 'invalid');
        if (event.length >= 8 && event.length <= 12) classNameChangeFunction('pass-max-length', 'invalid', 'valid');
        else classNameChangeFunction('pass-max-length', 'valid', 'invalid');
    } else {
        classNameChangeFunction('pass-number', 'valid', 'invalid');
        classNameChangeFunction('pass-special', 'valid', 'invalid');
        classNameChangeFunction('pass-lower', 'valid', 'invalid');
        classNameChangeFunction('pass-upper', 'valid', 'invalid');
        classNameChangeFunction('pass-length', 'valid', 'invalid');
        classNameChangeFunction('pass-max-length', 'valid', 'invalid');
    }
};

const togglePasswordChangeVal = (event) => {
    let flag = [];
    if (event) {
        let numberPattern = new RegExp(/(?=.*?[0-9])/);
        let specialSymbolPattern = new RegExp(/(?=.*?[#?!@$%^&*-])/);
        let lowercasePatteren = new RegExp(/(?=.*?[a-z])/);
        let uppercasePattern = new RegExp(/(?=.*?[A-Z])/);
        let maxlengthPatteren = new RegExp(/.{8,}/);

        if (specialSymbolPattern.test(event)) flag[0] = true;
        else flag[0] = false;
        if (numberPattern.test(event)) flag[1] = true;
        else flag[1] = false;
        if (lowercasePatteren.test(event)) flag[2] = true;
        else flag[2] = false;
        if (uppercasePattern.test(event)) flag[3] = true;
        else flag[3] = false;
        if (maxlengthPatteren.test(event)) flag[4] = true;
        else flag[4] = false;
        if (event.length <= 12) flag[5] = true;
        else flag[5] = false;
    } else {
        flag[0] = false;
        flag[1] = false;
        flag[2] = false;
        flag[3] = false;
        flag[4] = false;
        flag[5] = false;
    }
    return flag.every((ele) => ele);
};

const classNameChangeFunction = (id, oldClass, newClass) => {
    var strengthValidation = document.getElementById(id);
    strengthValidation.classList.replace(oldClass, newClass);
};

export {
    useProfile,
    useTrans,
    togglePassword,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut,
    togglePasswordChange,
    togglePasswordChangeVal
};
