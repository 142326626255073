import React, { useEffect, useState } from 'react';
import OrgTree from 'react-org-tree';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { GROUP_COLORS } from '../../../Components/constants/constants';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const DeviceGroupsTree = () => {
    document.title = 'Device Group Tree';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();

    let pathArr = window.location.hash?.split('/');
    let recordID = pathArr?.[pathArr?.length - 1];

    const [loading, setLoading] = useState(false);
    const [groupToView, setGroupToView] = useState({});

    useEffect(() => {
        setLoading(true);
        getAllGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.code);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const prepareLabel = (data, index) => {
        let color = GROUP_COLORS[index % 10];
        return (
            <div style={{ borderLeft: `4px solid ${color}` }} className="width-150 text-truncate">
                <div className="padding-x-20 padding-y-15">
                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{data.name}</EllipsisToolTip>
                </div>
            </div>
        );
    };

    const prepareParentLabel = (group) => {
        return (
            <div className="width-150 text-truncate bg-success text-white fw-semibold rounded">
                <div className="padding-x-15 padding-y-15">
                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{group.name}</EllipsisToolTip>
                </div>
            </div>
        );
    };

    const setChildLabels = (groups, index) => {
        groups.forEach((group) => {
            group.label = prepareLabel(group, index);
            group.expand = true;
            if (group.children?.length > 0) group.children = setChildLabels(group.children, index + 1);
        });
        return groups;
    };

    const getAllGroups = () => {
        api.get(url.DEVICE_GROUPS_UNSTRUCTURED + '/' + recordID, '', domains.IDM)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    let group = resp.data;
                    group.label = prepareParentLabel(group);
                    group.expand = true;
                    let index = 0;
                    if (group.children?.length > 0) {
                        group.children.forEach((child) => {
                            child.label = prepareLabel(child, index);
                            child.expand = true;
                            if (child.children?.length > 0) child.children = setChildLabels(child.children, index + 1);
                        });
                    }
                    setGroupToView(group);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'View Tree Structure'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="devicegroups"
                    />
                    <div className="w-100 bg-white mb-3">
                        <div className="d-flex w-full overflow-auto align-items-center justify-content-start pb-4">
                            <OrgTree data={groupToView} horizontal={false} collapsable={true} expandAll={true} />
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DeviceGroupsTree;
