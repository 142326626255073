import { AUTH_MODES } from './actionType';

const INIT_STATE = { authModes: '' };

const AuthModes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTH_MODES:
            return { ...state, authModes: action.payload };
        default:
            return state;
    }
};

export default AuthModes;
