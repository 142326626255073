import { all, fork } from 'redux-saga/effects';
import CommonsSaga from './Commons/saga';
import LayoutSaga from './layouts/saga';
import TenantSaga from './TenantConfig/saga';
import ChromeSaga from './ChromeEnterprise/saga';
import AuthSaga from './AuthModes/saga';

export default function* rootSaga() {
    yield all([fork(LayoutSaga), fork(TenantSaga), fork(CommonsSaga), fork(ChromeSaga), fork(AuthSaga)]);
}
