import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

const AddContact = () => {
    const config = useEnv();
    let history = useHistory();
    let api = new APIClient();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'add' ? 'Add Contact' : formType === 'edit' ? 'Edit Contact' : 'View Contact';
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ name: '', contacts: [], contactName: '', contactNumber: '' });

    useEffect(() => {
        if (recordID) getContact();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getContact = () => {
        setLoading(true);
        api.get(url.CONFIG + '/' + recordID)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setValues({
                        ...values,
                        name: resp.data.name,
                        contacts: resp.data?.config?.contacts ? resp.data?.config?.contacts : []
                    });
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleSubmit = () => {
        const dataObj = {
            name: values.name,
            config: { numbers: values.contacts.map((contact) => contact.number), contacts: values.contacts, name: values.name },
            configType: 'CONTACTS'
        };
        let apiService;
        if (recordID) apiService = api.update(url.CONFIG + '/' + recordID, dataObj);
        else apiService = api.create(url.CONFIG, dataObj);

        apiService
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(recordID ? toastMessages.callConfigConfigUpdate : toastMessages.callConfigConfigCreate);
                    history.push('/contactlist');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleAddContact = () => {
        let regex = new RegExp(/^[0-9]+$/);
        let test = regex.test(Number(values.contactNumber));
        if (!test || values.contactNumber?.length !== 10) {
            toast.error(toastMessages.invalidPhone);
            return;
        }
        const numbers = values.contacts?.map((contact) => contact.number);
        if (numbers.includes(values.contactNumber)) {
            toast.error(toastMessages.phoneExists);
            return;
        }
        let contactArr = [...values.contacts];
        contactArr.push({ name: values.contactName, number: values.contactNumber });
        setValues({ ...values, contactName: '', contactNumber: '', contacts: contactArr });
    };

    const handleRemoveOneItem = (contact) => {
        let contactArr = [...values.contacts];
        contactArr = contactArr.filter((cont) => cont.number !== contact.number);
        setValues({ ...values, contactName: '', contactNumber: '', contacts: contactArr });
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(inputValue)) {
            setValues({ ...values, contactNumber: inputValue });
        }
    };

    const userLabel = (val) => {
        let name = val?.split(' ');
        if (name?.length === 1) return name?.[0]?.charAt(0)?.toUpperCase();
        else return name?.[0]?.charAt(0)?.toUpperCase() + name?.[1]?.charAt(0)?.toUpperCase();
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={
                            formType === 'view' || formType === 'viewChecker'
                                ? 'View Contact'
                                : formType === 'edit'
                                ? 'Edit Contact'
                                : 'Add Contact'
                        }
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="contactlist"
                    />
                    <Card>
                        <CardHeader className="p-4 border border-2 border-bottom-dashed">
                            <Col sm={6}>
                                <Row className="d-flex align-items-center">
                                    <Col sm={4}>
                                        <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                            Name<span className="red-color ps-1">*</span>
                                        </Label>
                                    </Col>
                                    <Col sm={6}>
                                        {formType !== 'view' && formType !== 'viewChecker' ? (
                                            <Input
                                                name={'name'}
                                                id={'name'}
                                                className="form-control w-100"
                                                placeholder="Enter Name"
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => setValues({ ...values, name: e.target.value })}
                                                value={values.name}
                                            />
                                        ) : values.name ? (
                                            values.name
                                        ) : (
                                            '–'
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={6}>
                                    <Row className="d-flex align-items-center">
                                        <Col sm={4}>
                                            <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                Contact Name<span className="red-color ps-1">*</span>
                                            </Label>
                                        </Col>
                                        <Col sm={6}>
                                            {formType !== 'view' && formType !== 'viewChecker' ? (
                                                <Input
                                                    minLength="4"
                                                    maxLength="20"
                                                    type="text"
                                                    name={'contactName'}
                                                    id={'contactName'}
                                                    className="form-control w-100"
                                                    placeholder="Enter Contact Name"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(e) => setValues({ ...values, contactName: e.target.value })}
                                                    value={values.contactName}
                                                />
                                            ) : values.contactName ? (
                                                values.contactName
                                            ) : (
                                                '–'
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="d-flex align-items-center mt-3">
                                        <Col sm={4}>
                                            <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                Contact Number<span className="red-color ps-1">*</span>
                                            </Label>
                                        </Col>
                                        <Col sm={6}>
                                            {formType !== 'view' && formType !== 'viewChecker' ? (
                                                <Input
                                                    minLength="4"
                                                    maxLength="16"
                                                    type="text"
                                                    name={'contactNumber'}
                                                    id={'contactNumber'}
                                                    className="form-control w-100"
                                                    placeholder="Enter Contact Number"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={values.contactNumber}
                                                />
                                            ) : values.contactNumber ? (
                                                values.contactNumber
                                            ) : (
                                                '–'
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={3}></Col>
                                        <Col sm={7} className="d-flex align-items-center justify-content-end">
                                            <Button
                                                color="primary"
                                                onClick={handleAddContact}
                                                disabled={values.contactName === '' || values.contactNumber === ''}
                                            >
                                                + Add Contact
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="mt-3 border border-top-1"></div>
                            <div className="d-flex mt-3 align-items-center justify-content-between">
                                <span className="fw-medium fs-16">Total Contacts ({values.contacts?.length})</span>
                                <div>
                                    <Button color="light" onClick={() => setValues({ ...values, contacts: [] })}>
                                        Clear All Contacts
                                    </Button>
                                </div>
                            </div>
                            <Row className="mt-3">
                                {values.contacts.length > 0 ? (
                                    values.contacts.map((contact, ind) => (
                                        <Col xxl={3} xl={3} lg={3} md={4} sm={4} xs={6} key={ind}>
                                            <Card className="border border-2 rounded-1 shadow-card">
                                                <CardBody>
                                                    <Row>
                                                        <Col sm={10} className="d-flex">
                                                            <span className="rounded-circle header-profile-user border border-2 d-flex align-items-center justify-content-center fs-15 bg-success text-white">
                                                                {userLabel(contact.name)}
                                                            </span>
                                                            <span className="ms-2">
                                                                <div>{contact.name}</div>
                                                                <div>{contact.number}</div>
                                                            </span>
                                                        </Col>
                                                        {formType !== 'view' && (
                                                            <Col sm={2}>
                                                                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                                                    <i
                                                                        className="ri-delete-bin-line link-danger cursor-pointer fs-18"
                                                                        onClick={() => handleRemoveOneItem(contact)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex gap-2 justify-content-end mb-2">
                                <button
                                    type="button"
                                    className="btn w-sm btn-light"
                                    data-bs-dismiss="modal"
                                    onClick={() => history.push('/contactlist')}
                                >
                                    Close
                                </button>
                                {formType !== 'view' && (
                                    <button
                                        type="button"
                                        className="btn w-sm btn-success"
                                        disabled={!values.name || values.contacts.length === 0}
                                        onClick={handleSubmit}
                                    >
                                        {formType === 'add' ? 'Save' : 'Update'}
                                    </button>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddContact;
