/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, convertTextCase, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { LoginHistorySchema } from './TableSchema';

const LoginHistory = () => {
    document.title = 'Login History';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loginHistory, setLoginHistory] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        serviceCall();
    }, []);

    const handleGlobalSearch = (val) => {
        let params = {};
        if (val) params = { ...searchParams, username: val?.trim() };
        else params = { ...searchParams, username: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        serviceCall({ ...params, page: 1, size: 10 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, from: convertDate(val?.[0], '-'), to: convertDate(val?.[1], '-') };
        else params = { ...searchParams, from: undefined, to: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (params.from && params.to) serviceCall({ ...params, page: 1, size: 10 });
        else if (val?.length === 0) serviceCall({ ...params, page: 1, size: 10 });
    };

    const serviceCall = (paramsObj) => {
        setLoading(true);
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { ...searchParams, page: pageNumber, size: pageSize };
        api.get(url.LOGIN_HISTORY, params, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setTotalRecords(resp?.totalRecords);
                    resp.data?.forEach((ele) => {
                        ele.badgeClass = ele.activity?.toLowerCase() === 'login' ? 'success' : 'info';
                        ele.activity = convertTextCase(ele.activity, true);
                        ele.modifiedDate = ele.modifieddate ? getDate(ele.modifieddate) : '—';
                        ele.app = ele.app === 'portal' ? 'Web' : convertTextCase(ele.app, true);
                        ele.authtype = ele.authtype === 'local' ? 'Tectoro' : convertTextCase(ele.authtype);
                    });
                    setLoginHistory(resp?.data || []);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const onPageChange = (pgNumber, pgSize) => {
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        serviceCall({ ...searchParams, page: pgNumber, size: pgSize });
    };

    const handleExportLogs = () => {
        setLoading(true);
        api.get(url.LOGIN_HISTORY_DOWNLOAD, searchParams, domains.IDM)
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = 'Login History.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'Login History'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="adminreports"
                    />
                    <TableContainer
                        loading={loading}
                        searchFilter2={true}
                        dateRangeFilter2={true}
                        datePlaceHolder={'Date Range'}
                        // searchFiltersButton={true}
                        exportLogs={true}
                        handleExportLogs={handleExportLogs}
                        searchPlaceHolder={'username'}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand logs only)."
                        tableHeader={'All Login History'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={LoginHistorySchema}
                        data={loginHistory || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LoginHistory;
