import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { devicePowerStatus } from '../TableSchema';

const DevicePowerStatus = () => {
    let history = useHistory();
    let api = new APIClient();

    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10, type: 'DEVICE' });

    useEffect(() => {
        getReport(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getReport = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.DEVICE_REPORTS + '/powerstatus', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    resp.data.forEach((data) => {
                        data.power = data.powerStatus ? 'ON' : 'OFF';
                        data.badgeClass = data.powerStatus ? 'success' : 'danger';
                        data.modifiedDate = data.lastModifiedTime ? getDate(data.lastModifiedTime) : '—';
                    });
                    setTotalRecords(resp.totalRecords);
                    setReport(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onPageChange = (page, size) => {
        let params = { ...searchParams, page: page };
        setPageNumber(page);
        getReport(params);
    };

    const handleGlobalSearch = (val) => {
        let params = { ...searchParams, page: 1, serial: val };
        setPageNumber(1);
        getReport(params);
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1 });
        setPageNumber(1);
        if (params.fromDate && params.toDate) getReport({ ...params, page: 1 });
        else if (val?.length === 0) getReport({ ...params, page: 1 });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'Device Power Status'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="aereports"
                    />
                    <TableContainer
                        searchFilter2={true}
                        dateRangeFilter2={true}
                        datePlaceHolder={'Date Range'}
                        /*
                         * searchFiltersButton={true}
                         * exportLogs={true}
                         */
                        searchPlaceHolder={'Serial'}
                        // handleExportLogs={handleExportLogs}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand logs only)."
                        tableHeader={'Device Power Status'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={devicePowerStatus}
                        data={report || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DevicePowerStatus;
