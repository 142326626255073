import React, { useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import LinkAzureDirectory from './LinkAzureDirectory';
import LinkLDAP from './LinkLDAP';

const WindowsSetup = () => {
    document.title = 'Windows Setup';
    const [loading, setLoading] = useState(false);

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Windows Setup" pageTitle="Windows Setup" />
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <LinkAzureDirectory />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            {/* <LinkLDAP /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsSetup;
