import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom';
import RightArrow from '../../assets/images/right_arrow.png';
import { priviliges, reports } from '../../Components/constants/constants';
import { AuthUser } from '../../Components/Common/Util';
import { toast } from 'react-toastify';
import toastMessages from '../../common/messages/toastMessages';
const GetStarted = () => {
    document.title = 'Get Started';
    let history = useHistory();
    let user = AuthUser();
    user = user ? JSON.parse(user) : '';

    const handlePrivs = (privRequired) => {
        let flag = true;
        privRequired?.forEach((priv) => {
            flag = flag || user?.data?.priviliges?.find((reqPriv) => reqPriv === priv);
        });
        return flag;
    };

    const handleNavigation = (report) => {
        let privs = user?.data?.priviliges;
        let myDevicePriv = privs?.find((priv) => priv === priviliges.MYDEVICES);
        if (myDevicePriv) history.push(report.myDeviceRoute);
        else if (handlePrivs(report.privRequired)) history.push(report.link);
        else toast.error(toastMessages.doNotHaveAccess);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Get Started" pageTitle="Get Started" history={history} />
                    <Card>
                        <Row>
                            <div className="p-8 pb-0">
                                <div className="fs-18 py-2 fw-medium text-primary">Enterprise Mobility Solution :</div>
                                <div className="fs-13">
                                    Tectoro EMM has a central console for device administration, which helps organizations to configure,
                                    monitor and manage devices remotely. This enhances security and compliance of the devices with customer
                                    standards. We offer service to streamline device management, boost productivity and protect sensitive
                                    data.
                                </div>
                            </div>
                        </Row>
                        <Row className="p-8">
                            {reports()?.map(
                                (report, index) =>
                                    report.show && (
                                        <Col xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                                            <Card
                                                className="shadow-card rounded-0_8 cursor-pointer card-height-100 padding-20 mb-sm-0 mt-2"
                                                onClick={() => handleNavigation(report)}
                                            >
                                                <CardBody className="w-100">
                                                    <div className="bg-muted mb-3 d-flex justify-content-center">
                                                        <img src={report.logo} height={60} alt={report.label} />
                                                    </div>
                                                    <div className="fw-semibold fs-16 mb-3 text-primary d-flex justify-content-center">
                                                        {report.label}
                                                    </div>
                                                    <div className="text-muted word-wrap-break pb-2 d-flex justify-content-center">
                                                        {report.content}
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-5">
                                                        <div className="bg-muted p-2 d-flex justify-content-center position-absolute position-bottom-20">
                                                            <img src={RightArrow} height={20} alt="RightArrow" />
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                            )}
                        </Row>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GetStarted;
