import React from 'react';
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import Loader from './Loader';

const OffcanvasModal = (props) => {
    return (
        <React.Fragment>
            <Offcanvas
                backdrop={true}
                isOpen={props.open}
                toggle={props.toggle}
                direction={props.direction}
                id={props.OffcanvasModalID}
                className={`border-0 offcanvas-${props.direction} ${props.modalClassName}`}
            >
                <OffcanvasHeader
                    className={`d-flex align-items-center bg-primary bg-gradient p-4 offcanvas-header-dark height-60 ${props.modalHeaderClass}`}
                    toggle={props.toggle}
                >
                    <span className={'m-0 me-2 text-white fw-normal fs-15'}>{props.offcanvasHeader}</span>
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    {props.loading && <Loader />}
                    <SimpleBar className="h-100">
                        <div className={props.bodyCSSClass ? props.bodyCSSClass : 'p-6'}>{props.handleOffcanvasBody()}</div>
                    </SimpleBar>
                </OffcanvasBody>
                {!props.hideFooterButtons && (
                    <div className="offcanvas-footer border-top p-4 gap-3 d-flex justify-content-end">
                        <button type="button" className={'btn w-sm btn-light '} data-bs-dismiss="modal" onClick={props.handleCloseClick}>
                            {props.cancelText ? props.cancelText : 'Close'}
                        </button>
                        {!props.hideSaveButton && (
                            <button
                                type="button"
                                className="btn w-sm btn-success"
                                disabled={props.saveDisabled}
                                onClick={props.handleSaveClick}
                            >
                                {props.saveText ? props.saveText : 'OK'}
                            </button>
                        )}
                    </div>
                )}
            </Offcanvas>
        </React.Fragment>
    );
};

export default OffcanvasModal;
