import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import noEnterpriseImage from '../../assets/images/no_enterprise.png';

const NoEnterpriseAccount = (props) => {
    let history = useHistory();

    return (
        <Col xl={12}>
            <Card className="vh-77">
                <CardBody className="d-flex justify-content-center align-items-center flex-column gap-5">
                    <img src={noEnterpriseImage} alt="No Enterprise" />
                    <div>
                        <h1 className="text-primary">Oops !</h1>
                    </div>
                    <div className="oopscontent fs-20">SORRY, You can’t access the {props.screen}</div>
                    <div className="oopswarning cursor-pointer" onClick={() => history.push('/aeenterprise')}>
                        Please create an Enterprise Account
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default NoEnterpriseAccount;
