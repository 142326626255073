// @flow
import { all, fork, takeEvery, put } from 'redux-saga/effects';

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeChromeConfig({ payload: config }) {
    try {
        yield put({ type: 'CHROME_CONFIG_SUCCESS', config: config });
    } catch (error) {
        yield put({ type: 'CHROME_CONFIG_FAIL', error: error });
    }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
    yield takeEvery('CHROME_CONFIG', changeChromeConfig);
}

function* ChromeSaga() {
    yield all([fork(watchChangeLayoutType)]);
}

export default ChromeSaga;
