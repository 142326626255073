import React from 'react';
import TableContainer from '../../../Components/Common/TableContainer';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { priviliges } from '../../../Components/constants/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TimeFence = () => {
    let history = useHistory();
    const Schema = [
        { Header: 'Name' },
        { Header: 'Assigned Group' },
        { Header: 'Modified By' },
        { Header: 'Modified Date' },
        { Header: 'Actions' }
    ];

    const handleAddNew = () => {
        history.push('/wififence/add');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb pageTitle="Wifi Fence" history={''} homeLink="Dashboard" />
                    <TableContainer
                        columns={Schema}
                        data={[]}
                        tableHeader={'All Wifi Fences'}
                        isGlobalFilter={true}
                        searchFilter2={true}
                        searchPlaceHolder={'Name'}
                        addButtonText="Add Wifi-Fence"
                        addButton={true}
                        customPageSize={10}
                        handleAddNew={handleAddNew}
                        reader={priviliges.CONFIGURATION_READER}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TimeFence;
