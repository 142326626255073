// @flow
import { all, fork, takeEvery, put } from 'redux-saga/effects';

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeAuthModes({ payload: config }) {
    try {
        yield put({ type: 'AUTH_MODES_SUCCESS', config: config });
    } catch (error) {
        yield put({ type: 'AUTH_MODES_FAIL', error: error });
    }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
    yield takeEvery('AUTH_MODES', changeAuthModes);
}

function* AuthSaga() {
    yield all([fork(watchChangeLayoutType)]);
}

export default AuthSaga;
