import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { Enterprise, baseURL } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import NoEnterpriseAccount from '../NoEnterpriseAccount';

const ZeroTouch = () => {
    document.title = 'Zero Touch';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();
    let enterpriseObj = Enterprise();
    const enterprises = enterpriseObj ? JSON.parse(enterpriseObj) : {};

    const [enterprise, setEnterprise] = useState(enterprises);
    const [loading, setLoading] = useState(false);
    const [embededToken, setEmbededToken] = useState('');

    useEffect(() => {
        setEnterprise(enterprises);
        if (Object.keys(enterprises)?.length > 0) getEmbededToken(enterprises);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEmbededToken = (eData) => {
        setLoading(true);
        let EMBEDEDURL = url.EMBEDED_URL;
        let params = { enterpriseId: eData.id, parentUrl: baseURL() };
        api.get(EMBEDEDURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setEmbededToken(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleZeroTouchIframe = () => {
        if (embededToken) {
            var URL = `https://enterprise.google.com/android/zero-touch/embedded/companyhome?token=${embededToken}&dpcId=com.google.android.apps.work.clouddpc`;
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';

            script.onload = () => {
                window.gapi.load('gapi.iframes', () => {
                    var options = {
                        url: URL,
                        where: document.getElementById('container'),
                        attributes: {
                            style: 'width: 100%; height:1000px',
                            scrolling: 'yes',
                            showsearchbox: true,
                            search: true
                        }
                    };
                    var iframe = window.gapi.iframes.getContext().openChild(options);
                });
            };
            document.body.appendChild(script);
        }
        return (
            <div className="col-md-12 mb-4">
                <div id="container"></div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Zero Touch" history={history} homeLink="Dashboard" />
                    {!enterprise?.id ? (
                        <NoEnterpriseAccount screen="Zero Touch" />
                    ) : (
                        <div className="d-flex flex-column">
                            <Row>{handleZeroTouchIframe()}</Row>
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ZeroTouch;
