import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const Enrollment = (props) => {
    return (
        <Modal id="showModal" isOpen={props.open} toggle={props.toggle} centered>
            <ModalHeader className="bg-soft-info p-3" toggle={props.toggle}>
                You will be redirected to Google page for sign up
            </ModalHeader>
            <ModalBody>
                <p className="text-muted mb-0">Once your Sign Up is completed you will be redirected to back here</p>
            </ModalBody>
            <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                    <button type="button" className="btn btn-light" onClick={() => props.setModal(false)}>
                        Close
                    </button>
                    <button onClick={props.handleEnrollment} className="btn btn-success" id="add-btn">
                        Ok
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default Enrollment;
