import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { getDate } from '../../../Components/Common/Util';
import { appleCertiData } from '../../../Components/constants/constants';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

const APNCard = (props) => {
    const [data, setData] = useState(props.data ? { ...props.data } : '');

    useEffect(() => {
        setData(props.data ? { ...props.data } : '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    return (
        <Card className=" border">
            <CardHeader className="border-bottom-dashed">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                        {/* <div>
                                        <i className="ri-refresh-line fs-16"></i>
                                    </div> */}
                        <div className="fs-12 d-flex">
                            <div className="text-muted">{'Last Synced - '}</div>
                            <div className="fw-medium">{getDate(data.modifieddate)}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <div>
                            <i className="ri-information-line d-flex align-items-center blue-icon fs-15" id="renew" />
                            <UncontrolledTooltip placement="bottom" target="renew">
                                The APNs certificate is valid for one year from its date of creation. Renewing an APNs for MDM certificate
                                is similar to generating a new certificate. Select RENEW and follow the same steps for generating an APNs
                                for MDM certificate. * If you use a different Apple ID, you will need to re-enrol all managed mobile
                                devices.
                            </UncontrolledTooltip>
                        </div>
                        <div
                            className="text-primary text-decoration-underline px-1 fs-12 fw-medium cursor-pointer"
                            onClick={() => props.handleAction('change')}
                        >
                            Renew Certificate
                        </div>
                        <div>
                            <i
                                className="ri-edit-box-line px-1 fs-16 text-secondary cursor-pointer"
                                onClick={() => props.handleAction('change')}
                            ></i>
                        </div>
                        <div>
                            <i
                                className="ri-delete-bin-6-line px-1 fs-16 text-danger cursor-pointer"
                                onClick={() => props.handleAction('delete')}
                            ></i>
                        </div>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <Row>
                    {appleCertiData.map((ele, index) => {
                        return (
                            <Col className="col-6 mb-2" key={index}>
                                <div className="p-0">
                                    <div className="fw-medium py-1">{ele.label}</div>
                                    <div className="text-muted d-flex justify-content-start align-items-center">
                                        <EllipsisToolTip id={ele.key?.replaceAll(' ', '')}>
                                            {ele.type === 'date' ? getDate(data[ele.key]) : data[ele.key] ? data[ele.key] : '—'}
                                        </EllipsisToolTip>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </CardBody>
        </Card>
    );
};

export default APNCard;
