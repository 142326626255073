import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Card, Row, Col, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import verificationImage from '../../assets/images/verification-img.png';
import ExpiredImage from '../../assets/images/expiredImage.png';

const License = () => {
    document.title = 'License';
    const history = useHistory();

    const handleClick = () => {
        history.push('/license/plan');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="License" pageTitle="License" />
                    <Row>
                        <Col className="col-8 m-auto my-15">
                            <Card>
                                <div className="m-auto p-10 py-15">
                                    <div className="d-flex m-auto justify-content-center  align-items-center">
                                        <div className="fs-16 text-center fw-900">
                                            Your trail version has <img className="w-30" src={ExpiredImage} alt="" />
                                        </div>
                                    </div>
                                    <div className="d-flex m-auto justify-content-center align-items-center">
                                        <div className="fs-13 fw-900 text-muted">
                                            Your trail version has expired. Upgrade your account and use{' '}
                                            <b className=" m-1 fw-900 fs-14 text-muted">Premium plan</b>
                                        </div>
                                    </div>
                                    <div className="d-flex m-auto align-items-center justify-content-center">
                                        <img className="w-40 my-10" src={verificationImage} alt="" />
                                    </div>
                                    <div className="d-flex m-auto align-items-center justify-content-center">
                                        <Button className="bg-primary border-none p-3" onClick={handleClick}>
                                            <b className="p-3 px-10 fs-14">View Our Plan and Pricing</b>
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default License;
