// @flow
import { all, fork, takeEvery, put } from 'redux-saga/effects';

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeTenantConfig({ payload: config }) {
    try {
        yield put({ type: 'TENANT_CONFIG_SUCCESS', config: config });
    } catch (error) {
        yield put({ type: 'TENANT_CONFIG_FAIL', error: error });
    }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
    yield takeEvery('TENANT_CONFIG', changeTenantConfig);
}

function* TenantSaga() {
    yield all([fork(watchChangeLayoutType)]);
}

export default TenantSaga;
