import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { TenantConfig } from '../../../Components/Common/Util';
import MakerPolicies from './MakerPolicies';
import TabsComponent from '../../../Components/Common/TabsComponent';
import CheckerPolicies from './CheckerPolicies';
import Policies from './Policies';
import Loader from '../../../Components/Common/Loader';

const Policy = () => {
    document.title = 'Policy';
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const [selectedTab, setSelectedTab] = useState('maker');
    const [loading, setLoading] = useState(false);

    const handlePolicyTabContent = (type) => {
        return type === 'maker' ? (
            <MakerPolicies type={selectedTab} setLoading={handleLoading} />
        ) : (
            <CheckerPolicies type={selectedTab} setLoading={handleLoading} />
        );
    };

    const handleLoading = (val) => {
        setLoading(val);
    };

    const policiesList = [
        { tabID: 'maker', tabName: 'Maker', tabContent: handlePolicyTabContent('maker') },
        { tabID: 'checker', tabName: 'Under Review', tabContent: handlePolicyTabContent('checker') }
    ];

    return (
        <React.Fragment>
            {loading && <Loader />}
            {tenantConfig?.ENABLE_WORKFLOW ? (
                <React.Fragment>
                    <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                        <Container fluid>
                            <BreadCrumb pageTitle="Device Policies" history={history} homeLink="Dashboard" />
                            <TabsComponent tabsList={policiesList} defaultTabID={'maker'} toggleTab={(tab) => setSelectedTab(tab)} />
                        </Container>
                    </div>
                </React.Fragment>
            ) : (
                <Policies setLoading={setLoading} />
            )}
        </React.Fragment>
    );
};

export default Policy;
