import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import AndroidIcon from '../../../assets/images/svg/android.svg';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import { Tooltip } from '../../../Components/Common/Util';

const ApplicationsList = (props) => {
    const [cardData, setCardData] = useState([...props.cardData]);
    const [cardDataBackUp, setCardDataBackUp] = useState([...props.cardData]);
    const [pageCards, setPageCards] = useState([]);
    const [searchApp, setSearchApp] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(60);

    useEffect(() => {
        setCardDataBackUp([...props.cardData]);
        if (searchApp && searchApp !== '') onChangeApp(searchApp, props.cardData);
        else {
            setPageCards([...props.cardData].splice(0, 60));
            setCardData([...props.cardData]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cardData]);

    const onChangeApp = (val, cardsList) => {
        setSearchApp(val);
        cardsList = cardsList.filter(
            (card) => card.title.toLowerCase().includes(val.toLowerCase()) || card.packageName.toLowerCase().includes(val.toLowerCase())
        );
        setCardData(cardsList);
        setPageCards(cardsList.splice(60 * (activePage - 1), 60 * activePage));
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        let cards = [...cardData];
        setPageCards(cards.splice(60 * (pageNumber - 1), 60 * pageNumber));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                <Row>
                    <Col>
                        <div className="form-icon flex-nowrap">
                            <input
                                autoComplete="off"
                                type="text"
                                id="search-bar-0"
                                value={searchApp}
                                className={`form-control form-control-icon ${props.code ? '' : 'large-search-filter-1'}`}
                                placeholder="Search by package name or package title"
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={(e) => {
                                    onChangeApp(e.target.value, cardDataBackUp);
                                }}
                            />
                            <i className="ri-search-line link-info"></i>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                {pageCards?.length > 0 ? (
                    <>
                        {pageCards.map((app, index) => {
                            return (
                                <Col key={index} xl={props.code ? 6 : 2} ms={props.code ? 6 : 4} xs={props.code ? 12 : 6}>
                                    <Card
                                        className={
                                            'shadow-card border card-height-100 ribbon-box border shadow-none mb-lg-0 ' +
                                            (app.kiosk || (!props.enableCheck && app.HRBlocked) || app.setupAction || app.blockAction
                                                ? 'pe-none opacity-75'
                                                : '')
                                        }
                                    >
                                        <CardHeader className="p-3 d-flex justify-content-end algin-items-center">
                                            {app.kiosk && (
                                                <span className="ribbon-three ribbon-three-success">
                                                    <span>In Kiosk</span>
                                                </span>
                                            )}
                                            {app.setupAction && (
                                                <span className="ribbon-three ribbon-three-info">
                                                    <span>Setup Action</span>
                                                </span>
                                            )}
                                            <input
                                                className="form-check-input cursor-pointer"
                                                type="checkbox"
                                                disabled={props.formType === 'view'}
                                                checked={app.selected ? app.selected : false}
                                                onChange={(e) => {
                                                    props.handleAppSelection(e, app, index);
                                                    setActivePage(1);
                                                }}
                                            />
                                        </CardHeader>
                                        <CardBody
                                            className={props.disableConfig ? '' : 'cursor-pointer'}
                                            onClick={props.disableConfig ? undefined : () => props.handleConfig(app, index)}
                                        >
                                            <div className=" d-flex align-items-center flex-column">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <img
                                                        src={app.smallIconUrl ? app.smallIconUrl : AndroidIcon}
                                                        alt={'app'}
                                                        className="rounded-2"
                                                        width={40}
                                                        height={40}
                                                    />
                                                </div>
                                                <div className="flex-grow-1 mt-1 w-100">
                                                    <>
                                                        <p
                                                            className="fs-14 fw-medium m-0 text-muted text-center mb-1 text-truncate"
                                                            id={props.useTooltip2 ? `title-${index}` : `titles-${index}`}
                                                        >
                                                            {app.title}
                                                        </p>
                                                        {!props.useTooltip2 && Tooltip(`titles-${index}`, app.title)}
                                                        {props.useTooltip2 && Tooltip(`title-${index}`, app.title)}
                                                    </>
                                                    <>
                                                        <p
                                                            className="fs-14 m-0 mb-1 text-muted text-center text-truncate"
                                                            id={props.useTooltip2 ? `package-${index}` : `packages-${index}`}
                                                        >
                                                            {app.packageName}
                                                        </p>
                                                        {!props.useTooltip2 && Tooltip(`packages-${index}`, app.packageName)}
                                                        {props.useTooltip2 && Tooltip(`package-${index}`, app.packageName)}
                                                    </>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })}
                        {cardData?.length > 60 && (
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={cardData.length}
                                pageRangeDisplayed={3}
                                onChange={handlePageChange}
                                hideFirstLastPages={false}
                            />
                        )}
                    </>
                ) : (
                    <div className="fs-12 text-center py-4">{'No data found.'}</div>
                )}
            </Row>
        </React.Fragment>
    );
};

export default ApplicationsList;
