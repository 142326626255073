// @flow
import { all, fork, takeEvery, put } from 'redux-saga/effects';

/**
 * Changes the layout type
 * @param {*} param0
 */
function* authUser({ payload: auth }) {
    try {
        yield put({ type: 'AUTH_USER_SUCCESS', authUser: auth });
    } catch (error) {
        yield put({ type: 'AUTH_USER_FAIL', error: error });
    }
}

function* enterpriseAccount({ payload: enterprise }) {
    try {
        yield put({ type: 'ENTERPRISE_ACCOUNT_SUCCESS', enterpriseAccount: enterprise });
    } catch (error) {
        yield put({ type: 'ENTERPRISE_ACCOUNT_FAIL', error: error });
    }
}
function* envValFunc({ payload: envVals }) {
    try {
        yield put({ type: 'ENV_VALS_SUCCESS', envVals: envVals });
    } catch (error) {
        yield put({ type: 'ENV_VALS_FAIL', error: error });
    }
}
/**
 * Watchers
 */
export function* watchAuthUser() {
    yield takeEvery('AUTH_USER', authUser);
}

export function* watchEnterpriseAccount() {
    yield takeEvery('ENTERPRISE_ACCOUNT', enterpriseAccount);
}

export function* watchEnvVal() {
    yield takeEvery('ENV_VALS', envValFunc);
}

function* CommonsSaga() {
    yield all([fork(watchAuthUser), fork(watchEnterpriseAccount), fork(watchEnvVal)]);
}

export default CommonsSaga;
