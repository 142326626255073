/* eslint-disable max-lines */

import customLauncher from '../../assets/images/customlauncher.png';
import tectoroLauncher from '../../assets/images/launcher.png';

import AndroidImage from '../../assets/images/android_image.png';
import TvImage from '../../assets/images/Tv_image.png';
import IOSImage from '../../assets/images/ios_image.png';
import WindowsImage from '../../assets/images/windows_image.png';
import { TenantConfig } from '../Common/Util';

import DownloadImage from '../../assets/images/download.png';
import AppleImage from '../../assets/images/apple.png';
import UploadImage from '../../assets/images/upload.png';
import DownloadIcon from '../../assets/images/downloadIcon.png';
import SigninIcon from '../../assets/images/signinIcon.png';

import microsoft from '../../assets/images/microsoft.png';
import google from '../../assets/images/Google.png';
import tectoro from '../../assets/images/tectoroo.png';
import LDAP from '../../assets/images/LDAP.png';
import { COUNTRIES } from './Countries';

export const priorities = [
    { label: 'High', value: 'HIGH' },
    { label: 'Medium', value: 'MEDIUM' },
    { label: 'Low', value: 'LOW' }
];

export const status = [
    { label: 'Open', value: 'OPEN' },
    { label: 'New', value: 'NEW' },
    { label: 'Closed', value: 'CLOSED' }
];

export const LANGUAGES = [
    { label: 'Arabic', value: 'ar' },
    { label: 'English', value: 'en', isFixed: true },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Nepali', value: 'ne' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Sanskrit', value: 'sa' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Telugu', value: 'te' },
    { label: 'Urdu', value: 'ur' }
];

export const ASSETTYPES = [
    { label: 'Launcher Logo', value: 'Launcher Logo' },
    { label: 'Launcher Background Image', value: 'Launcher Background Image' },
    { label: 'Wallpaper', value: 'Wallpaper' },
    { label: 'Boot Logo Image', value: 'Boot Logo Image' },
    { label: 'Boot Animation', value: 'Boot Animation' },
    { label: 'Boot Sound', value: 'Boot Sound' },
    { label: 'Buzzer Sound', value: 'Buzzer Sound' }
];

export const FILTERS = [
    { label: 'Device Status', value: 'device_status' },
    { label: 'Online Status', value: 'online_status' },
    { label: 'App Status', value: 'appStatusArr' },
    { label: 'Policies', value: 'policies' },
    { label: 'Device Groups', value: 'groups' },
    { label: 'Violations', value: 'violations' }
];

export const CONTENT_TYPES = [
    { label: 'Audio', value: 'audio' },
    { label: 'Video', value: 'video' },
    { label: 'Image', value: 'image' },
    { label: 'Document', value: 'document' }
];

export const GROUP_COLORS = ['#3C2A21', '#424C8B', '#FF0810', '#A87DAC', '#428B83', '#63B736', '#6DC8C7', '#8B5E42', '#9650D1', '#447DB1'];

export const ACTION_CODES = {
    DONE: { name: 'Done', class: 'success' },
    SUBMITTED: { name: 'Submitted', class: 'warning' },
    FAIL: { name: 'Fail', class: 'danger' }
};

export const ACTIONS = {
    LOCK: { label: 'Lock', icon: 'ri-phone-lock-line' },
    START_LOST_MODE: { label: 'Start Lost Mode', icon: 'ri-smartphone-line' },
    STOP_LOST_MODE: { label: 'Stop Lost Mode', icon: 'ri-smartphone-line' },
    REBOOT: { label: 'Reboot', icon: 'ri-creative-commons-sa-line' },
    RESET_PASSWORD: { label: 'Reset Password', icon: 'ri-lock-password-line' },
    Scream: { label: 'Scream', icon: 'ri-volume-vibrate-line', off: 'ri-volume-off-vibrate-line' },
    SCREAM_OFF: { label: 'Scream Off', icon: 'ri-volume-off-vibrate-line' },
    CLEAR_CACHE: { label: 'Clear Cache', image: 'clearcache', alt: 'clearcache', showBadge: true, badgeColor: 'primary' },
    custom_launcher_password: {
        label: 'Launcher Password',
        icon: 'ri-key-2-line',
        alt: 'launcher',
        showTooltip: true,
        tooltipValue: 'launcherPassword'
    },
    factory_mode: {
        label1: 'Enable Factory Mode',
        action1: 'enable_factory_mode',
        icon1: 'ri-android-line',
        label2: 'Disable Factory Mode',
        action2: 'disable_factory_mode',
        icon2: 'ri-android-line'
    }
};

export const HEADERS = { total: 'Total Domains', allowed: 'Allowed Domains', blocked: 'Blocked Domains' };

export const fileFormats = [
    { label: 'PDF', value: 'pdf', extensions: ['.pdf'] },
    { label: 'Document', value: 'document', extenstions: ['.docx', '.doc', '.docs'] },
    { label: 'Excel', value: 'excel', extensions: ['.xlsx', 'xls'] },
    { label: 'CSV', value: 'csv', extensions: ['.csv'] }
];

export const securityOptions = [
    { label: 'None', value: 'None' },
    { label: 'WEP-PSK', value: 'WEP-PSK' },
    { label: 'WPA-PSK', value: 'WPA-PSK' }
    /*
     * { label: 'WPA-EAP', value: 'WPA-EAP' },
     * { label: 'WEP-8021X', value: 'WEP-8021X' },
     * { label: 'WPA2', value: 'WPA2' },
     * { label: 'WPA2-WPA3', value: 'WPA2-WPA3' },
     * { label: 'WPA3', value: 'WPA3' },
     * { label: 'WPA2-Enterprise', value: 'WPA2-Enterprise' },
     * { label: 'WPA2-WPA3-Enterprise', value: 'WPA2-WPA3-Enterprise' },
     * { label: 'WPA3-Enterprise', value: 'WPA3-Enterprise' },
     * { label: 'WPA3-Enterprise_192', value: 'WPA3-Enterprise_192' }
     */
];

export const qrSecurityOptions = [
    { label: 'NONE', value: 'NONE' },
    { label: 'WPA', value: 'WPA' },
    { label: 'WEP', value: 'WEP' }
];

export const enrolmentProcess = [
    {
        label: 'Skip Education Screens',
        value: 'educationScreens',
        helpText: 'Enabling this will skip the info screens during enrollment.'
    },
    {
        label: 'System Apps Enabled',
        value: 'systemApps',
        helpText: 'Enabling this will keep the system apps intact.'
    },
    {
        label: 'Allow Offline',
        value: 'allowOffline',
        helpText: 'Enabling this will allows offline provisioning.'
    },
    {
        label: 'Keep Screen On',
        value: 'screenOn',
        helpText: `
		1. Device screen is always active during the enrolment process.
		2. Supports API Version 33 and is deprecated from API Version 34.
        3. Supports only Android 13 & below.
		`
    }
];

export const luncherTypes = [
    /*
     * {
     *     label: 'Fully Managed Launcher',
     *     key: 'fully_managed',
     *     hideboxShadow: true,
     *     kioskInfo: 'Not In Kiosk Mode',
     *     image: fullyManaged,
     *     infoClass: 'danger',
     *     value: undefined,
     *     showKioskConfig: false,
     *     showConfig: false,
     *     checked: false,
     *     showLauncherSelection: false
     * },
     * {
     *     label: 'Android Kiosk Launcher',
     *     key: 'default_launcher',
     *     kioskInfo: 'In Kiosk Mode',
     *     image: androidKioskLauncher,
     *     value: true,
     *     infoClass: '0CD8C8',
     *     showKioskConfig: true,
     *     showConfig: false,
     *     checked: false,
     *     showLauncherSelection: false
     * },
     * {
     *     label: 'Tectoro Launcher',
     *     key: 'tectoro_launcher',
     *     kioskInfo: 'In Kiosk Mode',
     *     image: ttLauncher,
     *     infoClass: '0CD8C8',
     *     showKioskConfig: true,
     *     showConfig: true,
     *     showLauncherSelection: false
     * },
     */
    {
        label: { SA: 'Custom Launcher', TL: 'Tectoro Launcher' },
        key: 'custom_launcher',
        kioskInfo: 'In Kiosk Mode',
        alt: { SA: 'custom_launcher', TL: 'tectoro_launcher' },
        value: false,
        image: { SA: customLauncher, TL: tectoroLauncher },
        infoClass: '0CD8C8',
        showKioskConfig: true,
        checked: false,
        showConfig: true,
        showLauncherSelection: true
    }
];

export const TYPES = [
    { label: 'Announcement', value: 'ANNOUNCEMENT', key: 'announcement' }
    // { label: 'Puzzle', value: 'PUZZLE', key: 'puzzle' }
];

export const FORMATS = [
    { label: 'Audio', value: 'audio/mp3', key: 'mp3' },
    { label: 'Video', value: 'video/mp4', key: 'mp4' },
    { label: 'Text', value: 'txt', key: 'txt' }
];

export const DEVICE_ACTIONS = [
    { label: 'Antivirus', value: 'ANTIVIRUS' },
    { label: 'App Usage', value: 'APP_USAGE' },
    { label: 'Factory Mode', value: 'FACTORY_MODE' },
    { label: 'Factory Reset', value: 'FACTORY_RESET' },
    { label: 'Geofence', value: 'GEOFENCE' },
    { label: 'Launcher Password', value: 'LAUNCHER_PASSWORD' },
    { label: 'Location History', value: 'LOCATION_HISTORY' },
    { label: 'Lock', value: 'LOCK' },
    { label: 'Lost mode', value: 'LOST_MODE' },
    { label: 'Reboot', value: 'REBOOT' },
    { label: 'Refresh', value: 'REFRESH' },
    { label: 'Remote', value: 'REMOTE' },
    { label: 'Reset Password', value: 'RESET_PASSWORD' },
    { label: 'Scream', value: 'SCREAM' },
    { label: 'Violations', value: 'VIOLATIONS' }
];

export const CONFIGS_ON_POLICY = [
    { label: 'Enable Call Logs', value: 'enable_call_logs_config' },
    { label: 'Enable App Usage Report', value: 'enable_app_usage_report_config' },
    { label: 'Enable Violations', value: 'enable_violations_cofig' },
    { label: 'Enable Location History', value: 'enable_location_history_config' },
    { label: 'Enable SIM Operators', value: 'enable_sim_operators_config' },
    { label: 'Enable Battery Percentage', value: 'enable_battery_config' },
    { label: 'Enable Last Modified Date', value: 'enable_last_modified_date_config' },
    { label: 'Enable Personal Account', value: 'enable_personal_account_config' },
    { label: 'Enable Home Screen Wallpaper', value: 'enable_wallpaper_config' },
    { label: 'Enable Lock Screen Wallpaper', value: 'enable_lock_screen_wallpaper' },
    { label: 'Enable Boot Animation', value: 'enable_boot_animation_config' },
    { label: 'Enable Shutdown Logo', value: 'enable_shutdown_logo' }
];

export const APP_USAGE_INTERVALS = {
    1: '1AM - 2AM',
    2: '2AM - 3AM',
    3: '3AM - 4AM',
    4: '4AM - 5AM',
    5: '5AM - 6AM',
    6: '6AM - 7AM',
    7: '7AM - 8AM',
    8: '8AM - 9AM',
    9: '9AM - 10AM',
    10: '10AM - 11AM',
    11: '11AM - 12PM',
    12: '12PM - 1PM',
    13: '1PM - 2PM',
    14: '2PM - 3PM',
    15: '3PM - 4PM',
    16: '4PM - 5PM',
    17: '5PM - 6PM',
    18: '6PM - 7PM',
    19: '7PM - 8PM',
    20: '8PM - 9PM',
    21: '9PM - 10PM',
    22: '10PM - 11PM',
    23: '11PM - 12AM',
    24: '12AM - 1AM',
    25: 'Today'
};

export const MONTHS = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};

export const CONSTANTS = {
    KEYSIZE: 128,
    ITERATION_COUNT: 1000,
    PASSPHRASE: 'iZVhQrsuAwzU7j2pOkywChQ1wawN996fDU8NbIRLGS3G7fLm7y'
};

export const NETWORKS = [
    { label: 'Any', value: 'any' },
    { label: 'None', value: 'none' },
    { label: 'Airtel', value: 'airtel' },
    { label: 'BSNL', value: 'bsnl' },
    { label: 'Jio', value: 'jio' },
    { label: 'VI', value: 'Vodafone IN' }
];

export const SIM_TYPES = [
    { label: 'Any', value: 'any' },
    { label: 'Organization SIM', value: 'organisation_sim' }
];

export const fileTypeValidations = {
    wallpaper: ['png', 'jpg', 'jpeg'],
    bootImage: ['ogg', 'qmg'],
    shutdownlogo: ['zip'],
    bootAnimation: ['zip'],
    bootSound: ['mp3'],
    bootBuzzer: ['mp3'],
    bulkAction: ['csv'],
    documents: ['pdf', 'docx'],
    audio: ['mp3', 'wav'],
    video: ['mp4'],
    all: ['jpg', 'jpeg', 'png', 'mp3', 'mp4', 'pdf', 'docx'],
    apps: ['apk']
};

export const days = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
};

export const daysNumeric = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6
};

export const daysShort = {
    SUNDAY: 'Sun',
    MONDAY: 'Mon',
    TUESDAY: 'Tue',
    WEDNESDAY: 'Wed',
    THURSDAY: 'Thu',
    FRIDAY: 'Fri',
    SATURDAY: 'Sat'
};

export const APP_STATUS = [
    { label: 'Latest', class: 'success', value: '3', checked: false },
    { label: 'Not Latest', class: 'warning', value: '2', checked: false },
    { label: 'Not Installed', class: 'danger', value: '1', checked: false }
];

export const ACTION_TYPES = [
    { label: 'Add/Update Notes on Device', value: 'NOTES' },
    { label: 'Clear Cache', value: 'CLEAR_CACHE' },
    { label: 'Device Factory Reset', value: 'RESET' },
    { label: 'Lock Device', value: 'LOCK' },
    { label: 'Reboot Device', value: 'REBOOT' },
    { label: 'Retire Device', value: 'RETIRE' },
    { label: 'Reset Device Password', value: 'RESET_PASSWORD' },
    { label: 'Update Device Group on Device', value: 'NEWGROUP' },
    { label: 'Update Policy on Device', value: 'NEWPOLICY' }
];

export const priviliges = {
    DEVICE_READER: 'DEVICE_READER',
    DEVICE_EDITOR: 'DEVICE_EDITOR',
    POLICY_READER: 'POLICY_READER',
    POLICY_EDITOR: 'POLICY_EDITOR',
    TVPOLICY_READER: 'TVPOLICY_READER',
    TVPOLICY_EDITOR: 'TVPOLICY_EDITOR',
    DEVICEAUTOMATION_READER: 'DEVICEAUTOMATION_READER',
    DEVICEAUTOMATION_EDITOR: 'DEVICEAUTOMATION_EDITOR',
    DEVICEGROUP_READER: 'DEVICEGROUP_READER',
    DEVICEGROUP_EDITOR: 'DEVICEGROUP_EDITOR',
    APPLICATION_READER: 'APPLICATION_READER',
    APPLICATION_EDITOR: 'APPLICATION_EDITOR',
    FILE_READER: 'FILE_READER',
    FILE_EDITOR: 'FILE_EDITOR',
    TICKETS_READER: 'TICKETS_READER',
    TICKETS_EDITOR: 'TICKETS_EDITOR',
    MESSAGE_READER: 'MESSAGE_READER',
    MESSAGE_EDITOR: 'MESSAGE_EDITOR',
    CONFIGURATION_READER: 'CONFIGURATION_READER',
    CONFIGURATION_EDITOR: 'CONFIGURATION_EDITOR',
    ASSET_READER: 'ASSET_READER',
    ASSET_EDITOR: 'ASSET_EDITOR',
    ENTERPRISE_READER: 'ENTERPRISE_READER',
    ENTERPRISE_EDITOR: 'ENTERPRISE_EDITOR',
    ZEROTOUCH_READER: 'ZEROTOUCH_READER',
    ZEROTOUCH_EDITOR: 'ZEROTOUCH_EDITOR',
    USER_READER: 'USER_READER',
    USER_EDITOR: 'USER_EDITOR',
    USERGROUP_READER: 'USERGROUP_READER',
    USERGROUP_EDITOR: 'USERGROUP_EDITOR',
    ROLE_READER: 'ROLE_READER',
    ROLE_EDITOR: 'ROLE_EDITOR',
    TENANTMASTER_READER: 'TENANTMASTER_READER',
    TENANTMASTER_EDITOR: 'TENANTMASTER_EDITOR',
    TVDEVICE_READER: 'TVDEVICE_READER',
    TVDEVICE_EDITOR: 'TVDEVICE_EDITOR',
    TENANT_READER: 'TENANT_READER',
    TENANT_EDITOR: 'TENANT_EDITOR',
    CALLLOG_READER: 'CALLLOG_READER',
    TVAPPLICATION_READER: 'TVAPPLICATION_READER',
    TVAPPLICATION_EDITOR: 'TVAPPLICATION_EDITOR',
    PLAYSTORE_READER: 'PLAYSTORE_READER',
    PLAYSTORE_EDITOR: 'PLAYSTORE_EDITOR',
    CERTIFICATE_READER: 'CERTIFICATE_READER',
    CERTIFICATE_EDITOR: 'CERTIFICATE_EDITOR',
    GROUP_READER: 'GROUP_READER',
    GROUP_EDITOR: 'GROUP_EDITOR',
    IOSDEVICE_READER: 'DEVICE_READER',
    IOSDEVICE_EDITOR: 'DEVICE_EDITOR',
    IOSPOLICY_READER: 'POLICY_READER',
    IOSPOLICY_EDITOR: 'POLICY_EDITOR',
    IOS_DEVICE_READER: 'IOS_DEVICE_READER',
    IOS_DEVICE_EDITOR: 'IOS_DEVICE_EDITOR',
    IOSAPPS_READER: 'IOSAPPS_READER',
    IOSAPPS_EDITOR: 'IOSAPPS_EDITOR',
    MYDEVICES: 'MYDEVICES',
    WINDOWSPOLICY_READER: 'POLICY_READER',
    WINDOWSPOLICY_EDITOR: 'POLICY_EDITOR',
    WINDOWSDEVICE_READER: 'DEVICE_READER',
    WINDOWSDEVICE_EDITOR: 'DEVICE_EDITOR',
    AVSCAN_READER: 'AVSCAN_READER',
    AVSCAN_EDITOR: 'AVSCAN_EDITOR'
};

export const WIFI_OPTIONS = [
    // {
    //     label: 'Wi-Fi',
    //     value: 'Wi-Fi',
    //     extensions: [
    //         { label: '.xml', value: '.xml' }
    //         /*
    //          * { label: '.conf', value: '.conf' },
    //          * { label: '.config', value: '.config' },
    //          * { label: '.plist', value: '.plist' },
    //          * { label: '.json', value: '.json' }
    //          */
    //     ]
    // },
    // {
    //     label: 'VPN',
    //     value: 'VPN',
    //     extensions: [
    //         { label: '.ovpn', value: '.ovpn' }
    //         /*
    //          * { label: '.conf', value: '.conf' },
    //          * { label: '.xml', value: '.xml' },
    //          * { label: '.pcf', value: '.pcf' },
    //          * { label: '.mobileconfig', value: '.mobileconfig' }
    //          */
    //     ]
    // },
    {
        label: 'Cryptographic',
        value: 'Cryptographic',
        extensions: [
            { label: '.p12', value: '.p12', reqPassword: true },
            { label: '.pem', value: '.pem' },
            { label: '.pfx', value: '.pfx', reqPassword: true }
            /*
             * { label: '.cer', value: '.cer' },
             * { label: '.crt', value: '.crt' },
             * { label: '.key', value: '.key' },
             * { label: '.der', value: '.der' },
             * { label: '.csr', value: '.csr' },
             * { label: '.jks', value: '.jks' },
             * { label: '.keystore', value: '.keystore' },
             * { label: '.pub', value: '.pub' }
             */
        ]
    }
];

export const BRANDING_SIZES = [
    {
        label: 'Small',
        icon: { key: 'small', width: '80', height: '80', widthPercent: '80%', heightPercent: '80%' },
        appName: { key: 'small', width: '15', height: '15' }
    },
    {
        label: 'Medium',
        icon: { key: 'medium', width: '110', height: '110', widthPercent: '90%', heightPercent: '90%' },
        appName: { key: 'medium', width: '18', height: '18' }
    },
    {
        label: 'Large',
        icon: { key: 'large', width: '140', height: '140', widthPercent: '100%', heightPercent: '100%' },
        appName: { key: 'large', width: '22', height: '22' }
    }
];
export const APP_ICON_SIZES = [
    {
        label: 1,
        value: 1
    },
    {
        label: 2,
        value: 2
    },
    {
        label: 3,
        value: 3
    },
    {
        label: 4,
        value: 4
    },
    {
        label: 5,
        value: 5
    }
];
export const reports = () => {
    const tenant = TenantConfig();
    return [
        {
            label: 'Android',
            link: '/aeenterprise',
            myDeviceRoute: '/aemydevices',
            value: 'android_dashboard',
            logo: AndroidImage,
            show: tenant.ANDROID_ENTERPRISE_ENABLED,
            privRequired: [priviliges.ENTERPRISE_READER],
            content: 'Managing Android Devices with Tectoro EMM allows you to manage and monitor devices, with enhanced security.'
        },
        {
            label: 'Android TV',
            link: '/atvconsole',
            myDeviceRoute: '/tvmydevices',
            value: 'android_tv_dashboard',
            logo: TvImage,
            show: tenant.ANDROID_TV_ENABLED,
            privRequired: [priviliges.DEVICE_READER],
            content: 'TV devices can now be configured to push content and use the TVs for remote management.'
        },
        {
            label: 'Apple iOS',
            link: '/iossetup',
            myDeviceRoute: '/iosmydevices',
            value: 'ios_dashboard',
            logo: IOSImage,
            show: tenant.IOS_ENABLED,
            privRequired: [priviliges.DEVICE_READER],
            content:
                'Apple devices are now managed in a simple and efficient manner with Tectoro MDM in collaboration with Apple Business Manager. '
        },
        {
            label: 'Windows',
            link: '/windowssetup',
            value: 'window_dashboard',
            logo: WindowsImage,
            show: tenant.WINDOWS_ENABLED,
            privRequired: [priviliges.DEVICE_READER],
            content:
                'All Windows OS laptops and PCs can now be managed and monitored by Tectoro MDM, while boosting productivity and enhancing security with user customisation.'
        }
    ];
};

export const modalData = [
    {
        label: 'Contact Name',
        value: 'TectAdmin'
    },
    {
        label: 'Contact Number',
        value: '+91 9393939393'
    },
    {
        label: 'Email ID',
        value: 'tectadmin@tectoro.com'
    }
];

export const licenseData = [
    { title: 'License Starting Date', value: 'Apr 01, 2024 10:00 AM', labelCss: 'text-black' },
    { title: 'License End Date', value: 'Apr 01, 2024 10:00 AM' },
    { title: 'License Validity', value: '30 Days', labelCss: 'text-black' }
];

export const totalQuantityData = [
    { title: 'Total Quantity', value: '1,00,000' },
    { title: 'Used Licenses', value: '80,000' },
    { title: 'Remaining Licenses', value: '20,000', labelCss: 'text-success' }
];
export const purchasePlan = [
    'Up to 3 Projects',
    'Up to 299 Customers',
    'Scalable BandWidth',
    '5 FTP Login',
    'Up to 3 Projects',
    'Up to 299 Customers',
    'Scalable BandWidth',
    '5 FTP Login',
    'Up to 3 Projects',
    'Up to 299 Customers',
    'Up to 3 Projects',
    'Up to 299 Customers'
];
export const freeTrailPlan = [
    'Up to 3 Projects',
    'Up to 299 Customers',
    'Scalable BandWidth',
    '5 FTP Login',
    'Up to 3 Projects',
    'Up to 299 Customers',
    'Scalable BandWidth',
    '5 FTP Login',
    'Up to 3 Projects',
    'Up to 299 Customers',
    'Up to 3 Projects',
    'Up to 299 Customers'
];
export const inputComponentData = [
    {
        label: 'Device Count',
        type: 'text',
        inputType: 'input',
        value: 'device',
        Placeholder: 'Enter Device Count',
        mandatory: false
    },
    {
        label: 'License Start Date',
        type: 'date',
        inputType: 'input',
        value: 'startDate',
        Placeholder: 'Enter License Start Date',
        mandatory: false
    },
    {
        label: 'License End Date',
        type: 'date',
        inputType: 'input',
        value: 'endDate',
        Placeholder: 'Enter License End Date',
        mandatory: false
    }
];
export const applePushData = [
    {
        step: 'Step 1',
        image: DownloadImage,
        title: 'Download File',
        text: 'Please click below to download the Certificate Signing Request file. This needs to be uploaded to your Corporate Apple Account. This would help Apple recognise Tectoro EMM.',
        icon: DownloadIcon,
        linkText: 'Download File.csv'
    },
    {
        step: 'Step 2',
        image: AppleImage,
        title: 'Sign In to Apple Portal',
        text: 'Sign in to the Apple Push Notification portal with your Apple ID and upload the Vendor Signed CSR (.plist file). Download the APNs certificate (.pem/.crt /.cer file) generated. If you do not have an Apple account, it can be created and then same steps can be followed.',
        icon: SigninIcon,
        linkText: 'Sign In to Apple Portal'
    },
    {
        step: 'Step 3',
        image: UploadImage,
        title: 'Upload Apple Push Certificate',
        text: 'The APN certificate must be uploaded below to complete the APN process. This certificate expires in a year, and must be renewed. The reminder will be sent to the Apple ID email that is used for this process.'
    }
];

export const bussinessData = [
    {
        step: 'Step 1',
        image: DownloadImage,
        title: 'Download File',
        text: 'Please click below to download the Certificate Signing Request file. This needs to be uploaded to your Corporate Apple Account. This would help Apple recognise Tectoro EMM.',
        icon: DownloadIcon,
        linkText: 'Download File.csv'
    },
    {
        step: 'Step 2',
        image: AppleImage,
        title: 'Sign In to Apple Business Manager',
        text: 'Sign in to the Apple Business Manager or Apple School Manager with your corporate account details. Upload the certificate that is downloaded in step 1. The same can be done with Apple School Manager as well. A token signed file is available for download.',
        icon: SigninIcon,
        linkText: 'Sign In to Apple Business Manager',
        icon2: SigninIcon,
        linkText2: 'Sign In to Apple School Manager'
    },
    {
        step: 'Step 3',
        image: UploadImage,
        title: 'Upload Apple Signed Certificate',
        text: 'The Apple Business Manager signed certificate must be uploaded below to complete the DEP process. This certificate expires in a year, and must be renewed. The reminder will be sent to the Apple ID email that is used for this process.'
    }
];

export const volumePurchaseData = [
    {
        step: 'Step 1',
        image: AppleImage,
        title: 'Sign In to Apple Business Manager',
        text: 'Sign in to the Apple Business Manager or Apple School Manager with your corporate account details. Upload the certificate that is downloaded in step 1. The same can be done with Apple School Manager as well. A token signed file is available for download.',
        icon: SigninIcon,
        linkText: 'Sign In to Apple Business Manager',
        icon2: SigninIcon,
        linkText2: 'Sign In to Apple School Manager'
    },
    {
        step: 'Step 2',
        image: UploadImage,
        title: 'Upload VPP Token',
        text: 'The VPP token must be uploaded below to compare the VPP setup process. This token expires in one year and must be renewed.'
    }
];

export const ENROLLMENT_STEPS_DATA = [
    {
        label: 'Name',
        value: 'profile_name',
        inputType: 'input',
        path: ['profile'],
        helpText: 'Add profile name, which will be displayed during enrolment process.'
    },
    /*
     * {
     *     label: 'Configuration Web URL',
     *     inputType: 'input',
     *     value: 'configuration_web_url',
     *     path: ['profile'],
     *     helpText:
     *         'The same URL to be used, which was initially added by an Admin during setup process. This authenticates user before downloading the MDM enrolment profile.'
     * },
     */
    { label: 'Department', inputType: 'input', value: 'department', path: ['profile'], helpText: 'Add department or location name' },
    {
        label: 'Language',
        value: 'language',
        inputType: 'dropdown',
        optionLabel: 'label',
        optionValue: 'value',
        options: [
            { label: 'English', value: 'en' },
            { label: 'Deutsch', value: 'de' },
            { label: 'Español', value: 'es' },
            { label: 'Français', value: 'fr' },
            { label: '日本語', value: 'ja' },
            { label: '한국어', value: 'ko' },
            { label: '简体中文', value: 'zh-Hans' },
            { label: '繁體中文', value: 'zh-Hant' },
            { label: 'Italiano', value: 'it' },
            { label: 'Português', value: 'pt' },
            { label: 'Русский', value: 'ru' },
            { label: 'العربية', value: 'ar' },
            { label: 'Nederlands', value: 'nl' },
            { label: 'Svenska', value: 'sv' },
            { label: 'Suomi', value: 'fi' },
            { label: 'Dansk', value: 'da' },
            { label: 'Norsk', value: 'no' },
            { label: 'Polski', value: 'pl' },
            { label: 'Türkçe', value: 'tr' },
            { label: 'हिन्दी', value: 'hi' },
            { label: 'ไทย', value: 'th' },
            { label: 'Bahasa Indonesia', value: 'id' },
            { label: 'Čeština', value: 'cs' },
            { label: 'Magyar', value: 'hu' },
            { label: 'Română', value: 'ro' },
            { label: 'Ελληνικά', value: 'el' },
            { label: 'Українська', value: 'uk' },
            { label: 'Tiếng Việt', value: 'vi' }
        ],
        path: ['profile'],
        helpText: 'Add the language to set on the device'
    },
    /*
     * {
     *     label: 'Region',
     *     value: 'region',
     *     inputType: 'dropdown',
     *     options: COUNTRIES,
     *     optionLabel: 'name',
     *     optionValue: 'code',
     *     path: ['profile'],
     *     helpText: 'Add the Region to set on the device'
     * },
     */
    {
        label: 'Mandatory',
        inputType: 'checkbox',
        value: 'is_mandatory',
        path: ['profile'],
        helpText: 'Enabling this feature mandates administrators to use the profile obtained from the MDM server'
    },
    {
        label: 'Allow MDM profile removal',
        inputType: 'checkbox',
        value: 'is_mdm_removable',
        path: ['profile'],
        helpText: 'Disabling this option prevents end-users from removing the MDM profile on iOS devices once enrolled.'
    },
    {
        label: 'Allow Multi-user',
        value: 'is_multi_user',
        inputType: 'checkbox',
        path: ['profile'],
        helpText: 'Enabling this feature allows the user to configure Shared iPad.'
    },
    {
        label: 'Allow Supervised',
        value: 'is_supervised',
        inputType: 'checkbox',
        path: ['profile'],
        helpText: 'Enabling this feature sets the device to supervised mode, having more control over the device.'
    },
    {
        label: 'Allow Pairing',
        inputType: 'checkbox',
        value: 'allow_pairing',
        path: ['profile'],
        helpText: 'Enabling this feature allows the device to pair during setup.'
    },
    {
        label: 'Allow Auto advance setup',
        inputType: 'checkbox',
        value: 'auto_advance_setup',
        path: ['profile'],
        helpText:
            'Enabling this feature allows devices to be automatically configured based on settings from the MDM solution, which includes skipping all Setup Assistant panes.'
    },
    /*
     * {
     *     label: 'Allow Await Device Configuration',
     *     inputType: 'checkbox',
     *     value: 'await_device_configured',
     *     path: ['profile'],
     *     helpText:
     *         'Enabling this feature allows the user to proceed through the Setup Assistant only after the device has been fully configured.'
     * },
     */
    { label: 'Skip Enrollment Steps', inputType: 'heading' },
    {
        label: 'Accessibility',
        badges: ['iOS', 'iPad', 'tvOS'],
        helpText: 'The key to skip the Accessibility pane. This key is not available in macOS.',
        value: 'skip_setup_items',
        key: 'Accessibility',
        path: ['profile']
    },
    {
        label: 'Action Button',
        badges: ['iPhone', 'iPad'],
        helpText: 'The key to skip the Action Button configuration pane. This key is available in iOS 17 and later.',
        value: 'skip_setup_items',
        key: 'ActionButton',
        path: ['profile']
    },
    {
        label: 'Android',
        badges: ['iPhone', 'iPad'],
        helpText:
            'If the Restore pane isn’t skipped, this is the key to remove the Move from Android option in the Restore pane on iOS. This key is available in iOS 9 and later.',
        value: 'skip_setup_items',
        key: 'Android',
        path: ['Profile']
    },
    {
        label: 'Appearance',
        badges: ['iPhone', 'iPad', 'macOS'],
        helpText: 'The key to skip the Choose Your Look screen. This key is available in iOS 13+ and macOS 10.14 and later.',
        value: 'skip_setup_items',
        key: 'Appearance',
        path: ['profile']
    },
    {
        label: 'AppleID',
        badges: ['iPhone', 'iPad', 'tvOS', 'macOS'],
        helpText: 'The key to skip Apple ID setup. This key is available in iOS 7.0+, tvOS 10.2 and later, and macOS 10.9 and later.',
        value: 'skip_setup_items',
        key: 'AppleID',
        path: ['profile']
    },
    {
        label: 'AppStore',
        badges: ['iPhone', 'iPad', 'macOS'],
        helpText: 'The key to skip the App Store pane. This key is available in iOS 14.3 and later, and macOS 11.1 and later.',
        value: 'skip_setup_items',
        key: 'AppStore',
        path: ['profile']
    },
    {
        label: 'Biometric',
        badges: ['iPhone', 'iPad', 'macOS'],
        helpText: 'The key to skip biometric setup. This key is available in iOS 8.1 and later, and macOS 10.12.4 and later.',
        value: 'skip_setup_items',
        key: 'Biometric',
        path: ['profile']
    },
    {
        label: 'DeviceToDeviceMigration',
        badges: ['iPhone', 'iPad'],
        helpText: 'The key to skip Device to Device Migration pane. This key is available in iOS 13 and later.',
        value: 'skip_setup_items',
        key: 'DeviceToDeviceMigration',
        path: ['profile']
    },
    {
        label: 'Diagnostics',
        badges: ['iPhone', 'iPad', 'tvOS', 'macOS'],
        helpText:
            'The key to skip the App Analytics pane. This key is available in iOS 7 and later, tvOS 10.2 and later, and macOS 10.9 and later.',
        value: 'skip_setup_items',
        key: 'Diagnostics',
        path: ['profile']
    },
    {
        label: 'Enable Lockdown Mode',
        key: 'EnableLockdownMode',
        badges: ['macOS'],
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to skip the Lockdown Mode pane if an Apple ID is set up. Available in macOS 14 and later. '
    },
    {
        label: 'File Vault',
        badges: ['macOS'],
        key: 'FileVault',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to disable the FileVault Setup Assistant screen in macOS. This key is available in macOS 10.10 and later.'
    },
    {
        label: 'Location',
        badges: ['iPhone', 'macOS'],
        key: 'Location',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to disable Location Services. This key is available in iOS 7 and later, and macOS 10.11 and later.'
    },
    {
        label: 'Passcode',
        badges: ['iPhone'],
        key: 'Passcode',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to hide and disable the passcode pane. This key is available in iOS 7 and later.'
    },
    {
        label: 'Privacy',
        badges: ['iPhone', 'tvOS', 'macOS'],
        key: 'Privacy',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText:
            'The key to skip the privacy pane. This key is available in iOS 11.13 and later, tvOS 11.13 and later, and macOS 10.13.4 and later. '
    },
    {
        label: 'Siri',
        badges: ['iPhone', 'tvOS', 'macOS'],
        key: 'Siri',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to disable Siri. This key is available in iOS 7 and later, tvOS 10.2 and later, and macOS 10.12 and later. '
    },
    {
        label: 'Terms Of Address',
        badges: ['iPhone', 'macOS'],
        key: 'TermsOfAddress',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText:
            'The key to skip the Terms of Address pane. This key isn’t always skippable because this pane appears before the device retrieves the Cloud Configuration from the server. This key is available in iOS 16 and later, and macOS 13 and later. '
    },
    {
        label: 'TOS',
        badges: ['iPhone', 'tvOS', 'macOS'],
        key: 'TOS',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText:
            'The key to skip Terms and Conditions. This key is available in iOS 7 and later, tvOS 10.2 and later, and macOS 10.9 and later. '
    },
    {
        label: 'Welcome',
        badges: ['iPhone'],
        key: 'Welcome',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to skip the Get Started pane. This key is available in iOS 13 and later. '
    },
    {
        label: 'Software Update',
        badges: ['iPhone'],
        key: 'SoftwareUpdate',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to skip the mandatory software update screen in iOS. This key is available in iOS 12 and later. '
    },
    {
        label: 'iMessage And FaceTime',
        badges: ['iPhone'],
        key: 'iMessageAndFaceTime',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to skip the iMessage and FaceTime screen in iOS. This key is available in iOS 12 and later. '
    },
    {
        label: 'Screen Time',
        badges: ['iPhone'],
        key: 'ScreenTime',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to skip the Screen Time pane. This key is available in iOS 12 and later, and macOS 10.15 and later. '
    },
    {
        label: 'SIM Setup',
        badges: ['iPhone'],
        key: 'SIMSetup',
        value: 'skip_setup_items',
        path: ['profile'],
        helpText: 'The key to skip the add cellular plan pane. This key is available in iOS 12 and later.'
    }
];

export const appleCertiData = [
    { label: 'Common Name', key: 'commanName' },
    { label: 'Issued By', key: 'issusedBy' },
    { label: 'Issued Date', key: 'issuedDate', type: 'date' },
    { label: 'Modified By', key: 'modifiedby' },
    { label: 'Expires On', key: 'expirationDate', type: 'date' }
];
export const bussinessCertiData = [
    { label: 'Name', key: 'name' },
    { label: 'Status', key: 'status' },
    { label: 'Modified Date', key: 'modifieddate', type: 'date' },
    { label: 'Modified By', key: 'modifiedby' },
    { label: 'Expires On', key: 'expirationDate', type: 'date' }
];

export const VolumeCertiData = [
    { label: 'Name', key: 'name' },
    { label: 'Location', key: 'tokenOrg' },
    { label: 'Account Type', key: 'accounttype' },
    { label: 'App Auto Update', key: 'appAutoUpdate' },
    { label: 'Modified Date', key: 'modifieddate', type: 'date' },
    { label: 'Modified By', key: 'modifiedby' },
    { label: 'Expires On', key: 'tokenExpiry', type: 'date' }
];

export const DAYS = [
    {
        label: 'Mon',
        val: 'MONDAY',
        value: '1'
    },
    {
        label: 'Tue',
        val: 'TUESDAY',
        value: '2'
    },
    {
        label: 'Wed',
        val: 'WEDNESDAY',
        value: '3'
    },
    {
        label: 'Thu',
        val: 'THURSDAY',
        value: '4'
    },
    {
        label: 'Fri',
        val: 'FRIDAY',
        value: '5'
    },
    {
        label: 'Sat',
        val: 'SATURDAY',
        value: '6'
    },
    {
        label: 'Sun',
        val: 'SUNDAY',
        value: '0'
    }
];

export const AUTH_MODES = [
    {
        icon: tectoro,
        title: 'Tectoro',
        key: 'local',
        configured: false,
        enabled: true,
        info: 'Single Sign-on using a password securely set by an employee',
        status: 'Configure',
        route: '/tectoro'
    },
    {
        icon: microsoft,
        title: 'Microsoft',
        key: 'microsoft',
        configured: false,
        enabled: false,
        info: 'Single Sign-on using Microsoft Office 365 or Azure Active Directory Authentication',
        status: 'Configure',
        route: '/microsoft'
    },
    {
        icon: google,
        title: 'Google',
        key: 'google',
        configured: false,
        enabled: false,
        info: 'Single Sign-on using google for business',
        status: 'Configure',
        route: '/google'
    },
    {
        icon: LDAP,
        title: 'LDAP',
        key: 'ldap',
        configured: false,
        enabled: false,
        info: 'Single Sign-on using Office 365 or Azure Active Directory Authentication',
        status: 'Configure',
        route: '/ldap'
    }
];

export const defaultBranding = {
    iconSize: '110px',
    iconsPerRow: 4,
    iconSizeLabel: 'Medium',
    iconOrder: 'atoz',
    orientation: 'portrait',
    hideappLabel: false,
    appIconTextColor: '#FFFFFF',
    appIconBgColor: '#dd1d1d',
    appIconLabelTransparency: false,
    appLabelShadow: true,
    topBar: {
        enabled: true,
        color: '#FFFFFF',
        bgColor: '#405189',
        transparent: false,
        serial: true,
        imei: true,
        refresh: true
    },
    footer: {
        enabled: false,
        color: '#080808',
        bgColor: '#00fa11',
        transparent: false,
        text: 'All rights reserved. '
    },
    companyLogo: {
        enabled: false,
        logo: '501b2796cb654334a65c1663bb974458.png'
    },
    alertSound: {
        enabled: false
    }
};

export const ODM_FEATURES_SCHEMA = [
    {
        isHeader: true,
        label: 'Navigation Bar'
    },
    {
        label: 'Home',
        value: 'home',
        helpText: 'Enabling this feature would help user to view Home icon on the screen, which helps user in navigating to Home screen.',
        inputType: 'toggle'
    },
    {
        label: 'Recent',
        value: 'recent',
        helpText: 'Enabling this feature would help user to view Recent icon on the screen, which helps user view recent apps.',
        inputType: 'toggle'
    },
    {
        label: 'Back',
        value: 'back',
        helpText: 'Enabling this feature would help user to view Back icon on the screen, which helps user to navigate Back.',
        inputType: 'toggle'
    },
    {
        isHeader: true,
        label: 'Hard Keys'
    },
    {
        label: 'Volume Up',
        value: 'volumeUp',
        helpText: 'Enabling this feature would help user to increase the Volume.',
        inputType: 'toggle'
    },
    {
        label: 'Volume Down',
        value: 'volumeDown',
        helpText: 'Enabling this feature would help user to decrease the Volume.',
        inputType: 'toggle'
    },
    {
        label: 'Power',
        value: 'power',
        helpText: 'Enabling this feature would help user to Power-on or Power-off the device.',
        inputType: 'toggle'
    },
    {
        label: 'Notifications',
        value: 'notifications',
        helpText: 'Enabling this feature would help the user access the notification panel.',
        inputType: 'toggle'
    },
    {
        label: 'Factory Mode',
        value: 'factoryMode',
        helpText: 'Enabling this feature would help user to use certain options under factory mode.',
        inputType: 'toggle'
    }
];

export const integrateSchema = [
    {
        label: 'Client ID',
        value: 'clientId',
        inputType: 'input',
        helpText: 'Client ID'
    },
    {
        label: 'Client Secret',
        value: 'clientSecret',
        inputType: 'input',
        helpText: 'Client Secret'
    },
    {
        label: 'Tenant ID',
        value: 'tenantId',
        inputType: 'input',
        helpText: 'Tenant ID'
    }
];

export const dataProtectionSchema = [
    {
        isHeader: true,
        label: 'Data Transfer'
    },
    {
        label: 'Backup org data to Android backup services',
        inputType: 'radios',
        value: 'dataBackupBlocked',
        helpText: 'Display name of the profile - will be shown on the device',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    /*
     * {
     *     label: 'Send org data to other apps',
     *     value: 'sendOrgData',
     *     inputType: 'select',
     *     helpText:
     *         'Send organizational data to other apps using intents or URL schemes, ensuring permissions and security measures are in place',
     *     options: [
     *         { label: 'All apps', value: 'allApps' },
     *         { label: 'None', value: 'none' }
     *     ]
     * },
     * {
     *     label: 'Receive data from other apps',
     *     value: 'receiveData',
     *     inputType: 'select',
     *     helpText:
     *         'Receive data from other apps using intents (Android) or URL schemes and delegation methods (iOS), ensuring ..proper handling and security',
     *     options: [
     *         { label: 'All apps', value: 'allApps' },
     *         { label: 'None', value: 'none' }
     *     ]
     * },
     * {
     *     label: 'Cut, copy, paste',
     *     value: 'allowedOutboundClipboardSharingLevel',
     *     inputType: 'select',
     *     helpText: 'Cut, copy, and paste organizational data by implementing clipboard functionality, ensuring data integrity and security',
     *     options: [
     *         { label: 'Allow', value: 'allow' },
     *         { label: 'Restrict', value: 'restrict' },
     *         { label: 'Allow between policy managed apps', value: 'allowBetweenPolicyManagedApps' },
     *         { label: 'Allow paste-in from policy managed apps', value: 'allowPasteInFromPolicyManagedApps' }
     *     ]
     * },
     */
    {
        label: 'Screen capture and Google Assistant',
        inputType: 'radios',
        value: 'screenCaptureBlocked',
        helpText:
            'Google Assistant integration by utilizing appropriate APIs and permissions while ensuring user privacy and data security',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    {
        label: 'Save As Blocked',
        value: 'saveAsBlocked',
        inputType: 'radios',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    {
        label: 'Assigned',
        value: 'isAssigned',
        inputType: 'radios',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    {
        label: 'Device Compliance Required',
        value: 'deviceComplianceRequired',
        inputType: 'radios',
        radios: [
            { label: 'Require', value: 'require', val: false },
            { label: 'Not Required', value: 'notRequired', val: false }
        ]
    },
    {
        label: 'Managed Browser To Open Links Required',
        value: 'managedBrowserToOpenLinksRequired',
        inputType: 'radios',
        radios: [
            { label: 'Require', value: 'require', val: false },
            { label: 'Not Required', value: 'notRequired', val: false }
        ]
    },
    {
        isHeader: true,
        label: 'Encryption'
    },
    {
        label: 'Disable App Encryption If Device Encrypted',
        value: 'disableAppEncryptionIfDeviceEncryptionIsEnabled',
        inputType: 'radios',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    {
        label: 'Encrypt org data ',
        inputType: 'radios',
        value: 'encryptAppData',
        helpText:
            'Encrypt organizational data by classifying sensitive information, using suitable encryption methods, securing data at rest and in transit, and managing encryption keys securely',
        radios: [
            { label: 'Require', value: 'require', val: false },
            { label: 'Not Required', value: 'notRequired', val: false }
        ]
    },
    {
        label: 'Org Credentials Required',
        value: 'organizationalCredentialsRequired',
        inputType: 'radios',
        radios: [
            { label: 'Require', value: 'require', val: false },
            { label: 'Not Required', value: 'notRequired', val: false }
        ]
    },
    {
        isHeader: true,
        label: 'Functionality'
    },
    {
        label: 'Sync app with native contacts app',
        inputType: 'radios',
        value: 'contactSyncBlocked',
        helpText:
            'Sync your app with the native contacts app by requesting appropriate permissions, reading contacts using the device’s content resolver, and managing contact data securely',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    {
        label: 'Print org data',
        inputType: 'radios',
        value: 'printBlocked',
        helpText:
            'Sync your app with the native contacts app by requesting appropriate permissions, reading contacts using the device’s content resolver, and managing contact data securely',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    }
];

export const accessRequirementsSchema = [
    {
        isHeader: true,
        label: 'Configure PIN and credential requirements for accessing the apps'
    },
    {
        label: 'PIN for access',
        helpText: '',
        value: 'pinRequired',
        inputType: 'radios',
        radios: [
            { label: 'Require', value: 'require', val: false },
            { label: 'Not Required', value: 'notRequired', val: false }
        ]
    },
    {
        label: 'PIN type',
        inputType: 'radios',
        value: 'pinCharacterSet',
        showKey: 'pinRequired',
        showVal: 'require',
        helpText: '',
        radios: [
            { label: 'Numeric', value: 'numeric', val: false },
            { label: 'Passcode', value: 'passcode', val: false }
        ]
    },
    {
        label: 'Simple PIN',
        inputType: 'radios',
        showKey: 'pinRequired',
        showVal: 'require',
        value: 'simplePinBlocked',
        helpText: 'Simple PIN',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    {
        label: 'Minium PIN length',
        value: 'minimumPinLength',
        showKey: 'pinRequired',
        showVal: 'require',
        inputType: 'select',
        helpText: 'Minium PIN length',
        options: [
            { label: '4', value: 4 },
            { label: '6', value: 6 },
            { label: '8', value: 8 },
            { label: '10', value: 10 },
            { label: '12', value: 12 }
        ]
    },
    {
        label: 'Maximum Pin Retries',
        value: 'maximumPinRetries',
        showKey: 'pinRequired',
        showVal: 'require',
        inputType: 'select',
        helpText: 'Maximum Pin Retries',
        options: [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 }
        ]
    },
    {
        label: 'Fingerprint instead of PIN(Android 6.0 and above)',
        inputType: 'radios',
        showKey: 'pinRequired',
        showVal: 'require',
        value: 'fingerprintBlocked',
        helpText: 'Android 6.0 and above',
        radios: [
            { label: 'Allow', value: 'allow', val: false },
            { label: 'Restrict', value: 'restrict', val: false }
        ]
    },
    /*
     * {
     *     label: 'Reset PIN after(Days)',
     *     value: 'resetPIN',
     *     showKey: 'pinRequired',
     *     showVal: 'require',
     *     inputType: 'input',
     *     type: 'number',
     *     helpText: 'Days'
     * },
     */
    {
        label: 'App PIN when device PIN is set',
        inputType: 'radios',
        showKey: 'pinRequired',
        showVal: 'require',
        value: 'disableAppPinIfDevicePinIsSet',
        helpText: '',
        radios: [
            { label: 'Require', value: 'require', val: false },
            { label: 'Not Required', value: 'notRequired', val: false }
        ]
    }
    /*
     * {
     *     label: 'Work or School account',
     *     helpText: '',
     *     value: 'account',
     *     inputType: 'radios',
     *     radios: [
     *         { label: 'Require', value: 'require', val: false },
     *         { label: 'Not Required', value: 'notRequired', val: false }
     *     ]
     * },
     * {
     *     label: 'Rechek access requirements upon inactivity(Minutes)',
     *     helpText: '',
     *     value: 'accessRequirements',
     *     inputType: 'input',
     *     type: 'number'
     * }
     */
];

export const conditionsSchema = [
    {
        isHeader: true,
        label: 'App Conditions',
        helpText: 'App version should be in [Major].[Minor] or [Major].[Minor].[Build].[Major].[Minor].[Build].[Revision]',
        table: [
            {
                label: 'Maximum PIN attempts',
                fields: [
                    {
                        label: 'Maximum PIN attempts',
                        value: 'maxPINAttempts',
                        inputType: 'input',
                        helpText: 'Maximum PIN attempts'
                    },
                    {
                        label: 'Maximum PIN attempts',
                        value: 'maxPINAttemptsAction',
                        inputType: 'select',
                        helpText: 'Maximum PIN attempts',
                        options: [
                            { label: 'Reset PIN', value: 'resetPin' },
                            { label: 'Wipe Data', value: 'wipeData' }
                        ]
                    }
                ]
            },
            {
                label: 'Offline grace period(Minutes)',
                fields: [
                    {
                        label: 'Offline grace period',
                        value: 'offlineGracePeriod1',
                        inputType: 'input',
                        helpText: 'Offline grace period(Minutes)'
                    },
                    {
                        label: 'Offline grace period',
                        value: 'offlineGracePeriodAction1',
                        inputType: 'select',
                        helpText: 'Offline grace period action',
                        options: [
                            { label: 'Block access(Minutes)', value: 'blockAccess' },
                            { label: 'Wipe data(Days)', value: 'wipeData' }
                        ]
                    }
                ]
            },
            {
                label: 'Offline grace period(Days)',
                fields: [
                    {
                        label: 'Offline grace period',
                        value: 'offlineGracePeriod2',
                        inputType: 'input',
                        helpText: 'Offline grace period(Days)'
                    },
                    {
                        label: 'Offline grace period',
                        value: 'offlineGracePeriodAction2',
                        inputType: 'select',
                        helpText: 'Offline grace period action',
                        options: [
                            { label: 'Block access(Minutes)', value: 'blockAccess' },
                            { label: 'Wipe data(Days)', value: 'wipeData' }
                        ]
                    }
                ]
            },
            {
                label: 'Minimum app version',
                fields: [
                    {
                        label: 'Minimum app version',
                        value: 'appVersion',
                        inputType: 'input',
                        helpText: 'Minimum app version'
                    },
                    {
                        label: 'Minimum app version',
                        value: 'appVersionAction',
                        inputType: 'select',
                        helpText: 'Minimum app version',
                        options: [
                            { label: 'Block Access', value: 'blockAccess' },
                            { label: 'Notify', value: 'notify' }
                        ]
                    }
                ]
            }
        ]
    },
    {
        isHeader: true,
        label: 'Device conditions',
        helpText: 'OS version should be in format [Major].[Minor] or [Major].[Minor].[Build] or [Major].[Minor].[Build].[Revision]',
        table: [
            {
                label: 'Minimum OS version',
                fields: [
                    {
                        label: 'Minimum OS version',
                        value: 'osVersion',
                        inputType: 'input',
                        helpText: 'Minimum OS version'
                    },
                    {
                        label: 'Minimum OS version',
                        value: 'osVersionAction',
                        inputType: 'select',
                        helpText: 'Minimum OS version',
                        options: [
                            { label: 'Block Access', value: 'blockAccess' },
                            { label: 'Notify', value: 'notify' }
                        ]
                    }
                ]
            },
            {
                label: 'Minimum patch version',
                fields: [
                    {
                        label: 'Minimum patch version',
                        value: 'patchVersion',
                        inputType: 'date',
                        helpText: 'Minimum patch version'
                    },
                    {
                        label: 'Minimum patch version',
                        value: 'patchVersionAction',
                        inputType: 'select',
                        helpText: 'Minimum patch version',
                        options: [
                            { label: 'Block Access', value: 'blockAccess' },
                            { label: 'Notify', value: 'notify' }
                        ]
                    }
                ]
            }
        ]
    }
];

export const networkBinding = [
    {
        label: 'Primary Slot',
        value: 'operator1',
        helpText:
            'Allowing SIM functionality enables seamless management and integration of SIM cards within mobile devices, ensuring connectivity and communication capabilities.',
        options: [
            { label: 'Any', value: 'any' },
            { label: 'None', value: 'none' },
            { label: 'Airtel', value: 'airtel' },
            { label: 'BSNL', value: 'bsnl' },
            { label: 'Jio', value: 'jio' },
            { label: 'VI', value: 'Vodafone IN' }
        ]
    },
    {
        label: 'Secondary Slot',
        value: 'operator2',
        helpText:
            'Allowing SIM functionality enables seamless management and integration of SIM cards within mobile devices, ensuring connectivity and communication capabilities.',
        options: [
            { label: 'Any', value: 'any' },
            { label: 'None', value: 'none' },
            { label: 'Airtel', value: 'airtel' },
            { label: 'BSNL', value: 'bsnl' },
            { label: 'Jio', value: 'jio' },
            { label: 'VI', value: 'Vodafone IN' }
        ]
    }
];
export const simBinding = [
    {
        label: 'Bind First detected SIM',
        customCol: true,
        value: 'first_detected',
        helpText:
            'Selecting this option will automatically pair the first SIM card detected by the device, establishing a seamless connection.'
    },
    {
        label: 'Binding SIM with slot 1',
        customCol: true,
        value: 'slot1',
        helpText:
            'Binding the SIM card with slot 1 means establishing a connection between the SIM card placed in the first slot of the device and the device itself. This ensures that the services associated with the SIM card are accessible only through slot 1.'
    },
    {
        label: 'Binding SIM with slot 2',
        customCol: true,
        value: 'slot2',
        helpText:
            'Binding the SIM card with slot 2 means establishing a connection between the SIM card placed in the second slot of the device and the device itself. This ensures that the services associated with the SIM card are accessible only through slot 2.'
    }
];

export const simPinConfig = [
    {
        label: 'SIM PIN',
        label1: 'SIM 1',
        label2: 'SIM 2',
        helpText:
            'We can choose either SIM 1 or SIM 2, on which a PIN is required. This PIN must be entered each time the device is restarted, or SIM card is inserted into a device.',
        value: 'simPin',
        val1: 'sim1',
        val2: 'sim2'
    },
    {
        label: 'PIN Generation',
        label1: 'Random PIN Generation',
        label2: 'Custom PIN',
        helpText:
            'On selection of Random PIN generation, a random PIN will be generated by the system. On selection of Custom PIN, Admin will have a provision to set a customized PIN. This PIN will be applicable to all the devices to which the configuration is associated.',
        value: 'pinGen',
        val1: 'random',
        val2: 'custom'
    }
];

export const DEFAULT_ALLOWED_DOMAINS = [
    'gvt1.com',
    'gvt2.com',
    'android.apis.google.com',
    'firebaseio.com',
    'android.apis.google',
    'play.google.com',
    'android.com',
    'google-analytics.com',
    'googleusercontent.com',
    'dl.google.com',
    'dl-ssl.google.com',
    'android.clients.google.com',
    'm.google.com',
    'accounts.google.com',
    'accounts.google.in',
    'gcm-http.googleapis.com',
    'gcm-xmpp.googleapis.com',
    'android.googleapis.com',
    'fcm.googleapis.com',
    'fcm-xmpp.googleapis.com',
    'firebaseinstallations.googleapis.com',
    'fcm-xmpp.googleapis.com',
    'gcm-xmpp.googleapis.com',
    'pki.google.com',
    'clients1.google.com',
    'clients2.google.com',
    'clients3.google.com',
    'clients4.google.com',
    'clients5.google.com',
    'clients6.google.com',
    'omahaproxy.appspot.com ',
    'android.clients.google.com',
    'connectivitycheck.android.com',
    'connectivitycheck.gstatic.com',
    'www.google.com',
    'ota.googlezip.net',
    'ota-cache1.googlezip.net',
    'ota-cache2.googlezip.net',
    'mtalk.google.com',
    'mtalk4.google.com',
    'mtalk-staging.google.com',
    'mtalk-dev.google.com',
    'alt1-mtalk.google.com',
    'alt2-mtalk.google.com',
    'alt3-mtalk.google.com',
    'alt4-mtalk.google.com',
    'alt5-mtalk.google.com',
    'alt6-mtalk.google.com',
    'alt7-mtalk.google.com',
    'alt8-mtalk.google.com',
    'android.clients.google.com',
    'device-provisioning.googleapis.com',
    'accounts.youtube.com',
    'crl.pki.goog',
    'ocsp.pki.goog',
    'apis.google.com',
    'ajax.googleapis.com',
    'clients1.google.com',
    'payments.google.com',
    'ogs.google.com',
    'notifications.google.com',
    'enterprise.google.com',
    '*gstatic.com',
    '*.gstatic.com',
    '*.gvt1.com',
    '*.ggpht.com',
    '*.gvt2.com',
    '*.gvt3.com',
    '*.googleapis.com'
];

export const ABMenrollObj = [
    {
        label: 'Accessibility',
        value: 'accessibility',
        inputType: 'checkbox',
        helpText: 'The key to skip the Accessibility pane. This key is not available in macOS.',
        fieldClass: 'fw-medium',
        badgeText: ['MacOS'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'ActionButton',
        value: 'actionButton',
        inputType: 'checkbox',
        helpText: 'The key to skip the Action Button configuration pane. This key is available in iOS 17 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'Android',
        value: 'android',
        inputType: 'checkbox',
        helpText:
            'If the Restore pane isn’t skipped, this is the key to remove the Move from Android option in the Restore pane on iOS. This key is available in iOS 9 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'Appearance',
        value: 'appearance',
        inputType: 'checkbox',
        helpText: 'The key to skip the Choose Your Look screen. This key is available in iOS 13+ and macOS 10.14 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad', 'MacOS'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'AppleID',
        value: 'appleID',
        inputType: 'checkbox',
        helpText: 'The key to skip Apple ID setup. This key is available in iOS 7.0+, tvOS 10.2 and later, and macOS 10.9 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad', 'tvOS', 'MacOS'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'AppStore',
        value: 'appStore',
        inputType: 'checkbox',
        helpText: 'The key to skip the App Store pane. This key is available in iOS 14.3 and later, and macOS 11.1 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad', 'MacOS'],
        badgeClass: 'secondary',
        badge: true
    },
    {
        label: 'Biometric',
        value: 'biometric',
        inputType: 'checkbox',
        helpText: 'The key to skip biometric setup. This key is available in iOS 8.1 and later, and macOS 10.12.4 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad', 'MacOS'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'DeviceToDeviceMigration',
        value: 'dtodMirgrate',
        inputType: 'checkbox',
        helpText: 'The key to skip Device to Device Migration pane. This key is available in iOS 13 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad'],
        badge: true,
        badgeClass: 'secondary'
    },
    {
        label: 'Diagnostics',
        value: 'diagnostics',
        inputType: 'checkbox',
        helpText:
            'The key to skip the App Analytics pane. This key is available in iOS 7 and later, tvOS 10.2 and later, and macOS 10.9 and later.',
        fieldClass: 'fw-medium',
        badgeText: ['iPhone', 'iPad', 'tvOS', 'MacOS'],
        badge: true,
        badgeClass: 'secondary'
    }
];

const SELECT_APPS = [
    { label: 'WIFI', value: 'wifi' },
    { label: 'CHROME', value: 'chrome' },
    { label: 'OUTLOOK', value: 'outlook' },
    { label: 'SLACK', value: 'slack' },
    { label: 'ANY DESK', value: 'anyDesk' },
    { label: 'MESSAGES', value: 'messages' }
];

export const APP_FOLDER_SCHEMA = [
    {
        label: 'Enable folder',
        value: 'enableFolder',
        helpText: 'This feature enables admin to group the applications in one folder . Admin can aslo create multiple folders.',
        inputType: 'toggle',
        alert: true
    },
    {
        label: 'Display Image',
        value: 'displayImage',
        showOn: ['enableFolder'],
        inputType: 'folder',
        fields: [
            {
                label: 'Folder Name',
                helpText: 'Create a folder name, which will resemble the set of aplications added in the folder',
                value: 'folderName',
                inputType: 'input'
            },
            {
                label: 'Apps',
                helpText: 'Admin can select apps from  the app repository from the below dropdown',
                value: 'apps',
                inputType: 'select',
                customCol: true,
                options: SELECT_APPS
            }
        ]
    }
];

export const WIFISCHEMA = [
    {
        label: 'Wi-Fi Name',
        value: 'wifiName',
        helpText: 'Enter the name of wi-fi',
        inputType: 'input',
        type: 'text'
    },
    {
        label: 'Service Set Identifier (SSID)',
        value: 'ssid',
        helpText: 'Enter the SSID',
        inputType: 'input',
        type: 'text'
    },
    {
        label: 'Security',
        value: 'security',
        helpText: 'Select the security protocols used in wifi network to secure data transmissions',
        inputType: 'select',
        options: [
            { label: 'No Action', value: 'noAction' },
            { label: 'Choose Policy', value: 'choosePolicy' },
            { label: 'Disable Device', value: 'disableDevice' }
        ]
    }
];

export const GEOFENCE_ACTIONS = [
    {
        label: 'Geo-Fence In',
        value: 'geoFenceIn',
        helpText: 'Specify actions that occur when the device is outside the defined geographical area.',
        inputType: 'select',
        mandatory: true,
        options: [
            { label: 'No Action', value: 'NO_ACTION' },
            { label: 'Choose Policy', value: 'PolicySwitch' },
            { label: 'Disable Device', value: 'DISABLED' }
        ]
    },
    {
        label: 'Geo-Fence Out',
        value: 'geoFenceOut',
        helpText: 'Specify actions that occur when the device is outside the defined geographical area.',
        inputType: 'select',
        mandatory: true,
        options: [
            { label: 'No Action', value: 'NO_ACTION' },
            { label: 'Choose Policy', value: 'PolicySwitch' },
            { label: 'Disable Device', value: 'DISABLED' }
        ]
    }
];

export const WIFIFENCE_ACTIONS = [
    {
        label: 'Within SSID Network',
        helpText: 'Specify actions that occur when the device is within the defined Wi-fi network',
        value: 'withinSSID',
        inputType: 'select',
        options: [
            { label: 'No Action', value: 'noAction' },
            { label: 'Choose Policy', value: 'choosePolicy' },
            { label: 'Disable Device', value: 'disableDevice' }
        ]
    },
    {
        label: 'Outside SSID Network',
        value: 'outsideSSID',
        helpText: 'Specify actions that occur when the device is outside the defined Wi-fi network',
        inputType: 'select',
        options: [
            { label: 'No Action', value: 'noAction' },
            { label: 'Choose Policy', value: 'choosePolicy' },
            { label: 'Disable Device', value: 'disableDevice' }
        ]
    }
];

export const TIMEFENCE_CONFIGURATION = [
    {
        label: 'Time Slots',
        value: 'timeSlots',
        helpText: 'Select the time slots for which time fence should be enabled',
        inputType: 'time',
        mandatory: true,
        tableContent: [
            {
                label: 'Start time',
                value: 'startTime',
                type: 'time',
                icon: 'ri-time-line',
                options: {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h K'
                }
            },
            {
                label: 'End Time',
                value: 'endTime',
                type: 'time',
                icon: 'ri-time-line',
                options: {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h K'
                }
            }
        ]
    }
];

export const calllogReport = [
    { label: 'StartDate', accessor: 'startDate' },
    { label: 'End Date', accessor: 'endDate' },
    { label: 'Report Time', accessor: 'reportGenerateTime' },
    { label: 'Repeat', accessor: 'repeat' },
    { label: 'Frequency', accessor: 'frequency' }
];

export const AndroidPolicyQR = [
    {
        label: 'Advance Security Options',
        inputType: 'radio',
        value: 'securityOptions',
        radios: [
            {
                label: 'Enrollment Without Login',
                val: 'withoutLogin',
                toolTip: 'Upon enabling this option, user can enrol the device without any authentication.',
                value: 'securityOptions'
            },
            {
                label: 'Login with Authentication Modes',
                val: 'withAuthentic',
                toolTip:
                    'Upon enabling this option, the end user will be able to login with all the possible login options as defined in the authentication mode.',
                value: 'securityOptions'
            },
            {
                label: 'Login with Static Pin',
                val: 'withStatic',
                toolTip:
                    'A static PIN is a fixed numerical code used for secure authentication. During enrolment, the same PIN is used to verify user identity during authentication.',
                value: 'securityOptions'
            }
        ]
    },
    {
        inputType: 'input',
        maxLength: 4,
        showKey: 'securityOptions',
        showVal: 'withStatic',
        placeHolder: 'Static pin',
        placeholder: 'Custom PIN',
        value: 'pin',
        showOn: ['withStatic']
    }
];
