/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import { IsAuthorized, TenantConfig } from '../../Components/Common/Util';
import { tenantConfig } from '../../Components/constants/TenantConfig';
import { priviliges } from '../../Components/constants/constants';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';

const Tenant = () => {
    document.title = 'My Tenant';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();
    const [configs, setConfigs] = useState([]);
    let tectoroTenant = tenantConfig.TECTORO;
    let tenant = TenantConfig();
    let tenantCode = localStorage.getItem('account');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTenantConfigs({ page: 1, size: 10 });
    }, []);

    const getTenantConfigs = () => {
        setLoading(true);
        api.get(url.TENANTS + '/bycode/' + tenantCode + '/config/BRANDING', '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    setConfigs([resp.data]);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleEditConfig = () => {
        history.push(`/mytenant/edit/${tenantCode}`);
    };

    const handleViewConfig = () => {
        history.push(`/mytenant/view/${tenantCode}`);
    };

    return (
        <>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Account" history={history} homeLink="Dashboard" />
                    {(configs || []).map((item, index) => (
                        <React.Fragment key={index}>
                            <Col xxl={3} sm={6} className="project-card">
                                <Card className="card-height-100 shadow border">
                                    <CardBody>
                                        <div className="d-flex flex-column h-100">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 w-100">
                                                    <div className="d-flex gap-1 align-items-center justify-content-end">
                                                        <UncontrolledDropdown direction="start">
                                                            <DropdownToggle
                                                                tag="button"
                                                                className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                                            >
                                                                <i className="ri-more-fill" />
                                                            </DropdownToggle>

                                                            <DropdownMenu className="dropdown-menu-end">
                                                                <IsAuthorized
                                                                    privRequired={priviliges.TENANT_READER}
                                                                    yes={() => (
                                                                        <DropdownItem onClick={() => handleViewConfig({ original: item })}>
                                                                            <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{' '}
                                                                            View
                                                                        </DropdownItem>
                                                                    )}
                                                                />
                                                                <IsAuthorized
                                                                    privRequired={priviliges.TENANT_EDITOR}
                                                                    yes={() => (
                                                                        <DropdownItem onClick={() => handleEditConfig({ original: item })}>
                                                                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '}
                                                                            Edit
                                                                        </DropdownItem>
                                                                    )}
                                                                />
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-sm">
                                                        <span className={'avatar-title rounded p-2 bg-light'}>
                                                            <img alt="" src={tectoroTenant.COMPANY_LOGO} className="img-fluid p-1" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="mb-1 fs-15">
                                                        <div className="text-dark">{item.name}</div>
                                                    </h5>
                                                    <p className="text-muted text-truncate-two-lines mb-3">{tenantCode}</p>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex align-items-center">
                                                {tenant.COMPANY_POLICY_ACCESS?.length > 0 && (
                                                    <div className="text-primary fw-medium">Policy Types :</div>
                                                )}
                                                {tenant.COMPANY_POLICY_ACCESS?.length > 0 &&
                                                    tenant.COMPANY_POLICY_ACCESS.map((ele, ind) => {
                                                        return (
                                                            <div className="flex-shrink-0 ps-1" key={ind}>
                                                                <div className="fs-12 bg-soft-primary border px-2 py-1 rounded-2 m-0 text-primary">
                                                                    {ele}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div> */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment>
                    ))}
                </Container>
            </div>
        </>
    );
};

export default Tenant;
