/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, TenantConfig, getDate } from '../../../Components/Common/Util';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { messagesSchema } from '../TableSchema';
import AddMessage from './AddMessage';
// import AnnouncmentStatusChart from './AnnouncmentStatusChart';
import { priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import * as domain from '../../../helpers/domain_helper';
import AnnouncmentStatusChart from './AnnouncmentStatusChart';

const Messages = () => {
    document.title = 'Messages';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();
    let tenantConfig = TenantConfig();
    let tenantObj = tenantConfig ? JSON.parse(JSON.stringify(tenantConfig)) : '';

    const [loading, setLoading] = useState(false);
    const [viewLoading, setViewLoading] = useState(false);
    const [mode, setMode] = useState();
    const [messages, setMessages] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [offcanvasStatusModal, setOffcanvasStatusModal] = useState(false);
    const [currentDetailsObj, setCurrentDetailsObj] = useState();
    const [deviceGroups, setDeviceGroups] = useState([]);
    const [announcmentStatusData, setAnnouncmentStatusData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [flatGroups, setFlatGroups] = useState([]);
    const [announcementsSchema, setAnnouncementsSchema] = useState([]);
    const [selectedTab, setSelectedTab] = useState('messages');
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [recordDetails, setRecordDetails] = useState({});
    const [pageNum, setPageNum] = useState(1);
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let findPriv = user?.data?.privileges?.includes(priviliges.MESSAGE_EDITOR);

    useEffect(() => {
        setLoading(true);
        let schemaObj = [...messagesSchema];
        if (!tenantObj.ENABLE_SCHEDULE_MESSAGE) schemaObj = schemaObj.filter((schema) => schema.Header !== 'Message Type');
        schemaObj.forEach((schema) => {
            if (schema.Header === 'Actions') {
                schema.recall = tenantObj.ENABLE_RECALL;
                schema.editFadableConditionally = tenantObj.ENABLE_SCHEDULE_MESSAGE && findPriv;
            }
        });
        const actions = schemaObj.find((schema) => schema.Header === 'Actions');
        if (!actions.editFadableConditionally && !actions.recall) schemaObj = schemaObj.filter((schema) => schema.Header !== 'Actions');
        setAnnouncementsSchema(schemaObj);
        promiseHandler();
    }, []);

    const promiseHandler = () => {
        const deviceGroupPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100000 };
            api.get(url.DEVICE_GROUPS, params, domain.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Group failed');
                })
                .catch((_err) => reject('Groups failed'));
        });

        const messagePromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 10 };
            api.get(url.MESSAGES, params, domain.MESSAGES_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('Message failed');
                })
                .catch((_err) => reject('Message failed'));
        });

        Promise.allSettled([deviceGroupPromise, messagePromise])
            .then((result) => {
                let groupsData = [];
                if (result?.[0]?.status === 'fulfilled') {
                    result[0]?.value?.forEach((group) => {
                        group.label = group.name;
                        group.key = group.code;
                        group.nameCode = group.name + ' - ' + group.code;
                        groupsData.push(group);
                        if (group.children) {
                            let obj = setChildGroups(group.children, groupsData);
                            group.children = obj.children;
                            groupsData = [...obj.groupsData];
                        }
                    });
                    setFlatGroups(groupsData);
                    setDeviceGroups(result[0]?.value);
                }
                if (result?.[1]?.status === 'fulfilled') {
                    handleMessageBinding(result?.[1]?.value, groupsData);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    useEffect(() => {
        if (!offcanvasModal) document.title = 'Messages';
    }, [offcanvasModal]);

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.id);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setChildGroups = (groupArr, groupsData) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        return { children: groupArr, groupsData: groupsData };
    };

    const handleMessageBinding = (resp, groups) => {
        (resp.data || [])?.forEach((ele) => {
            if (ele.receivertype === 'POLICY') {
                ele.receiverType = 'Policy';
            } else if (ele.receivertype === 'DEVICE') {
                ele.receiverType = 'Device';
            } else if (ele.receivertype === 'GROUP') {
                ele.receiverType = 'Device Group';
                groups.forEach((dg) => {
                    if (ele.receiver === dg.value) ele.receiver = dg.label;
                });
            } else {
                ele.receiverType = 'All Devices';
            }
            ele.receiverserials =
                ele.receiver?.[0] === '*'
                    ? 'All'
                    : ele.receiver_serial?.length > 0
                    ? ele.receiver_serial?.join(', ')
                    : ele.receiver?.join(', ');
            if (ele.status?.toLowerCase() === 'sent') ele.badgeClass = 'success';
            else if (ele.status?.toLowerCase() === 'recalled') ele.badgeClass = 'danger';
            else ele.badgeClass = 'primary';
            ele.type = ele.deliverytype?.charAt(0)?.toUpperCase() + ele.deliverytype?.substr(1)?.toLowerCase();
            let scheduledDate = new Date(ele.scheduledtime);
            let currentDate = new Date();
            let dateComparision = scheduledDate.getTime() < currentDate.getTime();
            ele.rowDelete = ele.deliverytype?.toLowerCase() === 'scheduled' ? dateComparision : true;
            ele.rowEdit = ele.deliverytype?.toLowerCase() === 'scheduled' ? dateComparision : true;
            ele.deleteTooltip =
                ele.deliverytype?.toLowerCase() === 'scheduled'
                    ? dateComparision
                        ? 'You cannot delete this message as the scheduled time is reached.'
                        : 'Delete'
                    : 'You cannot delete this message as the message is already sent.';
            ele.editTooltip =
                ele.deliverytype?.toLowerCase() === 'scheduled'
                    ? dateComparision
                        ? 'You cannot edit this message as the scheduled time is reached.'
                        : 'Edit'
                    : 'You cannot edit this message as the message is already sent.';
            ele.modifiedBy = ele.modifiedby ? ele.modifiedby : 'Tectoro';
            ele.modifiedDate = ele.modifieddate ? getDate(ele.modifieddate) : '—';
            ele.editFadableConditionally = tenantObj.ENABLE_SCHEDULE_MESSAGE && findPriv;
            ele.recall = ele.status?.toUpperCase() !== 'RECALLED' && findPriv ? true : false;
            ele.receiver =
                ele.receiver?.[0] === '*'
                    ? ele.receiverType === 'Device Group'
                        ? 'All Groups'
                        : ele.receiverType === 'Policy'
                        ? 'All Policies'
                        : 'All Devices'
                    : ele.receiver
                    ? ele.receiver?.join()
                    : '—';
        });
        setTotalRecords(resp.totalRecords);
        setMessages(resp.data || []);
    };

    const getMessages = (deviceGroupArr, filters) => {
        setLoading(true);
        setPageNum(filters.page);
        let params = { ...filters };
        api.get(url.MESSAGES, params, domain.MESSAGES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    handleMessageBinding(resp, deviceGroupArr);
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleAddNew = (row) => {
        setMode('add');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };

    const handleClickView = (row) => {
        setMode('view');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };

    const handleEdit = (row) => {
        setMode('edit');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleClickViewDetails = (row) => {
        setViewLoading(true);
        setOffcanvasStatusModal(true);
        api.get(url.MESSAGES + '/' + row.original.id + '/metrics', '', domain.MESSAGES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setAnnouncmentStatusData(resp.data);
                    setViewLoading(false);
                }
            })
            .catch((err) => {
                setViewLoading(false);
            });
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.MESSAGES + '/' + selectedRow.original.id, '', domain.MESSAGES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.announcementDeleted);
                    getMessages(deviceGroups, { ...searchParams, page: 1, size: 10 });
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleRecall = (row) => {
        let obj = { status: 'RECALL' };
        setLoading(true);
        setDeleteModal(false);
        api.patch(url.MESSAGES + '/' + row.original?._id, obj, false, domain.MESSAGES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.announcementRecalled);
                    getMessages(deviceGroups, { ...searchParams, page: 1, size: 10 });
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const toggle = useCallback(async () => {
        setOffcanvasModal(!offcanvasModal);
    }, [offcanvasModal]);

    const statusToggle = useCallback(async () => {
        setOffcanvasStatusModal(!offcanvasStatusModal);
    }, [offcanvasStatusModal]);

    const saveFormData = (detailsObj) => {
        setCurrentDetailsObj({ ...detailsObj });
    };

    const handleSubmit = () => {
        if (saveDisabled()) {
            toast.error(toastMessages.fillAllMandatoryFields);
            return;
        }
        if (currentDetailsObj?.title?.length < 4) {
            toast.error(toastMessages.minLength4);
            return;
        }
        if (currentDetailsObj?.messageType?.toLowerCase() === 'scheduled' && new Date(currentDetailsObj.time) < new Date()) {
            toast.error(toastMessages.timeValidation);
            return;
        }
        if (currentDetailsObj?.files?.length) {
            let fileSize = 0;
            for (let i = 0; i < currentDetailsObj?.files?.length; i++) {
                fileSize = fileSize + currentDetailsObj?.files[i].fileSize;
            }
            if (fileSize > 30000000) {
                toast.error(toastMessages.filesSizeGT30);
                return;
            }
        }
        let dataobj = {
            receivertype: currentDetailsObj?.sendTo?.value,
            deliverytype: currentDetailsObj?.messageType,
            scheduledtime: currentDetailsObj?.messageType === 'SCHEDULED' ? currentDetailsObj?.scheduledTime : undefined,
            receiver:
                currentDetailsObj?.sendType?.toUpperCase() === 'ALL'
                    ? ['*']
                    : currentDetailsObj?.sendTo?.value === 'DEVICE'
                    ? currentDetailsObj?.senderInfo.map((info) => info.id)
                    : currentDetailsObj?.senderInfo?.map((info) => info.value),
            files: currentDetailsObj.files
        };
        if (currentDetailsObj?.type?.value === 'ANNOUNCEMENT')
            dataobj = {
                ...dataobj,
                message: currentDetailsObj.messageWithStyle
                    ? currentDetailsObj.messageWithStyle
                    : '<div>' + currentDetailsObj?.message + '</div>',
                title: currentDetailsObj.title
            };
        else {
            dataobj = {
                ...dataobj,
                message: '',
                title: currentDetailsObj?.question,
                answer: currentDetailsObj?.answer,
                format: currentDetailsObj?.format?.key
            };
            if (currentDetailsObj.format?.label === 'Text') dataobj = { ...dataobj, message: currentDetailsObj?.messageWithStyle };
        }
        if (mode === 'edit') dataobj.id = selectedRow?.original?.id;
        createNdUpdateData(dataobj, mode);
    };

    const prepareChangeArrayDetails = (field, current, copy) => {
        let filteredData = [];
        let iterableData = [];
        let checkField = field === 'files' ? 'serverFilename' : undefined;
        let found = false;
        if (current.length > copy.length) {
            filteredData = copy.length !== 0 ? copy?.map((item) => item[checkField]) : [];
            iterableData = current;
        } else {
            filteredData = current.length !== 0 ? current?.map((item) => item[checkField]) : [];
            iterableData = copy;
        }

        if (iterableData.length > 0) {
            iterableData.forEach((item) => {
                if (!filteredData.includes(checkField ? item[checkField] : item)) {
                    found = true;
                    return;
                }
            });
        }
        return found;
    };

    const createNdUpdateData = (params, modeType) => {
        let apiService = '';
        setLoading(true);
        if (modeType === 'edit') {
            let obj = {
                receivertype: currentDetailsObj?.sendTo?.value,
                title: currentDetailsObj.title === recordDetails.title ? undefined : currentDetailsObj.title,
                message: currentDetailsObj.messageWithStyle
                    ? currentDetailsObj.messageWithStyle
                    : '<div>' + currentDetailsObj?.message + '</div>',
                scheduledtime:
                    currentDetailsObj?.messageType === 'SCHEDULED' && currentDetailsObj?.scheduledTime === recordDetails?.scheduledTime
                        ? undefined
                        : currentDetailsObj.scheduleTime,
                type: currentDetailsObj?.messageType === recordDetails?.messageType ? undefined : currentDetailsObj?.messageType,
                files: prepareChangeArrayDetails('files', currentDetailsObj?.files, recordDetails?.files)
                    ? currentDetailsObj.files
                    : undefined,
                receiver:
                    currentDetailsObj?.sendType?.toUpperCase() === 'ALL'
                        ? ['*']
                        : currentDetailsObj?.sendTo?.value === 'DEVICE'
                        ? currentDetailsObj?.senderInfo.map((info) => info.id)
                        : currentDetailsObj?.senderInfo?.map((info) => (info.value ? info.value : info)),
                deliverytype: currentDetailsObj?.messageType === recordDetails?.messageType ? undefined : currentDetailsObj?.messageType
            };
            obj = JSON.parse(JSON.stringify(obj));
            apiService = api.patch(url.MESSAGES + '/' + selectedRow?.original?.id, obj, false, domain.MESSAGES_V1);
        } else apiService = api.create(url.MESSAGES, params, false, domain.MESSAGES_V1);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(modeType === 'edit' ? toastMessages.announcementUpdated : toastMessages.announcementCreated);
                    setCurrentDetailsObj({});
                    getMessages(deviceGroups, { ...searchParams, page: 1, size: 10 });
                    toggle();
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleOffcanvasBody = () => {
        return (
            <div className="position-reltive">
                <AddMessage
                    mode={mode}
                    toggle={toggle}
                    groupsData={flatGroups}
                    setRecordDetails={setRecordDetails}
                    setLoading={setLoading}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    saveFormData={saveFormData}
                    deviceGroups={deviceGroups}
                    recordID={selectedRow?.original?._id}
                />
            </div>
        );
    };

    const saveDisabled = () => {
        return currentDetailsObj?.type?.value === 'ANNOUNCEMENT'
            ? currentDetailsObj?.title?.length < 4 ||
                  !currentDetailsObj?.sendTo ||
                  (currentDetailsObj?.sendTo.value &&
                      (currentDetailsObj?.sendType?.toUpperCase() === 'ALL' ? false : currentDetailsObj?.senderInfo?.length === 0)) ||
                  !currentDetailsObj?.title ||
                  !(currentDetailsObj?.messageWithStyle || currentDetailsObj?.message) ||
                  (currentDetailsObj?.messageType === 'SCHEDULED' ? !currentDetailsObj?.scheduledTime : false)
            : currentDetailsObj?.title?.length < 4 ||
                  !currentDetailsObj?.sendTo ||
                  (currentDetailsObj?.sendTo.value &&
                      (currentDetailsObj?.sendType?.toUpperCase() === 'ALL' ? false : !currentDetailsObj?.senderInfo)) ||
                  !currentDetailsObj?.format?.value ||
                  (currentDetailsObj?.format?.value === 'txt'
                      ? !currentDetailsObj?.messageWithStyle || currentDetailsObj?.messageWithStyle === ''
                      : currentDetailsObj?.files?.length === 0) ||
                  !currentDetailsObj?.question ||
                  currentDetailsObj?.question === '' ||
                  !currentDetailsObj?.answer ||
                  currentDetailsObj?.answer === '';
    };

    const handleOffcanvasStatusBody = () => {
        return (
            <div>
                <Card className="border shadow-none">
                    <CardHeader className="align-items-center d-flex">
                        <span className="fs-14 mb-0 flex-grow-1">Announcement Status</span>
                    </CardHeader>
                    <CardBody className="p-2">
                        <Row>
                            <Col sm={8}>
                                <AnnouncmentStatusChart
                                    series={[announcmentStatusData?.read, announcmentStatusData?.delivered]}
                                    dataColors='["--EMM-success", "--EMM-danger"]'
                                />
                            </Col>
                            <Col sm={4} className="d-flex flex-column justify-content-center">
                                <p className="fw-medium mb-0">
                                    <i className="ri-checkbox-blank-circle-fill text-success align-middle me-2"></i> Read
                                    <span className="text-info align-middle ms-2">{announcmentStatusData?.read}</span>
                                </p>
                                <p className="fw-medium mb-0">
                                    <i className="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i> Unread
                                    <span className="text-info align-middle ms-2">{announcmentStatusData?.delivered}</span>
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                    {/* <TableContainer
                        hideDisplayTotalCount={true}
                        searchFilter2={true}
                        isGlobalFilter={true}
                        data={announcmentStatusData || []}
                        columns={viewMessagesStatusSchema}
                        reader="ANNOUNCEMENT_READER"
                        editor="ANNOUNCEMENT_EDITOR"
                        searchPlaceHolder="Serial"
                        tableHeader={'List of Devices'}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    /> */}
                </Card>
            </div>
        );
    };

    const handleGlobalSearch = (val) => {
        let params = { ...searchParams, page: 1, title: val?.trim() };
        setSearchParams(params);
        getMessages(deviceGroups, params);
    };

    const handlePagination = (page) => {
        setSearchParams({ ...searchParams, page: page });
        getMessages(deviceGroups, { ...searchParams, page: page });
    };

    /*
     * const handleAppsTabContent = (key) => {
     *     return key === 'messages' ? (
     *         <TableContainer
     *             searchFilter2={true}
     *             isGlobalFilter={true}
     *             handleGlobalSearch={handleGlobalSearch}
     *             addButton={true}
     *             loading={loading}
     *             totalRecords={totalRecords}
     *             columns={announcementsSchema}
     *             handleDelete={handleDelete}
     *             handleEdit={handleEdit}
     *             onPageChange={handlePagination}
     *             handleAddNew={handleAddNew}
     *             handleClickView={handleClickView}
     *             handleClickViewDetails={handleClickViewDetails}
     *             data={messages}
     *             editor="ANNOUNCEMENT_EDITOR"
     *             reader="ANNOUNCEMENT_READER"
     *             searchPlaceHolder="Title"
     *             className="custom-header-css"
     *             divClass="table-responsive table-card"
     *             tableClass="table table-nowrap table-border table-centered align-middle"
     *             theadClass="bg-light text-muted"
     *         />
     *     ) : (
     *         <></>
     *         // <UserSettings setLoading={setLoading} api={api} url={url} toast={toast} />
     *     );
     * };
     */

    /*
     * const tabsList = [
     *     { tabID: 'messages', tabName: 'All Messages', tabContent: handleAppsTabContent('messages') }
     *     // { tabID: 'user_settings', tabName: 'User Settings', tabContent: handleAppsTabContent('user_settings') }
     * ];
     */

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Messages" history={history} homeLink="Dashboard" />
                    {/* <TabsComponent tabsList={tabsList} defaultTabID={'messages'} toggleTab={(tab) => setSelectedTab(tab)} /> */}
                    <TableContainer
                        tableHeader={'All Messages'}
                        searchFilter2={true}
                        isGlobalFilter={true}
                        handleGlobalSearch={handleGlobalSearch}
                        addButton={true}
                        loading={loading}
                        totalRecords={totalRecords}
                        columns={announcementsSchema}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        onPageChange={handlePagination}
                        handleAddNew={handleAddNew}
                        handleRecall={handleRecall}
                        pageNum={pageNum}
                        handleClickView={handleClickView}
                        handleClickViewDetails={handleClickViewDetails}
                        data={messages}
                        editor={priviliges.MESSAGE_EDITOR}
                        reader={priviliges.MESSAGE_READER}
                        searchPlaceHolder="Title"
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        deleteMessage={'Are you sure you want to remove this message ?'}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={offcanvasModal}
                        hideSaveButton={mode === 'view'}
                        handleCloseClick={toggle}
                        OffcanvasModalID="announcementModal"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName="w-40"
                        saveText={currentDetailsObj?.messageType === 'SCHEDULED' ? 'Schedule send' : 'Send now'}
                        offcanvasHeader={mode === 'add' ? 'Add New Message' : `${mode?.charAt(0)?.toUpperCase() + mode?.slice(1)} Message`}
                        saveDisabled={saveDisabled()}
                        loading={loading}
                        handleSaveClick={handleSubmit}
                    />
                    <OffcanvasModal
                        direction="end"
                        modalClassName="w-40"
                        OffcanvasModalID="announcementStstusModal"
                        offcanvasHeader="View Message Status"
                        hideFooterButtons={true}
                        toggle={statusToggle}
                        open={offcanvasStatusModal}
                        handleCloseClick={statusToggle}
                        loading={viewLoading}
                        handleOffcanvasBody={handleOffcanvasStatusBody}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Messages;
