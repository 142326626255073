import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import SearchOption from '../../Components/Common/SearchOption';
import TableContainer from '../../Components/Common/TableContainer';
import { getFormTypeAndRecordId } from '../../Components/Common/Util';
import { freeTrailPlan, purchasePlan } from '../../Components/constants/constants';
import FreeImage from '../../assets/images/licenses/freeImage.png';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import { DevicesInfo, LicenseInfo, details, tableData } from './TableSchema';

const PurchaseLicense = () => {
    const [isSubmit, setIssubmit] = useState(false);
    const history = useHistory();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    let api = new APIClient();
    const [packageName, setPackageName] = useState('');
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];

    let recordID = formTypeAndId['recordID'];
    document.title = formType === 'view' ? 'License View' : formType === 'add' ? 'License Add' : 'Purchase License ';

    const [selectedStartDate, setSelectedStartDate] = useState('Select License Start Date');
    const [selectEndDate, setSelectEndDate] = useState('Enter License End Date');
    const handleDateChange = (date) => {
        setSelectedStartDate(date[0]);
    };
    const handleEndDateChange = (date) => {
        setSelectEndDate(date[0]);
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { devices: '', startDate: '', endDate: '' },
        validationSchema: Yup.object({
            devices: Yup.string(),
            startDate: Yup.string(),
            endDate: Yup.string()
        }),
        onSubmit: (values) => {}
    });
    const handleClick = () => {
        validation.handleSubmit();
        setIssubmit(true);
    };
    useEffect(() => {
        getLauncherPackage();
    });
    const getLauncherPackage = () => {
        setLoading(false);
        api.get(url.APPLICATIONS + '/LAUNCHER')
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    setPackageName(resp?.data?.[0]?.packageName);
                    if (recordID) getConfig(resp?.data?.[0]?.packageName);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };
    const getConfig = () => {
        setLoading(true);
    };
    const addLicense = () => {
        history.push('/license/add');
    };
    const handleClickView = (row) => {
        history.push('/license/view');
    };
    return (
        <React.Fragment>
            <div className="page-content">
                {!isSubmit ? (
                    <Container fluid>
                        <BreadCrumb
                            title={formType === 'view' ? 'License View' : formType === 'add' ? 'License Add' : 'Purchase License'}
                            pageTitle={formType === 'view' ? 'License View' : formType === 'add' ? 'License Add' : 'Purchase License'}
                        />
                        <Row>
                            <Col className="p-3">
                                <div className="text-center">
                                    <div className="fs-18 p-1 fw-semibold">Choose the plan that's right for you</div>
                                    <div className="fs-12 text-muted">
                                        Your free trail has <b className="text-danger fw-semibold fs-13">Expired</b>, you can buy a plan to
                                        access and continue enjoying the awesome features
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="margin-top-20 justify-content-around align-items-center">
                            <Col className="col-5">
                                <Row>
                                    <Col className="col-6 ">
                                        <Card>
                                            <CardHeader className="border-none">
                                                <div className="text-center text-info fs-16 fw-bold d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <img className="w-40" src={FreeImage} alt="freeImage"></img>
                                                    </div>
                                                    <div>Free Trail Plan</div>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="fw-semibold text-center fs-14 p-1">All Features Included</div>
                                                {freeTrailPlan.map((ele, ind) => {
                                                    return (
                                                        <div className="fs-12 py-1" key={ind}>
                                                            <i className="ri-checkbox-circle-fill text-primary px-1"></i>
                                                            {ele}
                                                        </div>
                                                    );
                                                })}
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col className="col-6 px-0">
                                        <Card className="p-0">
                                            <CardHeader className="border-none">
                                                <div className="text-center text-info fs-16 fw-bold d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <img className="w-40" src={FreeImage} alt="freeImage"></img>
                                                    </div>
                                                    <div>Purchase Plan</div>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="fw-semibold text-center fs-14 p-1">All Features Included</div>
                                                {purchasePlan.map((ele, ind) => {
                                                    return (
                                                        <div className="fs-12 py-1" key={ind}>
                                                            <i className="ri-checkbox-circle-fill text-primary px-1"></i>
                                                            {ele}
                                                        </div>
                                                    );
                                                })}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-5 p-5">
                                <div>
                                    <div className="py-3">
                                        <Label htmlFor="devices">Device Count</Label>
                                        <Input
                                            type="text"
                                            name="devices"
                                            id="devices"
                                            placeholder="Enter Device Count"
                                            value={validation.values.devices}
                                        ></Input>
                                    </div>
                                    <div className="py-3">
                                        <Label for="startdate">License Start Date</Label>
                                        <Flatpickr
                                            id="startdate"
                                            className="form-control"
                                            placeholder={selectedStartDate}
                                            value={selectedStartDate}
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                    <div className="py-3">
                                        <Label for="enddate">License End Date</Label>
                                        <Flatpickr
                                            id="enddate"
                                            className="form-control"
                                            placeholder={selectEndDate}
                                            value={selectEndDate}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="px-1">
                                            <Button className="bg-primary text-white border-none px-5" onClick={handleClick}>
                                                Submit
                                            </Button>
                                        </div>
                                        <div className="px-1">
                                            <Button className="bg-light text-primary border-primary">Save as Draft</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container fluid>
                        <BreadCrumb title="License Management" pageTitle="License" />
                        <Row>
                            <Col className="col-6">
                                <Card>
                                    <Row>
                                        {LicenseInfo.map((ele, index) => (
                                            <Col
                                                key={index}
                                                className={`text-center p-5 ${index !== LicenseInfo.length - 1 ? 'border-end' : ''} `}
                                            >
                                                <div className="text-muted fw-medium fs-14">{ele.title}</div>
                                                <div className="fw-medium py-1 fw-semibold fs-16">{ele.value}</div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                            </Col>
                            <Col className="col-6">
                                <Card>
                                    <Row>
                                        {DevicesInfo.map((ele, index) => (
                                            <Col
                                                key={index}
                                                className={`text-center p-5 ${index !== DevicesInfo.length - 1 ? 'border-end' : ''}`}
                                            >
                                                <div className="text-muted d-flex justify-content-center align-items-center fw-medium fs-14">
                                                    {ele.icon}
                                                    {ele.title}
                                                </div>
                                                <div className="fw-medium py-1 fw-semibold fs-16">{ele.value}</div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <Row>
                                <div className="d-flex justify-content-between align-items-center px-5">
                                    <div className="col-3">
                                        <SearchOption />
                                    </div>
                                    <div>
                                        <Button className="bg-primary" onClick={addLicense}>
                                            + Add License
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                        </Card>
                        <TableContainer
                            hideDisplayTotalCount={true}
                            data={[...details]}
                            columns={tableData}
                            customPageSize={5}
                            handleClickView={handleClickView}
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="table-nowrap table-border table-centered align-middle"
                            theadClass="bg-light text-muted"
                        />
                    </Container>
                )}
            </div>
        </React.Fragment>
    );
};

export default PurchaseLicense;
