/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'reactstrap';
import AnimatedTreeComponent from '../../Components/Common/AnimatedTreeComponent';

const Assigning = (props) => {
    const [groups, setGroups] = useState([...props.groups]);
    const [flatGroups, setFlatGroups] = useState([...props.flatGroups]);

    useEffect(() => {
        setGroups([...props.groups]);
        setFlatGroups([...props.flatGroups]);
    }, [props]);

    const handleGroupSelection = (groupArr) => {
        props.setGroups(groupArr);
    };

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.id);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setGroupsData = async (deviceGroups, dataObj, groupsData, deviceGroupCodes) => {
        await deviceGroups.forEach(async (data) => {
            data.label = data.name;
            data.key = data.code;
            data.checked = deviceGroupCodes.includes(data.code) || dataObj.checked;
            groupsData.push(data);
            if (data.children) {
                let obj = await setGroupsData(data.children, data, groupsData, deviceGroupCodes);
                data.children = obj.deviceGroups;
                groupsData = [...obj.groupsData];
            }
        });
        return { deviceGroups: deviceGroups, groupsData: groupsData };
    };

    const setCheckedGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.code === group.code) groupVal.checked = !!groupVal.checked;
            if (groupVal.children) groupVal = setCheckedGroup(groupVal, group);
        });
        return groupsObj;
    };

    const changeGroups = (groupsArr, checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj = setCheckedGroup(groupObj, group);
        });
        let fGroups = JSON.parse(JSON.stringify(props.flatGroups));
        fGroups.forEach((groupVal) => (groupVal.checked = checked.includes(groupVal.code)));
        props.setFlatGroups(fGroups);
        props.setSelectedGroups(checked);
        handleGroupSelection(groupsArr);
        setGroups(groupsArr);
    };

    return (
        <React.Fragment>
            <Form className="h-100">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                    <div className="h-100 ">
                        <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
                            <div className="mb-3 mb-lg-0 w-100 shadow-card">
                                <div className="padding-8 fs-14 border-radius-top-left-4 border-radius-top-right-4">
                                    <div className="d-flex align-items-center fw-medium fs-12 justify-content-between">
                                        <div className="d-flex align-items-center ">
                                            <i className="ri-database-2-fill me-2" />
                                            <span>All Groups </span>({groups.length})
                                        </div>
                                        {/* <div className="d-flex align-items-center text-info">
                                            {props.selectedGroups?.length} {`${props.selectedGroups?.length === 1 ? 'Group' : 'Groups'}`}{' '}
                                            Selected
                                        </div> */}
                                    </div>
                                </div>
                                <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
                                    <AnimatedTreeComponent
                                        groups={groups}
                                        flatGroups={flatGroups}
                                        changeGroups={changeGroups}
                                        disableCheckbox={props.mode === 'view'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Form>
        </React.Fragment>
    );
};

export default Assigning;
