import toastMessages from '../../common/messages/toastMessages';

export const reportSchema = [
    {
        label: 'Filters :',
        value: 'reportFilter',
        inputType: 'dropdown',
        customFilter: true,
        placeholder: 'Select Filter',
        options: [
            {
                label: 'Call logs',
                value: 'call_logs_reports',
                key: 'call_logs_reports',
                filters: true,
                table: {
                    searchFilter: true,
                    exportLogs: true,
                    tableHeader: 'All Call Logs',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        {
                            Header: 'Call Type',
                            accessor: 'callType',
                            customCell: true,
                            fieldType: 'customBadge',
                            sortable: true,
                            width: 50,
                            maxWidth: 50,
                            minWidth: 50
                        },
                        { Header: 'Caller Number', accessor: 'otherNumber', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
                        { Header: 'Call Duration', accessor: 'duration', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
                        {
                            Header: 'Call Date',
                            accessor: 'callDate',
                            sortable: false,
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100
                        }
                    ]
                }
            },
            {
                label: 'User Activity',
                value: 'user_activity_report',
                key: 'user_activity_report',
                filters: false,
                table: {
                    tableHeader: 'All User Activity',
                    tableSchema: [
                        { Header: 'Performed on', accessor: 'activity', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
                        {
                            Header: 'Activity Type',
                            accessor: 'activityType',
                            sortable: true,
                            width: 150,
                            minWidth: 150,
                            maxWidth: 150,
                            view: true
                        },
                        { Header: 'Username', accessor: 'userName', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
                        { Header: 'Date and Time', accessor: 'date', sortable: false, width: 150, minWidth: 150, maxWidth: 150 }
                    ]
                }
            },
            {
                label: 'App Versions Report',
                value: 'app_install',
                key: 'app_install',
                filters: false,
                table: {
                    searchFilter: true,
                    dropdownFilter: true,
                    exportLogs: true,
                    tableHeader: 'App Versions',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        // { Header: 'App Name', accessor: 'appName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
                        { Header: 'Package Name', accessor: 'packageName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
                        { Header: 'Version', accessor: 'version', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
                        // { Header: 'Status', accessor: 'status', sortable: false, width: 50, maxWidth: 50, minWidth: 50, customCell: true, fieldType: 'badge' },
                        { Header: 'Modified Date', accessor: 'modifiedTime', sortable: false, width: 100, minWidth: 100, maxWidth: 100 }
                    ]
                }
            },
            {
                label: 'Device Power Status',
                value: 'device_power_status',
                key: 'device_power_status',
                filters: true,
                table: {
                    searchFilter: true,
                    tableHeader: 'Device Power Status',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 150,
                            maxWidth: 150,
                            minWidth: 150,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        {
                            Header: 'Power Status',
                            accessor: 'power',
                            sortable: true,
                            width: 150,
                            minWidth: 150,
                            maxWidth: 150,
                            customCell: true,
                            fieldType: 'badge'
                        },
                        { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, minWidth: 150, maxWidth: 150 }
                    ]
                }
            },
            {
                label: 'Geo Fence',
                value: 'geo_fence_mobile',
                key: 'geo_fence_mobile',
                filters: true,
                table: {
                    searchFilter: true,
                    exportLogs: true,
                    tableHeader: 'All Mobile Geofences',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        { Header: 'Latitude', accessor: 'latitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        { Header: 'Longitude', accessor: 'longitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            sortable: true,
                            customCell: true,
                            width: 50,
                            maxWidth: 50,
                            minWidth: 50,
                            fieldType: 'badge'
                        },
                        { Header: 'Date', accessor: 'date', sortable: false, width: 100, maxWidth: 100, minWidth: 100 }
                    ]
                }
            },
            {
                label: 'Teachers session',
                value: 'teachers_audit',
                key: 'teachers_audit',
                filters: true,
                table: {
                    tableHeader: 'All Teachers Sessions',
                    tableSchema: [
                        { Header: 'Teacher Name', accessor: 'teacherName', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        { Header: 'Teacher Code', accessor: 'teacherCode', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        { Header: 'Class', accessor: 'class', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
                        { Header: 'Subject', accessor: 'subject', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        { Header: 'School Name', accessor: 'schoolName', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
                        {
                            Header: 'Session Start Date',
                            accessor: 'sessionStartDate',
                            sortable: false,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100
                        },
                        {
                            Header: 'Session End Date',
                            accessor: 'sessionEndDate',
                            sortable: false,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100
                        },
                        { Header: 'Head Master Name', accessor: 'hmName', sortable: true, width: 150, minWidth: 150, maxWidth: 150 }
                    ]
                }
            },
            {
                label: 'TV Geofence',
                value: 'geo_fence_tv',
                key: 'geo_fence_tv',
                filters: true,
                table: {
                    // searchFilter: true,
                    tableHeader: 'All TV Geofences',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        { Header: 'Latitude', accessor: 'latitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        { Header: 'Longitude', accessor: 'longitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            sortable: true,
                            customCell: true,
                            width: 50,
                            maxWidth: 50,
                            minWidth: 50,
                            fieldType: 'badge'
                        },
                        { Header: 'Date', accessor: 'date', sortable: false, width: 100, maxWidth: 100, minWidth: 100 }
                    ]
                }
            },
            {
                label: 'TV App Installation',
                value: 'tv_app_installation',
                key: 'tv_app_installation',
                filters: true,
                table: {
                    // searchFilter: true,
                    tableHeader: 'App Installation',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        { Header: 'App Name', accessor: 'appName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
                        { Header: 'Package Name', accessor: 'packageName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            sortable: false,
                            width: 50,
                            maxWidth: 50,
                            minWidth: 50,
                            customCell: true,
                            fieldType: 'badge'
                        },
                        { Header: 'Modified Date', accessor: 'modifiedTime', sortable: false, width: 100, minWidth: 100, maxWidth: 100 }
                    ]
                }
            },
            {
                label: 'TV Error Devices',
                value: 'tv_error_devices',
                key: 'tv_error_devices',
                filters: true,
                table: {
                    searchFilter: true,
                    tableHeader: 'All Error Devices',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 100,
                            maxWidth: 100,
                            minWidth: 100,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        { Header: 'Location', accessor: 'location', width: 100, minWidth: 100, maxWidth: 100, sortable: true },
                        { Header: 'Storage', accessor: 'storage', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
                        {
                            Header: 'Launcher Status',
                            accessor: 'launcherStatus',
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100,
                            customCell: true,
                            fieldType: 'infoText',
                            infoTooltip: 'infoTooltip',
                            sortable: false,
                            showTooltipKey: 'showTooltip'
                        },
                        { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 100, maxWidth: 100, minWidth: 100 }
                    ]
                }
            },
            {
                label: 'TV Power Status',
                value: 'tv_power_status',
                key: 'tv_power_status',
                filters: true,
                table: {
                    searchFilter: true,
                    tableHeader: 'All TV Power Status',
                    tableSchema: [
                        {
                            Header: 'Serial',
                            accessor: 'serial',
                            sortable: true,
                            width: 150,
                            maxWidth: 150,
                            minWidth: 150,
                            customCell: true,
                            fieldType: 'copy',
                            fieldKey: 'serial',
                            copyToastMsg: toastMessages.serialCopied
                        },
                        {
                            Header: 'Power Status',
                            accessor: 'power',
                            sortable: true,
                            width: 150,
                            minWidth: 150,
                            maxWidth: 150,
                            customCell: true,
                            fieldType: 'badge'
                        },
                        { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, minWidth: 150, maxWidth: 150 }
                    ]
                }
            },
            {
                label: 'Login & Logout History',
                value: 'login_history',
                key: 'login_history',
                filters: false,
                table: {
                    exportLogs: true,
                    tableHeader: 'All Login History',
                    searchFilter: true,
                    searchPlaceholder: 'Username',
                    tableSchema: [
                        {
                            Header: 'Username',
                            accessor: 'username',
                            sortable: true,
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100
                        },
                        {
                            Header: 'Activity',
                            accessor: 'activity',
                            sortable: true,
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100,
                            customCell: true,
                            fieldType: 'badge'
                        },
                        {
                            Header: 'Application',
                            accessor: 'app',
                            sortable: true,
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100
                        },
                        {
                            Header: 'Type',
                            accessor: 'authtype',
                            sortable: true,
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100
                        },
                        {
                            Header: 'Activity Date',
                            accessor: 'modifiedDate',
                            sortable: true,
                            width: 100,
                            minWidth: 100,
                            maxWidth: 100
                        }
                    ]
                }
            }
        ]
    }
];

export const scheduleSchema = [
    {
        value: 'reportType',
        inputType: 'dropdown',
        mandatory: true,
        customCol: true,
        options: [
            { label: 'Call logs', value: 'call logs', key: 'call_logs_reports' },
            { label: 'All User Activity', value: 'user activity', key: 'user_activity_report' },
            { label: 'Device Power Status', value: 'device power status', key: 'device_power_status' },
            { label: 'Mobile Geofencing ', value: 'Mobile Geofencing', key: 'geo_fence_mobile' },
            { label: 'App Version', value: 'App Version', key: 'app_install' },
            { label: 'Teachers session', value: 'Teachers session', key: 'teachers_audit' },
            { label: 'TV Geofence', value: 'TV Geofence', key: 'geo_fence_tv' },
            { label: 'TV App Installation', value: 'TV App Installation', key: 'tv_app_installation' },
            { label: 'TV Error Devices', value: 'TV Error Devices', key: 'tv_error_devices' },
            { label: 'TV Power Status', value: 'TV Power Status', key: 'tv_power_status' },
            { label: 'Login & logout history', value: 'Login & logout history', key: 'login_history' }
        ]
    },
    {
        label: 'Start Date',
        value: 'startDate',
        mandatory: true,
        inputType: 'date',
        helpText: 'The selected network service will be configured with the primary SIM slot.'
    },
    {
        label: 'End Date',
        value: 'endDate',
        mandatory: true,
        inputType: 'date',
        helpText: 'The selected network service will be configured with the primary SIM slot.'
    },
    {
        label: 'Frequency',
        inputType: 'radios',
        value: 'frequency',
        mandatory: true,
        helpText: 'On selection of Random PIN generation, a random PIN will be generated by the system.',
        radios: [
            { label: 'Daily', value: 'frequency', val: 'daily' },
            { label: 'Weekly', value: 'frequency', val: 'weekly' },
            { label: 'Monthly', value: 'frequency', val: 'monthly' }
        ]
    },
    {
        label: 'Repeat',
        inputType: 'radios',
        showKey: 'frequency',
        showVal: 'daily',
        mandatory: true,
        helpText: 'On selection of Random PIN generation, a random PIN will be generated by the system.',
        value: 'repeat',
        showOn: ['frequency'],
        radios: [
            { label: 'Everyday', val: 'everyday' },
            { label: 'Weekdays', val: 'weekdays' }
        ]
    },
    {
        label: 'Repeat',
        inputType: 'dropdown',
        showKey: 'frequency',
        showVal: 'weekly',
        placeholder: 'Select day ',
        mandatory: true,
        helpText: 'On selection of Random PIN generation, a random PIN will be generated by the system.',
        customCol: true,
        value: 'repeat',
        showOn: ['frequency'],
        options: [
            { label: 'Monday', value: 'monday' },
            { label: 'Tuesday', value: 'tuesday' },
            { label: 'Wednesday', value: 'wednesday' },
            { label: 'Thursday', value: 'thursday' },
            { label: 'Friday', value: 'friday' },
            { label: 'Saturday', value: 'saturday' },
            { label: 'Sunday', value: 'sunday' }
        ]
    },
    {
        label: 'Repeat',
        inputType: 'date',
        showKey: 'frequency',
        showVal: 'monthly',
        mandatory: true,
        placeholder: 'Select Date',
        value: 'repeat',
        showOn: ['frequency']
    },
    {
        label: 'Report Genearte Time At',
        value: 'reportGenerateTime',
        mandatory: true,
        inputType: 'date',
        helpText: 'The selected network service will be configured with the primary SIM slot.'
    },
    {
        label: 'Report Send To',
        value: 'reportSend',
        mandatory: true,
        list: 'reportSendTo',
        validator: 'email',
        inputType: 'chips',
        helpText: 'The selected network service will be configured with the primary SIM slot.'
    }
];
