import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { convertUTCtoIST, getDate } from '../../../Components/Common/Util';
import { VolumeCertiData } from '../../../Components/constants/constants';

const VPPCard = (props) => {
    return (
        <>
            <Card className=" border">
                <CardHeader className="border-bottom-dashed">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="fs-12 d-flex">
                                <div className="text-muted">{'Last Synced - '}</div>
                                <div className="fw-medium">{getDate(props.data.lastsync)}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <div
                                className="text-primary text-decoration-underline px-1 fs-12 fw-medium cursor-pointer"
                                onClick={() => props.handleAction('change')}
                            >
                                Renew Certificate
                            </div>
                            <div>
                                <i
                                    className="ri-edit-box-line px-1 fs-16 text-secondary cursor-pointer"
                                    onClick={() => props.handleAction('change')}
                                ></i>
                            </div>
                            <div>
                                <i
                                    className="ri-delete-bin-6-line px-1 fs-16 text-danger cursor-pointer"
                                    onClick={() => props.handleAction('delete')}
                                ></i>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        {VolumeCertiData.map((ele, index) => {
                            return (
                                <Col className={'col-6 mb-2'} key={index}>
                                    <div className="p-0 padding-bottom-3">
                                        <div className="fw-medium py-1">{ele.label}</div>
                                        <div className="text-muted">
                                            {ele.type === 'date'
                                                ? convertUTCtoIST(props.data[ele.key])
                                                : props.data[ele.key]
                                                ? props.data[ele.key]
                                                : '—'}
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};

export default VPPCard;
