import { TENANT_CONFIG } from './actionType';

// constants
import { tenantConfig } from '../../Components/constants/TenantConfig';

const INIT_STATE = { tenantConfig: tenantConfig?.['TT'] };

const TenantConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TENANT_CONFIG:
            return { ...state, tenantConfig: action.payload };
        default:
            return state;
    }
};

export default TenantConfig;
