/* eslint-disable quotes */
const nonComplianceReasons = [
    { label: 'This value is disallowed.', value: 'NON_COMPLIANCE_REASON_UNSPECIFIED' },
    { label: 'The setting is not supported in the API level of the Android version running on the device.', value: 'API_LEVEL' },
    { label: "The management mode (profile owner, device owner, etc.) doesn't support the setting.", value: 'MANAGEMENT_MODE' },
    { label: 'The user has not taken required action to comply with the setting.', value: 'USER_ACTION' },
    { label: 'The setting has an invalid value.', value: 'INVALID_VALUE' },
    { label: 'The app required to implement the policy is not installed.', value: 'APP_NOT_INSTALLED' },
    { label: 'The policy is not supported by the version of Android Device Policy on the device.', value: 'UNSUPPORTED' },
    { label: 'A blocked app is installed.', value: 'APP_INSTALLED' },
    { label: "The setting hasn't been applied at the time of the report, but is expected to be applied shortly.", value: 'PENDING' },
    {
        label: "The setting can't be applied to the app because the app doesn't support it, for example because its target SDK version is not high enough.",
        value: 'APP_INCOMPATIBLE'
    },
    { label: "The app is installed, but it hasn't been updated to the minimum version code specified by policy.", value: 'APP_NOT_UPDATED' }
];
export { nonComplianceReasons };
