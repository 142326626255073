import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TenantConfig } from '../Components/Common/Util';

const Footer = () => {
    const tenantConfig = TenantConfig();
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>{`${new Date().getFullYear()} © ${tenantConfig?.COMPANY_DEVELOPED_BY}.`}</Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">{tenantConfig?.COMPANY_FOOTER}</div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
