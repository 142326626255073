import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { tenantConfig as tenant } from '../../../Components/constants/TenantConfig';
import { useEnv } from '../../../envContext';
import { APIClient, setAuthorization } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { tenantConfig as TenantConf, authModes, authUser, enterpriseAccount } from '../../../store/actions';
import { AuthUser } from '../Util';

const Error401 = () => {
    const urlconf = useEnv();
    const api = new APIClient();
    document.title = '401 Error';
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        let user = AuthUser();
        const userObj = user ? JSON.parse(user) : null;
        const refreshToken = userObj?.data?.refreshToken ? userObj?.data?.refreshToken : '';
        if (refreshToken) {
            setAuthorization(refreshToken, true);
            api.get(url.REFRESH_TOKEN, '', domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        setAuthorization(resp?.data?.token);
                        userObj.data.token = resp?.data?.token;
                        dispatch(authUser(JSON.stringify(userObj)));
                        const route = localStorage.getItem('route401');
                        localStorage.removeItem('authObj');
                        localStorage.removeItem('route401');
                        history.push(route);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    localStorage.clear();
                    sessionStorage.clear();
                    dispatch(authUser(null));
                    dispatch(enterpriseAccount(null));
                    window.location.href = window.location.origin + '/#/login';
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backToLogin = () => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(authUser(null));
        dispatch(authModes(null));
        dispatch(enterpriseAccount(null));
        dispatch(TenantConf(tenant?.[urlconf.REACT_APP_COMPANY_ID]));
        history.push('/login');
    };

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl={5}>
                                <Card className="overflow-hidden">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <lord-icon
                                                className="avatar-xl"
                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                trigger="loop"
                                                colors="primary:#405189,secondary:#0ab39c"
                                                // eslint-disable-next-line no-inline-styles/no-inline-styles
                                                style={{ width: '120px', height: '120px' }}
                                            ></lord-icon>
                                            <h1 className="text-primary mb-4">Oops !</h1>
                                            <h4 className="text-uppercase">Sorry, Invalid Credentials</h4>
                                            <p className="text-muted mb-4">The page you are looking for is not available</p>
                                            <button type="button" onClick={backToLogin} className="btn btn-success">
                                                Back to Login
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Error401;
