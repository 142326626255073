import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist-indexeddb-storage';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

const hostname = window.location.hostname;

const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

if (['localhost', 'portal.mdmdev.tectoro.com'].includes(hostname)) middleware.push(createLogger());

const store = (preloadedState) => {
    const configStore = configureStore({
        reducer: persistedReducer,
        devTools: ['localhost', 'portal.mdmdev.tectoro.com'].includes(hostname),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] }
            }).concat(middleware),
        preloadedState
    });
    sagaMiddleware.run(rootSaga);
    return configStore;
};
const Store = store();
const persistor = persistStore(Store);

export { Store, persistor };
