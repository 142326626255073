import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { convertDate, getDate } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { userActivitySchema } from '../TableSchema';

const AllUserActivity = () => {
    document.title = 'All User Activity';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [userActivities, setUserActivities] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [activityTypes, setActivityTypes] = useState([]);
    const [userTypes, setUserTypes] = useState([]);

    useEffect(() => {
        serviceCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const serviceCall = (paramsObj) => {
        setLoading(true);
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { ...searchParams, page: pageNumber, size: pageSize };

        api.get(url.USER_ACTIVITY, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.userName = data.userName ? data.userName : '—';
                        const activityType = data.activityType.split('_');
                        // eslint-disable-next-line max-nested-callbacks
                        const capitalizedWords = activityType.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                        const activityTypeResult = capitalizedWords.join(' ');
                        data.activityType = activityTypeResult ? activityTypeResult : '—';
                        data.date = data.createdTime ? getDate(data.createdTime) : '—';
                    });
                    setUserActivities(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const onPageChange = (pgNumber, pgSize) => {
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        serviceCall({ ...searchParams, page: pgNumber, size: pgSize });
    };

    const handleGlobalSearch = (val) => {
        let params = {};
        if (val) params = { ...searchParams, serial: val?.trim()?.toUpperCase() };
        else params = { ...searchParams, serial: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        serviceCall({ ...params, page: 1, size: 10 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/') };
        else params = { ...searchParams, fromDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setPageNumber(1);
        if (params.fromDate) serviceCall({ ...params, page: 1, size: 10 });
        else if (val?.length === 0) serviceCall({ ...params, page: 1, size: 10 });
    };

    const handleSearchFilters = () => {
        setPageNumber(1);
        serviceCall({ ...searchParams, page: 1, size: 10 });
    };

    const handleExportLogs = () => {};

    const handleActivyTypeChange = (val) => {};

    const handleUserTypeChange = (e) => {};

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'All Users Activity'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="aereports"
                    />
                    <TableContainer
                        loading={loading}
                        /*
                         * searchFilter2={true}
                         * dateFilter={true}
                         * datePlaceHolder={'Date'}
                         * dropDownFilter3={true}
                         * dropDownFilter2={true}
                         * handleSelectChange={handleActivyTypeChange}
                         * dropDownParams3={{ label: 'Activity Type', options: activityTypes, value: 'activity' }}
                         * dropDownParams2={{ label: 'User Type', options: userTypes, value: 'userType' }}
                         * handleSelectChange2={handleUserTypeChange}
                         * exportLogs={true}
                         */
                        handleExportLogs={handleExportLogs}
                        // searchFiltersButton={true}
                        searchPlaceHolder={'User Name/ Email'}
                        exportLogTooltip="Export in CSV (Upto 10 Thousand activities only)."
                        tableHeader={'All Users Activity'}
                        isGlobalFilter={true}
                        addButton={false}
                        columns={userActivitySchema}
                        data={userActivities || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDateSearch={handleDateSearch}
                        handleSearchFilters={handleSearchFilters}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AllUserActivity;
