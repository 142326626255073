import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import { AUTH_MODES } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { IsAuthorized } from '../../../Components/Common/Util';

const AuthModes = () => {
    document.title = 'Authentication Modes';
    const api = new APIClient();
    const history = useHistory();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [statusOptions, setStatusOptions] = useState({ val: false, selectedEle: '' });
    const [confirmation, setConfirmation] = useState(false);
    const [authModesArr, setAuthModesArr] = useState(AUTH_MODES);

    useEffect(() => {
        getAuthModes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAuthModes = () => {
        setLoading(true);
        api.get(url.AUTH_MODES_CREATE, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    const auths = JSON.parse(JSON.stringify(authModesArr));
                    auths.forEach((auth) => {
                        auth = handleAuthMode(auth, resp.data);
                    });
                    setAuthModesArr(auths);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleAuthMode = (auth, auths) => {
        auths.forEach((authMode) => {
            if (authMode.mode === auth.key) {
                auth.configured = true;
                auth.enabled = authMode.status === 'A';
                auth.id = authMode._id;
            }
        });
        return auth;
    };

    const configure = (ele) => {
        history.push(`/authmodes${ele.route}/configure`);
    };

    const handleViewDetails = (ele) => {
        history.push(`/authmodes${ele.route}/view/${ele.id}`);
    };

    const handleEdit = (ele) => {
        history.push(`/authmodes${ele.route}/edit/${ele.id}`);
    };

    const onStatusChange = (e, ele) => {
        setStatusOptions({ val: e.target.checked, selectedEle: ele });
        setConfirmation(true);
    };

    const handleStatusConfirmation = () => {
        setLoading(true);
        const selOptions = JSON.parse(JSON.stringify(statusOptions));
        setStatusOptions({ val: false, selectedEle: '' });
        setConfirmation(false);
        api.patch(url.AUTH_MODES_CREATE + '/' + selOptions?.selectedEle?.id, { status: statusOptions?.val ? 'A' : 'I' }, false, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.statusUpdated);
                    getAuthModes();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to {statusOptions?.val ? 'Activate' : 'De-activate'} ?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={statusOptions?.selectedEle?.icon} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Auth Mode : <span className="fw-semibold">{statusOptions?.selectedEle?.title}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb title="Authentication Modes" pageTitle="Authentication Modes" backLink="" history={history} />
                    <Card className="card-height-100">
                        <CardHeader className="d-flex align-items-start justify-content-center">
                            <i className="ri-information-line blue-icon me-2 fs-15"></i>
                            <span className="fs-13">
                                Authentication Modes allows you to configure the type of security that is provided while logging into the
                                EMM Platform or EMM Applications. This include LDAP for directory services, Microsoft for Active Directory
                                integration, Google, and MFA for multi-factor authentication security.
                            </span>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {authModesArr.map((ele, index) => (
                                    <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={12} className="h-100" key={index}>
                                        <Card className={`h-100 min-height-280 ${ele.noAction ? 'border border-2 mask' : ''}`}>
                                            <CardBody className="text-center p-3 border border-bottom-0">
                                                <div className="d-flex justify-content-end">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="form-check form-switch form-switch-right form-switch-sm pe-0">
                                                            <IsAuthorized
                                                                privRequired="TENANT_EDITOR"
                                                                yes={() =>
                                                                    ele.configured &&
                                                                    !ele.noAction && (
                                                                        <i
                                                                            className="ri-edit-box-line fs-20 link-info cursor-pointer"
                                                                            onClick={() => handleEdit(ele)}
                                                                        />
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        {ele.configured && (
                                                            <IsAuthorized
                                                                privRequired="TENANT_EDITOR"
                                                                yes={() => (
                                                                    <div className="form-switch form-switch-md">
                                                                        <Input
                                                                            className={'form-check-input cursor-pointer height-18 width-30'}
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            name={'status'}
                                                                            onChange={(e) => onStatusChange(e, ele)}
                                                                            checked={ele.enabled}
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="px-1 py-2">
                                                    <img src={ele.icon} alt="img" height={50} />
                                                </div>
                                                <div className="px-3">
                                                    <div className="fw-semibold fs-14 py-1">{ele.title}</div>
                                                    <div className="fw-normal fs-12 min-height-50">{ele.info}</div>
                                                </div>
                                                <div>
                                                    {ele.configured ? (
                                                        <div className=" d-flex align-items-center gap-1 justify-content-center">
                                                            <i className="ri-checkbox-circle-fill text-primary fs-20" />
                                                            <span className="text-primary fw-medium fs-14">Configured</span>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flex-column justify-content-center gap-1">
                                                            <div className=" w-100">
                                                                <div className="d-flex align-items-center gap-1 justify-content-center fw-medium">
                                                                    <i className="ri-information-line fw-medium fs-14" />
                                                                    <span className="fs-13">Not Configured</span>
                                                                </div>
                                                            </div>
                                                            <IsAuthorized
                                                                privRequired="TENANT_EDITOR"
                                                                yes={() => (
                                                                    <div className="py-1">
                                                                        <Button
                                                                            className="bg-primary fs-14 px-10 fw-medium border-primary py-2"
                                                                            onClick={() => configure(ele)}
                                                                        >
                                                                            {ele.status}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </CardBody>
                                            {ele.configured && !ele.noAction && (
                                                <CardFooter
                                                    className="text-secondary py-2 text-center fs-14 fw-semibold border cursor-pointer p-3"
                                                    onClick={() => handleViewDetails(ele)}
                                                >
                                                    View Details
                                                </CardFooter>
                                            )}
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </CardBody>
                        <CardFooter className="padding-bottom-200 border-top-0"></CardFooter>
                    </Card>
                </Container>
                <DeleteModal
                    hideIcon={true}
                    hideDeleteMessage={true}
                    message={message()}
                    show={confirmation}
                    hideDeleteIcon={statusOptions?.val ? true : false}
                    cancelText="No"
                    confirmText={statusOptions?.val ? 'Yes, Activate it!' : 'Yes, De-activate it!'}
                    onDeleteClick={handleStatusConfirmation}
                    onCloseClick={() => {
                        setConfirmation(false);
                        setStatusOptions({ val: false, selectedEle: '' });
                    }}
                    // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                />
            </div>
        </React.Fragment>
    );
};

export default AuthModes;
