const BRANDING_SCHEMA = [
    {
        label: 'Enable Header',
        value: 'enableHeader',
        inputType: 'toggle',
        helpText: 'Enabling this will enable the header in the mobile',
        tab: 'theme'
    },
    {
        label: 'Header Background Color',
        value: 'headerBackgroundColor',
        show: ['enableHeader'],
        inputType: 'colorPicker',
        helpText: 'Choose the color for the header background',
        tab: 'theme'
    },
    {
        label: 'Header Text Color',
        value: 'headerTextColor',
        show: ['enableHeader'],
        inputType: 'colorPicker',
        helpText: 'Choose the color for the text in the header',
        tab: 'theme'
    },
    {
        label: 'Show Serial',
        value: 'showSerial',
        show: ['enableHeader'],
        inputType: 'toggle',
        helpText: 'Enabling this will show the device serial in the header',
        tab: 'theme'
    },
    {
        label: 'Show IMEI',
        value: 'showIMEI',
        show: ['enableHeader'],
        inputType: 'toggle',
        helpText: 'Enabling this will show the device IMEI in the header',
        tab: 'theme'
    },
    {
        label: 'Enable Header Transparency',
        value: 'headerTransparent',
        show: ['enableHeader'],
        inputType: 'toggle',
        helpText: 'Enabling this will make the header transparent',
        tab: 'theme'
    },
    {
        label: 'Enable Company Logo',
        value: 'enableCompanyLogo',
        show: ['enableHeader'],
        inputType: 'toggle',
        helpText: 'Enabling this will provide the scope to show company logo on the header',
        tab: 'theme'
    },
    {
        label: 'Company Logo',
        value: 'companyLogo',
        mandatory: true,
        show: ['enableHeader', 'enableCompanyLogo'],
        inputType: 'upload',
        accept: ['jpg', 'jpeg', 'png', 'svg'],
        helpText: 'This image will be displayed as the logo on the device header',
        tab: 'theme'
    },
    {
        label: 'App Icons Per Row',
        value: 'appsPerRow',
        inputType: 'select',
        options: [
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 }
        ],
        mandatory: true,
        helpText: 'Number of apps to be shown per row',
        tab: 'theme'
    },
    {
        label: 'App Icon Size',
        value: 'iconSize',
        inputType: 'radio',
        radios: [
            {
                label: 'Small',
                value: '80',
                icon: { key: 'small', width: '80', height: '80', widthPercent: '80%', heightPercent: '80%' },
                appName: { key: 'small', width: '15', height: '15' }
            },
            {
                label: 'Medium',
                value: '110',
                icon: { key: 'medium', width: '110', height: '110', widthPercent: '90%', heightPercent: '90%' },
                appName: { key: 'medium', width: '18', height: '18' }
            },
            {
                label: 'Large',
                value: '140',
                icon: { key: 'large', width: '140', height: '140', widthPercent: '100%', heightPercent: '100%' },
                appName: { key: 'large', width: '22', height: '22' }
            }
        ],
        mandatory: true,
        helpText: 'Choose the size of the icon',
        tab: 'theme'
    },
    {
        label: 'Enable App Label',
        value: 'enableAppLabel',
        inputType: 'toggle',
        helpText: 'Enabling this will display the app name',
        tab: 'theme'
    },
    {
        label: 'App Label Shadow',
        value: 'enableLabelShadow',
        show: ['enableAppLabel'],
        inputType: 'toggle',
        helpText: 'Enabling this will provide shadow to the app name',
        tab: 'theme'
    },
    {
        label: 'Screen Orientation',
        value: 'orientation',
        inputType: 'radio',
        helpText: 'Choose the orientation of the device',
        radios: [
            { label: 'Landscape', value: 'landscape' },
            { label: 'Portrait', value: 'portrait' }
        ],
        tab: 'theme'
    },
    {
        label: 'Icon Order',
        value: 'iconOrder',
        inputType: 'radio',
        helpText: 'Choose the sorting order of the apps on the device',
        radios: [
            { label: 'A To Z', value: 'atoz' },
            { label: 'Z To A', value: 'ztoa' }
        ],
        tab: 'theme'
    },
    {
        label: 'Enable Footer',
        value: 'enableFooter',
        inputType: 'toggle',
        helpText: 'Enables the footer on the device',
        tab: 'theme'
    },
    {
        label: 'Footer Background Color',
        value: 'footerBackgroundColor',
        inputType: 'colorPicker',
        show: ['enableFooter'],
        helpText: 'Choose the color of the footer background',
        tab: 'theme'
    },
    {
        label: 'Footer Text Color',
        value: 'footerTextColor',
        show: ['enableFooter'],
        inputType: 'colorPicker',
        helpText: 'Choose the color of the text on the footer',
        tab: 'theme'
    },
    {
        label: 'Enable Footer Transparency',
        value: 'footerTransparent',
        inputType: 'toggle',
        show: ['enableFooter'],
        helpText: 'Enabling this will make the footer transparent',
        tab: 'theme'
    },
    {
        label: 'Footer Text',
        value: 'footerText',
        inputType: 'input',
        maxLength: 250,
        show: ['enableFooter'],
        helpText: 'Enter the text to be shown on the footer',
        tab: 'theme'
    },
    {
        label: 'Enable Folder',
        value: 'enableFolder',
        helpText: 'This feature enables admin to group the applications in one folder. Admin can aslo create multiple folders.',
        inputType: 'toggle',
        tab: 'folder'
    },
    {
        inputType: 'folder',
        show: ['enableFolder'],
        tab: 'folder',
        optionsKey: 'applications',
        setterKey: 'setApplications',
        arrayKey: 'apps',
        fields: [
            {
                label: 'Folder Name',
                helpText: 'Create a folder name, which will resemble the set of aplications added in the folder',
                value: 'folderName',
                mandatory: true,
                inputType: 'input'
            },
            {
                label: 'Apps',
                helpText: 'Admin can select apps from  the app repository from the below dropdown',
                value: 'app',
                inputType: 'select',
                customCol: true,
                mandatory: true,
                optionsKey: 'applications',
                setterKey: 'setApplications',
                pushTo: 'folders',
                disable: ['folderName', 'apps'],
                arrayKey: 'apps',
                optionLabel: 'title',
                optionVal: 'packageName',
                showArray: true
            }
        ]
    }
];

export default BRANDING_SCHEMA;
