import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';

// import Components
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
// import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
// import SearchOption from '../Components/Common/SearchOption';
import { TenantConfig } from '../Components/Common/Util';

const Header = ({ _onChangeLayoutMode, _layoutModeType, headerClass }) => {
    const tenant = TenantConfig();
    const [search, setSearch] = useState(false);
    /*
     * const [timeString, setTimeString] = useState('30 MM : 00 SS');
     * const [session, setSession] = useState(true);
     * const [time, setTime] = useState(1800000);
     * const [activity, setActivity] = useState(false);
     * let user = AuthUser();
     */

    /*
     * useEffect(() => {
     *     if (user) setSession(true);
     *     else setSession(false);
     *     let timeOut = setTimeout(() => {
     *         clearTimeout(timeOut);
     *         timeStart(time);
     *     }, 5000);
     *     // eslint-disable-next-line react-hooks/exhaustive-deps
     * }, [user]);
     */

    /*
     * const logout = () => {
     *     setSession(false);
     *     localStorage.clear();
     *     sessionStorage.clear();
     *     dispatch(authUser(null));
     *     dispatch(enterpriseAccount(null));
     *     dispatch(tenantConfig(tenantConf?.[process.env.REACT_APP_COMPANY_ID]));
     *     history.push('/login');
     *     window.location.reload();
     * };
     */

    /*
     * const activityHappened = () => {
     *     setTimeString('30 MM : 00 SS');
     *     setActivity(false);
     *     if (activity)
     *         myVar1 = setTimeout(() => {
     *             clearTimeout(myVar1);
     *             setTime(1800000);
     *             timeStart(1800000);
     *         }, 5000);
     * };
     */

    /*
     * const handleActivity = () => {
     *     if (session) {
     *         activityHappened();
     *     }
     * };
     */

    /*
     * let events = ['keypress', 'click'];
     * events.forEach(function (name) {
     *     document.addEventListener(name, handleActivity, true);
     * });
     */

    /*
     * const handleTimeString = (timeVal) => {
     *     let { minutes, seconds } = convertSecondsTo(timeVal);
     *     let timeStr = (minutes < 10 ? '0' + minutes : minutes) + ' MM : ' + (seconds < 10 ? '0' + seconds : seconds) + ' SS';
     *     setTime(timeVal - 1000);
     *     setTimeString(timeStr);
     *     timeStart(timeVal - 1000);
     * };
     */

    /*
     * const timeStart = (timeVal) => {
     *     setActivity(true);
     *     myVar = setTimeout(() => {
     *         clearTimeout(myVar);
     *         if (timeVal > 0) {
     *             handleTimeString(timeVal);
     *         } else {
     *             logout();
     *         }
     *     }, 1000);
     * };
     */

    const toogleSearch = () => {
        setSearch(!search);
    };

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

        // For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
            document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu');
        }

        // For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === 'vertical') {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                document.documentElement.getAttribute('data-sidebar-size') === 'sm'
                    ? document.documentElement.setAttribute('data-sidebar-size', '')
                    : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                document.documentElement.getAttribute('data-sidebar-size') === 'lg'
                    ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
                    : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        // Two column menu
        if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
            document.body.classList.contains('twocolumn-panel')
                ? document.body.classList.remove('twocolumn-panel')
                : document.body.classList.add('twocolumn-panel');
        }
    };
    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex align-items-center">
                            <div className="navbar-brand-box horizontal-logo pe-3">
                                <Link to="/getstarted" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={tenant?.COMPANY_LOGO} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={tenant?.COMPANY_LOGO} alt="" height={'40'} />
                                    </span>
                                </Link>

                                <Link to="/getstarted" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={tenant?.COMPANY_LOGO} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={tenant?.COMPANY_LOGO} alt="" height={'40'} />
                                    </span>
                                </Link>
                            </div>
                            {/* <Col className="align-items-center ms-2 me-2">
                                {tenant?.PRIMARY_HEADER && <Row className="fw-medium fs-15 text-light">{tenant.PRIMARY_HEADER}</Row>}
                                {tenant?.SECONDARY_HEADER && <Row className="fw-medium fs-10 text-light">{tenant.SECONDARY_HEADER}</Row>}
                            </Col> */}
                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon"
                            >
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                            {/* <SearchOption /> */}
                        </div>

                        <div className="d-flex align-items-center">
                            {/* <div className="d-flex align-items-center gap-1 fs-14 text-light link-light fw-semibold">
                                <i className="ri-timer-line fs-20 fw-normal" />
                                <span>{timeString}</span>
                            </div> */}
                            <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle
                                    type="button"
                                    tag="button"
                                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                >
                                    <i className="ri-search-line fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search ..."
                                                    aria-label="Recipient's username"
                                                />
                                                <button className="btn btn-primary" type="submit">
                                                    <i className="mdi mdi-magnify"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>

                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />

                            {/* NotificationDropdown */}
                            {/* <NotificationDropdown /> */}

                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
