import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../Components/Common/Loader';
import { TenantConfig, loginUtil } from '../../Components/Common/Util';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';
import { useEnv } from '../../envContext';
import { APIClient, setAuthorization } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { authUser } from '../../store/actions';

const AuthCallback = () => {
    const api = new APIClient();
    const history = useHistory();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let tenantObj = TenantConfig();
    let tenantData = tenant?.[urlconf.REACT_APP_COMPANY_ID];

    useEffect(() => {
        setLoading(true);
        let params = new URLSearchParams(decodeURIComponent(history?.location?.search));
        const code = params.get('code');
        const state = params.get('state');
        api.create(url.AUTH_OIDC_CALLBACK, { state: state, code: code }, false, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setAuthorization(resp?.data?.token);
                resp.data.privileges = resp.data.privileges?.split(',');
                localStorage.setItem('account', resp.data.tenant);
                dispatch(authUser(JSON.stringify(resp)));
                loginUtil({
                    user: resp,
                    ten: resp.data.tenant,
                    api: api,
                    history: history,
                    dispatch: dispatch,
                    tenantObj: tenantObj,
                    tenantData: tenantData,
                    urlconf: urlconf
                });
                localStorage.removeItem('authObj');
                setLoading(false);
            })
            .catch((err) => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <React.Fragment>{loading && <Loader />}</React.Fragment>;
};

export default AuthCallback;
