import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import Loader from '../../Components/Common/Loader';
import { Enterprise } from '../../Components/Common/Util';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import { authUser, enterpriseAccount } from '../../store/actions';
import AndroidEnterprise from './AndroidEnterprise';

const Enterprises = () => {
    document.title = 'Enterprise';
    let enterPriseObj = Enterprise();
    const enterprises = enterPriseObj ? JSON.parse(enterPriseObj) : {};
    const urlconf = useEnv();
    const api = new APIClient();
    let dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        let enterpriseToken = window.location.search?.split('=')?.[1];
        if (enterpriseToken !== '' && enterpriseToken !== undefined && enterpriseToken !== null) {
            let enrollObj = localStorage.getItem('enrollData');
            let enrollData = enrollObj ? JSON.parse(enrollObj) : {};
            let params = { signupUrlName: enrollData?.name, enterpriseToken: enterpriseToken };
            completeRegistration(params);
        }
    }, []);

    const completeRegistration = (params) => {
        setLoading(true);
        api.create(url.ENTERPRISES, params)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    dispatch(enterpriseAccount(JSON.stringify(resp.data)));
                    setLoading(false);
                    window.location.href = window.location.origin + '/' + window.location.hash;
                }
            })
            .catch(function (error) {
                toast.error(error);
                setLoading(false);
            });
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        api.delete(url.ENTERPRISES + '/' + enterprises?.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.enterpriseCreated);
                    localStorage.clear();
                    sessionStorage.clear();
                    localStorage.clear();
                    dispatch(authUser(null));
                    dispatch(enterpriseAccount(null));
                    setLoading(false);
                    window.location.href = window.location.origin + '/#/login';
                }
            })
            .catch((err) => {
                setLoading(false);
                setDeleteModal(false);
            });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Accounts" history={history} homeLink="Dashboard" />
                    <AndroidEnterprise setLoading={setLoading} />
                </Container>
            </div>
            {deleteModal && (
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteConfirmation}
                    onCloseClick={() => setDeleteModal(false)}
                    deleteMessage={'Are you sure you want to remove your enterprise account ?'}
                />
            )}
        </React.Fragment>
    );
};

export default Enterprises;
